import { isFileNameEndsWith } from 'utils/baseName'
/**
 * Checks whether the file name of a file looks like a data file name.
 * This is done by looking at the extension and checking whether it is ".data".
 */
export function isDataFileName (fileName) {
  return isFileNameEndsWith(fileName, '.data')
}

export function isDataFileType (fileType) {
  return fileType === 'data'
}
