// turf
import React from 'react'
// Project deps
import { isAdmin } from 'modules/users/selectors'
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import { DataType } from 'types/form'
import DistanceImage from 'assets/images/distance-value-graph.png'
import DistanceAngleImage from 'assets/images/distance-and-angle-graph.png'
import { getCloneJobOptionValue } from '../utils'
import { MapBackendNameToFrontend } from '../constants'
import { Trans } from 'react-i18next'
import WikiButton from 'components/WikiButton'
import { isBayerEnvironment } from 'config'

export default {
  ptd_iteration_angle: {
    name: MapBackendNameToFrontend.ptd_iteration_angle,
    dataType: DataType.FLOAT,
    precision: 2,
    min: 0,
    tooltip: MapBackendNameToFrontend.ptd_iteration_angle_tooltip,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'ptd_iteration_angle', '6'),
    /*
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
    */
    containerNumber: 0,
    column: 1,
    columnWidth: 8,
  },
  ptd_iteration_angle_image: {
    name: '',
    dataType: DataType.IMAGE,
    src: DistanceAngleImage,
    initialValue: null,
    sendToBackend: false,
    positionX: 'center',
    containerNumber: 0,
    column: 0,
    columnWidth: 4,
  },
  ptd_iteration_distance: {
    name: MapBackendNameToFrontend.ptd_iteration_distance,
    dataType: DataType.FLOAT,
    precision: 2,
    tooltip: MapBackendNameToFrontend.ptd_iteration_distance_tooltip,
    min: 0,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'ptd_iteration_distance', '0.15'),
    containerNumber: 1,
    column: 1,
    columnWidth: 8,
    /*
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
    */
  },
  ptd_iteration_distance_image: {
    name: '',
    dataType: DataType.IMAGE,
    src: DistanceImage,
    initialValue: null,
    sendToBackend: false,
    containerNumber: 1,
    column: 0,
    columnWidth: 4,
  },
  ptd_cell_size: {
    name: MapBackendNameToFrontend.ptd_cell_size,
    dataType: DataType.FLOAT,
    precision: 2,
    tooltip: MapBackendNameToFrontend.ptd_cell_size_tooltip,
    min: 0,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'ptd_cell_size', '20'),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  ground_thickness: {
    name: MapBackendNameToFrontend.ground_thickness,
    dataType: DataType.FLOAT,
    precision: 2,
    min: 0,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'ground_thickness', '0.01'),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  feature_size: {
    name: MapBackendNameToFrontend.feature_size,
    dataType: DataType.FLOAT,
    precision: 2,
    min: 0,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'feature_size', '2'),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  ai_classification: {
    name: 'AI classification',
    dataType: DataType.BOOLEAN,
    /**
     * @Rob mentioned that multiple people noticed that lidarmill was slow.
     * Checking on this, its true, and that's because using AI classification makes things take 10x longer.
     * Can you please disable AI classification by default? I suspect many people don't really care about it anyway...
     */
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'ai_classification', false),
    invisible: isBayerEnvironment(),
  },
  filter_moving_objects: {
    name: () => {
      return <span>{MapBackendNameToFrontend.filter_moving_objects} <WikiButton title={<Trans i18nKey='templates.jobOptions.ground_classification.tooltip.filter_moving_objects' />} /></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'filter_moving_objects', false),
  },
  classify_powerlines: {
    name: () => {
      return <span>{MapBackendNameToFrontend.classify_powerlines} <WikiButton title={<Trans i18nKey='templates.jobOptions.ground_classification.tooltip.classify_powerlines' />} /></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'classify_powerlines', false),
  },
  /*
  checkpoint: {
    name: 'Use Custom checkpoint',
    dataType: DataType.BOOLEAN,
    invisible: (state, values) => !values.ai_classification,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'checkpoint', false),
  },
  */
}
