import { isDropboxFile } from 'modules/importWizard/utils'
import { UploadStatus, isCurrentStageRunning } from 'modules/upload/utils'
import { filterByArtifactId, filterByMissionId, filterByProjectId, findById } from './list'

export function getFilesForArtifact (files, artifactId) {
  return filterByArtifactId(artifactId, files)
}
export function getFilesForProject (files, projectId) {
  return filterByProjectId(projectId, files)
}
export function getFilesFilesForMission (files, missionId) {
  return filterByMissionId(missionId, files)
}

export function getFailedFileForFile (file, uploadState) {
  const failedFiles = uploadState.get('failedFiles')
  return failedFiles.find(failed =>
    failed.fileName === file.file.name &&
        failed.artifactId === file.artifactId)
}

/**
 * Returns the progress of uploading the current file as a number in the range of 0 to 1.
 * This function returns a blurry result, as it does not take into account that the last chunk might be smaller.
 * It is suitable for most Ui progress indicators.
 * @param state The `UploadState` sub state of the application's state.
 * @param file The `UploadState` sub state of the application's state.
 * @return The progress of uploading the current file.
 */
// function getCurrentlyUploadingFileProgress(state: UploadState): number {
function getCurrentlyUploadingFileProgress (uploadState, file, withFailedFile = true) {
  const fileId = file.id
  const transfer = uploadState.get('transfer')
  const files = uploadState.get('files')
  const transferStateFile = findById(fileId, transfer)
  if (!transferStateFile) return 0
  const { currentChunk } = transferStateFile
  let { total, done } = transferStateFile
  const currentUploadingFile = findById(fileId, files) // getCurrentlyUploadingFile(uploadState)
  // If a failed file is present for the currently uploading file, the amount of `done` and `total` chunks
  // need to be adjusted.
  // The uploader only knows about the chunks which it gets handed. And if an upload was retried than the chunks
  // which were already uploaded in the previous session shouldn't be handed to the uploader middleware again.
  //
  // **Example:**
  //
  // Assume there is a file with the chunks A,B,C,D,E,F,G,H,I and J.
  // In the first session the chunks A,B,C,D and E were already uploaded. Then the user refreshes the browser and
  // reinitiates the upload. The frontend fetches the list of chunks from the browser and gets the chunks A to J
  // with A to E being marked as `completed`. The uploader will only receive the chunks F,G,H,I and J. So `done`
  // might range from 0 to 4 (because at 5 the file is done and this function will pick another file from the state)
  // and `total` is 5.
  //
  // Without this adjustment the initial progressbar might look like this: `[          ] 0%`.
  // But the desired initial look is: `[#####     ] 50%`. (As half of the chunks were already uploaded in the last
  // session.)
  // In the end it will always be `[##########] 100%`.
  const failedFile = getFailedFileForFile(currentUploadingFile, uploadState)
  if (failedFile && failedFile.chunks && failedFile.chunks.length > 0 && withFailedFile) {
    done = failedFile.chunks.reduce((result, chunk) => typeof chunk === 'object'
      ? chunk.completed ? result + 1 : result
      : chunk ? result + 1 : result, 0)
    total = failedFile.chunks.length
  }
  const totalSize = currentUploadingFile.file.size
  let currentChunkProgress = 0
  if (currentChunk) {
    currentChunkProgress = (currentChunk.size * transferStateFile.progress) / totalSize
  }
  let chunksProgress = 0
  if (total > 0) {
    chunksProgress = done / total
  }
  return currentChunkProgress + chunksProgress
}

/**
 * Checks whether the specified file is currently uploading **OR**,
 * if the application is not yet uploading files, whether the file would be the next one to be uploaded.
 * @param file The file to check.
 * @param state The `UploadState` sub state of the application's state.
 * @return `true` if the file is uploading or will be uploaded next.
 */
// export function isFileCurrentlyUploading(file: File, state: UploadState): boolean {
export function isFileCurrentlyUploading (file, state) {
  // const currentFile = getCurrentlyUploadingFile(state)
  const transfer = (state.upload) ? state.upload.get('transfer') : state.get('transfer')
  return Boolean(findById(file.id, transfer))
  // return Boolean(currentFile && file === currentFile.file)
}

/**
 * Returns the currently uploading file.
 * @param state The `UploadState` sub state of the application's state.
 * @return The currently uplading `UploadingFile`.
 */
// export function getCurrentlyUploadingFile(state: UploadState) {
export function getCurrentlyUploadingFile (state) {
  const files = (state.upload) ? state.upload.get('files') : state.get('files')
  return files.find(file => file.status === UploadStatus.PREPARED)
}
/**
 * Checks whether a file is the one the uploader middleware is currently preparing.
 * @param file The file to check.
 * @param state The `UploadState` sub state of the application's state.
 * @return `true` if the file is preparing or will be prepared next.
 */
// export function isFileCurrentlyPreparing(file: File, state: UploadState): boolean {
export function isFileCurrentlyPreparing (file, state) {
  /*
  const currentFile = getCurrentlyPreparingFile(uploadState)
  return Boolean(currentFile && file === getCurrentlyPreparingFile(uploadState).file)
  */
  const prepare = (state.upload) ? state.upload.get('prepare') : state.get('prepare')
  return Boolean(findById(file.id, prepare))
}

export function getCurrentlyPreparingFile (uploadState) {
  const files = uploadState.get('files')
  return files.find(file => file.status === UploadStatus.PENDING)
}

/**
 * Returns the upload and preparation progress for a specific file.
 * Both the progress of the upload as well as the progress of preparing the file will
 * be returned as a number in the range of 0 and 1.
 * @param file The file which should be checked.
 * @param state The upload state of the application's state.
 * @return The progress of preparing and uploading the file.
 */
/// export function getFileProgress(file: UploadingFile, state: UploadState): UploadProgress {

export function getFileProgress (file, uploadState) {
  const prepare = uploadState.get('prepare')
  const fileId = file.id
  switch (file.status) {
    // If the file is still pending it might be the file which is currently
    // being prepared, if it is the file on top of the list.
    case UploadStatus.PENDING: {
      // A pending file cannot have an upload progress other than 0.
      const uploaded = 0
      let prepared = 0
      // If the file is currently being prepared, this determines the progress
      // of preparing the file.
      const prepareFileState = findById(fileId, prepare)
      if (prepareFileState) {
        prepared = prepareFileState.progress
      }
      return { uploaded, prepared }
    }
    // If the file is prepared it might be the one which is currently being uploaded,
    // if it is the file on top of the list.
    case UploadStatus.PREPARED: {
      let uploaded = 0
      // A file in the `preparedFiles` list always has a preparation progress of 1.
      const prepared = 1
      // If the file is currently being uploaded, this determines the progress
      // of uploading the file.
      if (isFileCurrentlyUploading(file, uploadState)) {
        uploaded = getCurrentlyUploadingFileProgress(uploadState, file)
      }
      return { uploaded, prepared }
    }
    // A file which is done always as a progress of 1 for both the preparation as the upload.
    default:
    case UploadStatus.DONE: {
      return { prepared: 1, uploaded: 1 }
    }
  }
}

/**
 * Returns all files in the upload state, independent of whether they are currently pending,
 * prepared or uploaded. The list will be sorted alphabetically to preserve the order.
 * @param state The upload state.
 * @param artifactId The id of the artifact to get all files for.
 * @return A list of all files in the upload state, alphabetically sorted.
 */
// export function getAllUploadFilesForArtifact(state: UploadState, artifactId: Uuid): File[] {
export function getAllUploadFilesForArtifact (state, artifactId) {
  const { upload } = state
  const files = upload.get('files')
  return getFilesForArtifact(files, artifactId)
    .map(file => file.file)
    // List has to be sorted to stay stable (preserve order) of items.
    .sort((file1, file2) => file1.name.localeCompare(file2.name))
}

// check if upload button is enabled for upload dialog
export function isUploadButtonDisabled (props) {
  const { stage, allFileNamesValid, noFiles } = props
  // The upload button is disabled if the upload is already running ...
  return isCurrentStageRunning(stage) ||
    // ... or some file names are invalid ...
    !allFileNamesValid ||
    // ... or there are no files to upload.
    noFiles
}

/**
 * Calculates the overall progress for all files in the currently ongoing upload.
 * @param state The `UploadState` sub state of the application's state.
 * @param artifactId The id of the artifact to calculate the progress for.
 * @return The overall progress of all files.
 */
export function getUploadProgressForArtifact (state, artifactId, withFailedFile = true) {
  const { upload } = state
  const prepare = upload.get('prepare')
  const transfer = upload.get('transfer')
  const files = getFilesForArtifact(upload.get('files'), artifactId)

  // The amount of bytes of files which are pending, prepared for upload or already done.
  const sumBytesByStatus = uploadStatus => files.reduce(
    (sum, { file, status }) => status === uploadStatus ? sum + file.size : sum,
    0,
  )
  const bytesPending = sumBytesByStatus(UploadStatus.PENDING)
  const bytesPrepared = sumBytesByStatus(UploadStatus.PREPARED)
  const bytesDone = sumBytesByStatus(UploadStatus.DONE)

  // The total amount of bytes of all files.
  const overallBytes = bytesPending + bytesPrepared + bytesDone
  // The file which the application is currently preparing. Can be undefined.
  // const currentlyPreparingFile = getNextPreparableFile(state)
  // The amount of bytes that are already prepared in the file which is currently preparing, or 0 if not preparing.
  let currentlyPreparedBytes = 0
  let currentlyUploadedBytes = 0
  files.forEach(file => {
    const prepareState = findById(file.id, prepare)
    if (prepareState) {
      currentlyPreparedBytes += file.file.size * prepareState.progress
      return
    }
    const transferState = findById(file.id, transfer)
    if (transferState) {
      currentlyUploadedBytes += file.file.size * getCurrentlyUploadingFileProgress(upload, file, withFailedFile)
    }
  })
  /*
  if (currentlyPreparingFile && currentlyPreparingFile.artifactId === artifactId) {
    currentlyPreparedBytes = currentlyPreparingFile.file.size * prepare.progress
  }
  */
  // The progress of preparing the files as a floating point number between 0 and 1.
  const prepared = (bytesPrepared + bytesDone + currentlyPreparedBytes) / overallBytes
  /*
  const currentlyUploadingFilesForArtifact = files.filter(file => findById(file.id, transfer))
  // const currentlyUploadingFile = getCurrentlyUploadingFile(upload)
  let currentlyUploadedBytes = 0
  // if (currentlyUploadingFile && currentlyUploadingFile.artifactId === artifactId) {
  if (currentlyUploadingFilesForArtifact.length > 0) {
    currentlyUploadedBytes = currentlyUploadingFilesForArtifact.reduce((totalUploadedBytes, currentlyUploadingFile) => {
      return totalUploadedBytes +
        currentlyUploadingFile.file.size * getCurrentlyUploadingFileProgress(upload, currentlyUploadingFile)
    }
    , 0)
  }
  */
  const uploaded = overallBytes > 0 ? (bytesDone + currentlyUploadedBytes) / overallBytes : 0
  return {
    uploaded,
    prepared,
  }
}

/**
 * Returns the total number of bytes of all files which are currently being uploaded.
 * @param state The upload state.
 * @param artifactId The id of the artifact to calculate the total size for.
 * @return The number of bytes of all files which are being uploaded.
 */
export function getTotalUploadSizeForArtifact (state, artifactId) {
  const { upload } = state
  const files = upload.get('files')
  return getFilesForArtifact(files, artifactId)
    .reduce((result, file) => {
      const failedFile = getFailedFileForFile(file, upload)
      if (failedFile && failedFile.chunks && failedFile.chunks.length > 0) {
        const doneSize = failedFile.completedSize
        return result + (file.file.size - doneSize)
      }
      return result + file.file.size
    }, 0)
}

export function getTotalSecondsUploading (artifactId, state) {
  const { upload } = state
  return upload.get('files').reduce(
    (totalSeconds, uploadFile) => {
      if (uploadFile.artifactId !== artifactId || uploadFile.status === UploadStatus.PENDING) {
        return totalSeconds
      }
      return totalSeconds + (uploadFile.duration || 0)
      /*
      if (uploadFile.status === UploadStatus.DONE) {
        return totalSeconds + (
          (uploadFile.finished && uploadFile.started)
            ? (uploadFile.finished.getTime() - uploadFile.started.getTime()) / 1000
            : 0
        )
      }
      if (isFileCurrentlyUploading(uploadFile, state)) {
        return totalSeconds + (Date.now() - (uploadFile.started ? uploadFile.started.getTime() : 0)) / 1000
      }
      return totalSeconds
      */
    },
    0,
  )
}

// Returns map
export function getFiles (files) {
  return {
    dropbox: files.filter(file => isDropboxFile(file)),
    files: files.filter(file => !isDropboxFile(file)),
  }
}

export function getUploadRates (artifactId, uploadProgress, state) {
  if (!artifactId) {
    return {
      transferRate: 0,
      timeRemaining: 0,
    }
  }
  const totalUploadSize = getTotalUploadSizeForArtifact(state, artifactId)
  const totalUploadedSize = uploadProgress * totalUploadSize
  // const timeSpent = getTotalSecondsUploading(artifactId, state)
  const currentDate = new Date()
  const { upload } = state
  const files = upload.get('files')
  const firstFileStartUploading = files.find(uploadFile => uploadFile.artifactId === artifactId && uploadFile.started)
  const startedDate = firstFileStartUploading ? firstFileStartUploading.started : currentDate
  const timeSpent = (currentDate.getTime() - startedDate.getTime()) / 1000
  const transferRate = totalUploadedSize / timeSpent
  const timeRemaining = (totalUploadSize - totalUploadedSize) / transferRate
  return {
    transferRate,
    timeRemaining,
  }
}

export function getUploadFilesMap (state, projectId, uploadingFilesFromProps, failedFilesFromProps) {
  const upload = state.upload
  const files = getFilesForProject(upload.get('files'), projectId)
  const transfer = upload.get('transfer')
  const failedFiles = failedFilesFromProps || getFilesForProject(upload.get('failedFiles'), projectId)
  const preparedFiles = files.filter(file => file.status === UploadStatus.PREPARED)
  const uploadingFiles = uploadingFilesFromProps || [
    ...preparedFiles.filter(file => findById(file.id, transfer)),
    ...preparedFiles.filter(file => !findById(file.id, transfer)),
    ...files.filter(file => file.status === UploadStatus.PENDING),
    ...files.filter(file => file.status === UploadStatus.DONE),
  ]

  const uploadingFailedFiles = failedFiles
    .filter(file => !uploadingFiles.find(fileToAdd => (
      fileToAdd.artifactId === file.artifactId &&
      fileToAdd.file.name === file.fileName &&
      fileToAdd.file.size === file.fileSize
    )))

  return {
    failedFiles,
    preparedFiles,
    uploadingFiles,
    uploadingFailedFiles,
  }
}

/**
 * Returns all file that will be uploaded (replaced) instead of provided fileName
 * @param {*} fileName
 * @param {*} files
 */
export const getReplacedFilesForFileName = (fileName, files = []) => {
  return files.filter(file => file.replace === fileName)
}
