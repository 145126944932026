import { createReducer } from 'reduxsauce'
import { convertRawProject } from 'types/projects'
// Local deps
import { INITIAL_STATE } from './initialState'
import { StatisticsTypes } from './actions'

export const getAllJobsLoading = state =>
  state.merge({
    jobs: [],
    getJobsIsLoading: true,
    getJobsErrorMessage: '',
  })

export const getAllJobsUpdate = (state, { jobs }) =>
  state.merge({
    jobs: [...state.get('jobs'), ...jobs],
    getJobsIsLoading: true,
    getJobsErrorMessage: '',
  })

export const getAllJobsSuccess = state =>
  state.merge({
    getJobsIsLoading: false,
    getJobsErrorMessage: null,
  })

export const getAllJobsFailure = (state, { errorMessage }) =>
  state.merge({
    jobs: null,
    getJobsIsLoading: false,
    getJobsErrorMessage: errorMessage,
  })

export const getAllJobsQueriedLoading = state =>
  state.merge({
    getJobsIsLoading: true,
    getJobsErrorMessage: '',
  })

export const getAllJobsQueriedSuccess = (state, { jobs, pagination }) =>
  state.merge({
    jobs,
    pagination,
    getJobsIsLoading: false,
    getJobsErrorMessage: null,
  })

export const getAllJobsQueriedFailure = (state, { errorMessage }) =>
  state.merge({
    jobs: [],
    getJobsIsLoading: false,
    getJobsErrorMessage: errorMessage,
  })

export const getAllProjectsLoading = state =>
  state.merge({
    projects: [],
    getProjectsIsLoading: true,
    getProjectsErrorMessage: null,
  })

export const getAllProjectsSuccess = (state, { projects }) =>
  state.merge({
    projects: projects.map(convertRawProject),
    getProjectsIsLoading: false,
  })

export const getAllProjectsFailure = (state, { errorMessage }) =>
  state.merge({
    projects: [],
    getProjectsIsLoading: false,
    getProjectsErrorMessage: errorMessage,
  })

export const getJobRunLogsLoading = state =>
  state.merge({
    getJobRunLogsIsLoading: true,
    getJobRunLogsErrorMessage: '',
  })

export const getJobRunLogsSuccess = (state, { jobRunLogs }) =>
  state.merge({
    jobRunLogs,
    getJobRunLogsIsLoading: false,
    getJobRunLogsErrorMessage: null,
  })

export const getJobRunLogsFailure = (state, { errorMessage }) =>
  state.merge({
    jobRunLogs: null,
    getJobRunLogsIsLoading: false,
    getJobRunLogsErrorMessage: errorMessage,
  })

export const getDataFileUploadLogsLoading = state =>
  state.merge({
    getDataFileUploadLogsIsLoading: true,
    getDataFileUploadLogsErrorMessage: '',
  })

export const getDataFileUploadLogsSuccess = (state, { dataFileUploadLogs }) =>
  state.merge({
    dataFileUploadLogs,
    getDataFileUploadLogsIsLoading: false,
    getDataFileUploadLogsErrorMessage: null,
  })

export const getDataFileUploadLogsFailure = (state, { errorMessage }) =>
  state.merge({
    dataFileUploadLogs: null,
    getDataFileUploadLogsIsLoading: false,
    getDataFileUploadLogsErrorMessage: errorMessage,
  })

export const getLogsLoading = state =>
  state.merge({
    getLogsIsLoading: true,
    getLogsErrorMessage: null,
  })

export const getLogsSuccess = (state, { jobRunLogs, dataFileUploadLogs }) =>
  state.merge({
    dataFileUploadLogs,
    jobRunLogs,
    getLogsIsLoading: false,
    getLogsErrorMessage: null,
  })

export const getLogsFailure = (state, { errorMessage }) =>
  state.merge({
    dataFileUploadLogs: [],
    jobRunLogs: [],
    getLogsIsLoading: false,
    getLogsErrorMessage: errorMessage,
  })

export const getWorkerUsageLoading = state => state.merge({ getWorkerUsageIsLoading: true })
export const getWorkerUsageSuccess = (state, { workerUsage }) => state.merge({ getWorkerUsageIsLoading: false, workerUsage })
export const getWorkerUsageFailure = state => state.merge({ getWorkerUsageIsLoading: false })

export const reducer = createReducer(INITIAL_STATE, {
  [StatisticsTypes.GET_ALL_JOBS_LOADING]: getAllJobsLoading,
  [StatisticsTypes.GET_ALL_JOBS_UPDATE]: getAllJobsUpdate,
  [StatisticsTypes.GET_ALL_JOBS_SUCCESS]: getAllJobsSuccess,
  [StatisticsTypes.GET_ALL_JOBS_FAILURE]: getAllJobsFailure,

  [StatisticsTypes.GET_ALL_PROJECTS_LOADING]: getAllProjectsLoading,
  [StatisticsTypes.GET_ALL_PROJECTS_SUCCESS]: getAllProjectsSuccess,
  [StatisticsTypes.GET_ALL_PROJECTS_FAILURE]: getAllProjectsFailure,

  [StatisticsTypes.GET_ALL_JOBS_QUERIED_LOADING]: getAllJobsQueriedLoading,
  [StatisticsTypes.GET_ALL_JOBS_QUERIED_SUCCESS]: getAllJobsQueriedSuccess,
  [StatisticsTypes.GET_ALL_JOBS_QUERIED_FAILURE]: getAllJobsQueriedFailure,

  [StatisticsTypes.GET_JOB_RUN_LOGS_LOADING]: getJobRunLogsLoading,
  [StatisticsTypes.GET_JOB_RUN_LOGS_SUCCESS]: getJobRunLogsSuccess,
  [StatisticsTypes.GET_JOB_RUN_LOGS_FAILURE]: getJobRunLogsFailure,

  [StatisticsTypes.GET_DATA_FILE_UPLOAD_LOGS_LOADING]: getDataFileUploadLogsLoading,
  [StatisticsTypes.GET_DATA_FILE_UPLOAD_LOGS_SUCCESS]: getDataFileUploadLogsSuccess,
  [StatisticsTypes.GET_DATA_FILE_UPLOAD_LOGS_FAILURE]: getDataFileUploadLogsFailure,

  [StatisticsTypes.GET_LOGS_LOADING]: getLogsLoading,
  [StatisticsTypes.GET_LOGS_SUCCESS]: getLogsSuccess,
  [StatisticsTypes.GET_LOGS_FAILURE]: getLogsFailure,

  [StatisticsTypes.GET_WORKER_USAGE_SUCCESS]: getWorkerUsageSuccess,
  [StatisticsTypes.GET_WORKER_USAGE_LOADING]: getWorkerUsageLoading,
  [StatisticsTypes.GET_WORKER_USAGE_FAILURE]: getWorkerUsageFailure,
})
