import { EventEmitter } from 'events'
// import { toast } from 'react-toastify'

/**
 * Used to describe the transfer status of a single chunk.
 */
export const TransferStatus = {
  /**
   * The transfer of the chunk was not yet started.
   */
  PENDING: 0,
  /**
   * The transfer of the chunk is currently in progress.
   */
  IN_PROGRESS: 1,
  /**
   * The transfer of the chunk is done and was successful.
   */
  SUCCESS: 2,
  /**
   * The transfer of the chunk is done but failed.
   */
  FAILED: 3,
  /**
   * Chunk is already in queue
   */
  QUEUED: 4,
}

/**
 * Converts a `ChunkList` to a `TransferChunkList` by appending the `PENDING` transfer
 * status to all chunks and initializing additional properties.
 * @param list The `ChunkList` which should be converted to a `TransferChunkList`.
 * @return the converted list of chunks.
 */
function amendTransferStatus (list) {
  return {
    ...list,
    created: new Date(),
    chunks: list.chunks.map(chunk => ({
      ...chunk,
      transferStatus: TransferStatus.PENDING,
      retries: 0,
    })),
  }
}

/**
 * Returns the next chunk which qualifies for uploading from a `TransferChunkList`.
 * Takes the transfer status as well as the amount of retries into account.
 * @param list The list of chunks in which to look for a chunk to upload.
 * @return The chunk which should be uploaded next, or undefined if there is no such chunk.
 */
function findNextChunk (list, maxRetries) {
  const indexOfChunk = list.chunks.findIndex(chunk => {
    const { transferStatus, retries } = chunk
    return (transferStatus === TransferStatus.FAILED && retries < maxRetries) ||
      transferStatus === TransferStatus.PENDING
  })
  return indexOfChunk >= 0 ? list.chunks[indexOfChunk] : null
}

function findQueuedChunks (list) {
  return list.chunks.filter(chunk => chunk.transferStatus === TransferStatus.QUEUED)
}

/**
 * Checks whether the transfer of all chunks was successful and emits the corresponding event.
 * @param list List of chunks to check the status of.
 * @param emitter `EventEmitter` to emit the 'done' event with the corresponding status on.
 */
function emitResult (list, emitter, startDate) {
  const failedChunks = list.chunks.filter(chunk => chunk.transferStatus === TransferStatus.FAILED)
  const okay = failedChunks.length <= 0
  emitter.emit('done', { okay, startDate, failedChunks: failedChunks.map(chunk => chunk.id) })
}

const countFailedChunks = list => {
  return list.chunks.reduce((amount, currentChunk) => {
    if (currentChunk.transferStatus === TransferStatus.FAILED) {
      return amount + 1
    }
    return amount
  }, 0)
}
const countDoneChunks = list => {
  return list.chunks.reduce((amount, currentChunk) => {
    if (currentChunk.transferStatus === TransferStatus.SUCCESS) {
      return amount + 1
    }
    return amount
  }, 0)
}

/**
 * Processes on chunk by slicing the file and sending data to the endpoint
 * Also
 */
function processChunk (chunk, list, file, emitter, presignedUrl) {
  chunk.transferStatus = TransferStatus.QUEUED
  return new Promise((resolve, reject) => {
    const data = file.slice(chunk.startByte, chunk.startByte + chunk.size)
    const startDate = new Date()
    const t0 = performance.now()
    const request = new XMLHttpRequest()
    // request.open('PUT', url.replace(/\{chunkId\}/, `${chunk.id}`), true)
    request.open('PUT', presignedUrl, true)
    request.timeout = 10 * 60 * 1000 // 10 minutes timeout
    // request.setRequestHeader('accept', 'application/json')
    // request.setRequestHeader('content-type', 'application/json')
    // request.setRequestHeader('Authorization', `Bearer ${authToken}`)
    // This event handler is called multiple times during the request and (if the browser supports it)
    // provides the progress of the upload in the form of the amount of the already transmitted bytes.
    request.onprogress = event => {
      // Set the status of the chunk to `IN_PROGRESS`.
      chunk.transferStatus = TransferStatus.IN_PROGRESS
      // If the progress is supported by the browser, a 'progress' event can be emitted.
      if (event.lengthComputable) {
        const progress = event.loaded / event.total
        emitter.emit('progress', {
          progress,
          chunk,
        })
      }
    }
    // This handler will be called when an error occured during the request.
    const errorHandler = response => {
      chunk.transferStatus = TransferStatus.FAILED
      const status = request.status
      // If we met 500 error we should stop making the requests for now.
      // TODO: Fix
      if (status === 500) {
        resolve({ okay: false, status, id: chunk.id })
        emitter.emit('server-error', { chunk, status, startDate })
        return
      }
      // This will happen if presigned url is expired
      if (status === 403) {
        resolve({ okay: false, status, id: chunk.id })
        return
      }
      // Increase the amount of retries and set the status of the chunk to failed.
      console.error('ERROR WHILE UPLOADING FILE OCURRED')
      console.error('TRIES = ', chunk.retries)
      chunk.retries++
      const total = list.chunks.length
      const failed = countFailedChunks(list)
      const done = countDoneChunks(list)
      // Emit the error together with the chunk.
      emitter.emit(
        'error',
        {
          chunk,
          total,
          done,
          failed,
          startDate,
          status,
          message: 'URL = ' + presignedUrl + '. Error: ' +
            request.statusText + '; ' +
            request.responseText + '; ',
        })
      // Try the next chunk.
      // toast.error('Can\'t upload file')
      resolve({ okay: false, status, id: chunk.id })
    }
    // Called when the request is done (read: The chunk was uploaded completely).
    // This will not be called when the server responds with a non-200 status code,
    // instead the `onerror` handler will be called then.
    // Sets the transfer status to `SUCCESS`, and continues with the next chunk.
    const onLoadHandler = response => {
      const t1 = performance.now()
      // Request HTTP status code was not `200 OK`.
      if (request.status !== 200) {
        errorHandler(response)
        return
      }
      chunk.transferStatus = TransferStatus.SUCCESS
      const total = list.chunks.length
      const failed = countFailedChunks(list)
      const done = countDoneChunks(list)
      emitter.emit('uploaded', { chunk, total, done, failed, startDate })
      resolve({ okay: true, id: chunk.id, duration: (t1 - t0) / 1000 })
      // Continue.
      // setTimeout(() => consumeChunks(file, list, emitter, incomeEmitter, options), 0)
    }
    request.onerror = errorHandler
    request.onload = onLoadHandler
    request.ontimeout = response => {
      // Request HTTP status code was not `200 OK`.
      if (request.status !== 200) {
        errorHandler(response)
      } else {
        onLoadHandler(response)
      }
    }
    // The body as transmitted to the server.
    // Start the request.
    emitter.emit('start-upload', { chunk })
    request.send(data)
  })
}

/**
 * Processes a list of chunks and uploads them all to a given Url. The list will be mutated by this function
 * as the resulting transfer status will be appended to each chunk. Occuring events will be emitted on the
 * provided `EventEmitter`.
 * @param list The list of chunks that should be transmitted.
 * @param url Url pattern to which the chunks should be uploaded. It is possible to include `{chunkId}` inside the
 *  url pattern, which will be replaced by the chunk's unique id.
 * @param authToken An authentication token which will be sent in the headers of the request as a Bearer authentication
 *  token along with the body.
 * @param file The file to which the chunk list belongs. Will be read during the process in order to transmit it.
 * @param emitter The `EventEmitter` on which all occuring events will be emitted.
 */
async function consumeChunks (
  file,
  list,
  emitter,
  startDate,
  options,
  chunks,
) {
  const {
    maxRetries,
    getPresignedUrls,
    presignedUrls,
    dataFileId,
    artifactId,
    logDataFile,
    incomeEmitter,
  } = options
  if (!presignedUrls) {
    if (options.getPresignedUrlsRetries >= 100) {
      emitResult(list, emitter, startDate)
      logDataFile({
        level: 'error',
        action: 'PRESIGNED_URL/GET/ERROR',
        artifactId,
        dataFileId,
        message: 'Can\'t get presigned URLs!',
      })
      return
    }
    try {
      const newPresignedUrls = await getPresignedUrls()
      if (!newPresignedUrls) {
        throw new Error('Presigned URLs is empty')
      }
      logDataFile({
        level: 'info',
        action: 'PRESIGNED_URL/GET/DONE',
        artifactId,
        dataFileId,
        message: 'Got presigned urls for data file chunks.' +
          Object.keys(newPresignedUrls).reduce((all, chunkId) => all + `; ${chunkId} ${newPresignedUrls[chunkId]}`, ''),
      })
      options.presignedUrls = newPresignedUrls
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        consumeChunks(file, list, emitter, startDate, options, chunks)
      }, 0)
    } catch (e) {
      logDataFile({
        level: 'error',
        action: 'PRESIGNED_URL/GET/ERROR',
        artifactId,
        dataFileId,
        message: 'Error while getting presigned URLs. ' + e.toString(),
      })
      options.getPresignedUrlsRetries = (options.getPresignedUrlsRetries || 0) + 1
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        consumeChunks(file, list, emitter, startDate, options, chunks)
      }, 1000)
    }
    return
  }
  const queuedChunks = findQueuedChunks(list)
  // If we already have some chunks in queue and have no space to add more items - we can skip
  if (queuedChunks.length <= 0 && !findNextChunk(list, maxRetries) && !options.resultEmitted) {
    emitResult(list, emitter, startDate)
    options.resultEmitted = true
    return
  }

  if (chunks.length > 0 && !incomeEmitter.cancelUpload) {
    if (incomeEmitter) {
      incomeEmitter.removeAllListeners()
      incomeEmitter.on('cancel', async event => {
        // When cancel action comes we need to stop to make all the requests and notify emitter
        if (!incomeEmitter.cancelUpload) {
          incomeEmitter.cancelUpload = true
          emitter.emit('cancel', { file })
        }
        // incomeEmitter = undefined;
      })
    }
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i]
      processChunk(
        chunk,
        list,
        file,
        emitter,
        presignedUrls[chunk.id],
        dataFileId,
        options,
      ).then(result => {
        if (!result.okay && result.status === 403) {
          logDataFile({
            level: 'error',
            action: 'PRESIGNED_URL/EXPIRED',
            artifactId,
            dataFileId,
            message: 'Presigned URL has been expired',
          })
          if (options.presignedUrls) {
            options.presignedUrls = undefined
            const chunksToSend = getNextUploadableChunks(list, options.chunksToSendInParallel, options.maxRetries)
            const timeoutId = setTimeout(() => {
              clearTimeout(timeoutId)
              consumeChunks(file, list, emitter, startDate, options, chunksToSend)
            }, 0)
            return
          }
        }
        const chunk = findNextChunk(list, maxRetries)
        if (chunk) {
          chunk.transferStatus = TransferStatus.QUEUED
          const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId)
            consumeChunks(file, list, emitter, startDate, options, [chunk])
          }, 0)
        } else {
          if (!options.resultEmitted) {
            emitResult(list, emitter, startDate)
            options.resultEmitted = true
          }
        }
      })
    }
  }
}

function getNextUploadableChunks (list, chunksToSendInParallel, maxRetries) {
  const chunksToSend = []
  for (let i = 0; i < chunksToSendInParallel; i++) {
    const chunk = findNextChunk(list, maxRetries)
    if (chunk) {
      chunk.transferStatus = TransferStatus.QUEUED
      chunksToSend.push(chunk)
    }
  }
  return chunksToSend
}

/**
 * Performs the upload of a list of chunks to a specified Url.
 * @param list The list of chunks as generated by `prepareFile()` that should be transmitted.
 * @param url Url pattern to which the chunks should be uploaded. It is possible to include `{chunkId}` inside the
 *  url pattern, which will be replaced by the chunks unique id.
 * @param authToken An authentication token which will be sent in the headers of the request as a Bearer authentication
 *  token along with the body.
 * @param file The file to which the chunk list belongs. Will be read during the process in order to transmit it.
 * @return `EventEmitter` with the following events:
 *  - 'done': Emitted when the transfer of all chunks is done.
 *  - 'error': Emitted when an error occured while reading the file or during a request to the backend.
 *  - 'progress': Emitted constantly during a request. Contains the current progress of uploading a chunk.
 *  - 'uploaded': Emitted whenever a single chunk was uploaded completely.
 */
export function upload (
  file,
  list,
  options,
) {
  const emitter = new EventEmitter()
  const startDate = new Date()
  const transferList = amendTransferStatus(list)
  // const chunkStatusMap = list.chunks.reduce((all, chunk) => ({ ...all, [chunk.id]: false }), {})
  // Find the next transferrable chunks.
  const chunksToSend = getNextUploadableChunks(transferList, options.chunksToSendInParallel, options.maxRetries)
  consumeChunks(
    file,
    transferList,
    emitter,
    startDate,
    options,
    chunksToSend,
  )
  return emitter
}
