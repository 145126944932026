import { omit } from 'ramda'
// Project deps
import { getRecommendedCRS } from 'modules/importWizard/selectors'
import { getCurrentProject } from 'modules/projects/selectors'
import { getGeoids, getCoordinateSystems, getCompoundCoordinateSystems, getVerticalCoordinateSystems, getHorizontalCoordinateSystems } from 'modules/app/selectors'
// Local deps
import { DataType } from 'types/form'
import { FieldTemplates, getTabsTemplate } from './fields'
import { CRSFields } from './constants'
import { coordinateSystemFieldDisabled } from '../utils'
import { isCompoundGeocentricCRS, getBackendString, isCRSFieldCompound } from 'utils/coordinateSystem'
import {
  getCompoundCRSGrid,
  disableHUnits,
  getCompoundCRSVUnit,
  getGeoidInitialValue,
  getHorizontalCRSInitialValue,
  getVerticalCRSInitialValue,
  getUnitVInitialValue,
  getUnitHInitialValue,
  unitBackendTransform,
  getNormalizeAxisOrderInitialValue,
  getCustomTabTemplateFields,
  getCRSTabTemplateFields,
  getCompoundTabTemplateFields,
  getCompoundCRSInitialValue,
} from './utils'
import { GCP_BACKEND_NAME, MapBackendNameToFrontend } from 'templates/constants'
import { getProjectCRSInitialValues } from './ProjectCRS/utils'
import { getBackendTransformedValues } from 'utils/templates'
import { ProjectCRSTemplateInfo } from './ProjectCRS/ProjectCRSTemplateInfo'
import { getGeoidCRSSource } from 'utils/geoid'

export const getGCPRecommendedCRSId = (state, extra) => getCurrentProject(state).projectId + '-' + extra

const getCompoundCRSOptions = (state, extra, values) => {
  const gcpRecommendedCRSId = getGCPRecommendedCRSId(state, extra)
  const recommendedCRS = getRecommendedCRS(state, gcpRecommendedCRSId) || {}
  const recommendedCompoundCRS = recommendedCRS.compoundCRS || []
  const coordinateSystems = getCompoundCoordinateSystems(state)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (filterMode) {
    if (recommendedCompoundCRS.length > 0) return recommendedCompoundCRS
  }
  return coordinateSystems
}

const getVerticalCRSOptions = (state, extra, values) => {
  const gcpRecommendedCRSId = getGCPRecommendedCRSId(state, extra)
  const recommendedCRS = getRecommendedCRS(state, gcpRecommendedCRSId) || {}
  const recommendedVerticalCRS = recommendedCRS.verticalCRS || []
  const coordinateSystems = getVerticalCoordinateSystems(state)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (filterMode) {
    if (recommendedVerticalCRS.length > 0) return recommendedVerticalCRS
  }
  return coordinateSystems
}

const getHorizontalCRSOptions = (state, extra, values) => {
  const gcpRecommendedCRSId = getGCPRecommendedCRSId(state, extra)
  const recommendedCRS = getRecommendedCRS(state, gcpRecommendedCRSId) || {}
  const recommendedHorizontalCRS = recommendedCRS.horizontalCRS || []
  const coordinateSystems = getHorizontalCoordinateSystems(state)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (filterMode) {
    if (recommendedHorizontalCRS.length > 0) return recommendedHorizontalCRS
  }
  return coordinateSystems
}

const getGeoidOptions = (state, extra, values) => {
  const gcpRecommendedCRSId = getGCPRecommendedCRSId(state, extra)
  const recommendedCRS = getRecommendedCRS(state, gcpRecommendedCRSId) || {}
  const recommendedGeoids = recommendedCRS.verticalGeoids || []
  const geoids = getGeoids(state)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (filterMode) {
    if (recommendedGeoids.length > 0) return recommendedGeoids
  }
  return geoids
}

const getHorizontalCRS = (state, extra, values) => {
  const gcpRecommendedCRSId = getGCPRecommendedCRSId(state, extra)
  const recommendedCRS = getRecommendedCRS(state, gcpRecommendedCRSId) || {}
  const initialHorizontalCRS = recommendedCRS.initialHorizontalCRS
  return initialHorizontalCRS
    ? getCoordinateSystems(state).find(crs => `${crs.code}` === `${initialHorizontalCRS.code}`)
    : null
}

const getVerticalCRS = (state, extra, values) => {
  const gcpRecommendedCRSId = getGCPRecommendedCRSId(state, extra)
  const recommendedCRS = getRecommendedCRS(state, gcpRecommendedCRSId) || {}
  const initialVerticalCRS = recommendedCRS.initialVerticalCRS
  return initialVerticalCRS
    ? getCoordinateSystems(state).find(crs => `${crs.code}` === `${initialVerticalCRS.code}`)
    : null
}

const GCPFieldsTemplatesBasic = Object.keys(FieldTemplates).reduce((allFields, key) => {
  return {
    ...allFields,
    [key]: {
      ...FieldTemplates[key],
      backendName: GCP_BACKEND_NAME,
    },
  }
}, {})

const GCPFieldsTemplates = {
  ...GCPFieldsTemplatesBasic,
  [CRSFields.NORMALIZE_AXIS_ORDER]: {
    ...GCPFieldsTemplatesBasic[CRSFields.NORMALIZE_AXIS_ORDER],
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { projectCRS } = extraProps
      if (projectCRS) {
        const normalizeAxisOrder = getNormalizeAxisOrderInitialValue(state, projectCRS)
        return normalizeAxisOrder
      }
      return false
    },
  },
  /*
  [CRSFields.IS_ELLIPSOIDAL]: {
    ...GCPFieldsTemplatesBasic[CRSFields.IS_ELLIPSOIDAL],
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { projectCRS } = extraProps
      if (projectCRS) {
        const isEllipsoidal = getIsEllipsoidalInitialValue(state, projectCRS)
        return isEllipsoidal
      }
      return false
    },
  },
  */
  [CRSFields.GEOID_CRS]: {
    ...GCPFieldsTemplatesBasic[CRSFields.GEOID_CRS],
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { projectCRS } = extraProps
      if (projectCRS) {
        const geoid = getGeoidInitialValue(state, projectCRS)
        if (geoid) {
          return getBackendString(getGeoidCRSSource(geoid))
        }
      }
      return null
    },
  },
  [CRSFields.GRID]: {
    ...GCPFieldsTemplatesBasic[CRSFields.GRID],
    options: (state, values, extra) => getGeoidOptions(state, extra, values),
    initialValue: (state, extra, { extraProps }) => {
      const { projectCRS } = extraProps
      if (projectCRS) {
        return getGeoidInitialValue(state, projectCRS)
      }
      return null
    },
  },
  [CRSFields.C_CRS]: {
    ...GCPFieldsTemplatesBasic[CRSFields.C_CRS],
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { projectCRS } = extraProps
      if (projectCRS && projectCRS[CRSFields.C_CRS]) {
        const crs = projectCRS[CRSFields.C_CRS]
        if (isCRSFieldCompound(crs)) {
          return getCompoundCRSInitialValue(state, projectCRS)
        }
        return null
      }
      return null
    },
    options: (state, values, extra) => getCompoundCRSOptions(state, extra, values),
  },
  [CRSFields.H_CRS]: {
    ...GCPFieldsTemplatesBasic[CRSFields.H_CRS],
    options: (state, values, extra) => getHorizontalCRSOptions(state, extra, values),
    initialValue: (state, extra, { extraProps, values }) => {
      const { projectCRS } = extraProps
      if (projectCRS) {
        return getHorizontalCRSInitialValue(state, projectCRS)
      }
      return getHorizontalCRS(state, extra, values)
    },
  },
  [CRSFields.V_CRS]: {
    ...GCPFieldsTemplatesBasic[CRSFields.V_CRS],
    options: (state, values, extra) => getVerticalCRSOptions(state, extra, values),
    initialValue: (state, extra, { extraProps, values }) => {
      const { projectCRS } = extraProps
      if (projectCRS) {
        return getVerticalCRSInitialValue(state, projectCRS)
      }
      return getVerticalCRS(state, extra, values)
    },
  },
  [CRSFields.H_UNITS]: {
    ...GCPFieldsTemplatesBasic[CRSFields.H_UNITS],
    initialValue: (state, extra, { extraProps, values }) => {
      const { projectCRS } = extraProps
      if (projectCRS) {
        return getUnitHInitialValue(state, projectCRS)
      }
      return null
    },
  },
  [CRSFields.V_UNITS]: {
    ...GCPFieldsTemplatesBasic[CRSFields.V_UNITS],
    initialValue: (state, extra, { extraProps, values }) => {
      const { projectCRS } = extraProps
      if (projectCRS) {
        return getUnitVInitialValue(state, projectCRS)
      }
      return null
    },
  },
  [CRSFields.CUSTOM_CRS]: {
    ...GCPFieldsTemplatesBasic[CRSFields.CUSTOM_CRS],
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { projectCRS } = extraProps
      if (projectCRS && projectCRS[CRSFields.C_CRS]) {
        const crs = projectCRS[CRSFields.C_CRS]
        if (!isCRSFieldCompound(crs)) {
          return crs
        }
        return ''
      }
      return ''
    },
  },
}

// Template for compound and geographic 3D tab
export const compoundTemplate = {
  ...getCompoundTabTemplateFields(GCPFieldsTemplates),
  [CRSFields.GRID]: {
    ...GCPFieldsTemplates[CRSFields.GRID],
    backendTransform: getCompoundCRSGrid,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  [CRSFields.C_CRS]: {
    ...GCPFieldsTemplates[CRSFields.C_CRS],
  },
  [CRSFields.H_UNITS]: {
    ...GCPFieldsTemplates[CRSFields.H_UNITS],
    disabled: (state, values, extra, formTemplate) => coordinateSystemFieldDisabled(state, values) ||
      disableHUnits(CRSFields.C_CRS, state, values, formTemplate),
    altOption: (state, values, formTemplate, option) => {
      const alternativeOption = {
        ...GCPFieldsTemplates[CRSFields.H_UNITS],
        name: MapBackendNameToFrontend.unit,
        gridProps: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
      }
      return isCompoundGeocentricCRS(state, values) ? alternativeOption : option
    },
  },
  [CRSFields.V_UNITS]: {
    ...GCPFieldsTemplates[CRSFields.V_UNITS],
    invisibleForTab: (state, values) => isCompoundGeocentricCRS(state, values),
    backendTransform: (original, state, values) => unitBackendTransform(getCompoundCRSVUnit(state, values)),
    /*
    optional: (state, values, extra) => isCRSFieldOptional(state, values, extra, 0) &&
      isCRSFieldOptional(state, values, extra, 1),
    */
  },
}

// Template for user-defined compound CRS tab
export const crsTemplate = {
  ...getCRSTabTemplateFields(GCPFieldsTemplates),
  [CRSFields.GRID]: {
    ...GCPFieldsTemplates[CRSFields.GRID],
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  [CRSFields.H_CRS]: {
    ...GCPFieldsTemplates[CRSFields.H_CRS],
  },
  [CRSFields.H_UNITS]: {
    ...GCPFieldsTemplates[CRSFields.H_UNITS],
    disabled: (state, values, extra, formTemplate) => coordinateSystemFieldDisabled(state, values) ||
      disableHUnits(CRSFields.H_CRS, state, values, formTemplate),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  [CRSFields.V_CRS]: {
    ...GCPFieldsTemplates[CRSFields.V_CRS],
  },
  [CRSFields.V_UNITS]: {
    ...GCPFieldsTemplates[CRSFields.V_UNITS],
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
}

// Template for proj/wkt/json string tab
export const customCRSTemplate = {
  ...getCustomTabTemplateFields(GCPFieldsTemplates),
  [CRSFields.CUSTOM_CRS]: {
    ...GCPFieldsTemplates[CRSFields.CUSTOM_CRS],
  },
}

const tabsTemplate = getTabsTemplate({
  compoundTemplate,
  crsTemplate,
  customCRSTemplate,
})

export const getCRSTemplate = (tabsTemplates, template, fieldTemplates = FieldTemplates) => ({
  ...template,
  [CRSFields.TAB]: {
    ...fieldTemplates[CRSFields.TAB],
    tabs: {
      ...tabsTemplates,
    },
  },
  [CRSFields.LAST_CHANGED_TAB]: {
    dataType: DataType.LAST_CHANGED_TAB,
    invisible: true,
    initialValue: 1,
  },
  [CRSFields.TABS_TEMPLATE]: {
    invisible: true,
    initialValue: tabsTemplates,
    dataType: DataType.TABS_TEMPLATE,
  },
  [CRSFields.COORDINATES_CHOOSER]: {
    ...fieldTemplates[CRSFields.COORDINATES_CHOOSER],
  },
})

// Basic template for CRS component
export const CRSTemplate = getCRSTemplate(tabsTemplate, {
  ...compoundTemplate,
  ...crsTemplate,
  ...customCRSTemplate,
})

export const GCPCRSTemplate = {
  [GCP_BACKEND_NAME]: {
    name: 'CRS',
    dataType: DataType.CRS,
    refStation: true,
    optional: false,
    invisible: false,
    initialValue: (state, values, { extraProps }) => {
      const { projectCRS } = extraProps
      if (projectCRS) {
        const crs = getProjectCRSInitialValues(state, projectCRS)
        return crs
      }
      return null
    },
    backendTransform: (original, state, values) => {
      return omit(['name'], getBackendTransformedValues(state, original, original, ProjectCRSTemplateInfo))
    },
  },
  /*
  [CRSFields.FILTER_MODE]: {
    name: 'Show only recommended coordinate reference systems',
    dataType: DataType.BOOLEAN,
    initialValue: true,
    sendToBackend: false,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  ...CRSTemplate,
  cesiumMap: {
    dataType: DataType.CESIUM_MAP,
    props: {
      height: '100%',
      width: '100%',
      type: 'gcp',
    },
    getValue: (state, value, values, extra, extraProps) => {
      const { columnAssignments = {}, data = [], startIndex } = extraProps
      const { name: nameIndex } = columnAssignments
      const points = getGCPsForArtifact(state, extra) || []
      return points.reduce((allRows, row, index) => {
        const originalDataItem = data[startIndex + index] || {}
        const name = typeof nameIndex === 'number' && originalDataItem[nameIndex]
        const rowX = (row[FrontendPositionCoordinates.LONGITUDE].data || row[FrontendPositionCoordinates.LONGITUDE])
        const rowY = (row[FrontendPositionCoordinates.LATITUDE].data || row[FrontendPositionCoordinates.LATITUDE])
        const rowZ = (row[FrontendPositionCoordinates.ALTITUDE].data || row[FrontendPositionCoordinates.ALTITUDE])
        return [
          ...allRows,
          {
            coordinates: {
              lon: rowX,
              lat: rowY,
              height: rowZ,
            },
            name: name || `GCP ${index}`,
            description: '',
            index: startIndex + index,
          },
        ]
      }, [])
    },
  },
  */
}

/*
  (state, values, extra, fieldOptions) => {
    const allVerticalSystems = getCoordinateSystems(state).filter(isVerticalCoordinateSystem)
    const horizontalCrs = values[CRSFields.H_CRS]
    const verticalCrs   = values[CRSFields.V_CRS]
    const horizontalCrsValue = getCRSValue(state, horizontalCrs) || {}
    const { area_bounds: hCrsAreaBounds = [] } = horizontalCrsValue
    const x = +getGcpCoordinate(state, 'lon')
    const y = +getGcpCoordinate(state, 'lat')
    const isPointLieInHorizontalCRS = isCoordinateLieInRectangle(x, y, ...hCrsAreaBounds)
    const positiveAreas = allVerticalSystems.filter(vCrs => {
      const { area_bounds: vCrsAreaBounds = [] } = vCrs
      const overlapArea = getCRSOverlapArea(
        ...hCrsAreaBounds,
        ...vCrsAreaBounds
      )
      return overlapArea > 0
        ? typeof x === 'number' && typeof y === 'number' ?
          isPointLieInHorizontalCRS && isCoordinateLieInRectangle(x, y, ...vCrsAreaBounds)
          : true
        : false
    })
    const listOfOptions = (horizontalCrsValue ? positiveAreas : allVerticalSystems)
      .map(crs => getCoordinateSystemLabel(crs))
    const { setValue, option, name } = fieldOptions
    if (listOfOptions.indexOf(verticalCrs) < 0) {
      const [firstListItem] = listOfOptions
      if (listOfOptions.length > 0 && firstListItem !== verticalCrs) {
        setValue(name, firstListItem, option)
      } else {
        if (verticalCrs !== null) {
          setValue(name, null, option)
        }
      }
    }
    return listOfOptions
  }
*/
