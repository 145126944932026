import moment from 'moment'
import { isEmpty, isNil } from 'ramda'
import { LAS_BACKEND_NAME } from 'templates/constants'
import { SECrsFieldNamesMap } from 'types/coordinateReferenceSystem'
import { getNumberOfWeeks } from 'utils/dateTime'
import { GpsStandardTime } from 'utils/time'
import { keepProps } from 'utils/dict'
import { getFileFromDropbox } from './utils'
import { getCRSExtendedInfo } from 'modules/crs/api'
import { isFileNameEndsWith } from 'utils/baseName'

const headerSize = 375

/**
 * Checks whether the file name of a file looks like a las/laz file name.
 * This is done by looking at the extension and checking whether it is '.las' | '.laz'.
 */
export function isLasFileName (fileName) {
  const lasExtensions = ['.las', '.laz']
  return lasExtensions.some(lasExtension => isFileNameEndsWith(fileName, lasExtension))
}

export function isLasFileType (fileType) {
  return fileType === 'las'
}

export const LasTimeFormat = {
  None: 'none',
  GpsStandardTime: 'standard',
  GpsStandardTimeAdjusted: 'adjusted',
  TimeOfWeek: 'week',
  SecondsIntoUtcDay: 'day',
  GpsUsecs: 'microseconds',
}

export const PrettyLasTimeFormat = {
  none: 'No Time / Zero',
  standard: 'GPS Standard Time',
  adjusted: 'GPS Standard Time (Adjusted)',
  week: 'Time of Week (seconds)',
  day: 'Seconds into UTC day',
  microseconds: 'GPS Microseconds',
}

export const MapPrettyLasTimeFormatToTimeFormat = Object.keys(PrettyLasTimeFormat).reduce((all, key) => ({
  ...all,
  [PrettyLasTimeFormat[key]]: key,
}), {})

async function getVLRContentAfterHeader (file, lastByte, vlr, blob, fromDropbox) {
  return new Promise(async resolve => {
    let fileToUse = file
    let currentByte = lastByte
    const recordLengthAfterHeader = vlr.recordLengthAfterHeader
    const extraInfoStart = currentByte
    const extraInfoEnd = extraInfoStart + recordLengthAfterHeader
    const contentLength = extraInfoEnd - extraInfoStart

    if (fromDropbox) {
      fileToUse = await getLasFileFromDropbox(blob, extraInfoStart, extraInfoEnd)
    }

    const vlrExtraInfoFileReader = new FileReader()
    vlrExtraInfoFileReader.onload = () => {
      const buffer = vlrExtraInfoFileReader.result
      currentByte += recordLengthAfterHeader
      resolve({
        currentByte,
        content: uint8arrayToString(new Uint8Array(buffer, 0, contentLength)),
      })
    }

    vlrExtraInfoFileReader.onerror = () => {}
    vlrExtraInfoFileReader.readAsArrayBuffer(fromDropbox ? fileToUse : fileToUse.slice(extraInfoStart, extraInfoEnd))
  })
}

const VLRStructure = [
  { name: 'reserved', size: 2, data: (view, buffer, currentByte, size) => view.getUint16(currentByte, true) },
  { name: 'userId', size: 16, data: (view, buffer, currentByte, size) => uint8arrayToString(new Uint8Array(buffer, currentByte, size)) },
  { name: 'recordId', size: 2, data: (view, buffer, currentByte, size) => view.getUint16(currentByte, true) },
  { name: 'recordLengthAfterHeader', size: 2, data: (view, buffer, currentByte, size) => view.getUint16(currentByte, true) },
  { name: 'description', size: 32, data: (view, buffer, currentByte, size) => uint8arrayToString(new Uint8Array(buffer, currentByte, size)) },
]

const VLRUserIdToGet = [
  'CRS_DEFINITIONS',
  'LASF_Projection',
  'TIME_FORMAT',
]

async function getNextVLR (file, lastByte, blob, fromDropbox) {
  return new Promise(async resolve => {
    let currentByte = lastByte
    const vlr = {}
    let fileToUse = file
    if (fromDropbox) {
      fileToUse = await getLasFileFromDropbox(blob, currentByte, currentByte + 54)
    }
    const vlrFileReader = new FileReader()
    vlrFileReader.onload = async () => {
      const buffer = vlrFileReader.result
      const view = new DataView(buffer)
      let localCurrentByte = 0
      VLRStructure.forEach(field => {
        const { name, size, data } = field
        vlr[name] = data(view, buffer, localCurrentByte, size)
        localCurrentByte += size
        currentByte += size
      })

      // Get VLR content only for few userIds to speed up the processing
      if (VLRUserIdToGet.includes(vlr.userId)) {
        const { content } = await getVLRContentAfterHeader(file, currentByte, vlr, blob, fromDropbox)
        currentByte += vlr.recordLengthAfterHeader
        vlr.value = content
        resolve({ vlr, currentByte })
      } else {
        currentByte += vlr.recordLengthAfterHeader
        resolve({ vlr, currentByte })
      }
    }
    vlrFileReader.onerror = () => {}
    vlrFileReader.readAsArrayBuffer(fromDropbox ? fileToUse : fileToUse.slice(currentByte, currentByte + 54))
  })
}

/**
 * **This function does not really parse any information from the ".las" file**. It is just named like this
 * in order to stay consistent with the naming for other file formats.
 * @param file The file which should be checked.
 * @return An object containing information about whether the file is a valid nav file, a log about the
 *   parsing and the information read from the header.
 */
// function parseNavFile(file: File): Promise<GenericParserInfo>
export async function parseLasFile (file, blob, fromDropbox) {
  const fileToUse = fromDropbox ? blob : file
  return new Promise(resolve => {
    const log = []
    if (!isLasFileName(file.name)) {
      log.push({
        level: 'warning',
        message: `Unsupported file extension in filename "${file.name}"`,
      })
    }
    if (file.size === 0) {
      log.push({ level: 'error', message: 'Invalid file size. File is empty.' })
      resolve({ okay: false, log, fileType: 'las' })
      return
    }
    const fileReader = new FileReader()
    fileReader.onload = async () => {
      // ArrayBuffer
      const buffer = fileReader.result
      const view = new DataView(buffer)
      const GlobalEncodingBitmask = view.getUint16(6, true)
      const TimeFormat = GlobalEncodingBitmask & 1 ? LasTimeFormat.GpsStandardTimeAdjusted : LasTimeFormat.TimeOfWeek
      const CreationDay = view.getUint16(90, true)
      const CreationYear = view.getUint16(92, true)
      let Time = moment.utc([CreationYear, 0, 1, 0]).add(CreationDay, 'days')
      const fileName = file.name
      if (/\b\d{8}-\d{1,8}\b/.test(fileName)) {
        const year = +fileName.slice(0, 4)
        const month = +fileName.slice(4, 6)
        const day = +fileName.slice(6, 8)
        const tow = +fileName.split('-')[1].split('.')[0]
        const week = getNumberOfWeeks(moment(`${year}-${month}-${day}`).toDate())
        Time = GpsStandardTime.clone().add(week, 'weeks').add(tow, 'seconds')
      }
      const header = {
        FileSignature: uint8arrayToString(new Uint8Array(buffer, 0, 4)),
        FileSourceID: view.getUint16(4, true),
        GlobalEncoding: GlobalEncodingBitmask,
        TimeFormat: TimeFormat,
        Time: Time,
        ProjectID1: 'not-used', // 4
        ProjectID2: 'not-used', // 2
        ProjectID3: 'not-used', // 2
        ProjectID4: 'not-used', // 8
        VersionMajor: view.getUint8(24),
        VersionMinor: view.getUint8(25),
        SystemIdentifier: uint8arrayToString(new Uint8Array(buffer, 26, 58)),
        GeneratingSoftware: uint8arrayToString(new Uint8Array(buffer, 58, 90)),
        CreationDay: CreationDay,
        CreationYear: CreationYear,
        HeaderSize: view.getUint16(94, true),
        OffsetToPointData: view.getUint32(96, true),
        NumberOfVariableLengthRecords: view.getUint32(100, true),
        PointDataFormatID: view.getUint8(104),
        PointDataRecordLength: view.getUint16(105, true),
        NumberOfPoints: view.getUint32(107, true),
        NumberOfPointByReturn: view.getUint32(107, true),
        ScaleFactor: [view.getFloat64(131, true), view.getFloat64(139, true), view.getFloat64(147, true)],
        Offset: [view.getFloat64(155, true), view.getFloat64(163, true), view.getFloat64(171, true)],
        max: [view.getFloat64(179, true), view.getFloat64(195, true), view.getFloat64(211, true)],
        min: [view.getFloat64(187, true), view.getFloat64(203, true), view.getFloat64(219, true)],
      }

      const variableRecords = []
      let variableLengthRecords = header.NumberOfVariableLengthRecords
      let currentByte = header.HeaderSize
      const foundUserIds = VLRUserIdToGet.reduce((all, key) => ({
        ...all,
        [key]: false,
      }), {})
      while (variableLengthRecords-- && !VLRUserIdToGet.every(userId => foundUserIds[userId])) {
        const { vlr, currentByte: lastByte } = await getNextVLR(fileToUse, currentByte, file, fromDropbox)
        currentByte += (lastByte - currentByte)
        foundUserIds[vlr.userId] = true
        variableRecords.push(vlr)
      }
      const result = {
        header,
        variableRecords,
      }
      let crs
      const crsDefinitions = variableRecords.find(record => record.userId === 'CRS_DEFINITIONS')
      const lasProjection = variableRecords.find(record => record.userId === 'LASF_Projection')
      const timeFormat = variableRecords.find(record => record.userId === 'TIME_FORMAT')
      if (timeFormat) {
        header.TimeFormat = MapPrettyLasTimeFormatToTimeFormat[timeFormat.value]
      }
      if (crsDefinitions && crsDefinitions.value) {
        const crsJSON = JSON.parse(crsDefinitions.value)
        crs = Object.keys(crsJSON).reduce((allValues, key) => ({
          ...allValues,
          [SECrsFieldNamesMap[key]]: crsJSON[key],
        }), {})
      } else if (lasProjection && lasProjection.value) {
        crs = {
          crs: lasProjection.value,
        }
      }
      // Sometimes we have crappy lasf_projection with geotiff descriptions
      // To be sure everything is okay - use backend to validate crs
      const checkCRSResult = await getCRSExtendedInfo(crs)
      // Everything is okay, we can use provided crs and also can a datum from response
      if (checkCRSResult.okay) {
        // const datum = checkCRSResult.crs.datum
        resolve({
          result,
          okay: true,
          log,
          fileType: 'las',
          [LAS_BACKEND_NAME]: keepProps([
            'crs',
            'crs_h',
            'crs_v',
            'datum',
            'geoid',
            'geoid_crs',
            'is_ellipsoidal',
            'normalize_axis_order',
            'unit_h',
            'unit_v',
          ], checkCRSResult.crs),
        })
      } else {
        // Something wrong with crs.
        // Set to undefined to autopopulate it further (or manully change by user)
        resolve({ result, okay: true, log, fileType: 'las', [LAS_BACKEND_NAME]: undefined })
      }
    }
    fileReader.onerror = () => {
      log.push({
        level: 'error',
        message: `Error reading file "${file.name}".`,
      })
      resolve({ okay: false, log, fileType: 'las' })
    }
    fileReader.readAsArrayBuffer(
      fileToUse.slice(0, headerSize),
    )
  })
}

const uint8arrayToString = array => {
  let str = ''
  array.forEach(item => {
    const c = String.fromCharCode(item)
    if (c !== '\u0000') {
      str += c
    }
  })
  return str.trim()
}

async function getLasFileFromDropbox (file, startByte, endByte, callback) {
  return getFileFromDropbox(file, {
    responseType: 'arraybuffer',
    headers: {
      Range: `bytes=${startByte}-${endByte - 1}`,
    },
  }, undefined, callback)
}

export async function parseDropboxLasFile (file) {
  return getLasFileFromDropbox(file, 0, headerSize, parseLasFile)
  /*
    headers: {
      Range: `bytes=0-${headerSize-1}`
    }
  */
}

export function getFileNameForFilePropertiesWithDefinedCRS (fileNames, properties) {
  return Object.keys(keepProps(fileNames, (properties.fileProperties || {}))).find(key => {
    const fileProperties = properties.fileProperties[key]
    return !isNil(fileProperties.crs) && !isEmpty(fileProperties.crs)
  })
}
