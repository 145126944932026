import moment from 'moment'
import i18n from 'i18n'
// turf
// Project deps
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue, getCompanyCalibrationsForCurrentProject } from '../utils'
import { findById } from 'utils/list'
import config from 'config'

const getCalibrationItemString = calibration => {
  return calibration ? `${calibration.rover_serial}, ${calibration.comment}${calibration.acquisition_date ? `, ${moment(calibration.acquisition_date).format(config.DATE_FORMAT)}` : ''}` : null
}

export default {
  useCalibration: {
    name: i18n.t('templates.jobOptions.calibration.useCalibration'),
    dataType: DataType.BOOLEAN,
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const calibrationId = getCloneJobOptionValue(state, artifactId, options, 'id')
        if (calibrationId) return true
      }
      return false
    },
    sendToBackend: false,
    gridProps: {
      xs: 12,
      sm: 3,
      md: 3,
      lg: 3,
    },
  },
  calibration: {
    name: i18n.t('templates.jobOptions.calibration.calibration.name'),
    label: i18n.t('templates.jobOptions.calibration.calibration.label'),
    dataType: DataType.AUTOCOMPLETE,
    disabled: (state, values) => !values.useCalibration,
    optional: (state, values) => !values.useCalibration,
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const calibrationId = getCloneJobOptionValue(state, artifactId, options, 'id')
        if (calibrationId) {
          const calibrations = getCompanyCalibrationsForCurrentProject(state)
          return findById(calibrationId, calibrations)
        }
      }
      return null
    },
    options: state => getCompanyCalibrationsForCurrentProject(state),
    displayTemplate: calibration => getCalibrationItemString(calibration),
    searchTemplate: calibration => getCalibrationItemString(calibration),
    getValue: (state, value, values, extra) => value ? getCalibrationItemString(value) : '',
    backendTransform: (original, state, values) => original ? {
      rover_serial: original.rover_serial,
      comment: original.comment,
      id: original.id,
    } : null,
    gridProps: {
      xs: 12,
      sm: 9,
      md: 9,
      lg: 9,
    },
  },
}
