import { GpsStandardTime } from 'utils/time'
import { WEEK, USECS } from 'types/dataTime'
import { toGpsTow, toIntGpsWeek } from 'types/gnssTime'
import { clamp } from 'ramda'

export const GpsTimeIntervalState = {
  INVALID_TIME: -1,
}

/**
 * Parsing the tow value from the gps time field
 * Previously gps time contained `tow` value which was `secs + nsecs / 1e9`.
 * Some newer intervals written in artifact properties have `towSecs` and `towNsecs` instead of `tow`
 * So here we set `tow` property based on what the structure is.
 * @param {GpsTime} time
 * @returns {Number}
 */
export const getTow = time => {
  if ('tow' in time) {
    return time.tow
  }
  if (time.towSecs === GpsTimeIntervalState.INVALID_TIME || time.towNsecs === GpsTimeIntervalState.INVALID_TIME) {
    return GpsTimeIntervalState.INVALID_TIME
  }
  return time.towSecs + time.towNsecs / 1e9
}

/**
 * Transforms legacy GpsTime structure to the new
 * Please see {@link getTow}
 * @param {GpsTime} time
 * @returns {GpsTime}
 */
export const transformLegacyGpsTime = time => {
  return typeof time === 'object'
    ? { ...time, tow: getTow(time) }
    : time
}
/**
 * Transforms old GpsTimeInterval structure to the new
 * Please see {@link getTow}
 * @param {GpsTimeInterval} interval
 * @returns {GpsTimeInterval} interval
 */
export const transformLegacyGpsTimeInterval = interval => {
  if (interval) {
    const { beginning, end, ...otherProps } = interval
    return {
      beginning: transformLegacyGpsTime(beginning),
      end: transformLegacyGpsTime(end),
      ...otherProps,
    }
  }
  return interval
}

// export function areOrdered(a: GpsTime, b: GpsTime): boolean {
export function areOrdered (a, b) {
  return a.week < b.week || (a.week === b.week && a.tow < b.tow)
}

// export function gpsMin(a: GpsTime, b: GpsTime): GpsTime {
export function gpsMin (a, b) {
  return areOrdered(a, b) ? a : b
}

export function gpsMax (a, b) {
  return areOrdered(a, b) ? b : a
}

// function convertGpsTime({ week, tow }: GpsTime)
export function convertGpsTime ({ week, tow }) {
  return week * WEEK + tow
}

export function convertGpsTimeNew ({ week, tow }) {
  return (week * 86400) + (tow / 1000)
}

export const gpsTimeToUTC = gpsTime => {
  const gps_epoch = GpsStandardTime
    .clone()
    .add(convertGpsTime(gpsTime) * 1000, 'milliseconds')
    .subtract(19, 'seconds')
  return gps_epoch
}

// Convert `GpsTime` to percentages (0.0-1.0) in the range `interval`.
// function interpolateGpsTime(time: GpsTime, { beginning, end }: GpsTimeInterval): number  {
export function interpolateGpsTime (time, { beginning, end }) {
  return (
    (convertGpsTime(time) - convertGpsTime(beginning)) /
    (convertGpsTime(end) - convertGpsTime(beginning))
  )
}

export function timeFromGpsSeconds (weekNumber, timeOfWeekSeconds) {
  return weekNumber * WEEK * USECS + timeOfWeekSeconds * USECS
}

export function getNewTowWeek ({ tow, week }, min, max) {
  let towNew = tow
  let weekNew = week
  if (towNew < 0) {
    weekNew--
    towNew = WEEK + towNew
  }
  if (towNew >= WEEK) {
    weekNew++
    towNew = towNew - WEEK
  }
  const time = timeFromGpsSeconds(weekNew, towNew)
  if (time < timeFromGpsSeconds(min.week, min.tow)) {
    return min
  }
  if (time > timeFromGpsSeconds(max.week, max.tow)) {
    return max
  }
  return { tow: towNew, week: weekNew }
}
