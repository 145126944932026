import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
// Project deps
import { isAdmin, isLoggedIn } from 'modules/users/selectors'

const AdminRoute = ({ isUserLoggedIn, isUserAdmin, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        isUserLoggedIn
          ? isUserAdmin
            ? <Component {...props} />
            : <Redirect to='/error' />
          : <Redirect to='/' />
      )}
    />
  )
}

const mapStateToProps = state => ({
  isUserLoggedIn: isLoggedIn(state),
  isUserAdmin: isAdmin(state),
})

AdminRoute.propTypes = {
  isUserAdmin: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
}

export default connect(mapStateToProps)(AdminRoute)
