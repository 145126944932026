import { createReducer } from 'reduxsauce'
// Project deps
import { ArtifactsTypes as ArtifactsActionTypes } from 'modules/artifacts/actions'
import { UsersTypes } from 'modules/users/actions'
import artifactsReducers from 'modules/artifacts/reducers'
import pipelinesReducers from 'modules/pipelines/reducers'
import { MissionsTypes } from 'modules/missions/actions'
import { convertRawArtifact } from 'types/artifacts'
import { convertRawPipeline } from 'types/pipelines'
import { convertRawMission } from 'types/missions'
import { convertRawProject } from 'types/projects'
import { makeUniqueById, mergeById, mapById, removeById, removeByMissionId, removeByIdOnce, findById, makeUnique } from 'utils/list'
import { sortByCreated } from 'utils/sort'
// Local deps
import { ProjectsTypes } from './actions'
import { INITIAL_STATE } from './initialState'
import { UploadTypes } from 'modules/upload/actions'

function removeItemsById (items, ids) {
  return items.filter(item => !ids.includes(item.id))
}

export const listProjectsLoading = state =>
  state.merge({
    listProjectsIsLoading: true,
    listProjectsErrorMessage: '',
  })

export const listProjectsSuccess = (state, { projects }) => {
  return state.merge({
    projects: projects.map(convertRawProject),
    listProjectsIsLoading: false,
    listProjectsErrorMessage: null,
  })
}

export const listProjectsFailure = (state, { errorMessage }) =>
  state.merge({
    projects: [],
    listProjectsIsLoading: false,
    listProjectsErrorMessage: errorMessage,
  })

/*
export const getProjectsByUserLoading = state =>
  state.merge({
    getProjectsByUserIsLoading: true,
    getProjectsByUserErrorMessage: '',
  })

export const getProjectsByUserSuccess = (state, { projects }) =>
  state.merge({
    projectsByUser: projects.map(convertRawProject),
    getProjectsByUserIsLoading: false,
    getProjectsByUserErrorMessage: null,
  })

export const getProjectsByUserFailure = (state, { errorMessage }) =>
  state.merge({
    projectsByUser: null,
    getProjectsByUserIsLoading: false,
    getProjectsByUserErrorMessage: errorMessage,
  })
  */
/*
export const getProjectLoading = state =>
  state.merge({
    getProjectsIsLoading: true,
    getProjectErrorMessage: '',
  })

export const getProjectSuccess = (state, { Project }) =>
  state.merge({
    Project,
    getProjectsIsLoading: false,
    getProjectErrorMessage: null,
  })

export const getProjectFailure = (state, { errorMessage }) =>
  state.merge({
    Project: null,
    getProjectIsLoading: false,
    getProjectErrorMessage: errorMessage,
  })
*/

export const createProjectLoading = state =>
  state.merge({
    createProjectIsLoading: true,
    createProjectErrorMessage: null,
  })

export const createProjectSuccess = state => {
  return state.merge({
    createProjectIsLoading: false,
    createProjectErrorMessage: null,
  })
}

export const createProjectFailure = (state, { errorMessage }) => {
  return state.merge({
    createProjectIsLoading: false,
    createProjectErrorMessage: errorMessage,
  })
}

export const deleteProjectLoading = (state, { projectId }) =>
  state.merge({
    deleteProject: {
      ...state.get('deleteProject'),
      [projectId]: true,
    },
    deleteProjectErrorMessage: '',
  })

export const deleteProjectSuccess = (state, { projectId }) => {
  return state.merge({
    projects: removeByIdOnce(projectId, state.get('projects')),
    deleteProject: {
      ...state.get('deleteProject'),
      [projectId]: false,
    },
    deleteProjectErrorMessage: null,
  })
}

export const deleteProjectFailure = (state, { errorMessage, projectId }) => {
  return state.merge({
    deleteProject: {
      ...state.get('deleteProject'),
      [projectId]: false,
    },
    deleteProjectErrorMessage: errorMessage,
  })
}

export const transferProjectLoading = state => state.merge({ transferProjectIsLoading: true })
export const transferProjectSuccess = state => state.merge({ transferProjectIsLoading: false })
export const transferProjectFailure = state => state.merge({ transferProjectIsLoading: false })

export const generateSearchIndex = (state, { projects }) => {
  const search = projects.map(project => {
    const { id } = project
    const joined = `${project.name} ${project.description}`
    return [id, joined]
  })
  localStorage.setItem('search', JSON.stringify(search))
  return state
}

export const getCurrentProjectDataSuccess = (state, { projectId, pipelines, artifacts, missions, project, jobs, jobIos }) => {
  const currentProject = state.get('currentProject')
  const newArtifacts = artifacts.data.data.map(convertRawArtifact)
  const newPipelines = sortByCreated(pipelines.map(convertRawPipeline))
  const newJobs = jobs.data.data.map(job => {
    const jobIosForJob = jobIos.data.data.filter(jobIo => jobIo.job.id === job.id)
    return {
      ...job,
      job_ios: jobIosForJob,
    }
  })
  return state.merge({
    currentProject: {
      ...currentProject,
      projectId,
      pipelines: newPipelines.map(pipeline => ({
        ...pipeline,
        job: newJobs.find(job => job.pipeline.id === pipeline.id),
      })),
      artifacts: newArtifacts,
      missions: sortByCreated(missions.data.data.map(convertRawMission)),
      project: convertRawProject(project.data.data),
    },
    allArtifacts: mergeById(state.get('allArtifacts'), newArtifacts),
    getCurrentProjectDataIsLoading: false,
    getCurrentProjectDataErrorMessage: null,
  })
}

export const getCurrentProjectDataLoading = state => {
  return state.merge({
    getCurrentProjectDataIsLoading: true,
    getCurrentProjectDataErrorMessage: null,
  })
}

export const getCurrentProjectDataFailure = (state, { errorMessage }) => {
  return state.merge({
    getCurrentProjectDataIsLoading: false,
    getCurrentProjectDataErrorMessage: errorMessage,
  })
}

// here just flow the directoryIDS to the store,[{id, artifactId}]
export const getDataDirectoriesForArtifactsLoading = state => state.merge({})
export const getDataDirectoriesForArtifactsSuccess = (state, { dataDirectoriesList: dataDirectories }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      dataDirectories,
    },
  })
}
export const getDataDirectoriesForArtifactsFailure = state => state.merge({})

// Finalize pipeline
export const finalizePipelineLoading = state =>
  state.merge({
    getProjectsIsLoading: true,
    getProjectErrorMessage: '',
  })

export const finalizePipelineSuccess = (state, { pipelineId }) => {
  const currentProject = state.get('currentProject')
  const pipelines = currentProject.pipelines
  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: mapById(pipelineId, pipelines, pipeline => ({ ...pipeline, finalized: true })),
    },
  })
}

export const finalizePipelineFailure = (state, { errorMessage }) =>
  state.merge({
    getProjectIsLoading: false,
    getProjectErrorMessage: errorMessage,
  })

export const getAllPipelinesLoading = state => state.merge({})
export const getAllPipelinesSuccess = (state, { allPipelines }) => state.merge({ allPipelines })
export const getAllPipelinesFailure = state => state.merge({})

/*
export const getCurrentProjectDataForPipelineLoading = state => {
  return state.merge({
    getCurrentProjectDataForPipelineIsLoading: true,
  })
}

export const getCurrentProjectDataForPipelineSuccess =
  (state, { projectId, pipelines, artifacts, project }) => {
    return state.merge({
      currentProject: {
        ...state.get('currentProject'),
        projectId,
        artifacts: artifacts.map(convertRawArtifact),
        project,
        pipelines,
      },
      getCurrentProjectDataForPipelineIsLoading: false,
    })
  }

export const getCurrentProjectDataForPipelineFailure = state => {
  return state.merge({
    currentProject: defaultProjectData,
    getCurrentProjectDataForPipelineIsLoading: false,
  })
}

export const getCurrentProjectDataForArtifactLoading = state => {
  return state.merge({
    getCurrentProjectDataForArtifactIsLoading: true,
    getCurrentProjectDataForArtifactErrorMessage: null,
  })
}

export const getCurrentProjectDataForArtifactSuccess = (state, { project, artifacts, missions }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      projectId: project.id,
      project,
      artifacts: artifacts.map(convertRawArtifact),
      missions,
    },
    getCurrentProjectDataForArtifactIsLoading: false,
  })
}

export const getCurrentProjectDataForArtifactFailure = (state, { errorMessage }) => {
  return state.merge({
    currentProject: defaultProjectData,
    getCurrentProjectDataForArtifactIsLoading: false,
    getCurrentProjectDataForArtifactErrorMessage: errorMessage,
  })
}
*/

export const getDataForArtifactLoading = (state, { artifactId }) => {
  return state.merge({
    artifactDataLoading: {
      ...state.get('artifactDataLoading'),
      [artifactId]: {
        isLoading: true,
        loaded: false,
      },
    },
    getDataForArtifactErrorMessage: null,
  })
}

export const getDataForArtifactSuccess = (state, { artifactId, artifact, dataDirectories, dataFiles }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      artifacts: mapById(
        artifactId,
        currentProject.artifacts,
        currentArtifact => convertRawArtifact({ ...currentArtifact, ...artifact }),
      ),
      dataDirectories: makeUniqueById([
        ...dataDirectories,
        ...currentProject.dataDirectories,
      ]),
      dataFiles: makeUniqueById([
        ...dataFiles,
        ...currentProject.dataFiles,
      ]),
    },
    getDataForArtifactIsLoading: false,
    artifactDataLoading: {
      ...state.get('artifactDataLoading'),
      [artifactId]: {
        isLoading: false,
        loaded: true,
      },
    },
  })
}

export const getDataForArtifactFailure = (state, { artifactId, errorMessage }) => {
  return state.merge({
    artifactDataLoading: {
      ...state.get('artifactDataLoading'),
      [artifactId]: false,
    },
    getDataForArtifactErrorMessage: errorMessage,
  })
}

export const dataFileUploadDone = (state, { id, dataFile }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      dataFiles: mapById(id, currentProject.dataFiles || [], () => dataFile),
    },
  })
}

export const getDataForPipelineLoading = (state, { pipelineId }) => {
  return state.merge({
    pipelineDataLoading: {
      ...state.get('pipelineDataLoading'),
      [pipelineId]: true,
    },
    getDataForPipelineErrorMessage: null,
  })
}

export const getDataForPipelineSuccess = (state, { pipelineId, pipeline }) => {
  const currentProject = state.get('currentProject')
  const projectArtifacts = currentProject.artifacts

  const artifacts = pipeline.jobIOs.reduce((allArtifacts, jobIO) => {
    return [
      ...allArtifacts,
      jobIO.artifact,
    ]
  }, [])

  const newProjectArtifacts = projectArtifacts.map(projectArtifact => {
    const foundArtifact = findById(projectArtifact.id, artifacts)
    return foundArtifact
      ? { ...projectArtifact, artifactStatus: foundArtifact.artifact_status }
      : projectArtifact
  })

  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: mapById(pipelineId, currentProject.pipelines, () => convertRawPipeline(pipeline)),
      artifacts: newProjectArtifacts,
    },
    allArtifacts: mergeById(state.get('allArtifacts'), newProjectArtifacts),
    pipelineDataLoading: {
      ...state.get('pipelineDataLoading'),
      [pipelineId]: false,
    },
  })
}

export const getDataForPipelineFailure = (state, { pipelineId, errorMessage }) => {
  return state.merge({
    pipelineDataLoading: {
      ...state.get('pipelineDataLoading'),
      [pipelineId]: false,
    },
    getDataForPipelineErrorMessage: errorMessage,
  })
}

export const updatePipelineSuccess = (state, { pipelineId, pipeline }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: mapById(pipelineId, currentProject.pipelines, () => pipeline),
    },
  })
}

export const updateProjectSuccess = (state, { projectId, project }) => {
  const currentProject = state.get('currentProject')
  const projects = mapById(projectId, state.get('projects'), () => convertRawProject(project))
  return state.merge({
    currentProject: {
      ...currentProject,
      project: convertRawProject(project),
      projectId,
    },
    projects,
  })
}
/*
export const getMinimalProjectsLoading = state =>
  state.merge({
    getProjectsIsLoading: true,
    getProjectsErrorMessage: '',
  })

export const getMinimalProjectsSuccess = (state, { projects }) =>
  state.merge({
    minimalProjects: projects.map(convertRawProject),
    getProjectsIsLoading: false,
    getProjectsErrorMessage: null,
  })

export const getMinimalProjectsFailure = (state, { errorMessage }) =>
  state.merge({
    minimalProjects: [],
    getProjectsIsLoading: false,
    getProjectsErrorMessage: errorMessage,
  })
*/

export const getProjectsQueriedLoading = state =>
  state.merge({
    getProjectsQueriedIsLoading: true,
    getProjectsQueriedErrorMessage: null,
  })

export const getProjectsQueriedSuccess = (state, { projects, pagination }) =>
  state.merge({
    projects: projects.map(convertRawProject),
    pagination,
    getProjectsQueriedIsLoading: false,
  })

export const getProjectsQueriedFailure = (state, { errorMessage }) =>
  state.merge({
    projects: [],
    getProjectsQueriedIsLoading: false,
    getProjectsQueriedErrorMessage: errorMessage,
  })

export const getPipelinesForCurrentProjectSuccess = (state, { projectId, pipelines }) => {
  const currentProject = state.get('currentProject')
  const currentProjectPipelines = currentProject.pipelines
  return state.merge({
    currentProject: {
      ...currentProject,
      projectId,
      pipelines: pipelines.map(convertRawPipeline).map(pipeline => {
        const pipelineInProject = findById(pipeline.id, currentProjectPipelines)
        return pipelineInProject
          ? {
            ...pipelineInProject,
            ...pipeline,
            jobRuns: pipelineInProject.jobRuns || pipeline.jobRuns || [],
          }
          : pipeline
      }).sort((a, b) => b.created.getTime() - a.created.getTime()),
    },
    getPipelinesForCurrentProjectIsLoading: false,
    getPipelinesForCurrentProjectErrorMessage: null,
  })
}

export const getPipelinesForCurrentProjectLoading = state => {
  return state.merge({
    getPipelinesForCurrentProjectIsLoading: true,
  })
}

export const getPipelinesForCurrentProjectFailure = (state, { errorMessage }) => {
  return state.merge({
    getPipelinesForCurrentProjectIsLoading: false,
    getPipelinesForCurrentProjectErrorMessage: errorMessage,
  })
}

export const getRecursivePipelinesForCurrentProjectSuccess = (state, { projectId, pipelinesIds, pipelines }) => {
  const currentProject = state.get('currentProject')
  const currentProjectPipelines = currentProject.pipelines
  return state.merge({
    currentProject: {
      ...currentProject,
      projectId,
      pipelines: currentProjectPipelines.map(pipeline => {
        const pipelineInRecursivePipelines = findById(pipeline.id, pipelines)
        if (!pipelineInRecursivePipelines) return pipeline
        const newPipeline = convertRawPipeline(pipelineInRecursivePipelines)
        return {
          ...pipeline,
          ...newPipeline,
          jobRuns: newPipeline.jobRuns || pipeline.jobRuns || [],
        }
      }).sort((a, b) => b.created.getTime() - a.created.getTime()),
    },
    recursivePipelinesIsLoading: {
      ...state.get('recursivePipelinesIsLoading'),
      ...pipelinesIds.reduce((allPipelinesIds, pipelineId) => ({
        ...allPipelinesIds,
        [pipelineId]: false,
      }), {}),
    },
    recursivePipelinesIsDone: {
      ...state.get('recursivePipelinesIsDone'),
      ...pipelinesIds.reduce((allPipelinesIds, pipelineId) => ({
        ...allPipelinesIds,
        [pipelineId]: true,
      }), {}),
    },
  })
}

export const getRecursivePipelinesForCurrentProjectLoading = (state, { projectId, pipelinesIds }) => {
  return state.merge({
    recursivePipelinesIsLoading: {
      ...state.get('recursivePipelinesIsLoading'),
      ...pipelinesIds.reduce((allPipelinesIds, pipelineId) => ({
        ...allPipelinesIds,
        [pipelineId]: true,
      }), {}),
    },
  })
}

export const getRecursivePipelinesForCurrentProjectFailure = (state, { projectId, pipelinesIds, errorMessage }) => {
  return state.merge({
    recursivePipelinesIsLoading: {
      ...state.get('recursivePipelinesIsLoading'),
      ...pipelinesIds.reduce((allPipelinesIds, pipelineId) => ({
        ...allPipelinesIds,
        [pipelineId]: false,
      }), {}),
    },
    getPipelinesForCurrentProjectErrorMessage: errorMessage,
  })
}

export const getCurrentArtifactDataSuccess = (state, { artifactId, artifact, dataDirectories, dataFiles }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      dataDirectories: [
        ...currentProject.dataDirectories,
        ...dataDirectories,
      ],
      dataFiles: [
        ...currentProject.dataFiles,
        ...dataFiles,
      ],
    },
  })
}

export const deleteMissionSuccess = (state, { missionId }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      artifacts: removeByMissionId(missionId, currentProject.artifacts),
      missions: removeById(missionId, currentProject.missions),
      allArtifacts: removeByMissionId(missionId, state.get('allArtifacts')),
    },
  })
}

export const updateMissionSuccess = (state, { missionId, mission }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      missions: mapById(missionId, currentProject.missions, () => mission),
    },
  })
}

export const getMissionsSuccess = (state, { projectId, missions }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      missions: sortByCreated(missions.map(convertRawMission)),
    },
  })
}
export const getMissionSuccess = (state, { projectId, mission }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      missions: sortByCreated(makeUniqueById([
        convertRawMission(mission),
        ...currentProject.missions,
      ])),
    },
  })
}

export const addMissions = (state, { missions }) => {
  const currentProject = state.get('currentProject')
  const addedMissions = missions.map(convertRawMission)
  return state.merge({
    currentProject: {
      ...currentProject,
      missions: sortByCreated([
        ...currentProject.missions,
        ...addedMissions,
      ]),
    },
  })
}

export const getDataForArtifactsSuccess = (state, { dataDirectories, dataFiles }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    getDataForArtifactsIsLoading: false,
    currentProject: {
      ...currentProject,
      dataDirectories: makeUniqueById([
        ...dataDirectories,
        ...currentProject.dataDirectories,
      ]),
      dataFiles: makeUniqueById([
        ...dataFiles,
        ...currentProject.dataFiles,
      ]),
    },
  })
}

export const getDataForArtifactsLoading = state => {
  return state.merge({
    getDataForArtifactsIsLoading: true,
    getDataForArtifactsErrorMessage: null,
  })
}

export const getDataForArtifactsFailure = (state, { errorMessage }) => {
  return state.merge({
    getDataForArtifactsIsLoading: false,
    getDataForArtifactsErrorMessage: errorMessage,
  })
}

export const deleteDataFileSuccess = (state, { dataFileId }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      dataFiles: removeById(dataFileId, currentProject.dataFiles),
    },
  })
}

export const getDataDirectory = (state, { dataDirectory }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      dataDirectories: makeUniqueById([
        dataDirectory,
        ...currentProject.dataDirectories,
      ]),
    },
  })
}

export const deleteProjectsLoading = (state, { projectIds }) => state.merge({
  deleteProjectRequest: makeUnique([
    ...projectIds,
    ...state.get('deleteProjectRequest'),
  ]),
})
export const deleteProjectsSuccess = (state, { projectIds }) => {
  return state.merge({
    projects: removeItemsById(state.get('projects'), projectIds),
    minimalProjects: removeItemsById(state.get('minimalProjects'), projectIds),
    projectsByUser: removeItemsById(state.get('projectsByUser'), projectIds),
    deleteProjectRequest: state.get('deleteProjectRequest').filter(id => !projectIds.includes(id)),
  })
}
export const deleteProjectsFailure = (state, { projectIds }) => state.merge({
  deleteProjectRequest: state.get('deleteProjectRequest').filter(id => !projectIds.includes(id)),
})

export const getUTMZoneLoading = (state, { projectId }) => {
  const utmZones = state.get('utmZone')
  return state.merge({
    utmZone: {
      ...utmZones,
      [projectId]: {
        ...(utmZones[projectId] || {}),
        loading: true,
      },
    },
  })
}

export const getUTMZoneSuccess = (state, { projectId, utmZone }) => {
  const utmZones = state.get('utmZone')
  return state.merge({
    utmZone: {
      ...utmZones,
      [projectId]: {
        ...(utmZones[projectId] || {}),
        loading: false,
        data: utmZone,
      },
    },
  })
}

export const getUTMZoneFailure = (state, { projectId, errorMessage }) => {
  const utmZones = state.get('utmZone')
  return state.merge({
    utmZone: {
      ...utmZones,
      [projectId]: {
        ...(utmZones[projectId] || {}),
        loading: false,
        error: errorMessage,
      },
    },
  })
}

export const getProjectPositionsLoading = (state, { projectId }) => state.merge({})
export const getProjectPositionsSuccess = (state, { projectId, positions }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      positions,
    },
  })
}

export const getProjectPositionsFailure = (state, { projectId, errorMessage }) => {
  return state.merge({
  })
}

export const getDataForEditMissionArtifactsLoading = state => {
  return state.merge({
    getDataForEditMissionArtifactsIsLoading: true,
  })
}

export const getDataForEditMissionArtifactsSuccess = state => {
  return state.merge({
    getDataForEditMissionArtifactsIsLoading: false,
  })
}

export const getDataForEditMissionArtifactsFailure = state => {
  return state.merge({
    getDataForEditMissionArtifactsIsLoading: false,
  })
}

export const getProjectArtifactsLoading = state => {
  return state.merge({
    getProjectArtifactsIsLoading: true,
  })
}
export const getProjectArtifactsSuccess = (state, { projectId, artifacts }) => {
  return state.merge({
    artifactsByProject: {
      ...state.get('artifactsByProject'),
      [projectId]: artifacts,
    },
    getProjectArtifactsIsLoading: false,
  })
}
export const getProjectArtifactsFailure = state => {
  return state.merge({
    getProjectArtifactsIsLoading: false,
  })
}
export const getDataForPipelineAndArtifactsSuccess = state => state.merge({})

export const createMissionForCurrentProjectSuccess = state => state.merge({})
export const createMissionForCurrentProjectFailure = state => state.merge({})

export const setArtifactProperties = (state, { artifactId, properties }) => {
  const currentProject = state.get('currentProject')
  const mapThroughArtifacts = artifacts => mapById(artifactId, artifacts, artifact => ({
    ...artifact,
    properties: {
      ...artifact.properties,
      ...properties,
      fileProperties: {
        ...artifact.properties.fileProperties,
        ...properties.fileProperties,
      },
    },
  }))
  return state.merge({
    currentProject: {
      ...currentProject,
      artifacts: mapThroughArtifacts(currentProject.artifacts),
    },
    allArtifacts: mapThroughArtifacts(state.get('allArtifacts')),
  })
}

export const getProjectsLoading = state =>
  state.merge({
    listProjectsIsLoading: true,
    listProjectsErrorMessage: null,
  })

export const getProjectsSuccess = (state, { projects }) =>
  state.merge({
    projects: sortByCreated(projects.map(convertRawProject)),
    listProjectsIsLoading: false,
    listProjectsErrorMessage: null,
  })

export const getProjectsFailure = (state, { errorMessage }) =>
  state.merge({
    projects: [],
    listProjectsIsLoading: false,
    listProjectsErrorMessage: errorMessage,
  })

export const getGCPsLoading = (state, { artifactId }) =>
  state.merge({
    gcps: {
      ...state.get('gcps'),
      [artifactId]: {
        points: [],
      },
    },
  })
export const getGCPsSuccess = (state, { gcps, artifactId }) => {
  return state.merge({
    gcps: {
      ...state.get('gcps'),
      [artifactId]: {
        ...gcps,
        points: gcps,
      },
    },
  })
}
export const getGCPsFailure = (state, { errorMessage, artifactId }) => state.merge({})

// Create project CRS
export const createProjectCRSLoading = (state, { projectId }) =>
  state.merge({
    createCRSLoading: true,
  })
export const createProjectCRSSuccess = (state, { projectId, crs }) => {
  const currentProject = state.get('currentProject')
  if (currentProject.projectId === projectId) {
    currentProject.project.coordinateReferenceSystem = crs
  }
  return state.merge({
    currentProject,
    createCRSLoading: false,
  })
}
export const createProjectCRSFailure = (state, { projectId }) => state.merge({
  createCRSLoading: false,
})

// For now deleting and updating CRS available only on project's page
export const deleteCRSSuccess = (state, { projectId, crsId }) => {
  const currentProject = state.get('currentProject')
  if (currentProject.projectId === projectId) {
    currentProject.project.coordinateReferenceSystem = null
  }
  return state.merge({
    currentProject,
  })
}
export const updateCRSSuccess = (state, { projectId, crsId, crs }) => {
  const currentProject = state.get('currentProject')
  if (currentProject.projectId === projectId) {
    currentProject.project.coordinateReferenceSystem = crs
  }
  return state.merge({
    currentProject,
  })
}

export const getDownloadTokenLoading = (state, { id }) => {
  return state.merge({
    isGetDownloadTokenLoading: {
      ...state.get('isGetDownloadTokenLoading'),
      [id]: true,
    },
  })
}
export const getDownloadTokenSuccess = (state, { id, token }) => {
  return state.merge({
    isGetDownloadTokenLoading: {
      ...state.get('isGetDownloadTokenLoading'),
      [id]: false,
    },
    downloadToken: {
      ...state.get('downloadToken'),
      [id]: token,
    },
  })
}
export const getDownloadTokenFailure = (state, { id }) => {
  return state.merge({
    isGetDownloadTokenLoading: {
      ...state.get('isGetDownloadTokenLoading'),
      [id]: false,
    },
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  ...artifactsReducers,
  ...pipelinesReducers,

  [ProjectsTypes.SET_ARTIFACT_PROPERTIES]: setArtifactProperties,

  /*
  [CompanyTypes.GET_COMPANY_PROJECTS_LOADING]: getProjectsLoading,
  [CompanyTypes.GET_COMPANY_PROJECTS_SUCCESS]: getProjectsSuccess,
  [CompanyTypes.GET_COMPANY_PROJECTS_FAILURE]: getProjectsFailure,
  */

  [ProjectsTypes.GET_PROJECTS_LOADING]: listProjectsLoading,
  [ProjectsTypes.GET_PROJECTS_SUCCESS]: listProjectsSuccess,
  [ProjectsTypes.GET_PROJECTS_FAILURE]: listProjectsFailure,

  /*
  [ProjectsTypes.GET_MINIMAL_PROJECTS_LOADING]: getMinimalProjectsLoading,
  [ProjectsTypes.GET_MINIMAL_PROJECTS_SUCCESS]: getMinimalProjectsSuccess,
  [ProjectsTypes.GET_MINIMAL_PROJECTS_FAILURE]: getMinimalProjectsFailure,
  */

  /*
  [ProjectsTypes.GET_PROJECTS_BY_USER_LOADING]: getProjectsByUserLoading,
  [ProjectsTypes.GET_PROJECTS_BY_USER_SUCCESS]: getProjectsByUserSuccess,
  [ProjectsTypes.GET_PROJECTS_BY_USER_FAILURE]: getProjectsByUserFailure,
  */

  /*
  [ProjectsTypes.GET_PROJECT_LOADING]: getProjectLoading,
  [ProjectsTypes.GET_PROJECT_SUCCESS]: getProjectSuccess,
  [ProjectsTypes.GET_PROJECT_FAILURE]: getProjectFailure,
  */

  [ProjectsTypes.CREATE_PROJECT_LOADING]: createProjectLoading,
  [ProjectsTypes.CREATE_PROJECT_SUCCESS]: createProjectSuccess,
  [ProjectsTypes.CREATE_PROJECT_FAILURE]: createProjectFailure,

  [ProjectsTypes.DELETE_PROJECT_LOADING]: deleteProjectLoading,
  [ProjectsTypes.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
  [ProjectsTypes.DELETE_PROJECT_FAILURE]: deleteProjectFailure,

  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_SUCCESS]: getCurrentProjectDataSuccess,
  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_LOADING]: getCurrentProjectDataLoading,
  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_FAILURE]: getCurrentProjectDataFailure,

  [ProjectsTypes.TRANSFER_PROJECT_LOADING]: transferProjectLoading,
  [ProjectsTypes.TRANSFER_PROJECT_SUCCESS]: transferProjectSuccess,
  [ProjectsTypes.TRANSFER_PROJECT_FAILURE]: transferProjectFailure,

  /*
  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_FOR_PIPELINE_SUCCESS]: getCurrentProjectDataForPipelineSuccess,
  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_FOR_PIPELINE_LOADING]: getCurrentProjectDataForPipelineLoading,
  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_FOR_PIPELINE_FAILURE]: getCurrentProjectDataForPipelineFailure,

  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_FOR_ARTIFACT_SUCCESS]: getCurrentProjectDataForArtifactSuccess,
  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_FOR_ARTIFACT_LOADING]: getCurrentProjectDataForArtifactLoading,
  [ProjectsTypes.GET_CURRENT_PROJECT_DATA_FOR_ARTIFACT_FAILURE]: getCurrentProjectDataForArtifactFailure,
  */

  [ProjectsTypes.GET_DATA_FOR_EDIT_MISSION_ARTIFACTS_LOADING]: getDataForEditMissionArtifactsLoading,
  [ProjectsTypes.GET_DATA_FOR_EDIT_MISSION_ARTIFACTS_SUCCESS]: getDataForEditMissionArtifactsSuccess,
  [ProjectsTypes.GET_DATA_FOR_EDIT_MISSION_ARTIFACTS_FAILURE]: getDataForEditMissionArtifactsFailure,

  [ProjectsTypes.GET_DATA_FOR_ARTIFACT_SUCCESS]: getDataForArtifactSuccess,
  [ProjectsTypes.GET_DATA_FOR_ARTIFACT_LOADING]: getDataForArtifactLoading,
  [ProjectsTypes.GET_DATA_FOR_ARTIFACT_FAILURE]: getDataForArtifactFailure,

  [ProjectsTypes.GET_DATA_FOR_PIPELINE_SUCCESS]: getDataForPipelineSuccess,
  [ProjectsTypes.GET_DATA_FOR_PIPELINE_LOADING]: getDataForPipelineLoading,
  [ProjectsTypes.GET_DATA_FOR_PIPELINE_FAILURE]: getDataForPipelineFailure,

  [ProjectsTypes.GET_ALL_PIPELINES_LOADING]: getAllPipelinesLoading,
  [ProjectsTypes.GET_ALL_PIPELINES_SUCCESS]: getAllPipelinesSuccess,
  [ProjectsTypes.GET_ALL_PIPELINES_FAILURE]: getAllPipelinesFailure,

  [ProjectsTypes.GET_DATA_DIRECTORIES_FOR_ARTIFACTS_LOADING]: getDataDirectoriesForArtifactsLoading,
  [ProjectsTypes.GET_DATA_DIRECTORIES_FOR_ARTIFACTS_SUCCESS]: getDataDirectoriesForArtifactsSuccess,
  [ProjectsTypes.GET_DATA_DIRECTORIES_FOR_ARTIFACTS_FAILURE]: getDataDirectoriesForArtifactsFailure,

  [ProjectsTypes.FINALIZE_PIPELINE_LOADING]: finalizePipelineLoading,
  [ProjectsTypes.FINALIZE_PIPELINE_SUCCESS]: finalizePipelineSuccess,
  [ProjectsTypes.FINALIZE_PIPELINE_FAILURE]: finalizePipelineFailure,

  [ProjectsTypes.UPDATE_PROJECT_SUCCESS]: updateProjectSuccess,

  [ProjectsTypes.GET_PROJECTS_QUERIED_LOADING]: getProjectsQueriedLoading,
  [ProjectsTypes.GET_PROJECTS_QUERIED_SUCCESS]: getProjectsQueriedSuccess,
  [ProjectsTypes.GET_PROJECTS_QUERIED_FAILURE]: getProjectsQueriedFailure,

  [ProjectsTypes.GET_PIPELINES_FOR_CURRENT_PROJECT_SUCCESS]: getPipelinesForCurrentProjectSuccess,
  [ProjectsTypes.GET_PIPELINES_FOR_CURRENT_PROJECT_LOADING]: getPipelinesForCurrentProjectLoading,
  [ProjectsTypes.GET_PIPELINES_FOR_CURRENT_PROJECT_FAILURE]: getPipelinesForCurrentProjectFailure,

  [ProjectsTypes.GET_RECURSIVE_PIPELINES_FOR_CURRENT_PROJECT_SUCCESS]: getRecursivePipelinesForCurrentProjectSuccess,
  [ProjectsTypes.GET_RECURSIVE_PIPELINES_FOR_CURRENT_PROJECT_LOADING]: getRecursivePipelinesForCurrentProjectLoading,
  [ProjectsTypes.GET_RECURSIVE_PIPELINES_FOR_CURRENT_PROJECT_FAILURE]: getRecursivePipelinesForCurrentProjectFailure,

  [ProjectsTypes.GET_DATA_FOR_ARTIFACTS_SUCCESS]: getDataForArtifactsSuccess,
  [ProjectsTypes.GET_DATA_FOR_ARTIFACTS_LOADING]: getDataForArtifactsLoading,
  [ProjectsTypes.GET_DATA_FOR_ARTIFACTS_FAILURE]: getDataForArtifactsFailure,

  [ProjectsTypes.GET_UTM_ZONE_LOADING]: getUTMZoneLoading,
  [ProjectsTypes.GET_UTM_ZONE_SUCCESS]: getUTMZoneSuccess,
  [ProjectsTypes.GET_UTM_ZONE_FAILURE]: getUTMZoneFailure,

  [ProjectsTypes.CREATE_MISSION_FOR_CURRENT_PROJECT_SUCCESS]: createMissionForCurrentProjectSuccess,
  [ProjectsTypes.CREATE_MISSION_FOR_CURRENT_PROJECT_FAILURE]: createMissionForCurrentProjectFailure,

  [ProjectsTypes.ADD_MISSIONS]: addMissions,

  [ProjectsTypes.DELETE_PROJECTS_LOADING]: deleteProjectsLoading,
  [ProjectsTypes.DELETE_PROJECTS_SUCCESS]: deleteProjectsSuccess,
  [ProjectsTypes.DELETE_PROJECTS_FAILURE]: deleteProjectsFailure,

  [ProjectsTypes.DELETE_USER_PROJECTS_LOADING]: deleteProjectsLoading,
  [ProjectsTypes.DELETE_USER_PROJECTS_SUCCESS]: deleteProjectsSuccess,
  [ProjectsTypes.DELETE_USER_PROJECTS_FAILURE]: deleteProjectsFailure,

  [ProjectsTypes.GET_PROJECT_ARTIFACTS_LOADING]: getProjectArtifactsLoading,
  [ProjectsTypes.GET_PROJECT_ARTIFACTS_SUCCESS]: getProjectArtifactsSuccess,
  [ProjectsTypes.GET_PROJECT_ARTIFACTS_FAILURE]: getProjectArtifactsFailure,

  [ProjectsTypes.GET_ARTIFACT_GCPS_LOADING]: getGCPsLoading,
  [ProjectsTypes.GET_ARTIFACT_GCPS_SUCCESS]: getGCPsSuccess,
  [ProjectsTypes.GET_ARTIFACT_GCPS_FAILURE]: getGCPsFailure,

  [ProjectsTypes.CREATE_PROJECT_CRS_LOADING]: createProjectCRSLoading,
  [ProjectsTypes.CREATE_PROJECT_CRS_SUCCESS]: createProjectCRSSuccess,
  [ProjectsTypes.CREATE_PROJECT_CRS_FAILURE]: createProjectCRSFailure,

  [ProjectsTypes.GET_DOWNLOAD_TOKEN_LOADING]: getDownloadTokenLoading,
  [ProjectsTypes.GET_DOWNLOAD_TOKEN_SUCCESS]: getDownloadTokenSuccess,
  [ProjectsTypes.GET_DOWNLOAD_TOKEN_FAILURE]: getDownloadTokenFailure,

  [ProjectsTypes.GET_DATA_FOR_PIPELINE_AND_ARTIFACTS_SUCCESS]: getDataForPipelineAndArtifactsSuccess,

  [UsersTypes.UPDATE_CRS_SUCCESS]: updateCRSSuccess,
  [UsersTypes.DELETE_CRS_SUCCESS]: deleteCRSSuccess,

  [ArtifactsActionTypes.PUT_GET_DATA_DIRECTORY]: getDataDirectory,
  [ArtifactsActionTypes.PUT_CREATE_DATA_DIRECTORY]: getDataDirectory,

  [MissionsTypes.DELETE_MISSION_SUCCESS]: deleteMissionSuccess,
  [MissionsTypes.UPDATE_MISSION_SUCCESS]: updateMissionSuccess,
  [MissionsTypes.GET_MISSIONS_SUCCESS]: getMissionsSuccess,
  [MissionsTypes.GET_MISSION_SUCCESS]: getMissionSuccess,

  [UploadTypes.DATA_FILE_DONE]: dataFileUploadDone,
})
