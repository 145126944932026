import { isFileNameEndsWith } from 'utils/baseName'

export const ShapeExtensions = [
  '.shp',
  '.prj',
  '.dbf',
  '.shx',
]
export const RequiredPointcloudShapeFiles = [
  '.shp',
  '.prj',
]
export const OptionalPointcloudShapeFiles = [
  '.dbf',
  '.shx',
]
export function isShapeFileOptional (fileName) {
  return OptionalPointcloudShapeFiles.some(shapeExtension => isFileNameEndsWith(fileName, shapeExtension))
}
export function isShapeFileRequired (fileName) {
  return RequiredPointcloudShapeFiles.some(shapeExtension => isFileNameEndsWith(fileName, shapeExtension))
}
/**
 * Checks whether the file name of a file looks like a shape file name.
 */
export function isShapeFileName (fileName) {
  return ShapeExtensions.some(shapeExtension => isFileNameEndsWith(fileName, shapeExtension))
}

export function isShapeFileType (fileType) {
  return fileType === 'shape'
}
