import React from 'react'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import {
  ClickAwayListener,
  Popper,
  Paper,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core'
// Project deps
import {
  routeProjectPipelines,
  routeProject,
  routeArtifact,
  routeProjectMissions,
  routeProjectMission,
} from 'utils/routing'
import LoadingText from 'components/LoadingText'
// Local deps
import SearchBlock from './SearchBlock'

const styles = theme => ({
  scrollParent: {
    maxHeight: 'calc(100vh - 250px)',
    overflowX: 'auto',
    overflowY: 'auto',
    maxWidth: '550px',
    textOverflow: 'ellipsis',
  },
  loader: {
    marginLeft: theme.spacing(1),
  },
  lastMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    height: '48px',
    padding: 0,
    '&&:hover': {
      background: 'inherit',
    },
  },
})

const typesMap = {
  'project': {
    title: 'Projects',
    getUrl: (projectId, id) => routeProject(projectId),
  },
  'pipeline': {
    title: 'Pipelines',
    getUrl: (projectId, id) => routeProjectPipelines(projectId, id),
  },
  'artifact': {
    title: 'Artifacts',
    getUrl: (projectId, id) => routeArtifact(id),
  },
  'file': {
    title: 'Files',
    getUrl: (projectId, id) => routeProjectMissions(projectId),
  },
  'job_run': {
    title: 'Job runs',
    getUrl: (projectId, id) => `/job_runs/${id}`,
  },
  'mission': {
    title: 'Missions',
    getUrl: (projectId, id) => routeProjectMission(projectId, id),
  },
}

class SearchList extends React.Component {
  scrollOffset = 50
  listenerSet = false
  state = {
    tabsValue: 0,
  }

  componentDidUpdate (prevProps) {
    if (prevProps.search !== this.props.search && this.listenerSet) {
      this.unsetListener()
      this.setListener()
      this.listenerSet = true
    }
    if (prevProps.isLoading && !this.props.isLoading && this.scrollParentRef) {
      this.scrollParentRef.scrollTo(0, 0)
    }
  }

  componentWillUnmount () {
    this.unsetListener()
  }

  setListener = () => {
    if (this.scrollParentRef) {
      this.scrollParentRef.addEventListener('scroll', _.throttle(this.onScroll, 275), false)
    }
  }

  unsetListener = () => {
    if (this.scrollParentRef) {
      this.scrollParentRef.removeEventListener('scroll', this.onScroll, false)
    }
  }

  onScroll = () => {
    const { isLoading } = this.props
    const distanceFromTop = this.scrollParentRef.clientHeight + this.scrollParentRef.scrollTop
    const distanceToNextLoad = this.scrollParentRef.scrollHeight - this.scrollOffset
    if (distanceFromTop >= distanceToNextLoad && this.props.data.length && !isLoading) {
      this.props.loadNext(distanceFromTop)
    }
  }

  isScrollExists = () => {
    if (this.scrollParentRef) {
      if (this.scrollParentRef.scrollTop === 0) {
        return false
      }
      return true
    }
    return false
  }

  handleClose = event => {
    this.props.handleClose(event)
  }

  onTabsChange = (event, tabsValue) => {
    event.preventDefault()
    this.setState({ tabsValue })
  }

  render () {
    const { tabsValue } = this.state
    const { data, open, anchorEl, isLoading, hasMore, classes, total, history, location } = this.props
    const dataByType = data.reduce((result, dataItem) => {
      const dataItemType = dataItem.type
      return {
        ...result,
        [dataItemType]: [
          ...(result[dataItemType] || []),
          dataItem,
        ],
      }
    }, Object.keys(typesMap).reduce((accum, key) => ({ ...accum, [key]: [] }), {}))

    const tabs = Object.keys(dataByType).reduce((allTabs, type) => {
      const _data = dataByType[type]
      const typeData = typesMap[type]
      return typeData
        ? [
          ...allTabs,
          {
            ...typeData,
            type: type,
            data: _data,
          }]
        : allTabs
    }, [])
    const selectedTab = tabs[tabsValue]

    if (open && !this.listenerSet) {
      this.setListener()
      this.listenerSet = true
    }
    if (!open && this.listenerSet) {
      this.unsetListener()
      this.listenerSet = false
    }

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        style={{
          zIndex: 99999,
          border: `1px solid grey`,
          overflow: isLoading ? 'hidden' : 'auto',
        }}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        <ClickAwayListener onClickAway={this.handleClose}>
          <div
            className={classes.scrollParent}
            key='popper--scroll-list'
            ref={ref => {
              this.scrollParentRef = ref
              if (ref) {
                this.setListener()
                this.listenerSet = true
              }
            }}
          >
            <Paper>
              <AppBar
                color='primary'
                position='static'
                elevation={0}>
                <Tabs
                  value={tabsValue}
                  onChange={this.onTabsChange}
                  variant='fullWidth'
                >
                  {
                    tabs.map(tab => (
                      <Tab
                        key={tab.type}
                        label={tab.title}
                      />
                    ))
                  }
                </Tabs>
              </AppBar>
              { (isLoading || (hasMore && total > 0))
                ? <div className={classes.lastMenuItem}>
                  <LoadingText title='Getting results'/>
                </div>
                : selectedTab && (
                  <SearchBlock
                    history={history}
                    location={location}
                    key={selectedTab.type}
                    data={selectedTab.data}
                    onClose={this.handleClose}
                    {...selectedTab}
                  />
                )
              }
            </Paper>
          </div>
        </ClickAwayListener>
      </Popper>
    )
  }
}

SearchList.propTypes = {
  search: PropTypes.string,
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  data: PropTypes.array,
  loadNext: PropTypes.func,
  loadAll: PropTypes.func,
  handleClose: PropTypes.func,
  anchorEl: PropTypes.object,
  hasMore: PropTypes.bool,
  classes: PropTypes.object,
  total: PropTypes.number,
  location: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(withStyles(styles)(SearchList))
