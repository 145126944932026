import { path } from 'ramda'
// import { getArtifactsForPipeline, getArtifactsForJobRun } from "../reducers/artifact-list/utils";
import { getPipelines, getCurrentProject, getArtifacts } from 'modules/projects/selectors'
import { JobRunState } from 'types/jobRuns'
import ProjectsActions, { ProjectsTypes } from 'modules/projects/actions'
import ArtifactsActions, { ArtifactsTypes } from 'modules/artifacts/actions'
import MissionsActions from 'modules/missions/actions'
import { findById, makeUnique } from 'utils/list'
import { ArtifactStatuses } from 'types/artifacts'
import { isJobRunSuccess } from 'utils/jobRuns'
import { JobType } from 'types/jobs'

const artifactRefresherMiddleware = store => next => action => {
  if (action.type === ProjectsTypes.GET_PIPELINES_FOR_CURRENT_PROJECT_SUCCESS) {
    const pipelinesBefore = getPipelines(store.getState())
    next(action)
    const pipelinesAfter = getPipelines(store.getState())
    const pipelinesFinished = pipelinesBefore.filter(({ id: idBefore, pipeline_state: pipelineStateBefore }) => {
      const afterPipeline = findById(idBefore, pipelinesAfter)
      if (!afterPipeline) {
        return false
      }
      const { pipeline_state: pipelineStateAfter } = findById(idBefore, pipelinesAfter)
      return pipelineStateBefore === JobRunState.RUNNING && isJobRunSuccess(pipelineStateAfter)
    })
    pipelinesFinished.forEach(pipeline => next(ProjectsActions.getDataForPipelineAndArtifacts(pipeline.id)))
    const reconPipelinesStatusChanged = pipelinesBefore
      .filter(pipeline => pipeline.job_types.includes(JobType.NL_ANALYZE_RECON_DATA))
      .filter(({ id: idBefore, pipeline_state: pipelineStateBefore }) => {
        const afterPipeline = findById(idBefore, pipelinesAfter)
        if (!afterPipeline) {
          return false
        }
        const { pipeline_state: pipelineStateAfter } = afterPipeline
        return pipelineStateBefore !== pipelineStateAfter
      })
    // Project ids for which recon analyze pipeline has finished just now
    const reconPipelinesFinishedProjectIds = makeUnique(
      reconPipelinesStatusChanged
        .filter(pipeline => pipelinesFinished.find(fp => fp.id === pipeline.id))
        .map(pipeline => path(['project', 'id'], pipeline)),
    )
    // update mission plps
    reconPipelinesFinishedProjectIds.forEach(projectId => next(MissionsActions.getMissions(projectId, false)))
    const newlyCreatedPipelines = pipelinesAfter.filter(pipeline => !pipelinesBefore.find(pipeBefore => pipeBefore.id === pipeline.id))
    // We should get new list of artifacts for newly created pipelines
    // Because we can have a situation with pipelines without any input/output artifacts
    if (reconPipelinesStatusChanged.length > 0 || newlyCreatedPipelines.length > 0) {
      const projectIds = makeUnique(
        ...reconPipelinesStatusChanged.map(pipeline => path(['project', 'id'], pipeline)),
        ...newlyCreatedPipelines,
      )
      projectIds.forEach(projectId => {
        next(ArtifactsActions.updateProjectArtifactsState(projectId, true))
      })
    }
  }
  if (action.type === ProjectsTypes.GET_DATA_FOR_PIPELINE_AND_ARTIFACTS_SUCCESS) {
    const { pipeline } = action
    const stateAfter = store.getState()
    const currentProject = getCurrentProject(stateAfter)
    const { artifacts } = currentProject
    const jobs = pipeline.jobs || []
    const outputJobIOs = jobs.reduce((accum, job) => {
      return [
        ...accum,
        ...job.job_ios,
      ]
    }, [])

    const artifactIds = outputJobIOs.map(({ artifact }) => artifact.id)
    const outputArtifactsPipeline = artifactIds.reduce((accum, artifactId) => {
      const foundArtifact = findById(artifactId, artifacts)
      return foundArtifact
        ? [...accum, foundArtifact]
        : accum
    }, [])
    if (outputArtifactsPipeline.length > 0) {
      next(ArtifactsActions.getArtifacts(artifactIds))
    }
  }
  if (action.type === ProjectsTypes.GET_DATA_FOR_PIPELINE_SUCCESS) {
    const pipelinesBefore = getPipelines(store.getState())
    next(action)
    const stateAfter = store.getState()
    const pipelinesAfter = getPipelines(stateAfter)
    const currentProject = getCurrentProject(stateAfter)
    const { artifacts } = currentProject
    const pipelinesFinished = pipelinesBefore.filter(({ id: idBefore, pipeline_state: pipelineStateBefore }) => {
      const { pipeline_state: pipelineStateAfter } = findById(idBefore, pipelinesAfter)
      return pipelineStateBefore === JobRunState.RUNNING && isJobRunSuccess(pipelineStateAfter)
    })

    pipelinesFinished.forEach(pipeline => {
      const projectId = path(['project', 'id'], pipeline)
      const jobs = pipeline.jobs || []
      const outputJobIOs = jobs.reduce((accum, job) => {
        return [
          ...accum,
          ...job.job_ios,
        ]
      }, [])

      const artifactIds = outputJobIOs.map(({ artifact }) => artifact.id)
      const outputArtifactsPipeline = artifactIds.reduce((accum, artifactId) => {
        const foundArtifact = findById(artifactId, artifacts)
        return foundArtifact
          ? [...accum, foundArtifact]
          : accum
      }, [])
      if (outputArtifactsPipeline.length > 0) {
        next(ArtifactsActions.getArtifacts(artifactIds))
      }
      if (pipeline.job_types.includes(JobType.NL_ANALYZE_RECON_DATA)) {
        next(MissionsActions.getMissions(projectId, false))
      }
    })
  // This handles the situation when we only update the artifact statuses
  } else if (action.type === ArtifactsTypes.UPDATE_PROJECT_ARTIFACTS_STATE_SUCCESS) {
    const artifactsBefore = getArtifacts(store.getState())
    next(action)
    const stateAfter = store.getState()
    const artifactsAfter = getArtifacts(stateAfter)
    const artifactsChangedState = artifactsBefore.filter(({ id: idBefore, artifactStatus: artifactStatusBefore }) => {
      const { artifactStatus: artifactStatusAfter } = findById(idBefore, artifactsAfter)
      return artifactStatusBefore !== ArtifactStatuses.READY && artifactStatusAfter === ArtifactStatuses.READY
    })
    if (artifactsChangedState.length > 0) {
      next(ArtifactsActions.getArtifacts(artifactsChangedState.map(art => art.id)))
    }
  } else {
    next(action)
  }
}

export default artifactRefresherMiddleware
