// Material UI
// Project deps
import { TrajectoryPathExtensions, GeoTiffFileTypes, ShapeExtensions, XMLExtensions, KMLExtensions, OBJExtensions, DXFExtensions } from 'modules/upload/file-types'

/*
const isValid = (files, extension1, extension2) => {
  const filesWithExtension1 = files.filter(file => file.file.name.endsWith(extension1))
  const filesWithExtension2 = files.filter(file => file.file.name.endsWith(extension2))
  if (filesWithExtension2.length > 0) return filesWithExtension1.length > 0
  if (filesWithExtension1.length > 0 && filesWithExtension2.length <= 0) return true
  return false
}

const isMissionValid = (files, extension1, extension2) => {
  const filesWithExtension1 = files.filter(file => file.file.name.endsWith(extension1))
  const filesWithExtension2 = files.filter(file => file.file.name.endsWith(extension2))
  return filesWithExtension1.length > 0 && filesWithExtension1.length <= 1
    ? filesWithExtension2.length >= 0 && filesWithExtension2.length <= 1
    : filesWithExtension1.length === 0
      ? filesWithExtension2.length === 0
      : false
}

const missionCheck = (
  filesOfTypeAdded,
  filesOfTypePotentiallyAdded,
  filesOfExtensionAdded,
  filesOfExtensionPotentiallyAdded,
  fileType,
  fileExtension,
  file,
  errors
) => {
  // Basically this means that we can upload only one file per extension
  const pofFilesNumber = filesOfExtensionAdded.length + filesOfExtensionPotentiallyAdded.length + 1
  if (pofFilesNumber <= 1) return true
  errors.push(`'${file.file.name}' file cannot be added can't be added because 1 file of this extension is allowed `)
  return false
}
*/

export const TrajectoryTemplate = {
  'trj': {
    min: 0,
    max: 1,
    // eslint-disable-next-line
    displayFileTypes: true,
    fileTypes: [
      ...TrajectoryPathExtensions,
      '.pof',
      '.poq',
      '.out',
      '.log',
    ],
    customMissionCheck: (
      filesOfTypeAdded,
      filesOfTypePotentiallyAdded,
      filesOfExtensionAdded,
      filesOfExtensionPotentiallyAdded,
      fileType,
      fileExtension,
      errors,
    ) => {
      const fileShouldBeAdded = (filesOfExtensionAdded.length <= 0 || filesOfExtensionPotentiallyAdded.length <= 0)
      return fileShouldBeAdded
    },
    isMissionValid: (files, suggestions) => true,
  },
}

export const NonMissionTemplate = {
  'artifacts': {
    // ...TrajectoryTemplate,
    'las': {
      min: 0,
      max: 100,
      // fileTypes: ['.las', '.laz'],
      fileTypes: [
        '.las',
        '.laz',
        ...TrajectoryPathExtensions,
        '.pof',
        '.poq',
        '.out',
        '.log',
        ...KMLExtensions,
        ...XMLExtensions,
        ...OBJExtensions,
        ...DXFExtensions,
        ...ShapeExtensions,
      ],
      displayFileTypes: true,
    },
    'tiff': {
      min: 0,
      max: 100,
      fileTypes: GeoTiffFileTypes,
      displayFileTypes: true,
    },
  },
  'trajectory': {
    ...TrajectoryTemplate,
  },
  'gcp': {
    'gcp': {
      min: 0,
      max: 100,
      fileTypes: ['.gcp'],
    },
    'polygon': {
      min: 1,
      max: 1,
      fileTypes: ['.kml', '.kmz'],
    },
  },
}
