import i18n from 'i18n'
import { JobType, DummyJobs } from 'types/jobs'
import { filterByProjectId, findById } from 'utils/list'
import { getJobIOsForArtifact } from 'utils/jobIOs'
import { isJobRunCanceled, isJobRunFailed, isJobRunSuccess } from './jobRuns'

export const isDummyJob = job => DummyJobs.indexOf(job.jobType) >= 0

const jobTypeTranslations = Object.values(JobType).reduce((all, jobType) => ({
  ...all,
  [jobType]: i18n.t(`templates.jobs.${jobType}`),
}), {})

/**
 * Converts the `jobType` identifier into a human-readable representation.
 */
export function prettifyJobType (jobType) {
  return jobTypeTranslations[jobType] || i18n.t(`templates.jobs.unknown`)
}

/**
 * A job run is done if it has either type `SUCCESS` or `FAILURE` or `CANCELED`.
 * @param jobRunState The state of the job run to check.
 * @return Whether the job is still running or already done.
 */
export function isJobRunDone (jobRunState) {
  return (
    isJobRunFailed(jobRunState) ||
    isJobRunSuccess(jobRunState) ||
    isJobRunCanceled(jobRunState)
  )
}

/**
 * A job run is done if it has either type `SUCCESS` or `FAILURE` or `CANCELED`.
 * @param jobRunState The state of the job run to check.
 * @return Whether the job is still running or already done.
 */
export function isJobRunSucceded (jobRunState) {
  return isJobRunSuccess(jobRunState)
}

/**
 * Finds all jobs which belong to a specified pipeline.
 * @param pipelineId The id of the pipeline to find the jobs for.
 * @param jobs The list of all known jobs.
 * @return All jobs which belong to the specified pipeline.
 */
export function getJobsForPipeline (pipelineId, jobs) {
  return jobs.filter(job => job.pipelineId === pipelineId)
}

export function getJobIdsForPipeline (pipelineId, jobs) {
  return getJobsForPipeline(pipelineId, jobs).map(job => job.id)
}

/**
 * Finds all jobs which belong to a specified project.
 * @param projectId The id of the project to list the jobs for.
 * @param pipelines The `PipelineListState` from the `AppState` to search for the project's pipelines in.
 * @param jobs The list of jobs to search for the jobs in.
 * @return All jobs which belong to the specified project.
 */
export function getJobsForProject (projectId, pipelines, jobs) {
  const projectPipelineIds = filterByProjectId(projectId, pipelines).map(pipeline => pipeline.id)
  return jobs.filter(job => projectPipelineIds.some(id => id === job.pipelineId))
}

export function getJobIdsForProject (projectId, pipelines, jobs) {
  return getJobsForProject(projectId, pipelines, jobs).map(job => job.id)
}

export function getJobsForArtifact (artifactId, jobIOs, jobs) {
  return getJobIOsForArtifact(artifactId, jobIOs).map(({ jobId }) => findById(jobId, jobs))
}

export function getJobsWithTypeForProject (jobType, projectId, pipelines, jobs) {
  return getJobsForProject(projectId, pipelines, jobs).filter(job => job.jobType === jobType)
}

export function hasJobsWithTypeInProject (jobType, projectId, pipelines, jobs) {
  return getJobsWithTypeForProject(jobType, projectId, pipelines, jobs).length > 0
}

export function hasSuccessfulJobsWithTypeInProject (jobType, projectId, pipelines, jobs, jobRuns) {
  return getJobsWithTypeForProject(jobType, projectId, pipelines, jobs).some(job =>
    jobRuns.some(jobRun => jobRun.jobId === job.id && isJobRunSuccess(jobRun.jobRunState)),
  )
}

export function isJobClonable (jobType) {
  return isSpatialFuserJob(jobType) ||
    isNavLabJob(jobType) ||
    isNavLabApplanixJob(jobType) ||
    isClassificationJob(jobType) ||
    isPointcloudOptimizationJob(jobType)
}

export function isSpatialFuserJob (jobType) {
  return jobType === JobType.SPATIAL_FUSER
}
export function isNavLabJob (jobType) {
  return jobType === JobType.NAVLAB
}
export function isNavLabApplanixJob (jobType) {
  return jobType === JobType.NAVLAB_APPLANIX
}
export function isPotreeJob (jobType) {
  return jobType === JobType.POTREE_CONVERTER
}
export function isTransferJob (jobType) {
  return jobType === JobType.TRANSFER
}
export function isUploadJob (jobType) {
  return jobType === JobType.UPLOAD_FILES
}
export function isOutputCRSJob (jobType) {
  return jobType === JobType.OUTPUT_CRS
}
export function isNavRoverAnalyzeJob (jobType) {
  return jobType === JobType.NL_ANALYZE_NAVIGATION_ROVER
}
export function isRefStationAnalyzeJob (jobType) {
  return jobType === JobType.NL_ANALYZE_REFERENCE_STATION
}
export function isTrajectoryAnalyzeJob (jobType) {
  return jobType === JobType.NL_ANALYZE_TRAJECTORY
}
export function isLidarAnalyzeJob (jobType) {
  return jobType === JobType.NL_ANALYZE_LIDAR_DATA
}
export function isClassificationJob (jobType) {
  return jobType === JobType.CLASSIFICATION
}
export function isPointcloudOptimizationJob (jobType) {
  return jobType === JobType.POINTCLOUD_OPTIMIZATION
}
export function isConverterJob (jobType) {
  return jobType === JobType.CONVERTER
}
export function isPipelineSettingsJob (jobType) {
  return jobType === JobType.PIPELINE_SETTINGS
}
export function isOrthoImageGenerationJob (jobType) {
  return jobType === JobType.ORTHOIMAGE_GENERATION
}
