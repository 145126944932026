// External dependencies.
import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI dependencies.
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
// Icons
import FlightIcon from '@material-ui/icons/FlightOutlined'
import ExploreIcon from '@material-ui/icons/ExploreOutlined'

const Quota = props => {
  const { minutesInMotion, navlabUsed, navlabMax, navProcessingLimitGrant, classes } = props
  const minutes = minutesInMotion.toString()
  const isUserHasUnlimitedNavLabs = navProcessingLimitGrant &&
    !(new Date() > navProcessingLimitGrant.endDate) &&
    navlabMax === 0
  const primaryText = isUserHasUnlimitedNavLabs
    ? <Trans
      i18nKey='navigator.statusBox.unlimitedNavLab.secondary'
      values={{ navlabUsed }}/>
    : <Trans
      i18nKey='navigator.statusBox.unlimitedNavLab.primary'
      values={{ navlabUsed, navlabMax: navlabMax <= 0 ? 0 : navlabMax }}/>
  const secondaryText = isUserHasUnlimitedNavLabs && <Trans i18nKey='navigator.statusBox.unlimitedNavLab.description'/>
  return (
    <React.Fragment>
      <ListItem className={classes.item}>
        <ListItemIcon>
          <FlightIcon />
        </ListItemIcon>
        <ListItemText classes={{ root: classes.container }}>
          <Typography variant='body2' component='span' className={classes.text}>
            <Trans
              i18nKey='navigator.statusBox.minutesInMotion'
              count={minutes}
              values={{ minutes }}
            />
          </Typography>
        </ListItemText>
      </ListItem>
      <ListItem className={classes.item}>
        <ListItemIcon>
          <ExploreIcon />
        </ListItemIcon>
        <ListItemText classes={{ root: classes.container }}>
          <Typography variant='body2' component='span' className={classes.text}>
            {primaryText}
          </Typography>
          {secondaryText && <Typography
            variant='body2'
            component='span'
            className={classes.secondaryText}>{secondaryText}</Typography>}
        </ListItemText>
      </ListItem>
    </React.Fragment>
  )
}

Quota.propTypes = {
  classes: PropTypes.object,
  minutesInMotion: PropTypes.number,
  navlabUsed: PropTypes.number,
  navlabMax: PropTypes.number,
  navProcessingLimitGrant: PropTypes.object,
}

export default Quota
