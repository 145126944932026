
import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
// Project deps
import JobsActions from 'modules/jobs/actions'
import { getProgressJobs } from 'modules/jobs/selectors'
import { UploadStatus, isCurrentStageRunning } from 'modules/upload/utils'
import { getLoggedUser } from 'modules/users/selectors'
import { PrettyJobRunState } from 'types/jobRuns'
import { getIdealChunkSize } from 'modules/upload/config'
import { routeProject, routeProjectPipelines } from 'utils/routing'
import { getFailedFileForFile } from 'utils/upload'
import { getCurrentArtifact } from 'modules/upload/selectors'
import { getAllArtifacts } from 'modules/projects/selectors'
import { useTimer } from 'hooks/useTimer'
import ProgressBar from 'components/Projects/ProgressBar'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  list: {
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  item: {
    padding: 0,
    width: '100%',
    marginBottom: theme.spacing(0.5),
  },
  itemContentContainer: {
    padding: theme.spacing(0, 2.5),
    width: '100%',
  },
}))

const ProgressWidget = props => {
  const { data, history, getJobsForUser, currentUser } = props
  const classes = useStyles()
  useTimer(() => {
    if (currentUser) getJobsForUser(currentUser.id)()
  }, 10000)
  return (
    data.length > 0
      ? <div className={classes.container}>
        <Typography color='inherit' variant='h6' align='center'>
          <Trans i18nKey='progressWidget.title' />
        </Typography>
        <List className={classes.list}>
          {
            data
              .filter(itemData => itemData.progress < 1)
              .map((itemData, { key, style, isScrolling }) => {
                const { id, projectId, pipelineId, type, progress, active, lastJobRunState } = itemData
                const onClick = type === 'project'
                  ? () => history.push(routeProject(projectId) + '/missions')
                  : () => history.push(routeProjectPipelines(projectId, pipelineId))
                const percentage = (progress * 100).toFixed(2)
                return (
                  <MenuItem
                    onClick={onClick}
                    key={id}
                    className={classes.item}>
                    <div className={classes.itemContentContainer}>
                      <ProgressBar
                        done={progress}
                        noGradient
                        active={active}
                        width='100%'
                        height={20}
                        displayStyle='small'
                        border
                        // rounded
                        // redGreenBackground
                        position='absolute'
                        labelCenter={
                          type === 'project'
                            ? <Trans
                              i18nKey={`progressWidget.label.upload.${active ? 'active' : 'pending'}`}
                              values={{ percentage }}
                            />
                            : progress === 0
                              ? PrettyJobRunState[lastJobRunState] || lastJobRunState
                              : <Trans
                                i18nKey='progressWidget.label.pipeline'
                                values={{ percentage }}
                              />
                        }
                      />
                    </div>
                  </MenuItem>
                )
              })
          }
        </List>
      </div>
      : null
  )
}

const mapStateToProps = state => {
  const uploadState = state.upload
  const files = uploadState.get('files')
  const jobs = getProgressJobs(state)
  let uploadingFilesByProject = {}
  if (!files.every(file => file.status === UploadStatus.DONE)) {
    uploadingFilesByProject = files.reduce((allProjects, file) => {
      const failedFileForFile = getFailedFileForFile(file, uploadState)
      const projectId = file.projectId
      const artifactId = file.artifactId
      const currentArtifactState = getCurrentArtifact(state)(artifactId)
      const project = allProjects[projectId] || { totalChunks: 0, completedChunks: 0, active: false }
      const hasChunks = file.chunks || (failedFileForFile && failedFileForFile.chunks)
      // If file doesn't have an array of chunks we just use the size of the file to find out the amount of chunks
      // Completed chunks of such a file is zero (file is not prepared and we don't know the statuses of the chunks)
      // TODO: add ability to prepare chunks of the file while another file is uploading
      const chunks = hasChunks || file.file.size / getIdealChunkSize(file.file.size)
      const chunksNumber = hasChunks ? chunks.length : chunks
      const totalChunks = project.totalChunks + chunksNumber
      // Chunks are uploading in the strict order. So we can use the index of the uncompleted chunk
      // to detect the amount of the uploaded chunks
      const lastIndexOfUncompletedChunk = hasChunks && chunks.findIndex(chunk => typeof chunk === 'object'
        ? !chunk.completed
        : !chunk
      )
      const completedChunks = project.completedChunks + (
        hasChunks
          ? lastIndexOfUncompletedChunk > 0 ? lastIndexOfUncompletedChunk : 0
          : file.status === UploadStatus.DONE
            ? chunksNumber
            : 0
      )
      const active = project.active
        ? project.active
        : !!(currentArtifactState && isCurrentStageRunning(currentArtifactState.stage))
      return {
        ...allProjects,
        [projectId]: {
          active,
          totalChunks,
          completedChunks,
        },
      }
    }, {})
  }
  return {
    state,
    data: [
      ...jobs.map(job => ({ ...job, type: 'pipeline' })),
      ...Object.keys(uploadingFilesByProject).map(key => {
        const project = uploadingFilesByProject[key]
        const { totalChunks, completedChunks, active } = project
        const progress = completedChunks === 0 && totalChunks === 0 ? 0 : completedChunks / totalChunks
        return {
          id: key,
          projectId: key,
          progress,
          type: 'project',
          active,
        }
      }),
    ],
    currentUser: getLoggedUser(state),
    artifacts: getAllArtifacts(state),
    // isDataLoading: isDataForArtifactsLoading(state),
  }
}

const mapDispatchToProps = dispatch => ({
  getJobsForUser: userId => () => dispatch(JobsActions.getJobsForUser(userId)),
})

ProgressWidget.propTypes = {
  userId: PropTypes.string,
  state: PropTypes.object,
  history: PropTypes.object,
  currentUser: PropTypes.object,
  data: PropTypes.array,
  getJobsForUser: PropTypes.func,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgressWidget))
