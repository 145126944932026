import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getMissions for project
  getMission: ['projectId', 'missionId'],
  getMissionLoading: null,
  getMissionSuccess: ['projectId', 'mission'],
  getMissionFailure: ['errorMessage'],
  // getMissions for project
  getMissions: ['projectId', 'exclude'],
  getMissionsLoading: null,
  getMissionsSuccess: ['projectId', 'missions'],
  getMissionsFailure: ['errorMessage'],
  // deleteMission by Id
  deleteMission: ['missionId'],
  deleteMissionLoading: ['missionId'],
  deleteMissionSuccess: ['missionId'],
  deleteMissionFailure: ['errorMessage', 'missionId'],

  // updateMission by Id
  updateMission: ['missionId', 'data'],
  updateMissionLoading: null,
  updateMissionSuccess: ['missionId', 'mission'],
  updateMissionFailure: ['errorMessage'],
})

export const MissionsTypes = Types
export default Creators
