// external dependencies
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// material ui dependencies
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// Project deps
import { theme } from 'HOC/withRoot'

const styles = theme => ({
  ...theme.global,
  label: {
    color: 'black',
    // position: 'absolute',
    zIndex: 3,
    width: '100%',
    padding: '0px 5px',
    lineHeight: '1em',
  },
  labelLeft: {
    left: 0,
    textAlign: 'left',
  },
  labelRight: {
    right: 0,
    textAlign: 'right',
  },
  labelCenter: {
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
  },
  container: {
    position: 'relative',
    width: '',
    background: '#dcdcdc',
  },
  doneBar: {
    position: 'absolute',
    height: '100%',
    zIndex: 2,
    background: theme.palette.primary.alternative,
    transition: 'all .3s linear',
  },
  prepareBar: {
    left: 0,
    top: 0,
    position: 'absolute',
    height: '100%',
    zIndex: 1,
    backgroundColor: theme.palette.secondary.light,
    transition: 'all .3s linear',
  },
  text: {
    lineHeight: '1em',
  },
})

function ProgressBar (props) {
  const {
    classes,
    displayStyle,
    done,
    prepared,
    active,
    doneOffset,
    labelLeft,
    labelRight,
    labelCenter,
    // noGradient,
    backgroundColor,
    width,
    height: inputHeight,
    rounded,
    padded,
    position,
    size,
  } = props
  const donePercent = done * 100
  const doneOffsetPercent = doneOffset ? doneOffset * 100 : 0
  const preparedPercent = prepared * 100
  const height = typeof size !== 'undefined' ? size : displayStyle === 'big' ? 44 : 28
  const fontSize = typeof size !== 'undefined' ? size - 3 : 'inherit'
  const progressBarClassName = active ? `${classes.doneBar} ${classes.active}` : classes.doneBar
  const borderRadius = rounded ? 10 : 0
  const background = typeof backgroundColor === 'string' ? backgroundColor : theme.palette.primary.alternative

  return (
    <div className={classes.container} style={{
      height: inputHeight || height,
      width: width || 'auto',
      borderRadius,
      padding: padded ? 2 : 0,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      {
        labelLeft &&
          <div className={classNames(classes.label, classes.labelLeft)}>
            {labelLeft}
          </div>
      }
      {
        labelCenter &&
          <Typography className={classNames(classes.label, classes.labelCenter)} style={{ fontSize }}
            variant='body2'
            color={'default'}
            classes={{ body2: classes.text }}
          >
            {labelCenter}
          </Typography>
      }
      {
        labelRight &&
          <div className={classNames(classes.label, classes.labelRight)}>
            {labelRight}
          </div>
      }
      <div
        className={progressBarClassName}
        style={{
          background,
          top: 0,
          left: `${doneOffsetPercent}%`,
          width: `${donePercent}%`,
          position: position || 'absolute',
          borderRadius: `${borderRadius}px ${done >= 0.96 ? borderRadius : 0}px ${done === 1 ? borderRadius : 0}px ${borderRadius}px`,
        }}
      />
      <div className={classes.prepareBar} style={{ width: `${preparedPercent}%` }}/>
    </div>
  )
}

ProgressBar.propTypes = {
  border: PropTypes.bool,
  active: PropTypes.bool,
  padded: PropTypes.bool,
  rounded: PropTypes.bool,
  noGradient: PropTypes.bool,
  done: PropTypes.number,
  prepared: PropTypes.number,
  doneOffset: PropTypes.number,
  position: PropTypes.string,
  labelLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  labelRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  labelCenter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  displayStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  classes: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default withStyles(styles)(ProgressBar)
