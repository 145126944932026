import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Typography from '@material-ui/core/Typography'
// Icons
import IconWarning from '@material-ui/icons/Warning'
import IconError from '@material-ui/icons/Error'
import IconInfo from '@material-ui/icons/Info'

const Warning = ({
  large,
  variant = 'error',
  error,
  disableIcon = false,
  children,
  containerStyle = {},
  style = {},
  ...otherProps
}) => {
  let Icon = null
  let color = ''
  switch (variant) {
    case 'info':
      color = '#4e4ee7'
      Icon = IconInfo
      break
    case 'error':
      color = '#f44336'
      Icon = IconError
      break
    case 'warning':
      color = '#ea7720'
      Icon = IconWarning
      break
    default:
      color = '#f44336'
      Icon = IconError
  }

  return (
    <Typography
      variant='body2'
      style={{
        fontStyle: 'italic',
        color,
        whiteSpace: 'normal',
        ...containerStyle,
      }} {...otherProps}>
      {!disableIcon &&
          <Icon
            fontSize={large ? 'large' : 'default'}
            style={{
              marginRight: '10px',
              verticalAlign: 'middle',
              fontSize: large ? 64 : 24,
              color,
              ...style,
            }} />
      }
      {children}
    </Typography>
  )
}

Warning.propTypes = {
  large: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.object,
  variant: PropTypes.oneOf(['info', 'error', 'warning']),
  disableIcon: PropTypes.bool,
  containerStyle: PropTypes.object,
}

export default Warning
