// External dependencies.
import React from 'react'
// Material Ui dependencies.
import IconButton from '@material-ui/core/IconButton'
// Icons
import ClearIcon from '@material-ui/icons/Clear'

const RemoveIconButton = props => {
  return (
    <IconButton {...props}>
      <ClearIcon />
    </IconButton>
  )
}

export default RemoveIconButton
