import { convertRawPipeline } from 'types/pipelines'
import axios from 'utils/axios'

export async function createPipeline (headers, projectId, name, emailNotification) {
  try {
    const url = `/projects/${projectId}/pipelines`
    const body = { name, email_notification: emailNotification }
    const result = await axios.post(url, body, { headers })
    const pipeline = convertRawPipeline(result.data.data)
    return pipeline
  } catch (e) {
    throw e
  }
}
