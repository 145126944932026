import { Map } from 'immutable'
import { path } from 'ramda'
import { filterByArtifactId, findById } from 'utils/list'

export const getProjectsByUser = state => {
  return state.projects.get('projectsByUser')
}

export const getProjects = state => {
  return state.projects.get('projects')
}

export const isTransferProjectLoading = state => state.projects.get('transferProjectIsLoading')

export const getCurrentProject = state => {
  return state.projects.get('currentProject')
}
export const getCurrentProjectId = state => {
  const currentProject = state.projects.get('currentProject')
  return currentProject && currentProject.projectId
}

export const getCurrentProjectArtifacts = state => {
  const currentProject = state.projects.get('currentProject')
  return currentProject && currentProject.artifacts
}

export const isCurrentProjectDataLoading = state => {
  return state.projects.get('getCurrentProjectDataIsLoading')
}

export const getArtifacts = state => {
  const isMap = state instanceof Map
  return isMap
    ? state.get('currentProject').artifacts
    : state.projects.get('currentProject').artifacts
}

export const getAllArtifacts = state => {
  const isMap = state instanceof Map
  return isMap
    ? state.get('allArtifacts')
    : state.projects.get('allArtifacts')
}

export const getDataDirectories = state => {
  return state.projects.get('currentProject').dataDirectories
}

export const getDataFiles = state => {
  return state.projects.get('currentProject').dataFiles
}

export const getDataFilesForArtifact = (state, artifactId) => {
  return filterByArtifactId(artifactId, state.projects.get('currentProject').dataFiles)
}

export const getDataDirectoriesForArtifact = (state, artifactId) => {
  return filterByArtifactId(artifactId, state.projects.get('currentProject').dataDirectories)
}

export const getArtifactById = ({ projects: state }) => artifactId => {
  return findById(artifactId, getAllArtifacts(state))
}

export const getPipelines = state => {
  return state.projects.get('currentProject').pipelines
}

export const getArtifact = (state, artifactId) => {
  return findById(artifactId, state.projects.get('currentProject').artifacts)
}

export const getAllArtifactDataLoading = state => {
  return state.projects.get('artifactDataLoading')
}

export const isArtifactDataLoading = (state, artifactId) => {
  return !!path(['isLoading'], state.projects.get('artifactDataLoading')[artifactId])
}

export const isArtifactDataLoaded = (state, artifactId) => {
  return !!path(['loaded'], state.projects.get('artifactDataLoading')[artifactId])
}

export const isPipelineDataLoading = (state, pipelineId) => {
  return !!state.projects.get('pipelineDataLoading')[pipelineId]
}

export const isProjectDeleteRequestLoading = (state, projectId) => state.projects.get('deleteProjectRequest').includes(projectId)
export const projectsDeleteRequestLoading = state => state.projects.get('deleteProjectRequest')

export const isDataForEditMissionLoading = state => !!state.projects.get('getDataForEditMissionArtifactsIsLoading')

export const getDeletePipelineState = state => state.projects.get('deletePipelineState')

export const getMinimalProjects = state => {
  return state.projects.get('minimalProjects')
}

export const getMissions = state => {
  return state.projects.get('currentProject').missions
}

export const getPositions = state => {
  return state.projects.get('currentProject').positions
}

export const getMinimalProjectsIsLoading = state => {
  return state.projects.get('getProjectsIsLoading')
}

export const getPagination = state => {
  return state.projects.get('pagination')
}

export const getCurrentProjectDataForPipelineIsLoading = state => {
  return !!state.projects.get('getCurrentProjectDataForPipelineIsLoading')
}

export const isGetProjectsQueriedLoading = state => {
  return !!state.projects.get('getProjectsQueriedIsLoading')
}

export const isGetProjectsByUserLoading = state => {
  return !!state.projects.get('getProjectsByUserIsLoading')
}

export const isArtifactUpdateLoading = state => state.projects.get('updateArtifactIsLoading')

export const isDataForMissionArtifactsLoading = state => state.projects.get('getDataForMissionArtifactsIsLoading')

export const isListProjectsLoading = state => state.projects.get('listProjectsIsLoading')

export const isDeleteProjectLoading = (state, id) => !!state.projects.get('deleteProject')[id]

export const isDataForArtifactsLoading = state => state.projects.get('getDataForArtifactsIsLoading')

export const isGetPipelinesForCurrentProjectLoading = state => state.projects.get('getPipelinesForCurrentProjectIsLoading')
export const isGetRecursivePipelinesForCurrentProjectDone = state => state.projects.get('recursivePipelinesIsDone')
export const isGetRecursivePipelinesForCurrentProjectLoading = state => state.projects.get('recursivePipelinesIsLoading')

export const getUTMZoneIsLoading = (state, projectId) => path([projectId, 'loading'], state.projects.get('utmZone'))
export const getUTMZoneData = (state, projectId) => path([projectId, 'data'], state.projects.get('utmZone'))

export const getArtifactsByProject = state => state.projects.get('artifactsByProject')
export const isGetProjectArtifactsLoading = state => state.projects.get('getProjectArtifactsIsLoading')

export const getArtifactGCPs = state => state.projects.get('gcps')

export const isCreateCRSLoading = state => state.projects.get('createCRSLoading')

export const getCurrentProjectCRSId = state => path(['coordinateReferenceSystem', 'id'], state.projects.get('currentProject').project)
export const isCreateProjectLoading = state => state.projects.get('createProjectIsLoading')

export const getDownloadToken = state => state.projects.get('downloadToken')
export const isGetDownloadTokenLoading = state => state.projects.get('isGetDownloadTokenLoading')
