/**
 * This enum is used to define the usage direction of artifacts in job ios.
 */
export const IOType = {
  INPUT: 'input',
  OUTPUT: 'output',
}

export function convertRawJobIO (raw) {
  const { id, job_io_type, artifact, job, created, updated, options } = raw
  return {
    id,
    artifactId: artifact.id,
    jobId: job.id,
    ioType: job_io_type,
    options,
    created: new Date(created),
    updated: new Date(updated),
  }
}
