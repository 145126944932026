import { isFileNameEndsWith } from 'utils/baseName'

export const GeoTiffFileTypes = [
  '.tif', '.tiff',
]

/**
 * Checks whether the file name of a file looks like a trajectory file name.
 */
// function isTrjFileName (fileName: string): boolean
export function isGeotiffFileName (fileName) {
  return GeoTiffFileTypes.some(geotiffExtension => isFileNameEndsWith(fileName, geotiffExtension))
}

export function isGeotiffFileType (fileType) {
  return fileType === 'tiff'
}

/**
 * "Parses" a file and checks if it really is a nav file by looking at the filename.
 * **This function does not really parse any information from the ".nav" file**. It is just named like this
 * in order to stay consistent with the naming for other file formats.
 * @param file The file which should be checked.
 * @return An object containing information about whether the file is a valid nav file, a log about the
 *   parsing and the information read from the header.
 */
// function parseNavFile(file: File): Promise<GenericParserInfo>
export async function parseGeotiffFile (file) {
  return new Promise(resolve => {
    const log = []
    if (!isGeotiffFileName(file.name)) {
      log.push({
        level: 'error',
        message: `Unsupported file extension in filename "${file.name}"`,
      })
      resolve({ okay: false, log, fileType: 'tiff' })
    }
    if (file.size === 0) {
      log.push({ level: 'error', message: 'Invalid file size. File is empty.' })
      resolve({ okay: false, log, fileType: 'tiff' })
      return
    }
    resolve({ okay: true, log, fileType: 'tiff' })
  })
}
