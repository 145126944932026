import { createReducer } from 'reduxsauce'

import { removeById } from 'utils/list'
// Local deps
import { INITIAL_STATE } from './initialState'
import { WorkersTypes } from './actions'

export const getWorkersLoading = state =>
  state.merge({
    getWorkersIsLoading: true,
    getWorkersErrorMessage: '',
  })

export const getWorkersSuccess = (state, { workers }) =>
  state.merge({
    workers,
    getWorkersIsLoading: false,
    getWorkersErrorMessage: null,
  })

export const getWorkersFailure = (state, { errorMessage }) =>
  state.merge({
    workers: [],
    getWorkersIsLoading: false,
    getWorkersErrorMessage: errorMessage,
  })

export const getWorkerRulesLoading = state =>
  state.merge({
    getWorkerRulesIsLoading: true,
    getWorkerRulesErrorMessage: '',
  })

export const getWorkerRulesSuccess = (state, { rules }) =>
  state.merge({
    rules,
    getWorkerRulesIsLoading: false,
    getWorkerRulesErrorMessage: null,
  })

export const getWorkerRulesFailure = (state, { errorMessage }) =>
  state.merge({
    rules: [],
    getWorkerRulesIsLoading: false,
    getWorkerRulesErrorMessage: errorMessage,
  })

export const deleteWorkerRuleLoading = state => state.merge({})
export const deleteWorkerRuleSuccess = (state, { id }) =>
  state.merge({
    rules: removeById(id, state.get('rules')),
  })
export const deleteWorkerRuleFailure = state => state.merge({})

export const addWorkerRuleLoading = state => state.merge({})
export const addWorkerRuleFailure = state => state.merge({})

export const reducer = createReducer(INITIAL_STATE, {
  [WorkersTypes.GET_WORKERS_LOADING]: getWorkersLoading,
  [WorkersTypes.GET_WORKERS_SUCCESS]: getWorkersSuccess,
  [WorkersTypes.GET_WORKERS_FAILURE]: getWorkersFailure,

  [WorkersTypes.GET_WORKER_RULES_LOADING]: getWorkerRulesLoading,
  [WorkersTypes.GET_WORKER_RULES_SUCCESS]: getWorkerRulesSuccess,
  [WorkersTypes.GET_WORKER_RULES_FAILURE]: getWorkerRulesFailure,

  [WorkersTypes.DELETE_WORKER_RULE_LOADING]: deleteWorkerRuleLoading,
  [WorkersTypes.DELETE_WORKER_RULE_SUCCESS]: deleteWorkerRuleSuccess,
  [WorkersTypes.DELETE_WORKER_RULE_FAILURE]: deleteWorkerRuleFailure,

  [WorkersTypes.ADD_WORKER_RULE_LOADING]: addWorkerRuleLoading,
  [WorkersTypes.ADD_WORKER_RULE_FAILURE]: addWorkerRuleFailure,
})
