import { getDateWithTimezone } from 'utils/dateTime'

export const WorkerStates = {
  WAITING: 'WAITING',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  DOWNLOADING: 'DOWNLOADING',
}

export const WorkerState = {
  [WorkerStates.WAITING]: 'waiting',
  [WorkerStates.UPLOADING]: 'uploading',
  [WorkerStates.PROCESSING]: 'processing',
  [WorkerStates.DOWNLOADING]: 'downloading',
}

const WorkerTypeNames = {
  PPC: 'PPC',
  SF: 'SF',
  IE: 'IE',
  POTREE_CONVERTER: 'POTREE_CONVERTER',
  TEST: 'TEST',
  SDC_IMPORT: 'SDC_IMPORT',
}

export const WorkerTypes = {
  [WorkerTypeNames.PPC]: 'PPCWorker',
  [WorkerTypeNames.SF]: 'SFWorker',
  [WorkerTypeNames.IE]: 'IEWorker',
  [WorkerTypeNames.POTREE_CONVERTER]: 'PotreeConverterWorker',
  [WorkerTypeNames.TEST]: 'TestWorker',
  [WorkerTypeNames.SDC_IMPORT]: 'SdcImportWorker',
}

export const ShortWorkerTypeNames = {
  [WorkerTypeNames.PPC]: 'PPC',
  [WorkerTypeNames.SF]: 'SF',
  [WorkerTypeNames.IE]: 'IE',
  [WorkerTypeNames.POTREE_CONVERTER]: 'Potree converter',
  [WorkerTypeNames.TEST]: 'Test',
  [WorkerTypeNames.SDC_IMPORT]: 'Sdc import',
}

/**
 * Converts a `RawWorker` to a `Worker`.
 * @param raw The raw worker as received by the Api.
 * @return The converted worker for use in the rest of this application.
 */
export function convertRawWorker (raw) {
  const {
    id,
    pipeline_id,
    host,
    job_run,
    job_type,
    last_message,
    token,
    worker_state,
    worker_type,
    created,
    last_contact,
    started_at,
    updated,
  } = raw
  return {
    id,
    pipelineId: pipeline_id,
    host,
    token,
    type: worker_type,
    state: worker_state,
    jobRun: job_run,
    jobType: job_type,
    lastMessage: last_message,
    created: typeof created === 'string' ? getDateWithTimezone(created) : undefined,
    lastContact: typeof last_contact === 'string' ? getDateWithTimezone(last_contact) : undefined,
    startedAt: typeof started_at === 'string' ? getDateWithTimezone(started_at) : undefined,
    updated: typeof updated === 'string' ? getDateWithTimezone(updated) : undefined,
  }
}

/**
 * Converts a `RawWorkerRule` to a `WorkerRule`.
 * @param raw The raw workerRule as received by the Api.
 * @return The converted workerRule for use in the rest of this application.
 */
export function convertRawWorkerRule (raw) {
  const {
    id,
    pipeline_name,
    worker_host,
    worker_token,
    created,
    updated,
  } = raw
  return {
    id,
    pipelineName: pipeline_name,
    host: worker_host,
    token: worker_token,
    created: typeof created === 'string' ? getDateWithTimezone(created) : undefined,
    updated: typeof updated === 'string' ? getDateWithTimezone(updated) : undefined,
  }
}
