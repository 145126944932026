import React from 'react'
import { Trans } from 'react-i18next'
import { SystemType } from 'types/missions'
import { isFileNameEndsWith } from './baseName'
import { baseEnvironment } from './permissions'

export const isSpatialExplorerV3SystemType = missionType => {
  return missionType === SystemType.PHOENIX_V3
}

export const isSpatialExplorerV4V5SystemType = missionType => {
  return missionType === SystemType.PHOENIX_V4_V5
}

export const isHyspexSystemType = missionType => {
  return missionType === SystemType.HYSPEX
}

export const isReconSystemType = missionType => {
  return missionType === SystemType.RECON
}

export const isRequired = (min, max) => {
  return min >= 1
}

export const countText = (min, max) => {
  return max >= 2
    ? <Trans i18nKey='missionTemplate.files.multiple'/>
    : <Trans i18nKey='missionTemplate.files.single'/>
}

export const requiredText = (isRequired, type) => {
  return isRequired
    ? <Trans i18nKey='missionTemplate.files.required' values={{ type }}/>
    : <Trans i18nKey='missionTemplate.files.nonRequired' values={{ type }}/>
}

/**
 * Checks if mission template key is valid
 * @param {Object} missionTemplate
 * @param {array} files
 * @param {string} key
 * @param {array} fileSuggestions
 */
export const isMissionTemplateKeyValid = (missionTemplate, files, key, fileSuggestions = []) => {
  const template = missionTemplate[key]
  const { min, max, isMissionValid } = template
  // const filesWithKey = files.filter(file => file.fileType === key)
  if (typeof isMissionValid === 'function') return isMissionValid(files, fileSuggestions)

  const filesWithKey = files.filter(({ file: { name } }) => isFileInTemplate(key, template, name))
  let numberOfFilesWithKey = filesWithKey.length
  if (numberOfFilesWithKey > max) {
    numberOfFilesWithKey = filesWithKey.filter(({ file: { name } }) => fileSuggestions.indexOf(name) < 0).length
  }
  return numberOfFilesWithKey >= min && numberOfFilesWithKey <= max
}

/**
 * Checks if mission template is valid
 * @param {Object} missionTemplate
 * @param {array} files
 * @param {array} fileSuggestions
 */
export const isMissionTemplateValid = (missionTemplate, files, fileSuggestions) => {
  const templateKeys = missionTemplate ? Object.keys(missionTemplate) : []
  return templateKeys.every(key => isMissionTemplateKeyValid(missionTemplate, files, key, fileSuggestions))
}

/**
 * Checks if fileName has a valid extension for the template
 * @param {string} key
 * @param {Object} template
 * @param {string} fileName
 */
export const isFileInTemplate = (key, template, fileName) => {
  const { fileTypes = [] } = template
  return fileTypes.length > 0
    ? fileTypes.some(fileType => isFileNameEndsWith(fileName, fileType))
    : isFileNameEndsWith(fileName, `.${key}`)
}

export const getMissionDownloadLink = token => {
  return `https://download.phoenixlidar.com/missions/${token}?compress_level=0&base=${baseEnvironment}`
}
