import { call, select, put, takeLeading, takeLatest, fork, all, join, takeEvery } from 'redux-saga/effects'
import { omit, path } from 'ramda'
import { toast } from 'react-toastify'
import i18n from 'i18n'
// Project deps
import {
  getFileTypeByFileExtension,
  shouldFileBeParsed,
  parseFileByFileExtension,
  parseDropboxFileByFileExtension,
  isPlpFileType,
  getFileNameForFilePropertiesWithDefinedCRS, isTrjFileType, isParsableImageFile,
} from 'modules/upload/file-types'
import { getCurrentProject } from 'modules/projects/selectors'
// import API from '../projects/api'
import ArtifactsActions from 'modules/artifacts/actions'
import ProjectsActions from 'modules/projects/actions'
import axios from 'utils/axios'
import { isCameraArtifact, isLidarArtifact, isGCPArtifact } from 'types/artifacts'
import { getErrorMessage, showErrorMessage } from 'utils/api'
import { CRSFieldsList } from 'templates/CRS/constants'
import { getGCP } from '../utils'
// Local deps
import { handleFiles, handleDropboxFiles, createArtifacts, getArtifactProperties, mergeFilesIntoArtifact } from './utils'
import { getImportWizard } from './selectors'
import ImportWizardActions, { ImportWizardTypes } from './actions'
import UploadActions from 'modules/upload/actions'
import { FILES_FIELD, PROTO_ARTIFACT_FIELD } from 'types/importWizard'
import { UploaderType } from 'types/upload'
import { getCRSExtendedInfo } from 'modules/crs/api'

function * addDocumentationFiles ({ files, filesField, artifactsField }) {
  yield put(ImportWizardActions.setAnalyze(true))
  try {
    const analyzedFiles = files.map(file => ({
      file,
      fileType: 'doc',
      okay: file.size > 0,
      logs: file.size <= 0 ? [{ level: 'error', message: 'Invalid file size. File is empty.' }] : [],
    }))
    yield put(ImportWizardActions.filesAnalyzed(analyzedFiles, filesField, artifactsField))
  } catch (e) {
    yield put(ImportWizardActions.setAnalyze(false))
  }
}

function * addReferenceStationFiles ({ files, protoArtifactId }) {
  try {
    const analyzedFiles = yield call(analyzeFiles, files)
    yield put(ImportWizardActions.addReferenceStationFilesSuccess(analyzedFiles, protoArtifactId))
  } catch (e) {
  }
}

function * addFiles ({ files, filesField = FILES_FIELD.MISSION, artifactsField = PROTO_ARTIFACT_FIELD.MISSION, fileType }) {
  yield put(ImportWizardActions.setAnalyze(true))
  try {
    const analyzedFiles = yield call(analyzeFiles, files, undefined, fileType)
    yield put(ImportWizardActions.filesAnalyzed(analyzedFiles, filesField, artifactsField))
  } catch (e) {
    yield put(ImportWizardActions.setAnalyze(false))
  }
}

function * addUploadFiles ({ files, artifactId, projectId, artifact }) {
  try {
    const analyzedFiles = yield call(analyzeFiles, files)
    const newArtifact = mergeFilesIntoArtifact(artifact, analyzedFiles)
    const newProperties = { ...newArtifact.properties }
    const firstFileWithCRSInFileProperties = getFileNameForFilePropertiesWithDefinedCRS(newProperties.fileNames, newProperties)
    if (firstFileWithCRSInFileProperties) {
      const firstCRSFromFileProperties = newProperties.fileProperties[firstFileWithCRSInFileProperties].crs
      newProperties.fileProperties = {
        ...analyzedFiles.reduce((allFileProps, file) => {
          return {
            ...allFileProps,
            [file.file.name]: {
              ...allFileProps[file.file.name],
              crs: firstCRSFromFileProperties,
            },
          }
        }, newProperties.fileProperties || {}),
      }
    }
    yield put(ProjectsActions.setArtifactProperties(artifactId, newProperties))
    yield put(UploadActions.addFiles(analyzedFiles, artifactId, projectId))
  } catch (e) {
  }
}

function * addUploadDropboxFiles ({ files, artifactId, projectId, artifact }) {
  try {
    const analyzedFiles = yield call(analyzeDropboxFiles, files)
    const newArtifact = mergeFilesIntoArtifact(artifact, analyzedFiles)
    const newProperties = { ...newArtifact.properties }
    const firstFileWithCRSInFileProperties = getFileNameForFilePropertiesWithDefinedCRS(newProperties.fileNames, newProperties)
    if (firstFileWithCRSInFileProperties) {
      const firstCRSFromFileProperties = newProperties.fileProperties[firstFileWithCRSInFileProperties].crs
      newProperties.fileProperties = {
        ...analyzedFiles.reduce((allFileProps, file) => {
          return {
            ...allFileProps,
            [file.file.name]: {
              ...allFileProps[file.file.name],
              ...(!isTrjFileType(file.fileType) && { crs: firstCRSFromFileProperties }),
            },
          }
        }, newProperties.fileProperties || {}),
      }
    }
    yield put(ProjectsActions.setArtifactProperties(artifactId, newProperties))
    yield put(UploadActions.addFiles(analyzedFiles, artifactId, projectId))
  } catch (e) {
  }
}

function * addDropboxFiles ({ files, filesField = FILES_FIELD.MISSION, artifactsField = PROTO_ARTIFACT_FIELD.MISSION, fileType }) {
  yield put(ImportWizardActions.setAnalyze(true))
  try {
    const analyzedFiles = yield call(analyzeDropboxFiles, files, undefined, fileType)
    yield put(ImportWizardActions.filesAnalyzed(analyzedFiles, filesField, artifactsField))
  } catch (e) {
    yield put(ImportWizardActions.setAnalyze(false))
  }
}

function * addMissionFiles ({ files, MissionTemplate }) {
  try {
    yield put(ImportWizardActions.setAnalyze(true))
    const { currentMissionId: missionId } = yield select(state => getImportWizard(state, ['currentMissionId']))
    const analyzedFiles = yield call(analyzeFiles, files, missionId)
    yield put(ImportWizardActions.missionFilesAnalyzed(analyzedFiles))
  } catch (e) {
    yield put(ImportWizardActions.setAnalyze(false))
  }
}

function * addMissionDropboxFiles ({ files, MissionTemplate }) {
  try {
    yield put(ImportWizardActions.setAnalyze(true))
    const { currentMissionId: missionId } = yield select(state => getImportWizard(state, ['currentMissionId']))
    const analyzedFiles = yield call(analyzeDropboxFiles, files, missionId)
    yield put(ImportWizardActions.missionFilesAnalyzed(analyzedFiles))
  } catch (e) {
    yield put(ImportWizardActions.setAnalyze(false))
  }
}

function * addEditMissionFiles ({ files, MissionTemplate }) {
  yield put(ImportWizardActions.setAnalyze(true))
  try {
    const {
      editMissionId: missionId,
      editMission,
    } = yield select(state => getImportWizard(state, ['editMissionId', 'editMission']))
    const analyzedFiles = yield call(analyzeFiles, files, missionId)
    yield put(ImportWizardActions.missionFilesAnalyzed(
      analyzedFiles.filter(file => !isPlpFileType(file.fileType)),
      'editMissionProtoArtifacts',
      'editMissionFiles',
      ['editMissionFiles', 'editMissionProtoFiles'],
      editMission,
      'editMissionId',
      MissionTemplate,
    ))
  } catch (e) {
    yield put(ImportWizardActions.setAnalyze(false))
  }
}

function * addEditMissionDropboxFiles ({ files, MissionTemplate }) {
  yield put(ImportWizardActions.setAnalyze(true))
  try {
    const {
      editMissionId: missionId,
      editMission,
    } = yield select(state => getImportWizard(state, ['editMissionId', 'editMission']))
    const analyzedFiles = yield call(analyzeDropboxFiles, files, missionId)
    yield put(ImportWizardActions.missionFilesAnalyzed(
      analyzedFiles.filter(file => !isPlpFileType(file.fileType)),
      'editMissionProtoArtifacts',
      'editMissionFiles',
      ['editMissionFiles', 'editMissionProtoFiles'],
      editMission,
      'editMissionId',
      MissionTemplate,
    ))
  } catch (e) {
    yield put(ImportWizardActions.setAnalyze(false))
  }
}

function getUnalyzedFile (file, missionId) {
  const fileType = getFileTypeByFileExtension(file)
  return {
    file,
    fileType,
    okay: file.size > 0,
    logs: file.size <= 0 ? [{ level: 'error', message: 'Invalid file size. File is empty.' }] : [],
    missionId,
  }
}

/**
 * Analyzes the files by inspecting their file extension and trying to parse them with the respective
 * parser. Will return a list of the files together with the status of parsing them, their `FileType` as well
 * as the result from parsing them.
 * @param files The files which should be analyzed.
 * @return The analyzed files.
 */
function * analyzeFile (file, missionId, parseFunction, predefinedFileType) {
  let fileType
  if (predefinedFileType) {
    fileType = predefinedFileType
  } else {
    fileType = yield getFileTypeByFileExtension(file)
  }
  if (shouldFileBeParsed(file)) {
    const parseResult = yield call(parseFunction, file, fileType)
    if (parseResult) {
      return {
        file,
        fileType,
        parseResult,
        okay: parseResult.okay,
        missionId,
      }
    }
  }

  return getUnalyzedFile(file, missionId)
}

/**
 * Run parser function for files
 * Js has some limitations on the number of concurrently running functions.
 * TODO: May be it's a browser limitation or something like this. Investigate this
 */
function * handleAnalyze (files, missionId, uploaderType, fileType) {
  // Choose the parser function
  const parseFunction = uploaderType === UploaderType.DROPBOX
    ? parseDropboxFileByFileExtension
    : parseFileByFileExtension
  let tasks = []
  const filesLimit = 50
  const filesToParse = []
  let imagesCount = 0
  let result = []

  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const isParsableImage = isParsableImageFile(file)
    // We should parse small amount of image files because we need to know their dimensions
    // Parsing a lot of images can be a time consuming task especially for images with large resolutions
    // So for now let's check every 100th image in the list
    if (isParsableImage) {
      if (imagesCount % 100 === 0) {
        filesToParse.push(file)
      } else {
        // For parsable images that are not not suitable we should skip parsing at all
        result.push(getUnalyzedFile(file, missionId))
      }
      imagesCount++
    } else {
      filesToParse.push(file)
    }
  }

  const filesCount = filesToParse.length
  let i = 1
  let endIndex = 0
  do {
    const startIndex = filesLimit * (i - 1)
    const step = (startIndex + filesLimit)
    endIndex = step > filesCount ? filesCount : step
    for (let i = startIndex; i < endIndex; i++) {
      tasks.push(yield fork(analyzeFile, filesToParse[i], missionId, parseFunction, fileType))
    }
    const tempResult = yield all(tasks.map(t => join(t)))
    result = [...result, ...tempResult]
    tasks = []
    i++
  } while (endIndex < filesCount)
  return result
}

function * analyzeFiles (files, missionId, fileType) {
  console.log('analyzeFiles')
  console.log(files)
  console.log(fileType)
  const analyzedFiles = yield call(handleAnalyze, files, missionId, undefined, fileType)
  return analyzedFiles
}

function * analyzeDropboxFiles (files, missionId, fileType) {
  const analyzedFiles = yield call(handleAnalyze, files, missionId, UploaderType.DROPBOX, fileType)
  return analyzedFiles
}

function * addDocumentationFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_DOCUMENTATION_FILES, addDocumentationFiles)
}

function * addFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_FILES, addFiles)
}

function * addDropboxFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_DROPBOX_FILES, addDropboxFiles)
}

function * addUploadFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_UPLOAD_FILES, addUploadFiles)
}

function * addUploadDropboxFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_UPLOAD_DROPBOX_FILES, addUploadDropboxFiles)
}

function * addMissionFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_MISSION_FILES, addMissionFiles)
}

function * addMissionDropboxFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_MISSION_DROPBOX_FILES, addMissionDropboxFiles)
}

function * addEditMissionFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_EDIT_MISSION_FILES, addEditMissionFiles)
}

function * addEditMissionDropboxFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_EDIT_MISSION_DROPBOX_FILES, addEditMissionDropboxFiles)
}

// const results = (fileProperties[fileName] || firstFile ? firstFile.parseResult : {}).result.slice(startIndex, gcpsPoints.length)
function * handleGCPProtoArtifacts ({ currentProject, protoArtifacts, gcps, protoArtifactToArtifactMap }) {
  try {
    const { projectId, project = {} } = currentProject
    const { gcps: projectGCPs } = project
    const gcpPoints = protoArtifacts.reduce((allGCP, protoArtifact) => {
      const { properties, id, files = [] } = protoArtifact
      const { startIndex, columnAssignments } = properties
      const gcpsPoints = gcps[id] || []
      const [firstFile] = files
      const { name: nameIndex } = columnAssignments
      const results = ((firstFile ? firstFile.parseResult : {}).result || []).slice(startIndex, gcpsPoints.length)
      const transformedCoordinates = gcpsPoints.map((gcpPoint, index) => {
        const result = results[index]
        return {
          ...gcpPoint,
          ...(result && {
            name: Array.isArray(result)
              ? result[nameIndex]
              : result.okay
                ? result.data[nameIndex]
                : `GCP ${index}`,
          }),
        }
      })
      return { ...allGCP, [protoArtifactToArtifactMap[id]]: transformedCoordinates }
    }, (projectGCPs || {}))

    if (Object.keys(gcpPoints).length > 0) {
      yield put(ProjectsActions.updateProject(projectId, { gcps: gcpPoints }))
    }
  } catch (e) {

  }
}

function * confirmEditGCP ({ artifact, protoArtifact }) {
  const state = yield select()
  const [
    currentProject,
    { gcps }] = yield select(state => [
    getCurrentProject(state),
    getImportWizard(state, ['gcps']),
  ])
  const newProperties = {
    ...omit(CRSFieldsList, artifact.properties),
    ...omit(['fileProperties'], getArtifactProperties(protoArtifact, state)),
  }
  yield call(handleGCPProtoArtifacts, { currentProject, protoArtifacts: [protoArtifact], gcps, protoArtifactToArtifactMap: { [protoArtifact.id]: artifact.id } })
  yield put(ArtifactsActions.updateArtifact(artifact.id, undefined, omit(['crs', 'crsDescription'], newProperties)))
  yield put(ImportWizardActions.reset([PROTO_ARTIFACT_FIELD.EDIT_GCP, FILES_FIELD.EDIT_GCP]))
}

function * handleProtoArtifacts ({ currentProject, protoArtifacts, missionIds, gcps }) {
  const { projectId } = currentProject
  const protoArtifactToArtifactMap = yield call(createArtifacts, projectId, protoArtifacts, missionIds)
  const cameraProtoArtifacts = protoArtifacts.filter(artifact => isCameraArtifact(artifact.artifactType))
  const lidarProtoArtifacts = protoArtifacts.filter(artifact => isLidarArtifact(artifact.artifactType))
  const gcpProtoArtifacts = protoArtifacts.filter(artifact => isGCPArtifact(artifact.artifactType))
  const otherProtoArtifacts = protoArtifacts.filter(artifact =>
    !isLidarArtifact(artifact.artifactType) &&
    !isCameraArtifact(artifact.artifactType) &&
    !isGCPArtifact(artifact.artifactType),
  )
  const sortedProtoArtifacts = [
    ...otherProtoArtifacts,
    ...lidarProtoArtifacts,
    ...gcpProtoArtifacts,
    ...cameraProtoArtifacts,
  ]
  if (gcpProtoArtifacts.length >= 0) {
    yield call(handleGCPProtoArtifacts, { currentProject, protoArtifacts: gcpProtoArtifacts, gcps, protoArtifactToArtifactMap })
  }

  // Handling all the files
  const { options, onlyDropboxUploads } = yield call(
    handleFiles,
    sortedProtoArtifacts,
    protoArtifactToArtifactMap,
    projectId,
  )
  // Handling Dropbox files
  const result = yield call(handleDropboxFiles, { options, projectId, onlyDropboxUploads })
  return result
}

function * confirm ({
  artifactsField,
  fieldsToReset,
  withoutMissions = true,
  protoArtifacts: inputProtoArtifacts,
  project,
  onSuccess,
}) {
  const isArtifactsFieldArray = Array.isArray(artifactsField)
  const firstArtifactsField = isArtifactsFieldArray ? artifactsField[0] : artifactsField
  yield put(ImportWizardActions.setConfirm(firstArtifactsField, true))
  const [currentProject, wizardState] = yield select(state => [
    getCurrentProject(state),
    getImportWizard(state, [...(isArtifactsFieldArray ? artifactsField : [artifactsField]), 'plp', 'missions', 'gcps']),
  ])
  const { plp, missions, gcps, ...otherFields } = wizardState
  const basicProtoArtifact = Object.keys(otherFields).reduce((all, key) => {
    return [
      ...all,
      ...otherFields[key],
    ]
  }, [])
  const projectToUse = project || currentProject
  // const projectMissions = yield select(state => getMissions(state))
  const { projectId } = projectToUse
  const protoArtifacts = inputProtoArtifacts || basicProtoArtifact.filter(protoArtifact => protoArtifact.files.length > 0)
  try {
    const backendMissionIds = {}
    // Here we create missions on backend if we need them
    // Eg for gcps, ref station and pointcloud trajectory artifacts we don't need missions
    if (!withoutMissions) {
      const backendMissions = []
      for (const templateMission of missions) {
        const { id: missionId, type, name } = templateMission
        const missionPlp = plp[missionId]
        // const missionName = getNewMissionName(projectMissions)
        const { data: { data: mission } } = yield call(axios.post,
          `/projects/${projectId}/missions`,
          {
            name,
            plp: missionPlp && JSON.parse(missionPlp),
            system_type: { name: type },
            // mission_type: type,
          })
        backendMissionIds[missionId] = mission.id
        backendMissions.push(mission)
      }
      yield put(ProjectsActions.addMissions(backendMissions))
    }

    const result = yield call(handleProtoArtifacts, {
      currentProject: projectToUse,
      protoArtifacts,
      missionIds: backendMissionIds,
      gcps,
    })
    if (result && result.okay) toast.success(i18n.t('toast.importWizard.startUpload'))
    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess()
    }
    yield put(ImportWizardActions.setConfirm(firstArtifactsField, false))
    yield put(ImportWizardActions.reset(fieldsToReset))
  } catch (error) {
    console.error(error)
  }
}

function * confirmEditMission () {
  const [
    currentProject,
    {
      editMissionProtoArtifacts: basicProtoArtifact,
      editMissionId,
      gcps,
    },
  ] = yield select(state => [
    getCurrentProject(state),
    getImportWizard(state, ['editMissionProtoArtifacts', 'editMissionId', 'gcps']),
  ])
  const protoArtifacts = basicProtoArtifact.filter(protoArtifact => protoArtifact.files.length > 0)
  try {
    yield call(handleProtoArtifacts, {
      currentProject,
      protoArtifacts,
      missionIds: { [editMissionId]: editMissionId },
      gcps,
    })
    yield put(ImportWizardActions.reset([
      'editMissionId',
      'editMission',
      'editDialogOpen',
      'editMissionArtifacts',
      'editMissionProtoFiles',
      'editMissionProtoArtifacts',
      'editMissionFiles',
    ]))
  } catch (error) {
    console.error(error)
  }
}

function * getGCPS ({ options, artifactId, displayErrors = true }) {
  yield put(ImportWizardActions.getGCPSLoading(artifactId))
  try {
    const gcps = yield getGCP(options, displayErrors)
    yield put(ImportWizardActions.getGCPSSuccess(artifactId, gcps))
  } catch (e) {
    yield put(ImportWizardActions.getGCPSFailure(getErrorMessage(e), artifactId))
  }
}

// Check the units of the crs
function * checkUnits ({ options, artifactId, displayErrors = true }) {
  yield put(ImportWizardActions.checkUnitsLoading())
  try {
    const { data: { data: response } } = yield call(axios.post, `/transformation/check_units`, options)
    yield put(ImportWizardActions.checkUnitsSuccess(artifactId, (Object.keys(options) || [])[0], response))
  } catch (e) {
    const errorMessage = path(['message'], e)
    if (displayErrors) {
      toast.error(path(['response', 'data', 'message'], e) || (errorMessage === 'Network Error'
        ? i18n.t('toast.importWizard.checkUnitsError')
        : errorMessage || ''
      ))
    }
    yield put(ImportWizardActions.checkUnitsFailure(getErrorMessage(e)))
  }
}

function * getCRSInfo ({ options, artifactId, displayErrors = true }) {
  yield put(ImportWizardActions.getCRSInfoLoading())
  try {
    const { okay, crs, message } = yield call(getCRSExtendedInfo, options)
    if (okay) {
      yield put(ImportWizardActions.getCRSInfoSuccess(artifactId, crs))
    } else {
      throw message
    }
  } catch (e) {
    if (displayErrors) showErrorMessage(e, i18n.t('toast.importWizard.getCRSInfoError'))
    yield put(ImportWizardActions.getCRSInfoFailure(getErrorMessage(e)))
  }
}

// This endpoint return transformed coordinates between input and output crs
function * transformFromCRSToCRS ({ options }) {
  yield put(ImportWizardActions.transformFromCRSToCRSLoading())
  try {
    const { data: { data: response } } = yield call(axios.post, `/transformation/crs_to_crs`, options)
    yield put(ImportWizardActions.transformFromCRSToCRSSuccess(response))
  } catch (e) {
    showErrorMessage(e, i18n.t('toast.importWizard.transformFromCRSToCRSError'))
    yield put(ImportWizardActions.transformFromCRSToCRSFailure(getErrorMessage(e)))
  }
}

function * getRecommendedCRS ({ projectId, crs, displayErrors = true, id }) {
  yield put(ImportWizardActions.getRecommendedCRSLoading())
  try {
    const { data: { data: recommendedCRS } } = yield call(axios.post, `/transformation/${projectId}/get_recommended_crs`, crs)
    yield put(ImportWizardActions.getRecommendedCRSSuccess(projectId, id, recommendedCRS))
  } catch (e) {
    yield put(ImportWizardActions.getRecommendedCRSFailure(getErrorMessage(e)))
  }
}

function * confirmWatcher () {
  yield takeLeading(ImportWizardTypes.CONFIRM, confirm)
}

function * confirmEditGCPWatcher () {
  yield takeLeading(ImportWizardTypes.CONFIRM_EDIT_GCP, confirmEditGCP)
}

function * confirmEditWatcher () {
  yield takeLeading(ImportWizardTypes.CONFIRM_EDIT_MISSION, confirmEditMission)
}

function * getGCPSWatcher () {
  yield takeEvery(ImportWizardTypes.GET_GCPS, getGCPS)
}

function * transformFromCRSToCRSWatcher () {
  yield takeLatest(ImportWizardTypes.TRANSFORM_FROM_CRS_TO_CRS, transformFromCRSToCRS)
}

function * checkUnitsWatcher () {
  yield takeLatest(ImportWizardTypes.CHECK_UNITS, checkUnits)
}

function * getCRSInfoWatcher () {
  yield takeLatest(ImportWizardTypes.GET_CRS_INFO, getCRSInfo)
}

function * getRecommendedCRSWatcher () {
  yield takeLatest(ImportWizardTypes.GET_RECOMMENDED_CRS, getRecommendedCRS)
}

function * addReferenceStationFilesWatcher () {
  yield takeLatest(ImportWizardTypes.ADD_REFERENCE_STATION_FILES, addReferenceStationFiles)
}

export default function * root () {
  yield fork(addFilesWatcher)
  yield fork(addDocumentationFilesWatcher)
  yield fork(addDropboxFilesWatcher)
  yield fork(addUploadFilesWatcher)
  yield fork(addUploadDropboxFilesWatcher)
  yield fork(addMissionFilesWatcher)
  yield fork(addMissionDropboxFilesWatcher)
  yield fork(addEditMissionFilesWatcher)
  yield fork(addEditMissionDropboxFilesWatcher)
  yield fork(addReferenceStationFilesWatcher)
  // yield take(ImportWizardTypes.CONFIRM, confirm)
  yield fork(confirmWatcher)
  yield fork(confirmEditWatcher)
  yield fork(confirmEditGCPWatcher)

  yield fork(getRecommendedCRSWatcher)
  yield fork(getCRSInfoWatcher)
  yield fork(getGCPSWatcher)
  yield fork(transformFromCRSToCRSWatcher)
  yield fork(checkUnitsWatcher)
}
