import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
// Project deps
import axios from 'utils/axios'
import { getDatumWithoutAreaBounds } from 'utils/datums'
// Local deps
import ProjectCRSWizardActions, { ProjectCRSWizardTypes } from './actions'

// Sagas
// Retrieve a list vertical and horizontal reference systems
function * getRecommendedWizardCRS ({ id, data }) {
  yield put(ProjectCRSWizardActions.getRecommendedWizardCRSLoading(id))
  try {
    const datum = data.datum
    const [verticalCRSData, horizontalCRSData] = yield all([
      call(axios.post, `/transformation/get_recommended_crs`, { ...data, datum: getDatumWithoutAreaBounds(datum), crs_type: 'vertical' }),
      call(axios.post, `/transformation/get_recommended_crs`, { ...data, datum: getDatumWithoutAreaBounds(datum), crs_type: 'projected' }),
    ])
    yield put(ProjectCRSWizardActions.getRecommendedWizardCRSSuccess(id, {
      vertical: verticalCRSData.data.data,
      horizontal: horizontalCRSData.data.data,
    }))
  } catch (e) {
    // showErrorMessage(e)
    yield put(ProjectCRSWizardActions.getRecommendedWizardCRSFailure(id))
  }
}
// Retrieve a list of recommende geoids based on selected datum and location
function * getRecommendedWizardGeoids ({ id, lat, lng, datum }) {
  yield put(ProjectCRSWizardActions.getRecommendedWizardGeoidsLoading(id, lat, lng, datum))
  try {
    const { data: { data: geoids } } = yield call(
      axios.get,
      `/constants/vertical_geoids?${lat ? `lat=${lat}` : ''}${lng ? `&lon=${lng}` : ''}${datum ? `&datum_name=${datum}` : ''}`,
    )
    yield put(ProjectCRSWizardActions.getRecommendedWizardGeoidsSuccess(id, lat, lng, datum, geoids))
  } catch (e) {
    // showErrorMessage(e)
    yield put(ProjectCRSWizardActions.getRecommendedWizardGeoidsFailure(id, lat, lng, datum))
  }
}

// Watchers
function * getRecommendedWizardCRSWatcher () {
  yield takeLatest(ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_CRS, getRecommendedWizardCRS)
}

function * getRecommendedWizardGeoidsWatcher () {
  yield takeLatest(ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_GEOIDS, getRecommendedWizardGeoids)
}

export default function * root () {
  yield fork(getRecommendedWizardCRSWatcher)
  yield fork(getRecommendedWizardGeoidsWatcher)
}
