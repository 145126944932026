import { withMaxPrecision, withRounding } from 'utils/numeric'
import { WEEK, USECS } from 'types/dataTime'

export function toJson (value) {
  const usecsIntoWeekValue = usecsIntoWeek(value)
  return { week: parseInt(toGpsWeek(value).toString()), tow: usecsIntoWeekValue / USECS }
}
export function usecsTo (time1, time2) {
  return time2 - time1
}
export function secsBetween (time1, time2) {
  return Math.abs(time1 - time2) / USECS
}
export function usecsIntoWeek (value) {
  return value % (WEEK * USECS)
}
export function toGpsTow (value) {
  const seconds = value / USECS
  const result = seconds % WEEK
  return withRounding(result.toString(), 6)
}
export function toGpsWeek (value) {
  return (value / USECS) / WEEK
}
export function toIntGpsWeek (value) {
  return parseInt(toGpsWeek(value).toString())
}
export function toStringWeekTow (value) {
  const week = parseInt(toGpsWeek(value).toString())
  const usecsIntoWeekValue = usecsIntoWeek(value)
  const tow = withMaxPrecision((usecsIntoWeekValue / USECS).toString(), 6) // withMaxPrecision(this.toGpsTow().toString(), 3)
  return `${week} / ${tow}`
}
