import { call, put, takeLatest, fork, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import axios from 'utils/axios'
import { getErrorMessage } from 'utils/api'
import { getLoggedUser } from 'modules/users/selectors'
// Local deps
import SupportActions, { SupportTypes } from './actions'

// Sagas
function * createTicket ({ data, attachments }) {
  yield put(SupportActions.createTicketLoading())
  try {
    const user = yield select(state => getLoggedUser(state))
    const { title, body } = data
    const name = user.first_name + ' ' + user.last_name
    const email = user.email
    const formData = {
      subject: title,
      text: `${body}\n\nURL: ${window.location.href}\n`,
      attachments: attachments,
      sender: `${name} <${email}>`,
    }
    const url = '/send_email'
    yield call(axios.post, url, formData)
    toast.success(i18next.t('toast.support.createTicketSuccess'))
    yield put(SupportActions.createTicketSuccess())
    yield put(SupportActions.closeSupportFormDialog())
  } catch (e) {
    yield put(SupportActions.createTicketFailure(getErrorMessage(e)))
    toast.error(i18next.t('toast.support.createTicketError'))
  }
}

// Watchers
function * createTicketWatcher () {
  yield takeLatest(SupportTypes.CREATE_TICKET, createTicket)
}

export default function * root () {
  yield fork(createTicketWatcher)
}
