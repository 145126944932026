import { path } from 'ramda'
import { getJobOptions, getPipelineTemplate } from 'modules/pipelineWizard/selectors'
import { isPipelineSettingsJob, isPointcloudOptimizationJob } from 'utils/jobs'

export const DataSetTypeMapping = {
  'Aerial': 'aerial',
  'Mobile': 'mobile',
}
export const DataSetTypeInitialValue = 'Aerial'
export const DataSetTypeOptions = Object.keys(DataSetTypeMapping)
const DataSetTypeReverseMapping = Object.keys(DataSetTypeMapping).reduce((all, key) => ({ ...all, [DataSetTypeMapping[key]]: key }), {})
export const transformPipelineSettings = (state, pipelineName, jobRunOptions) => {
  return {
    pipelineName: pipelineName,
    dataset_type: DataSetTypeReverseMapping[jobRunOptions['dataset_type']] || DataSetTypeInitialValue,
  }
}

export const transformPointcloudOptimization = (state, jobRunOptions) => {
  return {
    sensor_model: jobRunOptions.sensor_model,
    sensor_model_original: jobRunOptions.sensor_model_original,
    dataset_type: DataSetTypeReverseMapping[jobRunOptions['dataset_type']] || DataSetTypeInitialValue,
  }
}

export const getDatasetType = state => {
  const selectedTemplate = getPipelineTemplate(state)
  const jobId = (selectedTemplate.jobs.find(templateJob =>
    isPipelineSettingsJob(templateJob.jobType) ||
    isPointcloudOptimizationJob(templateJob.jobType),
  ) || {}).templateId
  const jobValues = getJobOptions(state)[jobId]
  const datasetType = DataSetTypeMapping[path(['rawValues', 'dataset_type'], jobValues)] || 'aerial'
  return datasetType
}
