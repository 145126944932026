// turf
// Project deps
// Local deps
import { DataType } from 'types/form'
import { MapBackendNameToFrontend } from '../constants'

export default {
  useCheckpoint: {
    name: MapBackendNameToFrontend.useCheckpoint,
    dataType: DataType.BOOLEAN,
    initialValue: false,
    sendToBackend: false,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
  },
  checkpoint: {
    name: MapBackendNameToFrontend.checkpoint,
    dataType: DataType.STRING,
    placeholder: '/tmp/checkpoint/',
    initialValue: '',
    optional: true,
    disabled: (state, values) => !values.useCheckpoint,
    backendTransform: (original, state, values) => !values.useCheckpoint ? '' : original,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 8,
      lg: 8,
    },
  },
}
