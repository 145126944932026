import moment from 'moment'
/**
 * Converts a `RawProject` to a `Project`.
 * @param raw The raw project as received by the Api.
 * @return The converted project for use in the rest of this application.
 */
export function convertRawProject (raw) {
  const {
    name,
    size,
    description,
    id,
    created,
    updated,
    user,
    gps_position,
    gcps,
    trajectories,
    plp,
    project_thumbnails,
    is_deleting,
    coordinate_reference_system,
  } = raw
  const { latitude, longitude } = gps_position
  const gpsPosition = typeof latitude === 'number' && typeof longitude === 'number' ? {
    lat: latitude,
    lng: longitude,
  } : {
    lat: null,
    lng: null,
  }
  return {
    id,
    sortName: (name || '').toLowerCase(),
    name,
    description: typeof description === 'string' ? description : undefined,
    created: typeof created === 'string' ? new Date(created) : undefined,
    momentCreated: moment(created),
    updated: typeof updated === 'string' ? new Date(updated) : undefined,
    // created,
    // updated,
    plp: typeof plp === 'object' && plp !== null ? plp : undefined,
    userId: user && user.id,
    size,
    gpsPosition,
    gcps: gcps || {},
    trajectories: trajectories || {},
    thumbnails: project_thumbnails,
    isDeleting: Boolean(is_deleting),
    coordinateReferenceSystem: coordinate_reference_system || null,
  }
}
