import React from 'react'
import { Trans } from 'react-i18next'
// turf
// Project deps
import {
  // getSelectedTemplateArtifacts,
  getSelectedReferenceStationDatums,
} from 'modules/pipelineWizard/selectors'
import { getDatums } from 'modules/app/selectors'
// import { getArtifacts } from '../modules/projects/selectors'
import Warning from 'components/Warning'
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import { getIEDatumLabel } from 'utils/datums'
// import { findById } from '../utils/list'
import { getInitialOutputDatum, isComputeGNSSAntennaOptionSelected } from '../jobOptionsUtils'
import { MapBackendNameToFrontend } from '../constants'
import { isAdmin } from 'modules/users/selectors'

export default {
  export_sbtc: {
    name: 'Export trajectory in SBTC format for offline processing',
    dataType: DataType.BOOLEAN,
    invisible: isComputeGNSSAntennaOptionSelected,
    sendToBackend: (state, values) => !isComputeGNSSAntennaOptionSelected(state),
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'export_sbtc', false),
  },
  export_pof: {
    name: 'Export trajectory in POF format for offline processing',
    dataType: DataType.BOOLEAN,
    invisible: isComputeGNSSAntennaOptionSelected,
    sendToBackend: (state, values) => !isComputeGNSSAntennaOptionSelected(state),
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'export_pof', false),
  },
  export_sbet: {
    name: 'Export trajectory in SBET format for offline processing',
    dataType: DataType.BOOLEAN,
    sendToBackend: (state, values) => !isComputeGNSSAntennaOptionSelected(state),
    invisible: isComputeGNSSAntennaOptionSelected,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'export_sbet', true),
  },
  output_datum: {
    name: MapBackendNameToFrontend.output_datum,
    dataType: DataType.AUTOCOMPLETE,
    disabled: false,
    adminOnly: true,
    initialValue: (state, extra, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      const datums = getDatums(state)
      if (clone) {
        const value = getCloneJobOptionValue(state, extra, options, 'output_datum')
        if (value) {
          const { navlab_datum } = value
          const datum = datums.find(datum => datum.navlab_datum === navlab_datum)
          if (datum) {
            return datum
          }
        }
      }
      return getInitialOutputDatum(state, extra, options)
    },
    options: state => getDatums(state),
    displayTemplate: datum => getIEDatumLabel(datum),
    searchTemplate: datum => getIEDatumLabel(datum),
    getValue: (state, value, values, extra) => value ? getIEDatumLabel(value) : '',
    extraRender: (state, values = {}, extra, propOptions = {}) => {
      const datums = getDatums(state)
      const selectedReferenceStationDatums = getSelectedReferenceStationDatums(state)

      const selectedDatums = Object.keys(selectedReferenceStationDatums)
      const [firstDatum] = selectedDatums
      const firstRefStationDatum = firstDatum && datums.find(datum => datum.navlab_datum === firstDatum)
      const selectedOutputDatum = values.output_datum

      return selectedDatums.length > 1
        ? null
        : (firstRefStationDatum && selectedOutputDatum)
          ? selectedOutputDatum.navlab_datum === firstRefStationDatum.navlab_datum
            ? null
            : <Warning variant='warning'>
              <Trans i18nKey='pipelineWizard.configureStep.differentNavLabDatum'/>
            </Warning>
          : null
    },
  },
}
