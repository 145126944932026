import { getEPSGDatums, getUnits, getVerticalCoordinateSystems, getCoordinateSystems } from 'modules/app/selectors'
import { getGeoid, getGeoidFileName, getGeoidLabel } from 'utils/geoid'
import { CRSFields } from 'templates/CRS/constants'
import { getEPSGDatumLabel } from 'utils/datums'
import { getCoordinateSystemLabelWithType, getBackendString, isCRSFieldCompound } from 'utils/coordinateSystem'
import { DataType } from 'types/form'
import { MapBackendNameToFrontend } from 'templates/constants'

// Transform v/h units to the values that should be send to backend
export const unitBackendTransform = (original, state, formValues) => {
  return !original || original === 'lonlat' ? null : getBackendString(original)
}

const isEllipsoidal = (state, values) => {
  return values.height_type === MapBackendNameToFrontend.ellipsoidal
}

const isCustomString = values => {
  if (!values) return false
  const crs = values[CRSFields.C_CRS]
  if (crs) {
    if (!isCRSFieldCompound(crs)) {
      return true
    }
  }
  return false
}

const isCompoundCRS = values => {
  if (!values) return false
  const crs = values[CRSFields.C_CRS]
  if (crs) {
    if (isCRSFieldCompound(crs) || typeof crs === 'object') {
      return true
    }
  }
  return false
}

const areUnitsDifferent = values => {
  return getBackendString(values[CRSFields.H_UNITS]) !== getBackendString(values[CRSFields.V_UNITS])
}

export const ProjectCRSTemplateInfo = {
  name: {
    variant: 'outlined',
    name: MapBackendNameToFrontend.crsName,
    dataType: DataType.STRING,
    optional: true,
    editable: false,
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      if (initialValues && 'name' in initialValues) {
        return false
      }
      return true
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        const name = initialValues.name
        return name
      }
      return ''
    },
  },
  datum: {
    name: MapBackendNameToFrontend.datum,
    dataType: DataType.AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      return isCustomString(initialValues) || isCompoundCRS(initialValues)
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        const datum = initialValues.datum
        if (datum) {
          if (typeof datum === 'string') {
            return getEPSGDatums(state).find(epsgDatum => getBackendString(epsgDatum) === datum) || null
          }
          return datum || null
        }
      }
      return null
    },
    displayTemplate: datum => getEPSGDatumLabel(datum),
    searchTemplate: datum => getEPSGDatumLabel(datum),
    getValue: (state, value, values, extra) => value ? getEPSGDatumLabel(value) : '',
    optional: true,
    editable: false,
    gridProps: (state, values, extra, options) => {
      return areUnitsDifferent(values)
        ? {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        }
        : {
          xs: 12,
          sm: 12,
          md: 9,
          lg: 9,
        }
    },
  },
  compound_crs: {
    name: MapBackendNameToFrontend.crs,
    dataType: DataType.AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    editable: false,
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      if (initialValues && initialValues[CRSFields.C_CRS] && !isCustomString(initialValues)) return false
      return true
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        let crs_c = initialValues[CRSFields.C_CRS]
        if (crs_c && !isCustomString(initialValues)) {
          if (typeof crs_c !== 'string') {
            crs_c = getBackendString(crs_c)
          }
          return getCoordinateSystems(state).find(crs => getBackendString(crs) === crs_c) || null
        }
      }
      return null
    },
    optional: true,
    displayTemplate: datum => getCoordinateSystemLabelWithType(datum),
    searchTemplate: datum => getCoordinateSystemLabelWithType(datum),
    getValue: (state, value, values, extra) => value ? getCoordinateSystemLabelWithType(value) : '',
    sendToBackend: (state, values) => {
      if (isCustomString(values)) {
        return false
      }
      return true
    },
    backendTransformName: CRSFields.C_CRS,
    backendTransform: getBackendString,
    gridProps: (state, values, extra, options) => {
      return areUnitsDifferent(values)
        ? {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        }
        : {
          xs: 12,
          sm: 12,
          md: 9,
          lg: 9,
        }
    },
  },
  [CRSFields.H_UNITS]: {
    name: (state, values) => {
      return areUnitsDifferent(values) ? MapBackendNameToFrontend.h_units : MapBackendNameToFrontend.units
    },
    dataType: DataType.AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    editable: false,
    optional: true,
    displayTemplate: unit => unit.name,
    searchTemplate: unit => unit.name,
    invisible: (state, values, extra) => {
      // const { initialValues } = extra
      return !areUnitsDifferent(values)
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        let unit_h = initialValues[CRSFields.H_UNITS]
        if (unit_h) {
          if (typeof unit_h !== 'string') {
            unit_h = getBackendString(unit_h)
          }
          return getUnits(state).find(unit => getBackendString(unit) === unit_h) || null
        }
      }
      return null
    },
    getValue: (state, value, values, extra) => (value && value.type === 'length') ? value.name : '',
    backendTransform: unitBackendTransform,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  [CRSFields.V_UNITS]: {
    name: (state, values) => {
      return areUnitsDifferent(values) ? MapBackendNameToFrontend.v_units : MapBackendNameToFrontend.units
    },
    dataType: DataType.AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    editable: false,
    optional: true,
    displayTemplate: unit => unit.name,
    searchTemplate: unit => unit.name,
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      return isCustomString(initialValues)
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        let unit_v = initialValues[CRSFields.V_UNITS]
        if (unit_v) {
          if (typeof unit_v !== 'string') {
            unit_v = getBackendString(unit_v)
          }
          return getUnits(state).find(unit => getBackendString(unit) === unit_v) || null
        }
      }
      return null
    },
    getValue: (state, value, values, extra) => (value && value.type === 'length') ? value.name : '',
    backendTransform: unitBackendTransform,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  /*
  projection_type: {
    name: 'Projection type',
    dataType: DataType.SELECTION,
    options: ['UTM', 'State plane'],
    initialValue: 'UTM',
    displayTemplate: datum => getEPSGDatumLabel(datum),
    searchTemplate: datum => getEPSGDatumLabel(datum),
    getValue: (state, value, values, extra) => value ? getEPSGDatumLabel(value) : '',
    optional: false,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  */
  // Proj/wkt compound crs
  /*
  crs: {
    name: 'CRS',
    dataType: DataType.AUTOCOMPLETE,
    options: state => getCoordinateSystems(state).filter(isHorizontalCoordinateSystem),
  },
  */
  [CRSFields.H_CRS]: {
    name: MapBackendNameToFrontend.crs_h,
    dataType: DataType.AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      return isCustomString(initialValues) || isCompoundCRS(initialValues)
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        let crs_h = initialValues[CRSFields.H_CRS]
        if (crs_h) {
          if (typeof crs_h !== 'string') {
            crs_h = getBackendString(crs_h)
          }
          return getCoordinateSystems(state).find(crs => getBackendString(crs) === crs_h) || null
        }
      }
      return null
    },
    optional: true,
    editable: false,
    displayTemplate: datum => getCoordinateSystemLabelWithType(datum),
    searchTemplate: datum => getCoordinateSystemLabelWithType(datum),
    getValue: (state, value, values, extra) => value ? getCoordinateSystemLabelWithType(value) : '',
    backendTransform: getBackendString,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 9,
      lg: 9,
    },
  },
  height_type: {
    name: MapBackendNameToFrontend.height_type,
    dataType: DataType.SELECTION,
    variant: 'outlined',
    optional: true,
    editable: false,
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      return isCustomString(initialValues) || isCompoundCRS(initialValues)
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        if (CRSFields.IS_ELLIPSOIDAL in initialValues) {
          const is_ellipsoidal = initialValues[CRSFields.IS_ELLIPSOIDAL]
          return is_ellipsoidal ? MapBackendNameToFrontend.ellipsoidal : MapBackendNameToFrontend.orthometric
        } else if ('height_type' in initialValues) {
          const is_ellipsoidal = initialValues.height_type
          if (typeof is_ellipsoidal === 'string') {
            return is_ellipsoidal
          }
        } else {
          return MapBackendNameToFrontend.ellipsoidal
        }
      }
      return null // 'Ellipsoidal'
    },
    backendTransformName: CRSFields.IS_ELLIPSOIDAL,
    backendTransform: (original, state, values) => {
      return original === MapBackendNameToFrontend.ellipsoidal
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  [CRSFields.NORMALIZE_AXIS_ORDER]: {
    name: MapBackendNameToFrontend.normalize_axis_order,
    dataType: DataType.BOOLEAN,
    editable: false,
    invisible: true,
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        const normalizeAxisOrder = initialValues[CRSFields.NORMALIZE_AXIS_ORDER]
        return normalizeAxisOrder
      }
      return null
    },
    tooltip: MapBackendNameToFrontend.tooltips.normalize_axis_order,
    backendTransform: (original, state, values) => {
      const crs = values[CRSFields.H_CRS]
      if (crs && !crs.standard_axis_order) return original
      return false
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  [CRSFields.GRID]: {
    name: MapBackendNameToFrontend.geoid,
    dataType: DataType.GEOID_AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      if (isCustomString(initialValues) || isCompoundCRS(initialValues)) return true
      return isEllipsoidal(state, values)
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        const geoid = initialValues[CRSFields.GRID]
        return getGeoid(state, geoid)
      }
      return null
    },
    optional: true,
    editable: false,
    displayTemplate: geoid => getGeoidLabel(geoid),
    searchTemplate: geoid => getGeoidLabel(geoid),
    getValue: (state, value, values, extra) => value ? getGeoidLabel(value) : '',
    sendToBackend: true,
    backendTransform: (original, state, values) => getGeoidFileName(original),
  },
  [CRSFields.V_CRS]: {
    name: MapBackendNameToFrontend.crs_v,
    dataType: DataType.AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    editable: false,
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      if (isCustomString(initialValues) || isCompoundCRS(initialValues)) return true
      return isEllipsoidal(state, values)
    },
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        let crs_v = initialValues[CRSFields.V_CRS]
        if (crs_v) {
          if (typeof crs_v !== 'string') {
            crs_v = getBackendString(crs_v)
          }
          return getVerticalCoordinateSystems(state).find(crs => getBackendString(crs) === crs_v) || null
        }
      }
      return null
    },
    optional: true,
    displayTemplate: datum => getCoordinateSystemLabelWithType(datum),
    searchTemplate: datum => getCoordinateSystemLabelWithType(datum),
    getValue: (state, value, values, extra) => value ? getCoordinateSystemLabelWithType(value) : '',
    sendToBackend: true,
    backendTransform: getBackendString,
  },
  [CRSFields.GEOID_CRS]: {
    dataType: DataType.STRING,
    invisible: true,
    optional: true,
    editable: false,
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        const geoid_crs = initialValues[CRSFields.GEOID_CRS]
        return geoid_crs
      }
      return null
    },
    sendToBackend: true,
  },
  [CRSFields.C_CRS]: {
    name: MapBackendNameToFrontend.proj,
    dataType: DataType.STRING,
    multiline: true,
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      return !isCustomString(initialValues)
    },
    /*
    invisible: (state, values, extra) => {
      const { initialValues } = extra
      return !(initialValues && initialValues[CRSFields.C_CRS])
    },
    */
    optional: true,
    editable: false,
    initialValue: (state, extra) => {
      const { initialValues } = extra
      if (initialValues) {
        return initialValues[CRSFields.C_CRS]
      }
      return null
    },
    backendTransform: (original, state, values) => {
      if (original && typeof original === 'object') {
        return getBackendString(original)
      }
      return original
    },
  },
}
