import { filterByArtifactId } from 'utils/list'
/**
 * Finds all data directories which belong to an artifact with a specified id.
 * @param artifactId The id of the artifact to find the data files for.
 * @param dataDirectoryList The data directory list state of the application.
 */

// function getDataDirectoriesForArtifact(artifactId: Uuid, dataDirectories: DataDirectory[]): DataDirectory[]
export function getDataDirectoriesForArtifact (artifactId, dataDirectories) {
  return filterByArtifactId(artifactId, dataDirectories)
}

// function getDataDirectoryIdsForArtifact(artifactId: Uuid, dataDirectories: DataDirectory[]): Uuid[]
export function getDataDirectoryIdsForArtifact (artifactId, dataDirectories) {
  return getDataDirectoriesForArtifact(artifactId, dataDirectories).map(({ id }) => id)
}

// function getUncompletedDataDirectoriesForArtifact(artifactId: Uuid, dataDirectories: DataDirectory[])
export function getUncompletedDataDirectoriesForArtifact (artifactId, dataDirectories) {
  // return dataDirectories[artifactId] || []
  return dataDirectories.filter(dataDirectory => dataDirectory.artifactId === artifactId && !dataDirectory.completed)
}

export function getCompletedDataDirectoriesForArtifact (artifactId, dataDirectories) {
  return dataDirectories.filter(dataDirectory => dataDirectory.artifactId === artifactId && dataDirectory.completed)
}
