import { getSelectedCameraArtifacts, getSelectedGCPArtifacts, getSelectedReferenceStationDatums, getSelectedReconArtifacts, getArtifactOptions, getSelectedNavRoverArtifacts } from 'modules/pipelineWizard/selectors'
import { getDatums, getRecommendedDatums } from 'modules/app/selectors'
import { getDatumInitialValue } from './CRS/utils'
import { DefaultOutputDatum } from './jobOptionsConstants'
import { getCurrentProject } from 'modules/projects/selectors'

export const isAlignToGcpEnabled = (state, formValues) => {
  return getSelectedGCPArtifacts(state).length > 0
}

/*
const isGCPThresholdEnabled = (state, values) => {
  return getSelectedGCPArtifacts(state).length > 0
}
*/

export const isEnablePhotosEnabled = (state, formValues) => {
  return getSelectedCameraArtifacts(state).length > 0
}

export const isContourIntervalEnabled = (state, values) => {
  return values.contours
}

export const isGeoTiffEnabled = (state, values) => {
  return values.dsm_dem
}

export const isGeoTiffRGBEnabled = (state, values) => {
  return values.geotiff_rgb
}

/*
const getMissionIds = (state, values) => {
  const selectedTemplateArtifacts = getSelectedTemplateArtifacts(state)
  const artifacts = getArtifacts(state)
  const allArtifactIds = Object.keys(selectedTemplateArtifacts).reduce(
    (allArtifacts, templateId) => allArtifacts.concat(selectedTemplateArtifacts[templateId]), []
  )
  const missionIds = []
  allArtifactIds.forEach(id => {
    const artifact = findById(id, artifacts)
    if (artifact) {
      if (artifact.mission) {
        const { id: missionId } = artifact.mission
        if (missionIds.indexOf(missionId) < 0) {
          missionIds.push(missionId)
        }
      }
    }
  })
  return missionIds
}
*/

export const getInitialOutputDatum = (state, extra, options) => {
  const { extraProps } = options
  const { projectCRS } = extraProps
  const datums = getDatums(state)
  const selectedReferenceStationDatums = getSelectedReferenceStationDatums(state)
  const [firstDatum] = Object.keys(selectedReferenceStationDatums)
  if (firstDatum) {
    const firstRefStationDatum = datums.find(datum => datum.navlab_datum === firstDatum)
    if (firstRefStationDatum) {
      return firstRefStationDatum
    }
  }
  if (projectCRS) {
    const datum = getDatumInitialValue(state, projectCRS)
    if (datum) {
      const projectCRSDatum = datums.find(iedatum => iedatum.name === datum.name)
      if (projectCRSDatum) {
        return projectCRSDatum
      }
    }
  }
  const currentProjectInfo = getCurrentProject(state)
  const project = currentProjectInfo.project
  const gpsPosition = project.gpsPosition
  if (gpsPosition && typeof gpsPosition.lat === 'number' && typeof gpsPosition.lng === 'number') {
    const [recommendedEPSGDatum] = getRecommendedDatums(state, gpsPosition.lat, gpsPosition.lng)
    if (recommendedEPSGDatum) {
      const recommendedIeDatum = datums.find(iedatum => iedatum.name === recommendedEPSGDatum.name)
      if (recommendedIeDatum) {
        return recommendedIeDatum
      }
    }
  }
  return datums.find(datum => datum.navlab_datum === DefaultOutputDatum)
}

/**
 * Returns true if at least one `gnss_processing_only` option in navigatior rover options selected
 * @param {Object} state
 * @returns {Boolean}
 */
export const isComputeGNSSAntennaOptionSelected = state => {
  const selectedNavArtifacts = getSelectedNavRoverArtifacts(state)
  const artifactOptions = getArtifactOptions(state)
  return selectedNavArtifacts.some(navArtifact => {
    const options = artifactOptions[navArtifact.id]
    if (options && options.values && options.values.gnss_processing_only) {
      return true
    }
    return false
  })
}
