import React from 'react'
import i18n from 'i18n'
// Icons
import SurveyIcon from '@material-ui/icons/Flight'
import PointcloudIcon from '@material-ui/icons/Cloud'
import TrajectoryIcon from '@material-ui/icons/Timeline'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import NoIcon from '@material-ui/icons/Close'

export const ProjectDataType = [
  {
    label: 'Survey',
    value: 'survey',
    primaryText: 'Process missions',
    icon: <SurveyIcon/>,
  },
  {
    label: 'Pointcloud',
    value: 'pointcloud',
    primaryText: 'Import a point cloud file into LiDARMill viewer',
    icon: <PointcloudIcon/>,
  },
  {
    label: 'Pre-processed Trajectory',
    value: 'trajectory',
    primaryText: 'Import a pre-processed trajectory file',
    icon: <TrajectoryIcon/>,
  },
]

export const GCPType = [
  {
    label: i18n.t('projectWizard.steps.gcp.type.no-gcp.label'),
    value: 'no-gcp',
    icon: <NoIcon/>,
  },
  {
    label: i18n.t('projectWizard.steps.gcp.type.file.label'),
    value: 'file',
    icon: <FileIcon/>,
    primaryText: i18n.t('projectWizard.steps.gcp.type.file.text'),
  },
  {
    label: i18n.t('projectWizard.steps.gcp.type.copy.label'),
    value: 'copy',
    icon: <FileIcon/>,
    primaryText: i18n.t('projectWizard.steps.gcp.type.copy.text'),
  },
]

export const PolygonType = [
  {
    label: i18n.t('projectWizard.steps.gcp.type.no-polygon.label'),
    value: 'no-polygon',
    icon: <NoIcon/>,
  },
  {
    label: i18n.t('projectWizard.steps.gcp.type.polygon-file.label'),
    value: 'polygon-file',
    icon: <FileIcon/>,
    primaryText: i18n.t('projectWizard.steps.gcp.type.polygon-file.text'),
  },
]
