import { ArtifactStatuses } from 'types/artifacts'
import { replaceMultipleWhiteSpaces } from 'utils/baseName'
import { filterByArtifactId, findById } from 'utils/list'

export const UploadStatus = {
  PENDING: 0,
  PREPARED: 1,
  DONE: 2,
}

export const CurrentUploadStage = {
  UNTOUCHED: 0,
  ADDING_FILES: 1,
  RUNNING: 2,
}

export const isCurrentStageRunning = stage => {
  return stage === CurrentUploadStage.RUNNING
}

/**
 * Converts a `FileList` object into a `File[]` array.
 */
// function fileListToArray(fileList: FileList): File[]
export function fileListToArray (fileList) {
  const files = []
  // `FileList` does not extend array, so it is neccessary to iterate it the good old way.
  for (let i = 0; i < fileList.length; ++i) {
    const file = fileList.item(i)
    files.push(file)
  }
  return files
}

/**
 * Checks only for fileName and artifactId
 * Maybe this check needs to be more precise
 * But as we don't allow to users upload files with the same names to the same artifact it's okay
 * @param {Array} dataFiles
 * @param {String} artifactId
 * @param {File|Object} file
 * @return The corresponding dataFile to the file
 */
export function getDataFileForFile (dataFiles, artifactId, file) {
  if (file instanceof File) {
    return dataFiles.find(dataFile =>
      replaceMultipleWhiteSpaces(file.name) === dataFile.fileName &&
      dataFile.artifactId === artifactId,
    )
  } else {
    return dataFiles.find(dataFile =>
      replaceMultipleWhiteSpaces(file.file.name) === dataFile.fileName &&
      dataFile.artifactId === artifactId,
    )
  }
}

/**
 * Returns the currently uploading file.
 * @param state The `UploadState` sub state of the application's state.
 * @return The currently uplading `UploadingFile`.
 */
export function getCurrentlyUploadingFile (state) {
  const { files } = state
  return files ? files.find(file => file.status === UploadStatus.PREPARED) : null
}

export function getCurrentlyPreparingFile (state) {
  const { files } = state
  return files ? files.find(file => file.status === UploadStatus.PENDING) : null
}

export function getFailedDataDirectoryFilesForArtifact (artifactId, state) {
  return filterByArtifactId(artifactId, state.get('failedDataDirectories'))
    .reduce((result, directory) => [ ...result, ...directory.fileIndex ], [])
}

export function getFailedDataDirectoryFileForFile (file, artifactId, state) {
  const failedFiles = getFailedDataDirectoryFilesForArtifact(artifactId, state)
  return failedFiles ? failedFiles.find(url => baseName(url) === file.file.name) : null
}

export function getFailedFilesForArtifact (artifactId, state) {
  return filterByArtifactId(artifactId, state.get('failedFiles'))
}

// We only add failed file to failedFiles array when this file not in:
// 1) files array(this array consists files that must be loaded or loaded already)
// 2) failedFiles array
// 3) file is not in artifact that is currently uploading
export const fileShouldBeAddedToFailedFiles = (dataFile, files, failedFiles, artifacts) => {
  const dataFileId = dataFile.id
  const fileInAddedFiles = files.find(file => {
    const prepFiled = file
    return prepFiled.dataFileId && prepFiled.dataFileId === dataFileId
  })
  const fileInFailedFiles = findById(dataFileId, failedFiles)
  const artifactForDataFile = findById(dataFile.artifactId, artifacts)
  const artifactStatus = artifactForDataFile && (artifactForDataFile.artifactStatus || artifactForDataFile.artifact_status)
  return !fileInAddedFiles && !fileInFailedFiles && !dataFile.completed &&
    (artifactForDataFile && artifactStatus !== ArtifactStatuses.UPLOADING)
}

function baseName (path) {
  // https://stackoverflow.com/questions/3820381/need-a-basename-function-in-javascript
  return path.split(/[\\/]/).pop()
}
