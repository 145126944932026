
export const setStorageItem = (key, value) => {
  localStorage.setItem(key, value)
}

export const removeStorageItem = key => {
  localStorage.removeItem(key)
}

export const getStorageItem = key => {
  return localStorage.getItem(key)
}
