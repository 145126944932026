// External dependencies.
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { omit } from 'ramda'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import Compress from 'compress.js'
// Material UI dependencies.
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  Grid,
  IconButton,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core'
// Icons
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
// Project internal dependencies.
import i18next from 'i18n'
import { trainingVideos, userManualUrl } from 'config'
import SupportActions from 'modules/support/actions'
import { isCreateTicketLoading, createTicketError } from 'modules/support/selectors'
import LoadingButton from 'components/LoadingButton'
import Warning from 'components/Warning'
import { getCurrentProject } from 'modules/projects/selectors'

import ArtifactAvatar from 'components/ArtifactAvatar'
import { ArtifactTypes } from 'types/artifacts'
import { getArtifactsOfType } from 'utils/artifacts'
// import { isLogFileName } from 'modules/upload/file-types'
import { isDocFile, isFileImage } from 'utils/fileTypes'
import ClosableDialog from 'components/ClosableDialog'
import { isFileNameEndsWith } from 'utils/baseName'
import { SystemType } from 'types/missions'

const MAX_ATTACHMENTS_SIZE = 7

const styles = theme => ({
  ...theme.global,
  container: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
  root: {
    height: '100%',
  },
  dialogTitle: {
    background: theme.palette.secondary.alternative,
    color: '#fff',
  },
  formContainer: {
    padding: theme.spacing(1),
    flex: 1,
  },
  images: {
    display: 'flex',
  },
  addImage: {
    backgroundColor: '#545b75',
    color: '#fff',
    height: 100,
    position: 'relative',
  },
  imageContainer: {
    height: 100,
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  uploadInput: {
    display: 'none',
  },
  deleteButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(0.25),
    '&&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  addIcon: {
    fontSize: 40,
  },
  deleteIcon: {
    width: '0.85em',
    height: '0.85em',
  },
  filesList: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})
const getExistIcon = isExist => {
  return isExist ? <CheckIcon style={{ color: 'green' }}/> : <CloseIcon style={{ color: 'red' }}/>
}

const compress = new Compress()

const Content = props => {
  const { handleClose, classes, errorMessage, onConfirm, projectPage, currentProject, isLoading } = props
  const { artifacts, missions } = currentProject
  const [formData, setFormData] = useState({
    body: '',
    title: '',
  })
  const [isBusy, setIsBusy] = useState(false)
  const [attachments, setAttachments] = useState([])

  // Handlers
  const handleChange = event => {
    const { value, name } = event.target
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }
  const handleSubmit = () => onConfirm(formData, attachments.map(attachment => omit(['prefix', 'size'], attachment)))
  const onRemoveFile = fileName => e => {
    setAttachments(prevState => prevState.filter(attachment => attachment.file_name !== fileName))
  }
  const handleAddFiles = async event => {
    const { target: { files } } = event
    const newFiles = [...files].filter(file => !attachments.find(attachment => attachment.file_name === file.name))
    setIsBusy(true)
    compress.compress(newFiles, {
      size: 4,
      quality: 0.75,
      maxWidth: 1920,
      maxHeight: 1920,
      resize: true,
    }).then(data => {
      const newAttachments = []
      for (let i = 0; i < data.length; i++) {
        const file = data[i]
        newAttachments.push({
          file_name: file.alt,
          size: file.endSizeInMb,
          data: file.data,
          prefix: file.prefix,
        })
      }
      setAttachments(prevState => [
        ...prevState,
        ...newAttachments,
      ])
      setIsBusy(false)
    })
  }

  const { body, title } = formData
  const totalAttachmentsSize = attachments.reduce((totalSize, attachment) => totalSize + attachment.size, 0)
  const canSendTicket = totalAttachmentsSize <= MAX_ATTACHMENTS_SIZE
  const logArtifacts = getArtifactsOfType(ArtifactTypes.LOG, artifacts)
  const docArtifacts = getArtifactsOfType(ArtifactTypes.DOCUMENTATION, artifacts)
  const isReconMission = missions.find(mission => mission.missionType === SystemType.RECON)
  const isOtherDocFilesExist = docArtifacts.find(artifact => artifact.properties.fileNames.find(fileName => isDocFile(fileName)))
  const isSystemTypePhotoExist = docArtifacts.find(artifact => artifact.properties.fileNames.find(fileName => isFileImage({ name: fileName })))
  const isLogFileExist = logArtifacts.find(artifact => artifact.properties.fileNames.find(fileName => isFileNameEndsWith(fileName, '.log')))
  const isKernelStopFileExist = logArtifacts.find(artifact => artifact.properties.fileNames.find(fileName => isFileNameEndsWith(fileName, '.kernelstop')))
  const canSendProjectTicket = isSystemTypePhotoExist && (isReconMission ? true : isLogFileExist)
  const bodyAndTitle = Boolean(body && title)
  return (
    <React.Fragment>
      <DialogTitle id='support-dialog-title' className={classes.dialogTitle}>
        <Trans i18nKey='support.title'/>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', padding: 0, flexDirection: 'column' }}>
        <div style={{ flex: 1, padding: '8px 16px', overflow: 'auto' }}>
          <Typography variant='body1'>
            <Trans
              i18nKey='support.trainingVideos'
              components={[
                ...[trainingVideos[0]].map(trainingVideo => (
                  <a key={trainingVideo.url} href={trainingVideo.url} target='_blank' rel='noopener noreferrer'>training video</a>
                )),
                <a key='manual' rel="noopener noreferrer" target='_blank' href={userManualUrl}>user manual</a>,
              ]}
            />
            {/* <Trans
              i18nKey='support.info'
              components={[
                <a key='manual' rel="noopener noreferrer" target='_blank' href={userManualUrl}>user manual</a>,
                <a key='video' rel="noopener noreferrer" target='_blank' href={trainingVideoUrl}>training video</a>,
              ]}
            /> */}
            {/*
                trainingVideos.map(trainingVideo => (
                  <li key={trainingVideo.url}>
                    <a href={trainingVideo.url} target='_blank' rel='noopener noreferrer'>{trainingVideo.label}</a>
                  </li>
                ))
              */}
          </Typography>
          {(projectPage && !canSendProjectTicket) && <div>
            {!isReconMission && <Warning variant='warning' disableIcon>
              <Trans i18nKey={`support.project.warning`}/>
            </Warning>
            }
            <List>
              <ListItem>
                <ListItemAvatar>
                  <ArtifactAvatar artifactType={ArtifactTypes.LOG}/>
                </ListItemAvatar>
                <ListItemText
                  primary={`LOG artifact (${logArtifacts.length} found)`}
                  secondary={<Trans i18nKey='support.project.log'/>}
                />
              </ListItem>
              <List className={classes.filesList}>
                <ListItem className={classes.listItem}>
                  {getExistIcon(isLogFileExist)}
                  <ListItemText primary={isReconMission ? 'service.log (optional)' : '.log file'} />
                </ListItem>
                {!isReconMission && <ListItem className={classes.listItem}>
                  {getExistIcon(isKernelStopFileExist)}
                  <ListItemText primary='.kernelstop file (optional)' />
                </ListItem>
                }
              </List>
              <ListItem>
                <ListItemAvatar>
                  <ArtifactAvatar artifactType={ArtifactTypes.DOCUMENTATION}/>
                </ListItemAvatar>
                <ListItemText
                  primary={`DOC artifact (${docArtifacts.length} found)`}
                  secondary={<Trans i18nKey='support.project.doc'/>}
                />
              </ListItem>
              <List className={classes.filesList}>
                <ListItem className={classes.listItem}>
                  {getExistIcon(isSystemTypePhotoExist)}
                  <ListItemText primary='photo of system setup' />
                </ListItem>
                <ListItem className={classes.listItem}>
                  {getExistIcon(isOtherDocFilesExist)}
                  <ListItemText primary='other documentations (optional)' />
                </ListItem>
              </List>
            </List>
          </div>
          }
          <Warning variant='warning' disableIcon>
            <Trans i18nKey='support.imagesWarning' values={{ size: MAX_ATTACHMENTS_SIZE }}/>
          </Warning>
          <div className={classes.formContainer}>
            <TextField
              fullWidth
              value={title}
              variant='outlined'
              label={<Trans i18nKey='support.subject.label'/>}
              placeholder={i18next.t('support.subject.placeholder')}
              onChange={handleChange}
              InputProps={{
                name: 'title',
              }}
              required
            />
            <TextField
              fullWidth
              className={classes.container}
              value={body}
              variant='outlined'
              label={<Trans i18nKey='support.question.label'/>}
              placeholder={i18next.t('support.question.placeholder')}
              onChange={handleChange}
              InputProps={{
                name: 'body',
              }}
              multiline
              rows={10}
              rowsMax={10}
              required
            />
            {errorMessage &&
              <Typography variant='body1' style={{ color: 'orange' }}>
                {errorMessage}
              </Typography>
            }
            {!canSendTicket &&
              <div className={classes.container}>
                <Warning>
                  <Trans i18nKey='support.maxImagesSizeWarning'/>
                </Warning>
              </div>
            }
            <Grid container className={classes.container} spacing={1}>
              <Grid item xs={3}>
                <Card className={classes.addImage}>
                  <input
                    className={classes.uploadInput}
                    type='file'
                    onInput={handleAddFiles}
                    id='upload-images-button-file'
                    onClick={event => {
                      event.target.value = null
                    }}
                    accept='image/x-png,image/gif,image/jpeg'
                    multiple={true}
                  />
                  <label htmlFor='upload-images-button-file'>
                    <LoadingButton isLoading={isBusy} style={{ height: '100%' }} containerProps={{ style: { height: '100%' } }} fullWidth component='span'>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: '#fff',
                      }}>
                        <AddIcon className={classes.addIcon}/>
                        <Trans i18nKey='support.addScreenshots'/>
                      </div>
                    </LoadingButton>
                  </label>
                </Card>
              </Grid>
              {
                attachments.map(({ file_name, data, size, prefix }) => (
                  <Grid item xs={3} key={file_name}>
                    <Card className={classes.imageContainer}>
                      <IconButton
                        className={classes.deleteButton}
                        onClick={onRemoveFile(file_name)}>
                        <CloseIcon className={classes.deleteIcon}/>
                      </IconButton>
                      <img src={prefix + data} className={classes.image} alt={file_name}/>
                    </Card>
                  </Grid>
                ))
              }
            </Grid>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <span id='noSend'><Trans i18nKey='support.cancel'/></span>
        </Button>
        <LoadingButton isLoading={isLoading} onClick={handleSubmit} color='primary' disabled={!bodyAndTitle || !canSendTicket}>
          <span id='yesSend'><Trans i18nKey='support.submit'/></span>
        </LoadingButton>
      </DialogActions>
    </React.Fragment>
  )
}

Content.propTypes = {
  classes: PropTypes.object,
  currentProject: PropTypes.object,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  openDialog: PropTypes.bool,
  projectPage: PropTypes.bool,
  handleClose: PropTypes.func,
}

const ContentWithLoader = Content

/**
 * Renders a support dialog
 */
const SupportFormDialog = props => {
  const { openDialog, handleClose } = props
  return (
    <ClosableDialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth='lg'
      classes={{ paper: props.classes.root }}
      iconStyle={{ color: '#fff' }}
      aria-labelledby='support-dialog-title'>
      <ContentWithLoader {...props}/>
    </ClosableDialog>
  )
}

SupportFormDialog.propTypes = {
  classes: PropTypes.object,
  currentProject: PropTypes.object,
  isLoading: PropTypes.bool,
  openDialog: PropTypes.bool,
  handleClose: PropTypes.func,
}

const mapStateToProps = state => ({
  isLoading: isCreateTicketLoading(state),
  errorMessage: createTicketError(state),
  currentProject: getCurrentProject(state),
})

const mapDispatchToProps = dispatch => ({
  onConfirm: (data, attachments) => dispatch(SupportActions.createTicket(data, attachments)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SupportFormDialog))
