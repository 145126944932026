import React from 'react'
import { setAuthCookies } from 'api/pages/api'
import { Loader } from 'components/Loader'
import { isLocalEnvironment } from 'config'
import { setStorageItem } from 'utils/localStorage'

const SetToken = props => {
  const { location } = props
  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token')
  const redirectURL = urlParams.get('redirect_url')
  const loggedAs = urlParams.get('logged_as_user')
  const permissionType = urlParams.get('permission_type')
  if (isLocalEnvironment()) {
    setStorageItem('tokenAccounts', token)
  }
  setStorageItem('loggedAs', loggedAs)
  setAuthCookies(token, permissionType).then(() => {
    if (window.parent) {
      window.parent.postMessage('token-set', '*')
    }
    if (redirectURL) {
      window.location.href = redirectURL
    }
  })
  return <Loader />
}

export default SetToken
