import { createReducer } from 'reduxsauce'
// Project deps
// Local deps
import { INITIAL_STATE } from './initialState'
import { CalibrationTypes } from './actions'

export const getCalibrationsLoading = (state, { companyId }) => state.merge({
  calibrationsIsLoading: {
    ...state.get('calibrationsIsLoading'),
    [companyId]: true,
  },
})
export const getCalibrationsSuccess = (state, { companyId, calibrations }) => {
  return state.merge({
    calibrations: {
      ...state.get('calibrations'),
      [companyId]: calibrations,
    },
    calibrationsIsLoading: {
      ...state.get('calibrationsIsLoading'),
      [companyId]: false,
    },
  })
}
export const getCalibrationsFailure = (state, { companyId }) => state.merge({
  calibrationsIsLoading: {
    ...state.get('calibrationsIsLoading'),
    [companyId]: false,
  },
})

const addCalibrationLoading = state => state.merge({ addCalibrationLoading: true })
const addCalibrationSuccess = (state, { companyId, calibration }) => {
  const calibrations = state.get('calibrations')
  return state.merge({
    calibrations: {
      ...calibrations,
      [companyId]: [calibration, ...(calibrations[companyId] || [])],
    },
    addCalibrationLoading: false,
  })
}
const addCalibrationFailure = state => state.merge({ addCalibrationLoading: false })

export const reducer = createReducer(INITIAL_STATE, {
  [CalibrationTypes.GET_COMPANY_CALIBRATIONS_LOADING]: getCalibrationsLoading,
  [CalibrationTypes.GET_COMPANY_CALIBRATIONS_SUCCESS]: getCalibrationsSuccess,
  [CalibrationTypes.GET_COMPANY_CALIBRATIONS_FAILURE]: getCalibrationsFailure,

  [CalibrationTypes.ADD_CALIBRATION_LOADING]: addCalibrationLoading,
  [CalibrationTypes.ADD_CALIBRATION_SUCCESS]: addCalibrationSuccess,
  [CalibrationTypes.ADD_CALIBRATION_FAILURE]: addCalibrationFailure,
})
