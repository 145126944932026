import { isAdmin, isCRSAdvanced } from 'modules/users/selectors'
import { getCurrentProjectCRSId } from 'modules/projects/selectors'

// import { createSelector } from 'reselect'
// import { getCoordinateSystemLabelWithType } from 'utils/coordinateSystem';

export const getConstants = state => {
  return state.app.get('constants')
}

export const getAllSystemTypes = state => {
  return (state.app.get('systemTypes') || []).filter(systemType => !systemType.deprecated)
}

export const isGetAllSystemTypesLoading = state => state.app.get('getAllSystemTypesIsLoading')

export const getIndustries = state => {
  return state.app.get('constants').industries
}

export const getAntennas = state => {
  return state.app.get('constants').antennas
}

export const getDatums = state => {
  return state.app.get('constants').ie_datums
}

export const getIEDatums = state => {
  return state.app.get('constants').ie_datums
}

export const getEPSGDatums = state => {
  return state.app.get('constants').epsg_datums
}

export const getEpochs = state => {
  return state.app.get('constants').epochs
}

export const getClassifications = state => {
  return state.app.get('constants').classifications
}

export const getCoordinateSystems = state => {
  return state.app.get('constants').coordinateSystems
}
export const getVerticalCoordinateSystems = state => {
  return state.app.get('constants').verticalCoordinateSystems
}

// Building the list of suggestions is a really expensive operation on a huge set of data which is
// why the result has to be cached using reselect.
/*
export const verticalCoordinateSystemsSuggestionsSelector =
  createSelector(
    getVerticalCoordinateSystems,
    coordinateSystems => coordinateSystems.map(getCoordinateSystemLabelWithType)
  )
*/
export const getHorizontalGeographic3DCoordinateSystems = state => {
  return state.app.get('constants').horizontalGeographic3DCoordinateSystems
}
export const getHorizontalCoordinateSystems = state => {
  return state.app.get('constants').horizontalCoordinateSystems
}
export const getCompoundCoordinateSystems = state => {
  return state.app.get('constants').compoundCoordinateSystems
}

export const getCountries = state => {
  return state.app.get('constants').countries
}

export const getTimezones = state => {
  return state.app.get('constants').timeZones
}

export const getGeoids = state => {
  return state.app.get('constants').geoids
}

export const getUnitSystems = state => {
  return ['metric', 'imperial']
}
export const isLoadingConstants = state => {
  return state.app.get('getConstantsIsLoading')
}
export const isLoadingAntennas = state => {
  return state.app.get('getAntennasIsLoading')
}
export const isConstantsLoaded = state => {
  return state.app.get('constantsLoaded')
}

export const getFileTypes = state => {
  return state.app.get('constants').filetypes
}

export const getUnits = state => {
  return state.app.get('constants').units
}

export const getProjectsUserSettings = state => {
  return state.app.get('userSettings').projects
}

export const getRecommendedDatums = (state, lat, lng) => {
  return state.app.get('recommendedDatums')[`${lat},${lng}`] || []
}

/*
  Allow step only for:
    1) admins
    2) advanced users
    3) there is no project CRS in the project
*/
export const isCRSEnabled = state => {
  const projectCRSId = getCurrentProjectCRSId(state)
  const isCRSAdvancedUser = isCRSAdvanced(state)
  return isAdmin(state) || !projectCRSId || Boolean(isCRSAdvancedUser)
}

export const getGCPTemplate = state => {
  return state.app.get('gcpTemplate')
}

export const getHeaderHeight = state => {
  return state.app.get('headerHeight')
}

export const getBrowser = state => {
  return state.app.get('browser')
}

export const getProducts = state => {
  return state.app.get('products')
}
