import { isProductionEnvironment } from 'config'
import ReactGA from 'react-ga4'

/**
 * Sends request to google analytics
 * @param {String} url url at which action is happened
 * @param {String} hitType https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference?sjid=17076053146445056153-EU#hitType
 */
export function sendGA (url, hitType = 'pageview') {
  if (isProductionEnvironment()) {
    ReactGA.send({ hitType: hitType, page: window.location.origin + url, title: '' })
  }
}
