import React from 'react'
import { logExtensions, TrajectoryPathExtensions } from 'modules/upload/file-types'
import { SystemType } from 'types/missions'
import { TrajectoryTemplate } from './nonMission'
import Warning from 'components/Warning'

export const SbetTemplate = {
  'trj': {
    min: 1,
    max: 1,
    // eslint-disable-next-line
    displayFileTypes: true,
    fileType: 'trj',
    fileTypes: ['.out'],
    /*
    customMissionCheck: (
      filesOfTypeAdded,
      filesOfTypePotentiallyAdded,
      filesOfExtensionAdded,
      filesOfExtensionPotentiallyAdded,
      fileType,
      fileExtension,
      errors
    ) => {
      const fileShouldBeAdded = (filesOfExtensionAdded.length <= 0 || filesOfExtensionPotentiallyAdded.length <= 0)
      return fileShouldBeAdded
    },
    isMissionValid: (files, suggestions) => true,
    */
  },
}

export const defaultMissionType = SystemType.PHOENIX_V4_V5

export const MissionTemplate = {
  [SystemType.PHOENIX_V4_V5]: {
    'plp': {
      min: 1,
      max: 1,
      fileTypes: ['.plp'],
    },
    'nav': {
      min: 1,
      max: 1,
      fileTypes: ['.nav', '.t04'],
    },
    'ldr': {
      min: 0,
      max: 100,
      text: 'ldr/rxp',
      fileTypes: ['.ldr', '.rxp', '.sdc', '.sdcx'],
    },
    'log': {
      min: 0,
      max: 100,
      fileTypes: logExtensions,
    },
  },
  [SystemType.PHOENIX_V3]: {
    'nav': {
      min: 1,
      max: 1,
      fileTypes: ['.nav', '.t04'],
    },
    'ldr': {
      min: 0,
      max: 100,
      text: 'ldr(rxp)',
      fileTypes: ['.ldr', '.rxp', '.sdc', '.sdcx'],
    },
    'cam': {
      min: 0,
      max: 100,
    },
    'log': {
      min: 0,
      max: 100,
      fileTypes: logExtensions,
    },
  },
  [SystemType.HYSPEX]: {
    'plp': {
      min: 1,
      max: 1,
      fileTypes: ['.plp'],
    },
    'ldr': {
      min: 1,
      max: 100,
      displayFileTypes: true,
      fileTypes: ['.pcap'],
    },
    'trj': {
      min: 1,
      max: 1,
      // eslint-disable-next-line
      displayFileTypes: true,
      fileType: 'trj',
      fileTypes: ['.out'],
    },
    'log': {
      min: 0,
      max: 1,
      displayFileTypes: true,
      fileType: 'log',
      fileTypes: ['.log'],
    },
    /*
    'nav': {
      min: 1,
      max: 1,
      fileTypes: ['.nav', '.t04'],
    },
    */
    /*
    'log': {
      min: 0,
      max: 100,
      fileTypes: [ '.kernelstop'],
    },
    */
  },
  [SystemType.RECON]: {
    'data': {
      min: 1,
      max: 10000,
      displayFileTypes: true,
      fileTypes: ['.data'],
    },
    'log': {
      min: 0,
      max: 1,
      onlyText: true,
      text: <Warning variant='warning' containerStyle={{
        fontWeight: '500',
        fontStyle: 'normal',
      }}>service.log (file is optional but we encourage you to upload it)</Warning>,
      displayFileTypes: true,
      fileTypes: ['service.log'],
    },
    'trj': {
      min: 0,
      max: 1,
      // eslint-disable-next-line
      displayFileTypes: true,
      fileTypes: [
        ...TrajectoryPathExtensions,
        '.pof',
        '.poq',
        '.out',
      ],
      customMissionCheck: (
        filesOfTypeAdded,
        filesOfTypePotentiallyAdded,
        filesOfExtensionAdded,
        filesOfExtensionPotentiallyAdded,
        fileType,
        fileExtension,
        errors
      ) => {
        const fileShouldBeAdded = (filesOfExtensionAdded.length <= 0 || filesOfExtensionPotentiallyAdded.length <= 0)
        return fileShouldBeAdded
      },
      isMissionValid: (files, suggestions) => true,
    },
  },
  [SystemType.RECON_UNPACKED]: {
    'plp': {
      min: 1,
      max: 1,
      fileTypes: ['.plp'],
    },
    'nav': {
      min: 1,
      max: 1,
      fileTypes: ['.nav', '.t04', '.ubx'],
    },
    'ldr': {
      min: 0,
      max: 100,
      text: 'ldr/rxp',
      fileTypes: ['.ldr', '.rxp', '.sdc', '.sdcx'],
    },
    'log': {
      min: 0,
      max: 1,
      onlyText: true,
      text: <Warning variant='warning' containerStyle={{
        fontWeight: '500',
        fontStyle: 'normal',
      }}>service.log (file is optional but we encourage you to upload it)</Warning>,
      displayFileTypes: true,
      fileTypes: ['service.log'],
    },
    'trj': {
      min: 0,
      max: 1,
      // eslint-disable-next-line
      displayFileTypes: true,
      fileTypes: [
        ...TrajectoryPathExtensions,
        '.pof',
        '.poq',
        '.out',
      ],
      customMissionCheck: (
        filesOfTypeAdded,
        filesOfTypePotentiallyAdded,
        filesOfExtensionAdded,
        filesOfExtensionPotentiallyAdded,
        fileType,
        fileExtension,
        errors
      ) => {
        const fileShouldBeAdded = (filesOfExtensionAdded.length <= 0 || filesOfExtensionPotentiallyAdded.length <= 0)
        return fileShouldBeAdded
      },
      isMissionValid: (files, suggestions) => true,
    },
  },
  [SystemType.POINTCLOUD_PROCESSINGS]: {
    'las': {
      min: 1,
      max: 100,
      // fileTypes: ['.las', '.laz'],
      fileTypes: [
        '.las',
        '.laz',
      ],
      displayFileTypes: true,
    },
    ...TrajectoryTemplate,
  },
}

export const EditMissionTemplate = {
  [SystemType.PHOENIX_V4_V5]: {
    ...MissionTemplate[SystemType.PHOENIX_V4_V5],
    ...TrajectoryTemplate,
  },
  [SystemType.PHOENIX_V3]: {
    ...MissionTemplate[SystemType.PHOENIX_V3],
    ...TrajectoryTemplate,
  },
  [SystemType.HYSPEX]: MissionTemplate[SystemType.HYSPEX],
  [SystemType.RECON]: MissionTemplate[SystemType.RECON],
  [SystemType.RECON_UNPACKED]: MissionTemplate[SystemType.RECON_UNPACKED],
  [SystemType.POINTCLOUD_PROCESSINGS]: MissionTemplate[SystemType.POINTCLOUD_PROCESSINGS]
}
