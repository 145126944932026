import { path } from 'ramda'
// Material UI
import { getCompanySubscriptions } from 'modules/companies/selectors'
import { getDateWithTimezone } from './dateTime'
import { getConstants } from 'modules/app/selectors'

/**
 * Checks if the given `subscription` is the active subscription
 * @param {Subscription} subscription
 * @returns {Boolean}
 */
export const isActiveSubscription = subscription => {
  const lowerCasedStatus = (subscription.subscription_state || '').toLowerCase()
  return Boolean(lowerCasedStatus === 'active' || lowerCasedStatus === 'feature')
}
/**
 * Checks if the given `subscription` is the cancelled subscription
 * @param {Subscription} subscription
 * @returns {Boolean}
 */
export const isCancelledSubscription = subscription => {
  const lowerCasedStatus = (subscription.subscription_state || '').toLowerCase()
  return Boolean(lowerCasedStatus === 'cancelled' || lowerCasedStatus === 'canceled')
}
/**
 * Checks if the given `subscription` is a Stripe subscription
 * @param {Subscription} subscription
 * @returns {Boolean}
 */
export const isStripeSubscription = subscription => {
  return subscription.subscription_type === 'stripe'
}
/**
 * Checks if the given `subscription` is a non-recurring subscription
 * @param {Subscription} subscription
 * @returns {Boolean}
 */
export const isNonRecurringSubscription = subscription => {
  return subscription.subscription_type === 'offline'
}
/**
 * Checks if the given `subscription` is a LiDARMill subscription by checking plan type
 * @param {Subscription} subscription
 * @param {Array.<SalesProduct>}
 * @returns {Boolean}
 */
export const isLidarMillSubscription = (subscription, salesProducts) => {
  if (subscription.product_duration) {
    if (subscription.product_duration.sales_product) {
      const salesProduct = salesProducts.find(sp => sp.id === subscription.product_duration.sales_product.id)
      return (salesProduct ? salesProduct.licensing_products || [] : []).some(licensingProduct => ((licensingProduct || {}).name || '').toLowerCase() === 'lidarmill')
    } else {
      return false
    }
  }
  // Legacy subscriptions
  const planType = path(['plan', 'p_type'], subscription)
  return (
    planType === 'sf_navlab' ||
    planType === 'navlab' ||
    planType === 'hosting' ||
    planType === 'ai_classification' ||
    planType === 'unlimited_storage' ||
    planType === 'extra_tb'
  )
}
/**
 * Returns the `active` subscriptions of the company
 * All non-recurring subscriptions with status `active` or `feature` are active subscriptions
 * Stripe subscriptions can be active only if the status
 * @param {AppState} state
 * @param {String} companyId
 * @returns {Array.<Subscription>}
 */
export function getActiveSubscriptions (state, companyId) {
  const salesProducts = getConstants(state).salesProducts
  const subscriptions = companyId ? getCompanySubscriptions(state, companyId) : []
  const lidarMillSubscriptions = subscriptions.filter(subscription => isLidarMillSubscription(subscription, salesProducts))
  const today = getDateWithTimezone()
  const stripeSubscriptions = lidarMillSubscriptions
    .filter(isStripeSubscription)
    .filter(subscription => {
      const isActive = isActiveSubscription(subscription)
      const isCancelled = isCancelledSubscription(subscription)
      // stripe subscription with status `cancelled` can be valid subscription until the `next_payment_date`
      // `end_date` already set to `next_payment_date`
      return isActive || (isCancelled && today.isSameOrBefore(subscription.end_date))
    })
  const nonRecurringSubscriptions = lidarMillSubscriptions
    .filter(isNonRecurringSubscription)
    .filter(isActiveSubscription)
  return [
    ...stripeSubscriptions,
    ...nonRecurringSubscriptions,
  ]
}
/**
 * Returns active subscriptions which are already expired, BUT due to some circumstances don't changed their status yet
 * @param {AppState} state
 * @param {String} companyId
 * @returns {Array.<Subscription>}
 */
export function getExpiredSubscriptions (state, companyId) {
  const today = getDateWithTimezone()
  const activeSubscriptions = getActiveSubscriptions(state, companyId)
  return activeSubscriptions.filter(subscription => today.isAfter(subscription.end_date))
}
/**
 * Returns active subscriptions which will be expired in two weeks from the current date
 * @param {AppState} state
 * @param {String} companyId
 * @returns {Array.<Subscription>}
 */
export function getExpireSoonSubscriptions (state, companyId) {
  const today = getDateWithTimezone()
  const twoWeeksAfterToday = today.clone().add(2, 'weeks')
  const activeSubscriptions = getActiveSubscriptions(state, companyId)
  return activeSubscriptions.filter(subscription => subscription.end_date.isBetween(today, twoWeeksAfterToday))
}
