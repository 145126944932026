import { create as createRand } from 'random-seed'

/**
 * Calculates a random color from a text. Will always calculate the same random color
 * for the same text.
 * @param string The text to colorize.
 * @param saturation The fixed saturation of the color. Default is 100.
 * @param lightness The fixed lightness of the color. Default is 87.5 for colors from
 *  the pastel color-space.
 * @return The random color to be used as CSS color value in `hsl(h, s, l)` format.
 */
export function colorize (text, saturation = 100, lightness = 87.5) {
  const rand = createRand(text)
  const hue = rand.range(360)
  return `hsl(${hue},${saturation}%,${lightness}%)`
}

export function colorizeHEX (text, saturation = 100, lightness = 87.5) {
  const rand = createRand(text)
  const hue = rand.range(360)
  return hslToHex(hue, saturation, lightness)
}

function hslToHex (h, s, l) {
  l /= 100
  const a = s * Math.min(l, 1 - l) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color).toString(16).padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}
