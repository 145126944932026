import { Map } from 'immutable'
import { artifactState } from 'modules/artifacts/initialState'
import { pipelineState } from 'modules/pipelines/initialState'

const defaultPagination = {
  has_next: false,
  has_prev: false,
  page: 1,
  page_size: 50,
  total: 0,
  total_pages: 0,
}

export const defaultProjectData = {
  projectId: undefined,
  userId: undefined,
  pipelines: [],
  artifacts: [],
  project: {},
  dataDirectories: [],
  dataFiles: [],
  missions: [],
  positions: [],
}

export const INITIAL_STATE = Map({
  ...artifactState,
  ...pipelineState,
  transferProjectIsLoading: false,
  currentProject: defaultProjectData,
  pagination: defaultPagination,

  getProjectsQueriedIsLoading: false,
  getProjectsQueriedErrorMessage: null,

  getCurrentProjectDataIsLoading: false,
  getCurrentProjectDataErrorMessage: null,

  gcps: {},
  createCRSLoading: false,

  deletePosition: {},
  addPositionIsLoading: false,

  artifactsByProject: {},
  getProjectArtifactsIsLoading: false,

  allArtifacts: [],
  allPipelines: [],

  projects: [],
  minimalProjects: [],
  getProjectsIsLoading: false,
  getProjectsErrorMessage: null,

  createProjectIsLoading: false,
  createProjectErrorMessage: null,

  projectsByUser: [],
  getProjectsByUserIsLoading: false,
  getProjectsByUserErrorMessage: null,

  deleteProject: {},
  deleteProjectRequest: [],
  deleteProjectErrorMessage: null,

  createPipelineIsLoading: false,
  createPipelineErrorMessage: null,

  createArtifactIsLoading: false,
  createArtifactErrorMessage: null,

  getDataForArtifactErrorMessage: null,
  artifactDataLoading: {},

  deleteArtifactIsLoading: false,
  deleteArtifactErrorMessage: null,

  getCurrentProjectDataForArtifactIsLoading: false,
  getCurrentProjectDataForArtifactErrorMessage: null,

  getDataForPipelineErrorMessage: null,
  pipelineDataLoading: {},

  updateArtifactIsLoading: false,
  updateArtifactErrorMessage: null,

  getCurrentProjectDataForPipelineIsLoading: false,

  getDataForMissionArtifactsIsLoading: false,
  getDataForMissionArtifactsErrorMessage: null,

  listProjectsIsLoading: false,

  getDataForArtifactsIsLoading: false,

  getPipelinesForCurrentProjectIsLoading: false,

  recursivePipelinesIsDone: {},
  recursivePipelinesIsLoading: {},

  getPipelinesForCurrentProjectErrorMessage: null,

  utmZone: {},

  getDataForEditMissionArtifactsIsLoading: false,
  downloadToken: {},
  isGetDownloadTokenLoading: {},
})
