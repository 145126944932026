import { isFileNameEndsWith } from 'utils/baseName'

export const imageExtensions = [
  // Mono
  '.mono8',
  '.mono10',
  '.mono10packed',
  '.mono12',
  '.mono12packed',
  '.mono16',
  // Bayer
  '.bayergr8',
  '.bayerrg8',
  '.bayergb8',
  '.bayerbg8',
  '.bayergr10',
  '.bayergr10packed',
  '.bayerrg10',
  '.bayerrg10packed',
  '.bayergb10',
  '.bayergb10packed',
  '.bayerbg10',
  '.bayerbg10packed',
  '.bayergr12',
  '.bayergr12packed',
  '.bayerrg12',
  '.bayerrg12packed',
  '.bayergb12',
  '.bayergb12packed',
  '.bayerbg12',
  '.bayerbg12packed',
  '.bayergr16',
  '.bayerrg16',
  '.bayergb16',
  '.bayerbg16',
  // RGB
  '.rgb8',
  // BGR
  '.bgr8',
  // YCBCR
  '.ycbcr422',
  // YUV
  '.yuv411packed',
  '.yuv422packed',
  '.yuv444packed',
  '.yuv422_yuyv_packed',
  // PGR
  '.pgr',
  '.th2',
  '.cr2',
  '.thm',
  '.jpg',
  '.jpeg',
  '.png',
]

export const isPGRImageFile = fileName => {
  return isFileNameEndsWith(fileName, '.pgr')
}
