import { ArtifactTypes } from 'types/artifacts'
// Local deps
import NavigationRoverTemplate from './jobIoOptions/navigationRover'
import CameraDataTemplate from './jobIoOptions/cameraData'
import LidarDataTemplate from './jobIoOptions/lidarData'
import ReferenceStationTemplate from './jobIoOptions/referenceStation'
import { pipelineTemplates } from './pipelines'

export function getJobIoOptionsForArtifactTemplate (pipelineJobType, artifactType) {
  const pipelineTemplate = pipelineTemplates.find(pipelineTemplate => pipelineTemplate.type === pipelineJobType)
  if (!pipelineTemplate) {
    return {}
  }
  const artifactTemplate = pipelineTemplate.artifacts.find(artifact => artifact.artifactType === artifactType)
  if (!artifactTemplate) {
    return {}
  }
  const { display = true, render = undefined } = artifactTemplate
  if (display) {
    if (render) {
      return render
    } else {
      return templateJobIOOptions[artifactType]
    }
  }
  return {}
}

export const templateJobIOOptions = {
  [ArtifactTypes.REFERENCE_STATION]: ReferenceStationTemplate,
  [ArtifactTypes.NAVIGATION_ROVER]: NavigationRoverTemplate,
  // [ArtifactTypes.RECON_DATA]: {},
  [ArtifactTypes.LIDAR_DATA]: LidarDataTemplate,
  [ArtifactTypes.CAMERA_DATA]: CameraDataTemplate,
}
