import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getUsers: ['excludeArray'],
  getUsersLoading: null,
  getUsersSuccess: ['users'],
  getUsersFailure: ['errorMessage'],

  // getUser
  getUser: ['payload'],
  getUserLoading: null,
  getUserSuccess: ['users'],
  getUserFailure: ['errorMessage'],

  // getActiveUser
  getActiveUser: ['user_id'],
  getActiveUserLoading: null,
  getActiveUserSuccess: ['user'],
  getActiveUserFailure: ['errorMessage'],

  setActiveUserSubscription: ['subscription'],
  setActiveUserGrants: ['grants'],

  // getMe
  getMe: null,
  getMeLoading: null,
  getMeSuccess: ['user'],
  getMeFailure: ['errorMessage'],

  // createUser
  createUser: ['payload'],
  createUserLoading: null,
  createUserSuccess: null,
  createUserFailure: ['errorMessage'],

  // login
  login: ['payload'],
  loginLoading: null,
  loginSuccess: ['token', 'user'],
  loginFailure: ['errorMessage', 'passwordNotSet'],

  // register
  register: ['payload', 'createdByUser'],
  registerLoading: null,
  registerSuccess: ['user', 'createdByUser'],
  registerFailure: ['errorMessage', 'createdByUser'],

  // logout
  logout: null,
  logoutLoading: null,
  logoutSuccess: null,
  logoutFailure: null,

  // request recovery
  requestRecovery: ['payload'],
  requestRecoveryLoading: null,
  requestRecoverySuccess: null,
  requestRecoveryFailure: ['errorMessage'],

  // recover password
  recover: ['payload'],
  recoverLoading: null,
  recoverSuccess: null,
  recoverFailure: ['errorMessage'],

  confirmEmail: ['token'],
  confirmEmailLoading: null,
  confirmEmailSuccess: null,
  confirmEmailFailure: ['errorMessage'],

  sendConfirmEmail: ['email'],
  sendConfirmEmailLoading: null,
  sendConfirmEmailSuccess: null,
  sendConfirmEmailFailure: ['errorMessage'],

  // updateUser
  updateUser: ['payload', 'withSuccessMessage'],
  updateUserLoading: null,
  updateUserSuccess: ['user'],
  updateUserFailure: ['errorMessage'],

  // change password
  changePassword: ['id', 'oldPassword', 'newPassword', 'repeatedPassword'],
  changePasswordLoading: null,
  changePasswordSuccess: null,
  changePasswordFailure: ['errorMessage'],

  hideSystemSettings: null,
  showSystemSettings: null,

  acceptTerms: null,

  setAcceptTermsFormOpen: ['open'],

  getProjectsStatistic: null,
  getProjectsStatisticLoading: null,
  getProjectsStatisticSuccess: ['projectsStatistic'],
  getProjectsStatisticFailure: null,

  getSubscriptions: ['userId'],
  getSubscriptionsLoading: ['userId'],
  getSubscriptionsSuccess: ['userId', 'subscriptions'],
  getSubscriptionsFailure: ['userId'],

  updateSubscription: ['companyId', 'subscriptionId', 'payload'],
  updateSubscriptionSuccess: ['companyId', 'subscriptionId', 'subscription'],

  getUserPermissions: ['userId'],
  getUserPermissionsLoading: ['userId'],
  getUserPermissionsSuccess: ['userId', 'permissions'],
  getUserPermissionsFailure: ['userId'],

  getCompanyCRS: ['companyId'],
  getCompanyCRSLoading: ['companyId'],
  getCompanyCRSSuccess: ['companyId', 'crs'],
  getCompanyCRSFailure: ['companyId', 'errorMessage'],

  getCRS: ['crsId'],
  getCRSLoading: ['crsId'],
  getCRSSuccess: ['crsId', 'crs'],
  getCRSFailure: ['crsId', 'errorMessage'],

  updateCRSStatus: ['title', 'total', 'current'],
  updateCRS: ['projectId', 'crsId', 'crs'],
  updateCRSLoading: ['projectId', 'crsId'],
  updateCRSSuccess: ['projectId', 'crsId', 'crs'],
  updateCRSFailure: ['projectId', 'crsId', 'errorMessage'],

  deleteCRS: ['projectId', 'crsId'],
  deleteCRSLoading: ['projectId', 'crsId'],
  deleteCRSSuccess: ['projectId', 'crsId'],
  deleteCRSFailure: ['projectId', 'crsId', 'errorMessage'],
  // login with two-factor authentication
  twoFactorLogin: ['payload'],
  twoFactorLoginLoading: null,
  twoFactorLoginSuccess: ['token', 'user', 'remember_2fa_token', 'remember_2fa_token_exp'],
  twoFactorLoginFailure: ['errorMessage', 'isTokenExpired'],

  getUserReleases: null,
  getUserReleasesLoading: null,
  getUserReleasesSuccess: ['releases'],
  getUserReleasesFailure: ['errorMessage'],

  setUserSeenReleases: ['productName', 'numberOfReleases', 'onSuccess'],
  setUserSeenReleasesLoading: null,
  setUserSeenReleasesSuccess: ['productName', 'numberOfReleases'],
  setUserSeenReleasesFailure: ['errorMessage'],

  setTwoFactorLogin: ['temp_token'],
})

export const UsersTypes = Types
export default Creators
