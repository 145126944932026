import { isFileNameEndsWith } from 'utils/baseName'
import { getFileFromDropbox } from './utils'

/**
 * Checks whether the file name of a file looks like a log file name.
 * This is done by looking at the extension and checking whether it is ".log".
 */
// function isLogFileName(fileName: string): boolean
export const logExtensions = ['.log', '.kernelstop', '.kernelstart'] // '.kernelstart'

export function isLogFileName (fileName) {
  const extensions = logExtensions
  return extensions.some(logExtension => isFileNameEndsWith(fileName, logExtension))
}

export function isSbetLogFileName (fileName) {
  const parseableExtensions = ['.log']
  return parseableExtensions.some(extension => {
    return fileName.includes('sbet') && isFileNameEndsWith(fileName, extension)
  })
}

export function shouldLogFileBeParsed (fileName) {
  return isSbetLogFileName(fileName)
}

export function isLogFileType (fileType) {
  return fileType === 'log'
}

// function parsePlpFile(file: File): Promise<PlpFileParserInfo>
export async function parseLogFile (file, blob) {
  return new Promise(resolve => {
    const log = []
    /*
    if (!isCamFileName(file.name)) {
      log.push({
        level: 'warning',
        message: `Unsupported file extension in filename "${file.name}"`,
      })
    }
    if (file.size < headerSize) {
      const message =
        `Invalid file size. File is only ${file.size} bytes long. At least ${headerSize} bytes are needed.`
      log.push({ level: 'error', message })
      resolve({ okay: false, log, fileType: 'log' })
      return
    }
    */
    const fileReader = new FileReader()
    fileReader.onload = () => {
      // ArrayBuffer
      const buffer = fileReader.result
      const splittedBuffer = buffer.split('\r')
      const result = {
        missionDate: splittedBuffer[5].split(':')[1].trim(),
        datum: splittedBuffer[16].split(':')[1].trim(),
        epoch: splittedBuffer[19].split(':')[1].trim(),
      }
      resolve({ result, okay: true, log, fileType: 'log' })
    }
    fileReader.onerror = () => {
      log.push({
        level: 'error',
        message: `Error reading file "${file.name}".`,
      })
      resolve({ okay: false, log, fileType: 'log' })
    }
    fileReader.readAsText(blob || file)
  })
}

export async function parseDropboxLogFile (file) {
  return getFileFromDropbox(file, { responseType: 'arraybuffer' }, { type: 'application/json' }, parseLogFile)
}
