import React from 'react'
import { path } from 'ramda'
import { Trans } from 'react-i18next'
// Project deps
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import { MapBackendNameToFrontend } from 'templates/constants'
import { getJobOptions, getPipelineTemplate } from 'modules/pipelineWizard/selectors'
import { isPointcloudOptimizationJob } from 'utils/jobs'
import WikiButton from 'components/WikiButton'

export default {
  trj_optimization: {
    name: (state, values, extra) => {
      return <span>{MapBackendNameToFrontend.trj_optimization} <WikiButton title={<Trans i18nKey='templates.jobOptions.optimization.tooltip.trj_optimization' />} /></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'trj_optimization', true),
  },
  gcp_align_to_ground_only: {
    name: MapBackendNameToFrontend.gcp_align_to_ground_only,
    dataType: DataType.BOOLEAN,
    invisible: (state, values, extra, formTemplate, option, options) => {
      const selectedTemplate = getPipelineTemplate(state)
      const jobId = (selectedTemplate.jobs.find(templateJob => isPointcloudOptimizationJob(templateJob.jobType)) || {}).templateId
      const jobValues = getJobOptions(state)[jobId]
      if (jobValues && path(['rawValues', 'dataset_type'], jobValues) === 'Mobile') {
        return true
      }
      return false
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'gcp_align_to_ground_only',
      false,
    ),
    backendTransform: (value, state) => {
      const selectedTemplate = getPipelineTemplate(state)
      const jobId = (selectedTemplate.jobs.find(templateJob => isPointcloudOptimizationJob(templateJob.jobType)) || {}).templateId
      const jobValues = getJobOptions(state)[jobId]
      if (jobValues && path(['rawValues', 'dataset_type'], jobValues) === 'Mobile') {
        return false
      }
      return value
    },
  },
  sensor_calibration: {
    name: (state, values, extra) => {
      return <span>{MapBackendNameToFrontend.sensor_calibration} <WikiButton title={<Trans i18nKey='templates.jobOptions.optimization.tooltip.sensor_calibration' />} /></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'sensor_calibration', false),
  },
  warning: {
    name: '',
    variant: 'error',
    dataType: DataType.TEXT,
    initialValue: 'Please select at least one of the provided options to continue processing',
    invisible: (state, values) => values.trj_optimization || values.sensor_calibration,
    isValid: (state, values) => values.trj_optimization || values.sensor_calibration,
    sendToBackend: false,
  },
}
