import { getCRS } from 'modules/crs/api'
import { call, put, takeLatest, fork, select } from 'redux-saga/effects'
// Project deps
import axios from 'utils/axios'
// Local deps
import ProjectWizardActions, { ProjectWizardTypes } from './actions'
import { getTransformedGenericCRS } from './utils'

// Sagas
// Get generic CRS data
function * getGenericCRS ({ lat, lon }) {
  yield put(ProjectWizardActions.getGenericCRSLoading())
  try {
    const state = yield select(state => state)
    const { data: { data: crs } } = yield call(axios.get, `coordinate_reference_systems/generic?lat=${lat}&lon=${lon}`)
    const convertedCRS = getTransformedGenericCRS(state, crs)
    yield put(ProjectWizardActions.getGenericCRSSuccess(convertedCRS))
  } catch (e) {
    yield put(ProjectWizardActions.getGenericCRSFailure())
  }
}

// Get predefined CRS data
function * getPredefinedCRS ({ crsId }) {
  yield put(ProjectWizardActions.getPredefinedCRSLoading(crsId))
  try {
    const state = yield select(state => state)
    const { okay, crs } = yield call(getCRS, crsId)
    if (!okay) {
      throw new Error()
    }
    const convertedCRS = getTransformedGenericCRS(state, crs)
    yield put(ProjectWizardActions.getPredefinedCRSSuccess(crsId, convertedCRS))
  } catch (e) {
    yield put(ProjectWizardActions.getPredefinedCRSFailure(crsId))
  }
}

// Watchers
function * getGenericCRSWatcher () {
  yield takeLatest(ProjectWizardTypes.GET_GENERIC_CRS, getGenericCRS)
}
function * getPredefinedCRSWatcher () {
  yield takeLatest(ProjectWizardTypes.GET_PREDEFINED_CRS, getPredefinedCRS)
}

export default function * root () {
  yield fork(getGenericCRSWatcher)
  yield fork(getPredefinedCRSWatcher)
}
