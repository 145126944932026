import React from 'react'
import i18n from 'i18n'
import { Trans } from 'react-i18next'
import { path } from 'ramda'
import { findById, makeUniqueById } from 'utils/list'
import { getArtifacts, getCurrentProject, getMissions } from 'modules/projects/selectors'
import { getSelectedCameraArtifacts } from 'modules/pipelineWizard/selectors'
import { getCompanyPositions } from 'modules/companies/selectors'
import { getActiveUser, getLoggedUser } from 'modules/users/selectors'
import { getImportWizard } from 'modules/importWizard/selectors'
import Warning from 'components/Warning'
import { getCompanyId } from 'utils/company'
import { getArtifactSensorIndex } from 'utils/artifacts'
// Local deps
import { CRSFields } from './CRS/constants'
import { getRotation, getTranslation, isSE5AndLowerPLP } from 'utils/calibrationconverter'
import { getArtifactProperties } from './utils'
import { analyzePriority, DatumPriority, analyzeToDescription } from './constants'

function getSensorTransform (state, artifactId, selectedIndex, settingsRoverFieldName, sessionsFieldName) {
  const { plp = {}, settingsFilesProcessingType } = getImportWizard(state, ['plp', 'settingsFilesProcessingType'])
  const artifacts = getArtifacts(state)
  const artifact = findById(artifactId, artifacts)
  const missions = getMissions(state)
  const mission = findById(artifact.missionId, missions)
  const typeOfIndex = typeof selectedIndex
  const index = typeOfIndex === 'string' ? selectedIndex : typeOfIndex === 'number' ? `${selectedIndex}` : '0'

  // If user uploaded settings plp file
  if (plp['settingsFiles']) {
    if (isSE5AndLowerPLP(plp['settingsFiles'])) {
      const settingsTransform = settingsFilesProcessingType === 'rover'
        ? path(['settingsRover', settingsRoverFieldName, index, 'processing', 'transform'], plp['settingsFiles'])
        : path([sessionsFieldName, index, 'settingsProcessing', 'transform'], plp['settingsFiles'])
      if (typeof settingsTransform !== 'undefined') {
        return { offset: getTranslation(settingsTransform), rotation: getRotation(settingsTransform) }
      }
    } else {
      const settingsMounting = settingsFilesProcessingType === 'rover'
        ? path(['settingsRover', settingsRoverFieldName, index, 'processing', 'mounting'], plp['settingsFiles'])
        : path([sessionsFieldName, index, 'settingsProcessing', 'mounting'], plp['settingsFiles'])
      if (typeof settingsMounting !== 'undefined') {
        return { offset: getTranslation(settingsMounting), rotation: getRotation(settingsMounting) }
      }
    }
  }

  // Trying to get offset and rotation from mission PLP
  // V6/V7
  const mounting = path(['plp', sessionsFieldName, index, 'settingsProcessing', 'mounting'], mission)
  if (typeof mounting !== 'undefined') {
    return { offset: getTranslation(mounting), rotation: getRotation(mounting) }
  }
  // V4/V5
  const transform = path(['plp', sessionsFieldName, index, 'settingsProcessing', 'transform'], mission)
  if (typeof transform !== 'undefined') {
    return { offset: getTranslation(transform), rotation: getRotation(transform) }
  }

  // If not set till this we can only get it from properties
  const { properties } = artifact
  if (typeof properties !== 'undefined' &&
    typeof properties.offset !== 'undefined' &&
    typeof properties.rotation !== 'undefined'
  ) {
    const { offset, rotation } = properties
    return {
      offset,
      rotation: rotation.map(clampBetween360Degrees),
    }
  }

  return { offset: [0, 0, 0], rotation: [0, 0, 0] }
}
export function getTabsTemplate (values) {
  return (values[CRSFields.TABS_TEMPLATE] || {}).initialValue || {}
}

export function getFocalLengths (state, values) {
  const sensorIndex = values.index
  const cameraArtifacts = [
    ...getSelectedCameraArtifacts(state),
  ]
  const missions = getMissions(state)
  const missionIds = cameraArtifacts.map(art => art.missionId).filter(id => id)
  const selectedMissions = makeUniqueById(
    missionIds
      .map(missionId => findById(missionId, missions))
      .filter(mission => mission)
  )
  const focalLengths = selectedMissions.length > 0 ? selectedMissions.map(mission => {
    const receptors = path(['plp', 'sessionsCameras', sensorIndex, 'settingsProcessing', 'receptors'], mission)
    return receptors ? path(['focalLength', 'x'], receptors[0]) || 16 : 16
  }) : [16]
  return focalLengths
}

export function focalLengthWarningMessage (state, values) {
  const focalLengths = getFocalLengths(state, values)
  const [firstFocalLength] = focalLengths
  const isDifferentFocalLengths = !focalLengths.every(length => length === firstFocalLength)
  return isDifferentFocalLengths ? <Warning variant='warning'><Trans i18nKey='templates.artifactOptions.camera.warning.focalLengthNotMatch'/></Warning> : null
}

/**
 * Create an entry in the dropdown menu for a position extraction method which exists in the
 * `Artifact`'s properties.
 */
export function createAnalyzeEntry (analyzeMode, properties, type, data) {
  const analyzeModeValues = path(['position', analyzeMode], properties)
  const isCustomMode = type === 'custom'
  const estimatedErrorRms =
  // If the mode is `Custom`, then set infinity as the error.
  isCustomMode ? Infinity
    : analyzeMode === 'rinex'
      ? 0.0
      : analyzeModeValues.estimatedErrorRms
  const errorString = (isCustomMode || !estimatedErrorRms)
    ? ''
    : i18n.t('templates.artifactOptions.referenceStation.warning.analyzeEntryError', { error: Math.round(estimatedErrorRms * 10000) / 100 })
  const datum = analyzeModeValues && analyzeModeValues.datum
  const epoch = analyzeModeValues && analyzeModeValues.epoch
  const positionName = analyzeModeValues && analyzeModeValues.name
  const description = `${positionName || analyzeToDescription[analyzeMode] || analyzeMode}${datum ? ` (${datum}${`, EPOCH: ${epoch}` || ''})` : ''}${errorString}`
  // const datumPriority = datum && DatumPriority.find(dp => datum.startsWith(dp.datum))
  return {
    priority: analyzePriority[analyzeMode] || (isCustomMode ? 10000 : 1),
    analyzeMode,
    description,
    newDescription: description,
    ...analyzeModeValues,
    estimatedErrorRms,
    type,
    data,
  }
}

export const isCalibrateCameraEnabled = (state, formValues) => {
  return getSelectedCameraArtifacts(state).length > 0
}

export function createAnalyzeEntryMapping (state, artifactId) {
  const artifactProperties = getArtifactProperties(state, artifactId)
  const position = path(['position'], artifactProperties)
  const availableAnalyzeModes = position
    ? Object.keys(position).filter(positionKey => positionKey !== 'centerpointRTX_pdf')
    : []
  const succeededAnalyzeModes = availableAnalyzeModes.filter(mode =>
    path(['position', mode, 'failed'], artifactProperties) !== true
  )
  let positions = []
  const loggedUser = getLoggedUser(state)
  const currentProject = getCurrentProject(state)
  if (currentProject.project.userId !== loggedUser.id) {
    const activeUser = getActiveUser(state)
    const activeUserCompanyId = getCompanyId(activeUser)
    positions = getCompanyPositions(state, activeUserCompanyId)
  } else {
    const companyId = getCompanyId(loggedUser)
    positions = getCompanyPositions(state, companyId)
  }
  const analyzeEntries = [
    ...succeededAnalyzeModes.map(mode => createAnalyzeEntry(mode, artifactProperties, 'analyzed')),
    createAnalyzeEntry('custom', artifactProperties, 'custom'),
  ]
  const customPositions = positions.map(customPosition => {
    return createAnalyzeEntry(customPosition.name, artifactProperties, 'custom', {
      ...customPosition,
      antennaName: customPosition.antenna_name || path(['antenna', 'name'], customPosition) || '',
      heightAntenna: customPosition.height_antenna,
      heightAntennaReference: customPosition.height_antenna_reference,
    })
  })
  return analyzeEntries.concat(customPositions).sort((a, b) => a.estimatedErrorRms - b.estimatedErrorRms)
}

export function isImuPresent (state, artifactId) {
  return Boolean(path(['inertial', 'imu'], getArtifactProperties(state, artifactId)))
}

export function getLidarTransform (state, artifactId, selectedIndex) {
  return getSensorTransform(state, artifactId, selectedIndex, 'lidars', 'sessionsLidars')
}

export function getCameraTransform (state, artifactId, selectedIndex) {
  return getSensorTransform(state, artifactId, selectedIndex, 'cameras', 'sessionsCameras')
}

export function getSensorIndex (state, artifactId, options) {
  const { extraProps } = options
  const { sensorIndex } = extraProps
  if (typeof sensorIndex !== 'undefined') {
    return sensorIndex
  }
  const artifacts = getArtifacts(state)
  const artifact = findById(artifactId, artifacts)
  return getArtifactSensorIndex(artifact)
}

export function clampBetween360Degrees (value) {
  const parsedValue = parseFloat(value)
  if (isNaN(parsedValue)) return 0
  const absoluteValue = Math.abs(parsedValue)
  const rotations = -Math.round(parsedValue / 360)
  return absoluteValue > 360
    ? clampBetween360Degrees(parsedValue + 360 * (rotations > 1 ? rotations - 1 : rotations))
    : parsedValue
}

export const processorToFrontendAntennaName = {
  'AbsoluteL1PhaseCenter': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.l1aRecommended'),
  // 'RelativeL1PhaseCenter': 'L1 Relative',
  'AntennaReferencePoint': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.arp'),
}

export const backendProcessorToFrontendAntennaName = {
  'l1a': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.l1aRecommended'),
  'arp': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.arp'),
}

export const getAntennaTemplate = entry => {
  if (!entry) {
    return ''
  }
  return `${entry.name}${entry.radome ? ` (${entry.radome})` : ''}`
}

