import { createReducer } from 'reduxsauce'
import { orderBy } from 'lodash'
// Project deps
import { mapById, removeById } from 'utils/list'
import { convertRawUser } from 'types/users'
import { convertRawProject } from 'types/projects'
import { PositionsTypes } from 'modules/positions/actions'
import { ProjectsTypes } from 'modules/projects/actions'
import { UsersTypes } from 'modules/users/actions'
// Local deps
import { INITIAL_STATE } from './initialState'
import { CompaniesTypes } from './actions'
import { convertRawSubscription } from 'types/subscriptions'

export const getCompaniesLoading = state =>
  state.merge({
    getCompaniesIsLoading: true,
    getCompaniesErrorMessage: '',
  })

export const getCompaniesSuccess = (state, { companies }) =>
  state.merge({
    companies,
    getCompaniesIsLoading: false,
    getCompaniesErrorMessage: null,
  })

export const getCompaniesFailure = (state, { errorMessage }) =>
  state.merge({
    companies: [],
    getCompaniesIsLoading: false,
    getCompaniesErrorMessage: errorMessage,
  })

export const getCompanyDataUsageLoading = (state, { companyId }) =>
  state.merge({
    getCompanyDataUsageIsLoading: {
      ...state.get('getCompanyDataUsageIsLoading'),
      [companyId]: true,
    },
  })

export const getCompanyDataUsageSuccess = (state, { companyId, dataUsage }) =>
  state.merge({
    dataUsageByCompany: {
      ...state.get('dataUsageByCompany'),
      [companyId]: dataUsage,
    },
    getCompanyDataUsageIsLoading: {
      ...state.get('getCompanyDataUsageIsLoading'),
      [companyId]: false,
    },
  })

export const getCompanyDataUsageFailure = (state, { companyId, errorMessage }) =>
  state.merge({
    getCompanyDataUsageIsLoading: {
      ...state.get('getCompanyDataUsageIsLoading'),
      [companyId]: false,
    },
  })

export const getUsersLoading = state =>
  state.merge({
    getUsersIsLoading: true,
    getUsersErrorMessage: '',
  })

export const getUsersSuccess = (state, { users }) =>
  state.merge({
    users: users.map(convertRawUser),
    getUsersIsLoading: false,
    getUsersErrorMessage: null,
  })

export const getUsersFailure = (state, { errorMessage }) =>
  state.merge({
    users: [],
    getUsersIsLoading: false,
    getUsersErrorMessage: errorMessage,
  })

export const getProjectsLoading = (state, { companyId }) =>
  state.merge({
    getProjectsIsLoading: {
      ...state.get('getProjectsIsLoading'),
      [companyId]: true,
    },
    getProjectsErrorMessage: '',
  })

export const getProjectsSuccess = (state, { companyId, projects, setAsCurrent }) => {
  const transformedProjects = projects.map(convertRawProject).sort((a, b) => b.created.getTime() - a.created.getTime())
  return state.merge({
    companyProjects: {
      ...state.get('companyProjects'),
      [companyId]: transformedProjects,
    },
    ...(setAsCurrent && { projects: transformedProjects }),
    getProjectsIsLoading: {
      ...state.get('getProjectsIsLoading'),
      [companyId]: false,
    },
    getProjectsErrorMessage: null,
  })
}

export const getProjectsFailure = (state, { companyId, errorMessage }) =>
  state.merge({
    projects: [],
    getProjectsIsLoading: {
      ...state.get('getProjectsIsLoading'),
      [companyId]: false,
    },
    getProjectsErrorMessage: errorMessage,
  })

export const transferProjectSuccess = (state, { companyId, project }) => {
  if (companyId && project) {
    const companyProjects = state.get('companyProjects')
    const projects = state.get('projects')
    const transformedProject = convertRawProject(project)
    return state.merge({
      companyProjects: {
        ...companyProjects,
        [companyId]: [transformedProject, ...(companyProjects[companyId] || [])],
      },
      projects: [transformedProject, ...(projects || [])],
    })
  }
  return state.merge({})
}

export const getCompanyLoading = state =>
  state.merge({
    getCompanyIsLoading: true,
    getCompanyErrorMessage: '',
  })

export const getCompanySuccess = (state, { company }) =>
  state.merge({
    currentCompany: company,
    getCompanyIsLoading: false,
    getCompanyErrorMessage: null,
  })

export const getCompanyFailure = (state, { errorMessage }) =>
  state.merge({
    currentCompany: {},
    getCompanyIsLoading: false,
    getCompanyErrorMessage: errorMessage,
  })

export const generateInviteUrlLoading = (state, { companyId }) =>
  state.merge({
    generateInviteUrlIsLoading: {
      [companyId]: true,
    },
    generateInviteUrlErrorMessage: '',
  })

export const generateInviteUrlSuccess = (state, { companyId }) =>
  state.merge({
    generateInviteUrlIsLoading: {
      [companyId]: false,
    },
    generateInviteUrlErrorMessage: null,
  })

export const generateInviteUrlFailure = (state, { errorMessage, companyId }) =>
  state.merge({
    generateInviteUrlIsLoading: {
      [companyId]: false,
    },
    generateInviteUrlErrorMessage: errorMessage,
  })

export const getCompanySystemTypesLoading = (state, { companyId }) => {
  return state.merge({
    systemTypesByCompanyLoading: {
      ...state.get('systemTypesByCompanyLoading'),
      [companyId]: true,
    },
  })
}

export const getCompanySystemTypesSuccess = (state, { companyId, systemTypes }) => {
  return state.merge({
    systemTypesByCompany: {
      ...state.get('systemTypesByCompany'),
      [companyId]: systemTypes,
    },
    systemTypesByCompanyLoading: {
      ...state.get('systemTypesByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanySystemTypesFailure = (state, { companyId }) => {
  return state.merge({
    systemTypesByCompanyLoading: {
      ...state.get('systemTypesByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanySubscriptionsLoading = (state, { companyId }) => {
  return state.merge({
    subscriptionsByCompanyLoading: {
      ...state.get('subscriptionsByCompanyLoading'),
      [companyId]: true,
    },
  })
}

export const getCompanySubscriptionsSuccess = (state, { companyId, subscriptions }) => {
  return state.merge({
    subscriptionsByCompany: {
      ...state.get('subscriptionsByCompany'),
      [companyId]: orderBy(subscriptions, ['next_payment_date'], ['asc']).map(convertRawSubscription),
    },
    subscriptionsByCompanyLoading: {
      ...state.get('subscriptionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanySubscriptionsFailure = (state, { companyId }) => {
  return state.merge({
    subscriptionsByCompanyLoading: {
      ...state.get('subscriptionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const updateUserSuccess = (state, { user }) => state.merge({
  users: mapById(user.id, state.get('users'), _user => user),
})

export const getCompanyPermissionsLoading = (state, { companyId }) => {
  return state.merge({
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: true,
    },
  })
}

export const getCompanyPermissionsSuccess = (state, { companyId, permissions }) => {
  return state.merge({
    permissionsByCompany: {
      ...state.get('permissionsByCompany'),
      [companyId]: permissions,
    },
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}

export const getCompanyPermissionsFailure = (state, { companyId }) => {
  return state.merge({
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}

/*
export const addCompanyPermissionsLoading = (state, { companyId }) => {
  return state.merge({
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: true,
    },
  })
}
export const addCompanyPermissionsSuccess = (state, { companyId, permissions }) => {
  return state.merge({
    permissionsByCompany: {
      ...state.get('permissionsByCompany'),
      [companyId]: permissions,
    },
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}
export const addCompanyPermissionsFailure = (state, { companyId }) => {
  return state.merge({
    permissionsByCompanyLoading: {
      ...state.get('permissionsByCompanyLoading'),
      [companyId]: false,
    },
  })
}
*/

export const deleteProjectsSuccess = (state, { projectIds, companyId }) => {
  const companyProjects = state.get('companyProjects')
  return state.merge({
    projects: state.get('projects').filter(project => !projectIds.includes(project.id)),
    companyProjects: {
      ...companyProjects,
      [companyId]: (companyProjects[companyId] || []).filter(project => !projectIds.includes(project.id)),
    },
  })
}

export const registerEmployeeLoading = state =>
  state.merge({
    registerIsLoading: true,
    registerErrorMessage: null,
  })

export const registerEmployeeSuccess = state => {
  return state.merge({
    registerIsLoading: false,
    registerErrorMessage: null,
  })
}

export const registerEmployeeFailure = (state, { errorMessage }) =>
  state.merge({
    registerIsLoading: false,
    registerErrorMessage: errorMessage,
  })

// Get list of all company positions
export const getPositionsLoading = (state, { companyId }) =>
  state.merge({
    positionsLoading: {
      ...state.get('positionsLoading'),
      [companyId]: true,
    },
  })

export const getPositionsSuccess = (state, { companyId, positions }) => {
  return state.merge({
    positions: {
      ...state.get('positions'),
      [companyId]: positions || [],
    },
    positionsLoading: {
      ...state.get('positionsLoading'),
      [companyId]: false,
    },
  })
}
export const getPositionsFailure = (state, { companyId, errorMessage }) =>
  state.merge({
    positionsLoading: {
      ...state.get('positionsLoading'),
      [companyId]: false,
    },
  })

// Add position
export const addReferenceStationPositionLoading = state => {
  return state.merge({
    addPositionIsLoading: true,
  })
}

export const addReferenceStationPositionSuccess = (state, { companyId, position }) => {
  const allPositions = state.get('positions')
  const companyPositions = allPositions[companyId] || []
  return state.merge({
    positions: {
      ...allPositions,
      [companyId]: [
        ...companyPositions,
        position,
      ],
    },
    addPositionIsLoading: false,
  })
}

export const addReferenceStationPositionFailure = (state, { errorMessage }) => {
  return state.merge({
    addPositionIsLoading: false,
  })
}

// Update position
export const updateReferenceStationPositionLoading = state => state.merge({})
export const updateReferenceStationPositionSuccess = (state, { companyId, id, position }) => {
  const allPositions = state.get('positions')
  const companyPositions = allPositions[companyId] || []
  return state.merge({
    positions: {
      ...allPositions,
      [companyId]: mapById(id, companyPositions, () => position),
    },
  })
}
export const updateReferenceStationPositionFailure = state => state.merge({})

// Delete position
export const deleteReferenceStationPositionLoading = (state, { id }) => {
  return state.merge({
    deletePosition: {
      ...state.get('deletePosition'),
      [id]: true,
    },
  })
}
export const deleteReferenceStationPositionSuccess = (state, { companyId, id }) => {
  const allPositions = state.get('positions')
  const companyPositions = allPositions[companyId] || []
  return state.merge({
    positions: {
      ...allPositions,
      [companyId]: removeById(id, companyPositions),
    },
    deletePosition: {
      ...state.get('deletePosition'),
      [id]: false,
    },
  })
}
export const deleteReferenceStationPositionFailure = (state, { id, errorMessage }) => {
  return state.merge({
    deletePosition: {
      ...state.get('deletePosition'),
      [id]: false,
    },
  })
}

export const updateSubscriptionSuccess = (state, { companyId, subscriptionId, subscription }) => {
  const companySubscriptions = state.get('subscriptionsByCompany')[companyId] || []
  return state.merge({
    subscriptionsByCompany: {
      ...state.get('subscriptionsByCompany'),
      [companyId]: mapById(subscriptionId, companySubscriptions, () => subscription),
    },
  })
}

// Check invite token
export const checkInviteTokenLoading = (state, { token }) => state.merge({
  checkTokenIsLoading: {
    ...state.get('checkTokenIsLoading'),
    [token]: true,
  },
  isTokenValid: {
    ...state.get('isTokenValid'),
    [token]: false,
  },
})
export const checkInviteTokenSuccess = (state, { token }) => {
  return state.merge({
    checkTokenIsLoading: {
      ...state.get('checkTokenIsLoading'),
      [token]: false,
    },
    isTokenValid: {
      ...state.get('isTokenValid'),
      [token]: true,
    },
  })
}
export const checkInviteTokenFailure = (state, { token }) => state.merge({
  checkTokenIsLoading: {
    ...state.get('checkTokenIsLoading'),
    [token]: false,
  },
})

export const reducer = createReducer(INITIAL_STATE, {
  [CompaniesTypes.GET_COMPANIES_LOADING]: getCompaniesLoading,
  [CompaniesTypes.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
  [CompaniesTypes.GET_COMPANIES_FAILURE]: getCompaniesFailure,

  [CompaniesTypes.GET_COMPANY_USERS_LOADING]: getUsersLoading,
  [CompaniesTypes.GET_COMPANY_USERS_SUCCESS]: getUsersSuccess,
  [CompaniesTypes.GET_COMPANY_USERS_FAILURE]: getUsersFailure,

  [CompaniesTypes.GET_COMPANY_PROJECTS_LOADING]: getProjectsLoading,
  [CompaniesTypes.GET_COMPANY_PROJECTS_SUCCESS]: getProjectsSuccess,
  [CompaniesTypes.GET_COMPANY_PROJECTS_FAILURE]: getProjectsFailure,

  [CompaniesTypes.GET_COMPANY_DATA_USAGE_LOADING]: getCompanyDataUsageLoading,
  [CompaniesTypes.GET_COMPANY_DATA_USAGE_SUCCESS]: getCompanyDataUsageSuccess,
  [CompaniesTypes.GET_COMPANY_DATA_USAGE_FAILURE]: getCompanyDataUsageFailure,

  [CompaniesTypes.GET_COMPANY_LOADING]: getCompanyLoading,
  [CompaniesTypes.GET_COMPANY_SUCCESS]: getCompanySuccess,
  [CompaniesTypes.GET_COMPANY_FAILURE]: getCompanyFailure,

  [CompaniesTypes.GENERATE_INVITE_URL_LOADING]: generateInviteUrlLoading,
  [CompaniesTypes.GENERATE_INVITE_URL_SUCCESS]: generateInviteUrlSuccess,
  [CompaniesTypes.GENERATE_INVITE_URL_FAILURE]: generateInviteUrlFailure,

  [CompaniesTypes.GET_COMPANY_SYSTEM_TYPES_LOADING]: getCompanySystemTypesLoading,
  [CompaniesTypes.GET_COMPANY_SYSTEM_TYPES_SUCCESS]: getCompanySystemTypesSuccess,
  [CompaniesTypes.GET_COMPANY_SYSTEM_TYPES_FAILURE]: getCompanySystemTypesFailure,

  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_LOADING]: getCompanySubscriptionsLoading,
  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCESS]: getCompanySubscriptionsSuccess,
  [CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS_FAILURE]: getCompanySubscriptionsFailure,

  [CompaniesTypes.GET_COMPANY_PERMISSIONS_LOADING]: getCompanyPermissionsLoading,
  [CompaniesTypes.GET_COMPANY_PERMISSIONS_SUCCESS]: getCompanyPermissionsSuccess,
  [CompaniesTypes.GET_COMPANY_PERMISSIONS_FAILURE]: getCompanyPermissionsFailure,

  [CompaniesTypes.REGISTER_EMPLOYEE_LOADING]: registerEmployeeLoading,
  [CompaniesTypes.REGISTER_EMPLOYEE_SUCCESS]: registerEmployeeSuccess,
  [CompaniesTypes.REGISTER_EMPLOYEE_FAILURE]: registerEmployeeFailure,

  [CompaniesTypes.GET_POSITIONS_LOADING]: getPositionsLoading,
  [CompaniesTypes.GET_POSITIONS_SUCCESS]: getPositionsSuccess,
  [CompaniesTypes.GET_POSITIONS_FAILURE]: getPositionsFailure,

  [CompaniesTypes.ADD_REFERENCE_STATION_POSITION_LOADING]: addReferenceStationPositionLoading,
  [CompaniesTypes.ADD_REFERENCE_STATION_POSITION_SUCCESS]: addReferenceStationPositionSuccess,
  [CompaniesTypes.ADD_REFERENCE_STATION_POSITION_FAILURE]: addReferenceStationPositionFailure,

  [CompaniesTypes.CHECK_INVITE_TOKEN_LOADING]: checkInviteTokenLoading,
  [CompaniesTypes.CHECK_INVITE_TOKEN_SUCCESS]: checkInviteTokenSuccess,
  [CompaniesTypes.CHECK_INVITE_TOKEN_FAILURE]: checkInviteTokenFailure,

  [PositionsTypes.UPDATE_REFERENCE_STATION_POSITION_LOADING]: updateReferenceStationPositionLoading,
  [PositionsTypes.UPDATE_REFERENCE_STATION_POSITION_SUCCESS]: updateReferenceStationPositionSuccess,
  [PositionsTypes.UPDATE_REFERENCE_STATION_POSITION_FAILURE]: updateReferenceStationPositionFailure,

  [PositionsTypes.DELETE_REFERENCE_STATION_POSITION_LOADING]: deleteReferenceStationPositionLoading,
  [PositionsTypes.DELETE_REFERENCE_STATION_POSITION_SUCCESS]: deleteReferenceStationPositionSuccess,
  [PositionsTypes.DELETE_REFERENCE_STATION_POSITION_FAILURE]: deleteReferenceStationPositionFailure,

  [ProjectsTypes.DELETE_PROJECTS_SUCCESS]: deleteProjectsSuccess,
  [ProjectsTypes.TRANSFER_PROJECT_SUCCESS]: transferProjectSuccess,
  [UsersTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [UsersTypes.UPDATE_SUBSCRIPTION_SUCCESS]: updateSubscriptionSuccess,
})
