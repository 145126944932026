
export const PermissionType = {
  READ: 'read',
}

export const PermissionDataType = {
  PROJECT: 'project',
  COMPANY: 'company',
}

export function convertRawCompanyPermission (raw) {
  const {
    id,
    user,
    company,
    updated,
    created,
    permission_type,
  } = raw
  return {
    id,
    userId: user.id,
    companyId: company.id,
    permissionType: permission_type,
    created: typeof created === 'string' ? new Date(created) : undefined,
    updated: typeof updated === 'string' ? new Date(updated) : undefined,
    type: PermissionDataType.COMPANY,
  }
}

export function convertRawProjectPermission (raw) {
  const {
    id,
    user,
    project,
    updated,
    created,
    permission_type,
  } = raw
  return {
    id,
    userId: user.id,
    projectId: project.id,
    permissionType: permission_type,
    created: typeof created === 'string' ? new Date(created) : undefined,
    updated: typeof updated === 'string' ? new Date(updated) : undefined,
    type: PermissionDataType.PROJECT,
  }
}
