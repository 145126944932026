// External dependencies.
// import bigInt from 'big-integer'
import { path } from 'ramda'
// Internal dependencies.
// import { GenericParserInfo, LogMessage } from './utils'
// import { GpsTime, GpsTimeInterval, GpsTimeIntervalState } from '../gps-time'
// import { GpsTimeIntervalState } from '../../../utils/gpsTime'
import { getFileFromDropbox } from './utils'
import { transformToEuler } from 'utils/math'
import { isFileNameEndsWith } from 'utils/baseName'

/*
 * The file format for the '.ldr' file can be described as follows:
 * (full struct definition in phusion/common/lidarlegacy.h)
 *  First | last | Type   | Description
 * -------+------+--------+--------------------------------------------------------------------------------------------
 *   0000 | 0007 | ASCII  | 43 4F 4E 46 44 41 54 41
 *        |      |        | Hexcode in ASCII range which can be read as 'CONFDATA'
 *        |      |        | Filetype identifier identifying this file as a ldr file.
 *   0008 | 0011 | uint32 | Version identifier containing the version of the protocol used in this file.
 *   0012 | 0015 | float  | The minimum range. Points closer than this will be ignored.
 *   0016 | 0019 | float  | The maximum range. Points further than this will be ignored.
 *   0020 | 0020 | uint8  | The model of the lidar scanner.
 *   0021 | 0022 |        | Reserved.
 *   0023 | 0023 | bool   | Whether to stop rotating after scanning.
 *   0024 | 0091 |        | The `LidarFov` struct. Ignored in this parser.
 *   0092 | 0155 | float  | The `transformInsToSensorColumnMajor` field.
 *   0156 | 0156 | uint8  | The index of the sensor. Only available if the protocol version is at least 2.
 *   ...  |  ... |        |
 *   0164 | 0171 | int64  | Starting timestamp, in GnssTime
 *   0172 | 0179 | int64  | Ending timestamp, in GnssTime
 *   0180 |  ... |        | The file continues with data which is irrelevant for the front end.
 */

const headerSize = 180

export const RieglHeaderFileSize = 2048

export const isRieglLdrFile = file => {
  if (isLdrFileType(file.fileType)) {
    const lidarModel = path(['parseResult', 'result', 'lidarModel'], file)
    return lidarModel ? lidarModel.includes('Riegl') : false
  }
  return false
}

/**
 * Checks whether the file name of a file looks like a ldr file name.
 * This is done by looking at the extension and checking whether it is '.ldr', '.rxp', or '.sdc', or '.sdcx'.
 */
// function isLdrFileName (fileName: string): boolean
export function isLdrFileName (fileName) {
  const ldrExtensions = ['.ldr']
  return ldrExtensions.some(ldrExtension => isFileNameEndsWith(fileName, ldrExtension)) ||
    isRxpFileName(fileName) ||
    isPcapFileName(fileName)
}

export function isPcapFileName (fileName) {
  const ldrExtensions = ['.pcap']
  return ldrExtensions.some(ldrExtension => isFileNameEndsWith(fileName, ldrExtension))
}

export const isRxpFile = fileName => {
  const ldrExtensions = ['.rxp']
  return ldrExtensions.some(ldrExtension => isFileNameEndsWith(fileName, ldrExtension))
}

export function isRxpFileName (fileName) {
  const ldrExtensions = ['.rxp', '.sdc', '.sdcx']
  return ldrExtensions.some(ldrExtension => isFileNameEndsWith(fileName, ldrExtension))
}

export function shouldLdrFileBeParsed (fileName) {
  const parseableExtensions = ['.ldr']
  return parseableExtensions.some(ldrExtension => isFileNameEndsWith(fileName, ldrExtension))
}

// export interface LdrFileHeader {
//     /**
//      * The version of rover with which this Ldr file was generated.
//      */
//     readonly version: number
//     /**
//      * The model of the lidar scanner.
//      */
//     readonly lidarModel: LidarModel
//     /**
//      * The index of the sensor. A unique number distinguishing the sensor from other sensors.
//      */
//     readonly sensorIndex: number
//     /**
//      * The transform matrix, and decoded offset and rotation vectors.
//      */
//     readonly transform: number[]
//     readonly offset: number[]
//     readonly rotation: number[]
//     /**
//      * The interval of the measurements in the file.
//      */
//     readonly interval: GpsTimeInterval
// }

/*
export interface LdrFileParserInfo extends GenericParserInfo {
    fileType: 'ldr'
    result?: LdrFileHeader
}
*/

/*
export type LidarModel =
  'Unknown' |
  'VelodyneHdl32E' |
  'VelodyneVlp16' |
  'RieglVux1UAV' |
  'RieglVux1LR' |
  'QuanergyM8' |
  'RieglMiniVuxUAV'
*/

/**
 * Used to convert Byte 14 of the `CameraInfo` fileformat into a human-readable trigger mode identifier.
 */
// const LidarModelMap: { [index: number]: LidarModel }
export const LidarModelMap = [
  'Unknown',
  'VelodyneHdl32E',
  'VelodyneVlp16',
  'RieglVux1UAV',
  'RieglVux1LR',
  'QuanergyM8',
  'RieglMiniVux1UAV',
  'RieglVux1HA',
  'VelodyneVlp32C',
  'LuminarH2',
  'OusterOs164',
]
export function isLdrFileType (fileType) {
  return fileType === 'ldr'
}

/**
 * Does the ugly work of pulling the int64 out of the .ldr file
 * (since Javascript doesn't actually have an int64 type).
 *
 * Conversion code is from phusion/common/gnsstime.cpp
 *
 * @param view The input data view.
 */
// function getGpsTime(view: DataView, offset: number): GpsTime {
/*
function getGpsTime (view, offset) {
  // Extract high and low words properly.
  // It's little-endian, remember.
  const low = view.getUint32(offset, true)
  const high = view.getUint32(offset + 4, true)
  const value = bigInt(high).shiftLeft(32).add(low)
  const week = value.divide(1000000).divide(604800)
  const tow = value.divide(1000000.0).mod(604800.0)
  const result = view.getInt32(offset, true) === -1 || view.getInt32(offset + 4, true) === -1
    ? { week: GpsTimeIntervalState.INVALID_TIME, tow: GpsTimeIntervalState.INVALID_TIME }
    : { week: week.toJSNumber(), tow: tow.toJSNumber() }
  return result
}
*/

/**
 * Parses a file and checks if it really is a ldr file by looking at the filename as well as the content of
 * the file. This also tries to parse the header of the file to provide some useful information.
 * @param file The file which should be checked and of which the header should be parsed.
 * @return An object containing information about whether the file is a valid ldr file, a log about the
 *   parsing and the information read from the header.
 */
// async function parseLdrFile (file: File): Promise<LdrFileParserInfo>
export async function parseLdrFile (file, blob) {
  return new Promise(resolve => {
    const log = []
    if (!isLdrFileName(file.name)) {
      log.push({
        level: 'warning',
        message: `Unsupported file extension in filename '${file.name}'`,
      })
    }
    if (file.size < headerSize) {
      const message =
        `Invalid file size. File is only ${file.size} bytes long. At least ${headerSize} bytes are needed.`
      log.push({ level: 'error', message })
      resolve({ okay: false, log, fileType: 'ldr' })
      return
    }
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const buffer = fileReader.result
      // tslint:disable-next-line
      const fileTypeIdentifier = String.fromCharCode.apply(null, new Uint8Array(buffer, 0, 8))
      if (fileTypeIdentifier !== 'CONFDATA') {
        log.push({
          level: 'error',
          message: `Filetype identifier (first 8 bytes) did not match 'CONFDATA'.`,
        })
        resolve({ okay: false, log, fileType: 'ldr' })
        return
      }
      const view = new DataView(buffer)
      const transform = Array(16).fill(undefined).map(
        // Use little endian here.
        (_, index) => view.getFloat32(92 + index * 4, true))
      const result = {
        version: view.getUint32(8, true),
        lidarModel: LidarModelMap[view.getUint8(20)],
        // index
        sensorIndex: view.getUint8(156),
        transform,
        offset: [transform[12], transform[13], transform[14]],
        rotation: transformToEuler(transform),
        /*
        interval: {
          beginning: getGpsTime(view, 164),
          end: getGpsTime(view, 172),
        },
        */
      }
      resolve({ result, okay: true, log, fileType: 'ldr' })
    }
    fileReader.onerror = () => {
      log.push({
        level: 'error',
        message: `Error reading file '${file.name}'.`,
      })
      resolve({ okay: false, log, fileType: 'ldr' })
    }
    fileReader.readAsArrayBuffer(
      (blob || file).slice(0, headerSize),
    )
  })
}

/*
export async function parseDropboxLdrFile (file) {
  const response = await axios.get(file.link, {
    responseType: 'arraybuffer',
    headers: {
      Range: `bytes=0-${headerSize-1}`
    }
  })
  const blob = new Blob([response.data], {type : 'text/plain'})
  return parseLdrFile(file, blob)
}
*/

export async function parseDropboxLdrFile (file) {
  return getFileFromDropbox(file, {
    responseType: 'arraybuffer',
    headers: {
      Range: `bytes=0-${headerSize - 1}`,
    },
  }, undefined, parseLdrFile)
}
