import { CRSFields } from 'templates/CRS/constants'
import { getCoordinateSystems, getUnits } from 'modules/app/selectors'
import { getBackendString } from 'utils/coordinateSystem'

export const getTransformedGenericCRS = (state, crsData) => {
  return {
    ...crsData,
    [CRSFields.H_CRS + '_transformed']: getCoordinateSystems(state).find(crs => getBackendString(crs) === crsData[CRSFields.H_CRS]),
    [CRSFields.V_CRS + '_transformed']: getCoordinateSystems(state).find(crs => getBackendString(crs) === crsData[CRSFields.V_CRS]),
    [CRSFields.V_UNITS + '_transformed']: getUnits(state).find(unit => getBackendString(unit) === crsData[CRSFields.V_UNITS]),
    [CRSFields.H_UNITS + '_transformed']: getUnits(state).find(unit => getBackendString(unit) === crsData[CRSFields.H_UNITS]),
  }
}
