import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({

  // Retrieve the proj, wkt, proj_json strings for crs
  getCRSInfo: ['options', 'artifactId', 'displayErrors'],
  getCRSInfoLoading: ['artifactId'],
  getCRSInfoSuccess: ['artifactId', 'crsInfo'],
  getCRSInfoFailure: ['errorMessage', 'artifactId'],

  // Checks the units of the crs
  checkUnits: ['options', 'artifactId', 'displayErrors'],
  checkUnitsLoading: ['artifactId'],
  checkUnitsSuccess: ['artifactId', 'crs_name', 'check'],
  checkUnitsFailure: ['errorMessage', 'artifactId'],

  // Retrieve the transformed coordinates
  getGCPS: ['options', 'artifactId', 'displayErrors'],
  getGCPSLoading: ['artifactId'],
  getGCPSSuccess: ['artifactId', 'gcps'],
  getGCPSFailure: ['errorMessage', 'artifactId'],

  // Retrieve transformed coordinates between two different crs
  transformFromCRSToCRS: ['options'],
  transformFromCRSToCRSLoading: null,
  transformFromCRSToCRSSuccess: ['transform'],
  transformFromCRSToCRSFailure: ['errorMessage'],

  getRecommendedCRS: ['projectId', 'crs', 'displayErrors', 'id'],
  getRecommendedCRSLoading: null,
  getRecommendedCRSSuccess: ['projectId', 'id', 'recommended'],
  getRecommendedCRSFailure: null,

  addDocumentationFiles: ['files', 'filesField', 'artifactsField'],
  addFiles: ['files', 'filesField', 'artifactsField', 'fileType'],
  addDropboxFiles: ['files', 'filesField', 'artifactsField', 'fileType'],
  addUploadFiles: ['files', 'artifactId', 'projectId', 'artifact'],
  addUploadDropboxFiles: ['files', 'artifactId', 'projectId', 'artifact'],
  filesAnalyzed: ['files', 'filesField', 'artifactsField'],
  addMission: null,
  removeCurrentMission: null,
  changeMissionType: ['id', 'missionType', 'appState'],
  changeMissionTypeSuccess: ['id', 'missionType'],
  changeCurrentMissionId: ['id'],
  changeCurrentMissionName: ['id', 'missionName'],
  removeFile: ['file', 'filesField', 'artifactsField'],

  editMissionFilesAnalyzed: ['files'],
  addEditMissionFiles: ['files', 'MissionTemplate'],
  addEditMissionDropboxFiles: ['files', 'MissionTemplate'],

  missionFilesAnalyzed: [
    'files',
    'artifactsField',
    'filesField',
    'allFilesField',
    'missionField',
    'missionIdField',
    'MissionTemplate',
  ],
  addMissionFiles: ['files', 'MissionTemplate'],
  addMissionDropboxFiles: ['files', 'MissionTemplate'],

  filesAnalyzeLoading: null,
  gotoStep: ['step'],
  addImages: ['files', 'protoArtifactId'],
  clearImages: ['protoArtifactId', 'protoArtifactsField'],
  removeImages: ['file', 'protoArtifactId'],
  addReferenceStationFiles: ['files', 'protoArtifactId'],
  addReferenceStationFilesSuccess: ['files', 'protoArtifactId'],
  addReferenceStation: null,
  setReferenceStationName: ['id', 'name'],
  removeReferenceStationFile: ['file', 'protoArtifactId'],
  removeReferenceStation: ['protoArtifactId'],
  setProtoArtifactProperties: ['protoArtifactId', 'properties', 'protoArtifactsField'],
  confirmEditMission: null,
  confirm: ['artifactsField', 'fieldsToReset', 'withoutMissions', 'protoArtifacts', 'project', 'onSuccess'],
  confirmEditGCP: ['artifact', 'protoArtifact'],
  closeEditGCPDialog: null,
  setConfirm: ['artifactsField', 'confirm'],
  reset: ['fieldsToReset'],
  startUpload: ['artifactId', 'autocomplete'],
  openEditDialog: ['missionId', 'mission', 'artifacts', 'files'],
  openEditDialogSuccess: ['missionId', 'mission', 'artifacts', 'files'],
  closeEditDialog: ['missionId'],
  removeErrors: ['missionId'],
  setAnalyze: ['analyzing'],
  setDropboxPath: ['path'],
  resetState: null,
  resetSettingsFiles: null,
  setSettingsFilesProcessingType: ['processingType'],
  setCameraFoldersMapping: ['mapping'],
}, { prefix: 'IMPORT_WIZARD/' })

export const ImportWizardTypes = Types
export default Creators
