import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI
import { IconButton } from '@material-ui/core'
// Icons
import HelpIcon from '@material-ui/icons/Help'
// Project deps
import Tooltip from 'components/StyledTooltip'

const DefaultStyle = {
  color: '#7e7e7e !important',
}

const WikiButton = props => {
  const { style = {}, title = <Trans i18nKey='wikiButton'/>, ...otherProps } = props
  return (
    <Tooltip title={title} placement='top'>
      <IconButton style={{ ...DefaultStyle, ...style }} {...otherProps} target='_blank'>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  )
}

WikiButton.propTypes = {
  style: PropTypes.object,
}

export default WikiButton
