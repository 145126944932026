import { call, put, takeLatest, fork } from 'redux-saga/effects'
import axios from 'utils/axios'
import { getErrorMessage } from 'utils/api'
// Local deps
import JobsActions, { JobsTypes } from './actions'

// Sagas
function * getJobsForUser ({ userId, filter }) {
  yield put(JobsActions.getJobsForUserLoading())
  try {
    const filterString = filter || 'status=running,waiting,scheduled,waiting_for_artifact'
    const { data: { data: jobs } } = yield call(axios.get, `/users/${userId}/jobs?${filterString}`)
    yield put(JobsActions.getJobsForUserSuccess(jobs))
  } catch (e) {
    yield put(JobsActions.getJobsForUserFailure(getErrorMessage(e)))
  }
}

function * getJobsForUserWatcher () {
  yield takeLatest(JobsTypes.GET_JOBS_FOR_USER, getJobsForUser)
}

export default function * root () {
  yield fork(getJobsForUserWatcher)
}
