import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@material-ui/core'
// Project deps
import { routeCompanyGeneral, routeUser } from 'utils/routing'
import LoadingButton from 'components/LoadingButton'

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 738,
  },
  content: {
    flexDirection: 'column',
  },
}))

const UpdateInfoDialog = props => {
  const { open = false, userValid, companyValid, companyId, userId, isLoading, onSubmit, userFields = [], companyFields = [] } = props
  const classes = useStyles()
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}>
      <DialogTitle>Missing information</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Typography>
          We have some missing information about{!userValid ? ' you' : ''}{!userValid && !companyValid ? ' and' : ''}{!companyValid ? ' your company' : ''}.
        </Typography>
        <ul>
          {userFields.length > 0 && <li><Typography><b>Missing user information</b>: {userFields.map(({ label }) => label).join(', ')}</Typography></li>}
          {companyFields.length > 0 && <li><Typography><b>Missing company information</b>: {companyFields.map(({ label }) => label).join(', ')}</Typography></li>}
        </ul>
        <Typography>
          Please update {!userValid && <a href={routeUser(userId)}>your</a>} {!userValid && !companyValid ? ' and' : ''} {!companyValid && <a href={routeCompanyGeneral(companyId)}>your company</a>} information to continue.
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton color='primary' onClick={onSubmit} isLoading={isLoading}>
          I updated the information
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

UpdateInfoDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  userValid: PropTypes.bool,
  companyValid: PropTypes.bool,
  userId: PropTypes.string,
  companyId: PropTypes.string,
  onSubmit: PropTypes.func,
  userFields: PropTypes.array,
  companyFields: PropTypes.array,
}

export default UpdateInfoDialog
