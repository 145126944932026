export default {
  MAX_RETRIES: 1000000,
  RETRY_TIMEOUT: 5000, // 5 seconds
  WAIT_TIME: 100,
  PREPARE_WAIT_TIME: 100,
  UPDATE_STATUS_WAIT_TIME: 120 * 1000, // 2 minutes
  MAX_UPLOAD_ITEMS: 2,
  MAX_UPLOAD_IMAGES_FILES: 10,
  MAX_PREPARE_IMAGES_FILES: 3,
  MAX_PREPARE_ITEMS: 1,
  UPDATE_DATADIRECTORY_EVERY: 5, // How much files for dataDirectory should be uploaded before updating the size
  MAX_CHUNKS_TO_SEND: 5, // How much chunks will be send in parallel for every file
  // When the difference between previous and current progress of the file
  // is more than 10 percent the action will be dispatched
  // It's very big performance boost when we prepare multiple files at the time
  MAX_PREPARE_PROGRESS_DIFFERENCE: 100 / 100, // make an update for prepare progress bar every 25%
  // const MAX_UPLOAD_PROGRESS_DIFFERENCE = 5 / 100 // make an update for upload progress bar every 5%
}

function convertMegaBytesToBytes (number) {
  return number * 1024 * 1024
}
// minimum chunk size (s3 limit is 5mb, but we will stick with our legacy min chunk size)
const minChunkSize = convertMegaBytesToBytes(10)
// maximum chunk size provided from s3
const maxChunkSize = convertMegaBytesToBytes(5 * 1024)
// maximum available chunks for s3 multipart upload per one upload
const maxChunks = 10000
const maxChunksSize = convertMegaBytesToBytes(maxChunks)
/**
 * s3 has limitations on amount of chunks and chunk size
 * This function is used to get the ideal chunk size (ideal in terms of to not to exceed the limits)
 * https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html
 * @param {Number} fileSize - size of the file in bytes
 * @returns {Number} ideal chunk size
 */
export function getIdealChunkSize (fileSize) {
  const chunkSize = convertMegaBytesToBytes(Math.ceil(fileSize / maxChunksSize))
  return Math.min(Math.max(minChunkSize, chunkSize), maxChunkSize)
}
