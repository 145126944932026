import { getDateWithTimezone } from 'utils/dateTime'
export const SystemType = {
  PHOENIX_V3: 'phoenix_v3',
  PHOENIX_V4_V5: 'phoenix_v4_v5',
  HYSPEX: 'hyspex',
  RECON: 'recon',
  RECON_UNPACKED: 'recon_unpacked',
  POINTCLOUD_PROCESSINGS: 'pointcloud_processing',
}

export function convertRawMission (raw) {
  const {
    id,
    mission_type,
    system_type,
    name,
    plp,
    project,
    updated,
    created,
    rover_serial,
  } = raw
  return {
    id,
    roverSerial: rover_serial,
    missionType: system_type ? system_type.name : mission_type,
    name,
    plp,
    projectId: project.id,
    created: getDateWithTimezone(created).toDate(),
    updated: getDateWithTimezone(updated).toDate(),
  }
}
