import axios from 'axios'
import config, { isProductionEnvironment, isLocalEnvironment } from 'config'
import moment from 'moment'
import { getCookie, setCookie, deleteCookie } from 'utils/cookies'
import { removeStorageItem, setStorageItem } from 'utils/localStorage'

export default axios.create({
  baseURL: config.API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosCbor = axios.create({
  baseURL: config.API_BASE,
  headers: {
    'Accept': 'application/cbor',
    'Content-Type': 'application/cbor',
  },
})

export const axiosLicensing = axios.create({
  baseURL: config.API_LICENSING_BASE_CLIENT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosAccounts = axios.create({
  baseURL: config.ACCOUNTS_API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosAccountsClient = axios.create({
  baseURL: config.ACCOUNTS_API_BASE_CLIENT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const axiosResources = axios.create({
  baseURL: config.RESOURCES_API_BASE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

const DOMAIN = isLocalEnvironment() ? 'localhost' : 'lidarmill.com'
const postfix = isProductionEnvironment() ? '' : '_develop'
const lmPostfix = isLocalEnvironment() ? 'local' : isProductionEnvironment() ? '_production' : '_develop'
const POTREE_TOKEN_COOKIE_NAME = `potree_user${postfix}`
export const LIDARMILL_REMEMBER_2FA_COOKIE_NAME = `remember_2fa_token${lmPostfix}`
const USER_TOKEN_COOKIE_NAME = `user_token${lmPostfix}`

export const setToken = (token, { setExpDate = true, remember_2fa_token = '', remember_2fa_token_exp }) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  // We set it to use later when redirecting to accounts if needed
  setStorageItem('userToken', token)
  if (setExpDate) {
    setCookie(
      POTREE_TOKEN_COOKIE_NAME,
      token,
      moment.utc().add(48, 'hours').toDate().toUTCString(),
      48 * 60 * 60,
      DOMAIN,
    )
    setCookie(
      USER_TOKEN_COOKIE_NAME,
      token,
      moment.utc().add(12, 'hours').toDate().toUTCString(),
      12 * 60 * 60,
      DOMAIN,
    )
  }
  if (remember_2fa_token && remember_2fa_token_exp) {
    setCookie(
      LIDARMILL_REMEMBER_2FA_COOKIE_NAME,
      remember_2fa_token,
      moment.utc().add(remember_2fa_token_exp, 'seconds').toDate().toUTCString(),
      remember_2fa_token_exp,
      DOMAIN,
    )
  }
}

export const removeToken = ({ auth = true, storage = true, cookie = true } = {}) => {
  if (auth) {
    axios.defaults.headers.common['Authorization'] = ''
  }
  if (storage) {
    removeStorageItem('userToken')
    removeStorageItem('tokenAccounts')
  }
  if (cookie) {
    deleteCookie(USER_TOKEN_COOKIE_NAME, DOMAIN)
    deleteCookie(POTREE_TOKEN_COOKIE_NAME, DOMAIN)
  }
}

export const getToken = () => {
  const userTokenCookie = getCookie(USER_TOKEN_COOKIE_NAME) || ''
  const token = userTokenCookie.split('=')[1] || ''
  return token
}

export const removeTokenOnceOptions = () => {
  return {
    transformRequest: (data, headers) => {
      delete headers.common.Authorization
      return data
    },
  }
}
