import React from 'react'
import { Trans } from 'react-i18next'
// Material Ui
import { makeStyles, createStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
// Project deps
import logo from 'assets/images/LiDARMill_byPhoenix.svg'

const useStyles = makeStyles(theme => createStyles({
  loaderContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    flexDirection: 'column',
  },
}))

function PageLoader (props) {
  const classes = useStyles()
  if (props.error) {
    console.error(props.error)
    return <div><Trans i18nKey='pageLoader.error'/> <button onClick={ props.retry }><Trans i18nKey='pageLoader.retry'/></button></div>
  } else if (props.pastDelay) {
    return <div className={classes.loaderContainer}>
      <img src={logo} style={{ marginBottom: 64 }} width={400}/>
      <CircularProgress size={64} disableShrink/>
    </div>
  } else {
    return null
  }
}

export default PageLoader
