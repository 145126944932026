import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // get constants for register form
  // get constants
  getAntennas: null,
  getAntennasLoading: null,
  getAntennasSuccess: ['antennas'],
  getAntennasFailure: null,

  getConstants: null,
  getConstantsLoading: null,
  getConstantsSuccess: [
    'countries',
    'industries',
    'timeZones',
    'filetypes',
    'epsg_datums',
    'ie_datums',
    'coordinateSystems',
    'units',
    'geoids',
    'datum_epochs',
    'classifications',
    'salesProducts',
  ],
  getConstantsFailure: ['errorMessage'],

  getProducts: null,
  getProductsLoading: null,
  getProductsSuccess: ['products'],
  getProductsFailure: ['errorMessage'],

  getAllSystemTypes: null,
  getAllSystemTypesLoading: null,
  getAllSystemTypesSuccess: ['systemTypes'],
  getAllSystemTypesFailure: null,

  getRecommendedDatums: ['lat', 'lng'],
  getRecommendedDatumsLoading: null,
  getRecommendedDatumsSuccess: ['lat', 'lng', 'datums'],
  getRecommendedDatumsFailure: null,

  getGCPTemplate: [],
  getGCPTemplateLoading: null,
  getGCPTemplateSuccess: ['template'],
  getGCPTemplateFailure: null,

  changeUserSettingsProjects: ['settings'],
  changeHeaderHeight: ['height'],

  openCheckTokenDialog: ['callback'],
  closeCheckTokenDialog: [],
})

export const AppTypes = Types
export default Creators
