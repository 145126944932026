import { getUTCDate } from 'utils/dateTime'

export function convertRawJobRunLog (raw) {
  const {
    id,
    job_id,
    pipeline_id,
    project_id,
    user_id,
    job_type,
    job_run_state,
    started_at,
    finished_at,
    duration,
    created,
    updated,
  } = raw
  return {
    id,
    jobId: job_id,
    pipelineId: pipeline_id,
    projectId: project_id,
    userId: user_id,
    jobType: job_type,
    jobRunState: job_run_state,
    startedAt: typeof started_at === 'string' ? getUTCDate(started_at) : undefined,
    finishedAt: typeof finished_at === 'string' ? getUTCDate(finished_at) : undefined,
    duration: typeof duration === 'number' ? duration : undefined,
    created: getUTCDate(created),
    updated: getUTCDate(updated),
  }
}
