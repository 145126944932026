import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getPipeline get single pipeline
  getPipeline: ['pipelineId', 'onSuccess', 'onError'],
  getPipelineLoading: null,
  getPipelineSuccess: ['pipelineId', 'pipeline'],
  getPipelineFailure: ['errorMessage'],

  putGetPipeline: ['pipelineId', 'pipeline'],

  // getQueriedPipelines
  getPipelinesQueried: ['per_page', 'page', 'query'],
  getPipelinesQueriedLoading: null,
  getPipelinesQueriedSuccess: ['pipelines', 'pagination'],
  getPipelinesQueriedFailure: ['errorMessage'],

  getJobRun: ['jobRunId', 'pipelineId', 'withLoading'],
  getJobRunLoading: ['jobRunId'],
  getJobRunSuccess: ['jobRunId', 'jobRun', 'pipelineId'],
  getJobRunFailure: ['jobRunId', 'errorMessage'],

  getPipelineIdByJobRun: ['jobRunId', 'onSuccess', 'onError'],
  getPipelineIdByJobRunLoading: null,
  getPipelineIdByJobRunSuccess: ['pipeline'],
  getPipelineIdByJobRunFailure: ['errorMessage'],

  cancelJobRun: ['jobRunId', 'pipelineId'],

  retryJob: ['jobId', 'pipelineId', 'options'],
  retryJobLoading: ['jobId'],
  retryJobSuccess: ['jobId', 'jobRun', 'pipelineId'],
  retryJobFailure: ['jobId', 'errorMessage'],

  // update pipeline
  updatePipeline: ['pipelineId', 'name'],
  updatePipelineLoading: ['pipelineId'],
  updatePipelineSuccess: ['pipelineId', 'name'],
  updatePipelineFailure: ['pipelineId', 'errorMessage'],

  // deletePipeline
  deletePipeline: ['pipelineId'],
  deletePipelineLoading: ['pipelineId'],
  deletePipelineSuccess: ['pipelineId'],
  deletePipelineFailure: ['pipelineId', 'errorMessage'],

  // updateCurrentPipelineData
  /*
  updateCurrentPipelineData:        ['pipelineId', 'withoutLoading'],
  updateCurrentPipelineDataSuccess: ['pipelineId', 'pipeline']
  */
})

export const PipelinesTypes = Types
export default Creators
