import { call, put, takeLatest, fork, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18n from 'i18n'
import axios from 'utils/axios'
// Local deps
import PositionsActions, { PositionsTypes } from './actions'
import { convertRawCompanyPosition } from 'types/companyPositions'

// Delete individual company reference station position
function * deleteReferenceStationPosition ({ companyId, id }) {
  yield put(PositionsActions.deleteReferenceStationPositionLoading(id))
  try {
    yield call(axios.delete, `/positions/${id}`)
    toast.success(i18n.t('toast.posititons.deleteReferenceStationPositionSuccess'))
    yield put(PositionsActions.deleteReferenceStationPositionSuccess(companyId, id))
  } catch (e) {
    yield put(PositionsActions.deleteReferenceStationPositionFailure(id))
  }
}
// Update individual company reference station position
function * updateReferenceStationPosition ({ companyId, id, data }) {
  yield put(PositionsActions.updateReferenceStationPositionLoading(id))
  try {
    const state = yield select(state => state)
    const { data: { data: position } } = yield call(axios.put, `/positions/${id}`, data)
    toast.success(i18n.t('toast.posititons.updateReferenceStationPositionSuccess'))
    yield put(PositionsActions.updateReferenceStationPositionSuccess(companyId, id, convertRawCompanyPosition(position, state)))
  } catch (e) {
    yield put(PositionsActions.updateReferenceStationPositionFailure(id))
  }
}

// Watchers
function * deleteReferenceStationPositionWatcher () {
  yield takeLatest(PositionsTypes.DELETE_REFERENCE_STATION_POSITION, deleteReferenceStationPosition)
}
function * updateReferenceStationPositionWatcher () {
  yield takeLatest(PositionsTypes.UPDATE_REFERENCE_STATION_POSITION, updateReferenceStationPosition)
}

export default function * root () {
  yield fork(deleteReferenceStationPositionWatcher)
  yield fork(updateReferenceStationPositionWatcher)
}
