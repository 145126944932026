import React from 'react'
import i18n from 'i18n'
import { Trans } from 'react-i18next'
// Local deps
import {
  getCloneArtifactOptionValue,
  safeToString,
  getImuOrientation,
} from '../utils'
import { DataType } from 'types/form'
import {
  getFocalLengths,
  focalLengthWarningMessage,
  isCalibrateCameraEnabled,
  getCameraTransform,
  getSensorIndex,
  clampBetween360Degrees,
} from '../jobIoOptionsUtils'
import { MapBackendNameToFrontend } from 'templates/constants'
import Tooltip from 'components/StyledTooltip'
import { isSE5AndLowerPLP, transformCameraCalibration } from 'utils/calibrationconverter'
import { getAllArtifacts, getMissions } from 'modules/projects/selectors'
import { isAdmin } from 'modules/users/selectors'
import { findById } from 'utils/list'
import { Wiki } from 'wiki'

export default {
  use_photos: {
    name: 'Use photos',
    dataType: DataType.BOOLEAN,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'use_photos',
      (state, extra, options = {}, jobOptions) => {
        const { values, extraProps = {} } = options
        const { clone } = extraProps
        if (!clone) {
          return true
        }
        return !(values && !('use_photos' in values))
      },
    ),
    createEmptyContainer: true,
    emptyContainerProps: {
      xs: 0,
      sm: 8,
      md: 8,
      lg: 8,
    },
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  create_report_only: {
    name: <Trans i18nKey='templates.artifactOptions.camera.create_report_only' />,
    dataType: DataType.BOOLEAN,
    adminOnly: true,
    optional: true,
    disabled: (state, values, extra, formTemplate) => formTemplate.calibrateCamera.getValue(state, values.calibrateCamera),
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'create_report_only',
      (state, extra, options = {}, jobOptions) => {
        const { values, extraProps = {} } = options
        const { clone } = extraProps
        if (!clone) {
          return false
        }
        return !(values && !('create_report_only' in values))
      },
    ),
    sendToBackend: (state) => isAdmin(state),
    createEmptyContainer: true,
    useRowContainerStyles: true,
    emptyContainerProps: {
      xs: 0,
      sm: 8,
      md: 8,
      lg: 8,
    },
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  /*
  disable_camera_data: {
    name: 'Disable',
    dataType: DataType.BOOLEAN,
    tooltip: 'Camera data won\'t be used during processing.',
    invisible: (state, _values, extra) => getSelectedReconArtifacts(state).length <= 0,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(state, artifactId, options, 'disable_camera_data', false),
    sendToBackend: (state, values) => getSelectedReconArtifacts(state).length > 0,
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  disable_camera_data_empty: {
    name: '',
    dataType: DataType.TEXT,
    optional: true,
    initialValue: '',
    invisible: (state, _values, extra) => getSelectedReconArtifacts(state).length <= 0,
    sendToBackend: false,
    gridProps: {
      xs: 0,
      sm: 8,
      md: 8,
      lg: 8,
    },
  },
  */
  overrideTransform: {
    name: MapBackendNameToFrontend.overrideTransform,
    dataType: DataType.BOOLEAN,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(state, artifactId, options, 'overrideTransform', false),
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  index: {
    name: MapBackendNameToFrontend.index,
    dataType: DataType.INTEGER,
    invisible: true,
    disabled: (_state, values) => !values.overrideTransform,
    optional: (_state, values) => !values.overrideTransform,
    initialValue: (state, artifactId, options) =>
      getCloneArtifactOptionValue(state, artifactId, options, 'index', () => safeToString(getSensorIndex(state, artifactId, options))),
  },
  transform: {
    dataType: DataType.AUTOCOMPLETE,
    invisible: true,
    optional: true,
    sendToBackend: false,
    initialValue: (state, artifactId, options) => {
      const { values } = options
      const orientation = getImuOrientation(state, artifactId, options)
      const cameraTransform = getCameraTransform(state, artifactId, values.index)
      const cameraArtifact = findById(artifactId, getAllArtifacts(state))
      const mission = findById(cameraArtifact.missionId, getMissions(state))
      const shouldPerformTransformation = mission && mission.plp && isSE5AndLowerPLP(mission.plp)
      // This happens when we update IMU orientation and if we have se6 plp
      if (!shouldPerformTransformation) {
        if (values.transform) {
          return {
            offset: values.offset,
            rotation: values.rotation,
          }
        }
        return cameraTransform
      } else {
        if (values.transform && values.overrideTransform) {
          return {
            offset: values.offset,
            rotation: values.rotation,
          }
        }
        return transformCameraCalibration(cameraTransform, orientation)
      }
    },
  },
  offset: {
    name: <Tooltip placement='top-start' title={<Trans i18nKey='templates.artifactOptions.generic.tooltip.offset'/>}>
      <div>
        <Trans i18nKey='templates.artifactOptions.generic.offsetLink' components={[
          <a key='link' target='_blank' rel='noopener noreferrer' href={Wiki.rotationOffsets}>link</a>,
        ]}/>
      </div>
    </Tooltip>,
    dataType: DataType.VECTOR3,
    componentNames: ['X', 'Y', 'Z'],
    precision: [3, 3, 3],
    disabled: (_state, values) => !values.overrideTransform,
    optional: (_state, values) => !values.overrideTransform,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'offset',
      () => {
        const { values } = options
        return values.transform.offset.map(safeToString)
      }
    ),
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  rotation: {
    name: <Tooltip placement='top-start' title={<Trans i18nKey='templates.artifactOptions.generic.tooltip.rotation'/>}>
      <div>
        <Trans i18nKey='templates.artifactOptions.generic.rotationLink' components={[
          <a key='link' target='_blank' rel='noopener noreferrer' href={Wiki.rotationOffsets}>link</a>,
        ]}/>
      </div>
    </Tooltip>,
    dataType: DataType.VECTOR3,
    componentNames: ['X', 'Y', 'Z'],
    rounding: true,
    precision: [3, 3, 3],
    displayFormat: true,
    format: value => safeToString(clampBetween360Degrees(value)),
    disabled: (_state, values) => !values.overrideTransform,
    optional: (_state, values) => !values.overrideTransform,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'rotation',
      () => {
        const { values } = options
        return values.transform.rotation.map(safeToString)
      }
    ),
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  calibrateCamera: {
    name: MapBackendNameToFrontend.calibrateCamera,
    dataType: DataType.BOOLEAN,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(state, artifactId, options, 'calibrateCamera', false),
    disabled: (state, values) => values.create_report_only || !isCalibrateCameraEnabled(state),
    getValue: (state, value) => isCalibrateCameraEnabled(state) ? value : false,
    backendTransform: (value, state) => isCalibrateCameraEnabled(state) ? value : false,
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  focal_length: {
    name: MapBackendNameToFrontend.focal_length,
    dataType: DataType.FLOAT,
    precision: 3,
    initialValue: (state, artifactId, options) =>
      getCloneArtifactOptionValue(state, artifactId, options, 'focal_length', () => getFocalLengths(state, options.values)[0].toString()),
    disabled: (state, values, extra, formTemplate) => !formTemplate.calibrateCamera.getValue(state, values.calibrateCamera),
    /*
    transformOnChangeOf: ['calibrateCamera'],
    transform: (original, state, formValues, artifactId) => !formValues.calibrateCamera ? '16' : original,
    */
    gridProps: {
      xs: 12,
      sm: 8,
      md: 8,
      lg: 8,
    },
    extraRender: (state, values) => focalLengthWarningMessage(state, values),
  },
  /*
  feature_detector: {
    name: i18n.t('templates.artifactOptions.camera.feature_detector'),
    dataType: DataType.SELECTION,
    options: ['ORB', 'SIFT'],
    disabled: (state, values, extra, formTemplate) => !formTemplate.calibrateCamera.getValue(state, values.calibrateCamera),
    optional: (state, _values, extra) => !isAdmin(state),
    invisible: (state, _values, extra) => !isAdmin(state),
    initialValue: (state, extra, options) => getCloneArtifactOptionValue(state, extra, options, 'feature_detector', 'ORB'),
    gridProps: {
      xs: 12,
      sm: 5,
      md: 5,
      lg: 5,
    },
  },
  */
  warning: {
    dataType: DataType.TEXT,
    invisible: (state, values) => !values.calibrateCamera,
    variant: 'warning',
    sendToBackend: false,
    initialValue: i18n.t('templates.artifactOptions.camera.warning.focalLength'),
  },
  camera_pose_correction: {
    name: MapBackendNameToFrontend.camera_pose_correction,
    dataType: DataType.BOOLEAN,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(state, artifactId, options, 'camera_pose_correction', true),
    backendTransform: (value, state, values) => value,
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
}
