import i18n from 'i18n'
import { path } from 'ramda'
import { antennaInitialValue } from './constants'
import { DataType } from 'types/form'
import { getAntennaOptions, getFavouriteOptions, addFavoriteOptionToCookie } from './utils'
import { getAntennaTemplate } from './jobIoOptionsUtils'
import { Cookies } from 'utils/constants'

export const RefStationTemplateFields = ['processing_antenna']

export const RefStationTemplate = {
  processing_antenna: {
    name: i18n.t('templates.artifactOptions.generic.antennaName'),
    dataType: DataType.ANTENNA_AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    displayTemplate: getAntennaTemplate,
    searchTemplate: getAntennaTemplate,
    getValue: (state, value, values, extra) => {
      addFavoriteOptionToCookie(value, Cookies.favoriteAntennas.amount, Cookies.favoriteAntennas.name); 
      return getAntennaTemplate(value)
    },
    compare: (suggestion, selectedItem) => suggestion.searchValue === selectedItem.searchValue,
    options: (state) => getFavouriteOptions(state, Cookies.favoriteAntennas.name),
    backendTransform: (original, state, formValues) => {
      return {
        name: original.value,
        radome: original.radome,
      }
    },
    initialValue: (state, extra, { extraProps = {} }) => {
      const { files } = extraProps
      const fileWithAntenna = files.find(file => path(['parseResult', 'result', 'antenna'], file))
      const antennaModel = fileWithAntenna && fileWithAntenna.parseResult.result.antenna
      return antennaModel
        ? getAntennaOptions(state).find(antenna => antenna.value === antennaModel) || antennaInitialValue
        : antennaInitialValue
    },
    viewLimit: 4,
  },
}
