
export const Wiki = {
  gcp: {
    gcp: 'https://docs.phoenixlidar.com/lidarmill-cloud/workflow/project/project-reference-setup#ground-control',
    import: 'https://docs.phoenixlidar.com/lidarmill-cloud/workflow/project/project-reference-setup#import',
  },
  project: {
    crs: 'https://docs.phoenixlidar.com/lidarmill-cloud/workflow/project/project-reference-setup#coordinate-reference-system-selecting-wizard',
  },
  crsWizard: {
    generic: 'https://docs.phoenixlidar.com/lidarmill-cloud/workflow/project/project-reference-setup#generic-crs',
    advanced: 'https://docs.phoenixlidar.com/lidarmill-cloud/workflow/project/project-reference-setup#advanced-usage',
  },
  spatialFuser: 'https://docs.phoenixlidar.com/lidarmill/processing-tools/spatial-fuser-pipeline',
  rotationOffsets: 'https://docs.phoenixlidar.com/rover/theory-and-workflow/orientation-and-offsets',
}
