import { Coordinates } from 'templates/constants'
import { CRSFields } from 'templates/CRS/constants'

export const omitFields = [
  'analyze',
  'datum',
  'altitudeTransformed',
  'coordinates_chooser',
  // 'custom_crs',
  'lastChangedTab',
  'latitudeTransformed',
  'longitudeTransformed',
  'tab',
  'tabsTemplate',
  'position',
  'transfer_project',
  'alternative_calibration_tools',
]

export const mapNameToTransformName = {
  'heightMarker': 'z',
  'latitude': 'y',
  'longitude': 'x',
  'antennaName': 'antenna_name',
  'heightAntenna': 'height_antenna',
  'heightAntennaReference': 'height_antenna_reference',
}
export const mapNameToValue = {
  [CRSFields.EPOCH]: (state, values, value, option) => {
    if (option && typeof option.format === 'function') return option.format(value)
    return value
  },
  [Coordinates.LONGITUDE]: (state, values, value, option) => value || '0',
  [Coordinates.LATITUDE]: (state, values, value, option) => value || '0',
  [Coordinates.ALTITUDE]: (state, values, value, option) => value || '0',
  // antennaName: (state, values, value, option) => value ? value.value : null,
}
