// External dependencies.
import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI dependencies.
import ListItem from '@material-ui/core/ListItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
// Icons
import CalendarIcon from '@material-ui/icons/EventOutlined'
import ProblemIcon from '@material-ui/icons/ReportProblemOutlined'
import RenewIcon from '@material-ui/icons/RestoreOutlined'
// Project deps
import { routeUserSubscription } from 'utils/routing'
import { getDateWithTimezone } from 'utils/dateTime'

const SubscriptionStatus = props => {
  const {
    userId,
    amountOfSubscriptions,
    isAdmin,
    hasOngoingSubscription,
    classes,
    isSubscriptionsLoading,
    expiredSubscriptions,
    expireSoonSubscriptions,
  } = props
  // canDownloadFiles, expirationDate
  // const cantDownloadOrExpired = !canDownloadFiles || new Date() > expirationDate
  const [closestExpireSubscription] = expireSoonSubscriptions
  const { IconComponent, color, primaryText, secondaryText } =
    // Has access cause the superuser
    isAdmin ? {
      IconComponent: RenewIcon,
      color: 'orange',
      primaryText: hasOngoingSubscription
        ? <Trans i18nKey='navigator.statusBox.subscribed.primary' />
        : <Trans i18nKey='navigator.statusBox.onlyGrants.primary' />,
      secondaryText: <Trans i18nKey='navigator.statusBox.admin' />,
    }
    // Has no access whatsoever.
      : !hasOngoingSubscription
        ? {
          IconComponent: ProblemIcon,
          color: 'red',
          primaryText: <Trans i18nKey='navigator.statusBox.unsubscribed.primary' />,
          secondaryText: null,
        } : (expiredSubscriptions.length + expireSoonSubscriptions.length) > 0
          ? {
            IconComponent: CalendarIcon,
            color: 'orange',
            primaryText: <Trans i18nKey='navigator.statusBox.subscribed.primary' />,
            secondaryText: <Trans
              i18nKey={
                expiredSubscriptions.length > 0 && expireSoonSubscriptions.length <= 0
                  ? 'navigator.statusBox.subscribed.secondaryExpired'
                  : expiredSubscriptions.length > 0 && expireSoonSubscriptions.length > 0
                    ? 'navigator.statusBox.subscribed.secondaryExpiredSoonExpire'
                    : 'navigator.statusBox.subscribed.secondarySoonExpire'
              }
              values={{
                amount: amountOfSubscriptions,
                expired: expiredSubscriptions.length,
                soonExpire: expireSoonSubscriptions.length,
                expireIn: closestExpireSubscription ? getDateWithTimezone().to(closestExpireSubscription.end_date) : '',
              }}
              components={[
                <a key='link' href={routeUserSubscription(userId)}>link</a>,
                <br key='break'/>,
                <br key='break1'/>,
              ]}
            />,
          }
          : {
            IconComponent: CalendarIcon,
            color: 'green',
            primaryText: <Trans i18nKey='navigator.statusBox.subscribed.primary' />,
            secondaryText: <Trans
              i18nKey='navigator.statusBox.subscribed.secondary'
              values={{ amount: amountOfSubscriptions }}
              components={[<a key='link' href={routeUserSubscription(userId)}>link</a>]}
            />,
          }
  return (
    <ListItem className={classes.item}>
      {isSubscriptionsLoading
        ? <React.Fragment>
          <ListItemIcon>
            <CircularProgress size={20}/>
          </ListItemIcon>
          <ListItemText primary={<Typography variant='body2' component='div'>
            <Trans i18nKey='navigator.statusBox.loading' /></Typography>}
          />
        </React.Fragment>
        : <React.Fragment>
          <ListItemIcon>
            <IconComponent style={{ color }} />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.container }}
            primary={
              <Typography variant='body2' component='div' style={{ color }}>{primaryText}</Typography>
            }
            secondary={
              <Typography variant='body2' component='div' style= {{ color }} className={classes.secondaryStatusText}>{secondaryText}</Typography>
            }
          />
        </React.Fragment>
      }
    </ListItem>
  )
}

SubscriptionStatus.propTypes = {
  userId: PropTypes.string,
  amountOfSubscriptions: PropTypes.number,
  isAdmin: PropTypes.bool,
  canDownloadFiles: PropTypes.bool,
  hasOngoingSubscription: PropTypes.bool,
  isSubscriptionsLoading: PropTypes.bool,
  expirationDate: PropTypes.any,
  classes: PropTypes.object,
  expiredSubscriptions: PropTypes.array,
  expireSoonSubscriptions: PropTypes.array,
}

/*

  const { IconComponent, color, primaryText, secondaryText } =
    // Has access cause the superuser
    (isAdmin) ? {
      IconComponent: RenewIcon,
      color: 'orange',
      primaryText: hasOngoingSubscription && canDownloadFiles
        ? <Trans i18nKey='navigator.statusBox.subscribed' />
        : <Trans i18nKey='navigator.statusBox.onlyGrants.primary' />,
      secondaryText: <Trans i18nKey='navigator.statusBox.admin' />,
    }
    // Has no access whatsoever.
      : (!hasOngoingSubscription && cantDownloadOrExpired) ? {
        IconComponent: ProblemIcon,
        color: 'red',
        primaryText: <Trans i18nKey='navigator.statusBox.unsubscribed.primary' />,
        secondaryText: <Trans i18nKey='navigator.statusBox.unsubscribed.secondary' />,
      }
      // Can still download files and use grants but only until the grant period runs out.
        : (!hasOngoingSubscription && canDownloadFiles) ? {
          IconComponent: RenewIcon,
          color: 'orange',
          primaryText: <Trans i18nKey='navigator.statusBox.onlyGrants.primary' />,
          secondaryText: <React.Fragment>
            <Trans
              i18nKey='navigator.statusBox.onlyGrants.secondary'
              values={{ date: moment(expirationDate).format(config.DATETIME_FORMAT) }}
            />
          </React.Fragment>,
        }
        // Has subscription but payment still not processed
          : (hasOngoingSubscription && cantDownloadOrExpired) ? {
            IconComponent: RenewIcon,
            color: 'orange',
            primaryText: <Trans i18nKey='navigator.statusBox.onlySubscription.primary' />,
            secondaryText: <Trans i18nKey='navigator.statusBox.onlySubscription.secondary' />,
          }
          // Has an active subscription (and can download files).
            : {
              IconComponent: CalendarIcon,
              color: 'green',
              primaryText: <Trans i18nKey='navigator.statusBox.subscribed' />,
              secondaryText: undefined,
            }

*/

export default SubscriptionStatus
