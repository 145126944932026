export function stableSort (array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getSorting (order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

export const sortByField = field => (a, b) => {
  if (a[field] > b[field]) {
    return 1
  } else if (a[field] < b[field]) {
    return -1
  }
  return 0
}

export function sortByCreated (items, order = 'desc') {
  const fOrder = order === 'desc' ? -1 : order === 'asc' ? 1 : 0
  return [...items].sort((a, b) => b.created.getTime() + fOrder * a.created.getTime())
}
