import { convertRawJob } from 'types/jobs'
import axios from 'utils/axios'

export async function createJob (headers, pipelineId, jobType) {
  try {
    const url = `/pipelines/${pipelineId}/jobs`
    const body = { job_type: jobType }
    const result = await axios.post(url, body, { headers })
    const job = convertRawJob(result.data.data)
    return job
  } catch (e) {
    throw e
  }
}
