import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  currentArtifact: {},
  dataDirectoryUploadedFiles: {},
  dataDirectoryUploadedFilesSize: {},
  dataDirectoryUpdateSizeTime: {},
  dataFiles: [],
  files: [],
  replaceFiles: [],
  failedFiles: [],
  failedDataDirectories: [],
  busy: false,
  transfer: [],
  prepare: [],
  preparedFilesByArtifact: {},
  uploadArtifactState: {},
  timerSet: {},
})
