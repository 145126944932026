// External dependencies.
import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { connect, useDispatch } from 'react-redux'
// Material UI dependencies.
import { makeStyles } from '@material-ui/core/styles'
import { Drawer, Hidden } from '@material-ui/core'
// Project internal dependencies.
import GrantsActions from 'modules/grants/actions'
import CompaniesActions from 'modules/companies/actions'
import { getLoggedUser, isAdmin, getUserPermissions } from 'modules/users/selectors'
import { getCompanyProjects, getCompanyProjectsIsLoading } from 'modules/companies/selectors'
import { getProjectsUserSettings } from 'modules/app/selectors'
import { stableSort, getSorting } from 'utils/sort'
import { getActiveUserProjects } from 'utils/company'
import { clearSelection } from 'utils/selection'
// Local deps
import DrawerContent from './Drawer'

const searchHeight = 42

const useStyles = makeStyles(theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  hiddenContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'hidden',
    backgroundColor: theme.palette.navigator.main,
  },
  item: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: 'rgba(255, 255, 255, 0.7)',
  },
  statusAndQuota: {
    borderTop: `1px solid ${theme.palette.navigator.light}`,
    paddingRight: theme.spacing(1),
  },
  itemSubscription: {
    // backgroundColor: theme.palette.primary.darker,
    // backgroundColor: '#202022'
    backgroundColor: theme.palette.navigator.dark,
  },
  firebase: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  projectsItem: {
    fontSize: theme.typography.fontSize,
    paddingRight: 0,
  },
  drawer: {
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      width: props => props.drawerWidth,
      flexShrink: 0,
    },
  },
  sideNavigator: {
    textDecoration: 'none',
    color: 'rgba(255, 255, 255, 0.7)',
    height: '100%',
    width: '100%',
  },
  block: {
    display: 'block',
  },
  project: {
    padding: theme.spacing(0.5, 1),
  },
  projectsContainer: {
    // flexBasis: '55%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    padding: 0,
  },
  logo: {
    padding: 10,
    width: '100%',
    height: '100%',
  },
  progress: {
    maxHeight: '30%',
    /* flexGrow: 1, */
    justifyContent: 'flex-end',
    display: 'flex',
    padding: 0,
    // marginTop: theme.spacing.unit * 2
  },
  termsAndConditions: {
    justifyContent: 'center',
    padding: 0,
  },
  notchedOutline: {
    border: 'none',
  },
  searchBox: {
    backgroundColor: '#fff',
    height: searchHeight,
    paddingRight: 0,
  },
  divider: {
    width: 1,
    height: searchHeight,
    background: '#a5a5a5',
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  dragger: {
    width: 5,
    right: -5,
    height: '100vh',
    cursor: 'ew-resize',
    position: 'absolute',
    // background: 'red',
    zIndex: 100000,
  },
  draggerMobile: {
    width: 5,
    left: props => props.drawerWidth,
    height: '100vh',
    cursor: 'ew-resize',
    position: 'absolute',
    // background: 'red',
    zIndex: 100000,
  },
}))

const Navigator = props => {
  const {
    activeCompanyId,
    isProjectsLoading,
    userId,
    canGetGrants,
    projects,
    headerHeight,
    onChangeSidebarWidth,
  } = props
  const classes = useStyles(props)
  const [isResizing, setIsResizing] = useState(false)
  const dispatch = useDispatch()
  const onGetCompanySubscriptions = useCallback(() => dispatch(CompaniesActions.getCompanySubscriptions(activeCompanyId)), [activeCompanyId, dispatch])
  const onGetCompanyProjects = useCallback(() => dispatch(CompaniesActions.getCompanyProjects(activeCompanyId)), [activeCompanyId, dispatch])
  // const onGetProjects = useCallback(() => dispatch(ProjectsActions.getProjects()), [])
  // const onGetProjectsByUser = useCallback(() => dispatch(ProjectsActions.getProjectsByUser(userId)), [userId])
  const getGrants = useCallback(() => dispatch(GrantsActions.getGrants(userId)), [userId, dispatch])

  useEffect(() => {
    if (activeCompanyId) {
      if (!isProjectsLoading) {
        onGetCompanyProjects()
      }
      onGetCompanySubscriptions()
    }
  }, [onGetCompanyProjects, onGetCompanySubscriptions, activeCompanyId])
  useEffect(() => {
    if (userId && canGetGrants) getGrants()
  }, [canGetGrants, userId, getGrants])

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMousemove)
      document.addEventListener('mouseup', handleMouseup)
    }
    return () => {
      document.removeEventListener('mousemove', handleMousemove)
      document.removeEventListener('mouseup', handleMouseup)
    }
  }, [isResizing])

  const handleMousedown = e => {
    setIsResizing(true)
    document.body.style.cursor = 'ew-resize'
  }

  const onChangeWidthDebounced = debounce(onChangeSidebarWidth, 10)

  const handleMousemove = e => {
    clearSelection()
    onChangeWidthDebounced(e.clientX)
  }

  const handleMouseup = e => {
    setIsResizing(false)
    document.body.style.cursor = 'default'
  }

  return (
    <nav className={classes.drawer}>
      <Hidden only={['xl', 'lg']} implementation='css'>
        <div
          id='dragger'
          onMouseDown={handleMousedown}
          className={classes.draggerMobile}
        />
      </Hidden>
      <Hidden only={['sm', 'xs', 'md']} implementation='css'>
        <div
          id="dragger"
          onMouseDown={handleMousedown}
          className={classes.dragger}
        />
      </Hidden>
      <Hidden only={['xl', 'lg']} implementation='css'>
        <Drawer
          variant='temporary'
          PaperProps={props.PaperProps}
          open={props.openSideMenu}
          onClose={props.onSideMenuChange}
        >
          <DrawerContent
            classes={classes}
            companyId={activeCompanyId}
            projects={projects}
            userId={userId}
            headerHeight={headerHeight}
            searchHeight={searchHeight}
          />
        </Drawer>
      </Hidden>
      <Hidden only={['sm', 'xs', 'md']} implementation='js'>
        <Drawer
          PaperProps={props.PaperProps}
          open
          variant='permanent'
        >
          <DrawerContent
            classes={classes}
            companyId={activeCompanyId}
            projects={projects}
            userId={userId}
            headerHeight={headerHeight}
            searchHeight={searchHeight}
          />
        </Drawer>
      </Hidden>
    </nav>
  )
}

Navigator.propTypes = {
  userId: PropTypes.string,
  activeUserId: PropTypes.string,
  activeCompanyId: PropTypes.string,
  projects: PropTypes.array,
  PaperProps: PropTypes.object,
  onSideMenuChange: PropTypes.func,
  onChangeSidebarWidth: PropTypes.func,
  canGetGrants: PropTypes.bool,
  openSideMenu: PropTypes.bool,
  isProjectsLoading: PropTypes.bool,
  headerHeight: PropTypes.number,
}

const mapStateToProps = (state, props) => {
  const { activeUserId, activeUser, projects: projectsFromProps, activeCompanyId, showAllProjects } = props
  const loggedUser = getLoggedUser(state)
  const userId = activeUserId || (loggedUser && loggedUser.id)
  const loggedUserPage = loggedUser && (
    activeUserId
      ? activeUserId === loggedUser.id
      : !activeUserId
  )
  const projectsUserSettings = getProjectsUserSettings(state)
  const userPermissions = getUserPermissions(state, activeUserId)
  const companyProjects = getCompanyProjects(state, activeCompanyId)
  const projects = projectsFromProps || getActiveUserProjects(loggedUser, activeUser, companyProjects, userPermissions, showAllProjects)
  return {
    userId,
    canGetGrants: loggedUserPage || isAdmin(state),
    projects: stableSort(projects, getSorting(projectsUserSettings.order, projectsUserSettings.sortBy)),
    isProjectsLoading: getCompanyProjectsIsLoading(state, activeCompanyId),
  }
}

export default connect(mapStateToProps)(Navigator)
