import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getCompanyCalibrations: ['companyId'],
  getCompanyCalibrationsLoading: ['companyId'],
  getCompanyCalibrationsSuccess: ['companyId', 'calibrations'],
  getCompanyCalibrationsFailure: ['companyId'],

  addCalibration: ['companyId', 'data', 'successCallback'],
  addCalibrationLoading: null,
  addCalibrationSuccess: ['companyId', 'calibration'],
  addCalibrationFailure: null,
})

export const CalibrationTypes = Types
export default Creators
