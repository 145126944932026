export const getImportWizard = (state, fields) =>
  fields.reduce((c, field) => {
    c[field] = state.importWizard.get(field)
    return c
  }, {})

export const getGCPsForArtifact = (state, artifactId) => {
  return state.importWizard.get('gcps')[artifactId] || []
}

export const getPipelineStringForArtifact = (state, artifactId) => {
  return state.importWizard.get('pipelineStrings')[artifactId]
}

export const getGCPsLoadingForArtifact = (state, artifactId) => {
  return state.importWizard.get('gcpsLoading')[artifactId]
}

export const getAllCheckUnits = state => state.importWizard.get('checkUnits')
export const getCheckUnits = (state, artifactId) => state.importWizard.get('checkUnits')[artifactId] || {}

export const getAllCRSInfo = state => state.importWizard.get('crsInfo')
export const getCRSInfo = (state, artifactId) => state.importWizard.get('crsInfo')[artifactId]
export const getRecommendedCRS = (state, projectId) => state.importWizard.get('recommendedCRS')[projectId]

export const isUploaderSubmitted = (state, artifactsField) => !!state.importWizard.get('confirmIsLoading')[artifactsField]
