// External dependencies.
import React, { useState } from 'react'
import { withRouter, NavLink, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import i18n from 'i18n'
import classNames from 'classnames'
// Material UI dependencies.
import { List, ListItemText, Typography, InputAdornment, IconButton, Tooltip } from '@material-ui/core'
// Icons
import SearchIcon from '@material-ui/icons/Search'
import AdvancedIcon from '@material-ui/icons/Tune'
// Project internal dependencies.
import withLoader from 'HOC/withLoader'
import LidarMillLogo from 'assets/images/LM-LOGO-TOPTAB.svg'
import { routeProject } from 'utils/routing'
import { getCompanyProjectsIsLoading } from 'modules/companies/selectors'
import ProgressWidget from 'components/ProgressWidget'
import VirtualizedList from 'components/VirtualizedList'
import ListItem from 'components/ListItem'
import DelayedSearchBox from 'components/DelayedSearchBox'
import RemoveIconButton from 'components/RemoveIconButton'
import Search from 'components/Search'
import { escape } from 'utils/regex'
// Local deps
const ContentWithLoader = withLoader(({ ...props }) => {
  return (
    <React.Fragment>{ props.drawerList }</React.Fragment>
  )
})

const Drawer = props => {
  const {
    classes,
    headerHeight,
    match,
    projects,
    companyId,
    userId,
    searchHeight,
  } = props

  const listProjectsIsLoading = useSelector(state => getCompanyProjectsIsLoading(state, companyId))
  const [advancedSearch, setAdvancedSearch] = useState(false)
  const [search, setSearch] = useState('')

  const onChange = value => {
    setSearch(value)
  }
  const onClear = () => {
    setSearch('')
  }

  const { params, path } = match
  const projectId = params.project_id
  const onProjectTabPage = params.tab && !!path.match('projects')
  const logoHeight = headerHeight - searchHeight
  const regexSearch = new RegExp(escape(search), 'i')
  const filteredProjects = projects
    .filter(project => {
      let keep = true
      if (search) {
        keep = Boolean((project.name).toString().match(regexSearch))
        if (!keep) return false
      }
      return keep
    })

  const additionalControls = (
    <React.Fragment>
      <div className={classes.divider}/>
      <Tooltip title={advancedSearch
        ? i18n.t('navigator.drawer.advancedSearch.tooltip')
        : i18n.t('navigator.drawer.search.tooltip')
      }>
        <IconButton
          onClick={() => setAdvancedSearch(prevState => !prevState)}
          color={advancedSearch ? 'primary' : 'default'}
          size='small'
          aria-label='Advanced search'
        >
          <AdvancedIcon/>
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )

  return (
    <List disablePadding className={classes.hiddenContent}>
      <ListItem disableGutters className={classNames(classes.firebase, classes.itemSubscription)}
        style={{ flexDirection: 'column', padding: 0, height: headerHeight }}>
        <NavLink className={classes.sideNavigator} to='/dashboard' style={{ height: logoHeight }}>
          <img src={LidarMillLogo} className={classes.logo} alt='LiDARMill'/>
        </NavLink>
        {!advancedSearch
          ? <DelayedSearchBox
            value={search}
            onChange={onChange}
            fullWidth
            variant='outlined'
            InputProps={{
              classes: { root: classes.searchBox, notchedOutline: classes.notchedOutline },
              startAdornment: <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>,
              endAdornment: <InputAdornment position='end'>
                <RemoveIconButton
                  size='small'
                  style={{ color: '#000' }}
                  aria-label='Clear'
                  onClick={onClear}
                />
                {additionalControls}
              </InputAdornment>,
            }}
            placeholder={i18n.t('navigator.drawer.search.placeholder')}
            className={classes.searchBox}
          />
          : <Search
            defaultType
            square
            fullWidth
            userId={userId}
            value={search}
            onChange={onChange}
            onClear={onClear}
            placeholder={i18n.t('navigator.drawer.advancedSearch.placeholder')}
            additionalControls={() => additionalControls}
          />
        }
      </ListItem>
      <ListItem
        disableGutters
        className={classNames(
          classes.block,
          classes.categoryHeader,
          classes.projectsContainer,
        )}
        style={{ flexGrow: 1 }}>
        <ContentWithLoader
          isLoading={listProjectsIsLoading}
          dark
          align={'center'}
          drawerList={(
            <div style={{
              width: '100%',
              paddingTop: 0,
              paddingBottom: 0,
              color: '#fff',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
              <div style={{
                display: 'flex',
                height: 550,
                flex: '1 1 auto',
              }}>
                <VirtualizedList
                  autoSizeHeight
                  rowHeight={30}
                  noDataLabel={i18n.t('projectsList.empty')}
                  renderItem={
                    (data, { key, style, isScrolling }) => {
                      const { name, id } = data
                      const url = routeProject(id)
                      return (
                        <ListItem
                          dense
                          selected={projectId && projectId === id}
                          component={Link}
                          to={onProjectTabPage ? `${url}/${params.tab}` : url}
                          key={key}
                          style={style}
                          className={classNames(
                            classes.item,
                            classes.itemActionable,
                            classes.block,
                            classes.project
                          )}
                        >
                          <ListItemText className={classes.projectsItem}>
                            {
                              name
                                ? <Typography variant='body2' noWrap style={{ color: 'white', paddingRight: '0' }}>{name}</Typography>
                                : <Typography variant='body2' noWrap style={{ color: 'white', paddingRight: '0' }}>
                                  <i><Trans i18nKey='pages.project.noName' /></i>
                                </Typography>
                            }
                          </ListItemText>
                        </ListItem>
                      )
                    }
                  }
                  list={filteredProjects}
                />
              </div>
            </div>
          )}
          ContainerProps={{
            style: {
              height: '100%',
            },
          }}
        />
      </ListItem>
      <ListItem
        className={classNames(
          classes.firebase,
          classes.itemSubscription,
          classes.progress
        )}>
        <ProgressWidget />
      </ListItem>
      {/*
        <ListItem disableGutters
          className={classNames(
            classes.firebase,
            classes.itemSubscription,
            classes.statusAndQuota
          )}>
          <SubscriptionStatusAndQuota userId={userId} companyId={companyId}/>
        </ListItem>
        <ListItem
          className={classNames(
            classes.firebase,
            classes.itemSubscription,
            classes.progress
          )}>
          <ProgressWidget />
        </ListItem>
        <ListItem
          className={classNames(
            classes.firebase,
            classes.itemSubscription,
            classes.termsAndConditions
          )}>
          <TermsAndConditions/>
        </ListItem>
      */}
    </List>
  )
}

Drawer.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
  userId: PropTypes.string,
  companyId: PropTypes.string,
  searchHeight: PropTypes.number,
  headerHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  projects: PropTypes.array,
}

export default withRouter(Drawer)
