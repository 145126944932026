import { IOType } from 'types/jobIos'
import { filterByArtifactId, findById } from './list'

/**
 * Finds all `JobIO`s which belong to a specified `Job`.
 * @param jobId The id of the job to find the job ios for.
 * @param jobIOs The list of all known job ios on which the search should be performed.
 */
export function getJobIOsForJob (jobId, jobIOs) {
  return jobIOs.filter(jobIO => jobIO.job.id === jobId)
}

/**
 * Finds all `JobIO`s which belong to a specified `Job` and return their ids.
 * @param jobId The id of the job to find the job ios for.
 * @param jobIOs The list of all known job ios on which the search should be performed.
 */
export function getJobIOIdsForJob (jobId, jobIOs) {
  return getJobIOsForJob(jobId, jobIOs).map(({ id }) => id)
}

/**
 * Returns the job io with the specified id from the specified list of job ios.
 * @param jobIOId The id of the job io to find.
 * @param jobIOs The list of job ios.
 * @return The job io that was queried or `undefined` if no such job io was found.
 */
export function getJobIOById (jobIOId, jobIOs) {
  return findById(jobIOId, jobIOs)
}

/**
 * Returns the job ios which belong to a specific artifact.
 * @param artifactId The artifact for which the job ios need to be queried.
 * @param jobIOs The list of job ios to search in.
 * @return The job ios which belong to the specified artifact.
 */
export function getJobIOsForArtifact (artifactId, jobIOs) {
  return filterByArtifactId(artifactId, jobIOs)
}

export function isOutputJobIO (jobIO) {
  return jobIO === IOType.OUTPUT
}

export function isInputJobIO (jobIO) {
  return jobIO === IOType.INPUT
}
