import { path, omit } from 'ramda'
import { CRSFields } from 'templates/CRS/constants'
import axios from 'utils/axios'
import { getCRSToBackend } from 'utils/coordinateSystem'

// Get all CRS data
export async function getCRS (crsId) {
  try {
    const { data: { data: crs } } = await axios.get(`/coordinate_reference_systems/${crsId}`)
    return { okay: true, crs }
  } catch (e) {
    return { okay: false }
  }
}

// Get all user's CRS
export async function getAllUserCRS (companyId) {
  try {
    const { data: { data: crsList } } = await axios.get(`/companies/${companyId}/coordinate_reference_systems`)
    return { okay: true, crsList }
  } catch (e) {
    return { okay: false }
  }
}

// Update CRS
export async function updateCRS (crsId, crs) {
  try {
    const { data: { data: newCrs } } = await axios.put(`/coordinate_reference_systems/${crsId}`, getCRSToBackend(crs))
    return { okay: true, crs: newCrs }
  } catch (e) {
    return { okay: false }
  }
}

// Delete CRS
export async function deleteCRS (crsId) {
  try {
    await axios.delete(`/coordinate_reference_systems/${crsId}`)
    return { okay: true }
  } catch (e) {
    return { okay: false }
  }
}

// Get CRS extended info
export async function getCRSExtendedInfo (options) {
  try {
    const { data: { data: crsInfo } } = await axios.post(
      `/coordinate_reference_systems/extended_info`,
      omit([CRSFields.EPOCH, 'points'], options),
    )
    return { okay: true, crs: crsInfo }
  } catch (e) {
    return { okay: false, message: path(['response', 'data', 'message'], e) || '' }
  }
}

export async function createCRS (projectId, crsBody = {}, useDefaultCRS, isPredefined) {
  try {
    const { lat, lng } = crsBody
    const { data: { data: crs } } = await axios.post(
      `/projects/${projectId}/coordinate_reference_systems${useDefaultCRS ? `?generic=true&lat=${lat}&lon=${lng}` : isPredefined ? '' : '?wizard=true'}`,
      getCRSToBackend(crsBody),
    )
    return { okay: true, crs }
  } catch (e) {
    return { okay: false, e }
  }
}
