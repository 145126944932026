import { isProductionEnvironment, isBayerEnvironment } from "config"

export const getCookie = cookieName => {
  const cookies = document.cookie.split(';')
  return cookies.find(cookie => cookie.includes(`${cookieName}`))
}

export const deleteCookie = (cookieName, domain) => {
  const cookie = cookieName +
    '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;max-age=-99999999' +
    (domain ? `;domain=${domain}` : '')
  document.cookie = cookie
}

export const setCookie = (cookieName, value, expiryDate, maxAge, domain) => {
  document.cookie = `${cookieName}=${value}; expires=${expiryDate}; path=/; max-age=${maxAge}${domain ? `; domain=${domain}` : ''}`
}

export const cookieName = isProductionEnvironment() || isBayerEnvironment() ? 'accounts_user' : 'accounts_develop_user'
