// Local.
import { withMaxPrecision } from 'utils/numeric'

/**
 * Whether this is a longitude or a latitude input field.
 */
export const Axis = {
  Latitude: 0,
  Longitude: 1,
}

export function componentIndex (componentName) {
  if (componentName === 'degrees') {
    return 0
  }
  if (componentName === 'minutes') {
    return 1
  }
  if (componentName === 'seconds') {
    return 2
  }
  return undefined
}

/**
 * Convert a `CoordinateValue` into a fractional high-precision degrees value.
 */
export function convertCoordinateToDegrees (value) {
  if (value.repr === 'DEGREES') {
    return value.degrees
  }
  if (value.repr === 'DEGREES_MINUTES') {
    return value.degrees + value.minutes / 60.0
  }
  if (value.repr === 'DEGREES_MINUTES_SECONDS') {
    return value.degrees + value.minutes / 60.0 + value.seconds / 3600.0
  }
  // This case should not be reachable.
  return undefined
}

/**
 * Convert a high-precision degrees value into a `CoordinateValue` with type `repr`.
 */
export function convertCoordinateFromDegrees (repr, value) {
  if (repr === 'DEGREES') {
    return {
      repr,
      degrees: value,
    }
  }
  if (repr === 'DEGREES_MINUTES') {
    const degreesFrac = value % 1
    const degrees = value - degreesFrac
    return {
      repr,
      degrees,
      minutes: degreesFrac * 60.0,
    }
  }
  if (repr === 'DEGREES_MINUTES_SECONDS') {
    const degreesFrac = value % 1
    const degrees = value - degreesFrac
    const minutesValue = degreesFrac * 60.0
    const minutesFrac = minutesValue % 1
    const minutes = minutesValue - minutesFrac
    return {
      repr,
      degrees,
      minutes,
      seconds: minutesFrac * 60.0,
    }
  }
  // This case should not be reachable.
  return undefined
}

/**
 * Converts from and to `CoordinateValue`s.
 */
export function convertCoordinate (repr, value) {
  return convertCoordinateFromDegrees(repr, convertCoordinateToDegrees(value))
}

/**
 * Given a coordinate representation and a component name, returns `true` if the component in question
 * should be displayed as part of the coordinate input form.
 */
export function shouldComponentBeRendered (componentName, repr) {
  return componentName === 'degrees' ||
    (componentName === 'minutes' && repr !== 'DEGREES') ||
    (componentName === 'seconds' && repr === 'DEGREES_MINUTES_SECONDS')
}

/**
 * Given a coordinate representation and a component name, returns the precision of the component
 * in the given representation. `0` describes an integer field.
 */
export function componentPrecision (componentName, repr) {
  // The `degrees` has 9 digits pricision. When there are multiple fields, it is an integer.
  if (componentName === 'degrees') {
    return repr === 'DEGREES' ? 9 : 0
  }
  if (componentName === 'minutes') {
    return repr === 'DEGREES'
      ? undefined
      : repr === 'DEGREES_MINUTES' ? 8 : 0
  }
  // The `seconds` field is only present when the field type also contains seconds.
  if (componentName === 'seconds') {
    return repr === 'DEGREES_MINUTES_SECONDS' ? 6 : undefined
  }
  return undefined
}

export function isNumber (stringValue) {
  const positiveFloatRegex = /^\d+(\.\d+)?$/g
  const matched = stringValue.match(positiveFloatRegex)
  return Boolean(matched)
}

/**
 * Given a coordinate representation, a component name, and the component value, tries to parse
 * the value and, if its precision is greater than required, trim the unwanted digits.
 * For integer numbers it drops the entire fractional part.
 */
export function trimComponent (componentName, repr, stringValue) {
  const precision = componentPrecision(componentName, repr)
  // A precision of `0` means that this field is an integer.
  if (precision === 0) {
    // Drop the fractional part.
    const parsed = parseInt(stringValue)
    return isNaN(parsed) ? stringValue : parsed.toString()
  }
  return withMaxPrecision(stringValue, precision)
}
