import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // createTicket
  createTicket: ['data', 'attachments'],
  createTicketLoading: null,
  createTicketSuccess: null,
  createTicketFailure: ['errorMessage'],
  // create Stripe subscription form dialog window
  closeSupportFormDialog: null,
  openSupportFormDialog: null,
})

export const SupportTypes = Types
export default Creators
