// turf
// Project deps
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import { MapBackendNameToFrontend } from '../constants'
import { getCloneJobOptions } from 'modules/pipelineWizard/selectors'

import { DataSetTypeMapping, getDatasetType } from './utils'

const isMinDurationInvisible = state => {
  const datasetType = getDatasetType(state)
  if (datasetType === DataSetTypeMapping.Mobile) {
    return true
  }
  return false
}

export default {
  min_duration: {
    name: MapBackendNameToFrontend.min_duration,
    dataType: DataType.INTEGER,
    disabled: (state, values) => values.manualy_compute_flightlines,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'min_duration', '15'),
    sendToBackend: (state, values) => !isMinDurationInvisible(state) && !values.manualy_compute_flightlines,
    invisible: isMinDurationInvisible,
  },
  manualy_compute_flightlines: {
    name: 'Manually split flightlines',
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'manualy_compute_flightlines',
      (state, extra, options, jobOptions) => {
        if (jobOptions && 'intervals' in jobOptions && 'settings' in jobOptions) {
          return true
        }
        return false
      },
    ),
    sendToBackend: (state, values) => values.manualy_compute_flightlines,
    gridProps: {
      xs: 6,
    },
  },
  settings: {
    name: '',
    dataType: DataType.CRS,
    optional: true,
    invisible: true,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'settings', null),
    sendToBackend: (state, values) => values.manualy_compute_flightlines,
    backendTransform: (value, state, rawValues) => 'settings' in rawValues.intervals
      ? rawValues.intervals.settings
      : value,
  },
  intervals: {
    name: '',
    dataType: DataType.TRAJECTORY_AUTOSPLIT,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'intervals', null),
    getClonedData: (state, extra, options) => {
      const { extraProps, formTemplate = {} } = options
      const { clone } = extraProps
      if (!clone) {
        return null
      }
      const jobOptions = getCloneJobOptions(state, extra.templateJobId)
      return jobOptions && 'settings' in jobOptions && 'intervals' in jobOptions && {
        settings: jobOptions.settings,
        intervals: jobOptions.intervals,
        hidden_flightlines: jobOptions.hidden_flightlines,
      }
    },
    optional: (state, values) => !values.manualy_compute_flightlines,
    disabled: (state, values) => !values.manualy_compute_flightlines,
    sendToBackend: (state, values) => values.manualy_compute_flightlines,
    backendTransform: (value, state) => {
      if ('hiddenFlightlines' in value && 'poses' in value && 'settings' in value) {
        const hiddenFlightlines = value.hiddenFlightlines
        return value.poses.reduce((all, poseContainer) => {
          const flightlines = poseContainer.flightlines
          return {
            ...all,
            [poseContainer.artifactId]: flightlines
              .map((fl, index) => {
                const isNotFlightline = (fl.id in hiddenFlightlines)
                if (isNotFlightline) {
                  if (flightlines[index - 1]) {
                    fl.beginning = flightlines[index - 1].end
                  }
                  if (flightlines[index + 1]) {
                    fl.end = flightlines[index + 1].beginning
                  }
                }
                return fl
              })
              .map(fl => ({
                ...fl.toJson(),
                is_flight_line: !(fl.id in hiddenFlightlines),
              })),
          }
        }, {})
      }
      return value
    },
    gridProps: {
      xs: 6,
    },
  },
  hidden_flightlines: {
    name: '',
    dataType: DataType.CRS,
    optional: true,
    invisible: true,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'hidden_flightlines', null),
    sendToBackend: false,
    backendTransform: (value, state, rawValues) => {
      if ('hiddenFlightlines' in rawValues.intervals) {
        const intervals = rawValues.intervals
        const hiddenFlightlines = rawValues.intervals.hiddenFlightlines
        return intervals.poses.reduce((all, poseContainer) => ({
          ...all,
          [poseContainer.artifactId]: poseContainer.flightlines
            .filter(fl => hiddenFlightlines[fl.id])
            .map(fl => fl.toJson()),
        }), {})
      }
      return value
    },
  },
  warning_text: {
    name: 'warning',
    variant: 'error',
    dataType: DataType.TEXT,
    optional: true,
    initialValue: 'To continue click \'Compute intervals\' button and create intervals using settings',
    invisible: (state, values, extra, formTemplate, option, { extraProps = {} }) => {
      const show = values.manualy_compute_flightlines && !values.intervals
      return !show
    },
    sendToBackend: false,
  },
}
