import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
// Project deps
import LidarmillUpdateForm from 'components/LidarmillUpdateForm'

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 738,
  },
  content: {
    flexDirection: 'column',
  },
}))

const AcceptUpdateDialog = props => {
  const { open = false, onClose, onSubmit } = props
  const classes = useStyles()
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}>
      <DialogContent classes={{ root: classes.content }}>
        <LidarmillUpdateForm/>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans i18nKey='lidarmillUpdate.cancel'/>
        </Button>
        <Button onClick={onSubmit} color='secondary'>
          <Trans i18nKey='lidarmillUpdate.confirm'/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AcceptUpdateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default AcceptUpdateDialog
