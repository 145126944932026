// Material UI
// Icons
// Project deps
import i18next from 'i18n'
import ReferenceStationTab from 'components/Tabs/ReferenceStationTab'
import { Route } from './route'

export const ProjectTabs = {
  OVERVIEW: 'overview',
  PIPELINES: 'pipelines',
  UPLOAD: 'upload',
  DELIVERABLES: 'deliverables',
  DOCUMENTATION: 'documentation',
  REFS: 'refs',
  GROUNDS: 'grounds',
  POLYGON: 'polygon',
  MISSIONS: 'missions',
  CRS: 'crs',
}

export const UserTabs = {
  GENERAL: 'general',
  PROJECTS: 'projects',
  SUBSCRIPTION: 'subscription',
  GRANTS: 'grants',
  SYSTEMS: 'systems',
}

export const CompanyTabs = {
  GENERAL: 'general',
  PROJECTS: 'projects',
  SUBSCRIPTION: 'subscription',
  USERS: 'users',
  SYSTEMS: 'systems',
  CALIBRATIONS: 'calibrations',
  USAGE: 'usage',
}

export const StatisticsTabs = {
  WORKERS: 'workers',
  PROJECTS: 'projects',
  PIPELINES: 'pipelines',
  USAGE: 'usage',
}

export const StatisticsUsageTabs = {
  USERS: 'users',
  PIPELINES: 'pipelines',
  METRICS: 'metrics',
  PROJECTS_STATISTIC: 'projects_statistic',
}

export const PaymentsTabs = {
  SUBSCRIPTION: 'subscription',
  TRANSACTIONS: 'transactions',
}

export const tabMap = {
  [Route.DASHBOARD]: [
    /*
    {
      label: i18next.t('tabs.dashboard.map'),
      tabName: 'map',
      path: `/dashboard/map`,
    },
    */
    {
      label: i18next.t('tabs.dashboard.projects'),
      tabName: 'projects',
      path: `/dashboard/projects`,
    },
    {
      label: i18next.t('tabs.dashboard.pipelines'),
      tabName: 'pipelines',
      path: `/dashboard/pipelines`,
    },
  ],
  [Route.STATISTICS]: [
    {
      label: i18next.t('tabs.statistics.workers'),
      tabName: StatisticsTabs.WORKERS,
      path: `/statistics/${StatisticsTabs.WORKERS}`,
    },
    {
      label: i18next.t('tabs.statistics.projects'),
      tabName: StatisticsTabs.PROJECTS,
      path: `/statistics/${StatisticsTabs.PROJECTS}`,
    },
    {
      label: i18next.t('tabs.statistics.pipelines'),
      tabName: StatisticsTabs.PIPELINES,
      path: `/statistics/${StatisticsTabs.PIPELINES}`,
    },
    {
      label: i18next.t('tabs.statistics.usage'),
      tabName: StatisticsTabs.USAGE,
      path: `/statistics/${StatisticsTabs.USAGE}/users`,
    },
  ],
  [Route.STATISTICS_USAGE]: [
    {
      label: i18next.t('tabs.statistics_usage.users'),
      tabName: StatisticsUsageTabs.USERS,
      path: `/statistics/${StatisticsTabs.USAGE}/${StatisticsUsageTabs.USERS}`,
    },
    {
      label: i18next.t('tabs.statistics_usage.pipelines'),
      tabName: StatisticsUsageTabs.PIPELINES,
      path: `/statistics/${StatisticsTabs.USAGE}/${StatisticsUsageTabs.PIPELINES}`,
    },
    {
      label: i18next.t('tabs.statistics_usage.metrics'),
      tabName: StatisticsUsageTabs.METRICS,
      path: `/statistics/${StatisticsTabs.USAGE}/${StatisticsUsageTabs.METRICS}`,
    },
    {
      label: i18next.t('tabs.statistics_usage.projects_statistic'),
      tabName: StatisticsUsageTabs.PROJECTS_STATISTIC,
      path: `/statistics/${StatisticsTabs.USAGE}/${StatisticsUsageTabs.PROJECTS_STATISTIC}`,
    },
  ],
  [Route.PROJECTS]: [
    {
      label: i18next.t('tabs.projects.missions'),
      tabName: ProjectTabs.MISSIONS,
      path: id => `/projects/${id}/${ProjectTabs.MISSIONS}`,
    },
    {
      label: ReferenceStationTab,
      tabName: ProjectTabs.REFS,
      path: id => `/projects/${id}/${ProjectTabs.REFS}`,
    },
    {
      label: i18next.t('tabs.projects.gcps'),
      tabName: ProjectTabs.GROUNDS,
      path: id => `/projects/${id}/${ProjectTabs.GROUNDS}`,
    },
    {
      label: i18next.t('tabs.projects.pipelines'),
      tabName: ProjectTabs.PIPELINES,
      path: id => `/projects/${id}/${ProjectTabs.PIPELINES}`,
    },
    {
      label: i18next.t('tabs.projects.overview'),
      tabName: ProjectTabs.OVERVIEW,
      path: id => `/projects/${id}/${ProjectTabs.OVERVIEW}`,
    },
    /*
    {
      label: i18next.t('tabs.projects.upload'),
      tabName: ProjectTabs.UPLOAD,
      path: id => `/projects/${id}/upload`,
    },
    */
    {
      label: i18next.t('tabs.projects.deliverables'),
      tabName: ProjectTabs.DELIVERABLES,
      path: id => `/projects/${id}/${ProjectTabs.DELIVERABLES}`,
    },
    {
      label: i18next.t('tabs.projects.documentation'),
      tabName: ProjectTabs.DOCUMENTATION,
      path: id => `/projects/${id}/${ProjectTabs.DOCUMENTATION}`,
    },
    {
      label: i18next.t('tabs.projects.crs'),
      tabName: ProjectTabs.CRS,
      path: id => `/projects/${id}/${ProjectTabs.CRS}`,
    },
  ],
  [Route.USERS]: [
    {
      label: i18next.t('tabs.users.projects'),
      tabName: UserTabs.PROJECTS,
      path: id => `/users/${id}/${UserTabs.PROJECTS}`,
    },
  ],
  [Route.PIPELINES]: [
    {
      label: i18next.t('tabs.pipelines.general'),
      tabName: 'general',
      path: id => `/pipelines/${id}/general`,
    },
    {
      label: i18next.t('tabs.pipelines.jobRuns'),
      tabName: 'job_runs',
      path: id => `/pipelines/${id}/job_runs`,
    },
  ],
  [Route.ARTIFACTS]: [
    {
      label: i18next.t('tabs.artifacts.general'),
      tabName: 'general',
      path: id => `/artifacts/${id}/general`,
    },
    {
      label: i18next.t('tabs.artifacts.pipelines'),
      tabName: 'pipelines',
      path: id => `/artifacts/${id}/pipelines`,
    },
  ],
  [Route.COMPANY]: [
    {
      label: i18next.t('tabs.users.users'),
      tabName: CompanyTabs.USERS,
      path: id => `/company/${id}/${CompanyTabs.USERS}`,
      display: ({ isUserCustomer }) => !isUserCustomer,
    },
    {
      label: i18next.t('tabs.users.projects'),
      tabName: CompanyTabs.PROJECTS,
      path: id => `/company/${id}/${CompanyTabs.PROJECTS}`,
    },
    {
      label: i18next.t('tabs.users.usage'),
      tabName: CompanyTabs.USAGE,
      path: id => `/company/${id}/${CompanyTabs.USAGE}`,
    },
  ],
}

export const getTabPath = (path, param) => {
  return typeof path === 'function' ? path(param) : path
}

export function getTabName (match, basicUrl) {
  const tabs = tabMap[basicUrl]
  const index = tabs && tabs.findIndex(tab => tab.tabName === match.params.tab)
  const tabIndex = (!index || index < 0) ? 0 : index
  return tabs[tabIndex].tabName || ''
}

export function getTabValue (match, basicUrl, tabName, extraProps) {
  const tabs = tabMap[basicUrl]
  const index = tabs && tabs.filter(tab => {
    const { display = true } = tab
    return typeof display === 'function' ? display(extraProps) : display
  }).findIndex(tab => tab.tabName === tabName)
  const tabIndex = (!index || index < 0) ? 0 : index
  return tabIndex
}
