import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
// Project deps
import { isLoggedIn } from 'modules/users/selectors'

const RegisterRoute = ({ isUserLoggedIn, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )}
    />
  )
}

const mapStateToProps = state => ({
  isUserLoggedIn: isLoggedIn(state),
})

RegisterRoute.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
}

export default connect(mapStateToProps)(RegisterRoute)
