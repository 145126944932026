import { isFileNameEndsWith } from 'utils/baseName'

export const KMLExtensions = [
  '.kml',
  '.kmz',
]
/**
 * Checks whether the file name of a file looks like a kml/kmz file name.
 * This is done by looking at the extension and checking whether it is '.kml' | '.kmz'.
 */
export function isKMLFileName (fileName) {
  return KMLExtensions.some(kmlExtension => isFileNameEndsWith(fileName, kmlExtension))
}

export function isKMLFileType (fileType) {
  return fileType === 'kml'
}
