export function convertRawDataFile (raw) {
  const { sha1_checksum, id, s3_key: s3Key, s3_link: s3Link, file_size: fileSize, file_name: fileName, completed, created, updated, chunks, artifact } = raw
  const dataFile = {
    id,
    s3Key,
    s3Link,
    fileSize,
    fileName,
    completed: Boolean(completed),
    created: new Date(created),
    updated: new Date(updated),
    artifactId: artifact.id,
    checksum: sha1_checksum,
  }
  if (typeof chunks !== 'undefined') {
    return {
      ...dataFile,
      chunks: chunks.map(chunk => ({
        id: chunk.chunk_id,
        size: chunk.chunk_size,
        completed: chunk.completed,
        checksum: chunk.checksum,
      })),
    }
  }
  return dataFile
}
