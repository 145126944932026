import React from 'react'
import { omit, path, lensPath, set } from 'ramda'
import { Trans } from 'react-i18next'
// Project deps
import {
  getCoordinateSystems,
  getVerticalCoordinateSystems,
  getHorizontalGeographic3DCoordinateSystems,
  getGeoids,
  getUnits,
  getEpochs,
} from 'modules/app/selectors'
import { isGcpRowParsed } from 'modules/upload/file-types'
import Warning from 'components/Warning'
import { validateJobOptionsForm } from 'components/TemplatedForm/utils'
import { getBaseName, isFileNameEndsWith } from 'utils/baseName'
import { getBackendTransformedValues } from 'utils/templates'
import { keepProps } from 'utils/dict'
import { toDecimalYear, fromDecimalYear } from 'utils/dateTime'
import { SECrsFieldNamesMap } from 'types/coordinateReferenceSystem'
import { getCoordinateSystemLabel, isCompoundGeocentricCRS, isCompoundCRS, isGeographic2DCRS, isGeographic3DCRS, getCRSDeprecatedWarning, getBackendString, isCRSFieldCompound } from 'utils/coordinateSystem'
import { isCustomAnalyzeSelected, getCRSOverlapArea, isUserCustomAnalyzeTypeSelected, isCustomAnalyzeTypeSelected, isAnalyzedTypeSelected } from 'templates/utils'

// Local deps
import {
  CRSFields,
  CRSTag,
  CRSFieldsList,
  tabsMap,
  UnitName,
  OldCRSFieldsToNewMapping,
  CRSFieldsOld,
  CRSFieldsListNew,
} from 'templates/CRS/constants'
import { TransformationTemplateId } from 'types/transformation'
import {
  Coordinates,
  CoordinateTypes,
  REF_STATION_BACKEND_NAME,
  SPATIAL_FUSER_BACKEND_NAME,
  GCP_BACKEND_NAME,
  MapBackendNameToFrontend,
} from 'templates/constants'
import { addUniqueKeyToCRS, getProjectCRSInitialValues } from './ProjectCRS/utils'
import { getGeoid, getGeoidFileName } from 'utils/geoid'
import { getCRSExtendedInfo } from 'modules/crs/api'

const isLengthUnitType = unitType => {
  return unitType === 'length'
}

export const getCustomCRSValue = values => {
  const value = values[CRSFields.CUSTOM_CRS]
  return typeof value === 'undefined' || !value
    ? ''
    : value
}

/**
 * Returns the object that will be saved to the artifact/job-runs/etc. options
 * @param {Object} crs
 */
export const getCRSOptions = (crs = {}) => {
  return getBackendString(crs)
  // return crs && crs.auth_name && crs.code && crs.proj_string ? crs : null
}

/**
 * Returns the options for custom str that will be send to the backend to retrieve transformed coordinates and other data
 * @param {Object} options
 */
export const getCustomCRSTransformationOptions = values => {
  return getTransformationOptions(values)
}

/**
 * Returns the options for CRS that will be send to the backend to retrieve transformed coordinates and other data
 * @param {Object} options
 */
export const getTransformationOptions = values => {
  const {
    [CRSFields.V_CRS]: crs_v,
    [CRSFields.H_CRS]: crs_h,
    [CRSFields.C_CRS]: crs_c,
    [CRSFields.H_UNITS]: h_units,
    [CRSFields.V_UNITS]: v_units,
    [CRSFields.EPOCH]: epoch,
    [CRSFields.GRID]: geoid,
    [CRSFields.NORMALIZE_AXIS_ORDER]: normalize_axis_order,
    [CRSFields.GEOID_CRS]: geoid_crs,
    // [CRSFields.IS_ELLIPSOIDAL]: is_ellipsoidal,
    points,
    // datum,
  } = values
  return {
    [CRSFields.C_CRS]: typeof crs_c === 'string' ? crs_c : getCRSOptions(crs_c),
    [CRSFields.V_CRS]: typeof crs_v === 'string' ? crs_v : getCRSOptions(crs_v),
    [CRSFields.H_CRS]: typeof crs_h === 'string' ? crs_h : getCRSOptions(crs_h),
    [CRSFields.V_UNITS]: typeof v_units === 'string' ? v_units : ((v_units && !isLengthUnitType(v_units.type)) || !v_units) ? null : getBackendString(v_units),
    [CRSFields.H_UNITS]: typeof h_units === 'string' ? h_units : ((h_units && !isLengthUnitType(h_units.type)) || !h_units) ? null : getBackendString(h_units),
    [CRSFields.EPOCH]: typeof epoch === 'string' ? epoch : toDecimalYear(epoch),
    [CRSFields.GRID]: typeof geoid === 'string' ? geoid : getBackendGeoidString(geoid),
    [CRSFields.GEOID_CRS]: geoid ? geoid_crs : null,
    // [CRSFields.IS_ELLIPSOIDAL]: is_ellipsoidal,
    [CRSFields.NORMALIZE_AXIS_ORDER]: normalize_axis_order,
    // ...(typeof datum !== 'undefined' && { datum })
    points,
  }
}

// Shorthand for setTimeout function
export function callWithDelay (cb, delay = 0.00001) { setTimeout(() => cb(), delay) }

export const getTabTemplate = (tabName, templates) => {
  const template = templates && templates[tabName]
  return template || null
}

export const getTabName = tab => {
  return tabsMap[tab] || 0
}

export const getCRSFields = options => {
  const { values } = options
  const {
    [CRSFields.LAST_CHANGED_TAB]: currentTab,
    [CRSFields.TABS_TEMPLATE]: tabsTemplate,
  } = values
  const tabTemplate = getTabTemplate(getTabName(currentTab), tabsTemplate)
  const { fields = {} } = tabTemplate
  return Object.keys(fields).reduce((allFields, fieldName) => {
    const fieldOption = fields[fieldName]
    const value = values[fieldName]
    return {
      ...allFields,
      ...(fieldOption.tag === CRSTag.CRS
        ? { [fieldName]: value }
        : {}
      ),
    }
  }, {})
}

export const onGetPosition = (options, name) => {
  const { actions = {} } = options
  const { onGetPosition: onGetPositionProp } = actions
  if (typeof onGetPositionProp === 'function') {
    onGetPositionProp(options, name)
  }
}

export const renderCRSWarning = (state, values, extra, propOptions) => {
  const { name } = propOptions
  const crs = values[name]
  return getCRSDeprecatedWarning(state, crs)
}

export const renderVerticalCRSWarning = (state, values, extra, propOptions) => {
  const horizontalCRS = values[CRSFields.H_CRS]
  const verticalCRS = values[CRSFields.V_CRS]
  const warnings = []
  if (isGeographic2DCRS(horizontalCRS) && isGeographic3DCRS(verticalCRS)) {
    warnings.push(
      <Warning variant='warning'>
        <Trans i18nKey='CRS.warnings.verticalCrs'/>
      </Warning>
    )
  }
  warnings.push(renderCRSWarning(state, values, extra, propOptions))
  return warnings
}

export const getPropsForCRSOptions = (state, values, extra, propOptions) => {
  return {
    secondaryText: option => getCRSDeprecatedWarning(state, option),
  }
}

const getTab = (state, values, tabName = CRSFields.TAB) => {
  const valuesTab = values[tabName]
  return isCustomAnalyzeSelected(values)
    ? typeof valuesTab === 'number'
      ? valuesTab
      : -1
    : -1
}

export const isCRSFieldOptional = (state, values = {}, extra, formTemplate = {}, tab, useLastChangedTab = true) => {
  const currentTab = useLastChangedTab ? getTab(state, values, CRSFields.LAST_CHANGED_TAB) : getTab(state, values)
  const tabTemplate = formTemplate[CRSFields.TAB] || {}
  const { optional } = tabTemplate
  return !isCustomAnalyzeSelected(values)
    ? true
    : (typeof optional === 'function' && optional(state, values, extra, tabTemplate, formTemplate)) || (
      currentTab !== tab && currentTab !== -1
    )
}

export const isCRSFieldOptionalForLastChangedTab = (state, values = {}, extra, formTemplate = {}, tab) => {
  const currentTab = getTab(state, values)
  const tabTemplate = formTemplate[CRSFields.TAB] || {}
  const { optional } = tabTemplate
  return !isCustomAnalyzeSelected(values)
    ? true
    : (typeof optional === 'function' && optional(state, values, extra, tabTemplate, formTemplate)) || (
      currentTab !== tab && currentTab !== -1
    )
}

export const isVerticalUnitsOptional = (state, values, extra) => {
  const currentTab = getTab(state, values)
  return !isCustomAnalyzeSelected(values)
    ? true
    : currentTab === 1
      ? !values[CRSFields.GRID]
      : true
}

export const disableGeoid = (state, values, extra) => {
  const currentTab = getTab(state, values)
  const verticalCrs = values[CRSFields.V_CRS]
  return !isCustomAnalyzeSelected(values)
    ? false
    : currentTab === 1
      ? isGeographic3DCRS(verticalCrs)
      : currentTab === 0
        ? disableCompoundCRSGrid(state, values)
        : false
}

export const getCRSValue = (state, value, crss) => {
  const coordinateSystems = crss || getCoordinateSystems(state)
  return coordinateSystems.find(crs => getCoordinateSystemLabel(crs) === (
    typeof value === 'string' ? value : getCoordinateSystemLabel(findCRS(coordinateSystems, value))
  )) || null
}

export function findCRS (coordinateSystems, crsToFind) {
  return crsToFind ? coordinateSystems.find(crs => {
    return crs.auth_name === 'custom'
      // If there is a custom CRS we finding it by the proj_string property
      ? crs.proj_string === crsToFind.proj_string
      : crs.code === crsToFind.code
  }) : null
}

export function gridBackendTransform (original, state, formValues) {
  return getBackendGeoidString(original)
  /*
  return original
    ? keepProps(['file', 'name', 'boundary', 'description', 'crs'], original)
    : original || null
    */
}

export const disableCompoundCRSGrid = (state, values) => {
  const crsValue = values[CRSFields.C_CRS]
  return crsValue ? !isCompoundCRS(crsValue) : true
}

export const getCompoundCRSGrid = (original, state, values) => {
  const crsValue = values[CRSFields.C_CRS]
  return crsValue ? isCompoundCRS(crsValue) ? gridBackendTransform(original, state, values) : null : null
}

export function disableHUnits (crsField, state, values, formTemplate) {
  const crs = formTemplate[crsField]
  const crsValue = crs ? values[crsField] : null
  const unitType = path([CRSFields.H_UNITS, 'type'], crsValue)
  return typeof unitType !== 'undefined' && !isLengthUnitType(unitType)
}

export const isCustomCrsTab = (tab, templates) => {
  return templates && Boolean(Object.keys(templates).find(
    (tabTemplate, tabIndex) => tab === tabIndex && templates[tabTemplate].isCustomCrsTab
  ))
}
/*
export const getNewCRSValues = (state, name, value, option, values) => {
  const { tag } = option
  if (tag === CRSTag.CUSTOM_STR) {
    const selectedCustomStr = values[CRSFields.CUSTOM_CRS_CHOOSER]
    values[CRSFields.CUSTOM_CRS][customCRSRev[selectedCustomStr]] = value
    values[name] = value
  } else if (tag === CRSTag.EPOCH) {
    values[name] = value ? moment(value).toDate() : null
  } else {
    values[name] = value
  }
  return values
}
*/

// If we selected compound CRS that is 'geocentric' we should set the v-units be the same as the h-units
export const getCompoundCRSVUnit = (state, values) => {
  const isGeocentric = isCompoundGeocentricCRS(state, values)
  return isGeocentric ? values[CRSFields.H_UNITS] : values[CRSFields.V_UNITS]
}

// If we selected compound CRS that is 'geocentric' we should set the name of h-units to 'Unit'
export const getCompoundCRSHUnitName = (state, values) => {
  const isGeocentric = isCompoundGeocentricCRS(state, values)
  return isGeocentric ? MapBackendNameToFrontend.unit : UnitName.H_UNITS
}

export const getVerticalCRSOptions = (state, values) => {
  const allVerticalSystems = getVerticalCoordinateSystems(state)
  const horizontalCrs = values[CRSFields.H_CRS]
  const horizontalCrsValue = horizontalCrs || {}
  const { area_bounds: hCrsAreaBounds = [] } = horizontalCrsValue
  // Find vertical systems that have overlapping with the currently selected horizontal system
  const areaValidVerticalSystems = allVerticalSystems.filter(vCrs => {
    const { area_bounds: vCrsAreaBounds = [] } = vCrs
    const overlapArea = getCRSOverlapArea(
      ...hCrsAreaBounds,
      ...vCrsAreaBounds
    )
    return overlapArea > 0
  })
  const listOfOptions = (horizontalCrsValue ? areaValidVerticalSystems : allVerticalSystems)
    .map(crs => getCoordinateSystemLabel(crs))
  return listOfOptions
}

export const getCRSValues = (values, crss, state, extra) => {
  const options = { state, extra, values }
  const {
    [CRSFields.V_CRS]: vertical_crs,
    [CRSFields.H_CRS]: horizontal_crs,
    [CRSFields.C_CRS]: compound_crs,
    [CRSFields.V_UNITS]: v_units,
    [CRSFields.H_UNITS]: h_units,
    [CRSFields.TAB]: currentTab,
    ...other
  } = values
  const tab = typeof currentTab === 'number' ? currentTab : getTabValue(options)
  return {
    ...other,
    [CRSFields.V_CRS]: getCRSFormValue(crss, vertical_crs) || null,
    [CRSFields.H_CRS]: getCRSFormValue(crss, horizontal_crs) || null,
    [CRSFields.C_CRS]: getCRSFormValue(crss, compound_crs) || null,
    [CRSFields.V_UNITS]: v_units || null,
    [CRSFields.H_UNITS]: h_units || null,
    [CRSFields.TAB]: tab,
  }
}

export const getCRSFormValue = (coordinateSystems, crs) => {
  return findCRS(coordinateSystems, crs)
  /*
  return typeof crs === 'string'
    ? crs
    : getCoordinateSystemLabel(findCRS(coordinateSystems, crs))
  */
}

/**
 * Function returns options for transforming coordinates
 * @param {Object} values
 * @param {Array} points
 * @param {Object} state
 * @param {Object} extra
 */
export const getTransformOptions = (values, points, state, extra, projectCRS) => {
  // const options = { state, extra, values }
  // const crs = getCRSFields(options)
  const tab = values[CRSFields.TAB]
  const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
  if (!tabTemplate) return {}
  const epoch = getEpoch(state, extra, values, projectCRS)
  const isCompoundTabSelected = tab === 0
  const isCRSTabSelected = tab === 1
  const isCRSOrCompoundCRSTabSelected = isCompoundTabSelected || isCRSTabSelected
  const compoundCRS = values[CRSFields.C_CRS]
  /*
    [CRSFields.V_CRS]: isCRSOrCompoundCRSTabSelected
      ? isCRSTabSelected ? values[CRSFields.ENABLE_V_CRS] ? null : values[CRSFields.V_CRS] : null
      : null,
  */
  return getTransformationOptions({
    [CRSFields.C_CRS]: isCompoundTabSelected ? compoundCRS : null,
    [CRSFields.H_CRS]: isCRSOrCompoundCRSTabSelected
      ? isCRSTabSelected ? values[CRSFields.H_CRS] : null
      : null,
    [CRSFields.V_CRS]: isCRSOrCompoundCRSTabSelected
      ? isCRSTabSelected ? values[CRSFields.V_CRS] : null
      : null,
    [CRSFields.V_UNITS]: isCRSOrCompoundCRSTabSelected
      ? isCompoundTabSelected ? getCompoundCRSVUnit(state, values) : values[CRSFields.V_UNITS]
      : null,
    [CRSFields.H_UNITS]: isCRSOrCompoundCRSTabSelected ? values[CRSFields.H_UNITS] : null,
    [CRSFields.EPOCH]: epoch,
    [CRSFields.GRID]: isCRSOrCompoundCRSTabSelected
      ? isCompoundTabSelected
        ? compoundCRS ? isCompoundCRS(compoundCRS) ? values[CRSFields.GRID] : null : null
        : values[CRSFields.GRID]
      : null,
    [CRSFields.GEOID_CRS]: isCRSOrCompoundCRSTabSelected ? values[CRSFields.GEOID_CRS] : null,
    // [CRSFields.IS_ELLIPSOIDAL]: isCRSOrCompoundCRSTabSelected ? values[CRSFields.IS_ELLIPSOIDAL] : null,
    [CRSFields.NORMALIZE_AXIS_ORDER]: isCRSOrCompoundCRSTabSelected ? values[CRSFields.NORMALIZE_AXIS_ORDER] : null,
    points,
  })
}

/**
 * Returns transform options based on the tab
 * @param {*} values
 * @param {*} points
 * @param {*} state
 * @param {*} extra
 */
export const getCRSTransformOptions = (values, points, state, extra, projectCRS) => {
  const tab = values[CRSFields.TAB]
  const tabName = tabsMap[tab]
  const tabTemplate = getTabTemplate(tabName, values[CRSFields.TABS_TEMPLATE])
  if (tabTemplate && tabTemplate.isCustomCrsTab) {
    const customCrsString = getCustomCRSValue(values)
    const epoch = getEpoch(state, extra, values, projectCRS)
    return getCustomCRSTransformationOptions({
      [CRSFields.C_CRS]: customCrsString,
      [CRSFields.H_CRS]: null,
      [CRSFields.V_CRS]: null,
      [CRSFields.V_UNITS]: null,
      [CRSFields.H_UNITS]: null,
      [CRSFields.EPOCH]: epoch,
      [CRSFields.GRID]: null,
      [CRSFields.GEOID_CRS]: null,
      [CRSFields.NORMALIZE_AXIS_ORDER]: null,
      // [CRSFields.IS_ELLIPSOIDAL]: null,
      points,
    })
  } else {
    return getTransformOptions(values, points, state, extra, projectCRS)
  }
}

/**
 * Returns transform options based on the tab
 * @param {*} values
 * @param {*} points
 * @param {*} state
 * @param {*} extra
 */
export const getProjectWizardCRSTransformOptions = (values, points, state, extra, crs) => {
  if (crs && crs[CRSFields.C_CRS] && !isCRSFieldCompound(crs[CRSFields.C_CRS])) {
    const customCrsString = getCustomCRSValue(values)
    const epoch = getEpoch(state, extra, {}, crs)
    return getCustomCRSTransformationOptions({
      [CRSFields.C_CRS]: customCrsString,
      [CRSFields.H_CRS]: null,
      [CRSFields.V_CRS]: null,
      [CRSFields.V_UNITS]: null,
      [CRSFields.H_UNITS]: null,
      [CRSFields.EPOCH]: epoch,
      [CRSFields.GRID]: null,
      [CRSFields.GEOID_CRS]: null,
      [CRSFields.NORMALIZE_AXIS_ORDER]: null,
      points,
    })
  } else {
    return getTransformOptions(values, points, state, extra, crs)
  }
}

/**
 * Function returns options for transforming coordinates
 * @param {Object} values
 * @param {Array} points
 * @param {Object} state
 * @param {Object} extra
 */
export const getNewTransformOptions = (values, points, state, extra, projectCRS) => {
  const epoch = getEpoch(state, extra, values, projectCRS)
  return getTransformationOptions({
    [CRSFields.C_CRS]: values[CRSFields.C_CRS],
    [CRSFields.H_CRS]: values[CRSFields.H_CRS],
    [CRSFields.V_CRS]: values[CRSFields.V_CRS],
    [CRSFields.V_UNITS]: values[CRSFields.V_UNITS],
    [CRSFields.H_UNITS]: values[CRSFields.H_UNITS],
    [CRSFields.EPOCH]: epoch,
    [CRSFields.GRID]: values[CRSFields.GRID],
    [CRSFields.GEOID_CRS]: values[CRSFields.GEOID_CRS],
    [CRSFields.NORMALIZE_AXIS_ORDER]: values[CRSFields.NORMALIZE_AXIS_ORDER],
    points,
  })
}

export const getNewCRSTransformOptions = (values, points, state, extra, crs) => {
  if (crs && crs[CRSFields.C_CRS] && !isCRSFieldCompound(crs[CRSFields.C_CRS])) {
    const customCrsString = crs[CRSFields.C_CRS]
    const epoch = getEpoch(state, extra, {}, crs)
    return getCustomCRSTransformationOptions({
      [CRSFields.C_CRS]: customCrsString,
      [CRSFields.H_CRS]: null,
      [CRSFields.V_CRS]: null,
      [CRSFields.V_UNITS]: null,
      [CRSFields.H_UNITS]: null,
      [CRSFields.EPOCH]: epoch,
      [CRSFields.GRID]: null,
      [CRSFields.GEOID_CRS]: null,
      [CRSFields.NORMALIZE_AXIS_ORDER]: null,
      points,
    })
  } else {
    return getNewTransformOptions(values, points, state, extra, crs)
  }
}

export const getFormattedValues = (values, checkedUnits) => {
  if (typeof values === 'undefined') return values
  const newValues = values
  const tab = values[CRSFields.TAB]
  const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
  const isCustomCRSTab = tabTemplate && tabTemplate.isCustomCrsTab
  if (isCustomCRSTab) {
    // const crs_name = path(['fields', CRSFields.CUSTOM_CRS, 'fieldName'], tabTemplate)
    const isLonLat = checkedUnits[CRSFields.CUSTOM_CRS]
    return {
      ...newValues,
      [CRSFields.COORDINATES_CHOOSER]: isLonLat
        ? CoordinateTypes.LAT_LON
        : CoordinateTypes.NORTHING_EASTING,
    }
  }
  return newValues
}

export function shouldRenderXYZ (state, values, formTemplate, option) {
  const tab = values[CRSFields.TAB]
  const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
  const compound_crs = formTemplate[CRSFields.C_CRS]
  const horizontal_crs = formTemplate[CRSFields.H_CRS]
  if (!isCustomAnalyzeSelected(values)) {
    return false
  }
  if (path(['fields', CRSFields.C_CRS], tabTemplate) && compound_crs) {
    const compoundCRSValue = compound_crs ? values[CRSFields.C_CRS] : null
    return isLengthUnitType(path([CRSFields.H_UNITS, 'type'], compoundCRSValue))
  }
  if (path(['fields', CRSFields.H_CRS], tabTemplate) && horizontal_crs) {
    const horizontalCRSValue = horizontal_crs ? values[CRSFields.H_CRS] : null
    return isLengthUnitType(path([CRSFields.H_UNITS, 'type'], horizontalCRSValue))
  }
  return false
  /*
  const selectedHorizontalCRS = horizontal_crs ? horizontal_crs.getCRSValue(state, values[CRSFields.H_CRS]) : null
  const crsType = selectedHorizontalCRS && selectedHorizontalCRS.type
  return isCustomAnalyzeSelected(values)
    ? crsType === 'geocentric' || crsType === 'projected' || crsType === 'compound'
    : false
  */
}

export const getValuesByTab = (tab, values) => {
  const tabName = tabsMap[tab]
  if (tabName) {
    const tabTemplate = getTabTemplate(tabName, values[CRSFields.TABS_TEMPLATE])
    const fields = tabTemplate && tabTemplate.fields
    if (fields) {
      const fieldNames = Object.keys(fields)
      const fieldNamesListShouldBeOmitted = CRSFieldsList.filter(fieldName => fieldNames.indexOf(fieldName) < 0)
      return omit(fieldNamesListShouldBeOmitted, values)
    }
  }
  return values
}

export const isTabTemplateValid = (template, values, state, extra) => {
  return validateJobOptionsForm(values, template, state, extra)
}

export const getTabValue = options => {
  const { values, state, extra } = options
  const { [CRSFields.TABS_TEMPLATE]: tabsTemplate = {} } = values
  const tabIndex = Object.keys(tabsTemplate).findIndex((tabName, index) => {
    const tempValues = { ...values, [CRSFields.TAB]: index, [CRSFields.LAST_CHANGED_TAB]: index }
    const tabTemplate = getTabTemplate(tabName, tabsTemplate)
    const fields = tabTemplate && tabTemplate.fields
    return isTabTemplateValid(fields, tempValues, state, extra)
  })
  return tabIndex >= 0 ? tabIndex : 0
}

/**
 * Found geoid based on the previous versions of the geoids
 * @param {*} state
 * @param {*} value
 */
export const getGridValue = (state, value) => {
  const geoids = getGeoids(state)
  if (value) {
    if (typeof value === 'string') {
      const baseName = getBaseName(value)
      return geoids.find(geoid => geoid.name.includes(baseName))
    }
    if (typeof value === 'object') {
      if (isFileNameEndsWith(value.file, '.gtx')) return geoids.find(geoid => geoid.file.includes(value.name))
      if (isFileNameEndsWith(value.file, '.tif')) return value
    }
  }
  return null
}

/**
 * Getting properties of the CRS for the GCP artifact
 */
export const getGCPNewProperties = (state, extra, properties, crsInitialState = {}) => {
  let newGCPProperties = {}
  const geoidProperty = properties[CRSFields.GRID]
  const geoidValue = geoidProperty && getGridValue(state, geoidProperty)
  newGCPProperties = {
    startIndex: properties.startIndex,
    ...crsInitialState,
    ...properties,
    ...(properties[CRSFields.EPOCH] && { [CRSFields.EPOCH]: fromDecimalYear(properties.epoch) }),
    ...(geoidProperty && { [CRSFields.GRID]: geoidValue }),
    [CRSFields.TAB]: -1,
  }
  newGCPProperties[CRSFields.TAB] = getTabValue({ state, values: newGCPProperties, extra })
  const tab = newGCPProperties[CRSFields.TAB]
  newGCPProperties[CRSFields.LAST_CHANGED_TAB] = tab

  const tabTemplate = getTabTemplate(tabsMap[tab], newGCPProperties[CRSFields.TABS_TEMPLATE])
  if (tabTemplate.isCustomCrsTab) {
    newGCPProperties[CRSFields.CUSTOM_CRS] = newGCPProperties[CRSFields.CUSTOM_CRS]
  }
  return newGCPProperties
}

export const isGCPFieldsSet = options => {
  const { values, state, extra } = options
  const {
    [CRSFields.LAST_CHANGED_TAB]: currentTab,
    [CRSFields.TABS_TEMPLATE]: tabsTemplate,
  } = values
  const tab = typeof currentTab === 'number' ? currentTab : getTabValue(options)
  const tabTemplate = getTabTemplate(tabsMap[tab], tabsTemplate)
  const fields = tabTemplate && tabTemplate.fields
  return isTabTemplateValid(fields, values, state, extra)
}

export const isLasFieldsSet = options => {
  const { values, state, extra } = options
  const {
    [CRSFields.LAST_CHANGED_TAB]: currentTab,
    [CRSFields.TABS_TEMPLATE]: tabsTemplate,
  } = values
  const tab = typeof currentTab === 'number' ? currentTab : getTabValue(options)
  const tabTemplate = getTabTemplate(tabsMap[tab], tabsTemplate)
  const fields = tabTemplate && tabTemplate.fields
  return isTabTemplateValid(fields, values, state, extra)
}

export const getPosition = (options, name) => {
  const { actions = {}, values } = options
  const { onGetGCP = () => {} } = actions
  const point = {
    x: values[Coordinates.LONGITUDE] || '0',
    y: values[Coordinates.LATITUDE] || '0',
    z: values[Coordinates.ALTITUDE] || '0',
  }
  const transformationOptions = getCustomCRSTransformationOptions({
    [CRSFields.C_CRS]: values[name],
    [CRSFields.H_CRS]: null,
    [CRSFields.V_CRS]: null,
    [CRSFields.V_UNITS]: null,
    [CRSFields.H_UNITS]: null,
    [CRSFields.EPOCH]: values[CRSFields.EPOCH],
    [CRSFields.GRID]: null,
    [CRSFields.GEOID_CRS]: null,
    [CRSFields.NORMALIZE_AXIS_ORDER]: null,
    // [CRSFields.IS_ELLIPSOIDAL]: null,
    points: [point],
  })
  onGetGCP(transformationOptions)
}

export const isCoordinatesSet = (columnAssignments = {}) => {
  const { x, y, z } = columnAssignments
  return x >= 0 && y >= 0 && z >= 0
}

export function getCoordinateType (state, values, formTemplate) {
  const xyz = shouldRenderXYZ(state, values, formTemplate)
  const tab = values[CRSFields.TAB]
  const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
  const selectedCoordinatesTypeValue = values[CRSFields.COORDINATES_CHOOSER]
  // PROJ or WKT tab selected so we need to display fields based on CRSFields.COORDINATES_CHOOSER field
  if (tabTemplate && tabTemplate.isCustomCrsTab && selectedCoordinatesTypeValue) {
    return selectedCoordinatesTypeValue
  } else {
    return xyz ? CoordinateTypes.NORTHING_EASTING : CoordinateTypes.LAT_LON
  }
}

export function resetCoordinates (values, valuesToSet) {
  const longitude = values[Coordinates.LONGITUDE]
  const latitude = values[Coordinates.LATITUDE]
  const altitude = values[Coordinates.ALTITUDE]

  if (longitude !== 0) {
    values[Coordinates.LONGITUDE] = '0'
    valuesToSet[Coordinates.LONGITUDE] = '0'
  }
  if (latitude !== 0) {
    values[Coordinates.LATITUDE] = '0'
    valuesToSet[Coordinates.LATITUDE] = '0'
  }
  if (altitude !== 0) {
    values[Coordinates.ALTITUDE] = '0'
    valuesToSet[Coordinates.ALTITUDE] = '0'
  }
}

export function getNewGCPProtoArtifact (protoArtifact, state = {}) {
  return getNewCRSProtoArtifact(protoArtifact, state, ['properties'])
}
export function getNewLasProtoArtifact (protoArtifact, state = {}, fileName) {
  return getNewCRSProtoArtifact(protoArtifact, state, ['properties', 'fileProperties', fileName, 'crs'])
}

export function getNewCRSProtoArtifact (protoArtifact, state = {}, paths) {
  const values = path(paths, protoArtifact)
  /*
  const hasCoordinateReferenceSystemField = 'coordinate_reference_system' in values
  if (hasCoordinateReferenceSystemField) {
    values = {
      ...values,
      ...values.coordinate_reference_system
    }
  }
  */
  if (values) {
    const {
      [CRSFields.LAST_CHANGED_TAB]: tab,
      [CRSFields.TABS_TEMPLATE]: tabsTemplate,
    } = values
    const tabName = tabsMap[tab]
    const tabTemplate = getTabTemplate(tabName, tabsTemplate)
    const fields = tabTemplate ? tabTemplate.fields : {}
    const fieldNames = tabTemplate ? Object.keys(fields) : []
    const newProps = omit(Object.keys(values).filter(propName => {
      const isInFieldsNames = fieldNames.indexOf(propName) >= 0
      const isNotInFieldsArray = CRSFieldsList.indexOf(propName) < 0
      return !(isInFieldsNames || (!isInFieldsNames && isNotInFieldsArray))
    }), values)
    const newProtoArtifact = { ...protoArtifact }
    const transformedValues = getBackendTransformedValues(state, newProps, newProps, fields, true)
    const resultValues = getValuesByTab(transformedValues[CRSFields.TAB], transformedValues)
    return set(lensPath(paths), resultValues, newProtoArtifact)
  }
  return protoArtifact
  /*
      Object.keys(newProps).reduce((allProps, key) => {
      const prop = newProps[key]
      const fieldOption = fields[key] || {}
      const { backendTransform, sendToBackend = true } = fieldOption
      const originalValue = prop
      if (!sendToBackend) return allProps
      const value = typeof backendTransform === 'function'
        ? backendTransform(originalValue, state, newProps)
        : originalValue
      return {
        ...allProps,
        [key]: value,
      }
    }, {}),
    */
}

export const onGetTransformedPoints = (state, extra, actions, point, crs, anotherCrs) => {
  const { onGetGCP = () => {} } = actions
  if (crs) {
    const transformationOptions = getNewCRSTransformOptions(
      crs,
      Array.isArray(point) ? point : [point],
      state,
      extra,
      anotherCrs
    )
    if (typeof onGetGCP === 'function') {
      onGetGCP(transformationOptions)
    }
  }
}

export const updateCoordinatesChooser = (state, setMultipleValues, crs, anotherCrs, { onChange, values } = {}, newCoordinates = null) => {
  const transformationOptions = omit(['points'], getNewCRSTransformOptions(crs, [], state, '', anotherCrs))
  getCRSExtendedInfo(transformationOptions).then(result => {
    let coordinatesType = CoordinateTypes.NORTHING_EASTING
    const newCrs = { ...crs }
    if (result.okay) {
      const resultCrs = result.crs
      if (result.crs.is_angular) {
        coordinatesType = CoordinateTypes.LAT_LON
      } else if (result.crs.is_geocentric) {
        coordinatesType = CoordinateTypes.NORTHING_EASTING
      } else {
        coordinatesType = CoordinateTypes.EASTING_NORTHING
      }
      // newCrs = transformRefStationCRSValues(state, { [REF_STATION_BACKEND_NAME]: resultCrs })[REF_STATION_BACKEND_NAME]
      // newCrs.crs = newCrs.crs || newCrs[CRSFields.C_CRS] || null
      newCrs.datum = resultCrs.ie_datum
    }
    if (setMultipleValues) {
      coordinatesType = newCoordinates ? newCoordinates : coordinatesType
      setMultipleValues({
        [REF_STATION_BACKEND_NAME]: newCrs,
        [CRSFields.COORDINATES_CHOOSER]: coordinatesType,
      })
    } else if (onChange && values) {
      // TODO: fix this
      // this function is called when analyze or crs is changed
      // but for cloned with settings we can't update coordinate types with async call to extended info
      // because we don't have setMultipleValues at the time
      // Problem here that onChange callback not merging values but change the values
      // but we need to change only one value here
      setTimeout(() => {
        onChange(
          { ...values, [CRSFields.COORDINATES_CHOOSER]: coordinatesType },
          true,
          { ...values, [CRSFields.COORDINATES_CHOOSER]: coordinatesType }
        )
      }, 500)
    }
  })
}

export const onChangePosition = originalTabsTemplate => (name, value, options) => {
  const {
    state,
    values: oldValues,
    formTemplate,
    setMultipleValues,
    actions,
    extra,
    extraProps = {},
    option,
  } = options
  const { tag } = option
  const { projectCRS } = extraProps
  const values = { ...oldValues, [name]: value }
  const valuesToSet = {}
  if (tag === CRSTag.COORDINATE) {
    const crs = values[REF_STATION_BACKEND_NAME]
    const point = {
      x: values[Coordinates.LONGITUDE],
      y: values[Coordinates.LATITUDE],
      z: values[Coordinates.ALTITUDE],
    }
    const useCrs = crs || projectCRS || {}
    if (useCrs) {
      onGetTransformedPoints(state, extra, actions, point, useCrs, useCrs)
    }
    return
  }
  if (isAnalyzedTypeSelected(values)) {
    const crs = projectCRS || values[REF_STATION_BACKEND_NAME]
    updateCoordinatesChooser(state, setMultipleValues, crs || {}, projectCRS || {}, {}, CoordinateTypes.NORTHING_EASTING)
  }
  // If user selected 'Custom' reference station position
  if (isCustomAnalyzeTypeSelected(values)) {
    const crs = projectCRS || values[REF_STATION_BACKEND_NAME]
    if (values[CRSFields.COORDINATES_CHOOSER] === CoordinateTypes.NORTHING_EASTING) {
      if (crs || projectCRS) {
        updateCoordinatesChooser(state, setMultipleValues, crs || {}, projectCRS || {})
        resetCoordinates(values, valuesToSet)
      }
    }
  }
  // If user selected company reference station position
  if (isUserCustomAnalyzeTypeSelected(values) && value && value.data) {
    const customPositionCRS = value.data[REF_STATION_BACKEND_NAME]
    const point = {
      x: value.data.x,
      y: value.data.y,
      z: value.data.z,
    }
    const newCRSValue = getProjectCRSInitialValues(state, customPositionCRS)
    valuesToSet[REF_STATION_BACKEND_NAME] = newCRSValue
    onGetTransformedPoints(state, extra, actions, point, newCRSValue, newCRSValue)
    updateCoordinatesChooser(state, setMultipleValues, newCRSValue, newCRSValue)
    /*
      const newValues = { ...values, ...(value.data || {}), tabsTemplate: originalTabsTemplate }
      const tab = getTabValue({ state, values: newValues, extra })
      newValues[CRSFields.TAB] = tab
      newValues[CRSFields.LAST_CHANGED_TAB] = tab

      const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
      const { fields } = tabTemplate
      const point = {
        x: value.data.x,
        y: value.data.y,
        z: value.data.z,
      }
      if (tabTemplate.isCustomCrsTab) {
        const newCustomStrValue = value.data[CRSFields.CUSTOM_CRS]
        checkUnitsWithCustomCRS(newCustomStrValue, extra, checkUnits)
      }
      const isTemplateValid = isTabTemplateValid(fields, newValues, state, extra)
      const transformationOptions = getTransformOptions(newValues, [point], state, extra, projectCRS)
      valuesToSet[CRSFields.TAB] = tab
      valuesToSet[CRSFields.LAST_CHANGED_TAB] = tab
      if (isTemplateValid && typeof onGetGCP === 'function') onGetGCP(transformationOptions)
      // Retrieve CRS info only when at least one crs is determined
      if (
        (transformationOptions[CRSFields.C_CRS] ||
        transformationOptions[CRSFields.V_CRS] ||
        transformationOptions[CRSFields.H_CRS]) && typeof onGetCRSInfo === 'function'
      ) onGetCRSInfo(omit(['points'], transformationOptions), extra)
    */
  } else if (isCustomAnalyzeSelected(values)) {
    const coordinateType = getCoordinateType(state, values, formTemplate)
    if (coordinateType === CoordinateTypes.NORTHING_EASTING) {
      resetCoordinates(values, valuesToSet)
    }
  }
  if (Object.keys(valuesToSet).length > 0) callWithDelay(() => setMultipleValues(valuesToSet), 1)
}

const getParsedResult = (file, field) => {
  const prevParsed = path(['parseResult', 'result'], file) || path(['result'], file)
  return prevParsed
    ? field
      ? path(Array.isArray(field) ? field : [field], prevParsed)
      : prevParsed
    : undefined
}

export const getPoints = (file, artifact) => {
  const { properties } = artifact
  const { startIndex, columnAssignments } = properties
  if (!properties || !columnAssignments) return []
  const { x, y, z } = columnAssignments

  const parsed = getParsedResult(file)
  const parsedData = parsed || []
  return (parsedData || path(['result'], file) || [])
    .reduce((allRows, tempRow, index) => {
      const row = tempRow.data || tempRow
      if (index >= startIndex) {
        const rowX = row[x]
        const rowY = row[y]
        const rowZ = row[z]
        return [
          ...allRows,
          {
            x: rowX,
            y: rowY,
            z: rowZ,
          },
        ]
      }
      return allRows
    }, [])
}

export const getGCPS = (properties, file, artifact, props) => {
  const { state, extra, coordinateSystems, onGetGCPs, onGetCRSInfo, projectCRS } = props
  // We need to retrieve transformed gcp coordinates
  const options = { state, extra, values: properties }
  const {
    [CRSFields.TAB]: currentTab,
    [CRSFields.TABS_TEMPLATE]: tabsTemplate,
    columnAssignments: currentColumnAssignments,
    startIndex,
  } = properties
  const tab = typeof currentTab === 'number' ? currentTab : getTabValue(options)
  const newValues = getCRSValues(properties, coordinateSystems, state, extra)
  if (
    isCoordinatesSet(currentColumnAssignments) &&
    isGCPFieldsSet({ state, extra, values: properties }) &&
    !isCustomCrsTab(tab, tabsTemplate)
  ) {
    const crs = getCRSFields({ ...options, values: newValues })
    const crsKeys = Object.keys(crs)
    if (crsKeys.length > 0 && crsKeys.some(key => crs[key])) {
      const parsed = getParsedResult(file)
      const parsedData = parsed || []
      const points = getPoints(file, artifact).filter((point, index) => {
        const row = parsedData[index + startIndex]
        return isGcpRowParsed(row)
      })
      const transformationOptions = getCRSTransformOptions(newValues, points, state, extra, projectCRS)
      onGetGCPs(transformationOptions, extra)
      onGetCRSInfo(transformationOptions)
    }
  }
}

export const getEpoch = (state, extra, values, projectCRS) => {
  if (!extra || extra === TransformationTemplateId.INPUT || extra === TransformationTemplateId.OUTPUT) {
    return values ? values[CRSFields.EPOCH] : null
  }
  if (projectCRS && projectCRS.datum) {
    const datumString = getBackendString(projectCRS.datum)
    const epochs = getEpochs(state)
    const epoch = epochs[datumString]
    if (epoch) return epoch.toString()
  }
  return toDecimalYear(new Date())
}

export const getBackendGeoidString = geoid => {
  return getGeoidFileName(geoid)
}

export const getNormalizeAxisOrderInitialValue = (state, values) => {
  const normalizeAxisOrder = values[CRSFields.NORMALIZE_AXIS_ORDER]
  if (typeof normalizeAxisOrder === 'undefined') return false
  return Boolean(normalizeAxisOrder)
}

export const getIsEllipsoidalInitialValue = (state, values) => {
  const isEllipsoidal = values[CRSFields.IS_ELLIPSOIDAL]
  if (typeof isEllipsoidal === 'undefined') return false
  return Boolean(isEllipsoidal)
}

export const getDatumInitialValue = (state, values) => {
  const datum = values.datum
  if (!datum) return null
  return datum
}

export const getUnitVInitialValue = (state, values) => {
  const unitV = values[CRSFields.V_UNITS]
  if (!unitV) return null
  return getUnits(state).find(unit => getBackendString(unit) === unitV)
}

export const getUnitHInitialValue = (state, values) => {
  const unitH = values[CRSFields.H_UNITS]
  if (!unitH) return null
  return getUnits(state).find(unit => getBackendString(unit) === unitH)
}

export const getGeoidInitialValue = (state, values) => {
  const geoid = values[CRSFields.GRID]
  if (!geoid) return null
  return getGeoid(state, geoid)
}

export const getCompoundCRSInitialValue = (state, values) => {
  const crsC = values[CRSFields.C_CRS]
  if (!crsC || !isCRSFieldCompound(crsC)) return null
  return getCoordinateSystems(state).find(crs => getBackendString(crs) === crsC)
}

export const getVerticalCRSInitialValue = (state, values) => {
  const crsV = values[CRSFields.V_CRS]
  if (!crsV) return null
  return getVerticalCoordinateSystems(state).find(crs => getBackendString(crs) === crsV)
}

export const getHorizontalCRSInitialValue = (state, values) => {
  const crsH = values[CRSFields.H_CRS]
  if (!crsH) return null
  return getHorizontalGeographic3DCoordinateSystems(state).find(crs => getBackendString(crs) === crsH)
}

// If we clicked on tab with custom str we should try to check the units
export const checkUnitsWithCustomCRS = (value, extra, checkUnits) => {
  if (typeof checkUnits === 'function' && value) {
    checkUnits({ [CRSFields.C_CRS]: value }, extra)
  }
}

export const CRSFieldsTransform = {
  [CRSFields.C_CRS]: getCompoundCRSInitialValue,
  [CRSFields.V_CRS]: getVerticalCRSInitialValue,
  [CRSFields.H_CRS]: getHorizontalCRSInitialValue,
  [CRSFields.V_UNITS]: getUnitVInitialValue,
  [CRSFields.H_UNITS]: getUnitHInitialValue,
  [CRSFields.GRID]: getGeoidInitialValue,
}

export const transformOldCRSFieldsToNew = (state, values) => {
  const updatedFieldNamesValues = values ? Object.keys(values).reduce((allProps, key) => {
    const value = values[key]
    if (key in OldCRSFieldsToNewMapping) {
      const newFieldName = OldCRSFieldsToNewMapping[key]
      return {
        ...allProps,
        [newFieldName]: value,
      }
    }
    return {
      ...allProps,
      [key]: value,
    }
  }, {}) : {}
  if (updatedFieldNamesValues[CRSFields.GRID] && 'file' in updatedFieldNamesValues[CRSFields.GRID]) {
    updatedFieldNamesValues[CRSFields.GRID] = getGeoid(state, updatedFieldNamesValues[CRSFields.GRID])
  }
  return updatedFieldNamesValues
}

export const transformNewCRSFieldsToValues = (state, values) => {
  return values ? Object.keys(values).reduce((allValues, key) => {
    const value = values[key]
    if (key === CRSFields.C_CRS && value && !isCRSFieldCompound(value)) {
      return {
        ...allValues,
        [key]: value,
        // [CRSFields.CUSTOM_CRS]: value,
      }
    }
    const transform = CRSFieldsTransform[key]
    if (typeof transform !== 'undefined') {
      return {
        ...allValues,
        [key]: transform(state, values),
      }
    }
    return {
      ...allValues,
      [key]: values[key],
    }
  }, {}) : {}
}

export const backendCRSFieldTransform = callback => (original, state, formValues) => {
  if (isCustomAnalyzeSelected(formValues) || !('analyze' in formValues)) {
    const transformedValue = callback(original, state, formValues)
    return transformedValue
  }
  return null
}

// Transform v/h units to the values that should be send to backend
export const unitBackendTransform = (original, state, formValues) => {
  return !original || original === 'lonlat' ? null : getBackendString(original)
}

const getInitialValue = (state, extra, options, option) => {
  const { initialValue } = option
  return typeof initialValue === 'function' ? initialValue(state, extra, options) : initialValue
}

export const getInitialTab = (state, extra, options, clone) => {
  const { actions, formTemplate, extraProps, values } = options
  const { projectCRS } = extraProps
  const { onGetCRSInfo = () => {} } = actions
  if (!clone && projectCRS && projectCRS[CRSFields.C_CRS]) {
    const crs = projectCRS[CRSFields.C_CRS]
    if (!isCRSFieldCompound(crs)) {
      return 2
    }
  }
  const units = getUnits(state)
  const horizontalCrs = getInitialValue(state, extra, { ...options, name: CRSFields.H_CRS }, formTemplate[CRSFields.H_CRS])
  const verticalCrs = getInitialValue(state, extra, { ...options, name: CRSFields.V_CRS }, formTemplate[CRSFields.V_CRS])
  if (horizontalCrs) {
    const h_units = getInitialValue(state, extra, { ...options, name: CRSFields.H_UNITS }, formTemplate[CRSFields.H_UNITS])
    const v_units = getInitialValue(state, extra, { ...options, name: CRSFields.V_UNITS }, formTemplate[CRSFields.V_UNITS])
    const datum = 'processing_datum' in formTemplate
      ? getInitialValue(state, extra, { ...options, name: 'processing_datum' }, formTemplate.processing_datum)
      : null
    const geoid = getInitialValue(state, extra, { ...options, name: CRSFields.GRID }, formTemplate[CRSFields.GRID])
    const geoidCrs = getInitialValue(state, extra, { ...options, name: CRSFields.GEOID_CRS }, formTemplate[CRSFields.GEOID_CRS])
    // const isEllipsoidal = getInitialValue(state, extra, { ...options, name: CRSFields.IS_ELLIPSOIDAL }, formTemplate[CRSFields.IS_ELLIPSOIDAL])
    const normalizeAxisOrder = getInitialValue(state, extra, { ...options, name: CRSFields.NORMALIZE_AXIS_ORDER }, formTemplate[CRSFields.NORMALIZE_AXIS_ORDER])
    // Processing datum is only in OUTPUT_CRS Job
    const epoch = 'processing_datum' in formTemplate
      ? datum ? getEpoch(state, extra, values, projectCRS) : null
      : getEpoch(state, extra, values, projectCRS)
    // const epoch = getInitialValue(state, extra, { ...options, name: CRSFields.EPOCH }, formTemplate[CRSFields.EPOCH])
    const transformationOptions = getTransformationOptions({
      [CRSFields.C_CRS]: null,
      [CRSFields.V_CRS]: verticalCrs,
      [CRSFields.H_CRS]: horizontalCrs,
      [CRSFields.V_UNITS]: v_units ? units.find(unit => unit.code === v_units.code) : null,
      [CRSFields.H_UNITS]: h_units ? units.find(unit => unit.code === h_units.code) : null,
      [CRSFields.EPOCH]: epoch,
      [CRSFields.GRID]: geoid,
      [CRSFields.GEOID_CRS]: geoidCrs,
      [CRSFields.NORMALIZE_AXIS_ORDER]: normalizeAxisOrder,
      // [CRSFields.IS_ELLIPSOIDAL]: isEllipsoidal,
      points: [],
    })
    onGetCRSInfo(omit(['points'], transformationOptions), false)
  }
  return horizontalCrs ? 1 : 0
}

export const getInitialLastChangedTab = (state, extra, options, clone) => {
  const { formTemplate, extraProps } = options
  const { projectCRS } = extraProps
  if (!clone && projectCRS && projectCRS[CRSFields.C_CRS]) {
    const crs = projectCRS[CRSFields.C_CRS]
    if (!isCRSFieldCompound(crs)) {
      return 2
    }
  }
  const horizontalCrs = getInitialValue(state, extra, { ...options, name: CRSFields.H_CRS }, formTemplate[CRSFields.H_CRS])
  return horizontalCrs ? 1 : 0
}

export const getCustomTabTemplateFields = FieldTemplates => {
  return {
    [CRSFields.GEOID_CRS]: {
      ...FieldTemplates[CRSFields.GEOID_CRS],
    },
    /*
    [CRSFields.IS_ELLIPSOIDAL]: {
      ...FieldTemplates[CRSFields.IS_ELLIPSOIDAL],
    },
    */
    [CRSFields.NORMALIZE_AXIS_ORDER]: {
      ...FieldTemplates[CRSFields.NORMALIZE_AXIS_ORDER],
    },
    [CRSFields.GRID]: {
      ...FieldTemplates[CRSFields.GRID],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
    [CRSFields.H_CRS]: {
      ...FieldTemplates[CRSFields.H_CRS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
    [CRSFields.H_UNITS]: {
      ...FieldTemplates[CRSFields.H_UNITS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
    [CRSFields.V_CRS]: {
      ...FieldTemplates[CRSFields.V_CRS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
    [CRSFields.V_UNITS]: {
      ...FieldTemplates[CRSFields.V_UNITS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
  }
}

export const getCRSTabTemplateFields = FieldTemplates => {
  return {
    [CRSFields.GEOID_CRS]: {
      ...FieldTemplates[CRSFields.GEOID_CRS],
    },
    /*
    [CRSFields.IS_ELLIPSOIDAL]: {
      ...FieldTemplates[CRSFields.IS_ELLIPSOIDAL],
    },
    */
    [CRSFields.NORMALIZE_AXIS_ORDER]: {
      ...FieldTemplates[CRSFields.NORMALIZE_AXIS_ORDER],
    },
    [CRSFields.C_CRS]: {
      ...FieldTemplates[CRSFields.C_CRS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
    [CRSFields.CUSTOM_CRS]: {
      ...FieldTemplates[CRSFields.CUSTOM_CRS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
  }
}

export const getCompoundTabTemplateFields = FieldTemplates => {
  return {
    [CRSFields.GEOID_CRS]: {
      ...FieldTemplates[CRSFields.GEOID_CRS],
    },
    /*
    [CRSFields.IS_ELLIPSOIDAL]: {
      ...FieldTemplates[CRSFields.IS_ELLIPSOIDAL],
    },
    */
    [CRSFields.NORMALIZE_AXIS_ORDER]: {
      ...FieldTemplates[CRSFields.NORMALIZE_AXIS_ORDER],
    },
    [CRSFields.H_CRS]: {
      ...FieldTemplates[CRSFields.H_CRS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
    [CRSFields.V_CRS]: {
      ...FieldTemplates[CRSFields.V_CRS],
      invisibleForTab: true,
      invisible: true,
      optionalForTab: true,
      optional: true,
    },
  }
}

export const transformRefStationCRSValues = (state, values) => {
  let newRefStationValues = {}
  // Handle new format of CRS
  if (REF_STATION_BACKEND_NAME in values) {
    const coordinateReferenceSystem = values[REF_STATION_BACKEND_NAME]
    // Pipelines can be created from SE that have different names for fields
    // Transform them to the suitable for us
    const transformedCoordinateReferenceSystemNames = coordinateReferenceSystem ? Object.keys(coordinateReferenceSystem).reduce((all, key) => {
      if (key in SECrsFieldNamesMap) {
        return {
          ...all,
          [SECrsFieldNamesMap[key]]: coordinateReferenceSystem[key],
        }
      }
      return all
    }, coordinateReferenceSystem) : {}
    const transformedCoordinateReferenceSystem = transformNewCRSFieldsToValues(
      state,
      omit(['id', 'name'], transformedCoordinateReferenceSystemNames)
    )
    newRefStationValues = {
      ...values,
      [REF_STATION_BACKEND_NAME]: addUniqueKeyToCRS(transformedCoordinateReferenceSystem),
    }
  // Handle old format of CRS
  } else {
    const transformedCoordinateReferenceSystem = transformOldCRSFieldsToNew(state, values)
    newRefStationValues = {
      ...omit(CRSFieldsList, transformedCoordinateReferenceSystem),
      [REF_STATION_BACKEND_NAME]: addUniqueKeyToCRS(keepProps(CRSFieldsListNew, transformedCoordinateReferenceSystem)),
    }
  }
  newRefStationValues.analyze = !newRefStationValues.analyze ? 'Custom' : newRefStationValues.analyze
  return newRefStationValues
}

export const transformOutputCRSValues = (state, values) => {
  const coordinateReferenceSystem = (values[SPATIAL_FUSER_BACKEND_NAME] || {})
  // Handle old format of CRS
  if (
    CRSFieldsOld.C_CRS in coordinateReferenceSystem ||
    CRSFieldsOld.V_CRS in coordinateReferenceSystem ||
    CRSFieldsOld.H_CRS in coordinateReferenceSystem ||
    CRSFieldsOld.CUSTOM_CRS in coordinateReferenceSystem
  ) {
    const transformedCoordinateReferenceSystem = transformOldCRSFieldsToNew(
      state,
      omit(['id', 'name'], coordinateReferenceSystem)
    )
    return {
      ...values,
      [SPATIAL_FUSER_BACKEND_NAME]: addUniqueKeyToCRS(transformedCoordinateReferenceSystem),
    }
  // Handle new format of CRS
  } else {
    const transformedCoordinateReferenceSystem = transformNewCRSFieldsToValues(
      state,
      omit(['id', 'name'], coordinateReferenceSystem)
    )
    return {
      ...values,
      [SPATIAL_FUSER_BACKEND_NAME]: addUniqueKeyToCRS(transformedCoordinateReferenceSystem),
    }
  }
}

export const transformGCPCRSValues = (state, extra, values, crsInitialState) => {
  // Handle new format of CRS
  if (GCP_BACKEND_NAME in values) {
    const coordinateReferenceSystem = values[GCP_BACKEND_NAME]
    const transformedCoordinateReferenceSystem = transformNewCRSFieldsToValues(
      state,
      omit(['id', 'name'], coordinateReferenceSystem)
    )
    return {
      ...values,
      [GCP_BACKEND_NAME]: addUniqueKeyToCRS(transformedCoordinateReferenceSystem),
    }
  } else {
    // Handle old format of CRS
    const transformedProperties = transformOldCRSFieldsToNew(state, values)
    return {
      ...omit(CRSFieldsList, transformedProperties),
      [GCP_BACKEND_NAME]: {
        ...crsInitialState,
        ...keepProps(CRSFieldsListNew, transformedProperties),
      },
    }
  }
}
