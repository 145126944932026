import { path } from 'ramda'
import { insertById, mergeById, mapById, removeById, findById, makeUniqueById } from 'utils/list'
import { getLoadingStatus, getSuccessStatus, getFailureStatus } from 'utils/reducersHelpers'
import { convertRawArtifact } from 'types/artifacts'
import { getArtifacts } from 'modules/projects/selectors'
// Local deps
import { ArtifactsTypes as ArtifactsActionTypes } from './actions'

const getArtifact = (state, { artifact }) => {
  const artifactId = artifact.id
  // Insert the new element into the list.
  const artifacts = getArtifacts(state)
  return state.merge({
    currentProject: {
      ...state.get('currentProject'),
      artifacts: insertById(artifacts, artifact),
    },
    allArtifacts: insertById(state.get('allArtifacts'), artifact),
    artifactLoading: {
      ...state.get('artifactLoading'),
      [artifactId]: false,
    },
  })
}

const getArtifactsReducer = (state, { artifacts }) => {
  const currentProjectArtifacts = getArtifacts(state)
  return state.merge({
    currentProject: {
      ...state.get('currentProject'),
      artifacts: makeUniqueById([
        ...artifacts,
        ...currentProjectArtifacts,
      ]),
    },
    allArtifacts: makeUniqueById([
      ...artifacts,
      ...state.get('allArtifacts'),
    ]),
  })
}

const getArtifactLoading = (state, { artifactId }) => {
  return state.merge({
    artifactLoading: {
      ...state.get('artifactLoading'),
      [artifactId]: true,
    },
  })
}

const getArtifactFailure = (state, { artifactId }) => {
  return state.merge({
    artifactLoading: {
      ...state.get('artifactLoading'),
      [artifactId]: false,
    },
  })
}

const getArtifactError = state => state.merge({})

const getArtifactsSuccess = (state, action) => {
  const { artifacts: payloadArtifacts } = action
  // Insert the new element into the list.
  const artifacts = getArtifacts(state)
  const artifactsLoading = payloadArtifacts.reduce((ids, artifact) => ({ ...ids, [artifact.id]: false }), {})
  return state.merge({
    currentProject: {
      ...state.get('currentProject'),
      artifacts: payloadArtifacts.reduce((newArtifacts, artifact) => {
        return insertById(newArtifacts, artifact)
      }, artifacts),
    },
    allArtifacts: payloadArtifacts.reduce((newArtifacts, artifact) => {
      return insertById(newArtifacts, artifact)
    }, state.get('allArtifacts')),
    artifactLoading: {
      ...state.get('artifactLoading'),
      ...artifactsLoading,
    },
  })
}

const getArtifactsLoading = (state, { artifactIds }) => {
  const artifactsLoading = artifactIds.reduce((ids, artifactId) => ({ ...ids, [artifactId]: true }), {})
  return state.merge({
    artifactLoading: {
      ...state.get('artifactLoading'),
      ...artifactsLoading,
    },
  })
}

const getArtifactsFailure = (state, { artifactIds, errorMessage }) => {
  const artifactsLoading = artifactIds.reduce((ids, artifactId) => ({ ...ids, [artifactId]: false }), {})
  return state.merge({
    artifactLoading: {
      ...state.get('artifactLoading'),
      ...artifactsLoading,
    },
  })
}

const completeArtifactLoading = state => state.merge({})
const completeArtifact = (state, { apiResult }) => {
  const { artifactId } = apiResult
  const artifacts = state.get('artifacts')
  return !artifacts.some(artifact => artifact.id === artifactId)
    ? state.merge({})
    : state.merge({
    // Iterate over all artifacts and replace the affected ones.
      artifacts: mapById(artifactId, artifacts, artifact => ({ ...artifact, completed: true })),
    })
}
const completeArtifactError = state => state.merge({})

const listDataFilesLoading = state => state.merge({})
const listDataFiles = (state, action) => {
  // artifactId
  const { dataFiles: newDataFiles } = action
  const currentProject = state.get('currentProject')
  const currentDataFiles = currentProject.dataFiles
  const newCurrentProject = {
    ...currentProject,
    dataFiles: mergeById(
      currentDataFiles,
      newDataFiles,
    ),
  }
  return state.merge({ currentProject: newCurrentProject })
}
const listDataFilesError = state => state.merge({})

const getDataFilesLoading = state => state.merge({})
const getDataFiles = state => state.merge({})
const getDataFilesError = state => state.merge({})

export const deleteDataDirectoryFileSuccess = (state, { artifactId, dataDirectoryId, link }) => {
  const currentProject = state.get('currentProject')
  const currentArtifact = findById(artifactId, currentProject.artifacts)
  if (currentArtifact) {
    return state.merge({
      currentProject: {
        ...currentProject,
        dataDirectories: mapById(dataDirectoryId, currentProject.dataDirectories, dataDirectory => ({
          ...dataDirectory,
          fileIndex: dataDirectory.fileIndex.filter(file => file !== link),
        })),
      },
      deleteDataDirectoryFileLoading: {
        ...state.get('deleteDataDirectoryFileLoading'),
        [link]: false,
      },
    })
  }
  return state.merge({
    deleteDataDirectoryFileLoading: {
      ...state.get('deleteDataDirectoryFileLoading'),
      [link]: false,
    },
  })
}
export const deleteDataDirectoryFileLoading = (state, { link }) => {
  return state.merge({
    deleteDataDirectoryFileLoading: {
      ...state.get('deleteDataDirectoryFileLoading'),
      [link]: true,
    },
  })
}
export const deleteDataDirectoryFileFailure = (state, { link }) => {
  return state.merge({
    deleteDataDirectoryFileLoading: {
      ...state.get('deleteDataDirectoryFileLoading'),
      [link]: false,
    },
  })
}

// Create artifact
export const createArtifactLoading = state => state.merge({ createArtifactIsLoading: true })
export const createArtifactSuccess = (state, { artifact }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    createArtifactIsLoading: false,
    currentProject: {
      ...currentProject,
      artifacts: [convertRawArtifact(artifact), ...currentProject.artifacts],
    },
  })
}
export const createArtifactFailure = (state, { errorMessage }) =>
  state.merge({
    createArtifactIsLoading: false,
    createArtifactErrorMessage: errorMessage,
  })

// Delete artifact
export const deleteArtifactLoading = (state, { artifactId }) => state.merge({
  deleteArtifactStatus: getLoadingStatus(state.get('deleteArtifactStatus'), artifactId),
})
export const deleteArtifactSuccess = (state, { artifactId }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      artifacts: removeById(artifactId, currentProject.artifacts),
    },
    allArtifacts: removeById(artifactId, state.get('allArtifacts')),
    deleteArtifactStatus: getSuccessStatus(state.get('deleteArtifactStatus'), artifactId),
  })
}
export const deleteArtifactFailure = (state, { artifactId, errorMessage }) => state.merge({
  deleteArtifactStatus: getFailureStatus(state.get('deleteArtifactStatus'), artifactId),
})
// Delete artifacts
export const deleteArtifacts = (state, { artifactIds }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      artifacts: currentProject.artifacts.filter(art => !artifactIds.includes(art.id)),
    },
    allArtifacts: state.get('allArtifacts').filter(art => !artifactIds.includes(art.id)),
    deleteArtifactStatus: artifactIds.reduce((newState, artifactId) => getSuccessStatus(newState, artifactId), state.get('deleteArtifactStatus')),
  })
}
// Update artifact
export const updateArtifactLoading = state => state.merge({
  updateArtifactIsLoading: true,
  updateArtifactErrorMessage: null,
})
export const updateArtifactSuccess = (state, { artifactId, artifact }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      artifacts: mapById(artifactId, currentProject.artifacts, () => convertRawArtifact(artifact)),
    },
    updateArtifactIsLoading: false,
  })
}
export const updateArtifactFailure = (state, { errorMessage }) => state.merge({
  updateArtifactIsLoading: false,
  updateArtifactErrorMessage: errorMessage,
})

export const setCompleteDataDirectoryLoading = (state, { dataDirectoryId }) => state.merge({
  completeDataDirectoryLoading: {
    ...state.get('completeDataDirectoryLoading'),
    [dataDirectoryId]: true,
  },
})
export const setCompleteDataDirectorySuccess = (state, { dataDirectoryId, dataDirectory }) => {
  const currentArtifact = state.get('currentArtifact')
  return state.merge({
    currentArtifact: {
      ...currentArtifact,
      dataDirectories: mapById(dataDirectoryId, currentArtifact.dataDirectories, () => dataDirectory),
    },
    completeDataDirectoryLoading: {
      ...state.get('completeDataDirectoryLoading'),
      [dataDirectoryId]: false,
    },
  })
}
export const setCompleteDataDirectoryFailure = (state, { dataDirectoryId, errorMessage }) => state.merge({
  completeDataDirectoryLoading: {
    ...state.get('completeDataDirectoryLoading'),
    [dataDirectoryId]: false,
  },
})

export const getCurrentArtifactDataSuccess = (state, { artifactId, artifact, dataDirectories, dataFiles }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      dataDirectories: [
        ...currentProject.dataDirectories,
        ...dataDirectories,
      ],
      dataFiles: [
        ...currentProject.dataFiles,
        ...dataFiles,
      ],
    },
  })
}

export const deleteDataFileLoading = state => state.merge({})
export const deleteDataFileSuccess = (state, { dataFileId }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      dataFiles: removeById(dataFileId, currentProject.dataFiles),
    },
  })
}
export const deleteDataFileFailure = state => state.merge({})

export const getCurrentArtifactDataLoading = state => state.merge({
  getCurrentArtifactDataIsLoading: true,
  getCurrentArtifactDataErrorMessage: null,
})

/*
export const getCurrentArtifactDataSuccess =
  (state, { artifactId, artifact, input_pipelines=[], output_pipelines=[], dataDirectories=[], dataFiles=[] }) =>
    state.merge({
      currentArtifact: {
        artifactId,
        artifact: convertRawArtifact(artifact),
        input_pipelines,
        output_pipelines,
        dataDirectories,
        dataFiles,
      },
      getCurrentArtifactDataIsLoading: false,
      getCurrentArtifactDataErrorMessage: null,
    })
*/
export const getCurrentArtifactDataFailure = (state, { errorMessage }) => state.merge({
  currentArtifact: {
    artifactId: '',
    artifact: {},
    input_pipelines: [],
    output_pipelines: [],
    dataDirectories: [],
    dataFiles: [],
  },
  getCurrentArtifactDataIsLoading: false,
  getCurrentArtifactDataErrorMessage: errorMessage,
})

export const updateProjectArtifactsStateSuccess = (state, { artifacts, fullReplace, projectId }) => {
  const currentProject = state.get('currentProject')
  const allArtifacts = state.get('allArtifacts') || []
  if (fullReplace) {
    const convertedArtifacts = artifacts.map(convertRawArtifact)
    return state.merge({
      currentProject: {
        ...currentProject,
        artifacts: convertedArtifacts,
      },
      allArtifacts: [
        ...allArtifacts.filter(art => path(['project', 'id'], art) !== projectId),
        ...convertedArtifacts,
      ],
    })
  }
  const projectArtifacts = currentProject.artifacts
  const newProjectArtifacts = projectArtifacts.map(projectArtifact => {
    const foundArtifact = findById(projectArtifact.id, artifacts)
    return foundArtifact
      ? { ...projectArtifact, artifactStatus: foundArtifact.artifact_status }
      : projectArtifact
  },
  )
  return state.merge({
    currentProject: {
      ...currentProject,
      artifacts: newProjectArtifacts,
    },
    allArtifacts: mergeById(state.get('allArtifacts'), newProjectArtifacts),
  })
}
export const updateProjectArtifactsStateFailure = state => state.merge({})

// Copy artifact
export const copyArtifactLoading = state => state.merge({ copyArtifactIsLoading: true })
export const copyArtifactSuccess = (state, { artifact }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    copyArtifactIsLoading: false,
    currentProject: {
      ...currentProject,
      artifacts: [
        ...currentProject.artifacts,
        convertRawArtifact(artifact),
      ],
    },
    allArtifacts: [
      ...state.get('allArtifacts'),
      artifact,
    ],
  })
}
export const copyArtifactFailure = state => state.merge({ copyArtifactIsLoading: false })

const exportCalibrationLoading = (state, { artifactId }) => state.merge({
  exportCalibrationLoading: {
    ...state.get('exportCalibrationLoading'),
    [artifactId]: true,
  },
  exportCalibrationErrors: {
    ...state.get('exportCalibrationErrors'),
    [artifactId]: [],
  },
})
const exportCalibrationSuccess = (state, { artifactId }) => state.merge({
  exportCalibrationLoading: {
    ...state.get('exportCalibrationLoading'),
    [artifactId]: false,
  },
  exportCalibrationErrors: {
    ...state.get('exportCalibrationErrors'),
    [artifactId]: [],
  },
  exportCalibrationWarnings: {
    ...state.get('exportCalibrationWarnings'),
    [artifactId]: [],
  },
})
const exportCalibrationFailure = (state, { artifactId, errors = [] }) => state.merge({
  exportCalibrationLoading: {
    ...state.get('exportCalibrationLoading'),
    [artifactId]: false,
  },
  exportCalibrationErrors: {
    ...state.get('exportCalibrationErrors'),
    [artifactId]: errors,
  },
})

const validateCalibrationLoading = (state, { artifactId }) => state.merge({
  exportCalibrationLoading: {
    ...state.get('exportCalibrationLoading'),
    [artifactId]: true,
  },
  exportCalibrationErrors: {
    ...state.get('exportCalibrationErrors'),
    [artifactId]: [],
  },
  exportCalibrationWarnings: {
    ...state.get('exportCalibrationWarnings'),
    [artifactId]: [],
  },
})
const validateCalibrationSuccess = (state, { artifactId, warnings = [] }) => state.merge({
  exportCalibrationLoading: {
    ...state.get('exportCalibrationLoading'),
    [artifactId]: false,
  },
  exportCalibrationErrors: {
    ...state.get('exportCalibrationErrors'),
    [artifactId]: [],
  },
  exportCalibrationWarnings: {
    ...state.get('exportCalibrationWarnings'),
    [artifactId]: warnings,
  },
})
const validateCalibrationFailure = (state, { artifactId, errors = [], warnings = [] }) => state.merge({
  exportCalibrationLoading: {
    ...state.get('exportCalibrationLoading'),
    [artifactId]: false,
  },
  exportCalibrationErrors: {
    ...state.get('exportCalibrationErrors'),
    [artifactId]: errors,
  },
  exportCalibrationWarnings: {
    ...state.get('exportCalibrationWarnings'),
    [artifactId]: warnings,
  },
})

const setPointcloudSettingsLoading = state => state.merge({ setPointcloudSettingsLoading: true })
const setPointcloudSettingsSuccess = state => state.merge({ setPointcloudSettingsLoading: false })
const setPointcloudSettingsFailure = state => state.merge({ setPointcloudSettingsLoading: false })

const fixAntennaMismatchLoading = state => state.merge({ fixingAntennaMismatchLoading: true })
const fixAntennaMismatchSuccess = state => state.merge({ fixingAntennaMismatchLoading: false })
const fixAntennaMismatchFailure = state => state.merge({ fixingAntennaMismatchLoading: false })

const updateArtifactPosesLoading = (state, { artifactId }) => state.merge({
  updateArtifactPosesStatus: {
    ...state.get('updateArtifactPosesStatus'),
    [artifactId]: { isLoading: true, error: false },
  },
})
const updateArtifactPosesSuccess = (state, { artifactId }) => state.merge({
  updateArtifactPosesStatus: {
    ...state.get('updateArtifactPosesStatus'),
    [artifactId]: { isLoading: false, error: false, succeed: true },
  },
})
const updateArtifactPosesFailure = (state, { artifactId }) => state.merge({
  updateArtifactPosesStatus: {
    ...state.get('updateArtifactPosesStatus'),
    [artifactId]: { isLoading: false, error: true },
  },
})

export default {
  [ArtifactsActionTypes.PUT_GET_ARTIFACT]: getArtifact,
  [ArtifactsActionTypes.PUT_GET_ARTIFACTS]: getArtifactsReducer,
  [ArtifactsActionTypes.PUT_CREATE_ARTIFACT]: getArtifact,
  [ArtifactsActionTypes.PUT_UPDATE_ARTIFACT]: getArtifact,

  [ArtifactsActionTypes.GET_ARTIFACT_LOADING]: getArtifactLoading,
  [ArtifactsActionTypes.GET_ARTIFACT_FAILURE]: getArtifactFailure,

  [ArtifactsActionTypes.PUT_GET_ARTIFACT_ERROR]: getArtifactError,

  [ArtifactsActionTypes.GET_ARTIFACTS_SUCCESS]: getArtifactsSuccess,
  [ArtifactsActionTypes.GET_ARTIFACTS_FAILURE]: getArtifactsFailure,
  [ArtifactsActionTypes.GET_ARTIFACTS_LOADING]: getArtifactsLoading,

  [ArtifactsActionTypes.PUT_COMPLETE_ARTIFACT]: completeArtifact,
  [ArtifactsActionTypes.PUT_COMPLETE_ARTIFACT_LOADING]: completeArtifactLoading,
  [ArtifactsActionTypes.PUT_COMPLETE_ARTIFACT_ERROR]: completeArtifactError,

  [ArtifactsActionTypes.PUT_LIST_DATA_FILES]: listDataFiles,
  [ArtifactsActionTypes.PUT_LIST_DATA_FILES_LOADING]: listDataFilesLoading,
  [ArtifactsActionTypes.PUT_LIST_DATA_FILES_ERROR]: listDataFilesError,

  [ArtifactsActionTypes.GET_DATA_FILES]: getDataFiles,
  [ArtifactsActionTypes.GET_DATA_FILES_LOADING]: getDataFilesLoading,
  [ArtifactsActionTypes.GET_DATA_FILES_ERROR]: getDataFilesError,

  [ArtifactsActionTypes.CREATE_ARTIFACT_LOADING]: createArtifactLoading,
  [ArtifactsActionTypes.CREATE_ARTIFACT_SUCCESS]: createArtifactSuccess,
  [ArtifactsActionTypes.CREATE_ARTIFACT_FAILURE]: createArtifactFailure,

  [ArtifactsActionTypes.DELETE_ARTIFACT_LOADING]: deleteArtifactLoading,
  [ArtifactsActionTypes.DELETE_ARTIFACT_SUCCESS]: deleteArtifactSuccess,
  [ArtifactsActionTypes.DELETE_ARTIFACT_FAILURE]: deleteArtifactFailure,

  [ArtifactsActionTypes.COPY_ARTIFACT_LOADING]: copyArtifactLoading,
  [ArtifactsActionTypes.COPY_ARTIFACT_SUCCESS]: copyArtifactSuccess,
  [ArtifactsActionTypes.COPY_ARTIFACT_FAILURE]: copyArtifactFailure,

  [ArtifactsActionTypes.UPDATE_ARTIFACT_LOADING]: updateArtifactLoading,
  [ArtifactsActionTypes.UPDATE_ARTIFACT_SUCCESS]: updateArtifactSuccess,
  [ArtifactsActionTypes.UPDATE_ARTIFACT_FAILURE]: updateArtifactFailure,

  [ArtifactsActionTypes.UPDATE_PROJECT_ARTIFACTS_STATE_SUCCESS]: updateProjectArtifactsStateSuccess,
  [ArtifactsActionTypes.UPDATE_PROJECT_ARTIFACTS_STATE_FAILURE]: updateProjectArtifactsStateFailure,

  [ArtifactsActionTypes.SET_COMPLETE_DATA_DIRECTORY_LOADING]: setCompleteDataDirectoryLoading,
  [ArtifactsActionTypes.SET_COMPLETE_DATA_DIRECTORY_SUCCESS]: setCompleteDataDirectorySuccess,
  [ArtifactsActionTypes.SET_COMPLETE_DATA_DIRECTORY_FAILURE]: setCompleteDataDirectoryFailure,

  [ArtifactsActionTypes.GET_CURRENT_ARTIFACT_DATA_SUCCESS]: getCurrentArtifactDataSuccess,

  [ArtifactsActionTypes.DELETE_DATA_FILE_LOADING]: deleteDataFileLoading,
  [ArtifactsActionTypes.DELETE_DATA_FILE_SUCCESS]: deleteDataFileSuccess,
  [ArtifactsActionTypes.DELETE_DATA_FILE_FAILURE]: deleteDataFileFailure,

  [ArtifactsActionTypes.DELETE_DATA_DIRECTORY_FILE_LOADING]: deleteDataDirectoryFileLoading,
  [ArtifactsActionTypes.DELETE_DATA_DIRECTORY_FILE_SUCCESS]: deleteDataDirectoryFileSuccess,
  [ArtifactsActionTypes.DELETE_DATA_DIRECTORY_FILE_FAILURE]: deleteDataDirectoryFileFailure,

  [ArtifactsActionTypes.EXPORT_CALIBRATION_LOADING]: exportCalibrationLoading,
  [ArtifactsActionTypes.EXPORT_CALIBRATION_SUCCESS]: exportCalibrationSuccess,
  [ArtifactsActionTypes.EXPORT_CALIBRATION_FAILURE]: exportCalibrationFailure,

  [ArtifactsActionTypes.VALIDATE_CALIBRATION_LOADING]: validateCalibrationLoading,
  [ArtifactsActionTypes.VALIDATE_CALIBRATION_SUCCESS]: validateCalibrationSuccess,
  [ArtifactsActionTypes.VALIDATE_CALIBRATION_FAILURE]: validateCalibrationFailure,

  [ArtifactsActionTypes.SET_POINTCLOUD_SETTINGS_LOADING]: setPointcloudSettingsLoading,
  [ArtifactsActionTypes.SET_POINTCLOUD_SETTINGS_SUCCESS]: setPointcloudSettingsSuccess,
  [ArtifactsActionTypes.SET_POINTCLOUD_SETTINGS_FAILURE]: setPointcloudSettingsFailure,

  [ArtifactsActionTypes.FIX_ANTENNA_MISMATCH_LOADING]: fixAntennaMismatchLoading,
  [ArtifactsActionTypes.FIX_ANTENNA_MISMATCH_SUCCESS]: fixAntennaMismatchSuccess,
  [ArtifactsActionTypes.FIX_ANTENNA_MISMATCH_FAILURE]: fixAntennaMismatchFailure,

  [ArtifactsActionTypes.UPDATE_ARTIFACT_POSES_LOADING]: updateArtifactPosesLoading,
  [ArtifactsActionTypes.UPDATE_ARTIFACT_POSES_SUCCESS]: updateArtifactPosesSuccess,
  [ArtifactsActionTypes.UPDATE_ARTIFACT_POSES_FAILURE]: updateArtifactPosesFailure,

  [ArtifactsActionTypes.DELETE_ARTIFACTS]: deleteArtifacts,
}
