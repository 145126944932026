import { ImageFileType } from 'modules/upload/file-types'
import { imageExtensions } from 'types/extensions'
import { isFileNameEndsWith } from './baseName'

/**
 * Checks if file is probably an image file
 * By finding file 'name' in suggestions
 * Or by checking file 'type' field
 * Or by check file 'name' extension
 * @param {*} file
 * @param {*} suggestions
 */
export const isFileImage = ({ type, name }, suggestions = []) => {
  const foundInSuggestions = Boolean(suggestions.find(suggestion => Boolean(name.includes(suggestion))))
  return foundInSuggestions
    ? true
    : (type && type.match(ImageFileType)) ||
      imageExtensions.some(extension => isFileNameEndsWith(name, extension)) ||
      (/^cam[0-9]{1,4}-/ig).test((name || ''))
}

export const isDocFile = name => {
  const docFilesExtenstions = ['.doc', '.docx', '.docm', '.pdf', '.txt']
  return docFilesExtenstions.some(extension => isFileNameEndsWith(name, extension))
}
