import React from 'react'
import PropTypes from 'prop-types'
// Material Ui
import { withStyles } from '@material-ui/core/styles'
import { Button, CircularProgress, LinearProgress } from '@material-ui/core'
// Project deps

const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -theme.spacing(1.5),
    marginLeft: -theme.spacing(1.5),
  },
  linearProgress: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  },
})

const LoadingButton = props => {
  const { isLoading, progressVariant = 'circular', classes, buttonClasses, children, containerProps = {}, forwardRef, ...otherProps } = props
  return (
    <div className={classes.wrapper} {...containerProps}>
      <Button ref={forwardRef} classes={buttonClasses} {...otherProps} disabled={otherProps.disabled || isLoading}>
        { children }
        { isLoading && progressVariant === 'circular'
          ? <CircularProgress size={24} className={classes.circularProgress}/>
          : progressVariant === 'linear'
            ? <LinearProgress className={classes.linearProgress}/>
            : null
        }
      </Button>
    </div>
  )
}

LoadingButton.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  progressVariant: PropTypes.oneOf(['circular', 'linear']),
  classes: PropTypes.object,
  containerProps: PropTypes.object,
  forwardRef: PropTypes.func,
}

export default withStyles(styles)(LoadingButton)
