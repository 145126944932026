import { isFileNameEndsWith } from 'utils/baseName'

export const XMLExtensions = [
  '.xml',
]
/**
 * Checks whether the file name of a file looks like a xml file name.
 */
export function isXMLFileName (fileName) {
  return XMLExtensions.some(xmlExtension => isFileNameEndsWith(fileName, xmlExtension))
}

export function isXMLFileType (fileType) {
  return fileType === 'xml'
}
