import { Map } from 'immutable'
export const INITIAL_STATE = Map({
  twoFactorLogin: false,
  twoFactorLoginIsLoading: false,
  tempToken: null,
  users: [],
  projectsStatistic: [],
  user: {},
  crs: {},
  crsLoading: false,
  updateCRSLoading: false,
  deleteCRSLoading: false,
  companyCRS: {},
  companyCRSLoading: {},
  positions: {},
  positionsLoading: {},

  activeUser: {},
  token: null,
  roles: null,
  getMeIsLoading: false,
  getMeErrorMessage: null,
  getActiveUserIsLoading: false,
  getActiveUserErrorMessage: null,
  getUsersIsLoading: false,
  getUsersErrorMessage: null,
  createUserIsLoading: false,
  createUserErrorMessage: null,
  loginIsLoading: false,
  loginErrorMessage: null,
  registerIsLoading: false,
  registerErrorMessage: null,
  requestRecoveryIsLoading: false,
  requestRecoveryErrorMessage: null,
  recoverIsLoading: false,
  recoverIsSuccess: false,
  recoverErrorMessage: null,
  rehydrated: false,

  companyRegisterIsLoading: false,

  confirmEmailIsSuccess: false,
  confirmEmailIsLoading: false,
  confirmEmailErrorMessage: null,

  sendConfirmEmailIsSuccess: false,
  sendConfirmEmailIsLoading: false,
  sendConfirmEmailErrorMessage: null,

  changeUserActivationIsLoading: null,
  changeUserActivationErrorMessage: null,

  updateUserIsLoading: null,
  updateUserErrorMessage: null,
  updateUserSucceed: false,

  acceptTermsFormOpen: false,
  systemTypesByUser: {},
  systemTypesByUserLoading: {},

  subscriptionsByUser: {},
  subscriptionsByUserLoading: {},

  permissionsByUser: {},
  permissionsByUserLoading: {},

  updateCRSDialogOpen: false,
  updateCRSStatus: null,

  passwordNotSet: false,
  isLoggedAs: false,

  totalReleases: 0,
  releases: [],
  releasesIsLoading: false,
})
