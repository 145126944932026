import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  addFiles: ['files', 'artifactId', 'projectId'],
  addDataFiles: ['files'],
  startUpload: ['artifactId', 'autocomplete', 'dataDirectoryId', 'projectId', 'files'],
  startUploadDataFile: ['artifactId'],
  startDropboxUpload: ['artifactId'],
  setBusy: ['busy'],
  prefixFiles: ['s3KeyPrefix', 'files'],
  setTimer: ['artifactId'],

  startPrepare: ['id', 'artifactId'],
  prepareFileDone: ['id', 'chunks', 'dataFileId', 'artifactId', 's3KeyPrefix', 'dataFile', 'artifactId'],
  prepareFileProgress: ['progress', 'id'],
  prepareFileError: ['error', 'id'],
  prepareFilesDone: ['artifactId', 'files'],
  dataFileError: ['id', 'file', 'error'],
  uploadFileError: ['id', 'artifactId', 'total', 'done', 'failed', 'file', 'dataFileId'],
  error: ['total', 'done', 'failed', 'file', 'dataFileId'],

  startTransfer: ['id', 'artifactId'],
  setCancelFunction: ['id', 'userEmitter'],
  chunkUploaded: ['progress'],
  updateUploadRates: ['id', 'duration'],
  imageFailed: ['id'],
  chunkProgress: ['progress', 'chunk', 'id'],
  done: ['result'],

  dataFileDone: ['id', 'dataFile'],
  dataFileChunkDone: ['dataFileId', 'chunkId'],

  allFilesDone: ['artifactId'],
  cancel: ['artifactId'],
  removeAllFiles: ['artifactId'],
  removeFile: ['file'],
  reset: null,
  setArtifactUpdateStatus: ['id', 'artifactId'],
  setDataDirectoryUploadedFiles: ['dataDirectoryId', 'numberOfFiles'],
  setDataDirectoryUploadedFilesSize: ['dataDirectoryId', 'size'],
  setDataDirectoryUpdateSizeTime: ['dataDirectoryId', 'timestamp'],
}, { prefix: 'UPLOAD/' })

export const UploadTypes = Types
export default Creators
