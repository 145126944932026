import React from 'react'
import { Trans } from 'react-i18next'
// import { DatePicker } from 'material-ui-pickers'
// Material UI
// Icons
// Project deps
import {
  cleanJobOptionValues,
  isVector3FormField,
  isFloatFormField,
  isIntegerFormField,
  isCoordinateFormField,
  isCoordinateSystemsFormField,
  isTabsFormField,
  isCesiumMapFormField,
  isCustomCRSFormField,
  isGPSWeekFormField,
} from 'utils/jobOptions'
import { isValueSet } from 'utils/templatedForm'
import { DataType } from 'types/form'

export const valueShouldBeTransformed = (transform, transformOnChangeOf, names) => {
  return transform && transformOnChangeOf && names.find(name => transformOnChangeOf.indexOf(name) !== -1)
}

// This function is used outside of this component to make possible the resetting the fields of the template
export const resetValues = (name, props, prevProps) => {
  const { formTemplate = {}, state = {}, extra = '', actions = {}, extraProps = {}, values, onChange, ...restProps } = props
  const thisProps = prevProps || props
  const names = Array.isArray(name) ? name : [name]

  const reinitializedValues = names.reduce((result, fieldName) => {
    const field = formTemplate[fieldName]
    const { initialValue } = field
    // If initial value was not defined on the field the value will be left empty.
    if (typeof initialValue === 'undefined') {
      return result
    }
    // If the initial value is a function it needs to be evaluated in order to get the desired value.
    if (typeof initialValue === 'function') {
      const calculatedValue = initialValue(state, extra, {
        formTemplate,
        actions,
        extraProps,
        values: result,
        name: fieldName,
        onChange,
        ...restProps,
      })
      // Do not write `undefined` into the state because it would lead to the user seeing an "undefined"
      // in the value of the fields.
      if (typeof calculatedValue === 'undefined') {
        return result
      }
      return {
        ...result,
        [fieldName]: calculatedValue,
      }
    }
    // Otherwise the literal value will be used.
    return {
      ...result,
      [fieldName]: initialValue,
    }
  }, values || {})
  const newValues = Object.keys(reinitializedValues).reduce(
    (result, fieldName) => {
      const field = formTemplate[fieldName]
      const { transform, transformOnChangeOf } = field
      const shouldBeTransformed = valueShouldBeTransformed(transform, transformOnChangeOf, Object.keys(formTemplate))
      if (shouldBeTransformed) {
        return {
          ...result,
          [fieldName]: transform(
            reinitializedValues[fieldName],
            thisProps.state,
            reinitializedValues,
            thisProps.extra,
            formTemplate,
            shouldBeTransformed,
            undefined,
            undefined,
            extraProps,
          ),
        }
      }
      return result
    }, reinitializedValues
  )

  if (onChange && formTemplate) {
    const cleanedValues = cleanJobOptionValues(formTemplate, newValues, state, extra)
    const valid = validateJobOptionsForm(newValues, formTemplate, state, extra, props)
    onChange(cleanedValues, valid, newValues)
  }
}

/**
 * Performs a check for whether a provided string is a valid floating point number.
 * @param value The value to perform the check on.
 * @return `true` if the provided value was a valid floatingpoint number and `false` otherwise.
 */
export function isFloatFormFieldValid (value, state, formValues, formField, options) {
  if (typeof value !== 'string') {
    return false
  }
  const isFloatField = Boolean(value.match(/^[+-]?([0-9]*[.])?[0-9]+$/))
  if (!isFloatField) {
    return false
  }
  if (formField) {
    const { min, max } = formField
    const numberValue = +value
    const minValue = typeof min === 'function' ? min(state, formValues, value, options) : min
    const maxValue = typeof max === 'function' ? max(state, formValues, value, options) : max
    if (numberValue < minValue || numberValue > maxValue) {
      return false
    }
  }
  return true
}

/**
 * Performs a check for whether a provided string is a valid integer number.
 * @param value The value to perform the check on.
 * @return `true` if the provided value was a valid integer number and `false` otherwise.
 */
export function isIntegerFormFieldValid (value, state, formValues, formField, options) {
  if (typeof value !== 'string') {
    return false
  }
  const isIntegerField = Boolean(value.match(/^-?\d+$/))
  if (!isIntegerField) {
    return false
  }
  if (formField) {
    const { min, max } = formField
    const numberValue = +value
    const minValue = typeof min === 'function' ? min(state, formValues, value, options) : min
    const maxValue = typeof max === 'function' ? max(state, formValues, value, options) : max
    if (numberValue < minValue || numberValue > maxValue) {
      return false
    }
  }
  return true
}

/**
 * Performs a check for whether a provided value is a valid coordinate.
 * @param value The value to perform the check on.
 * @return `true` if the provided value was a valid coordinate and `false` otherwise.
 */
export function isCoordinateFormFieldValid (value) {
  if (typeof value !== 'string') {
    return false
  }
  const floatRegex = /^[-]?\d+(\.\d+)?$/g
  return Boolean(value.match(floatRegex))
}

export function getTypeSpecificErrorMessage (value, formField, state, formValues, options) {
  const { dataType, min, max } = formField
  if (isIntegerFormField(dataType) || isGPSWeekFormField(dataType)) {
    if (!isIntegerFormFieldValid(value)) {
      return <Trans i18nKey='options.errors.integer' />
    }
    if (isIntegerFormField(dataType)) {
      const numberValue = +value
      if ('min' in formField || 'max' in formField) {
        const minValue = typeof min === 'function' ? min(state, formValues, value, options) : min
        const maxValue = typeof max === 'function' ? max(state, formValues, value, options) : max
        if (numberValue < minValue) {
          return `Min value is ${minValue}`
        }
        if (numberValue > maxValue) {
          return `Max value is ${maxValue}`
        }
      }
    }
  }
  if (isFloatFormField(dataType) || isVector3FormField(dataType)) {
    if (!isFloatFormFieldValid(value)) {
      return <Trans i18nKey='options.errors.float' />
    }
    if (isFloatFormField(dataType)) {
      const numberValue = +value
      const minValue = typeof min === 'function' ? min(state, formValues, value, options) : min
      const maxValue = typeof max === 'function' ? max(state, formValues, value, options) : max
      if (numberValue < minValue) {
        return `Min value is ${minValue}`
      }
      if (numberValue > maxValue) {
        return `Max value is ${maxValue}`
      }
    }
  }
}

/**
 * Checks whether a form field is optional in the context of the application.
 * @param formField The field to check.
 * @param state The state of the application.
 * @param formValues The current values of the form.
 * @param extra Any extra data which will be used in the lambdas in the definition.
 * @return Whether the field is currently optional or not.
 */
export function isFormFieldOptional (formField, formValues, state, extra, formTemplate, shouldCheckTab = false) {
  const { optional, optionalForTab } = formField
  if (typeof optional === 'function') {
    return shouldCheckTab
      ? typeof optionalForTab === 'function'
        ? optionalForTab(state, formValues, extra, formField, formTemplate)
        : optional(state, formValues, extra, formField, formTemplate)
      : optional(state, formValues, extra, formField, formTemplate)
  }
  return optional
}

/**
 * Returns the error message for a form field.
 * @param value The field value.
 * @param formField The form field.
 * @param formValues All values of the form.
 * @param extra An extra parameter that will be passed to the lambdas.
 * @param state The whole application's state.
 * @return An appropriate error message.
 */
export function getFormFieldErrorMessage (value, formField, formValues, state, extra, formTemplate, options) {
  return (
    isFormFieldOptional(formField, formValues, state, extra, formTemplate, true)
      ? undefined
      : isValueSet(value, formField.dataType)
        ? getTypeSpecificErrorMessage(value, formField, state, formValues, options)
        : <Trans i18nKey='options.errors.required' />
  )
}

/**
 * Returns the error message for a form field.
 * @param value The field value.
 * @param formField The form field.
 * @param formValues All values of the form.
 * @param extra An extra parameter that will be passed to the lambdas.
 * @param state The whole application's state.
 * @return An appropriate error message.
 */
export function getFormFieldLabel (value, formField, formValues, state, extra, formTemplate, options) {
  const { name, label } = formField
  if (label) {
    if (typeof label === 'function') {
      return label(state, formValues, extra)
    }
    return label
  }
  if (name) {
    if (typeof name === 'function') {
      return name(state, formValues, extra)
    }
    return name
  }
  return ''
}

function validateJobOption (value, formField, formValues, state, extra, formTemplate, options) {
  const { dataType, isValid } = formField
  if (isFormFieldOptional(formField, formValues, state, extra, formTemplate)) {
    return true
  }
  if (dataType === DataType.TEXT) {
    if (typeof isValid === 'function') {
      return isValid(state, formValues, extra, options)
    }
    return true
  }
  if (dataType === DataType.TRAJECTORY_AUTOSPLIT || dataType === DataType.CONFIGURE_POINTCLOUD_VIEWER) {
    return value && typeof value === 'object'
  }
  if (isCoordinateSystemsFormField(dataType) ||
      isTabsFormField(dataType) ||
      isCesiumMapFormField(dataType) ||
      isCustomCRSFormField(dataType) ||
      dataType === DataType.IMAGE ||
      dataType === DataType.LAST_CHANGED_TAB ||
      dataType === DataType.TABS_TEMPLATE ||
      dataType === DataType.REF_STATION_POSITION ||
      dataType === DataType.FILELIST_SELECTOR ||
      dataType === DataType.MULTI_SELECTION
  ) {
    return true
  }
  if (!isValueSet(value, dataType)) {
    return false
  }
  if (isIntegerFormField(dataType) || isGPSWeekFormField(dataType)) {
    return isIntegerFormFieldValid(value, state, formValues, formField, options)
  }
  if (isFloatFormField(dataType)) {
    return isFloatFormFieldValid(value, state, formValues, formField, options)
  }
  if (isCoordinateFormField(dataType)) {
    return isCoordinateFormFieldValid(value, state, formValues, formField, options)
  }
  return true
}

/**
 * Validates a full job options form. Will take into account all validations for all possible
 * option types and return whether they are all valid.
 * @param formValues The values the user typed into the form fields.
 * @param options The template from which the form was generated.
 * @return If all values were valid.
 */
export function validateJobOptionsForm (formValues = {}, formTemplate = {}, state = {}, extra, options) {
  const isFormValid = (formValues && formTemplate) ? Object.keys(formTemplate).every(name => {
    const formField = formTemplate[name]
    const value = formValues[name]
    return validateJobOption(value, formField, formValues, state, extra, formTemplate, options)
  }) : false
  return isFormValid
}

export const setInitialValues = props => {
  const { values } = props
  if (typeof values === 'undefined') {
    callOnChange(props, getResetValues(props))
  }
}

/**
 * Cleans and validates the values and calls `onChange`.
*/
export const callOnChange = (props, newValues) => {
  const { state, extra, formTemplate, onChange } = props
  if (onChange && formTemplate) {
    const cleanedValues = cleanJobOptionValues(formTemplate, newValues, state, extra)
    const valid = validateJobOptionsForm(newValues, formTemplate, state, extra, props)
    onChange(cleanedValues, valid, newValues)
  }
}

export const getInitialStateValues = ({ state, extra, formTemplate, actions, extraProps = {}, onChange }) => {
  // When the `extra` property or the template itself changed the values need to be restored to the
  // initial values. Those can be configured in the template as either functions calculating the initial values
  // from the `state` and the `extra` properties, or literal values.
  return Object.keys(formTemplate).reduce(
    (result, fieldName) => {
      const field = formTemplate[fieldName]
      const { initialValue } = field
      // If initial value was not defined on the field the value will be left empty.
      if (typeof initialValue === 'undefined') {
        return result
      }
      // If the initial value is a function it needs to be evaluated in order to get the desired value.
      if (typeof initialValue === 'function') {
        const calculatedValue = initialValue(
          state,
          extra,
          {
            formTemplate,
            actions,
            extraProps,
            values: result,
            name: fieldName,
            onChange,
          })
        // Do not write `undefined` into the state because it would lead to the user seeing an "undefined"
        // in the value of the fields.
        if (typeof calculatedValue === 'undefined') {
          return result
        }
        return {
          ...result,
          [fieldName]: calculatedValue,
        }
      }
      // Otherwise the literal value will be used.
      return {
        ...result,
        [fieldName]: typeof initialValue === 'undefined' ? null : initialValue,
      }
    },
    {}
  )
}

export const getResetValues = (props, thisProps) => {
  const selfProps = thisProps || props
  const { formTemplate, extraProps = {} } = selfProps
  if (typeof formTemplate !== 'object') {
    return {}
  }
  // When the `extra` property or the template itself changed the values need to be restored to the
  // initial values. Those can be configured in the template as either functions calculating the initial values
  // from the `state` and the `extra` properties, or literal values.
  const newValues = getInitialStateValues(selfProps)

  return Object.keys(formTemplate).reduce(
    (result, fieldName) => {
      const field = formTemplate[fieldName]
      const { transform, transformOnChangeOf } = field
      const shouldBeTransformed = valueShouldBeTransformed(transform, transformOnChangeOf, Object.keys(formTemplate))
      if (shouldBeTransformed) {
        return {
          ...result,
          [fieldName]: transform(
            newValues[fieldName],
            selfProps.state,
            newValues,
            selfProps.extra,
            formTemplate,
            shouldBeTransformed,
            undefined,
            undefined,
            extraProps
          ),
        }
      }
      return result
    },
    newValues
  )
}
