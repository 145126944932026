import { isFileNameEndsWith } from 'utils/baseName'

export const DXFExtensions = [
  '.dxf',
]
/**
 * Checks whether the file name of a file looks like a dxf file name.
 */
export function isDXFFileName (fileName) {
  return DXFExtensions.some(dxfExtension => isFileNameEndsWith(fileName, dxfExtension))
}

export function isDXFFileType (fileType) {
  return fileType === 'dxf'
}
