import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  deleteReferenceStationPosition: ['companyId', 'id'],
  deleteReferenceStationPositionLoading: ['id'],
  deleteReferenceStationPositionSuccess: ['companyId', 'id'],
  deleteReferenceStationPositionFailure: ['id', 'errorMessage'],

  updateReferenceStationPosition: ['companyId', 'id', 'data'],
  updateReferenceStationPositionLoading: ['id'],
  updateReferenceStationPositionSuccess: ['companyId', 'id', 'position'],
  updateReferenceStationPositionFailure: ['id', 'errorMessage'],
})

export const PositionsTypes = Types
export default Creators
