import i18n from 'i18n'
export const BackendPositionCoordinates = {
  LATITUDE: 'lat',
  LONGITUDE: 'lon',
  ALTITUDE: 'altitude',
}

export const FrontendPositionCoordinates = {
  LATITUDE: 'lat',
  LONGITUDE: 'lon',
  ALTITUDE: 'z',
}

export const LatLonPositionCoordinatesLabel = {
  LATITUDE: i18n.t('coordinates.x'),
  LONGITUDE: i18n.t('coordinates.y'),
  ALTITUDE: i18n.t('coordinates.z'),
}

export const TransformedPositionCoordinatesLabel = {
  LATITUDE: i18n.t('coordinates.transformed.x'),
  LONGITUDE: i18n.t('coordinates.transformed.y'),
  ALTITUDE: i18n.t('coordinates.transformed.z'),
}

const handleCoordinate = coordinate => {
  return coordinate === 'inf' || !coordinate
    ? 0
    : +coordinate
}

export const convertRawPosition = position => {
  const {
    [BackendPositionCoordinates.LATITUDE]: lat,
    [BackendPositionCoordinates.LONGITUDE]: lon,
    [BackendPositionCoordinates.ALTITUDE]: altitude,
  } = position
  return {
    [FrontendPositionCoordinates.LONGITUDE]: handleCoordinate(lon),
    [FrontendPositionCoordinates.LATITUDE]: handleCoordinate(lat),
    [FrontendPositionCoordinates.ALTITUDE]: handleCoordinate(altitude),
  }
}
