import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  registerIsLoading: false,
  registerErrorMessage: null,
  // All available companies
  companies: [],
  getCompaniesIsLoading: false,
  getCompaniesErrorMessage: null,

  // Current company users
  users: [],
  getUsersIsLoading: false,
  getUsersErrorMessage: null,

  // Current company positions
  positions: {},
  positionsLoading: {},
  addPositionIsLoading: false,
  deletePosition: {},

  companyProjects: {},
  // Current company projects
  projects: [],
  getProjectsIsLoading: {},
  getProjectsErrorMessage: null,

  // Current company info
  currentCompany: {},
  getCompanyIsLoading: false,

  // Last generated invite url
  inviteUrl: {},
  generateInviteUrlIsLoading: {},
  generateInviteUrlErrorMessage: null,

  deleteCompanyIsLoading: false,
  deleteCompanyErrorMessage: null,

  systemTypesByCompany: {},
  systemTypesByCompanyLoading: {},

  subscriptionsByCompany: {},
  subscriptionsByCompanyLoading: {},

  permissionsByCompany: {},
  permissionsByCompanyLoading: {},

  checkTokenIsLoading: {},
  isTokenValid: {},

  // Data usage by company
  dataUsageByCompany: {},
  getCompanyDataUsageIsLoading: {},
})
