import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getRecommendedWizardCRS: ['id', 'data'],
  getRecommendedWizardCRSLoading: ['id'],
  getRecommendedWizardCRSSuccess: ['id', 'crs'],
  getRecommendedWizardCRSFailure: ['id'],

  getRecommendedWizardGeoids: ['id', 'lat', 'lng', 'datum'],
  getRecommendedWizardGeoidsLoading: ['id', 'lat', 'lng', 'datum'],
  getRecommendedWizardGeoidsSuccess: ['id', 'lat', 'lng', 'datum', 'geoids'],
  getRecommendedWizardGeoidsFailure: ['id', 'lat', 'lng', 'datum'],
})

export const ProjectCRSWizardTypes = Types
export default Creators
