import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { GrantsTypes } from './actions'

function filterGrantPermission (newGrant, state) {
  return state.get('grantPermissions').filter(({ grant }) =>
    grant.userId !== newGrant.userId ||
    grant.grantType !== newGrant.grantType ||
    grant.context !== newGrant.context,
  )
}

// Check grant
export const checkGrantLoading = (state, { grant, key }) => {
  const grantPermissions = filterGrantPermission(grant, state)
  return state.merge({
    checkGrantLoading: {
      ...state.get('checkGrantLoading'),
      [key]: true,
    },
    checkGrantErrorMessage: null,
    grantPermissions: [
      ...grantPermissions, {
        grant,
        isLoading: true,
      },
    ],
  })
}

export const checkGrantSuccess = (state, { grant, allowed, key }) => {
  const grantPermissions = filterGrantPermission(grant, state)
  return state.merge({
    checkGrantLoading: {
      ...state.get('checkGrantLoading'),
      [key]: false,
    },
    lastTimeGrantsChecked: {
      ...state.get('lastTimeGrantsChecked'),
      [key]: new Date().getTime(),
    },
    checkGrantErrorMessage: null,
    grantPermissions: [
      ...grantPermissions,
      {
        grant,
        allowed,
        isLoading: false,
      },
    ],
  })
}

export const checkGrantFailure = (state, { errorMessage, key }) =>
  state.merge({
    checkGrantLoading: {
      ...state.get('checkGrantLoading'),
      [key]: false,
    },
    checkGrantErrorMessage: errorMessage,
  })

export const checkGrantsLoading = (state, { key }) => {
  return state.merge({
    checkGrantsLoading: {
      ...state.get('checkGrantsLoading'),
      ...(key && { [key]: true }),
    },
  })
}

export const checkGrantsSuccess = (state, { grants, key }) => {
  const firstGrant = grants[0].grant
  const grantPermissions = state.get('grantPermissions').filter(({ grant }) =>
    grant.userId !== firstGrant.userId ||
    grant.grantType !== firstGrant.grantType ||
    grant.context !== firstGrant.context,
  )
  return state.merge({
    checkGrantLoading: {
      ...state.get('checkGrantLoading'),
      [key]: false,
    },
    checkGrantErrorMessage: null,
    checkGrantsLoading: {
      ...state.get('checkGrantsLoading'),
      ...(key && { [key]: false }),
    },
    grantPermissions: [
      ...grantPermissions,
      ...grants.map(grant => ({ ...grant, isLoading: false })),
    ],
  })
}

export const checkGrantsFailure = (state, { key, errorMessage }) => {
  return state.merge({
    checkGrantLoading: {
      ...state.get('checkGrantLoading'),
      [key]: false,
    },
    checkGrantErrorMessage: errorMessage,
    checkGrantsLoading: {
      ...state.get('checkGrantsLoading'),
      ...(key && { [key]: false }),
    },
  })
}

// List grants
export const getGrantsLoading = (state, { userId }) =>
  state.merge({
    getGrantsLoading: true,
    getGrantsErrorMessage: null,
  })

export const getGrantsSuccess = (state, { grants, navlabUsed, navlabMax, userId }) => {
  const grantStateItem = { grants, navlabUsed, navlabMax }
  const grantsForUser = {
    ...state.get('grantsForUser'),
    [userId]: grantStateItem,
  }
  return state.merge({
    getGrantsLoading: false,
    getGrantsErrorMessage: null,
    grantsForUser,
  })
}

export const getGrantsFailure = (state, { errorMessage, userId }) =>
  state.merge({
    getGrantsLoading: false,
    getGrantsErrorMessage: errorMessage,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [GrantsTypes.CHECK_GRANT_LOADING]: checkGrantLoading,
  [GrantsTypes.CHECK_GRANT_SUCCESS]: checkGrantSuccess,
  [GrantsTypes.CHECK_GRANT_FAILURE]: checkGrantFailure,

  [GrantsTypes.CHECK_GRANTS_LOADING]: checkGrantsLoading,
  [GrantsTypes.CHECK_GRANTS_SUCCESS]: checkGrantsSuccess,
  [GrantsTypes.CHECK_GRANTS_FAILURE]: checkGrantsFailure,

  [GrantsTypes.GET_GRANTS_LOADING]: getGrantsLoading,
  [GrantsTypes.GET_GRANTS_SUCCESS]: getGrantsSuccess,
  [GrantsTypes.GET_GRANTS_FAILURE]: getGrantsFailure,
})
