import kmlParser from 'js-kml-parser'
import JSZip from 'jszip'
import { isKMLFileName } from './kml'
import { getFileFromDropbox } from './utils'
/**
 * Checks whether the file name of a file looks like a polygon file name.
 * This is done by looking at the extension and checking whether it is '.kml' | '.kmz'.
 */
export function isPolygonFileName (fileName) {
  return isKMLFileName(fileName)
}

export function isPolygonFileType (fileType) {
  return fileType === 'polygon'
}

/**
 * To read KMZ we need to unzip it and find KML file in it
 * @param {File} file kmz file
 * @returns {Promise}
 */
async function parseKMZ (file) {
  const getKmlDom = kmzFile => {
    const zip = new JSZip()
    return zip.loadAsync(kmzFile)
      .then(zip => {
        let kmlDom = null
        zip.forEach((relPath, file) => {
          if (relPath.endsWith('.kml') && kmlDom === null) {
            kmlDom = file.async('string').then(xmlString => xmlString)
          }
        })
        if (kmlDom) {
          return { okay: true, data: kmlDom }
        } else {
          return { okay: false, error: 'No kml file found' }
        }
      })
      .catch(e => ({ okay: false, error: 'Can\t parse KMZ file. Seems file is not a ZIP file' }))
  }
  const kmlResult = await getKmlDom(file)
  return kmlResult
}

function kmlStringToGeoJSON (kmlString) {
  try {
    const geoJSON = kmlParser.toGeoJson(kmlString)
    const logs = []
    let okay = true
    let parseResult = {}
    const polygonFeatures = geoJSON.features.filter(feature => feature.geometry.type === 'Polygon')
    if (polygonFeatures.length > 1) {
      okay = false
      logs.push({ level: 'error', message: 'Currently LiDARMill supports KML/KMZ files with only one polygon' })
    } else if (polygonFeatures.length <= 0) {
      okay = false
      logs.push({ level: 'error', message: 'No polygon found in the uploaded file!' })
    } else {
      parseResult = {
        coordinates: polygonFeatures[0].geometry.coordinates,
      }
    }

    return {
      okay: okay,
      fileType: 'polygon',
      result: parseResult,
      log: logs,
    }
  } catch (error) {
    return {
      okay: false,
      fileType: 'polygon',
      log: [{
        level: 'error',
        message: error,
      }],
    }
  }
}
/**
 * KML file can be in .kml or .kmz format and thus needs to be parsed differently
 * @param {File} file kmz file
 * @returns {Promise}
 */
export async function parsePolygonFile (file, blob) {
  return new Promise(resolve => {
    const filename = file.name.toLowerCase()
    if (filename.endsWith('.kmz')) {
      parseKMZ(file || blob)
        .then(result => {
          if (result.okay) {
            resolve(result.data.then(result => kmlStringToGeoJSON(result)))
          } else {
            resolve({
              okay: false,
              log: [{
                level: 'error',
                message: result.error,
              }],
              fileType: 'polygon',
            })
          }
        })
    } else if (filename.endsWith('.kml')) {
      const reader = new FileReader()
      reader.onload = function (event) {
        resolve(kmlStringToGeoJSON(event.target.result))
      }
      reader.onerror = () => {
        resolve({
          okay: false,
          log: [{
            level: 'error',
            message: `Error reading file "${file.name}".`,
          }],
          fileType: 'polygon',
        })
      }
      reader.readAsText(file || blob)
    }
  })
}

export async function parseDropboxPolygonFile (file) {
  return getFileFromDropbox(file, undefined, undefined, parsePolygonFile)
}
