import createSagaMiddleware from 'redux-saga'
import { createStore, compose, applyMiddleware } from 'redux'
import history from 'browserHistory'
import { persistReducer, persistStore } from 'redux-persist'
import createFilter from 'redux-persist-transform-filter-immutable'
import immutableTransform from 'redux-persist-transform-immutable'
import logger from 'redux-logger'
import artifactRefresherMiddleware from 'middlewares/artifactRefresher'
import appStateMiddleware from 'middlewares/appStateMiddleware'
import persistMiddleware from 'middlewares/persist'

import { rootReducer as modulesReducers, rootSaga } from 'modules/index'

/**
 * This import defaults to localStorage for web and AsyncStorage for react-native.
 *
 * Keep in mind this storage *is not secure*. Do not use it to store sensitive information
 * (like API tokens, private and sensitive data, etc.).
 *
 * If you need to store sensitive information, use redux-persist-sensitive-storage.
 * @see https://github.com/CodingZeal/redux-persist-sensitive-storage
 */
import storage from 'redux-persist/lib/storage'
import { uploadMiddleware } from 'modules/upload/middleware'
import { importWizardMiddleware } from 'modules/importWizard/middleware'
import config, { Env } from 'config'

const persistConfig = {
  transforms: [
    createFilter('users', ['token', 'user']),
    /**
     * This is necessary to support immutable reducers.
     * @see https://github.com/rt2zz/redux-persist-transfwantorm-immutable
     */
    immutableTransform(),
  ],
  key: 'root',
  storage: storage,
  /**
   * Blacklist state that we do not need/want to persist
   */
  blacklist: [
    // 'auth',
    'url',
    'statistics',
    'importWizard',
    // 'upload',
    'grants',
    'dropboxToken',
  ],
  whitelist: [
    // 'app',
    'users',
  ],
}

export default () => {
  // Allow redux-devtools only for local development
  const composeEnhancers = config.ENV === Env.LOCAL
    ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 20 })
      : compose)
    : compose

  // Connect the sagas to the redux store
  const sagaMiddleware = createSagaMiddleware({
    // This is done to catch all uncatched errors
    // See, for example: src/modules/projects/sagas => createPipeline
    onError: e => {
      console.log(e)
    },
  })
  const middleware = [
    persistMiddleware,
    importWizardMiddleware,
    appStateMiddleware,
    uploadMiddleware,
    artifactRefresherMiddleware,
    sagaMiddleware,
  ]
  if (config.ENV === Env.LOCAL) {
    middleware.push(logger)
  }

  // Redux persist
  const persistedReducer = persistReducer(persistConfig, modulesReducers)
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)))
  const persistor = persistStore(store)

  if (module.hot) {
    module.hot.accept('./modules/', () => {
      const nextRootReducer = require('./modules/index').rootReducer
      store.replaceReducer(nextRootReducer)
    })
  }

  // Kick off the root saga
  sagaMiddleware.run(rootSaga)

  return { store, persistor, history }
}
