import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { DialogContent, TextField, Typography } from '@material-ui/core'
import AppActions from 'modules/app/actions'
import { axiosAccounts } from 'utils/axios'
import { getErrorMessage } from 'utils/api'
import LoadingButton from 'components/LoadingButton'
import { Trans } from 'react-i18next'
import { toast } from 'react-toastify'
import ClosableDialog from 'components/ClosableDialog'
import { getLoggedUser } from 'modules/users/selectors'

const useStyles = makeStyles(theme => ({
  submit: {
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  recover: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(4),
  },
}))

const checkToken = async token => {
  try {
    await axiosAccounts.post('/verify_totp', { token })
    return { okay: true }
  } catch (e) {
    const message = getErrorMessage(e)
    return { okay: false, message }
  }
}

const CheckToken = props => {
  const classes = useStyles()
  const user = useSelector(getLoggedUser)
  const open = useSelector(state => state.app.get('checkTokenDialogOpen'))
  const callback = useSelector(state => state.app.get('checkTokenCallback'))
  const [token, setToken] = useState()
  const [isLoading, setIsLoading] = useState()
  const dispatch = useDispatch()
  const onClose = () => dispatch(AppActions.closeCheckTokenDialog())
  const onChange = e => {
    const { value } = e.target
    setToken(value)
  }
  const onAccept = () => {
    onClose()
    if (callback) {
      callback()
    }
  }
  const onCheckToken = () => {
    setIsLoading(true)
    checkToken(token).then(({ okay, message }) => {
      if (okay) {
        onAccept()
      } else {
        toast.error(message)
      }
      setIsLoading(false)
    })
  }
  useEffect(() => {
    if (open && !user.use_2fa) {
      onAccept()
    }
    setToken('')
  }, [open])
  return (
    <ClosableDialog open={open} onClose={onClose}>
      <DialogContent className={classes.content}>
        <Typography variant='body1' className={classes.recover}>
          To approve your actions we need you to enter the code from the two-factor app on your mobile device.
        </Typography>
        <TextField
          margin='normal'
          fullWidth
          variant='outlined'
          label={<Trans i18nKey='login.code' />}
          value={token}
          onChange={onChange}
          autoFocus
          InputProps={{
            name: 'code',
            id: 'code',
          }}
        />
        <LoadingButton
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          disabled={!token}
          className={classes.submit}
          isLoading={isLoading}
          onClick={onCheckToken}
        >
          <Trans i18nKey='login.verifyButton' />
        </LoadingButton>
      </DialogContent>
    </ClosableDialog>
  )
}

export default CheckToken
