import { call, put, takeLatest, fork, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import axios from 'utils/axios'
import { JobRunState } from 'types/jobRuns'
import { getErrorMessage, showErrorMessage } from 'utils/api'
import { isOutputJobIO } from 'utils/jobIOs'
import { getLoggedUser as getUser } from 'modules/users/selectors'
// import { getCurrentProjectDataForPipeline } from '../projects/sagas'
// Local deps
import PipelinesActions, { PipelinesTypes } from './actions'
import ArtifactsActions from '../artifacts/actions'
import { convertRawRecursivePipeline } from 'types/pipelines'
// Sagas
// https://api.develop.lidarmill.com/users/0dc9276f-51e0-497d-9ab2-dafcb8baacdf/Pipelines
// Retrieves individual pipeline by pipelineId
function * getPipeline ({ pipelineId, onSuccess, onError }) {
  yield put(PipelinesActions.getPipelineLoading())
  try {
    const { data: { data: pipeline } } = yield call(axios.get, `/pipelines/${pipelineId}`)
    yield put(PipelinesActions.getPipelineSuccess(pipelineId, pipeline))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    if (onError) {
      onError()
    }
    yield put(PipelinesActions.getPipelineFailure(getErrorMessage(e)))
  }
}

// Retrieves a list of pipelines with parameters (per_page, query, ..etc)
function * getPipelinesQueried ({ per_page, page, query }) {
  yield put(PipelinesActions.getPipelinesQueriedLoading())
  try {
    const [userId] = yield select(state => [getUser(state).id])
    let url = `users/${userId}/pipelines?per_page=${per_page}&page=${page}`
    const defaultSort = '&sort=created&order=desc'
    if (query) {
      const { sort, filter } = { sort: query.sort || defaultSort, filter: query.filter || '' }
      url = `${url}${sort}${filter}`
    } else {
      url = `${url}${defaultSort}`
    }
    const { data: { data: pipelines, pagination } } = yield call(axios.get, url)
    yield put(PipelinesActions.getPipelinesQueriedSuccess(pipelines, pagination))
  } catch (e) {
    yield put(PipelinesActions.getPipelinesQueriedFailure(getErrorMessage(e)))
  }
}

// Get the details of job run
export function * getJobRun ({ jobRunId, pipelineId, withLoading = true }) {
  // Check if 'Loading' status should be updated
  if (withLoading) {
    yield put(PipelinesActions.getJobRunLoading(jobRunId))
  }
  try {
    const { data: { data: jobRun } } = yield call(axios.get, `/job_runs/${jobRunId}`)
    yield put(PipelinesActions.getJobRunSuccess(jobRunId, jobRun, pipelineId))
  } catch (e) {
    yield put(PipelinesActions.getJobRunFailure(jobRunId, getErrorMessage(e)))
  }
}

// Get the pipeline id by job run
export function * getPipelineIdByJobRun ({ jobRunId, onSuccess, onError }) {
  yield put(PipelinesActions.getPipelineIdByJobRunLoading())
  try {
    const { data: { data: jobRun } } = yield call(axios.get, `/job_runs/${jobRunId}`)
    const { data: { data: job } } = yield call(axios.get, `/jobs/${jobRun.job.id}`)
    const { pipeline } = job
    yield put(PipelinesActions.getPipelineIdByJobRunSuccess(pipeline))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    if (onError) {
      onError()
    }
    yield put(PipelinesActions.getPipelineIdByJobRunFailure(getErrorMessage(e)))
  }
}

// Cancel the job run
export function * cancelJobRun ({ jobRunId, pipelineId }) {
  yield put(PipelinesActions.getJobRunLoading(jobRunId))
  try {
    const args = { job_run_state: JobRunState.CANCELING }
    const { data: { data: jobRun } } = yield call(axios.put, `/job_runs/${jobRunId}`, args)
    // yield put(PipelinesActions.cancelJobRunSuccess())
    yield put(PipelinesActions.getJobRunSuccess(jobRunId, jobRun, pipelineId))
  } catch (e) {
    yield put(PipelinesActions.getJobRunSuccess(jobRunId, getErrorMessage(e)))
  }
}

// Retry the job
export function * retryJob ({ jobId, pipelineId, options }) {
  yield put(PipelinesActions.retryJobLoading(jobId))
  try {
    const url = `/jobs/${jobId}/job_runs`
    const body = { options }
    const { data: { data: jobRun } } = yield call(axios.post, url, body)
    yield put(PipelinesActions.retryJobSuccess(jobId, jobRun, pipelineId))
  } catch (e) {
    showErrorMessage(e)
    yield put(PipelinesActions.retryJobFailure(jobId, getErrorMessage(e)))
  }
}

// Update the pipeline name
export function * updatePipeline ({ pipelineId, name }) {
  yield put(PipelinesActions.updatePipelineLoading(pipelineId))
  try {
    const body = { name }
    yield call(axios.post, `/pipelines/${pipelineId}`, body)
    toast.success(i18next.t('toast.pipeline.updateSuccess'))
    yield put(PipelinesActions.updatePipelineSuccess(pipelineId, name))
  } catch (e) {
    toast.success(i18next.t('toast.pipeline.updateError'))
    yield put(PipelinesActions.updatePipelineFailure(pipelineId, getErrorMessage(e)))
  }
}

// Delete the pipeline both with all output artifacts
export function * deletePipeline ({ pipelineId }) {
  yield put(PipelinesActions.deletePipelineLoading(pipelineId))
  try {
    const { data: { data: pipelineData } } = yield call(axios.get, `/pipelines/${pipelineId}/recursive`)
    yield call(axios.delete, `/pipelines/${pipelineId}`)
    const { jobs } = convertRawRecursivePipeline(pipelineData)
    const allOutputArtifacts = jobs.reduce((allArtifacts, job) => [
      ...allArtifacts,
      ...job.job_ios
        .filter(job_io => isOutputJobIO(job_io.job_io_type))
        .map(job_io => job_io.artifact.id),
    ], [])
    yield put(ArtifactsActions.deleteArtifacts(allOutputArtifacts))
    toast.success(i18next.t('toast.pipeline.deleteSuccess'))
    yield put(ArtifactsActions.deleteArtifacts(allOutputArtifacts))
    yield put(PipelinesActions.deletePipelineSuccess(pipelineId))
  } catch (e) {
    toast.error(i18next.t('toast.pipeline.deleteError'))
    yield put(PipelinesActions.deletePipelineFailure(pipelineId, getErrorMessage(e)))
  }
}

function * getPipelineWatcher () {
  yield takeLatest(PipelinesTypes.GET_PIPELINE, getPipeline)
}

function * getPipelinesQueriedWatcher () {
  yield takeLatest(PipelinesTypes.GET_PIPELINES_QUERIED, getPipelinesQueried)
}

function * getJobRunWatcher () {
  yield takeLatest(PipelinesTypes.GET_JOB_RUN, getJobRun)
}

function * cancelJobRunWatcher () {
  yield takeLatest(PipelinesTypes.CANCEL_JOB_RUN, cancelJobRun)
}

function * retryJobWatcher () {
  yield takeLatest(PipelinesTypes.RETRY_JOB, retryJob)
}

function * updatePipelineWatcher () {
  yield takeLatest(PipelinesTypes.UPDATE_PIPELINE, updatePipeline)
}

function * deletePipelineWatcher () {
  yield takeLatest(PipelinesTypes.DELETE_PIPELINE, deletePipeline)
}

function * getPipelineIdByJobRunWatcher () {
  yield takeLatest(PipelinesTypes.GET_PIPELINE_ID_BY_JOB_RUN, getPipelineIdByJobRun)
}

export default function * root () {
  yield fork(getPipelineWatcher)
  yield fork(getPipelinesQueriedWatcher)
  yield fork(getPipelineIdByJobRunWatcher)
  yield fork(getJobRunWatcher)
  yield fork(cancelJobRunWatcher)
  yield fork(retryJobWatcher)
  yield fork(deletePipelineWatcher)
  yield fork(updatePipelineWatcher)
  // yield fork(updateCurrentPipelineDataWatcher)
}
