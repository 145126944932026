import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// Project deps
import { getCompanyId } from 'utils/company'
import { getLoggedUser } from 'modules/users/selectors'
// Local deps
import Layout from './Layout'

/**
 * Renders the layout with the default values
 */
const DefaultLayout = props => {
  const { activeCompanyId, children, ...otherProps } = props
  return (
    <Layout
      activeCompanyId={activeCompanyId}
      {...otherProps}
    >
      {children}
    </Layout>
  )
}

const mapStateToProps = state => ({
  activeCompanyId: getCompanyId(getLoggedUser(state)),
})

DefaultLayout.propTypes = {
  activeCompanyId: PropTypes.string,
  children: PropTypes.any,
}

export default connect(mapStateToProps)(DefaultLayout)
