import { path, omit } from 'ramda'
import i18n from 'i18n'
// Material UI
// Project deps
import { toDecimalYear, fromDecimalYear } from 'utils/dateTime'
import {
  getHorizontalCoordinateSystems,
  getVerticalCoordinateSystems,
  getCompoundCoordinateSystems,
  getUnits,
  getGeoids,
} from 'modules/app/selectors'
import {
  coordinateFieldDisabled,
  coordinateSystemFieldDisabled,
  isCustomAnalyzeSelected,
  getTransformedField,
} from '../utils'
import {
  getCoordinateSystemLabel,
  getCoordinateSystemLabelWithType,
  isCompoundGeocentricCRS,
  isGeographic3DCRS,
  getBackendString,
  isCRSFieldCompound,
} from 'utils/coordinateSystem'
import { getGeoidCRSSource, getGeoidLabel } from 'utils/geoid'
import { DataType } from 'types/form'
// Local deps
import { CRSFields, CRSTag, crsTabNames, UnitName, tabsMap } from './constants'
import {
  callWithDelay,
  renderCRSWarning,
  getPropsForCRSOptions,
  disableHUnits,
  disableGeoid,
  getCompoundCRSGrid,
  getCompoundCRSVUnit,
  onGetPosition,
  getTabTemplate,
  getCRSOptions,
  isTabTemplateValid,
  getTransformOptions,
  getCoordinateType,
  resetCoordinates,
  isCRSFieldOptional,
  isCRSFieldOptionalForLastChangedTab,
  isVerticalUnitsOptional,
  renderVerticalCRSWarning,
  getCustomCRSValue,
  gridBackendTransform,
  getGridValue,
  checkUnitsWithCustomCRS,
  backendCRSFieldTransform,
  unitBackendTransform,
  onGetTransformedPoints,
} from './utils'
import { Coordinates, CoordinateTypes, MapBackendNameToFrontend, REF_STATION_BACKEND_NAME } from 'templates/constants'

export const getVerticalCRSLabel = crs => {
  const baseLabel = getCoordinateSystemLabel(crs)
  return isGeographic3DCRS(crs) ? i18n.t('crs.vertical.ellipsoidalHeight', { crs: baseLabel }) : baseLabel
}

const getTransformedUnit = (state, analyzeData, unitFieldName) => {
  const value = analyzeData[unitFieldName] || null
  if (value) return (getUnits(state) || []).find(crs => `${crs.code}` === `${value.code}`)
  return value
}

const CRSMapToFunc = {
  [CRSFields.V_CRS]: getVerticalCoordinateSystems,
  [CRSFields.H_CRS]: getHorizontalCoordinateSystems,
  [CRSFields.C_CRS]: getCompoundCoordinateSystems,
}
const getTransformedCRS = (state, analyzeData, crsFieldName) => {
  const value = analyzeData[crsFieldName] || null
  if (value) {
    return CRSMapToFunc[crsFieldName](state).find(crs => `${crs.code}` === `${value.code}`)
  }
  return value
}

export const FieldTemplates = {
  [CRSFields.TAB]: {
    // optional: coordinateFieldDisabled,
    dataType: DataType.TABS,
    tag: CRSTag.TAB,
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    invisible: (state, formValues) => !isCustomAnalyzeSelected(formValues),
    initialValue: (state, formValues, options) => {
      if (coordinateFieldDisabled(state, formValues)) {
        return -1
      }
      const { extraProps = {} } = options
      const { projectCRS } = extraProps
      if (projectCRS && projectCRS[CRSFields.C_CRS]) {
        const crs = projectCRS[CRSFields.C_CRS]
        if (!isCRSFieldCompound(crs)) {
          return 2
        }
        return 0
      }
      return 1
    },
  },
  /*
  [CRSFields.NORMALIZE_AXIS_ORDER]: {
    name: MapBackendNameToFrontend.normalize_axis_order,
    dataType: DataType.BOOLEAN,
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    optional: true,
    optionalForTab: true,
    invisible: true,
    invisibleForTab: true,
    initialValue: false,
    tooltip: MapBackendNameToFrontend.tooltips.normalize_axis_order,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  */
  [CRSFields.GRID]: {
    name: MapBackendNameToFrontend.geoid,
    tag: CRSTag.GEOID,
    dataType: DataType.GEOID_AUTOCOMPLETE,
    invisible: true,
    extraRender: (state, values, extra, propOptions) => null,
    /*
    extraRender: (state, values, extra, propOptions) => {
      const { name } = propOptions
      const geoid = values[name]
      const position = {
        longitude: +values[Coordinates.LONGITUDE],
        latitude: +values[Coordinates.LATITUDE],
      }
      if (position && geoid) {
        const isProjectPositionValid = isCoordinateInBounds(position, geoid.bound)
        if (!isProjectPositionValid) {
          return <Warning variant='warning'>
            The entered coordinates are not within the selected geoid
          </Warning>
        }
      }
      return null
    },
    */
    initialValue: null,
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    options: state => getGeoids(state),
    displayTemplate: geoid => getGeoidLabel(geoid),
    searchTemplate: geoid => getGeoidLabel(geoid),
    getValue: (state, value, values, extra) => value ? getGeoidLabel(value) : '',
    optional: true,
    disabled: (state, formValues, extra) =>
      coordinateSystemFieldDisabled(state, formValues, extra) ||
      disableGeoid(state, formValues, extra),
    backendTransform: backendCRSFieldTransform(gridBackendTransform),
    transformOnChangeOf: ['analyze'],
    transform: (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData, extraProps) => {
      const { useProjectCRS } = extraProps
      if (useProjectCRS) return original
      if (name === 'analyze') {
        return getTransformedField(
          (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
            getGridValue(state, analyzeData[CRSFields.GRID]),
        )(original, state, formValues, extra, formTemplate, name, oldValues, analyzeData)
      }
      return original
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  [CRSFields.GEOID_CRS]: {
    dataType: DataType.STRING,
    optional: true,
    optionalForTab: true,
    invisible: true,
    invisibleForTab: true,
    initialValue: null,
    transformOnChangeOf: [CRSFields.GRID],
    transform: (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
      const geoid = formValues[CRSFields.GRID]
      if (geoid) {
        return getBackendString(getGeoidCRSSource(geoid))
      }
      return null
    },
    backendTransform: backendCRSFieldTransform(original => original),
  },
  /*
  [CRSFields.IS_ELLIPSOIDAL]: {
    dataType: DataType.BOOLEAN,
    variant: 'outlined',
    optional: true,
    optionalForTab: true,
    invisible: true,
    invisibleForTab: true,
    initialValue: false,
  },
  */
  [CRSFields.EPOCH]: {
    name: MapBackendNameToFrontend.epochOld,
    tag: CRSTag.EPOCH,
    dataType: DataType.DATETIME,
    invisible: true,
    disabled: (state, formValues) => coordinateFieldDisabled(state, formValues),
    initialValue: null,
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    format: value => typeof value === 'string' ? value : toDecimalYear(value),
    reverseFormat: value => fromDecimalYear(value),
    backendTransform: (original, state, formValues) => {
      return original
        ? typeof original === 'string'
          ? original
          : toDecimalYear(original)
        : null
    },
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => analyzeData[CRSFields.EPOCH] || null,
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  [CRSFields.H_CRS]: {
    name: MapBackendNameToFrontend.crs_h,
    tag: CRSTag.CRS,
    dataType: DataType.AUTOCOMPLETE,
    invisible: true,
    optionProps: (state, values, extra, propOptions) => getPropsForCRSOptions(state, values, extra, propOptions),
    extraRender: (state, values, extra, propOptions) => renderCRSWarning(state, values, extra, propOptions),
    optional: (state, values, extra, option, formTemplate) => isCRSFieldOptional(state, values, extra, formTemplate, 1),
    optionalForTab: (state, values, extra, option, formTemplate) => isCRSFieldOptionalForLastChangedTab(state, values, extra, formTemplate, 1),
    disabled: (state, formValues) => coordinateSystemFieldDisabled(state, formValues),
    initialValue: null,
    options: state => getHorizontalCoordinateSystems(state),
    displayTemplate: datum => getCoordinateSystemLabelWithType(datum),
    searchTemplate: datum => getCoordinateSystemLabelWithType(datum),
    getValue: (state, value, values, extra) => value ? getCoordinateSystemLabelWithType(value) : '',
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    backendTransform: backendCRSFieldTransform(getCRSOptions),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        getTransformedCRS(state, analyzeData, CRSFields.H_CRS),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 9,
      lg: 9,
    },
  },
  /*
  [CRSFields.ENABLE_V_CRS]: {
    name: 'Ellipsoidal height',
    tag: CRSTag.ENABLE_V_CRS,
    dataType: DataType.BOOLEAN,
    invisible: true,
    initialValue: false,
    sendToBackend: false,
    onChange: onChangeCRSField,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  */
  [CRSFields.V_CRS]: {
    name: MapBackendNameToFrontend.crs_v,
    tag: CRSTag.CRS,
    dataType: DataType.AUTOCOMPLETE,
    invisible: true,
    // values[CRSFields.ENABLE_V_CRS] ||
    optional: (state, values, extra, option, formTemplate) => isCRSFieldOptional(state, values, extra, formTemplate, 1) ||
    Boolean(values[CRSFields.GRID]),
    optionalForTab: (state, values, extra, option, formTemplate) => isCRSFieldOptionalForLastChangedTab(state, values, extra, formTemplate, 1),
    // options: getVerticalCRSOptions,
    optionProps: (state, values, extra, propOptions) => getPropsForCRSOptions(state, values, extra, propOptions),
    extraRender: (state, values, extra, propOptions) => renderVerticalCRSWarning(state, values, extra, propOptions),
    disabled: (state, formValues) => coordinateSystemFieldDisabled(state, formValues),
    // formValues[CRSFields.ENABLE_V_CRS],
    initialValue: null,
    options: state => getVerticalCoordinateSystems(state),
    displayTemplate: crs => getVerticalCRSLabel(crs),
    searchTemplate: crs => getVerticalCRSLabel(crs),
    getValue: (state, value, values, extra) => value ? getVerticalCRSLabel(value) : '',
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    backendTransform: backendCRSFieldTransform(getCRSOptions),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        getTransformedCRS(state, analyzeData, CRSFields.V_CRS),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 9,
      lg: 9,
    },
  },
  [CRSFields.C_CRS]: {
    name: MapBackendNameToFrontend.crs,
    tag: CRSTag.CRS,
    dataType: DataType.AUTOCOMPLETE,
    invisible: true,
    optional: (state, values, extra, option, formTemplate) => isCRSFieldOptional(state, values, extra, formTemplate, 0),
    optionalForTab: (state, values, extra, option, formTemplate) => isCRSFieldOptionalForLastChangedTab(state, values, extra, formTemplate, 0),
    optionProps: (state, values, extra, propOptions) => getPropsForCRSOptions(state, values, extra, propOptions),
    extraRender: (state, values, extra, propOptions) => renderCRSWarning(state, values, extra, propOptions),
    disabled: (state, formValues) => coordinateSystemFieldDisabled(state, formValues),
    initialValue: null,
    options: state => getCompoundCoordinateSystems(state),
    displayTemplate: crs => getCoordinateSystemLabelWithType(crs),
    searchTemplate: crs => getCoordinateSystemLabelWithType(crs),
    getValue: (state, value, values, extra) => value ? getCoordinateSystemLabelWithType(value) : '',
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    backendTransform: backendCRSFieldTransform(getCRSOptions),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        getTransformedCRS(state, analyzeData, CRSFields.C_CRS),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  [CRSFields.H_UNITS]: {
    name: UnitName.H_UNITS,
    tag: CRSTag.UNIT,
    disabled: (state, formValues) => coordinateSystemFieldDisabled(state, formValues),
    invisible: true,
    dataType: DataType.AUTOCOMPLETE,
    optional: true,
    options: state => (getUnits(state) || []),
    displayTemplate: unit => unit.name,
    searchTemplate: unit => unit.name,
    initialValue: null,
    getValue: (state, value, values, extra) => (value && value.type === 'length') ? value.name : '',

    /*
    (state, formValues, formTemplate) => {
      const compound_crs = formValues[CRSFields.C_CRS]
      const compoundCRSValue = getCRSValue(state, compound_crs)
      return compoundCRSValue ? compoundCRSValue[CRSFields.H_UNITS] : 'm'
    },
    */
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        getTransformedUnit(state, analyzeData, CRSFields.H_UNITS),
    ),
    backendTransform: backendCRSFieldTransform(unitBackendTransform),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  [CRSFields.V_UNITS]: {
    name: UnitName.V_UNITS,
    tag: CRSTag.UNIT,
    disabled: (state, formValues) => coordinateSystemFieldDisabled(state, formValues),
    dataType: DataType.AUTOCOMPLETE,
    invisible: true,
    optional: (state, values, extra) => isVerticalUnitsOptional(state, values, extra),
    options: state => (getUnits(state) || []),
    displayTemplate: unit => unit.name,
    searchTemplate: unit => unit.name,
    initialValue: null,
    getValue: (state, value, values, extra) => (value && value.type === 'length') ? value.name : '',

    /*
    (state, formValues, formTemplate) => {
      const compound_crs = formValues[CRSFields.C_CRS]
      const compoundCRSValue = getCRSValue(state, compound_crs)
      return compoundCRSValue ? compoundCRSValue[CRSFields.V_UNITS] : 'm'
    },
    */
    onChange: (name, value, options) => onChangeCRSField(name, value, options),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        getTransformedUnit(state, analyzeData, CRSFields.V_UNITS),
    ),
    backendTransform: backendCRSFieldTransform(unitBackendTransform),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  [CRSFields.CUSTOM_CRS]: {
    name: 'Custom string',
    dataType: DataType.CUSTOM_CRS,
    tag: CRSTag.CUSTOM_CRS,
    invisible: true,
    onGetPosition: (options, name) => () => onGetPosition(options, name),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
        const customCRSValue = analyzeData[CRSFields.CUSTOM_CRS]
        if (customCRSValue) {
          return customCRSValue
        }
        return original
      },
    ),
    /*
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => analyzeData[CRSFields.CUSTOM_CRS] || ''
    ),
    */
    // backendTransform: (original, state, values) => null,
    backendTransformName: 'crs',
    backendTransform: backendCRSFieldTransform((original, state, values) => {
      if (original) {
        return original
      }
      return backendCRSFieldTransform(getCRSOptions)(values[CRSFields.C_CRS], state, values)
    }),
    optional: (state, values, extra, option, formTemplate) => isCRSFieldOptional(state, values, extra, formTemplate, 2),
    optionalForTab: (state, values, extra, option, formTemplate) => isCRSFieldOptionalForLastChangedTab(state, values, extra, formTemplate, 2),
    multiline: true,
    initialValue: '',
    placeholder: MapBackendNameToFrontend.proj,
    InputProps: {
      style: {
        height: '100%',
      },
    },
    onChange: (name, value, options) => onChangeCustomCRSField(name, value, options),
    onChangeOn: 'blur',
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  [CRSFields.COORDINATES_CHOOSER]: {
    name: 'Coordinates type',
    dataType: DataType.SELECTION,
    invisible: true,
    invisibleForTab: true,
    disabled: (state, formValues) => coordinateFieldDisabled(state, formValues),
    optional: true,
    options: [CoordinateTypes.LAT_LON, CoordinateTypes.NORTHING_EASTING, CoordinateTypes.EASTING_NORTHING],
    initialValue: CoordinateTypes.NORTHING_EASTING,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
}

export function onChangeCustomCRSField (name, value, options) {
  const {
    extra,
    actions = {},
    values: oldValues,
    setMultipleValues,
    option,
  } = options
  const { tag } = option
  const values = {
    ...oldValues,
    [name]: value,
  }
  const valuesToSet = {}
  const { checkUnits = () => {} } = actions
  if (values[CRSFields.LAST_CHANGED_TAB] !== values[CRSFields.TAB]) {
    valuesToSet[CRSFields.LAST_CHANGED_TAB] = values[CRSFields.TAB]
  }

  if (tag === CRSTag.CUSTOM_CRS) {
    valuesToSet[CRSFields.CUSTOM_CRS] = value
  }

  if (Object.keys(valuesToSet).length > 0 && typeof setMultipleValues === 'function') {
    callWithDelay(() => setMultipleValues(valuesToSet), 1)
  }
  checkUnitsWithCustomCRS(values[CRSFields.CUSTOM_CRS], extra, checkUnits)
}

export function onChangeCRSField (name, value, options) {
  const {
    state,
    actions = {},
    values: oldValues,
    option,
    formTemplate,
    setMultipleValues,
    extraProps = {},
    extra,
  } = options
  const { points, projectCRS } = extraProps
  const { tag } = option
  const {
    onGetGCP = () => {},
    onGetCRSInfo = () => {},
    checkUnits = () => {},
  } = actions
  const units = getUnits(state)
  const oldCoordinateType = getCoordinateType(state, oldValues, formTemplate)
  const values = { ...oldValues }
  const valuesToSet = {}
  if (extra !== 'inputCRS' && extra !== 'outputCRS') {
    values[name] = value
    const point = {
      x: +values[Coordinates.LONGITUDE],
      y: +values[Coordinates.LATITUDE],
      z: +values[Coordinates.ALTITUDE],
    }
    onGetTransformedPoints(
      state,
      extra,
      points || [point],
      values[REF_STATION_BACKEND_NAME],
      values[REF_STATION_BACKEND_NAME],
    )
    return
  }

  // If some fields except of tab changed we should changed last_changed_tab field
  if (tag !== CRSTag.TAB) {
    if (values[CRSFields.LAST_CHANGED_TAB] !== values[CRSFields.TAB]) {
      valuesToSet[CRSFields.LAST_CHANGED_TAB] = values[CRSFields.TAB]
      values[CRSFields.LAST_CHANGED_TAB] = values[CRSFields.TAB]
    }
  }

  const oldVUnit = oldValues[CRSFields.V_UNITS]
  const oldHUnit = oldValues[CRSFields.H_UNITS]

  const delay = 1

  const setUnitWithDelay = (oldValue, crsValue, fieldName) => {
    if (crsValue) {
      const crsUnit = crsValue[fieldName]
      const unitValue = crsUnit && units.find(unit => unit.code === crsUnit.code)
      if (path(['code'], crsUnit) !== path(['code'], oldValue)) {
        valuesToSet[fieldName] = unitValue || null
      }
    } else {
      if (oldValue) {
        valuesToSet[fieldName] = null
      }
    }
  }

  const setVUnits = (oldValue, crsValue) => setUnitWithDelay(oldValue, crsValue, CRSFields.V_UNITS)
  const setHUnits = (oldValue, crsValue) => setUnitWithDelay(oldValue, crsValue, CRSFields.H_UNITS)
  const setUnit = (crsValue, fieldName) => {
    const crsUnit = crsValue && crsValue[fieldName]
    const unitValue = crsUnit && units.find(unit => unit.code === crsUnit.code)
    values[fieldName] = unitValue || null
  }

  values[name] = value
  if (tag === CRSTag.CRS) {
    if (name === CRSFields.C_CRS) {
      // Setting v/h units
      setUnit(value, CRSFields.H_UNITS)
      setUnit(value, CRSFields.V_UNITS)
      setVUnits(oldVUnit, value)
      setHUnits(oldHUnit, value)
    }
    // When horizontal CRS value changed we should changed vertical crs also
    if (name === CRSFields.H_CRS) {
      // Setting h_units
      setUnit(value, CRSFields.H_UNITS)
      setHUnits(oldHUnit, value)
      // If vertical crs is disabled we should preselect v_units from horizontal crs
      /*
      if (typeof values[CRSFields.ENABLE_V_CRS] !== 'undefined' && !values[CRSFields.ENABLE_V_CRS]) {
        setUnit(value, CRSFields.V_UNITS)
        setVUnits(oldVUnit, value)
      }
      */
    }
    if (name === CRSFields.V_CRS) {
      // Setting v_units
      setUnit(value, CRSFields.V_UNITS)
      setVUnits(oldVUnit, value)
    }
  }
  if (tag === CRSTag.TAB) {
    const tab = value
    const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
    if (tabTemplate.fields[CRSFields.C_CRS]) {
      const crs = formTemplate[CRSFields.C_CRS]
      const crsValue = crs ? values[CRSFields.C_CRS] : null
      // Setting compound crs
      values[CRSFields.C_CRS] = crsValue
      // Setting v/h units
      setUnit(crsValue, CRSFields.H_UNITS)
      setUnit(crsValue, CRSFields.V_UNITS)
      setVUnits(oldVUnit, crsValue)
      setHUnits(oldHUnit, crsValue)
    }
    if (tabTemplate.fields[CRSFields.H_CRS] && tabTemplate.fields[CRSFields.V_CRS]) {
      const verticalCRS = formTemplate[CRSFields.V_CRS]
      const horizontalCRS = formTemplate[CRSFields.H_CRS]
      const verticalCRSValue = verticalCRS ? values[CRSFields.V_CRS] : null
      const horizontalCRSValue = horizontalCRS ? values[CRSFields.H_CRS] : null
      // Setting v/h crs
      values[CRSFields.V_CRS] = verticalCRSValue
      values[CRSFields.H_CRS] = horizontalCRSValue
      // Setting v/h units
      setUnit(horizontalCRSValue, CRSFields.H_UNITS)
      setUnit(verticalCRSValue, CRSFields.V_UNITS)
      setVUnits(oldVUnit, verticalCRSValue)
      setHUnits(oldHUnit, horizontalCRSValue)
    }
    if (tabTemplate.isCustomCrsTab) {
      const newCustomStrValue = getCustomCRSValue(values)
      valuesToSet[CRSFields.CUSTOM_CRS] = newCustomStrValue
      checkUnitsWithCustomCRS(newCustomStrValue, extra, checkUnits)
    }
  }

  const newCoordinateType = getCoordinateType(state, values, formTemplate)
  if (
    oldCoordinateType !== newCoordinateType &&
    formTemplate[Coordinates.LONGITUDE] &&
    formTemplate[Coordinates.ALTITUDE] &&
    formTemplate[Coordinates.LATITUDE]
  ) {
    resetCoordinates(values, valuesToSet)
  }

  // Set multiple values at time
  if (Object.keys(valuesToSet).length > 0 && typeof setMultipleValues === 'function') {
    callWithDelay(() => setMultipleValues(valuesToSet), delay)
  }

  const tab = values[CRSFields.TAB]
  const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])

  // If we clicked on tab with custom crs we do not need to retrieve transformed coordinates
  if (tabTemplate.isCustomCrsTab) return

  const { fields } = tabTemplate
  const point = {
    x: +values[Coordinates.LONGITUDE],
    y: +values[Coordinates.LATITUDE],
    z: +values[Coordinates.ALTITUDE],
  }

  const isTemplateValid = isTabTemplateValid(fields, values, state, extra)
  const transformationOptions = getTransformOptions(values, points || [point], state, extra, projectCRS)
  if (
    isTemplateValid &&
    typeof onGetGCP === 'function' &&
    (transformationOptions[CRSFields.C_CRS] ||
    transformationOptions[CRSFields.V_CRS] ||
    transformationOptions[CRSFields.H_CRS])

  ) onGetGCP(transformationOptions)
  // Retrieve CRS info only when at least one crs is determined
  // And only when data that was changed is not the actual tab
  if (
    (transformationOptions[CRSFields.C_CRS] ||
    transformationOptions[CRSFields.V_CRS] ||
    transformationOptions[CRSFields.H_CRS]) && (
      values[CRSFields.LAST_CHANGED_TAB] === values[CRSFields.TAB] &&
      tag !== CRSTag.TAB &&
      tag !== CRSTag.COORDINATE
    ) && typeof onGetCRSInfo === 'function'
  ) {
    onGetCRSInfo(omit(['points'], transformationOptions), extra)
  }
}

// Template for compound and geographic 3D tab
export const compoundTemplate = {
  [CRSFields.GRID]: {
    ...FieldTemplates[CRSFields.GRID],
    backendTransform: backendCRSFieldTransform(getCompoundCRSGrid),
  },
  [CRSFields.C_CRS]: {
    ...FieldTemplates[CRSFields.C_CRS],
  },
  [CRSFields.H_UNITS]: {
    ...FieldTemplates[CRSFields.H_UNITS],
    disabled: (state, values, extra, formTemplate) => coordinateSystemFieldDisabled(state, values) ||
      disableHUnits(CRSFields.C_CRS, state, values, formTemplate),
    altOption: (state, values, formTemplate, option) => {
      const alternativeOption = {
        ...FieldTemplates[CRSFields.H_UNITS],
        name: MapBackendNameToFrontend.unit,
        gridProps: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
        },
      }
      return isCompoundGeocentricCRS(state, values) ? alternativeOption : option
    },
    /*
    optional: (state, values, extra) => isCRSFieldOptional(state, values, extra, 0) &&
      isCRSFieldOptional(state, values, extra, 1),
    */
  },
  [CRSFields.V_UNITS]: {
    ...FieldTemplates[CRSFields.V_UNITS],
    invisibleForTab: (state, values) => isCompoundGeocentricCRS(state, values),
    backendTransform: (original, state, values) => unitBackendTransform(getCompoundCRSVUnit(state, values)),
    /*
    optional: (state, values, extra) => isCRSFieldOptional(state, values, extra, 0) &&
      isCRSFieldOptional(state, values, extra, 1),
    */
  },
}

// Template for user-defined compound CRS tab
export const crsTemplate = {
  [CRSFields.GRID]: {
    ...FieldTemplates[CRSFields.GRID],
  },
  [CRSFields.H_CRS]: {
    ...FieldTemplates[CRSFields.H_CRS],
  },
  [CRSFields.H_UNITS]: {
    ...FieldTemplates[CRSFields.H_UNITS],
    /*
    optional: (state, values, extra) => isCRSFieldOptional(state, values, extra, 0) &&
      isCRSFieldOptional(state, values, extra, 1),
    */
    disabled: (state, values, extra, formTemplate) => coordinateSystemFieldDisabled(state, values) ||
      disableHUnits(CRSFields.H_CRS, state, values, formTemplate),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  /*
  [CRSFields.ENABLE_V_CRS]: {
    ...FieldTemplates[CRSFields.ENABLE_V_CRS],
  },
  */
  [CRSFields.V_CRS]: {
    ...FieldTemplates[CRSFields.V_CRS],
  },
  [CRSFields.V_UNITS]: {
    ...FieldTemplates[CRSFields.V_UNITS],
    /*
    optional: (state, values, extra) => isCRSFieldOptional(state, values, extra, 0) &&
      isCRSFieldOptional(state, values, extra, 1),
    */
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
}

// Template for proj/wkt/json string tab
export const customCRSTemplate = {
  [CRSFields.CUSTOM_CRS]: {
    ...FieldTemplates[CRSFields.CUSTOM_CRS],
  },
}

export const getTabsTemplate = ({
  compoundTemplate,
  crsTemplate,
  customCRSTemplate,
}) => {
  return {
    [crsTabNames.COMPOUND]: {
      label: i18n.t(`templates.crsOptions.tabs.${crsTabNames.COMPOUND}`),
      fields: compoundTemplate,
    },
    [crsTabNames.CRS]: {
      label: i18n.t(`templates.crsOptions.tabs.${crsTabNames.CRS}`),
      fields: crsTemplate,
    },
    [crsTabNames.CUSTOM_CRS]: {
      label: i18n.t(`templates.crsOptions.tabs.${crsTabNames.CUSTOM_CRS}`),
      isCustomCrsTab: true,
      fields: customCRSTemplate,
    },
  }
}

// This is a special template for coordinate systems
export const tabsTemplate = getTabsTemplate({
  compoundTemplate,
  crsTemplate,
  customCRSTemplate,
})
