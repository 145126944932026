import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { reducer as companiesReducer } from './companies/reducers'
import { reducer as usersReducer } from './users/reducers'
import { reducer as missionsReducer } from './missions/reducers'
import { reducer as projectsReducer } from './projects/reducers'
import { reducer as urlReducer } from './url/reducers'
import { reducer as _appReducer } from './app/reducers'
import { reducer as uploadReducer } from './upload/reducers'
import { reducer as importWizardReducer } from './importWizard/reducers'
import { reducer as workersReducer } from './workers/reducers'
import { reducer as statisticsReducer } from './statistics/reducers'
import { reducer as supportReducer } from './support/reducers'
import { reducer as grantsReducer } from './grants/reducers'
import { reducer as pipelineWizardReducer } from './pipelineWizard/reducers'
import { reducer as jobsReducer } from './jobs/reducers'
import { reducer as projectCRSWizardReducer } from './projectCRSWizard/reducers'
import { reducer as projectWizardReducer } from './projectWizard/reducers'
import { reducer as calibrationsReducer } from './calibrations/reducers'

import appSagas from './app/sagas'
import companiesSagas from './companies/sagas'
import usersSagas from './users/sagas'
import projectsSagas from './projects/sagas'
import importWizardSagas from './importWizard/sagas'
import workersSagas from './workers/sagas'
import statisticsSagas from './statistics/sagas'
import supportSagas from './support/sagas'
import grantsSagas from './grants/sagas'
import pipelineWizardSagas from './pipelineWizard/sagas'
import jobsSagas from './jobs/sagas'
import pipelinesSagas from './pipelines/sagas'
import artifactsSagas from './artifacts/sagas'
import missionsSagas from './missions/sagas'
import projectCRSWizardSagas from './projectCRSWizard/sagas'
import projectWizardSagas from './projectWizard/sagas'
import positionsSagas from './positions/sagas'
import calibrationsSagas from './calibrations/sagas'
import { keepProps } from 'utils/dict'

/* Reducers */
export const appReducer = combineReducers({
  app: _appReducer,
  companies: companiesReducer,
  users: usersReducer,
  projects: projectsReducer,
  url: urlReducer,
  upload: uploadReducer,
  importWizard: importWizardReducer,
  workers: workersReducer,
  statistics: statisticsReducer,
  support: supportReducer,
  grants: grantsReducer,
  pipelineWizard: pipelineWizardReducer,
  jobs: jobsReducer,
  missions: missionsReducer,
  projectCRSWizard: projectCRSWizardReducer,
  projectWizard: projectWizardReducer,
  calibrations: calibrationsReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem('persist:root')
    state = keepProps(['app'], state)
  }
  return appReducer(state, action)
}

export function * rootSaga () {
  yield all([
    fork(appSagas),
    fork(companiesSagas),
    fork(usersSagas),
    fork(projectsSagas),
    fork(importWizardSagas),
    fork(workersSagas),
    fork(statisticsSagas),
    fork(supportSagas),
    fork(grantsSagas),
    fork(pipelineWizardSagas),
    fork(jobsSagas),
    fork(pipelinesSagas),
    fork(artifactsSagas),
    fork(missionsSagas),
    fork(projectCRSWizardSagas),
    fork(projectWizardSagas),
    fork(positionsSagas),
    fork(calibrationsSagas),
  ])
}
