import React from 'react'
import PropTypes from 'prop-types'
// Material UI
// Icons
import WarningIcon from '@material-ui/icons/Warning'
// Project deps
import i18next from 'i18n'
import { shouldDisplayFixAntennaMissmatch } from 'utils/artifacts'
import { useSelector } from 'react-redux'
import { getCurrentProject } from 'modules/projects/selectors'

const ReferenceStationTab = props => {
  const currentProject = useSelector(getCurrentProject)
  const { artifacts = [] } = currentProject
  const artifactsWithAntennaMismatchError = artifacts.filter(shouldDisplayFixAntennaMissmatch)
  return artifactsWithAntennaMismatchError.length > 0
    ? <span>
      <WarningIcon style={{ color: 'orange', marginRight: 8, verticalAlign: 'middle' }}/>
      {i18next.t('tabs.projects.refs')}
    </span>
    : i18next.t('tabs.projects.refs')
}

ReferenceStationTab.propTypes = {
  currentProject: PropTypes.shape({
    artifacts: PropTypes.array,
  }),
}

export default ReferenceStationTab
