import { call, put, takeLatest, fork, select, takeEvery } from 'redux-saga/effects'
import CompaniesActions from 'modules/companies/actions'
import PipelinesActions from 'modules/pipelines/actions'
import ArtifactsActions from 'modules/artifacts/actions'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import { path } from 'ramda'
import history from 'browserHistory'
import { routeError } from 'utils/routing'
import { showErrorMessage, getErrorMessage, getAuthentificationHeader } from 'utils/api'
import { getCompanyId } from 'utils/company'
import axios, { axiosAccounts, LIDARMILL_REMEMBER_2FA_COOKIE_NAME, removeToken } from 'utils/axios'
import { register as registerUser } from '../utils'
// Local deps
import UsersActions, { UsersTypes } from './actions'
import { getLoggedUser } from './selectors'
import { getCurrentCompanyProjects } from 'modules/companies/selectors'
import { getCRS as getCRSData, getAllUserCRS, deleteCRS as deleteCRSData, updateCRS as updateCRSData } from 'modules/crs/api'
import { getAddress, getSystemSerialNumber } from 'utils/users'
import { getCurrentProject } from 'modules/projects/selectors'
import { isNavLabJob, isPotreeJob, isRefStationAnalyzeJob, isSpatialFuserJob } from 'utils/jobs'
import { getPipelineInputArtifacs, getPipelineOutputArtifacs, isSomePipelineProcessing } from 'utils/pipelines'
import { isArtifactDone, isArtifactInUse } from 'utils/artifacts'
import { isGCPArtifact } from 'types/artifacts'
import { GCP_BACKEND_NAME } from 'templates/constants'
import { keepProps } from 'utils/dict'
import { CRSFieldsListNew } from 'templates/CRS/constants'
import { findById } from 'utils/list'
import { getCookie } from 'utils/cookies'
import { convertRawRecursivePipeline } from 'types/pipelines'
import { isLocalEnvironment } from 'config'

// Sagas
function * getUsers ({ excludeArray = [] }) {
  yield put(UsersActions.getUsersLoading())
  try {
    const { data: { data: users } } = yield call(axios.get,
      `/users${excludeArray.length > 0 ? `?exclude=last_activity,${excludeArray.join(',')}` : ''}`)
    yield put(UsersActions.getUsersSuccess(users))
  } catch (e) {
    yield put(UsersActions.getUsersFailure(getErrorMessage(e)))
  }
}

function * getProjectsStatistic () {
  yield put(UsersActions.getProjectsStatisticLoading())
  try {
    const { data: { data: projects_statistic } } = yield call(axios.get, '/users/data_usage')
    yield put(UsersActions.getProjectsStatisticSuccess(projects_statistic))
  } catch (e) {
    yield put(UsersActions.getProjectsStatisticFailure(getErrorMessage(e)))
  }
}

function * changePassword ({ id, oldPassword, newPassword, repeatedPassword }) {
  yield put(UsersActions.changePasswordLoading())
  try {
    yield call(axios.put, `/users/${id}/change_password`, {
      ...(typeof oldPassword !== 'undefined' && { old_password: oldPassword }),
      new_password: newPassword,
      repeated_password: repeatedPassword,
    })
    yield put(UsersActions.changePasswordSuccess())
    toast.success(i18next.t('toast.user.changePasswordSuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.changePasswordFailure(getErrorMessage(e)))
  }
}

function * getMe () {
  yield put(UsersActions.getMeLoading())
  try {
    const { data: { data: user } } = yield call(axiosAccounts.get, `/me`)
    yield put(CompaniesActions.getCompanySystemTypes(getCompanyId(user)))
    yield put(UsersActions.getMeSuccess(user))
  } catch (e) {
    yield put(UsersActions.logout())
    yield put(UsersActions.getMeFailure(getErrorMessage(e)))
  }
}

function * getUser ({ payload }) {
  yield put(UsersActions.getUserLoading())
  try {
    const { token, id } = payload
    const { data } = yield call(axios.get, `/users/${id}`, {
      headers: getAuthentificationHeader(token),
    })
    yield put(UsersActions.getUserSuccess(data.user))
  } catch (e) {
    yield put(UsersActions.getUserFailure(getErrorMessage(e)))
  }
}

function * getActiveUser ({ user_id }) {
  yield put(UsersActions.getActiveUserLoading())
  try {
    const { data: { data: user } } = yield call(axios.get, `/users/${user_id}`)
    yield put(UsersActions.getActiveUserSuccess(user))
  } catch (e) {
    const status = e.response.status
    if (status >= 400 && status < 500) {
      history.push(routeError())
      toast.error(i18next.t('toast.user.getActiveUserError'))
    }
    yield put(UsersActions.getActiveUserFailure(getErrorMessage(e)))
  }
}

function * createUser ({ payload }) {
  yield put(UsersActions.createUserLoading())
  try {
    yield call(axios.post, '/users/create', payload)
    yield put(UsersActions.createUserSuccess())
    yield put(UsersActions.getUsers())
  } catch (e) {
    yield put(UsersActions.createUserFailure(getErrorMessage(e)))
  }
}

function * register ({ payload, createdByUser = false }) {
  yield put(UsersActions.registerLoading())
  try {
    const body = {
      email: payload.email,
      password: payload.password,
      first_name: payload.firstName,
      last_name: payload.lastName,
      company: {
        name: payload.company,
        // url: payload.companyUrl,
        // industry: payload.industry,
        // phone: payload.phone,
        // invoice_address: payload.invoiceAddress,
      },
      country: payload.country,
      address: getAddress(payload),
      // phone: payload.phone,
      // mapping_system_product: payload.mappingSystemProduct,
      // navigation_system_vendor: payload.navigationSystemVendor,
      // time_zone: payload.timeZone,
      // unit_system: payload.unitSystem,
      terms_accepted: payload.termsAccepted,
      system_serial_numbers: getSystemSerialNumber(payload),
    }
    const data = yield call(registerUser, `/register?lat=${payload.lat}&lon=${payload.lng}`, body, !createdByUser)
    yield put(UsersActions.registerSuccess(data.data, createdByUser))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.registerFailure(getErrorMessage(e)))
  }
}

function * login ({ payload }) {
  yield put(UsersActions.loginLoading())
  try {
    const { email, password } = payload
    const remember2faCookie = getCookie(LIDARMILL_REMEMBER_2FA_COOKIE_NAME) || ''
    const remember2faToken = remember2faCookie.split('=')[1] || ''
    const { data: { data } } = yield call(axiosAccounts.post, '/login', {
      email,
      password,
      remember_2fa_token: remember2faToken,
    })
    if ('2fa_required' in data) {
      yield put(UsersActions.setTwoFactorLogin(data.temp_auth_token))
    } else {
      toast.success(i18next.t('toast.user.loginSuccess'))
      yield put(UsersActions.loginSuccess(data.token, data.user))
    }
  } catch (e) {
    if (e.response.status === 400 && path(['data', 'data', 'password_not_set'], e.response)) {
      yield put(UsersActions.loginFailure(getErrorMessage(e), true))
    } else {
      showErrorMessage(e)
      yield put(UsersActions.loginFailure(getErrorMessage(e)))
    }
  }
}

function * twoFactorLogin ({ payload }) {
  yield put(UsersActions.twoFactorLoginLoading())
  try {
    const { token, remember_2fa } = payload
    const tempToken = yield select(state => state.users.get('tempToken'))
    const { data: { data } } = yield call(axiosAccounts.post, '/login/2fa', {
      token: token,
      temp_auth_token: tempToken,
      remember_2fa,
    })
    const { remember_2fa_token, remember_2fa_token_expires_in } = data
    toast.success(i18next.t('toast.user.loginSuccess'))
    yield put(UsersActions.twoFactorLoginSuccess(data.token, data.user, remember_2fa_token, remember_2fa_token_expires_in))
  } catch (e) {
    if (e.response.status === 400 && path(['data', 'data', 'token_expired'], e.response)) {
      yield put(UsersActions.twoFactorLoginFailure(getErrorMessage(e), true))
      toast.success(i18next.t('toast.user.twoFactorAuthLoginError'))
    } else {
      showErrorMessage(e)
      yield put(UsersActions.twoFactorLoginFailure(getErrorMessage(e), false))
    }
  }
}

function * logout () {
  yield put(UsersActions.logoutLoading())
  try {
    if (isLocalEnvironment()) {
      yield put(UsersActions.logoutSuccess())
    } else {
      yield call(axios.get, '/remove_auth_cookies', { withCredentials: true })
      yield put(UsersActions.logoutSuccess())
    }
  } catch (e) {
    yield put(UsersActions.logoutFailure(getErrorMessage(e)))
  }
}

function * requestRecovery ({ payload }) {
  yield put(UsersActions.requestRecoveryLoading())
  try {
    const { email } = payload
    yield call(axios.post, '/recovery', { email })
    yield put(UsersActions.requestRecoverySuccess())
    history.push('/')
    toast.success(i18next.t('toast.user.requestRecoverySuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.requestRecoveryFailure(getErrorMessage(e)))
  }
}

function * recover ({ payload }) {
  yield put(UsersActions.recoverLoading())
  try {
    const { token, password } = payload
    yield call(axios.post, `/recovery/${token}`, { password })
    toast.success(i18next.t('toast.user.recoverSuccess'))
    yield put(UsersActions.recoverSuccess())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.recoverFailure(getErrorMessage(e)))
  }
}

function * confirmEmail ({ token }) {
  yield put(UsersActions.confirmEmailLoading())
  try {
    yield call(axios.post, `/email_confirmation/${token}`, {})
    yield put(UsersActions.confirmEmailSuccess())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.confirmEmailFailure(getErrorMessage(e)))
  }
}

function * sendConfirmEmail ({ email }) {
  yield put(UsersActions.sendConfirmEmailLoading())
  try {
    yield call(axios.post, `/email_confirmation`, { email })
    yield put(UsersActions.sendConfirmEmailSuccess())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.sendConfirmEmailFailure(getErrorMessage(e)))
  }
}
function * updateUser ({ payload, withSuccessMessage = true }) {
  yield put(UsersActions.updateUserLoading())
  try {
    const { id, ...payloadWithoutId } = payload
    const { data: { data: user } } = yield call(axios.post, `/users/${id}`, payloadWithoutId)
    if (withSuccessMessage) toast.success(i18next.t('toast.user.updateSuccess'))
    yield put(UsersActions.updateUserSuccess(user))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.updateUserFailure(getErrorMessage(e)))
  }
}

function * acceptTerms () {
  try {
    const loggedUser = yield select(state => getLoggedUser(state))
    const { data: { data: user } } = yield call(axios.post, `/users/${loggedUser.id}`, {
      terms_accepted: true,
    })
    yield put(UsersActions.updateUserSuccess(user))
    yield put(UsersActions.setAcceptTermsFormOpen(false))
  } catch (e) {
    yield put(UsersActions.updateUserFailure(getErrorMessage(e)))
  }
}

function * getSubscriptions ({ userId }) {
  yield put(UsersActions.getSubscriptionsLoading(userId))
  try {
    const { data: { data: subscriptions } } = yield call(axios.get, `/users/${userId}/subscriptions`)
    yield put(UsersActions.getSubscriptionsSuccess(userId, subscriptions))
  } catch (e) {
    yield put(UsersActions.getSubscriptionsFailure(userId))
  }
}

function * updateSubscription ({ companyId, subscriptionId, payload }) {
  // yield put(UsersActions.getSubscriptionsLoading(userId))
  try {
    const { data: { data: updatedSubscription } } = yield call(axios.put, `/subscriptions/${subscriptionId}`, payload)
    yield put(UsersActions.updateSubscriptionSuccess(companyId, subscriptionId, updatedSubscription))
    toast.success(i18next.t('toast.user.updateSubscriptionSuccess'))
  } catch (e) {
    // yield put(UsersActions.getSubscriptionsFailure(userId))
  }
}

function * getUserPermissions ({ userId }) {
  yield put(UsersActions.getUserPermissionsLoading(userId))
  try {
    const { data: { data: permissions } } = yield call(axios.get, `/users/${userId}/permissions`)
    yield put(UsersActions.getUserPermissionsSuccess(userId, permissions))
  } catch (e) {
    yield put(UsersActions.getUserPermissionsFailure(userId))
  }
}

// Get list of all users CRSs
function * getCompanyCRS ({ companyId }) {
  yield put(UsersActions.getCompanyCRSLoading(companyId))
  try {
    const { okay, crsList: crs } = yield call(getAllUserCRS, companyId)
    if (!okay) {
      throw new Error()
    }
    const projects = yield select(state => getCurrentCompanyProjects(state))
    const transformedCrs = crs.map(crs => {
      const { project: { id } } = crs
      const foundProject = findById(id, projects)
      return foundProject ? {
        ...crs,
        project: {
          name: foundProject.name,
          id,
        },
      } : crs
    })
    yield put(UsersActions.getCompanyCRSSuccess(companyId, transformedCrs))
  } catch (e) {
    yield put(UsersActions.getCompanyCRSFailure(companyId))
  }
}

// Get all CRS data
function * getCRS ({ crsId }) {
  yield put(UsersActions.getCRSLoading(crsId))
  try {
    const { okay, crs } = yield call(getCRSData, crsId)
    if (!okay) {
      throw new Error()
    }
    const projects = yield select(state => getCurrentCompanyProjects(state))
    const { project: { id } } = crs
    const foundProject = findById(id, projects)
    const transformedCrs = foundProject ? {
      ...crs,
      project: {
        name: foundProject.name,
        id,
      },
    } : crs
    yield put(UsersActions.getCRSSuccess(crsId, transformedCrs))
  } catch (e) {
    yield put(UsersActions.getCRSFailure(crsId))
  }
}

// Update CRS
function * updateCRS ({ projectId, crsId, crs }) {
  yield put(UsersActions.updateCRSLoading(projectId, crsId))
  try {
    const currentProject = yield select(state => getCurrentProject(state))
    const { pipelines, artifacts } = currentProject
    const navLabPipelines = pipelines.filter(pipeline =>
      pipeline.job_types.some(jobType => isNavLabJob(jobType)),
    )
    const spatialFuserPipelines = pipelines.filter(pipeline =>
      pipeline.job_types.some(jobType => isSpatialFuserJob(jobType)),
    )
    const allPotreePipelines = pipelines.filter(pipeline =>
      pipeline.job_types.some(jobType => isPotreeJob(jobType)),
    )
    const analyzeRefStationPipelines = pipelines
      .filter(pipeline =>
        pipeline.job_types.some(jobType =>
          isRefStationAnalyzeJob(jobType)) &&
        !isSomePipelineProcessing([pipeline]),
      )
    const gcpArtifacts = artifacts.filter(artifact =>
      isGCPArtifact(artifact.artifactType) &&
      isArtifactDone(artifact.artifactStatus),
    )
    const nonUserPotreePipelines = []
    const userPotreePipelines = []
    yield put(UsersActions.updateCRSStatus(i18next.t('pages.users.updateCrsStatus.prepare'), 100, 1))
    for (let i = 0; i < allPotreePipelines.length; i++) {
      const pipeline = allPotreePipelines[i]
      const pipelineId = pipeline.id
      const { data: { data: pipelineData } } = yield call(axios.get, `/pipelines/${pipelineId}/recursive`)
      const pipelineDataConverted = convertRawRecursivePipeline(pipelineData)
      if (pipelineDataConverted.links.sf_pipeline.length > 0) {
        nonUserPotreePipelines.push(pipelineDataConverted)
      } else {
        userPotreePipelines.push(pipelineDataConverted)
      }
    }
    const totalSteps =
      navLabPipelines.length +
      spatialFuserPipelines.length +
      nonUserPotreePipelines.length +
      userPotreePipelines.length +
      analyzeRefStationPipelines.length +
      gcpArtifacts.length
    let currentStep = 0
    const { okay, crs: newCrs } = yield call(updateCRSData, crsId, crs)
    if (!okay) {
      throw new Error()
    }
    const projects = yield select(state => getCurrentCompanyProjects(state))
    // Delete NavLab pipelines
    for (let i = 0; i < navLabPipelines.length; i++, currentStep++) {
      const pipeline = navLabPipelines[i]
      const pipelineId = pipeline.id
      yield put(UsersActions.updateCRSStatus(i18next.t('pages.users.updateCrsStatus.deleteNavlab'), totalSteps, currentStep))
      const { data: { data: pipelineData } } = yield call(axios.get, `/pipelines/${pipelineId}/recursive`)
      yield call(axios.delete, `/pipelines/${pipelineId}`)
      const { jobs } = convertRawRecursivePipeline(pipelineData)
      const allOutputArtifacts = getPipelineOutputArtifacs(jobs)
      yield put(ArtifactsActions.deleteArtifacts(allOutputArtifacts))
      yield put(PipelinesActions.deletePipelineSuccess(pipelineId))
    }
    // Delete Spatial Fuser pipelines
    for (let i = 0; i < spatialFuserPipelines.length; i++, currentStep++) {
      const pipeline = spatialFuserPipelines[i]
      const pipelineId = pipeline.id
      yield put(UsersActions.updateCRSStatus(i18next.t('pages.users.updateCrsStatus.deleteSpatialFuser'), totalSteps, currentStep))
      const { data: { data: pipelineData } } = yield call(axios.get, `/pipelines/${pipelineId}/recursive`)
      yield call(axios.delete, `/pipelines/${pipelineId}`)
      const { jobs } = convertRawRecursivePipeline(pipelineData)
      const allOutputArtifacts = getPipelineOutputArtifacs(jobs)
      yield put(ArtifactsActions.deleteArtifacts(allOutputArtifacts))
      yield put(PipelinesActions.deletePipelineSuccess(pipelineId))
    }
    // Delete Potree pipelines
    for (let i = 0; i < nonUserPotreePipelines.length; i++, currentStep++) {
      const pipeline = nonUserPotreePipelines[i]
      const pipelineId = pipeline.id
      yield put(UsersActions.updateCRSStatus(i18next.t('pages.users.updateCrsStatus.deletePotree'), totalSteps, currentStep))
      yield call(axios.delete, `/pipelines/${pipelineId}`)
      const { jobs } = pipeline
      const allOutputArtifacts = getPipelineOutputArtifacs(jobs)
      yield put(ArtifactsActions.deleteArtifacts(allOutputArtifacts))
      yield put(PipelinesActions.deletePipelineSuccess(pipelineId))
    }
    // Update user uploaded LAS artifacts
    for (let i = 0; i < userPotreePipelines.length; i++, currentStep++) {
      const pipeline = userPotreePipelines[i]
      yield put(UsersActions.updateCRSStatus(i18next.t('pages.users.updateCrsStatus.restartPotree'), totalSteps, currentStep))
      const { jobs } = pipeline
      const [job] = jobs
      const [lasInputArtifactId] = getPipelineInputArtifacs(jobs)
      if (!lasInputArtifactId) continue
      const { data: { data } } = yield call(axios.get, `/artifacts/${lasInputArtifactId}`)
      // Updating properties of LAS artifacts
      if (!isArtifactInUse(data) && (isArtifactDone(data.artifact_status))) {
        const { data: { data: artifact } } = yield call(axios.post, `/artifacts/${lasInputArtifactId}`, {
          properties: {
            ...data.properties,
            fileProperties: {
              ...Object.keys(data.properties.fileProperties).reduce((allFileProperties, fileName) => {
                return {
                  ...allFileProperties,
                  [fileName]: {
                    ...(data.properties.fileProperties[fileName] || {}),
                    crs: keepProps(CRSFieldsListNew, crs),
                  },
                }
              }, {}),
            },
          },
        })
        yield put(ArtifactsActions.updateArtifactSuccess(artifact.id, artifact))
        yield call(axios.post, `/jobs/${job.id}/job_runs`, { options: {} })
      }
    }
    // Restart RefStation pipelines
    for (let i = 0; i < analyzeRefStationPipelines.length; i++, currentStep++) {
      const pipeline = analyzeRefStationPipelines[i]
      const pipelineId = pipeline.id
      yield put(UsersActions.updateCRSStatus(i18next.t('pages.users.updateCrsStatus.restartAnalyzeRefStation'), totalSteps, currentStep))
      const { data: { data: pipelineData } } = yield call(axios.get, `/pipelines/${pipelineId}/recursive`)
      const pipelineDataConverted = convertRawRecursivePipeline(pipelineData)
      const { jobs } = pipelineDataConverted
      const [job] = jobs
      if (job) {
        const { job_runs: jobRuns } = job
        const jobOptions = jobRuns.reduce((accum, jobRun) => {
          const { job: { id: jobId }, options } = jobRun
          if (typeof options !== 'undefined' && Object.keys(options).length !== 0) {
            return {
              ...accum,
              [jobId]: {
                options,
              },
            }
          }
          return accum
        }, {})
        const options = path([job.id, 'options'], jobOptions)
        const { data: { data: jobRun } } = yield call(axios.post, `/jobs/${job.id}/job_runs`, { options: options || {} })
        yield put(PipelinesActions.retryJobSuccess(job.id, jobRun, pipelineId))
      }
    }
    // Update GCP artifact coordinate_reference_system
    for (let i = 0; i < gcpArtifacts.length; i++, currentStep++) {
      const gcpArtifact = gcpArtifacts[i]
      const artifactId = gcpArtifact.id
      yield put(UsersActions.updateCRSStatus(i18next.t('pages.users.updateCrsStatus.updateGCP'), totalSteps, currentStep))
      const { data: { data: artifact } } = yield call(axios.post, `/artifacts/${artifactId}`, {
        properties: {
          ...gcpArtifact.properties,
          [GCP_BACKEND_NAME]: keepProps(CRSFieldsListNew, crs),
        },
      })
      yield put(ArtifactsActions.updateArtifactSuccess(artifactId, artifact))
    }
    const { project: { id } } = newCrs
    const foundProject = findById(id, projects)
    const transformedCrs = foundProject ? {
      ...newCrs,
      project: {
        name: foundProject.name,
        id,
      },
    } : newCrs
    toast.success(i18next.t('toast.user.updateCRSSuccess'))
    yield put(UsersActions.updateCRSSuccess(projectId, crsId, transformedCrs))
  } catch (e) {
    console.log(e)
    toast.error(i18next.t('toast.user.updateCRSError'))
    yield put(UsersActions.updateCRSFailure(projectId, crsId))
  }
}

// Delete CRS
function * deleteCRS ({ projectId, crsId }) {
  yield put(UsersActions.deleteCRSLoading(projectId, crsId))
  try {
    const { okay } = yield call(deleteCRSData, crsId)
    if (!okay) {
      throw new Error()
    }
    toast.success(i18next.t('toast.user.deleteCRSSuccess'))
    yield put(UsersActions.deleteCRSSuccess(projectId, crsId))
  } catch (e) {
    yield put(UsersActions.deleteCRSFailure(projectId, crsId))
  }
}

function * getUserReleases () {
  yield put(UsersActions.getUserReleasesLoading())
  try {
    const loggedUser = yield select(state => getLoggedUser(state))
    if (!loggedUser) {
      throw new Error('User is not logged in')
    }
    const { data: { data: user } } = yield call(axiosAccounts.get, `/users/${loggedUser.id}/products/releases`)
    yield put(UsersActions.getUserReleasesSuccess(user))
  } catch (e) {
    yield put(UsersActions.getUserReleasesFailure(getErrorMessage(e)))
  }
}

function * setUserSeenReleases ({ productName, numberOfReleases, onSuccess }) {
  yield put(UsersActions.setUserSeenReleasesLoading())
  try {
    const loggedUser = yield select(state => getLoggedUser(state))
    if (!loggedUser) {
      throw new Error('User is not logged in')
    }
    yield call(axiosAccounts.post, `/users/${loggedUser.id}/products/${productName}/releases`, { last_seen: new Date().toISOString() })
    yield put(UsersActions.setUserSeenReleasesSuccess(productName, numberOfReleases))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield put(UsersActions.setUserSeenReleasesFailure(getErrorMessage(e)))
  }
}

// Watchers
function * getUsersWatcher () {
  yield takeLatest(UsersTypes.GET_USERS, getUsers)
}

function * getProjectsStatisticWatcher () {
  yield takeLatest(UsersTypes.GET_PROJECTS_STATISTIC, getProjectsStatistic)
}

function * getMeWatcher () {
  yield takeLatest(UsersTypes.GET_ME, getMe)
}

function * getUserWatcher () {
  yield takeLatest(UsersTypes.GET_USER, getUser)
}

function * getActiveUserWatcher () {
  yield takeLatest(UsersTypes.GET_ACTIVE_USER, getActiveUser)
}

function * createUserWatcher () {
  yield takeLatest(UsersTypes.CREATE_USER, createUser)
}

function * loginWatcher () {
  yield takeLatest(UsersTypes.LOGIN, login)
}

function * twoFactorLoginWatcher () {
  yield takeLatest(UsersTypes.TWO_FACTOR_LOGIN, twoFactorLogin)
}

function * registerWatcher () {
  yield takeLatest(UsersTypes.REGISTER, register)
}

function * logoutWatcher () {
  yield takeLatest(UsersTypes.LOGOUT, logout)
}

function * requestRecoveryWatcher () {
  yield takeLatest(UsersTypes.REQUEST_RECOVERY, requestRecovery)
}

function * recoverWatcher () {
  yield takeLatest(UsersTypes.RECOVER, recover)
}

function * confirmEmailWatcher () {
  yield takeLatest(UsersTypes.CONFIRM_EMAIL, confirmEmail)
}

function * sendConfirmEmailWatcher () {
  yield takeLatest(UsersTypes.SEND_CONFIRM_EMAIL, sendConfirmEmail)
}

function * updateUserWatcher () {
  yield takeLatest(UsersTypes.UPDATE_USER, updateUser)
}

function * changePasswordWatcher () {
  yield takeLatest(UsersTypes.CHANGE_PASSWORD, changePassword)
}

function * acceptTermsWatcher () {
  yield takeLatest(UsersTypes.ACCEPT_TERMS, acceptTerms)
}

function * getSubscriptionsWatcher () {
  yield takeEvery(UsersTypes.GET_SUBSCRIPTIONS, getSubscriptions)
}

function * updateSubscriptionWatcher () {
  yield takeLatest(UsersTypes.UPDATE_SUBSCRIPTION, updateSubscription)
}

function * getUserPermissionsWatcher () {
  yield takeLatest(UsersTypes.GET_USER_PERMISSIONS, getUserPermissions)
}

function * getCompanyCRSWatcher () {
  yield takeLatest(UsersTypes.GET_COMPANY_CRS, getCompanyCRS)
}

function * getCRSWatcher () {
  yield takeLatest(UsersTypes.GET_CRS, getCRS)
}

function * updateCRSWatcher () {
  yield takeLatest(UsersTypes.UPDATE_CRS, updateCRS)
}

function * deleteCRSWatcher () {
  yield takeLatest(UsersTypes.DELETE_CRS, deleteCRS)
}

function * getUserReleasesWatcher () {
  yield takeLatest(UsersTypes.GET_USER_RELEASES, getUserReleases)
}

function * setUserSeenReleasesWatcher () {
  yield takeLatest(UsersTypes.SET_USER_SEEN_RELEASES, setUserSeenReleases)
}

export default function * root () {
  yield fork(getUsersWatcher)
  yield fork(getProjectsStatisticWatcher)
  yield fork(getUserWatcher)
  yield fork(getMeWatcher)
  yield fork(createUserWatcher)
  yield fork(loginWatcher)
  yield fork(twoFactorLoginWatcher)
  yield fork(registerWatcher)
  yield fork(logoutWatcher)
  yield fork(requestRecoveryWatcher)
  yield fork(recoverWatcher)
  yield fork(confirmEmailWatcher)
  yield fork(sendConfirmEmailWatcher)
  yield fork(getActiveUserWatcher)
  yield fork(updateUserWatcher)
  yield fork(changePasswordWatcher)
  yield fork(acceptTermsWatcher)
  yield fork(getSubscriptionsWatcher)
  yield fork(updateSubscriptionWatcher)
  yield fork(getUserPermissionsWatcher)
  yield fork(getCompanyCRSWatcher)
  yield fork(getCRSWatcher)
  yield fork(updateCRSWatcher)
  yield fork(deleteCRSWatcher)
  yield fork(getUserReleasesWatcher)
  yield fork(setUserSeenReleasesWatcher)
}
