/**
 * Transform a `RawDataDirectory` to a `DataDirectory`:
 * @param raw The raw data directory that needs to be converted.
 * @param artifactId The id of the artifact the data directory belongs to.
 * @return The converted data directory.
 */
export function convertRawDataDirectory (raw) {
  const { id, s3_key: s3Key, s3_link: s3Link, description, completed, created, updated, file_index: fileIndex, artifact } = raw
  return {
    id,
    s3Key,
    s3Link,
    description,
    completed: Boolean(completed),
    created: new Date(created),
    updated: new Date(updated),
    fileIndex,
    artifactId: artifact.id,
  }
}
