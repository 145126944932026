import { Map } from 'immutable'
import Bowser from 'bowser'
import { path } from 'ramda'
import { storageId } from 'config'
import { getStorageItem } from 'utils/localStorage'

const parsedBrowser = Bowser.parse(path(['navigator', 'userAgent'], window))

const getInitialUserSettings = () => {
  const userSettingsFromLocalStorage = getStorageItem(storageId.settings)
  return !userSettingsFromLocalStorage
    ? {
      projects: {
        sortBy: 'updated',
        order: 'desc',
      },
    }
    : JSON.parse(userSettingsFromLocalStorage)
}

export const INITIAL_STATE = Map({
  constants: {
    industries: [],
    countries: [],
    timeZones: [],
    antennas: [],
    filetypes: [],
    epochs: [],
    ie_datums: [],
    epsg_datums: [],
    coordinateSystems: [],
    verticalCoordinateSystems: [],
    horizontalCoordinateSystems: [],
    compoundCoordinateSystems: [],
    horizontalGeographic3DCoordinateSystems: [],
    units: [],
    geoids: [],
    classifications: {},
    salesProducts: [],
  },
  gcpTemplate: '',
  recommendedDatums: {},
  systemTypes: [],
  constantsLoaded: false,
  getConstantsIsLoading: false,
  getAntennasIsLoading: false,
  getAllSystemTypesIsLoading: false,
  userSettings: getInitialUserSettings(),
  browser: parsedBrowser
    ? parsedBrowser.browser.name + ' ' + parsedBrowser.browser.version
    : '',
  headerHeight: 150,
  sidebarWidth: 256,
  checkTokenDialogOpen: false,
  checkTokenCallback: null,
  products: [],
})
