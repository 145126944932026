import { getFileFromDropbox } from './utils'

const extensions = [
  new RegExp(/\.\d\d[a-zA-Z]$./, 'ig'),
  new RegExp(/\.\d\d[a-zA-Z]$/, 'ig'),
  new RegExp(/\.hcn$/, 'ig'),
  new RegExp(/\.refpp$/, 'ig'),
  new RegExp(/\.m\d\d$/, 'ig'),
  new RegExp(/\.(jps|tps)$/, 'ig'),
  new RegExp(/\.(dat|bin)$/, 'ig'),
  new RegExp(/\.nov$/, 'ig'),
  new RegExp(/\.pdc$/, 'ig'),
  new RegExp(/\.cdu$/, 'ig'),
  new RegExp(/\.gps$/, 'ig'),
  new RegExp(/\.cmc$/, 'ig'),
  new RegExp(/\.sbf$/, 'ig'),
  new RegExp(/\.ash$/, 'ig'),
  new RegExp(/\.tr[a-zA-z]*$/, 'ig'),
  new RegExp(/\.(gpb|epp|sta)$/, 'ig'),
  new RegExp(/\.raw$/, 'ig'),
  new RegExp(/\.ubx$/, 'ig'),
  new RegExp(/\.t0(0|1|2|4)$/, 'ig'),
  new RegExp(/\.rt(1|2)7$/, 'ig'),
  new RegExp(/\.cap$/, 'ig'),
  new RegExp(/\.rnx$/, 'ig'),
  new RegExp(/\.crx$/, 'ig'),
  new RegExp(/\.[a-zA-Z]$/, 'ig'),
]

export const extensionsLabels = [
  '.hcn',
  '.refpp',
  '.jps',
  '.tps',
  '.dat',
  '.bin',
  '.nov',
  '.pdc',
  '.cdu',
  '.gps',
  '.cmc',
  '.sbf',
  '.ash',
  '.gpb',
  '.epp',
  '.sta',
  '.raw',
  '.ubx',
  '.t00',
  '.t01',
  '.t02',
  '.t04',
  '.rt17',
  '.rt27',
  '.cap',
  '.rnx',
  '.crx',
  '.tr[any letter]',
  '.m[any digit][any digit]',
  '.[any letter]',
  '.[any digit][any digit][any letter]',
]

export function shouldRefStationFileBeParsed (fileName) {
  const fileExtension = fileName.toLowerCase()
  return fileExtension.match(new RegExp(/\.\d\d[oO]$/, 'ig'))
}

/**
 * Checks whether the file name of a file looks like a reference station file name.
 */
export function isRefStationFileName (fileName) {
  const fileExtension = fileName.toLowerCase()
  return extensions.some(extension => {
    return fileExtension.match(extension)
  })
  // return extensions.some(extension => fileExtension.toLowerCase().endsWith(extension))
}

export const ReferenceStationFileType = 'reference_station'

export function isRefStationFileType (fileType) {
  return fileType === ReferenceStationFileType
}

// TODO: Not sure which is the actual size of it
const headerSize = 2048

export async function parseRefStationFile (file, blob) {
  return new Promise(resolve => {
    const log = []
    const fileReader = new FileReader()
    fileReader.onload = () => {
      // ArrayBuffer
      const buffer = fileReader.result
      const splittedBuffer = buffer.split(new RegExp(/\n|\r/))
      const antennaTypeRow = splittedBuffer.find(row => row.endsWith('ANT # / TYPE'))
      // If row with antenna type was found:
      // 1) Split by whitespaces
      // 2) Remove empty strings
      // 3) Antenna model should be in the second column
      const antennaModel = antennaTypeRow && antennaTypeRow.split(' ').filter(str => str)[1]
      const result = {
        antenna: antennaModel,
      }
      resolve({ result, okay: true, log, fileType: ReferenceStationFileType })
    }
    fileReader.onerror = () => {
      log.push({
        level: 'error',
        message: `Error reading file "${file.name}".`,
      })
      resolve({ okay: false, log, fileType: ReferenceStationFileType })
    }
    fileReader.readAsText((blob || file).slice(0, headerSize))
  })
}

export async function parseDropboxRefStationFile (file) {
  return getFileFromDropbox(file, { responseType: 'arraybuffer' }, { type: 'application/json' }, parseRefStationFile)
}
