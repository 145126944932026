// function extractFileProperties(files: AnalyzedFile[]): { [fileName: string]: FileProperties }
export function extractFileProperties (files) {
  return files.reduce(
    (accum, file) =>
      // TODO Is this check necessary or handled be previous logic?
      // Use of any due to non-uniform declaration of file parse props.
      file.okay && typeof file.parseResult !== 'undefined'
        ? { ...accum, [file.file.name]: file.parseResult }
        : accum,
    {},
  )
}
