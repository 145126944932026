import axios from 'axios'
import { getPrevUrl } from 'modules/url/selectors'
import Cookies from 'js-cookie'
// Project deps
import { inIframe } from 'utils/browser'
import { routeLoginAccounts } from 'utils/routing'
import { cookieName } from 'utils/cookies'
import { isLocalEnvironment } from 'config'
import { getStorageItem } from 'utils/localStorage'
import { setToken } from 'utils/axios'

const persistMiddleware = store => next => action => {
  if (action.type === 'persist/REHYDRATE') {
    if (!inIframe() && !window.location.pathname.startsWith('/set_token')) {
      const state = store.getState()
      const prevUrl = getPrevUrl(state)
      const pathName = prevUrl === '/' ? '/dashboard/projects' : prevUrl
      const newAction = {
        ...action,
        token: null,
      }
      // When we login with accounts (or logging as another user using UI)
      if (isLocalEnvironment()) {
        const userToken = getStorageItem('userToken')
        setToken(userToken, { setExpDate: true })
        newAction.token = userToken
      } else {
        const token = Cookies.get(cookieName)
        if (token) {
          newAction.token = token
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        } else {
          window.location.href = routeLoginAccounts(window.location.origin + pathName)
        }
      }
      next(newAction)
    }
  } else {
    next(action)
  }
}

export default persistMiddleware
