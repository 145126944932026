import { makeUniqueById, mapById, removeById } from 'utils/list'
import { getSuccessStatus, getFailureStatus, getLoadingStatus } from 'utils/reducersHelpers'
// Local deps
import { PipelinesTypes } from './actions'

export const getPipelinesQueriedLoading = state =>
  state.merge({
    getPipelinesIsLoading: true,
    getPipelinesErrorMessage: '',
  })

export const getPipelinesQueriedSuccess = (state, { pipelines, pagination }) =>
  state.merge({
    pipelines,
    pipelinesPagination: pagination,
    getPipelinesIsLoading: false,
    getPipelinesErrorMessage: null,
  })

export const getPipelinesQueriedFailure = (state, { errorMessage }) =>
  state.merge({
    pipelines: [],
    getPipelinesIsLoading: false,
    getPipelinesErrorMessage: errorMessage,
  })

export const putGetPipeline = (state, { pipelineId, pipeline }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: makeUniqueById([
        pipeline,
        ...currentProject.pipelines,
      ]),
    },
    allPipelines: makeUniqueById([
      pipeline,
      ...state.get('allPipelines'),
    ]),
  })
}

export const getPipelineLoading = state =>
  state.merge({
    getPipelineIsLoading: true,
    getPipelineErrorMessage: null,
  })

export const getPipelineSuccess = (state, { pipelineId, pipeline }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: makeUniqueById([
        pipeline,
        ...currentProject.pipelines,
      ]),
    },
    allPipelines: makeUniqueById([
      pipeline,
      ...state.get('allPipelines'),
    ]),
    getPipelineIsLoading: false,
  })
}

export const getPipelineFailure = (state, { errorMessage }) =>
  state.merge({
    getPipelineIsLoading: false,
    getPipelineErrorMessage: errorMessage,
  })

export const getJobRunLoading = (state, { jobRunId }) => {
  return state.merge({
    getJobRunStatus: getLoadingStatus(state.get('getJobRunStatus'), jobRunId),
  })
}

export const getJobRunSuccess = (state, { jobRunId, jobRun, pipelineId }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: mapById(pipelineId, currentProject.pipelines, pipeline => ({
        ...pipeline,
        jobRuns: (pipeline.jobRuns && pipeline.jobRuns.length > 0) ? mapById(jobRunId, pipeline.jobRuns, () => jobRun) : [jobRun],
      })),
    },
    getJobRunStatus: getSuccessStatus(state.get('getJobRunStatus'), jobRunId),
  })
}

/*
export const getJobRunSuccess = (state, { jobRunId, jobRun }) => {
  const currentPipeline = state.get('currentPipeline')
  const newJobRuns = currentPipeline.jobRuns
  const loadStatus = currentPipeline.jobRunLoadStatus
  return state.merge({
    getJobRunIsLoading: false,
    currentPipeline: {
      ...currentPipeline,
      jobRuns: newJobRuns.map(_jobRun => _jobRun.id === jobRunId ? jobRun : _jobRun),
    },
    jobRunLoadStatus: {
      ...loadStatus,
      [jobRunId]: true,
    },
  })
}
*/

export const getJobRunFailure = (state, { jobRunId, errorMessage }) => {
  return state.merge({
    getJobRunStatus: getFailureStatus(state.get('getJobRunStatus'), jobRunId, errorMessage),
  })
}

export const retryJobLoading = (state, { jobId }) => {
  return state.merge({
    retryJobStatus: getLoadingStatus(state.get('retryJobStatus'), jobId),
  })
}

export const retryJobSuccess = (state, { jobId, jobRun, pipelineId }) => {
  const currentProject = state.get('currentProject')

  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: mapById(pipelineId, currentProject.pipelines, pipeline => ({
        ...pipeline,
        jobRuns: [
          ...(pipeline.jobRuns || []),
          jobRun,
        ],
      })),
    },
    retryJobStatus: getSuccessStatus(state.get('retryJobStatus'), jobId),
  })
}

export const retryJobFailure = (state, { jobId, errorMessage }) => {
  return state.merge({
    retryJobStatus: getFailureStatus(state.get('retryJobStatus'), jobId, errorMessage),
  })
}

export const updatePipelineLoading = (state, { pipelineId }) =>
  state.merge({
    updatePipelineStatus: getLoadingStatus(state.get('updatePipelineStatus'), pipelineId),
  })

export const updatePipelineSuccess = (state, { pipelineId, name }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: mapById(pipelineId, currentProject.pipelines, pipeline => ({ ...pipeline, name })),
    },
    updatePipelineStatus: getSuccessStatus(state.get('updatePipelineStatus'), pipelineId),
  })
}

export const updatePipelineFailure = (state, { pipelineId, errorMessage }) => {
  return state.merge({
    updatePipelineStatus: getFailureStatus(state.get('updatePipelineStatus'), pipelineId, errorMessage),
  })
}

export const deletePipelineLoading = (state, { pipelineId }) => state.merge({
  deletePipelineStatus: getLoadingStatus(state.get('deletePipelineStatus'), pipelineId),
})

export const deletePipelineSuccess = (state, { pipelineId }) => {
  const currentProject = state.get('currentProject')
  return state.merge({
    currentProject: {
      ...currentProject,
      pipelines: removeById(pipelineId, currentProject.pipelines),
    },
    allPipelines: removeById(pipelineId, state.get('allPipelines')),
    deletePipelineStatus: getSuccessStatus(state.get('deletePipelineStatus'), pipelineId),
  })
}

export const deletePipelineFailure = (state, { pipelineId, errorMessage }) => {
  return state.merge({
    deletePipelineStatus: getFailureStatus(state.get('deletePipelineStatus'), pipelineId, errorMessage),
  })
}

export const getPipelineIdByJobRunLoading = state => {
  return state.merge({
    getPipelineIdByJobRunIsLoading: true,
    getPipelineIdByJobRunErrorMessage: null,
    pipelineFromJobRun: null,
  })
}

export const getPipelineIdByJobRunSuccess = (state, { pipeline }) => {
  return state.merge({
    getPipelineIdByJobRunIsLoading: false,
    pipelineFromJobRun: pipeline,
  })
}

export const getPipelineIdByJobRunFailure = (state, { errorMessage }) => {
  return state.merge({
    getPipelineIdByJobRunIsLoading: false,
    getPipelineIdByJobRunErrorMessage: errorMessage,
  })
}

export default {
  [PipelinesTypes.PUT_GET_PIPELINE]: putGetPipeline,

  [PipelinesTypes.GET_PIPELINE_LOADING]: getPipelineLoading,
  [PipelinesTypes.GET_PIPELINE_SUCCESS]: getPipelineSuccess,
  [PipelinesTypes.GET_PIPELINE_FAILURE]: getPipelineFailure,

  [PipelinesTypes.GET_PIPELINES_QUERIED_LOADING]: getPipelinesQueriedLoading,
  [PipelinesTypes.GET_PIPELINES_QUERIED_SUCCESS]: getPipelinesQueriedSuccess,
  [PipelinesTypes.GET_PIPELINES_QUERIED_FAILURE]: getPipelinesQueriedFailure,

  [PipelinesTypes.GET_PIPELINE_ID_BY_JOB_RUN_LOADING]: getPipelineIdByJobRunLoading,
  [PipelinesTypes.GET_PIPELINE_ID_BY_JOB_RUN_SUCCESS]: getPipelineIdByJobRunSuccess,
  [PipelinesTypes.GET_PIPELINE_ID_BY_JOB_RUN_FAILURE]: getPipelineIdByJobRunFailure,

  [PipelinesTypes.GET_JOB_RUN_LOADING]: getJobRunLoading,
  [PipelinesTypes.GET_JOB_RUN_SUCCESS]: getJobRunSuccess,
  [PipelinesTypes.GET_JOB_RUN_FAILURE]: getJobRunFailure,

  [PipelinesTypes.RETRY_JOB_LOADING]: retryJobLoading,
  [PipelinesTypes.RETRY_JOB_SUCCESS]: retryJobSuccess,
  [PipelinesTypes.RETRY_JOB_FAILURE]: retryJobFailure,

  [PipelinesTypes.UPDATE_PIPELINE_LOADING]: updatePipelineLoading,
  [PipelinesTypes.UPDATE_PIPELINE_SUCCESS]: updatePipelineSuccess,
  [PipelinesTypes.UPDATE_PIPELINE_FAILURE]: updatePipelineFailure,

  [PipelinesTypes.DELETE_PIPELINE_LOADING]: deletePipelineLoading,
  [PipelinesTypes.DELETE_PIPELINE_SUCCESS]: deletePipelineSuccess,
  [PipelinesTypes.DELETE_PIPELINE_FAILURE]: deletePipelineFailure,

}
