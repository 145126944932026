import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const withLoader = Component => {
  const Loader = props => {
    const { isLoading, overlay, dark = false, text = '', align = 'center', ContainerProps = {}, smallText = false, ...otherProps } = props
    const { style: containerStyle, ...otherContainerProps } = ContainerProps
    let style
    if (overlay) {
      style = {
        position: 'absolute',
        width: `100%`,
        background: dark ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)',
      }
    } else {
      style = {
        position: 'relative',
      }
    }
    return (
      <div style={{ ...containerStyle, position: 'relative' }} {...otherContainerProps}>
        { isLoading &&
            <div style={{
              left: '0',
              top: '0',
              zIndex: '1101',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: align || (overlay ? 'center' : 'start'),
              flexDirection: 'column',
              color: '#fff',
              ...style,
            }}
            >
              <CircularProgress color={'primary'} size={24}/>
              { typeof text !== 'object'
                ? <Typography variant={smallText ? 'body2' : 'h5'} color={dark ? 'inherit' : 'primary'}>{text}</Typography>
                : text
              }
            </div>
        }
        <Component {...otherProps}/>
      </div>
    )
  }

  Loader.propTypes = {
    align: PropTypes.string,
    dark: PropTypes.bool,
    overlay: PropTypes.bool,
    isLoading: PropTypes.bool,
    smallText: PropTypes.bool,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    ContainerProps: PropTypes.object,
  }
  Loader.defaultProps = {
    overlay: false,
    isLoading: true,
  }

  return Loader
}

withLoader.propTypes = {
  Component: PropTypes.element,
}

export default withLoader

/*
const withLoader = (Component) => {
  // eslint-disable-next-line react/display-name
  class Loader extends React.Component {
    state = {
      width: null,
    }

    componentDidMount () {
      this.setState({ width: this.container.clientWidth })
    }

    render () {
      // eslint-disable-next-line react/prop-types
      const { isLoading, overlay, ...otherProps } = this.props
      let style;
      if (overlay) {
        style = {
          position: 'absolute',
          width: `100%`,
          background: 'rgba(0,0,0,0.3)'
        }
      } else {
        const { width } = this.state
        style = {
          position: 'relative',
          //width: `calc(100% - ${width})`,
        }
      }

      return (
        // eslint-disable-next-line no-return-assign
        // What's the reason to use this? <div ref={ e => this.container = e}>
        <div>
          { isLoading &&
              <div style={{
                left: '0',
                top: '0',
                zIndex: '9999',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...style,
              }}
              >
                <CircularProgress />
              </div>
          }
          <Component {...otherProps}/>
        </div>
      )
    }
  }

  Loader.propTypes = {
    isLoading: PropTypes.bool,
    overlay: PropTypes.bool,
    otherProps: PropTypes.func,
  }

  Loader.defaultPropTypes = {
    overlay: true,
    isLoading: true,
  }

  return Loader
}

withLoader.propTypes = {
  Component: PropTypes.element,
};

export default withLoader
*/
