import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  createStartPipeline: ['name', 'emailNotification', 'artifactOptions', 'onSuccessCallback'],
  createStartPipelineLoading: null,
  createStartPipelineFailure: null,
  createStartPipelineSuccess: null,
  changeCreationStatus: ['creationStage', 'okay', 'errorMessage'],
  updatePipelineTemplate: ['pipelineTemplate'],

  deselectAllArtifacts: null,
  selectDeselectArtifactsForTemplate: ['templateArtifactId', 'artifactId', 'artifact', 'deselectArtifacts', 'appState'],
  selectArtifactsForTemplate: ['templateArtifactId', 'artifacts', 'appState'],
  deselectArtifactsForTemplate: ['templateArtifactId', 'artifacts', 'appState'],
  selectArtifactForTemplate: ['templateArtifactId', 'artifactId', 'artifact', 'appState'],
  deselectArtifactForTemplate: ['templateArtifactId', 'artifactId', 'artifact', 'appState'],

  resetState: null,
  updateArtifactOptions: ['artifact', 'artifactId', 'artifactOptions', 'valid', 'rawValues', 'appState'],
  updateArtifactsOptions: ['artifacts', 'artifactOptions', 'valid', 'rawValues', 'appState'],

  updateJobOptions: ['templateJobId', 'jobOptions', 'valid', 'rawValues'],
  gotoConfigureStep: ['step'],
  gotoWizardStep: ['step', 'key', 'prevStepId'],
  setPreselectState: ['preselect'],
  getGCP: ['options', 'id'],
  getGCPLoading: null,
  getGCPSuccess: ['gcp', 'id'],
  getGCPFailure: ['errorMessage'],
  setAdditionalJobRunOptions: ['artifactId', 'options'],
  setAdditionalJobIoOptions: ['artifactId', 'options'],
  setNavIntervalIndex: ['artifactId', 'startIndex', 'endIndex'],
  clonePipeline: null,
  setCloneOptions: ['options'],

  resetClonePipeline: null,
}, { prefix: 'PIPELINE_WIZARD/' })

export const PipelineWizardTypes = Types
export default Creators
