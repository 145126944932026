
export const FILES_FIELD = {
  GCP: 'gcpFiles',
  POLYGON: 'polygonFiles',
  MISSION: 'files',
  REF: 'refFiles',
  NON_MISSION: 'nonMissionFiles',
  EDIT_GCP: 'editGcpFiles',
  DOCUMENTATION: 'documentationFiles',
}

export const PROTO_ARTIFACT_FIELD = {
  GCP: 'gcpProtoArtifacts',
  POLYGON: 'polygonProtoArtifacts',
  MISSION: 'protoArtifacts',
  REF: 'refProtoArtifacts',
  NON_MISSION: 'nonMissionProtoArtifacts',
  EDIT_GCP: 'editGcpProtoArtifacts',
  DOCUMENTATION: 'documentationProtoArtifacts',
}

export const NUMBER_OF_IMAGES = {
  ANY_AMOUNT: Infinity,
  NOT_ALLOWED: -1,
}
