import { createReducer } from 'reduxsauce'
import { path, omit, equals } from 'ramda'
import { v4 } from 'uuid'
// Project deps
import i18next from 'i18n'
import { ArtifactTypes, getCamFilePhotosNumber, isCameraArtifact, isRefStationArtifact } from 'types/artifacts'
import { SystemType } from 'types/missions'
import { convertRawPosition } from 'types/position'
import { MissionTemplate, defaultMissionType } from 'templates/missions'
import { mapById, removeById, findById, removeByMissionId, filterByMissionId } from 'utils/list'
import { getCameraFileModel, getArtifactsOfType, getCamArtifactProperties, isCameraArtifactHasLadybugSensor } from 'utils/artifacts'
import { getFileExtension, getBaseName, isFileNameEndsWith } from 'utils/baseName'
import { getLoggedUser, isAdmin } from 'modules/users/selectors'
import { getAllSystemTypes } from 'modules/app/selectors'
import { getCompanySystemTypes } from 'modules/companies/selectors'
import { getMissions } from 'modules/projects/selectors'
import { generateProtoArtifacts, isPfsFileName, isCamFileName, isPlpFileType, isCamFileType, isCreatedFromPlp, isNavFileType, ReferenceStationFileType, ImageFileType, isImageFileType, isLasFileType, isGcpFileName } from 'modules/upload/file-types'
import { isBaslerOrPylonCamera } from 'modules/upload/file-types/utils'
import { getUnmatched, getMissingLdrFilesBaseNameSuggestions, getMissingLdrSuggestions } from 'components/ImportWizard/utils'
// Internal deps
import { INITIAL_STATE } from './initialState'
import { ImportWizardTypes as Types } from './actions'
import { createProtoArtifact } from 'utils/protoArtifacts'
import {
  createSuggestionProtoArtifacts,
  mergeFilesIntoProtoArtifacts,
  groupFilesIntoProtoArtifacts,
  mergeSuggestionProtoArtifacts,
  regenerateArtifactNames,
  scrubInvalidProtoArtifacts,
  removeFileFromProtoArtifacts,
  generateArtifactName,
  makeFilesUnique,
  splitFilesByTypes,
  getNewMissionName,
  updateProtoArtifactProperties,
  isDropboxFile,
  sortFilesByName,
} from './utils'
import { FILES_FIELD, PROTO_ARTIFACT_FIELD, NUMBER_OF_IMAGES } from 'types/importWizard'
import { isPGRImageFile } from 'types/extensions'
/**
 * The index of the highest step. If there are three steps present, this number should be `3`.
 */
export const maxStep = 4

const fileTypesMap = {
  IMAGE_FILE: 'images',
  KNOWN_FILE: 'known',
  UNKNOWN_FILE: 'unknown',
}

function checkImagesSize (images, preferredSize, errors) {
  for (let i = 0; i < images.length; i++) {
    const image = images[i]
    const fileResolution = path(['parseResult', 'result', 'resolution'], image)
    // We should not check resolution for dropbox files or non jpeg/png/jpg files
    if (!fileResolution) {
      continue
    }
    const isImageHasSameResolution = (
      (fileResolution.width === preferredSize.x && fileResolution.height === preferredSize.y) ||
      (fileResolution.height === preferredSize.x && fileResolution.width === preferredSize.y)
    )
    if (!isImageHasSameResolution) {
      errors.push(
        i18next.t(
          'importWizard.errors.wrongImageResolution',
          {
            fileName: image.file.name,
            width: preferredSize.x,
            height: preferredSize.y,
            fileWidth: fileResolution.width,
            fileHeight: fileResolution.height,
          },
        ),
      )
      break
    }
  }
}

const RefProtoArtifacts = PROTO_ARTIFACT_FIELD.REF
const MissionProtoArtifacts = PROTO_ARTIFACT_FIELD.MISSION
const MissionFiles = FILES_FIELD.MISSION
/*
const getNewProtoArtifactForImages = (protoId, protoArtifacts, files, props = {}) => {
  return mapById(protoId, protoArtifacts, proto => {
    const protoFiles = files.map(({ file }) => ({ file, fileType: 'image', okay: true }))
    const newFiles = [
      ...proto.files,
      ...protoFiles,
    ]

    return {
      ...proto,
      properties: {
        ...proto.properties,
        ...props
      },
      files: newFiles,
      name: proto.name,
    }
  })
}
*/
const getNewProtoArtifactForImages = (protoArtifact, files, props = {}) => {
  const protoFiles = files.map(({ file }) => ({
    file,
    fileType: ImageFileType,
    okay: file.size > 0,
    logs: file.size <= 0 ? [{ level: 'error', message: 'Invalid file size. File is empty.' }] : [],
  }))
  const newFiles = [
    ...protoArtifact.files,
    ...protoFiles,
  ]

  return {
    ...protoArtifact,
    properties: {
      ...protoArtifact.properties,
      ...props,
    },
    files: newFiles,
    name: protoArtifact.name,
  }
}

const getNotMappedFolders = mapping => {
  return Object.keys(mapping).filter(key => !mapping[key])
}

const getMappedFolders = mapping => {
  return Object.keys(mapping).filter(key => Boolean(mapping[key]))
}

const splitImagesByFolder = images => {
  return images.reduce((allDirectories, image) => {
    const imageSubfolder = image.file.parentFolder
    return {
      ...allDirectories,
      // We should add images in the previously sorted order
      [imageSubfolder]: [...(allDirectories[imageSubfolder] || []), image],
    }
  }, {})
}

// Create mapping between input folders from dropbox api and backend directory sub_folder (cam0, cam1)
// For the values of undefined dialog should be shown for the user to choose where to add image files
/*
  {
    'images': 'cam0',
    'cam0': 'cam0',
    'cam1': 'cam1',
    'images1': undefined,
  }
*/
const createCameraSubFolderMapping = (cameraProtoArtifacts, inputFolders) => {
  const unusedCameraProtoArtifacts = [...cameraProtoArtifacts]
  let mapping = inputFolders.reduce((allMapping, folder) => {
    const correspondingCameraProtoArtifactIndex = unusedCameraProtoArtifacts.findIndex(protoArtifact => protoArtifact.properties.sub_folder === folder)
    const isFound = correspondingCameraProtoArtifactIndex >= 0
    if (isFound) {
      unusedCameraProtoArtifacts.splice(correspondingCameraProtoArtifactIndex, 1)
    }
    return {
      ...allMapping,
      [folder]: isFound ? folder : undefined,
    }
  }, {})
  const notMappedFolders = getNotMappedFolders(mapping)
  // If we have cameras that is unused and have not mapped dropbox folders
  if (unusedCameraProtoArtifacts.length > 0 && notMappedFolders.length > 0) {
    // And we have only one camera session to add into and the dropbox folders have different name (e.g. images and cam0)
    if (cameraProtoArtifacts.length === 1) {
      mapping = {
        ...mapping,
        ...notMappedFolders.reduce((notMapped, key) => ({ ...notMapped, [key]: unusedCameraProtoArtifacts[0].properties.sub_folder }), {}),
      }
    }
  }
  return {
    mapping,
    true: getMappedFolders(mapping),
    false: getNotMappedFolders(mapping),
  }
}

const getImagesBySubFolder = (sub_folder, mapping, filesByDirectory, nonDropboxFiles) => {
  let files = Object.keys(mapping).reduce((allFiles, key) => {
    const value = mapping[key]
    return value === sub_folder ? [
      ...allFiles,
      ...(filesByDirectory[key] || []),
    ] : allFiles
  }, [])
  const useLocal = files.length <= 0
  if (useLocal) {
    files = [...nonDropboxFiles]
  }
  return { useLocal, files }
}

function processCameraArtifactImages (protoArtifact, images, filesNumber, isLastCameraArtifact) {
  const { files } = protoArtifact
  const imagesToAdd = []
  for (let i = images.length - 1; i >= 0; i--) {
    const image = images[i]
    const imageFileName = image.file.name
    if (imagesToAdd.length === filesNumber) break
    /*
    if (!isLastCameraArtifact) {
      if (imagesToAdd.length === filesNumber) break
    }
    */
    // Skip images with the same name
    if (files.some(file => file.file.name === imageFileName)) continue
    imagesToAdd.push(image)
    images.splice(i, 1)
  }
  return imagesToAdd
}

function updateMissionName (appState, missionType, missionName, files) {
  const defaultMissionName = i18next.t('importWizard.missionName')
  if (missionType === SystemType.PHOENIX_V4_V5) {
    const [firstPlpFile] = files.filter(file => isPlpFileType(file.fileType))
    if (firstPlpFile && missionName.startsWith(defaultMissionName)) return getBaseName(firstPlpFile.file.name)
    return missionName
  }
  if (missionType === SystemType.PHOENIX_V3) {
    const [firstNavFile] = files.filter(file => isNavFileType(file.fileType))
    if (firstNavFile && missionName.startsWith(defaultMissionName)) return getBaseName(firstNavFile.file.name)
    return missionName
  }
  if (missionType === SystemType.HYSPEX) {
    const projectMissions = getMissions(appState)
    const newMissionName = getNewMissionName(projectMissions)
    return newMissionName || missionName
  }
  // If mission name is different from the default one it means that the user manually changed it, so keep it
  if (missionName !== defaultMissionName) {
    return missionName
  }
  return defaultMissionName
}

function getDefaultCamArtifactProperties (artifact) {
  const { files = [] } = artifact
  const [firstFile] = files
  return getCamArtifactProperties(
    `cam${path(['parseResult', 'result', 'sensorIndex'], firstFile)}`,
    firstFile ? getCamFilePhotosNumber(firstFile) + 1 : 0,
    'cam',
  )
}

function getRequiredNumberOfImages (protoArtifact, camFile) {
  const createdFromPlp = isCreatedFromPlp(protoArtifact.createdFrom)
  if (createdFromPlp) {
    if (isCameraArtifactHasLadybugSensor(protoArtifact)) {
      if (protoArtifact.fileSuggestions.length <= 0) {
        // For proto artifact with `Ladybug` sensor and empty suggestions we can upload as much image files as we want
        return NUMBER_OF_IMAGES.ANY_AMOUNT
      }
      return protoArtifact.fileSuggestions.length
    }
    return protoArtifact.fileSuggestions.length
  } else {
    if (camFile) {
      return getCamFilePhotosNumber(camFile)
    }
  }
  // Means that we can't add more files
  return NUMBER_OF_IMAGES.NOT_ALLOWED
}

/**
 * Process the input images:
 * - merge camera artifacts existing images with the new ones
 * - ignore invalid files and add the errors
 * @param {Array} images
 * @param {Array} protoArtifacts
 * @param {Array} pfsFiles
 */
const processImages = (images, protoArtifacts, pfsFiles, isLastProtoArtifact) => {
  // Checks for image files
  const errors = []
  let newProtoArtifacts = [...protoArtifacts]
  const cameraProtoArtifacts = getArtifactsOfType(ArtifactTypes.CAMERA_DATA, protoArtifacts)
  const numberOfCameraProtoArtifacts = cameraProtoArtifacts.length
  if (images.length > 0 || pfsFiles.length > 0) {
    let pfsFileIndex = 0
    if (numberOfCameraProtoArtifacts > 0) {
      newProtoArtifacts = newProtoArtifacts.map((protoArtifact, index) => {
        if (!isCameraArtifact(protoArtifact.artifactType)) return protoArtifact
        let newProtoArtifact = { ...protoArtifact }
        const { fileSuggestions = [], properties } = protoArtifact
        const pixelCount = (path(['pixelCounts'], properties) || [])[0]
        const canUploadPfsFile = fileSuggestions.find(name => isPfsFileName(name))
        const createdFromPlp = isCreatedFromPlp(protoArtifact.createdFrom)
        // const isLastProtoArtifact = index === numberOfCameraProtoArtifacts - 1
        const protoArtifactFiles = protoArtifact.files
        const numberOfProtoArtifactFiles = protoArtifactFiles.length
        const pfsFileInProtoArtifact = protoArtifactFiles.find(file => isPfsFileName(file.file.name))
        const protoArtifactFilesNumber = createdFromPlp
          ? numberOfProtoArtifactFiles
          : numberOfProtoArtifactFiles - (pfsFileInProtoArtifact ? 2 : 1)
        const camFile = protoArtifactFiles.find(file => isCamFileType(file.fileType))
        const requiredNumberOfImages = getRequiredNumberOfImages(protoArtifact, camFile)
        if (!createdFromPlp) {
          if (camFile) {
            const model = getCameraFileModel(camFile)
            if (isBaslerOrPylonCamera(model) && !pfsFileInProtoArtifact) {
              // Basler and Pylon cameras can have additional pfs file
              if (pfsFileIndex <= pfsFiles.length - 1) {
                newProtoArtifact = getNewProtoArtifactForImages(
                  newProtoArtifact,
                  [pfsFiles[pfsFileIndex++]],
                  {
                    ...newProtoArtifact.properties,
                    number_of_images: newProtoArtifact.properties.number_of_images + 1,
                  },
                )
                pfsFiles.splice(pfsFileIndex - 1, 1)
              }
            }
          }
        }
        if (canUploadPfsFile && !pfsFileInProtoArtifact && pfsFiles.length > 0) {
          newProtoArtifact = getNewProtoArtifactForImages(
            newProtoArtifact,
            [pfsFiles[pfsFileIndex++]],
            {
              ...newProtoArtifact.properties,
              number_of_images: newProtoArtifact.properties.number_of_images + 1,
            },
          )
          pfsFiles.splice(pfsFileIndex - 1, 1)
        }
        // In this case we can add as much image files as we want
        if (requiredNumberOfImages === NUMBER_OF_IMAGES.ANY_AMOUNT) {
          // For proto artifact with `Ladybug` sensor we can upload only `.pgr` files
          if (isCameraArtifactHasLadybugSensor(newProtoArtifact)) {
            const pgrFiles = images.filter(image => isPGRImageFile(image.file.name))
            images = images.filter(image => !isPGRImageFile(image.file.name))
            const imagesToAdd = processCameraArtifactImages(
              newProtoArtifact,
              pgrFiles,
              NUMBER_OF_IMAGES.ANY_AMOUNT,
              isLastProtoArtifact,
            )
            newProtoArtifact = getNewProtoArtifactForImages(newProtoArtifact, imagesToAdd)
          }
        } else {
          if (requiredNumberOfImages !== NUMBER_OF_IMAGES.NOT_ALLOWED) {
            const numberOfImages = requiredNumberOfImages - protoArtifactFilesNumber
            if (requiredNumberOfImages > 0 && numberOfImages > 0) {
              const imagesToAdd = processCameraArtifactImages(newProtoArtifact, images, numberOfImages, isLastProtoArtifact)
              // Before adding images we need to check their dimensions
              checkImagesSize(imagesToAdd, pixelCount, errors)
              newProtoArtifact = getNewProtoArtifactForImages(newProtoArtifact, imagesToAdd)
            }
          }
        }
        return newProtoArtifact
      })
    } else {
      errors.push(i18next.t('importWizard.errors.plpOrCamRequired'))
    }
  }
  if (images.length > 0 && numberOfCameraProtoArtifacts > 0 && isLastProtoArtifact) {
    errors.push(i18next.t('importWizard.errors.tooManyImages', { count: images.length }))
  }
  return {
    protoArtifacts: newProtoArtifacts,
    errors,
    images,
    pfsFiles,
  }
}

const processFiles = (
  uploaderTemplate,
  addedFiles,
  files,
  unmatchedFileSuggestions,
  missingFileSuggestions,
) => {
  const filesToCreateProtos = []
  const errors = []
  // Go through the array of files to find all the missingFileSuggestions for each file
  files.forEach(file => {
    // Here we can add more functions
    // The result of called function must be [BASENAME_OF_FILE]: [ARRAY OF FILENAMES THAT REQUIRED]
    const missingLdrFiles = getMissingLdrFilesBaseNameSuggestions([file.file])
    missingFileSuggestions = Object.keys(missingLdrFiles).reduce((allSuggestions, key) => {
      const suggestions = allSuggestions[key]
      const missingSuggestions = missingLdrFiles[key]

      if (suggestions) {
        return {
          ...allSuggestions,
          [key]: [
            ...suggestions,
            ...missingSuggestions,
          ],
        }
      } else {
        return {
          ...allSuggestions,
          [key]: missingSuggestions,
        }
      }
    }, missingFileSuggestions)
  })

  // Loop through the files with known types
  const notAllowedForMissionFiles = []
  files.forEach(file => {
    const fileName = file.file.name
    const fileType = file.fileType
    const fileExtension = (getFileExtension(fileName) || '').toLowerCase()
    // When we upload a file that suggested from a .plp file then we not need any other checks
    if (unmatchedFileSuggestions.length > 0 && unmatchedFileSuggestions.includes(fileName)) {
      filesToCreateProtos.push(file)
      return
    }
    // When we upload a file that are not allowed by the template but file is in the missingFileSuggestions
    // e.g. some file required another file
    const suggestionKeys = Object.keys(missingFileSuggestions)
    if (suggestionKeys.length > 0) {
      const baseName = getBaseName(fileName)
      const suggestions = missingFileSuggestions[baseName]
      if (suggestions && suggestions.includes(fileName)) {
        // missingFileSuggestions = omit([baseName], missingFileSuggestions)
        filesToCreateProtos.push(file)
        return
      }
    }
    // After all previous checks we check for a corresponding file in the template
    if (uploaderTemplate) {
      const key = Object.keys(uploaderTemplate).find(templateKey => {
        const template = uploaderTemplate[templateKey]
        const fileTypes = template.fileTypes || []
        // // Boolean(templateKey === fileType && uploaderTemplate[fileType]) ||
        return (fileTypes.length > 0 && fileTypes.find(extension => isFileNameEndsWith(extension, fileExtension)))
      })
      const typeTemplate = uploaderTemplate[key]
      if (typeTemplate) {
        const { max, customMissionCheck: func } = typeTemplate
        // Files with the same type that already added
        const filesWithTypeAdded = addedFiles.filter(file => file.fileType === fileType)
        // Files with the same type that could be added in current session
        const filesWithTypeCouldBeAdded = filesToCreateProtos.filter(file => file.fileType === fileType)
        // Files with the same extension that already added
        const filesWithExtensionAdded = filesWithTypeAdded.filter(file => isFileNameEndsWith(file.file.name, fileExtension))
        // Files with the same extension that could be added in current session
        const filesWithExtensionCouldBeAdded = filesToCreateProtos.filter(file => isFileNameEndsWith(file.file.name, fileExtension))
        // This check is used for some corner cases like trajectory files
        if (typeof func === 'function') {
          const fileShouldBeAdded =
            func(
              filesWithTypeAdded,
              filesWithTypeCouldBeAdded,
              filesWithExtensionAdded,
              filesWithExtensionCouldBeAdded,
              fileType,
              fileExtension,
              file,
              errors,
            )
          if (fileShouldBeAdded) filesToCreateProtos.push(file)
        } else {
          // Just trivial check
          // 1 stands for current file
          const numberOfFiles = filesWithTypeAdded.length + filesWithTypeCouldBeAdded.length + 1
          if (numberOfFiles <= max) {
            filesToCreateProtos.push(file)
          } else {
            errors.push(i18next.t('importWizard.errors.oneFilePerType', { fileName, max }))
          }
        }
      } else {
        notAllowedForMissionFiles.push(file)
      }
    }
  })

  const filesByType = notAllowedForMissionFiles.reduce((allTypes, file) => {
    const fileType = file.fileType
    return {
      ...allTypes,
      [fileType]: [
        ...(allTypes[fileType] || []),
        file,
      ],
    }
  }, {})
  // If user try to upload ref/gcp/las/las/trajectory files and the files are not in mission template
  // we need to display a message to guide the user
  Object.keys(filesByType).forEach(key => {
    const currentFileTypeFiles = filesByType[key] || []
    const count = currentFileTypeFiles.length
    if (count > 0) {
      const tabSuggestionKey = `importWizard.tabSuggestion.${key}`
      const secondaryText = i18next.t(tabSuggestionKey)
      const primaryText = i18next.t('importWizard.errors.filesNotAllowed', {
        fileNames: currentFileTypeFiles.map(file => file.file.name).join(', '),
        count: currentFileTypeFiles.length,
      })
      errors.push(`${primaryText}${secondaryText !== tabSuggestionKey ? ` ${secondaryText}` : ' File is not allowed for this mission'}`)
    }
  })

  return {
    filesToCreateProtos,
    errors,
  }
}

const getSuggestionProtoArtifacts = (
  template,
  addedFiles,
  inputFiles,
  reducers,
  missionId,
) => {
  const { max } = template
  const addedFilesNumber = addedFiles.length
  if (addedFilesNumber < max) {
    let files = inputFiles
    let filesNumber = files.length
    const numberOfFileCanBeAdded = max - addedFilesNumber
    files = filesNumber + addedFilesNumber <= max && filesNumber > 0
      ? files
      : files.slice(0, numberOfFileCanBeAdded >= filesNumber ? filesNumber : numberOfFileCanBeAdded)
    filesNumber = files.length

    if (filesNumber > 0) {
      const { protoArtifacts } = reducers.reduce(
        (oldState, reducer) => reducer(oldState),
        {
          protoArtifacts: [],
          files,
          missionId,
        },
      )
      return {
        artifacts: protoArtifacts,
        implicitFiles: files,
      }
    }
  }
  return {
    artifacts: [],
    implicitFiles: [],
  }
}

export const filesAnalyzed = (state, { files, filesField, artifactsField }) => {
  const currentMissionId = state.get('currentMissionId')
  const addedPlp = state.get('plp')
  const filesFromField = state.get(filesField)
  const protoArtifactsFromField = state.get(artifactsField) || []

  const { uniqueFiles } = makeFilesUnique([...files, ...filesFromField])
  const inputFiles = uniqueFiles.filter(({ file: { name: fileName } }) => !filesFromField.find(
    fileFromField => fileFromField.file.name === fileName),
  )

  const reducers = [
    // Create new suggestion artifacts.
    createSuggestionProtoArtifacts,
    // Merge new files into already present `ProtoArtifact`s.
    mergeFilesIntoProtoArtifacts,
    // Generate new `ProtoArtifacts` from the remaining files.
    groupFilesIntoProtoArtifacts,
    // Merge existing `ProtoArtifact`s into suggestion `ProtoArtifact`s.
    mergeSuggestionProtoArtifacts,
    // Regenerate the artifact names.
    regenerateArtifactNames,
  ]
  const lasFiles = inputFiles.filter(file => isLasFileType(file.fileType))
  const nonLasFiles = inputFiles.filter(file => !isLasFileType(file.fileType))
  const { protoArtifacts } = reducers.reduce(
    (oldState, reducer) => reducer(oldState),
    {
      protoArtifacts: protoArtifactsFromField,
      // Put las files to the start of the list to create a proto artifact from it firstly
      files: [
        ...lasFiles,
        ...nonLasFiles,
      ],
      missionId: currentMissionId,
    },
  )

  const plpFile = files.find(file => isPlpFileType(file.fileType))
  const plp = plpFile &&
    plpFile.parseResult &&
    plpFile.parseResult.result &&
    plpFile.parseResult.result.data
  return state.merge({
    analyzing: false,
    [filesField]: [ ...filesFromField, ...inputFiles ],
    // Before adding proto artifacts we scrub invalid proto artifacts
    ...(artifactsField && { [artifactsField]: scrubInvalidProtoArtifacts(protoArtifacts) }),
    ...(filesField === 'settingsFiles' ? {
      plp: typeof plp === 'string' ? { ...addedPlp, 'settingsFiles': JSON.parse(plp) } : addedPlp,
    } : {
      plp: typeof plp === 'string' ? { ...addedPlp, [currentMissionId]: plp } : addedPlp,
    }),
  })
}

// We need different reducer for Mission uploader because we have totally different checks for it
export const missionFilesAnalyzed = (state, {
  appState,
  files: unfilteredFiles,
  artifactsField = MissionProtoArtifacts,
  filesField = MissionFiles,
  allFilesField = [MissionFiles],
  missionField = null,
  missionIdField = 'currentMissionId',
  MissionTemplate: InputMissionTemplate = MissionTemplate,
}) => {
  const addedPlp = state.get('plp')
  const missions = state.get('missions')
  const allMissionProtoArtifacts = state.get(artifactsField)
  const allMissionFiles = allFilesField.reduce((allFiles, field) => [
    ...allFiles,
    ...state.get(field),
  ], [])
  const currentMissionId = state.get(missionIdField)
  const errors = []
  const mission = missionField || findById(currentMissionId, missions)
  const protoArtifactsForCurrentMission = filterByMissionId(currentMissionId, allMissionProtoArtifacts)
  const filesForCurrentMission = allMissionFiles.filter(file => (file.missionId || file.file.missionId) === currentMissionId)
  const backupPlpFiles = unfilteredFiles
    .filter(file => isPlpFileType(file.fileType) && file.file.name.includes('backup'))
  const nonBackupPlpFiles = unfilteredFiles
    .filter(file => isPlpFileType(file.fileType) && !file.file.name.includes('backup'))
  // If we upload multiple plp files:
  // 1) Find all non-backup and backup plp files
  // 2) Keep only non-backup plp files if at least on exist. If not - keep backup plp files
  const plpFiles = nonBackupPlpFiles.length > 0 ? nonBackupPlpFiles : backupPlpFiles
  const nonPlpFiles = unfilteredFiles.filter(file => !isPlpFileType(file.fileType))
  const { uniqueFiles, unUniqueFiles } = makeFilesUnique([...plpFiles, ...nonPlpFiles, ...filesForCurrentMission])
  unUniqueFiles.forEach(file => errors.push(i18next.t('importWizard.errors.sameName', { fileName: file.file.name })))
  let inputFiles = uniqueFiles.filter(
    ({ file: { name } }) => !filesForCurrentMission.find(missionFile => missionFile.file.name === name),
  )
  const missionType = (mission && (mission.type || mission.missionType)) || ''
  const missionTemplate = InputMissionTemplate[missionType]

  const reducers = [
    // Create new suggestion artifacts.
    createSuggestionProtoArtifacts,
    // Merge new files into already present `ProtoArtifact`s.
    mergeFilesIntoProtoArtifacts,
    // Generate new `ProtoArtifacts` from the remaining files.
    groupFilesIntoProtoArtifacts,
    // Merge existing `ProtoArtifact`s into suggestion `ProtoArtifact`s.
    mergeSuggestionProtoArtifacts,
    // Regenerate the artifact names.
    regenerateArtifactNames,
  ]

  // Firstly we need to find .plp or .cam files
  // If it exist in mission template we need to create a suggestion artifacts
  // We will need that artifacts in the future to find corresponding image files
  // to make possible to upload images with .cam and .plp files at the same time
  let suggestionProtoArtifacts = []
  let otherFiles = []
  let newFiles = [...inputFiles]
  const plpTemplate = missionTemplate['plp']
  const camTemplate = missionTemplate['cam']
  // Phoenix V4-V7 and Hyspex system types
  if (plpTemplate) {
    const { artifacts, implicitFiles } = getSuggestionProtoArtifacts(
      plpTemplate,
      filesForCurrentMission.filter(file => isPlpFileType(file.fileType)),
      inputFiles.filter(file => isPlpFileType(file.fileType)),
      reducers,
      currentMissionId,
    )
    suggestionProtoArtifacts = artifacts
    otherFiles = implicitFiles
  }
  // Phoenix V3 system type
  if (camTemplate) {
    const { artifacts, implicitFiles } = getSuggestionProtoArtifacts(
      camTemplate,
      filesForCurrentMission.filter(file => isCamFileType(file.fileType)),
      inputFiles.filter(file => isCamFileType(file.fileType)),
      reducers,
      currentMissionId,
    )
    suggestionProtoArtifacts = artifacts.map(artifact => ({
      ...artifact,
      properties: getDefaultCamArtifactProperties(artifact),
    }))
    otherFiles = implicitFiles
  }
  // Filter .pfs files from the list
  const pfsFiles = inputFiles.filter(file => isPfsFileName(file.file.name))
  inputFiles = inputFiles.filter(file => !isPfsFileName(file.file.name))
  // Remove plp and cam files.
  // - in V4-V7 .cam files not allowed
  // - in V3 Camera artifact with .cam file will be created, so we don't need them further
  newFiles = inputFiles.filter(file => !isCamFileType(file.fileType) && !isPlpFileType(file.fileType))

  // Merge already created artifacts with the created from suggestions
  const protoArtifacts = [
    ...protoArtifactsForCurrentMission,
    ...suggestionProtoArtifacts,
  ]

  const cameraProtoArtifacts = getArtifactsOfType(ArtifactTypes.CAMERA_DATA, protoArtifacts)
  // Get all camera artifacts suggestion
  const cameraArtifactsSuggestions = cameraProtoArtifacts.reduce((allSuggestions, artifact) => {
    const [firstFile] = artifact.files
    const isCamFile = firstFile && isCamFileName(firstFile.file.name)
    return [
      ...allSuggestions,
      ...artifact.fileSuggestions.length > 0
        ? artifact.fileSuggestions
        : artifact.files.length > 0
          ? isCamFile ? Array(getCamFilePhotosNumber(firstFile)).fill('') : []
          : [],
    ]
  }, []).filter(Boolean)

  // Split all files by three different type
  const {
    [fileTypesMap.KNOWN_FILE]: files,
    [fileTypesMap.UNKNOWN_FILE]: notAllowedFiles,
    [fileTypesMap.IMAGE_FILE]: images,
  } = splitFilesByTypes(newFiles, cameraArtifactsSuggestions)
  if (notAllowedFiles.length > 0) {
    errors.push(i18next.t('importWizard.errors.unknownFiles', {
      fileNames: notAllowedFiles.map(file => file.file.name).join(', '),
      count: notAllowedFiles.length,
    }))
  }
  const { unmatchedFileSuggestions, unmatchedFiles } = getUnmatched(filesForCurrentMission, protoArtifacts)

  const {
    missingFileSuggestionsForCurrentMission: missingFileSuggestions,
  } = getMissingLdrSuggestions(missionType, unmatchedFiles.map(file => file.file))
  const plpFile = otherFiles.find(file => isPlpFileType(file.fileType)) ||
    filesForCurrentMission.find(file => isPlpFileType(file.fileType))

  // Sort images by names in descending order
  // We adding images to the proto artifacts from the end of the list
  // So the files from the beginning of the list can be ignored if we uploaded more files than needed
  const sortedImages = sortFilesByName(images)

  // Dropbox files
  const dropboxImages = sortedImages.filter(image => isDropboxFile(image))
  const dropboxPfsFiles = pfsFiles.filter(file => isDropboxFile(file))
  // Non dropbox files
  const nonDropboxImages = sortedImages.filter(image => !isDropboxFile(image))
  const nonDropboxPfsFiles = pfsFiles.filter(file => !isDropboxFile(file))
  const dropboxImagesByDirectory = splitImagesByFolder(dropboxImages)
  const dropboxPfsFilesByDirectory = splitImagesByFolder(dropboxPfsFiles)
  const dropboxDirectories = Object.keys(dropboxImagesByDirectory)

  const amountNonDropboxImages = nonDropboxImages.length + nonDropboxPfsFiles.length
  const isNonDropboxImageExists = amountNonDropboxImages > 0
  const isDropboxImagesExists = dropboxImages.length > 0 || dropboxPfsFiles.length > 0
  const isImagesAdded = isNonDropboxImageExists || isDropboxImagesExists
  // If we have some non dropbox files we should temp id to map them to proper camera artifact
  const idForImages = isNonDropboxImageExists
    ? v4()
    : undefined

  const nonDropboxFoldersMapping = cameraProtoArtifacts.reduce((all, protoArtifact) => {
    const { properties } = protoArtifact
    const { number_of_images, sub_folder } = properties
    if (number_of_images === amountNonDropboxImages) {
      return {
        ...all,
        [idForImages]: sub_folder,
      }
    }
    return all
  }, {})

  const { mapping } = createCameraSubFolderMapping(cameraProtoArtifacts, dropboxDirectories)
  let newCameraProtoArtifacts = [...cameraProtoArtifacts]
  let showCameraDialog = true
  let imagesErrors = []
  if (cameraProtoArtifacts.length === 1) {
    const {
      protoArtifacts: updatedCameraArtifacts,
      errors: updatedImagesErrors,
    } = cameraProtoArtifacts.reduce((all, protoArtifact, index) => {
      const { protoArtifacts, errors } = processImages(
        [
          ...dropboxImages,
          ...dropboxPfsFiles,
          ...nonDropboxImages,
          ...nonDropboxPfsFiles,
        ],
        [protoArtifact],
        pfsFiles,
        true,
      )

      return {
        protoArtifacts: [...all.protoArtifacts, protoArtifacts[0]],
        errors: [...all.errors, ...errors],
      }
    }, {
      protoArtifacts: [],
      errors: [],
    })
    newCameraProtoArtifacts = updatedCameraArtifacts
    showCameraDialog = false
    imagesErrors = updatedImagesErrors
  }

  const updatedProtoArtifacts = protoArtifacts.map(protoArtifact => {
    return findById(protoArtifact.id, newCameraProtoArtifacts) || protoArtifact
  })
  // Filter unmatchedFileSuggestions array to remove gcp files and prevent them from being uploaded to the Mission uploader
  const filteredUnmatchedFileSuggestions = unmatchedFileSuggestions.filter(file => !isGcpFileName(file))
  /*
  // Next two functions do the actual processing of files:
  const { protoArtifacts: updatedProtoArtifacts, errors: imagesErrors } = processImages(
    sortedImages,
    protoArtifacts,
    pfsFiles,
  )
  */
  const { filesToCreateProtos, errors: filesErrors } = processFiles(
    missionTemplate,
    filesForCurrentMission,
    files,
    filteredUnmatchedFileSuggestions,
    missingFileSuggestions,
  )

  const plp = plpFile &&
    plpFile.parseResult &&
    plpFile.parseResult.result &&
    plpFile.parseResult.result.data

  const protoArtifactsReducers = [
    // Create new suggestion artifacts.
    createSuggestionProtoArtifacts,
    // Merge new files into already present `ProtoArtifact`s.
    mergeFilesIntoProtoArtifacts,
    // Update artifact properties
    updateProtoArtifactProperties,
    // Generate new `ProtoArtifacts` from the remaining files.
    groupFilesIntoProtoArtifacts,
    // Merge existing `ProtoArtifact`s into suggestion `ProtoArtifact`s.
    mergeSuggestionProtoArtifacts,
    // Regenerate the artifact names.
    regenerateArtifactNames,
  ]

  const result = protoArtifactsReducers.reduce(
    (oldState, reducer) => reducer(oldState),
    {
      plp: plp || addedPlp[currentMissionId],
      protoArtifacts: updatedProtoArtifacts,
      files: filesToCreateProtos,
      missionId: currentMissionId,
    },
  )

  const currentSessionFiles = [
    otherFiles.find(file => isPlpFileType(file.fileType)) || undefined,
    ...filesToCreateProtos,
  ].filter(file => file)
  const newMissions = mapById(currentMissionId, missions, mission => ({
    ...mission,
    name: updateMissionName(appState, missionType, mission.name, currentSessionFiles),
  }),
  )
  return state.merge({
    errors: {
      ...state.get('errors'),
      [currentMissionId]: [
        ...errors,
        ...imagesErrors,
        ...filesErrors,
      ],
    },
    [filesField]: sortFilesByName([
      ...state.get(filesField),
      ...filesToCreateProtos,
      ...otherFiles,
    ]),
    analyzing: false,
    ...(showCameraDialog && isImagesAdded && cameraProtoArtifacts.length > 0 && {
      analyzing: true,
      showCameraArtifactsMapping: true,
      cameraFolders: cameraProtoArtifacts.map(protoArtifact => protoArtifact.properties.sub_folder),
      predefineFolder: {
        ...mapping,
        ...nonDropboxFoldersMapping,
      },
      ...(
        isNonDropboxImageExists
          ? {
            systemFolders: [idForImages],
            systemKeptImages: { [idForImages]: nonDropboxImages },
            systemPfsFiles: { [idForImages]: nonDropboxPfsFiles },
          }
          : {
            systemFolders: [],
            systemKeptImages: {},
            systemPfsFiles: {},
          }
      ),
      dropboxFolders: dropboxDirectories,
      keptImages: dropboxImagesByDirectory,
      keptPfsFiles: dropboxPfsFilesByDirectory,
    }),
    missions: newMissions,
    // Before adding proto artifacts we scrub invalid proto artifacts
    [artifactsField]: scrubInvalidProtoArtifacts(result.protoArtifacts).reduce((all, protoArtifact) => [
      ...all,
      {
        ...protoArtifact,
        files: sortFilesByName(protoArtifact.files),
      },
    ], []),
    plp: typeof plp === 'string' ? { ...addedPlp, [currentMissionId]: plp } : addedPlp,
  })
}

export const setCameraFoldersMapping = (state, { mapping }) => {
  const errors = state.get('errors')
  const imagesByDirectory = {
    ...state.get('keptImages'),
    ...state.get('systemKeptImages'),
  }
  const pfsFilesByDirectory = {
    ...state.get('keptPfsFiles'),
    ...state.get('systemPfsFiles'),
  }
  const currentMissionId = state.get('currentMissionId')
  const protoArtifacts = state.get(MissionProtoArtifacts)
  const cameraProtoArtifacts = getArtifactsOfType(ArtifactTypes.CAMERA_DATA, protoArtifacts)

  const { protoArtifacts: newCameraProtoArtifacts, errors: imagesErrors } = cameraProtoArtifacts.reduce((all, protoArtifact) => {
    const { sub_folder } = protoArtifact.properties
    const { files: images } = getImagesBySubFolder(sub_folder, mapping, imagesByDirectory, [])
    const { files: pfsFiles } = getImagesBySubFolder(sub_folder, mapping, pfsFilesByDirectory, [])

    const { protoArtifacts, errors } = processImages(
      images,
      [protoArtifact],
      pfsFiles,
      true,
    )

    return {
      protoArtifacts: [...all.protoArtifacts, protoArtifacts[0]],
      errors: [...all.errors, ...errors],
    }
  }, {
    protoArtifacts: [],
    errors: [],
  })
  const updatedProtoArtifacts = protoArtifacts.map(protoArtifact => {
    return findById(protoArtifact.id, newCameraProtoArtifacts) || protoArtifact
  })

  return state.merge({
    errors: {
      ...errors,
      [currentMissionId]: [
        ...(errors[currentMissionId] || []),
        ...imagesErrors,
      ],
    },
    analyzing: false,
    showCameraArtifactsMapping: false,
    cameraFolders: [],
    dropboxFolders: [],
    keptImages: {},
    keptPfsFiles: {},
    systemKeptImages: {},
    systemPfsFiles: {},
    systemFolders: [],
    predefineFolder: {},
    [MissionProtoArtifacts]: updatedProtoArtifacts,
  })
}

export const filesAnalyzeLoading = state => state.merge({ analyzing: true })

export const gotoStep = (state, { step }) => {
  // Don't allow invalid steps, skipping a step or going to the current step again.
  if (step > maxStep || step < 0 || step === state.step) {
    return state
  }
  return state.merge({ step })
}

export const removeFile = (state, { file: fileToRemove, filesField, artifactsField }) => {
  const files = state.get(filesField).filter(file => file.file !== fileToRemove)

  const scrubbedProtoArtifacts = scrubInvalidProtoArtifacts(
    removeFileFromProtoArtifacts(state.get(artifactsField), fileToRemove),
  )
  return state.merge({
    [filesField]:
      files.filter(file =>
        scrubbedProtoArtifacts.find(proto => proto.files.find(protoFile => protoFile.file === file.file)) ||
          isPlpFileType(file.fileType),
      ),
    [artifactsField]: scrubbedProtoArtifacts,
  })
}

// receive one image at a time
export const updateProtoArtifactsAddImages = (state, { files, protoArtifactId }) => {
  const protoArtifacts = state.get(MissionProtoArtifacts)
  const newProtoArtifacts = mapById(
    protoArtifactId,
    protoArtifacts,
    protoArtifact => getNewProtoArtifactForImages(protoArtifact, files),
  )
  return state.merge({ [MissionProtoArtifacts]: newProtoArtifacts })
}
/*
  // Step 2/3 - Images and reference stations:
  if (action.type === 'IMPORT_WIZARD/IMAGES/ADD' || action.type === 'IMPORT_WIZARD/REFERENCE_STATION/ADD_FILES') {
  const { files: fileList, protoArtifactId } = action
*/
export const updateProtoArtifactsAdd = (state, { files, protoArtifactId }) => {
  //  const { protoArtifacts } = getImportWizard(state, [ 'protoArtifacts' ])
  const protoArtifacts = state.get(MissionProtoArtifacts)

  const newProtoArtifacts = mapById(protoArtifactId, protoArtifacts, protoArtifact => {
    const fileType = isCameraArtifact(protoArtifact.artifactType) ? ImageFileType
      : isRefStationArtifact(protoArtifact.artifactType) ? ReferenceStationFileType
        : 'unknown'
    const newFiles = [
      ...protoArtifact.files,
      ...files.map(file => ({
        file,
        fileType: fileType,
        okay: true,
      })),
    ]
    return {
      ...protoArtifact,
      files: newFiles,
      name: isRefStationArtifact(protoArtifact.artifactType)
        ? generateArtifactName(protoArtifact.artifactType, newFiles.map(file => file.file))
        : protoArtifact.name,
    }
  })

  return state.merge({ [MissionProtoArtifacts]: newProtoArtifacts })
}

export const updateRefProtoArtifactsAdd = (state, { files, protoArtifactId }) => {
  const protoArtifacts = [...state.get(RefProtoArtifacts)]
  const rawFiles = files.map(file => file.file)
  const newProtoArtifacts = mapById(protoArtifactId, protoArtifacts, protoArtifact => {
    const protoArtifactFiles = protoArtifact.files
    const { uniqueFiles } = makeFilesUnique(rawFiles)
    const inputFiles = uniqueFiles.filter(
      file => !protoArtifactFiles.find(protoArtFile => protoArtFile.file.name === file.name),
    )
    const newFiles = [
      ...protoArtifactFiles,
      ...inputFiles.map(file => {
        const actualFile = files.find(file_ => file_.file === file)
        return actualFile
      }),
    ]
    return {
      ...protoArtifact,
      files: newFiles,
      name: !protoArtifact.name
        ? generateArtifactName(protoArtifact.artifactType, newFiles.map(file => file.file))
        : protoArtifact.name,
    }
  })

  return state.merge({ [RefProtoArtifacts]: newProtoArtifacts })
}

export const updateRefProtoName = (state, { id, name }) => {
  const protoArtifacts = state.get(RefProtoArtifacts)
  const newProtoArtifacts = mapById(id, protoArtifacts, protoArtifact => {
    return {
      ...protoArtifact,
      name,
    }
  })

  return state.merge({ [RefProtoArtifacts]: newProtoArtifacts })
}

export const updateRefProtoArtifactsRemove = (state, { file: fileToRemove, protoArtifactId }) => {
  const protoArtifacts = state.get(RefProtoArtifacts)
  const newProtoArtifacts = mapById(protoArtifactId, protoArtifacts, protoArtifact => ({
    ...protoArtifact,
    files: protoArtifact.files.filter(file => file.file !== fileToRemove),
  })).filter(protoArtifact => protoArtifact.files.length > 0)

  return state.merge({ [RefProtoArtifacts]: newProtoArtifacts })
}

export const removeImages =
  (state, { file: fileToRemove, protoArtifactId, protoArtifactsField = MissionProtoArtifacts }) => {
    const protoArtifacts = state.get(protoArtifactsField)
    const newProtoArtifacts = mapById(protoArtifactId, protoArtifacts, protoArtifact => {
      const protoArtifactFiles = protoArtifact.files
      const newFiles = protoArtifact.files.filter(file => file.file !== fileToRemove)
      if (isPfsFileName(fileToRemove.name)) {
        const pfsFileInProtoArtifact = protoArtifactFiles.find(file => file.file === fileToRemove)
        if (pfsFileInProtoArtifact) {
          return {
            ...protoArtifact,
            name: generateArtifactName(protoArtifact.artifactType, newFiles.map(file => file.file)),
            files: newFiles,
            properties: {
              ...protoArtifact.properties,
              number_of_images: protoArtifact.properties.number_of_images - 1,
            },
          }
        }
      }
      return {
        ...protoArtifact,
        name: generateArtifactName(protoArtifact.artifactType, newFiles.map(file => file.file)),
        files: newFiles,
      }
    })

    return state.merge({
      [protoArtifactsField]: newProtoArtifacts,
    })
  }

export const clearImages = (state, { protoArtifactId, protoArtifactsField = MissionProtoArtifacts }) => {
  const protoArtifacts = state.get(protoArtifactsField)
  /*
  const newProtoArtifacts = mapById(protoArtifactId, protoArtifacts, protoArtifact => ({
    ...protoArtifact,
    files: protoArtifact.files.filter(file => file.fileType !== 'image'),
  }))
  */

  const newProtoArtifacts = mapById(protoArtifactId, protoArtifacts, protoArtifact => {
    const protoArtifactFiles = protoArtifact.files
    const pfsFilesInProtoArtifact = protoArtifactFiles.filter(file => isPfsFileName(file.file.name))
    const nonImageFiles = protoArtifactFiles.filter(file => !isImageFileType(file.fileType))
    if (pfsFilesInProtoArtifact.length > 0) {
      return {
        ...protoArtifact,
        files: nonImageFiles,
        properties: {
          ...protoArtifact.properties,
          number_of_images: protoArtifact.properties.number_of_images - pfsFilesInProtoArtifact.length,
        },
      }
    }
    return {
      ...protoArtifact,
      name: generateArtifactName(protoArtifact.artifactType, nonImageFiles),
      files: nonImageFiles,
    }
  })

  return state.merge({
    [protoArtifactsField]: newProtoArtifacts,
  })
}

export const addReferenceStation = state => {
  const protoArtifacts = state.get(RefProtoArtifacts)
  const newProtoArtifacts = [
    ...protoArtifacts,
    {
      ...createProtoArtifact(ArtifactTypes.REFERENCE_STATION),
      // name: getDefaultArtifactName(ArtifactTypes.REFERENCE_STATION),
    },
  ]
  return state.merge({ [RefProtoArtifacts]: newProtoArtifacts })
}

export const removeReferenceStation = (state, { protoArtifactId }) => {
  const protoArtifacts = state.get(RefProtoArtifacts)
  const newProtoArtifacts = removeById(protoArtifactId, protoArtifacts)
  return state.merge({ [RefProtoArtifacts]: newProtoArtifacts })
}

export const setProtoArtifactProperties = (state, { protoArtifactId, properties, protoArtifactsField }) => {
  const protoArtifacts = state.get(protoArtifactsField)
  const newProtoArtifacts = mapById(protoArtifactId, protoArtifacts, protoArtifact => ({ ...protoArtifact, properties }))
  return state.merge({ [protoArtifactsField]: newProtoArtifacts })
}

export const reset = (state, { fieldsToReset }) => {
  const initState = INITIAL_STATE.toObject()
  const missions = state.get('missions')
  const currentMissionId = state.get('currentMissionId')
  const mission = findById(currentMissionId, missions)
  const missionType = (mission && (mission.type || mission.missionType)) || ''
  if (missionType === SystemType.RECON && fieldsToReset) {
    return state.merge({
      ...fieldsToReset.filter(fieldName => fieldName !== 'missions' && fieldName !== 'currentMissionId')
        .reduce((allFields, field) => ({
          ...allFields,
          [field]: initState[field],
        }), {}),
    })
  }
  if (fieldsToReset) {
    return state.merge({
      ...fieldsToReset.reduce((allFields, field) => ({
        ...allFields,
        [field]: initState[field],
      }), {}),
    })
  }
  return state.merge({ initState })
}
/**
 * Returns default mission name based on selected `missionType`
 * and available `projectMissions`
 * @param {String} missionType
 * @param {Array} projectMissions
 * @returns {String}
 */
const getDefaultMissionName = (missionType, projectMissions = []) => {
  if (
    missionType === SystemType.POINTCLOUD_PROCESSINGS ||
    missionType === SystemType.RECON
  ) {
    return i18next.t('importWizard.missionName')
  }
  return getNewMissionName(projectMissions)
}

export const addMission = (state, { appState }) => {
  const projectMissions = getMissions(appState)
  const loggedUser = getLoggedUser(appState)
  const companyId = loggedUser && loggedUser.company && loggedUser.company.id
  const allowedSystemTypes = getCompanySystemTypes(appState, companyId)
  const allSystemTypes = getAllSystemTypes(appState)
  const systemTypes = isAdmin(appState)
    ? allSystemTypes
    : allowedSystemTypes
  const defaultSystemType = systemTypes.find(({ name }) => name === defaultMissionType) || systemTypes[0]
  const missionType = defaultSystemType && defaultSystemType.name
  const missionName = getDefaultMissionName(missionType, projectMissions)
  const missions = state.get('missions')
  const newMissionIndex =
    missions.length > 0
      ? parseInt(missions[missions.length - 1].id) + 1
      : 1
  const missionId = `${newMissionIndex}`
  return state.merge({
    missions: [...missions,
      {
        id: missionId,
        type: missionType,
        name: missionName,
      },
    ],
    currentMissionId: missionId,
  })
}

export const removeCurrentMission = state => {
  const missions = state.get('missions')
  const currentMissionId = state.get('currentMissionId')

  if (missions.length > 1) {
    const files = state.get(MissionFiles)
    const protoArtifacts = state.get(MissionProtoArtifacts)
    const newMissions = removeById(currentMissionId, missions)
    return state.merge({
      missions: newMissions,
      files: removeByMissionId(currentMissionId, files),
      [MissionProtoArtifacts]: removeByMissionId(currentMissionId, protoArtifacts),
      currentMissionId: newMissions[newMissions.length - 1].id,
    })
  } else {
    return state.merge({
      ...INITIAL_STATE.toObject(),
      missions: [{
        id: '1',
        type: defaultMissionType,
      }],
      currentMissionId: '1',
    })
  }
}

export const changeMissionType = (state, { id, missionType, appState }) => {
  const projectMissions = getMissions(appState)
  const protoArtifacts = state.get(MissionProtoArtifacts)
  const files = state.get(MissionFiles)
  const errors = state.get('errors')
  return state.merge({
    missions: mapById(id, state.get('missions'), mission => ({
      ...mission,
      type: missionType,
      name: getDefaultMissionName(missionType, projectMissions),
    })),
    files: removeByMissionId(id, files),
    [MissionProtoArtifacts]: removeByMissionId(id, protoArtifacts),
    errors: omit([id], errors),
  })
}

export const changeCurrentMissionId = (state, { id }) => {
  return state.merge({
    currentMissionId: id,
  })
}

export const changeCurrentMissionName = (state, { id, missionName }) => {
  return state.merge({
    missions: mapById(id, state.get('missions'), mission => ({ ...mission, name: missionName })),
  })
}

const transformInfToNull = value => {
  return value === Infinity || value === -Infinity ? null : value
}

export const openEditDialog = (state, { missionId, mission, artifacts = [], files = [] }) => {
  const plp = mission.plp
  let protoArtifacts = []
  if (plp) {
    protoArtifacts = (generateProtoArtifacts(`${mission.name}.plp`, plp))
      .map(artifact => ({
        ...artifact,
        missionId,
      }))
      .filter(proto =>
        // Just keep artifacts which are
        // 1) Non-camera artifacts
        // 2) Camera artifacts without any corresponding `Camera` artifact in artifacts array
        !isCameraArtifact(proto.artifactType) ||
        (isCameraArtifact(proto.artifactType) && !artifacts.find(art =>
          equals(art.properties.offset, proto.properties.offset) &&
          equals(art.properties.rotation, proto.properties.rotation) &&
          equals(art.properties.index, proto.properties.index) &&
          transformInfToNull(path(['properties', 'interval', 'beginning', 'tow'], proto)) ===
            path(['properties', 'interval', 'beginning', 'tow'], art) &&
          transformInfToNull(path(['properties', 'interval', 'beginning', 'week'], proto)) ===
            path(['properties', 'interval', 'beginning', 'week'], art) &&
          transformInfToNull(path(['properties', 'interval', 'end', 'tow'], proto)) ===
            path(['properties', 'interval', 'end', 'tow'], art) &&
          transformInfToNull(path(['properties', 'interval', 'end', 'week'], proto)) ===
            path(['properties', 'interval', 'end', 'week'], art),
        )))
  }
  return state.merge({
    editMissionId: missionId,
    editMission: mission,
    editDialogOpen: true,
    editMissionPlp: null,
    editMissionArtifacts: artifacts,
    editMissionProtoFiles: files,
    editMissionProtoArtifacts: protoArtifacts,
  })
}

export const closeEditDialog = (state, { missionId }) => {
  return state.merge({
    errors: omit([missionId], state.get('errors')),
    editMissionId: null,
    editMission: {},
    editDialogOpen: false,
    editMissionArtifacts: [],
    editMissionProtoArtifacts: [],
    editMissionProtoFiles: [],
    editMissionFiles: [],
  })
}

export const closeEditGCPDialog = (state, { artifactId }) => {
  return state.merge({
    editGcpProtoArtifacts: [],
    editGcpFiles: [],
  })
}

export const removeErrors = (state, { missionId }) => {
  return state.merge({
    errors: omit([missionId], state.get('errors')),
  })
}

export const setAnalyze = (state, { analyzing }) => {
  return state.merge({
    analyzing,
  })
}

export const setDropboxPath = (state, { path }) =>
  state.merge({
    lastDropboxPath: path,
  })

export const resetState = state => state.merge({
  ...INITIAL_STATE.toObject(),
})

export const setConfirm = (state, { artifactsField, confirm }) => {
  return state.merge({
    confirmIsLoading: {
      ...state.get('confirmIsLoading'),
      [artifactsField]: confirm,
    },
  })
}

export const getGCPsLoading = (state, { artifactId }) =>
  state.merge({
    gcps: {
      ...state.get('gcps'),
      [artifactId]: [],
    },
    gcpsLoading: {
      ...state.get('gcpsLoading'),
      [artifactId]: true,
    },
  })
export const getGCPsSuccess = (state, { gcps, artifactId }) => {
  const gcpsPoints = gcps.points.map(convertRawPosition)
  return state.merge({
    pipelineStrings: {
      ...state.get('pipelineStrings'),
      [artifactId]: (gcps || {}).pipeline_string,
    },
    gcps: {
      ...state.get('gcps'),
      [artifactId]: gcpsPoints,
    },
    gcpsLoading: {
      ...state.get('gcpsLoading'),
      [artifactId]: false,
    },
  })
}
export const getGCPsFailure = (state, { errorMessage, artifactId }) => state.merge({
  gcpsLoading: {
    ...state.get('gcpsLoading'),
    [artifactId]: false,
  },
})

export const checkUnitsLoading = (state, { artifactId }) => state.merge({})
export const checkUnitsFailure = (state, { errorMessage, artifactId }) => state.merge({})
export const checkUnitsSuccess = (state, { artifactId, crs_name, check }) => {
  const checkUnits = state.get('checkUnits')
  const lonlat = check.lonlat
  return state.merge({
    checkUnits: {
      ...checkUnits,
      [artifactId]: {
        ...checkUnits[artifactId],
        [crs_name]: lonlat,
      },
    },
  })
}

export const getCRSInfoLoading = (state, { artifactId }) => state.merge({})
export const getCRSInfoFailure = (state, { errorMessage, artifactId }) => state.merge({})
export const getCRSInfoSuccess = (state, { artifactId, crsInfo }) => {
  const currentCrsInfo = state.get('crsInfo')
  return state.merge({
    crsInfo: {
      ...currentCrsInfo,
      [artifactId]: {
        ...(currentCrsInfo[artifactId] || {}),
        ...crsInfo,
      },
    },
  })
}

export const transformFromCRSToCRSLoading = (state, { artifactId }) => state.merge({})
export const transformFromCRSToCRSSuccess = (state, { transform }) =>
  state.merge({
    demoCrsTransform: {
      ...transform,
      points: transform.points.map(convertRawPosition),
    },
  })
export const transformFromCRSToCRSFailure = (state, { errorMessage, artifactId }) => state.merge({})

export const getRecommendedCRSLoading = state => state.merge({})
export const getRecommendedCRSFailure = state => state.merge({})
export const getRecommendedCRSSuccess = (state, { projectId, id, recommended }) => {
  return state.merge({
    recommendedCRS: {
      ...state.get('recommendedCRS'),
      [(id || projectId)]: {
        compoundCRS: recommended.compound,
        horizontalCRS: recommended.horizontal,
        verticalCRS: recommended.vertical,
        verticalGeoids: recommended.vertical_geoids,
        initialHorizontalCRS: recommended.recommended.utm_projected_crs,
        initialVerticalCRS: recommended.recommended.geographic_3d_crs,
      },
    },
  })
}

export const resetSettingsFiles = state => state.merge({
  settingsFiles: [],
  plp: {
    ...state.get('plp'),
    'settingsFiles': null,
  },
  settingsFilesProcessingType: 'sessions',
})

export const setSettingsFilesProcessingType = (state, { processingType }) => state.merge({
  settingsFilesProcessingType: processingType,
})

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_STATE]: resetState,
  [Types.FILES_ANALYZED]: filesAnalyzed,
  [Types.MISSION_FILES_ANALYZED]: missionFilesAnalyzed,
  [Types.FILES_ANALYZE_LOADING]: filesAnalyzeLoading,
  [Types.GOTO_STEP]: gotoStep,
  [Types.REMOVE_FILE]: removeFile,
  [Types.ADD_IMAGES]: updateProtoArtifactsAddImages,
  [Types.REMOVE_IMAGES]: removeImages,
  [Types.CLEAR_IMAGES]: clearImages,
  [Types.ADD_MISSION]: addMission,
  [Types.REMOVE_CURRENT_MISSION]: removeCurrentMission,
  [Types.CHANGE_CURRENT_MISSION_ID]: changeCurrentMissionId,
  [Types.CHANGE_CURRENT_MISSION_NAME]: changeCurrentMissionName,
  [Types.CHANGE_MISSION_TYPE]: changeMissionType,
  [Types.SET_REFERENCE_STATION_NAME]: updateRefProtoName,
  [Types.ADD_REFERENCE_STATION]: addReferenceStation,
  [Types.ADD_REFERENCE_STATION_FILES_SUCCESS]: updateRefProtoArtifactsAdd,
  [Types.REMOVE_REFERENCE_STATION_FILE]: updateRefProtoArtifactsRemove,
  [Types.REMOVE_REFERENCE_STATION]: removeReferenceStation,
  [Types.SET_PROTO_ARTIFACT_PROPERTIES]: setProtoArtifactProperties,
  [Types.OPEN_EDIT_DIALOG]: openEditDialog,
  [Types.CLOSE_EDIT_DIALOG]: closeEditDialog,
  [Types.CLOSE_EDIT_GCP_DIALOG]: closeEditGCPDialog,
  [Types.RESET]: reset,
  [Types.SET_ANALYZE]: setAnalyze,
  [Types.SET_DROPBOX_PATH]: setDropboxPath,
  [Types.REMOVE_ERRORS]: removeErrors,
  [Types.SET_CONFIRM]: setConfirm,

  [Types.GET_RECOMMENDED_CRS_LOADING]: getRecommendedCRSLoading,
  [Types.GET_RECOMMENDED_CRS_SUCCESS]: getRecommendedCRSSuccess,
  [Types.GET_RECOMMENDED_CRS_FAILURE]: getRecommendedCRSFailure,

  [Types.GET_GCPS_LOADING]: getGCPsLoading,
  [Types.GET_GCPS_SUCCESS]: getGCPsSuccess,
  [Types.GET_GCPS_FAILURE]: getGCPsFailure,

  [Types.CHECK_UNITS_LOADING]: checkUnitsLoading,
  [Types.CHECK_UNITS_SUCCESS]: checkUnitsSuccess,
  [Types.CHECK_UNITS_FAILURE]: checkUnitsFailure,

  [Types.GET_CRS_INFO_LOADING]: getCRSInfoLoading,
  [Types.GET_CRS_INFO_SUCCESS]: getCRSInfoSuccess,
  [Types.GET_CRS_INFO_FAILURE]: getCRSInfoFailure,

  [Types.TRANSFORM_FROM_CRS_TO_CRS_LOADING]: transformFromCRSToCRSLoading,
  [Types.TRANSFORM_FROM_CRS_TO_CRS_SUCCESS]: transformFromCRSToCRSSuccess,
  [Types.TRANSFORM_FROM_CRS_TO_CRS_FAILURE]: transformFromCRSToCRSFailure,

  [Types.RESET_SETTINGS_FILES]: resetSettingsFiles,
  [Types.SET_SETTINGS_FILES_PROCESSING_TYPE]: setSettingsFilesProcessingType,

  [Types.SET_CAMERA_FOLDERS_MAPPING]: setCameraFoldersMapping,
})
