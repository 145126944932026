import React from 'react'
import { Trans } from 'react-i18next'
import i18n from 'i18n'
import { path } from 'ramda'
import AxisOrientationImage from 'assets/images/axis-orientation.png'
// Local deps
import {
  coordinateFieldDisabled,
  getValueFromArtifact,
  getAntennaOptions,
  getValueFromSettingsFile,
  getNavRoverInitialValues,
  getCloneArtifactOptionValue,
  safeToString,
  getFavouriteOptions,
  addFavoriteOptionToCookie,
} from '../utils'
import { DataType } from 'types/form'
import {
  isImuPresent,
  processorToFrontendAntennaName,
  backendProcessorToFrontendAntennaName,
  getAntennaTemplate,
} from '../jobIoOptionsUtils'
import { getAllArtifacts, getMissions } from 'modules/projects/selectors'
import { findById } from 'utils/list'
import { antennaInitialValue, DynamicsModelOptions, MapBackendNameToFrontend } from 'templates/constants'
import Tooltip from 'components/StyledTooltip'
import { Wiki } from 'wiki'
import { token } from 'modules/users/selectors'
import { getMinimumAlignmentVelocity } from 'modules/artifacts/api'
import { Cookies } from 'utils/constants'

/**
 * Returns true if `gnss_processing_only` option for current navigation rover options selected
 * @param {Object} values
 * @returns {Boolean}
 */
const isComputeGNSSAntennaSelected = values => {
  return values && values.gnss_processing_only
}
/**
 * Checks for dual antenna setup in PLP
 * @param {Object} state
 * @param {String} artifactId
 * @returns {Boolean}
 */
const isDualAntennaSetupInPLP = (state, artifactId) => {
  return getValueFromSettingsFile(state, ['settingsRover', 'navigationSystem', 'isDualAntennaSetup'])
}
/**
 * Checks for dual antenna setup in artifact properties
 * @param {Object} state
 * @param {String} artifactId
 * @returns {Boolean}
 */
const isDualAntennaSetupInArtifactProperties = (state, artifactId) => {
  return getValueFromArtifact(state, artifactId, ['isDualAntenna'])
}

/**
 * Checks for dual antenna setup
 * Combination from `isDualAntennaSetupInPLP` and `isDualAntennaSetupInArtifactProperties`
 * @param {Object} state
 * @param {String} artifactId
 * @returns {Boolean}
 */
const isDualAntennaSetup = (state, artifactId) => {
  const plpIsDualAntennaValue = isDualAntennaSetupInPLP(state, artifactId)
  return typeof plpIsDualAntennaValue === 'undefined' ? isDualAntennaSetupInArtifactProperties(state, artifactId) : plpIsDualAntennaValue
}

/**
 * Checks if the dynamics model is set to 'Automatic'.
 * @param {Object} values - The object containing the dynamics model information.
 * @returns {boolean} True if the dynamics model is 'Automatic', false otherwise.
 */
const isAutomaticDynamicsModel = values => {
  return values.dynamicsModel === 'Automatic'
}

/**
 * Determines if the minimum alignment velocity is disabled based on various conditions.
 * @param {Object} values - The object that includes the dynamics model, minimum GNSS velocity, and loading state.
 * @returns {boolean} True if the minimum alignment velocity should be disabled, false otherwise.
 */

const isDisabledMinAlignVelocity = values => {
  return isAutomaticDynamicsModel(values)
    || (values.min_gnss_velocity && values.min_gnss_velocity === '')
    || values.isMinVelocityLoading
}

/**
 * Fetches and updates the minimum alignment velocity for a given dynamics model.
 * This function asynchronously retrieves the minimum GNSS velocity using a specified dynamics model,
 * then updates the application state accordingly.
 * 
 * @param {string} artifactId - The unique identifier for the artifact.
 * @param {string} dynamicsModel - The model used to determine the dynamics involved.
 * @param {function} updateState - Function to update the state in the application.
 */
function updateMinimumAlignmentVelocity(artifactId, dynamicsModel, updateState) {
  updateState({ isMinVelocityLoading: true })
  setTimeout(function () {
    getMinimumAlignmentVelocity(artifactId, dynamicsModel)
      .then(minVelocity => {
        updateState({ min_gnss_velocity: minVelocity.toString(), isMinVelocityLoading: false })
      })
      .catch(error => {
        updateState({ isMinVelocityLoading: false })
      })
  }, 100)
}

export default {
  gnss_processing_only: {
    name: 'Compute only GNSS antenna positions (used only for SLAM)',
    dataType: DataType.BOOLEAN,
    // will merge this option into job run options
    mergeToJobRunOptions: true,
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        // support for value with old name
        const oldValue = getCloneArtifactOptionValue(state, artifactId, options, 'differential_gnss')
        const newValue = getCloneArtifactOptionValue(state, artifactId, options, 'gnss_processing_only')
        return oldValue || newValue || false
      }
      return false
    },
    // set the value of Minimum Alignment Velocity when changing dynamics model
    onChange: (name, value, options) => {
      const { state, setMultipleValues, extra, values } = options
      updateMinimumAlignmentVelocity(extra, value ? 'Pedestrian' : 'Automatic', setMultipleValues)
    }
  },
  // state for handling if request was made to the server for a getting min_gnss_velocity
  isMinVelocityLoading: {
    dataType: DataType.BOOLEAN,
    invisible: true,
    sendToBackend: false,
    initialValue: false,
    transformOnChangeOf: ['min_gnss_velocity', 'gnss_processing_only'],
    transform: () => {
      return false
    },
  },
  // The dynamics model defines the type of the drone (ship, guy, drone, ...).
  // It is used to tune `NavLab` to correctly calculate everything.
  // It can only be selected when the corresponding property existed on the `Artifact`.
  dynamicsModel: {
    name: MapBackendNameToFrontend.dynamicsModel,
    dataType: DataType.SELECTION,
    options: (state, formValues, extra) => {
      if (isComputeGNSSAntennaSelected(formValues)) {
        return DynamicsModelOptions.filter(dynamicsModel => dynamicsModel !== 'Automatic')
      }
      return DynamicsModelOptions
    },
    transformOnChangeOf: ['gnss_processing_only'],
    transform: (
      original,
      state,
      formValues,
      extra,
      formTemplate,
      name,
      oldValues,
      extraProps,
    ) => {
      if (isComputeGNSSAntennaSelected(formValues)) {
        return DynamicsModelOptions.find(dynamicsModel => dynamicsModel === 'Pedestrian')
      } else if (isComputeGNSSAntennaSelected(oldValues)) {
        return getCloneArtifactOptionValue(state, extra, { formTemplate, extraProps }, 'dynamicsModel', 'Automatic')
      }
      return original
    },
    // set the value of Minimum Alignment Velocity when changing dynamics model
    onChange: (name, value, options) => {
      const { state, setMultipleValues, extra, values } = options
      updateMinimumAlignmentVelocity(extra, value, setMultipleValues)
    },
    // Field is not required if no `Artifact` with `imu` options is present.
    // optional: (state, _values, extra) => !isImuPresent(state, extra),
    // Field is invisible if no `imu` is found in any of the `Artifact`s.
    // invisible: (state, _values, extra) => !isImuPresent(state, extra),
    initialValue: (state, extra, options) => getCloneArtifactOptionValue(state, extra, options, 'dynamicsModel', 'Automatic'),
    gridProps: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  /**
  * Represents the minimum GNSS velocity configuration for an artifact.
  * This setting is dynamically updated based on the dynamics model selected.
  * It's used primarily in scenarios where precise GNSS positioning is crucial,
  * such as in certain navigation and mapping applications.
  */
  min_gnss_velocity: {
    name: MapBackendNameToFrontend.min_gnss_velocity,
    dataType: DataType.FLOAT,
    getInitialValueWhileClone: async (artifactId, artifactSettings) => {
      if (!isDisabledMinAlignVelocity(artifactSettings)) {
        return await getMinimumAlignmentVelocity(artifactId, artifactSettings.dynamicsModel)
      }
      return artifactSettings.min_gnss_velocity
    },
    tooltip: (state, formValues, extra) => isDisabledMinAlignVelocity(formValues)
      ? i18n.t('templates.artifactOptions.navigationRover.tooltip.min_gnss_velocity_disabled')
      : i18n.t('templates.artifactOptions.navigationRover.tooltip.min_gnss_velocity'),
    optional: (state, formValues, extra) => isDisabledMinAlignVelocity(formValues),
    disabled: (state, formValues, extra) => isDisabledMinAlignVelocity(formValues),
    initialValue: (state, extra, options) => getCloneArtifactOptionValue(state, extra, options, 'min_gnss_velocity', ''),
    sendToBackend: true,
    gridProps: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  // The following three fields specify the relative rotation of the Imu to the rest of the drone.
  bodyToImuRotation: {
    name: <Tooltip placement='top-start' title={<Trans i18nKey='templates.artifactOptions.navigationRover.tooltip.bodyToImuRotation'/>}>
      <div>
        <Trans i18nKey='templates.artifactOptions.navigationRover.bodyToImuRotationLink' components={[
          <a key='link' target='_blank' rel='noopener noreferrer' href={Wiki.rotationOffsets}>link</a>,
        ]}/>
      </div>
    </Tooltip>,
    componentNames: ['X', 'Y', 'Z'],
    dataType: DataType.VECTOR3,
    precision: [3, 3, 3],
    disabled: (state, values, artifactId) => isComputeGNSSAntennaSelected(values),
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'bodyToImuRotation',
      () => getNavRoverInitialValues(
        state,
        () => {
          const pathInProperties = ['settingsRover', 'navigationSystem', 'imu', 'orientation']
          return [
            getValueFromSettingsFile(state, [...pathInProperties, 'x']),
            getValueFromSettingsFile(state, [...pathInProperties, 'y']),
            getValueFromSettingsFile(state, [...pathInProperties, 'z']),
          ].map(safeToString)
        },
        () => {
          const pathInProperties = ['inertial', 'bodyToImuRotation']
          return [
            getValueFromArtifact(state, artifactId, [...pathInProperties, 'x']),
            getValueFromArtifact(state, artifactId, [...pathInProperties, 'y']),
            getValueFromArtifact(state, artifactId, [...pathInProperties, 'z']),
          ].map(safeToString)
        }
      )
    ),
    containerNumber: 0,
    column: 0,
    columnWidth: 9,
  },
  // The relative offset of the lever arm to the body of the drone.
  leverArm1: {
    name: <Tooltip placement='top-start' title={<Trans i18nKey='templates.artifactOptions.navigationRover.tooltip.leverArm1'/>}>
      <div>
        <Trans i18nKey='templates.artifactOptions.navigationRover.leverArm1Link' components={[
          <a key='link' target='_blank' rel='noopener noreferrer' href={Wiki.rotationOffsets}>link</a>,
        ]}/>
      </div>
    </Tooltip>,
    componentNames: ['X', 'Y', 'Z'],
    dataType: DataType.VECTOR3,
    precision: [3, 3, 3],
    disabled: (state, values, artifactId) => isComputeGNSSAntennaSelected(values),
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'leverArm1',
      () => getNavRoverInitialValues(
        state,
        () => {
          const pathInProperties = ['settingsRover', 'navigationSystem', 'offsets', 'ant1', 'translation']
          return [
            getValueFromSettingsFile(state, [...pathInProperties, 'x']),
            getValueFromSettingsFile(state, [...pathInProperties, 'y']),
            getValueFromSettingsFile(state, [...pathInProperties, 'z']),
          ].map(safeToString)
        },
        () => {
          return [
            getValueFromArtifact(state, artifactId, ['leverArm1', 'x']),
            getValueFromArtifact(state, artifactId, ['leverArm1', 'y']),
            getValueFromArtifact(state, artifactId, ['leverArm1', 'z']),
          ].map(safeToString)
        }
      )
    ),
    warningMessage: (state, values, extra, formTemplate, option) => {
      const value = values.leverArm1
      if (value.every(val => parseFloat(val) === parseFloat(0))) {
        return i18n.t('templates.artifactOptions.navigationRover.warning.leverArm1')
      }
      return null
    },
    containerNumber: 0,
    column: 0,
    columnWidth: 9,
  },
  leverArm2: {
    name: <Tooltip placement='top-start' title={<Trans i18nKey='templates.artifactOptions.navigationRover.tooltip.leverArm2'/>}>
      <div>
        <Trans i18nKey='templates.artifactOptions.navigationRover.leverArm2Link' components={[
          <a key='link' target='_blank' rel='noopener noreferrer' href={Wiki.rotationOffsets}>link</a>,
        ]}/>
      </div>
    </Tooltip>,
    componentNames: ['X', 'Y', 'Z'],
    dataType: DataType.VECTOR3,
    precision: [3, 3, 3],
    disabled: (state, values, artifactId) => {
      if (isComputeGNSSAntennaSelected(values)) {
        return true
      }
      return !isDualAntennaSetup(state, artifactId)
    },
    optional: (state, values, artifactId) => {
      return isDualAntennaSetup(state, artifactId)
    },
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'leverArm2',
      () => getNavRoverInitialValues(
        state,
        () => {
          if (!isDualAntennaSetupInPLP(state, artifactId)) { return ['0', '0', '0'] }
          const pathInProperties = ['settingsRover', 'navigationSystem', 'offsets', 'ant2', 'translation']
          return [
            getValueFromSettingsFile(state, [...pathInProperties, 'x']),
            getValueFromSettingsFile(state, [...pathInProperties, 'y']),
            getValueFromSettingsFile(state, [...pathInProperties, 'z']),
          ].map(safeToString)
        },
        () => {
          if (!isDualAntennaSetupInArtifactProperties(state, artifactId)) { return ['0', '0', '0'] }
          return [
            getValueFromArtifact(state, artifactId, ['leverArm2', 'x']),
            getValueFromArtifact(state, artifactId, ['leverArm2', 'y']),
            getValueFromArtifact(state, artifactId, ['leverArm2', 'z']),
          ].map(safeToString)
        }
      )
    ),
    containerNumber: 0,
    column: 0,
    columnWidth: 9,
  },
  axisOrientationImage: {
    name: MapBackendNameToFrontend.axisOrientation,
    dataType: DataType.IMAGE,
    src: AxisOrientationImage,
    initialValue: null,
    sendToBackend: false,
    height: 175,
    containerNumber: 0,
    column: 1,
    columnWidth: 3,
  },
  antennaName: {
    name: MapBackendNameToFrontend.antennaName,
    tooltip: i18n.t('templates.artifactOptions.generic.tooltip.antennaName'),
    tooltipProps: {
      enterDelay: 500,
    },
    viewLimit: 4,
    dataType: DataType.ANTENNA_AUTOCOMPLETE,
    displayTemplate: getAntennaTemplate,
    searchTemplate: getAntennaTemplate,
    getValue: (state, value, values, extra) => {
      addFavoriteOptionToCookie(value, Cookies.favoriteAntennas.amount, Cookies.favoriteAntennas.name)
      return getAntennaTemplate(value)
    },
    compare: (suggestion, selectedItem) => suggestion.searchValue === selectedItem.searchValue,
    options: (state) => getFavouriteOptions(state, Cookies.favoriteAntennas.name),
    backendTransform: (original, state, formValues) => original.value,
    disabled: (state, values) => coordinateFieldDisabled(state, values),
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      const artifact = findById(artifactId, getAllArtifacts(state))
      const mission = findById(artifact.missionId, getMissions(state))
      if (clone) {
        const value = getCloneArtifactOptionValue(state, artifactId, options, 'antennaName')
        if (value) {
          return getAntennaOptions(state).find(antenna => antenna.value === value)
        }
      }
      const gnssRoverAntenna = path(['plp', 'sessionsGnssRover', 'antenna'], mission)
      const settingsRoverAntenna = path(['plp', 'settingsRover', 'navigationSystem', 'antenna'], mission)
      if (gnssRoverAntenna) {
        return getAntennaOptions(state).find(antenna => (
          antenna.value === gnssRoverAntenna.name &&
          antenna.radome === gnssRoverAntenna.radome
        )) || antennaInitialValue
      }
      if (settingsRoverAntenna) {
        return getAntennaOptions(state).find(antenna => (
          antenna.value === settingsRoverAntenna
        )) || antennaInitialValue
      }
      return antennaInitialValue
    },
  },
  antennaRadome: {
    dataType: DataType.STRING,
    invisible: true,
    optional: true,
    initialValue: null,
    sendToBackend: true,
    backendTransform: (original, state, formValues) => {
      if (formValues.antennaName) {
        return formValues.antennaName.radome || null
      }
      return null
    },
  },
  heightAntennaReference: {
    /**
     * This one is discussed a lot so I leave a reference to the issue
     * https://gitlab.com/phoenixlidar/lidarmill-frontend/-/issues/1117
     */
    // invisible: state => !isAdmin(state),
    // adminOnly: true,
    name: i18n.t('templates.artifactOptions.generic.heightAntennaReference'),
    dataType: DataType.SELECTION,
    tooltip: i18n.t('templates.artifactOptions.generic.tooltip.heightAntennaReference'),
    tooltipProps: {
      enterDelay: 500,
    },
    options: [
      backendProcessorToFrontendAntennaName.l1a,
      backendProcessorToFrontendAntennaName.arp,
    ],
    mapping: {
      [processorToFrontendAntennaName.AbsoluteL1PhaseCenter]: 'l1a',
      [processorToFrontendAntennaName.AntennaReferencePoint]: 'arp',
      // 'L1 Relative': 'l1r',
    },
    disabled: (state, values) => coordinateFieldDisabled(state, values),
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const value = getCloneArtifactOptionValue(state, artifactId, options, 'heightAntennaReference')
        if (value) {
          return backendProcessorToFrontendAntennaName[value]
        }
      }
      const key = getValueFromArtifact(state, artifactId, ['antenna', 'heightReference'])
      const processor = key in processorToFrontendAntennaName
        ? processorToFrontendAntennaName
        : backendProcessorToFrontendAntennaName
      return processor[key]
    },
  },
}
