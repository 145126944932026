// Project deps
// Local deps
import { DataType } from 'types/form'
import { getLasTemplateFieldInitialValue } from 'templates/utils'
import { LasSettingsFrontEndOptionNames, MapLasSettingsBackendOptionsToFrontend, MapLasSettingsFrontendOptionsToBackend } from 'templates/constants'

export const LasSettingsTemplate = {
  psid: {
    name: (state, formValues, extra, extraProps) => {
      const { settings } = extraProps
      if (settings && settings.psid && settings.psid.range) {
        const range = settings.psid.range
        return `PSID (${range[0]} - ${range[1]})`
      }
      return 'PSID'
    },
    dataType: DataType.SELECTION,
    options: LasSettingsFrontEndOptionNames,
    disabledOptions: (state, formValues, extra) => {
      const formValuesToCheck = ['user_data', 'nir']
      return LasSettingsFrontEndOptionNames.filter(option => formValuesToCheck.find(formName =>
        option === MapLasSettingsBackendOptionsToFrontend[formValues[formName]] && formValues[formName] !== '',
      ))
    },
    initialValue: (state, values, { extraProps }) => getLasTemplateFieldInitialValue('psid', extraProps),
    variant: 'outlined',
    optional: true,
    mapping: MapLasSettingsFrontendOptionsToBackend,
    getValue: (state, formValue, values, extra, extraProps) => {
      return MapLasSettingsBackendOptionsToFrontend[formValue] || ''
    },
  },
  user_data: {
    name: (state, formValues, extra, extraProps) => {
      const { settings } = extraProps
      if (settings && settings.user_data && settings.user_data.range) {
        const range = settings.user_data.range
        return `User data (${range[0]} - ${range[1]})`
      }
      return 'User data'
    },
    dataType: DataType.SELECTION,
    options: LasSettingsFrontEndOptionNames,
    disabledOptions: (state, formValues, extra) => {
      const formValuesToCheck = ['psid', 'nir']
      return LasSettingsFrontEndOptionNames.filter(option => formValuesToCheck.find(formName =>
        option === MapLasSettingsBackendOptionsToFrontend[formValues[formName]] && formValues[formName] !== '',
      ))
    },
    initialValue: (state, values, { extraProps }) => getLasTemplateFieldInitialValue('user_data', extraProps),
    variant: 'outlined',
    optional: true,
    mapping: MapLasSettingsFrontendOptionsToBackend,
    getValue: (state, formValue, values, extra, extraProps) => {
      return MapLasSettingsBackendOptionsToFrontend[formValue] || ''
    },
  },
  /*
  nir: {
    name: (state, formValues, extra, extraProps) => {
      const { settings } = extraProps
      if (settings && settings.nir && settings.nir.range) {
        const range = settings.nir.range
        return `NIR (${range[0]} - ${range[1]})`
      }
      return 'NIR'
    },
    dataType: DataType.SELECTION,
    options: LasSettingsFrontEndOptionNames,
    disabledOptions: (state, formValues, extra) => {
      const formValuesToCheck = ['psid', 'user_data']
      return LasSettingsFrontEndOptionNames.filter(option => formValuesToCheck.find(formName =>
        option === MapLasSettingsBackendOptionsToFrontend[formValues[formName]] && formValues[formName] !== ''
        )
      )
    },
    initialValue: (state, values, { extraProps }) => getLasTemplateFieldInitialValue('nir', extraProps),
    variant: 'outlined',
    optional: true,
    mapping: MapLasSettingsFrontendOptionsToBackend,
    getValue: (state, formValue, values, extra, extraProps) => {
      return MapLasSettingsBackendOptionsToFrontend[formValue] || ''
    }
  }
  */
}
