import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getGenericCRS: ['lat', 'lon'],
  getGenericCRSLoading: null,
  getGenericCRSSuccess: ['crs'],
  getGenericCRSFailure: ['errorMessage'],

  getPredefinedCRS: ['crsId'],
  getPredefinedCRSLoading: ['crsId'],
  getPredefinedCRSSuccess: ['crsId', 'crs'],
  getPredefinedCRSFailure: ['crsId', 'errorMessage'],

  setCRS: ['crs', 'isPredefined', 'useDefaultCRS', 'useProjString'],
  resetProjectWizardState: null,
})

export const ProjectWizardTypes = Types
export default Creators
