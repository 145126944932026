// turf
// Project deps
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import { path } from 'ramda'
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import { MapBackendNameToFrontend } from '../constants'
import { getJobOptions, getPipelineTemplate } from 'modules/pipelineWizard/selectors'
import { isPipelineSettingsJob } from 'utils/jobs'

export const getInitialValue = (state, defaultValue, mobileDefaultValue) => {
  const selectedTemplate = getPipelineTemplate(state)
  const jobId = selectedTemplate.jobs.find(templateJob => isPipelineSettingsJob(templateJob.jobType)).templateId
  const jobValues = getJobOptions(state)[jobId]
  if (jobValues && path(['rawValues', 'dataset_type'], jobValues) === 'Mobile') {
    return mobileDefaultValue
  }
  return defaultValue
}

export default {
  set_full_density: {
    name: MapBackendNameToFrontend.set_full_density,
    dataType: DataType.BOOLEAN,
    tooltip: 'Settings from the PLP will be overridden for achieving full LIDAR density (ignoring the PLP\'s laser-beam selection, frustums, echo-selection, reflectance-filter and range-filter defined in the LiDAR->Processing tab). Disable this if you want to use settings that were defined in the uploaded PLP.',
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'set_full_density',
      () => getInitialValue(state, true, true),
    ),
  },
  downwardfov: {
    name: MapBackendNameToFrontend.downwardfov,
    dataType: DataType.FLOAT,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'downwardfov',
      () => getInitialValue(state, '110', '360'),
    ),
  },
  lidarrangemin: {
    name: MapBackendNameToFrontend.lidarrangemin,
    dataType: DataType.FLOAT,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'lidarrangemin',
      () => getInitialValue(state, '10', '1'),
    ),
  },
  lidarrangemax: {
    name: MapBackendNameToFrontend.lidarrangemax,
    dataType: DataType.FLOAT,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'lidarrangemax',
      () => getInitialValue(state, '1000', '1000'),
    ),
  },
}
