import { PipelineWizardTypes } from 'modules/pipelineWizard/actions'
import { ImportWizardTypes } from 'modules/importWizard/actions'
const appStateMiddleware = store => next => action => {
  const state = store.getState()
  const newAction = {
    ...action,
    appState: state,
  }
  // This middleware created to pass the additional field 'appState' to the reducer
  // because in redux-saga reducer we only have access to the module state
  if (
    action.type === PipelineWizardTypes.DESELECT_ARTIFACT_FOR_TEMPLATE ||
    action.type === PipelineWizardTypes.SELECT_ARTIFACT_FOR_TEMPLATE ||
    action.type === PipelineWizardTypes.DESELECT_ARTIFACTS_FOR_TEMPLATE ||
    action.type === PipelineWizardTypes.SELECT_ARTIFACTS_FOR_TEMPLATE ||
    action.type === PipelineWizardTypes.UPDATE_ARTIFACT_OPTIONS ||
    action.type === PipelineWizardTypes.UPDATE_ARTIFACTS_OPTIONS ||
    action.type === PipelineWizardTypes.SELECT_DESELECT_ARTIFACTS_FOR_TEMPLATE ||
    action.type === ImportWizardTypes.ADD_MISSION ||
    action.type === ImportWizardTypes.CHANGE_MISSION_TYPE ||
    action.type === ImportWizardTypes.MISSION_FILES_ANALYZED
  ) {
    next(newAction)
  } else {
    next(action)
  }
}
export default appStateMiddleware
