import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// Material UI
import { TextField } from '@material-ui/core'

const DelayedSearchBox = props => {
  const { component = TextField, transform, onChange, value, delay = 100, ...otherProps } = props
  const [localValue, setLocalValue] = useState(value)
  const timerRef = useRef(null)

  useEffect(() => {
    setLocalValue(value)
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [value])

  const handleSearchChange = event => {
    const { value: targetValue, name } = event.target
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    if (!targetValue) {
      onChange(targetValue, name, value)
      setLocalValue(targetValue)
    } else {
      const transformedValue = typeof transform === 'function'
        ? transform(targetValue, name, value)
        : targetValue
      timerRef.current = setTimeout(() => {
        onChange(transformedValue, name, value)
      }, delay)
      setLocalValue(transformedValue)
    }
  }
  const Component = component
  return (
    <Component
      {...otherProps}
      value={localValue}
      onChange={handleSearchChange}
    />
  )
}

DelayedSearchBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  delay: PropTypes.number,
}

export default DelayedSearchBox
