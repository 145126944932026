import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getWorkers
  getWorkers: ['withLoading'],
  getWorkersLoading: null,
  getWorkersSuccess: ['workers'],
  getWorkersFailure: ['errorMessage'],

  getWorkerRules: ['withLoading'],
  getWorkerRulesLoading: null,
  getWorkerRulesSuccess: ['rules'],
  getWorkerRulesFailure: ['errorMessage'],

  addWorkerRule: ['data'],
  addWorkerRuleLoading: null,
  addWorkerRuleSuccess: null,
  addWorkerRuleFailure: ['errorMessage'],

  deleteWorkerRule: ['id'],
  deleteWorkerRuleLoading: null,
  deleteWorkerRuleSuccess: ['id'],
  deleteWorkerRuleFailure: ['errorMessage'],

})

export const WorkersTypes = Types
export default Creators
