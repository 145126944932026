const specials = [
  '/', '.', '*', '+', '?', '|',
  '(', ')', '[', ']', '{', '}', '\\',
]
const escapeRegex = new RegExp(
  '(\\' + specials.join('|\\') + ')', 'g',
)
export function escape (string) {
  return string.replace(escapeRegex, '\\$1')
}

// Get hex string and returns array of numbers which represents rgb color (from 0 to 255) instead of css string
export function convertHexToRgb (hex) {
  return hex.match(/[0-9a-f]{2}/g).map(x => parseInt(x, 16))
}