import bigInt from 'big-integer'
import { max } from 'ramda'
import { getFileFromDropbox } from './utils'
import { transformToEuler } from 'utils/math'
import { isFileNameEndsWith } from 'utils/baseName'

// import { GenericParserInfo, LogMessage } from "./utils"

/*
 * The file format for the ".cam" files can be described as follows:
 *  Start | End  | Type   | Description
 * -------+------+--------+--------------------------------------------------------------------------------------------
 *   0000 | 0007 | ASCII  | 43 4d 52 41 44 41 54 41
 *        |      |        | Hexcode in ASCII range which can be read as "CMRADATA"
 *        |      |        | Filetype identifier identifying this file as a cam file.
 *   0008 | 0010 |        | Version identifier containing the version of the protocol used in this file:
 *        |      | uint8  |  - 000: Major Version
 *        |      | uint8  |  - 001: Minor Version
 *        |      | uint8  |  - 002: Patch Version
 *   0011 | 0011 | uint8  | The index of the sensor for which this file was generated.
 *   0012 | 0014 |        | Identifies the type of camera which generated the images:
 *        |      | uint8  |  - 000: The model of the camera. For more information see `CameraModelMap`.
 *        |      | uint8  |  - 001: The trigger mode of the camera.
 *        |      |        |         or more information see `CameraTriggerModeMap`.
 *        |      | uint8  |  - 002: A Bitmask defining the Storage Mode.
 *        |      |        |          - Bit 1: Data was stored on camera.
 *        |      |        |          - Bit 2: Data was downloaded.
 *   0015 | 0015 | bool   | "applyExifOrientation". For more information see "phusion" repository.
 *   0016 | 0017 |        | Information about the "Event Pin" to which the camera was connected.
 *        |      | uint8  |  - 000: The board identifier for the event pin.
 *        |      |        |         For more information see `EventPinBoardMap`.
 *        |      | uint8  |  - 001: The pin index.
 *   0018 | 0018 | uint8  | previewQuality
 *   0019 | 0019 | uint8  | togglePreviewBetweenFullAndCropped
 *   0020 | 0020 | uint8  | alwaysSendFrustum
 *   0021 | 0024 | float  | sensorWidth
 *   0024 | 0027 | float  | sensorHeight
 *   0027 | 0028 | int16  | pixelWidthColorProjection
 *   0028 | 0029 | int16  | pixelWidthPreview
 *   0029 | 0032 | float  | triggerInterval
 *   0032 | 0035 | float  | triggerDistance
 *   0035 | 0038 | float  | minimumPreviewInterval
 *   0038 | 0101 | float  | transformInsToSensorColumnMajor
 */

const headerSize = 421

/**
 * Checks whether the file name of a file looks like a camera info file name.
 * This is done by looking at the extension and checking whether it is ".cam".
 */
export function isCamFileName (fileName) {
  return isFileNameEndsWith(fileName, '.cam')
}

export function isCamFileType (fileType) {
  return fileType === 'cam'
}

export function isPfsFileName (fileName) {
  return isFileNameEndsWith(fileName, '.pfs')
}

/*
export interface CamFileHeader {
  //  * The version of rover with which this camera info file was generated.
  version: string
  //  * The index of the sensor. A unique number distinguishing the camera from other cameras.
  sensorIndex: number
  //  * The model name of the camera.
  cameraModel: CameraModel
  //  * Whether the camer was triggered passively, after a certain distance or after a given interval.
  cameraTriggerMode: CameraTriggerMode
  storageMode: {
    //  * Data was stored on the cameras internal storage.
    camera: boolean
    //  * Data was downloaded from the camera over the network.
    download: boolean
  }
  //  * Information about the event pin on which the camera was connected and which was used to trigger
  //  * the camera and get the correct timestamp.
  pin: {
    board: EventPinBoard
    index: number
  }
}
*/

/**
 * Used to convert Byte 18 of the ".cam" fileformat into a human-readble board identifier for the
 * event pin which was used by the camera.
 */
export const EventPinBoardMap = [
  'None',
  'Primary',
  'Secondary',
]

/**
 * Used to convert Byte 14 of the ".cam" fileformat into a human-readable trigger mode identifier.
 */
export const CameraTriggerModeMap = [
  'Passive',
  'Interval',
  'Distance',
]

/**
 * Used to convert Byte 13 of the ".cam" fileformat into a human-readable camera model identifier.
 */
export const CameraModelMap = [
  'Unknown',
  'USB',
  'Micro Controller',
  'Basler',
  'Xenics',
  'Ladybug',
  'Point Grey',
  'Headwall',
]

export const ImageFileType = 'image'

export const isImageFileType = fileType => {
  return fileType === ImageFileType
}

/**
 * Parses a file and checks if it really is a cam file by looking at the filename as well as the content of
 * the file. This also tries to parse the header of the file to provide some useful information.
 * @param file The file which should be checked and of which the header should be parsed.
 * @return An object containing information about whether the file is a valid cam file, a log about the
 *   parsing and the information read from the header.
 */
// function parseCamFile(file: File): Promise<CamFileParserInfo>
export async function parseCamFile (file, blob) {
  return new Promise(resolve => {
    const log = []
    if (!isCamFileName(file.name)) {
      log.push({
        level: 'warning',
        message: `Unsupported file extension in filename "${file.name}"`,
      })
    }
    if (file.size < headerSize) {
      const message =
        `Invalid file size. File is only ${file.size} bytes long. At least ${headerSize} bytes are needed.`
      log.push({ level: 'error', message })
      resolve({ okay: false, log, fileType: 'cam' })
      return
    }
    const fileReader = new FileReader()
    fileReader.onload = () => {
      // ArrayBuffer
      const buffer = fileReader.result
      // eslint:disable-next-line
      const fileTypeIdentifier = String.fromCharCode.apply(null, new Uint8Array(buffer, 0, 8))
      if (fileTypeIdentifier !== 'CMRADATA') {
        log.push({
          level: 'error',
          message: `Filetype identifier (first 8 bytes) did not match "CAMRDATA".`,
        })
        resolve({ okay: false, log, fileType: 'cam' })
        return
      }
      const view = new DataView(buffer)
      const storageModeBitmask = view.getUint8(14)
      const transform = Array(16).fill(undefined).map(
        // Use little endian here.
        (_, index) => view.getFloat32(38 + index * 4, true))
      const result = {
        version: `${view.getUint8(8)}.${view.getUint8(9)}.${view.getUint8(10)}`,
        sensorIndex: view.getUint8(11),
        cameraModel: CameraModelMap[view.getUint8(12)],
        cameraTriggerMode: CameraTriggerModeMap[view.getUint8(13)],
        transform,
        offset: [transform[12], transform[13], transform[14]],
        rotation: transformToEuler(transform),
        storageMode: {
          camera: Boolean(storageModeBitmask & 1),
          download: Boolean(storageModeBitmask & 2),
        },
        pin: {
          board: EventPinBoardMap[view.getUint8(16)],
          index: view.getUint8(17),
        },
        interval: getInterval(view, file.size),
      }
      resolve({ result, okay: true, log, fileType: 'cam' })
    }
    fileReader.onerror = () => {
      log.push({
        level: 'error',
        message: `Error reading file "${file.name}".`,
      })
      resolve({ okay: false, log, fileType: 'cam' })
    }
    fileReader.readAsArrayBuffer(
      (blob || file), // .slice(0, headerSize)
    )
  })
}

export async function parseDropboxCamFile (file) {
  return getFileFromDropbox(file, { responseType: 'arraybuffer' }, undefined, parseCamFile)
  /*
    headers: {
      Range: `bytes=0-${headerSize-1}`
    }
  */
}
/**
 * Extract time intervals from .cam file
 * First interval starts on 2048 + 236 byte
 * Next interval located on (2048 + 236) + 312, another one on (2048 + 236) + 312 + 312, ...etc
 * @param {DataView} view
 * @param {Number} fileSize
 */
function getInterval (view, fileSize) {
  let weekMin = Infinity
  let weekMax = -Infinity
  let towMin = Infinity
  let towMax = -Infinity
  let currentPosition = 2048 + 236
  if (fileSize < currentPosition) {
    return {
      beginning: {
        week: -1,
        tow: -1,
      },
      end: {
        week: -1,
        tow: -1,
      },
    }
  }

  const low = view.getUint32(currentPosition, true)
  const high = view.getUint32(currentPosition + 4, true)
  const value = bigInt(high).shiftLeft(32).add(low)
  const week = value.divide(1000000).divide(604800).toJSNumber()
  const tow = value.divide(1000000.0).mod(604800.0).toJSNumber()

  weekMin = week
  towMin = tow

  currentPosition += 312
  while (currentPosition < fileSize) {
    const low = view.getUint32(currentPosition, true)
    const high = view.getUint32(currentPosition + 4, true)
    const value = bigInt(high).shiftLeft(32).add(low)
    const week = value.divide(1000000).divide(604800).toJSNumber()
    const tow = value.divide(1000000.0).mod(604800.0).toJSNumber()
    weekMax = max(week, weekMax)
    towMax = max(tow, towMax)
    currentPosition += 312
  }
  return {
    beginning: {
      week: weekMin,
      tow: towMin,
    },
    end: {
      week: weekMax,
      tow: towMax,
    },
  }
}
const _URL = window.URL || window.webkitURL
export async function parseImageFile (file, blob) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = function () {
      // Check if image is bad/invalid
      if (this.width + this.height === 0) {
        resolve({
          okay: false,
          log: [{
            level: 'error',
            message: `Error reading file "${file.name}". 0 length file`,
          }],
        })
        return
      }
      resolve({ okay: true, result: { resolution: { width: this.width, height: this.height } } })
    }
    image.onerror = function () {
      resolve({
        okay: false,
        log: [{
          level: 'error',
          message: `Error reading file "${file.name}". 0 length file`,
        }],
      })
    }
    image.src = _URL.createObjectURL(file)
  })
}
