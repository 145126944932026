import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import axios from 'utils/axios'
import { getErrorMessage } from 'utils/api'
import { convertRawJob } from 'types/jobs'
import { convertRawJobRunLog } from 'types/jobRunLogs'
import { convertRawDataFileUploadLog } from 'types/dataFileUploadLogs'
// Local deps
import StatisticsActions, { StatisticsTypes } from './actions'

// Sagas
function * getJobs () {
  yield put(StatisticsActions.getAllJobsLoading())
  try {
    const url = `/jobs/`
    const { data: { data: jobs } } = yield call(axios.get, url)
    yield put(StatisticsActions.getAllJobsUpdate(jobs.map(convertRawJob)))
    yield put(StatisticsActions.getAllJobsSuccess())
  } catch (e) {
    yield put(StatisticsActions.getAllJobsFailure(getErrorMessage(e)))
  }
}

function * getJobsQueried ({ per_page, page, query }) {
  yield put(StatisticsActions.getAllJobsQueriedLoading())
  try {
    let url = `/jobs/?per_page=${per_page}&page=${page}`
    const defaultSort = '&sort=created&order=desc'
    if (query) {
      const { sort, filter } = query
      url = `${url}${sort || defaultSort}${filter || ''}`
    } else {
      url = `${url}${defaultSort}`
    }
    const { data: { data: jobs, pagination } } = yield call(axios.get, url)
    yield put(StatisticsActions.getAllJobsQueriedSuccess(jobs.map(convertRawJob), pagination))
  } catch (e) {
    yield put(StatisticsActions.getAllJobsQueriedFailure(getErrorMessage(e)))
  }
}

function * getProjects () {
  yield put(StatisticsActions.getAllProjectsLoading())
  try {
    const url = `/projects/`
    const { data: { data: projects } } = yield call(axios.get, url)
    yield put(StatisticsActions.getAllProjectsSuccess(projects))
  } catch (e) {
    yield put(StatisticsActions.getAllProjectsFailure(getErrorMessage(e)))
  }
}

// Sagas

function * getLogs ({ startDate, endDate }) {
  yield put(StatisticsActions.getLogsLoading())
  try {
    const [
      { data: { data: jobRunLogs } },
      { data: { data: dataFileUploadLogs } },
    ] = yield all([
      call(axios.get, `/job_run_logs?time_begin=${startDate.toISOString()}&time_end=${endDate.toISOString()}`),
      call(axios.get, `/data_file_upload_logs?time_begin=${startDate.toISOString()}&time_end=${endDate.toISOString()}`),
    ])
    yield put(StatisticsActions.getLogsSuccess(
      jobRunLogs.map(convertRawJobRunLog),
      dataFileUploadLogs.map(convertRawDataFileUploadLog),
    ))
  } catch (e) {
    yield put(StatisticsActions.getLogsFailure(getErrorMessage(e)))
  }
}

function * getJobRunLogs ({ startDate, endDate }) {
  yield put(StatisticsActions.getJobRunLogsLoading())
  try {
    const url = `/job_run_logs?time_begin=${startDate.toISOString()}&time_end=${endDate.toISOString()}`
    const { data: { data: jobRunLogs } } = yield call(axios.get, url)
    yield put(StatisticsActions.getJobRunLogsSuccess(jobRunLogs.map(convertRawJobRunLog)))
  } catch (e) {
    yield put(StatisticsActions.getJobRunLogsFailure(getErrorMessage(e)))
  }
}

function * getDataFileUploadLogs ({ startDate, endDate }) {
  yield put(StatisticsActions.getDataFileUploadLogsLoading())
  try {
    const url = `/data_file_upload_logs?time_begin=${startDate.toISOString()}&time_end=${endDate.toISOString()}`
    const { data: { data: dataFileUploadLogs } } = yield call(axios.get, url)
    yield put(StatisticsActions.getDataFileUploadLogsSuccess(dataFileUploadLogs.map(convertRawDataFileUploadLog)))
  } catch (e) {
    yield put(StatisticsActions.getDataFileUploadLogsFailure(getErrorMessage(e)))
  }
}

function * getWorkerUsage ({ startDate, endDate }) {
  yield put(StatisticsActions.getWorkerUsageLoading())
  try {
    const url = `/workers_statistics?start_date=${startDate}&end_date=${endDate}`
    const { data: { data: workerUsage } } = yield call(axios.get, url)
    yield put(StatisticsActions.getWorkerUsageSuccess(workerUsage))
  } catch (e) {
    yield put(StatisticsActions.getWorkerUsageFailure(getErrorMessage(e)))
  }
}

// Watchers
function * getJobsWatcher () {
  yield takeLatest(StatisticsTypes.GET_ALL_JOBS, getJobs)
}

function * getJobsQueriedWatcher () {
  yield takeLatest(StatisticsTypes.GET_ALL_JOBS_QUERIED, getJobsQueried)
}

function * getProjectsWatcher () {
  yield takeLatest(StatisticsTypes.GET_ALL_PROJECTS, getProjects)
}

function * getDataFileUploadLogsWatcher () {
  yield takeLatest(StatisticsTypes.GET_DATA_FILE_UPLOAD_LOGS, getDataFileUploadLogs)
}

function * getJobRunLogsWatcher () {
  yield takeLatest(StatisticsTypes.GET_JOB_RUN_LOGS, getJobRunLogs)
}

function * getLogsWatcher () {
  yield takeLatest(StatisticsTypes.GET_LOGS, getLogs)
}

function * getWorkerUsageWatcher () {
  yield takeLatest(StatisticsTypes.GET_WORKER_USAGE, getWorkerUsage)
}

export default function * root () {
  yield fork(getJobsWatcher)
  yield fork(getProjectsWatcher)
  yield fork(getJobRunLogsWatcher)
  yield fork(getDataFileUploadLogsWatcher)
  yield fork(getJobsQueriedWatcher)
  yield fork(getLogsWatcher)
  yield fork(getWorkerUsageWatcher)
}
