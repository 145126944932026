import React from 'react'
import PropTypes from 'prop-types'
// Material UI dependencies.
import { withStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
// Project internal dependencies.
import { getTabValue } from 'templates/tabs'
// Local deps
import Breadcrumb from './Breadcrumb'

const styles = theme => ({
  breadcrumbContainer: {
    color: theme.palette.grey.light,
  },
  breadcrumbLink: {
    textDecoration: 'none',
    color: `${theme.palette.grey.darkLight} !important`,
    fontSize: 18,
    minHeight: 0,
    transition: 'color 150ms ease',
    padding: theme.spacing(0.25, 1),
    borderRadius: 4,
    '&:hover': {
      color: `${theme.palette.grey.light} !important`,
      backgroundColor: '#3b4148 !important',
    },
  },
  breadcrumbActive: {
    color: `${theme.palette.grey.light} !important`,
    fontSize: 18,
    minHeight: 0,
  },
  select: {
    minHeight: 0,
  },
})

export const CustomBreadcrumbs = props => {
  const {
    onClick,
    breadcrumbsData: { breadcrumbs, basicUrl },
    extraProps,
    classes,
    match,
  } = props
  // Parsing to get the first url segment, like projects, users, payments, statistics, etc.
  // This needs to be done to change the tab if we clicked on breadcrumb
  return (
    breadcrumbs && typeof breadcrumbs === 'function'
      ? breadcrumbs(onClick)
      : basicUrl && breadcrumbs.length > 1 ? <Breadcrumbs
        arial-label='Breadcrumb'
        className={classes.breadcrumbContainer}
        separator='>'
        maxItems={5}>
        {
          breadcrumbs.map((breadcrumb, index) => {
            // Last item should not be clickable
            const last = index === breadcrumbs.length - 1
            const { path } = breadcrumb
            // Getting the tab value in case we click on breadcrumb and tab value is changed
            const tabsValue = getTabValue(match, basicUrl, undefined, extraProps)
            return (
              path && <Breadcrumb
                key={`breadcrumb-${index}`}
                breadcrumb={breadcrumb}
                options={{ last, tabsValue, classes, onClick }}
              />
            // : <Typography key={path} color={'inherit'} className={classes.breadcrumbLink}>No label</Typography>
            )
          })
        }
      </Breadcrumbs>
        : null
  )
}

CustomBreadcrumbs.propTypes = {
  onClick: PropTypes.func,
  breadcrumbs: PropTypes.object,
  classes: PropTypes.object,
  match: PropTypes.object,
  extraProps: PropTypes.object,
  breadcrumbsData: PropTypes.object,
}

CustomBreadcrumbs.defaultProps = {
  onClick: () => {},
  breadcrumbsData: {
    breadcrumbs: [],
    basicUrl: '',
  },
  classes: {},
  match: {},
}

export default withStyles(styles)(CustomBreadcrumbs)
