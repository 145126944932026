import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { MissionsTypes } from './actions'

export const deleteMissionLoading = (state, { missionId }) => {
  return state.merge({
    deleteMission: {
      ...state.get('deleteMission'),
      [missionId]: true,
    },
    deleteMissionErrorMessage: null,
  })
}
export const deleteMissionFailure = (state, { errorMessage, missionId }) => {
  return state.merge({
    deleteMission: {
      ...state.get('deleteMission'),
      [missionId]: false,
    },
    deleteMissionErrorMessage: errorMessage,
  })
}

export const deleteMissionSuccess = (state, { missionId }) => {
  return state.merge({
    deleteMission: {
      ...state.get('deleteMission'),
      [missionId]: false,
    },
  })
}
export const getMissionsLoading = state => {
  return state.merge({})
}
export const getMissionsFailure = state => {
  return state.merge({})
}
export const getMissionsSuccess = state => {
  return state.merge({})
}
export const updateMissionLoading = state => {
  return state.merge({})
}
export const updateMissionFailure = state => {
  return state.merge({})
}
export const updateMissionSuccess = state => {
  return state.merge({})
}

export const reducer = createReducer(INITIAL_STATE, {
  [MissionsTypes.DELETE_MISSION_LOADING]: deleteMissionLoading,
  [MissionsTypes.DELETE_MISSION_FAILURE]: deleteMissionFailure,
  [MissionsTypes.DELETE_MISSION_SUCCESS]: deleteMissionSuccess,

  [MissionsTypes.GET_MISSIONS_LOADING]: getMissionsLoading,
  [MissionsTypes.GET_MISSIONS_FAILURE]: getMissionsFailure,
  [MissionsTypes.GET_MISSIONS_SUCCESS]: getMissionsSuccess,

  [MissionsTypes.UPDATE_MISSION_LOADING]: updateMissionLoading,
  [MissionsTypes.UPDATE_MISSION_FAILURE]: updateMissionFailure,
  [MissionsTypes.UPDATE_MISSION_SUCCESS]: updateMissionSuccess,
})
