import { isUserCompanyCustomer, isUserCompanyEmployee } from 'utils/company'
export const getUsers = state => {
  return state.users.get('users') || []
}

export const getUsersIsLoading = state => {
  return state.users.get('getUsersIsLoading')
}

export const isLoggedIn = state => {
  return !!state.users.get('token')
}

export const isAdmin = state => {
  const users = state.users
  return users && !!(users.get('token') && users.get('user') && !!users.get('user').is_superuser)
}

export const isCRSAdvanced = state => {
  const users = state.users
  return users && users.get('token') && users.get('user')
    ? users.get('user').is_crs_advanced
    : false
}

export const isCurrentUserCompanyAdmin = state => {
  const user = state.users.get('user')
  return !!(state.users.get('token') && user && isUserCompanyEmployee(user) && !!user.is_company_admin)
}

export const isCurrentUserCompanyEmployee = state => {
  const user = state.users.get('user')
  return !!(state.users.get('token') && user && isUserCompanyEmployee(user))
}

export const isCurrentUserCompanyCustomer = state => {
  const user = state.users.get('user')
  return !!(state.users.get('token') && user && isUserCompanyCustomer(user))
}

export const isCurrentUserAdminOrCompanyAdmin = state => {
  return isAdmin(state) || isCurrentUserCompanyAdmin(state)
}

export const isLoginLoading = state => {
  return !!state.users.get('loginIsLoading')
}

export const getLoginError = state => {
  return state.users.get('loginErrorMessage')
}

export const isRecoverLoading = state => {
  return !!state.users.get('recoverIsLoading')
}

export const isRecoverSuccess = state => {
  return !!state.users.get('recoverIsSuccess')
}

export const isSendConfirmEmailLoading = state => {
  return !!state.users.get('sendConfirmEmailIsLoading')
}

export const isSendConfirmEmailSuccess = state => {
  return !!state.users.get('sendConfirmEmailIsSuccess')
}

export const isConfirmEmailLoading = state => {
  return !!state.users.get('confirmEmailIsLoading')
}

export const isConfirmEmailSuccess = state => {
  return !!state.users.get('confirmEmailIsSuccess')
}

export const getLoggedUser = state => {
  return state.users.get('user')
}

export const getUserTimezone = state => {
  const user = state.users.get('user')
  return user ? user.time_zone || '' : ''
}

export const getActiveUser = state => {
  return state.users.get('activeUser')
}

export const getActiveUserIsLoading = state => {
  return state.users.get('getActiveUserIsLoading')
}

export const updateUserIsLoading = state => {
  return state.users.get('updateUserIsLoading')
}

export const token = state => {
  return state.users.get('token')
}

export const isLoggedAsOtherUser = state => {
  return state.users.get('isLoggedAs')
}

export const getProjectsStatistic = state => state.users.get('projectsStatistic') || []
export const isGetProjectsStatisticsLoading = state => state.users.get('getProjectsStatisticIsLoading')

export const isRegisterLoading = state => !!state.users.get('registerIsLoading')

export const isAcceptTermsFormOpen = state => state.users.get('acceptTermsFormOpen')

export const isGetSystemTypesLoading = (state, userId) => {
  const systemTypesByUserLoading = state.users.get('systemTypesByUserLoading') || {}
  return systemTypesByUserLoading[userId] || false
}

export const getSubscriptions = (state, userId) => {
  const subscriptionsByUser = state.users.get('subscriptionsByUser') || {}
  return subscriptionsByUser[userId] || []
}

export const isGetSubscriptionsLoading = (state, userId) => {
  const subscriptionsByUserLoading = state.users.get('subscriptionsByUserLoading') || {}
  return subscriptionsByUserLoading[userId] || false
}

export const isUserSuccessfullyUpdated = state => state.users.get('updateUserSucceed')

export const getAllUserPermissions = state => state.users.get('permissionsByUser') || {}
export const getAllUserPermissionsLoading = state => state.users.get('permissionsByUserLoading') || {}

export const getUserPermissions = (state, userId) => {
  if (state.users) {
    const permissionsByUser = state.users.get('permissionsByUser') || {}
    return permissionsByUser[userId] || []
  }
  return []
}

export const isGetUserPermissionsLoading = (state, userId) => {
  const permissionsByUserLoading = state.users.get('permissionsByUserLoading') || {}
  return permissionsByUserLoading[userId] || false
}

export const getCompanyAllCRS = (state, companyId) => {
  return (state.users.get('companyCRS')[companyId] || [])
}
export const getCompanyAllCRSLoading = (state, companyId) => {
  return !!state.users.get('companyCRSLoading')[companyId]
}
export const getProjectCRS = (state, crsId) => {
  return state.users.get('crs')[crsId]
}
export const isGetCRSLoading = state => {
  return state.users.get('crsLoading')
}
export const isDeleteCRSLoading = state => {
  return state.users.get('deleteCRSLoading')
}
export const isUpdateCRSLoading = state => {
  return state.users.get('updateCRSLoading')
}
export const getUpdateCRSStatus = state => {
  return state.users.get('updateCRSStatus')
}
export const getUpdateCRSDialogOpen = state => {
  return state.users.get('updateCRSDialogOpen')
}
export const isRequestRecoveryLoading = state => state.users.get('requestRecoveryIsLoading')
export const isPasswordNotSet = state => state.users.get('passwordNotSet')

export const isTwoFactorLogin = state => state.users.get('twoFactorLogin')
export const isTwoFactorLoginLoading = state => !!state.users.get('twoFactorLoginIsLoading')

export const isRehydrated = state => !!state.users.get('rehydrated')

export const isGetReleasesLoading = state => !!state.users.get('releasesIsLoading')
export const getReleases = state => state.users.get('releases')
export const getTotalReleases = state => state.users.get('totalReleases')
