import React from 'react'
import { isNil, path } from 'ramda'
import { Trans } from 'react-i18next'
import i18n from 'i18n'
// Material UI
import { CircularProgress, Chip } from '@material-ui/core'
// Icons
/*
import IconSuccess from '@material-ui/icons/Done'
import IconFailure from '@material-ui/icons/Close'
import IconUploading from '@material-ui/icons/CloudUpload'
import IconWaiting from '@material-ui/icons/QueryBuilder'
import IconCancel from '@material-ui/icons/Cancel'
*/
// Project deps
import {
  ArtifactStatuses,
  PrettyArtifactStatuses,
  isTrajectoryArtifact,
  isRefStationArtifact,
  isNavRoverArtifact,
  isLidarArtifact,
  isCameraArtifact,
  isPointcloudArtifact,
  isReconDataArtifact,
  isPolygonArtifact,
  ArtifactTypes,
} from 'types/artifacts'
import {
  getArtifactsOfType,
  gpsIntervalsEquals,
  getArtifactGpsIntervals,
  getArtifactSensorIndex,
  prettifyArtifactType,
  isArtifactCanBeSelectedForProcessing,
  // prettifyArtifactType,
} from 'utils/artifacts'
import { getInputArtifacts, getUserInputArtifacts, getArtifactRange, isSomePipelineProcessing } from 'utils/pipelines'
import { templateJobOptions } from 'templates/jobOptions'
import { templateJobIOOptions, getJobIoOptionsForArtifactTemplate } from 'templates/jobIoOptions'
import { concatMap, findById, partition } from 'utils/list'
import { getArtifacts } from 'modules/projects/selectors'
import {
  getPipelineTemplate,
  getSelectedTemplateArtifacts,
  getJobOptions,
  getConfigureStep,
  getArtifactOptions,
  getSelectedPointcloudArtifacts,
  getAdditionalJobIoOptions,
} from 'modules/pipelineWizard/selectors'
import { convertGpsTime, transformLegacyGpsTimeInterval } from 'utils/gpsTime'
import { isCustomAnalyzeSelected } from 'templates/utils'
import { getTabTemplate } from 'templates/CRS/utils'
import { tabsMap, CRSFields } from 'templates/CRS/constants'
import { getDatums } from 'modules/app/selectors'
import { getImportWizard } from 'modules/importWizard/selectors'
import { isAdmin } from 'modules/users/selectors'
import { isFormFieldOptional } from 'components/TemplatedForm/utils'
import { theme } from 'HOC/withRoot'
import { REF_STATION_BACKEND_NAME, analyzePriority } from 'templates/constants'
import { PipelineWizardType } from 'types/pipelines'
import { withMaxPrecision } from 'utils/numeric'
import moment from 'utils/moment'
import { isInteger, isUndefined } from 'lodash'

// Components

export const templateStep = 0
export const settingsFileStep = 1
export const artifactStep = 2
export const configureStep = 3

export const Steps = {
  TEMPLATE: 'template',
  FILES: 'files',
  SETTINGS: 'settings',
  NAV_INTERVAL: 'nav-interval',
  CONFIGURE: 'configure',
}

const stepsTemplates = {
  [PipelineWizardType.NAVLAB]: [Steps.TEMPLATE, Steps.FILES, Steps.NAV_INTERVAL, Steps.CONFIGURE], // Steps.SETTINGS,
  [PipelineWizardType.CLASSIFICATION]: {
    admin: [Steps.TEMPLATE, Steps.FILES, Steps.CONFIGURE],
    default: [Steps.TEMPLATE, Steps.FILES],
  },
  [PipelineWizardType.ORTHOIMAGE_GENERATION]: state => {
    const selectedPointcloudArtifacts = getSelectedPointcloudArtifacts(state)
    if (selectedPointcloudArtifacts.length > 0) {
      return [Steps.TEMPLATE, Steps.FILES, Steps.CONFIGURE]
    } else {
      return [Steps.TEMPLATE, Steps.FILES]
    }
  },
  DEFAULT: [Steps.TEMPLATE, Steps.FILES, Steps.CONFIGURE],
}

const cloneStepsTemplates = {
  [PipelineWizardType.NAVLAB]: [Steps.NAV_INTERVAL, Steps.CONFIGURE],
  [PipelineWizardType.CLASSIFICATION]: {
    admin: [Steps.CONFIGURE],
    default: [],
  },
  [PipelineWizardType.ORTHOIMAGE_GENERATION]: [],
  DEFAULT: [Steps.CONFIGURE],
}

const getStepsTemplate = (state, pipelineWizardType, isUserAdmin, templates = stepsTemplates) => {
  const stepsTemplate = templates[pipelineWizardType]
  if (stepsTemplate) {
    if (Array.isArray(stepsTemplate)) {
      return stepsTemplate
    } else if (typeof stepsTemplate === 'function') {
      return stepsTemplate(state)
    } else {
      return isUserAdmin ? stepsTemplate.admin : stepsTemplate.default
    }
  } else {
    return templates.DEFAULT
  }
}

export const getSteps = (state, options) => {
  const isUserAdmin = isAdmin(state)
  const { pipelineWizardType, navRoverArtifacts } = options
  if (pipelineWizardType === PipelineWizardType.NAVLAB) {
    if (navRoverArtifacts.length > 0) {
      return getStepsTemplate(state, pipelineWizardType, isUserAdmin)
    } else {
      return getStepsTemplate(state, 'DEFAULT', isUserAdmin)
    }
  }
  return getStepsTemplate(state, pipelineWizardType, isUserAdmin)
}

export const getCloneSteps = (state, options) => {
  const isUserAdmin = isAdmin(state)
  const { pipelineWizardType, navRoverArtifacts } = options
  if (pipelineWizardType === PipelineWizardType.NAVLAB) {
    if (navRoverArtifacts.length > 0) {
      return getStepsTemplate(state, pipelineWizardType, isUserAdmin, cloneStepsTemplates)
    } else {
      return getStepsTemplate(state, 'DEFAULT', isUserAdmin, cloneStepsTemplates)
    }
  }
  return getStepsTemplate(state, pipelineWizardType, isUserAdmin, cloneStepsTemplates)
}

/**
 * Checks whether the first step is valid an the user can continue.
 * @param state The application's state.
 */
export function isTemplateStepValid (state, otherOptions) {
  // The user must have selected a template.
  const pipelineTemplate = getPipelineTemplate(state)
  return isPipelineTemplateSelected(pipelineTemplate) &&
  // The user must have all necessary artifacts for that template.
    isPipelineTemplateFulfillable(state) &&
  // The user must not have an empty name specified.
    isNameNotEmpty(otherOptions.name)
}

/**
 * Checks whether the second step is valid an the user can continue.
 * @param state The application's state.
 */
export function isFilesStepValid (state, options) {
  const additionalJobIoOptions = getAdditionalJobIoOptions(state)
  const isUserAdmin = isAdmin(state)
  const artifacts = getArtifacts(state)
  const pipelineTemplate = getPipelineTemplate(state)
  const selectedTemplateArtifacts = getSelectedTemplateArtifacts(state)
  const userInputArtifacts = getUserInputArtifacts(pipelineTemplate)
  const selectedArtifacts = userInputArtifacts.reduce((allArtifacts, template) => {
    const { templateId } = template
    const selectedArtifactIds = selectedTemplateArtifacts[templateId] || []
    const selectedArtifactsByType = selectedArtifactIds.map(id => findById(id, artifacts)).filter(Boolean)
    return [
      ...allArtifacts,
      ...selectedArtifactsByType,
    ]
  }, [])

  const validImageOffsetValue = Object.values(additionalJobIoOptions).every(option =>
    (isInteger(option.image_offset) || isUndefined(option.image_offset))
    && option.image_offset !== 0 && option.image_offset !== '-')
  const validAdditionalOptions = Object.keys(additionalJobIoOptions).length === 0
    ? true
    : validImageOffsetValue
  return userInputArtifacts.every(({ templateId }) => {
    const numSelected = (selectedTemplateArtifacts[templateId] || []).length
    const range = getArtifactRange(templateId, pipelineTemplate, selectedArtifacts, isUserAdmin)
    return range.min <= numSelected && numSelected <= range.max
  }) && validAdditionalOptions
}

/**
 * Checks whether the second step is valid an the user can continue.
 * @param state The application's state.
 */
export function isSettingsStepValid (state, options) {
  const { pipelineWizardType } = options
  const { settingsFiles, settingsFilesProcessingType } = getImportWizard(state, ['settingsFiles', 'settingsFilesProcessingType'])
  return pipelineWizardType === PipelineWizardType.NAVLAB
    ? settingsFiles.length <= 0 || settingsFiles.length === 1
    : pipelineWizardType === PipelineWizardType.SPATIAL_FUSER
      ? settingsFiles.length <= 0 || (settingsFiles.length === 1 && settingsFilesProcessingType)
      : true
}

/**
 * Checks whether the second step is valid an the user can continue.
 * @param state The application's state.
 */
export function isNavIntervalStepValid (state) {
  return true
}

/**
 * Checks whether the `Configure` step is valid an the user can continue.
 * @param state The application's state.
 */
export function isConfigureStepValid (state) {
  const pipelineTemplate = getPipelineTemplate(state)
  const selectedTemplateArtifacts = getSelectedTemplateArtifacts(state)
  const jobOptions = getJobOptions(state)
  const artifactOptions = getArtifactOptions(state)
  const currentConfigureStep = getConfigureStep(state)
  const configureStepCount = getConfigureStepCount(state, pipelineTemplate, selectedTemplateArtifacts)
  // If the user has no `pipelineTemplate` selected in the state, the step should not be valid.
  // Please note that this will never be the case, but the below could would crash if `pipelineTemplate` was
  // `undefined`.
  if (isNil(pipelineTemplate)) {
    return false
  }
  // If the user did not reach the last configure step (user can still continue within the stepper in the
  // last step), the user should be able to continue.
  if (currentConfigureStep < configureStepCount - 1) {
    return true
  }

  // If not all job options are valid, the user should not be able to submit the pipeline.
  const jobIdTypeMapping = getPipelineTemplateJobs(state, pipelineTemplate).map(job => ({ id: job.templateId, type: job.jobType }))
  if (!allJobFormValuesValid(
    // Which template job form has which values.
    jobOptions,
    // Which template job id has which type.
    jobIdTypeMapping,
    // Which job type has which options.
    templateJobOptions,
    state,
    selectedTemplateArtifacts
  )) {
    return false
  }

  // If not all artifact options are valid, the user should not be able to submit the pipeline.
  // This gives a list of template input artifacts, not actual selected artifacts.
  const artifactIdTypeMapping = concatMap(
    templateArtifact =>
      (selectedTemplateArtifacts[templateArtifact.templateId] || [])
        .map(id => ({ id, type: templateArtifact.artifactType })),
    getInputArtifacts(pipelineTemplate)
  )
  if (!allArtifactFormValuesValid(
    // Which artifact form has which values.
    artifactOptions,
    // Which artifact id has which type.
    artifactIdTypeMapping,
    // Which artifact type has which options.
    templateJobIOOptions,
    state
  )) {
    return false
  }

  // If all options are valid, the user can submit the pipeline.
  return true
}

/**
 * Checks whether the user has a pipeline template selected.
 * @param state The `PipelineAddState` from the application state.
 */
export function isPipelineTemplateSelected (pipelineTemplate) {
  return !isNil(pipelineTemplate)
}

/**
 * Checks whether the user has a name defined in the state.
 * @param state The `PipelineAddState` from the application state.
 */
export function isNameNotEmpty (name) {
  return name.length > 0
}

/**
 * Lists all artifact types the user is missing for the currently selected pipeline template.
 * If the pipeline template needs a `TRAJECTORY` artifact and the user doesn't have an artifact of
 * that type, this function will include that type in the array which is returned.
 * @param state The application state.
 * @return An array including all `ArtifactTypes`s the user is missing.
 */
export function getMissingArtifactTypes (state) {
  const pipelineTemplate = getPipelineTemplate(state)
  const isUserAdmin = isAdmin(state)
  if (pipelineTemplate) {
    const artifacts = getArtifacts(state)
    return concatMap(
      templateArtifact => {
        const { artifactType, templateId } = templateArtifact
        const { min } = getArtifactRange(templateId, pipelineTemplate, artifacts, isUserAdmin)
        const hasEnoughArtifacts = getArtifactsOfType(artifactType, artifacts).length >= min
        // getArtifactsOfTypeForProject(artifactType, projectId, artifacts).length >= min;
        return hasEnoughArtifacts ? [] : [artifactType]
      },
      getUserInputArtifacts(pipelineTemplate)
    )
  }
  return []
}

/**
 * Checks if a user has all the artifacts necessary for the currently selected pipeline template.
 * TODO Take into account disabled artifacts (inter-artifact relations).
 * @param state The application state.
 * @return `true` if the user has all necessary artifacts. `false` otherwise.
 */
export function isPipelineTemplateFulfillable (state) {
  return getMissingArtifactTypes(state).length === 0
}

/**
 * Returns `true` if the user can continue and the `Next` button should be enabled and `false` otherwise.
 * @param state The application's state.
 */
export function canContinue (state, otherOptions) {
  switch (otherOptions.stepId) {
    case Steps.TEMPLATE: return isTemplateStepValid(state, otherOptions)
    case Steps.FILES: return isFilesStepValid(state, otherOptions)
    case Steps.SETTINGS: return isSettingsStepValid(state, otherOptions)
    case Steps.NAV_INTERVAL: return isNavIntervalStepValid(state, otherOptions)
    case Steps.CONFIGURE: return isConfigureStepValid(state, otherOptions)
    default: return false
  }
}

export function canGotoStep (stepId, state, otherOptions) {
  const { pipelineWizardType } = otherOptions
  switch (stepId) {
    // Template step
    case Steps.TEMPLATE:
      return canGotoTemplateStep(state, otherOptions)
    // Artifacts step
    case Steps.FILES:
      return canGotoFilesStep(state, otherOptions)
    // Plp file step
    case Steps.SETTINGS:
      return canGotoSettingsStep(state, otherOptions)
    // Configure nav files intervals step
    case Steps.NAV_INTERVAL:
      return pipelineWizardType === PipelineWizardType.SPATIAL_FUSER
        ? true
        : canGotoNavIntervalStep(state, otherOptions)
    // Configure pipeline options
    case Steps.CONFIGURE:
      return canGotoConfigureStep(state, otherOptions)
    default:
      return false
  }
}

/**
 * Will return `true` if the user can proceed to the 'Template' step.
 */
export function canGotoTemplateStep () {
  return true
}

/**
 * Will return `true` if the user can proceed to the 'Files' step.
 * @param state The application's state.
 */
export function canGotoFilesStep (state, otherOptions) {
  return isTemplateStepValid(state, otherOptions)
}

/**
 * Will return `true` if the user can proceed to the 'Settings' step.
 * @param state The application's state.
 */
export function canGotoSettingsStep (state, otherOptions) {
  return isTemplateStepValid(state, otherOptions) &&
    isFilesStepValid(state, otherOptions)
}

/**
 * Will return `true` if the user can proceed to the 'Nav-interval' step.
 * @param state The application's state.
 */
export function canGotoNavIntervalStep (state, otherOptions) {
  return isTemplateStepValid(state, otherOptions) &&
    isFilesStepValid(state, otherOptions) &&
    isSettingsStepValid(state, otherOptions) && (
    otherOptions.navRoverArtifacts.length > 0
  )
}

/**
 * Will return `true` if the user can proceed to the third step.
 * @param state The application's state.
 */
export function canGotoConfigureStep (state, otherOptions) {
  return isTemplateStepValid(state, otherOptions) &&
    isSettingsStepValid(state, otherOptions) &&
    isNavIntervalStepValid(state, otherOptions) &&
    isFilesStepValid(state, otherOptions)
}

export function getTransformedId (artifactId, type, group) {
  return `${artifactId}.${type}${typeof group !== 'undefined' ? `.${group}` : ''}`
}

export function getArtifactsGroupedByIndex (state, pipelineTemplate) {
  if (typeof pipelineTemplate === 'undefined') {
    return {}
  }
  const { artifacts: templateArtifacts } = pipelineTemplate
  const artifactTypesToGroup = templateArtifacts
    .filter(templateArtifact => templateArtifact.group)
    .map(templateArtifact => templateArtifact.artifactType)
  const artifacts = getArtifacts(state)
  const artifactIds = getArtifactIdsForPipelineWizard(state)
  const selectedArtifacts = artifactIds.map(id => findById(id, artifacts)).filter(artifact => Boolean(artifact))
  const templateArtifactsToGroup = selectedArtifacts.filter(artifact => artifactTypesToGroup.includes(artifact.artifactType))
  let otherTemplateArtifacts = selectedArtifacts
    .filter(artifact => {
      const template = templateArtifacts.find(templateArtifact => templateArtifact.artifactType === artifact.artifactType)
      const { display = true } = template
      return display
    })
    .filter(artifact => !artifactTypesToGroup.includes(artifact.artifactType))
  const groupedByIndex = templateArtifactsToGroup.reduce((indices, artifact) => {
    const { artifactType } = artifact
    const templateArtifact = templateArtifacts.find(art => art.artifactType === artifactType)
    if (templateArtifact.split) {
      const splitBy = templateArtifact.splitBy
      const indexes = templateArtifact.getGroupIndexes(state, artifact)
      return {
        ...indices,
        ...splitBy.reduce((all, type) => {
          return {
            ...all,
            [type]: indexes[type].reduce((all, index) => ({
              ...all,
              [index]: [
                { ...artifact, transformedId: getTransformedId(artifact.id, type, index), artifactType: type },
                ...(all[index] || []),
              ],
            }), (indices[type] || {})),
          }
        }, {}),
      }
    }
    const index = getArtifactSensorIndex(artifact)
    if (typeof index === 'number') {
      const indicesByArtifactType = indices[artifactType] || {}
      return {
        ...indices,
        [artifactType]: {
          ...indicesByArtifactType,
          [index]: [
            { ...artifact, transformedId: artifact.id },
            ...(indicesByArtifactType[index] || []),
          ],
        },
      }
    } else {
      otherTemplateArtifacts.push(artifact)
      return indices
    }
  }, {})
  otherTemplateArtifacts = otherTemplateArtifacts.reduce((all, artifact) => {
    const { artifactType } = artifact
    const templateArtifact = templateArtifacts.find(art => art.artifactType === artifactType)
    if (templateArtifact.split) {
      const splitBy = templateArtifact.splitBy
      return [
        ...all,
        ...splitBy.map(type => ({
          ...artifact,
          transformedId: artifact.id + '.' + type,
          artifactType: type,
        })
        ),
      ]
    }
    return [...all, { ...artifact, transformedId: artifact.id }]
  }, [])
  return {
    otherTemplateArtifacts,
    groupedByIndex,
  }
}

export const StepType = {
  MERGED: 'merged',
  ARTIFACT: 'artifact',
  JOB: 'job',
}

/**
 * Return the artifact-step name for the grouped artifacts
 * @param {*} artifactType
 * @param {*} index
 */
export const getStepNameForArtifactsGroupedByIndex = (artifactType, index) => {
  if (isCameraArtifact(artifactType)) {
    return i18n.t('templates.artifactOptions.camera.index', { index })
  }
  if (isLidarArtifact(artifactType)) {
    return i18n.t('templates.artifactOptions.lidar.index', { index })
  }
  return undefined
}

export function getFormattedArtifactsGroupedByIndex (artifacts = {}, artifactType) {
  return Object.keys(artifacts).map(key => {
    const items = artifacts[key]
    const [firstItem] = items
    return {
      name: getStepNameForArtifactsGroupedByIndex(artifactType, key),
      item: items,
      sensorIndex: key,
      type: StepType.ARTIFACT,
      id: firstItem.id,
      transformedId: firstItem.transformedId,
    }
  })
}

export function getPipelineTemplateJobs (state, pipelineTemplate,) {
  if (typeof pipelineTemplate === 'undefined') {
    return 0
  }
  return pipelineTemplate.jobs
    .filter(job => typeof templateJobOptions[job.jobType] !== 'undefined')
    .filter(job => {
      const { enable = true } = job
      return typeof enable === 'function' ? enable(state) : enable
    })
}

/**
 * Returns the amount of configure steps. This is the amount of user input artifacts and the amount of jobs.
 * The amount of steps in the last step of the pipeline wizard is equal to the number returned by this function.
 * @param pipelineTemplate The pipeline template of which the step count should be calculated.
 */
export function getConfigureStepCount (state, pipelineTemplate, selectedTemplateArtifacts) {
  if (typeof pipelineTemplate === 'undefined') {
    return 0
  }
  const { groupedByIndex } = getArtifactsGroupedByIndex(state, pipelineTemplate)
  const tempTemplateJobs = getPipelineTemplateJobs(state, pipelineTemplate)
  // const { artifacts: templateArtifacts } = pipelineTemplate
  const { true: templateJobs } = partition(job => {
    const { display = true } = job
    return typeof display === 'function' ? display(state) : display
  }, tempTemplateJobs)
  const jobCount = templateJobs.length
  const { true: templateArtifacts } = partition(templateArtifact => {
    const { display = true } = templateArtifact
    return display && typeof getJobIoOptionsForArtifactTemplate(pipelineTemplate.type, templateArtifact.artifactType) !== 'undefined'
  }, getInputArtifacts(pipelineTemplate))
  const artifactCount = templateArtifacts
    .filter(templateArtifact => !templateArtifact.group)
    .map(templateArtifact => (selectedTemplateArtifacts[templateArtifact.templateId] || []).length)
    .reduce((a, b) => a + b, 0)
  const artifactTypes = Object.keys(groupedByIndex)
  return jobCount + artifactTypes.reduce((count, key) => {
    const groupedByArtifactType = groupedByIndex[key]
    return count + Object.keys(groupedByArtifactType).reduce((countByArtifactType, index) => {
      const items = groupedByArtifactType[index]
      return countByArtifactType + (items.length > 0 ? 1 : 0)
    }, 0)
  }, artifactCount)
}

function allOptionsValid (state, values, item, formDefinition) {
  // Check if the user has input anything at all.
  if (typeof values !== 'object') {
    const extra = item.id
    // If all options have been optional, then the job options are valid.
    // Otherwise at least one required option has not been specified.
    return isFormTemplateAllOptional(formDefinition, values, state, extra)
  }
  return values.valid || typeof formDefinition === 'undefined'
}

/**
 * Checks if all options for which the user can input values are optional for a job.
 * In other words: returns false, if there is at least one required field.
 * @param options The options to check.
 * @return `true` if all options are optional.
 */
function isFormTemplateAllOptional (options, values, state, extra) {
  // If there are no options (e.g. options is `undefined`), then there exist no
  // required options <=> all options are optional.
  if (typeof options !== 'object') {
    return true
  }
  return Object.keys(options).every(optionName => isFormFieldOptional(options[optionName], values, state, extra))
}

/**
 * Checks if all values of a job form are valid.
 * @param formValues The form values from the state. Maps a template id to a set of values.
 * @param idTypeMapping This object maps the `templateId`s to their type identifiers.
 * @param formTemplate The form template definition of the form.
 * @return Whether everything is valid.
 */
export function allJobFormValuesValid (formValues, idTypeMapping, formTemplate, state, selectedTemplateArtifacts) {
  return idTypeMapping.every(item => {
    const values = formValues[item.id]
    const formDefinition = formTemplate[item.type]

    // Check if the user has input anything at all.
    if (typeof values !== 'object') {
      const extra = Object.keys(selectedTemplateArtifacts).map(
        key => selectedTemplateArtifacts[parseInt(key)]
      )
      // If all options have been optional, then the job options are valid.
      // Otherwise at least one required option has not been specified.
      return isFormTemplateAllOptional(formDefinition, values, state, extra)
    }
    return values.valid || typeof formDefinition === 'undefined'
  })
}

/**
 * Checks if all values of the artifact form are valid.
 * @param formValues The form values from the state. Maps a template id to a set of values.
 * @param idTypeMapping This object maps the `templateId`s to their type identifiers.
 * @param formTemplate The form template definition of the form.
 * @return Whether everything is valid.
 */
export function allArtifactFormValuesValid (formValues, idTypeMapping, formTemplate, state) {
  const pipelineTemplate = getPipelineTemplate(state)
  const { artifacts: templateArtifacts } = pipelineTemplate
  return idTypeMapping.every(item => {
    const templateArtifact = templateArtifacts.find(art => art.artifactType === item.type)
    if (typeof templateArtifact.display !== 'undefined' && !templateArtifact.display ) {
      return true
    }
    if (templateArtifact.split) {
      return templateArtifact.splitBy.every(type => {
        const ids = Object.keys(formValues).filter(id => id.split('.')[0] === item.id && id.split('.')[1] === type)
        return ids.every(optionsId => allOptionsValid(state, formValues[optionsId], item, formTemplate[ids]))
      })
    }
    const values = formValues[item.id]
    const formDefinition = getJobIoOptionsForArtifactTemplate(pipelineTemplate.type, item.type)
    return allOptionsValid(state, values, item, formDefinition)
  })
}

/**
 * This function filters the given list of artifacts and returns only those
 * which the user did not yet select in any other select field for artifacts.
 * @param artifacts The list of artifacts to filter.
 * @param templateArtifactMap The map of selected artifacts.
 * @return All artifacts in `artifacts` which are not yet selected in `templateArtifactMap`.
 */
export function getUnselectedArtifacts (artifacts, templateArtifactMap) {
  const selectedIds = concatMap(
    key => templateArtifactMap[parseInt(key, 10)],
    Object.keys(templateArtifactMap)
  )
  return artifacts.filter(artifact => selectedIds.indexOf(artifact.id) === -1)
}

export const getArtifactStatusColor = status => {
  return theme.palette.artifactStatusColor[status] || 'inherit'
}

export function getArtifactStatusIcon (status, value) {
  const style = {
    backgroundColor: getArtifactStatusColor(status),
    color: '#fff',
    height: 20,
    borderRadius: '6px',
  }
  const isArtifactInAction =
    status === ArtifactStatuses.UPLOADING ||
    status === ArtifactStatuses.PROCESSING
    // status === ArtifactStatuses.WAITING
  const isArtifactScheduled =
    status === ArtifactStatuses.SCHEDULED
  return <Chip
    style={style}
    label={
      <React.Fragment>
        {isArtifactInAction && <CircularProgress color='inherit' size={12} style={{ marginRight: 2, verticalAlign: 'middle' }}/>}
        {isArtifactScheduled ? value &&
          <Trans
            i18nKey={`pipelineWizard.artifactStatus.${status}`}
            values={{ time: value }}
          />
          : PrettyArtifactStatuses[status]
        }
      </React.Fragment>
    }
    size='small'
  />
  /*
  if (status === ArtifactStatuses.UPLOADING) {
    return <IconUploading style={{ color: 'orange' }} />
  }
  if (status === ArtifactStatuses.PROCESSING) {
    return <IconWaiting style={{ color: 'green' }} />
  }
  if (status === ArtifactStatuses.OUTPUT_OF_FAILED_PIPELINE) {
    return <IconFailure style={{ color: 'red' }} />
  }
  if (status === ArtifactStatuses.READY) {
    return <IconSuccess style={{ color: 'green' }} />
  }
  if (status === ArtifactStatuses.UNCOMPLETED) {
    return <IconCancel style={{ color: 'grey' }} />
  }
  if (status === ArtifactStatuses.SCHEDULED) {
    return <IconWaiting style={{ color: 'orange' }} />
  }
  if (status === ArtifactStatuses.WAITING) {
    return <IconWaiting style={{ color: 'grey' }} />
  }
  */
}

/*
  Check if the first interval is fully overlapped by the second interval
*/
export function isIntervalsFullyOverlaps (interval1, interval2) {
  const start2 = interval2 && convertGpsTime(interval2.beginning)
  const end2 = interval2 && convertGpsTime(interval2.end)
  const start1 = interval1 && convertGpsTime(interval1.beginning)
  const end1 = interval1 && convertGpsTime(interval1.end)
  if (start2 && start1 && end2 && end1) {
    if (start1 >= start2 && end1 <= end2) {
      return true
    }
    return false
  }
  return false // ??
}

/*
  Check if the first interval is partially overlapped by the second interval
*/
export function isIntervalsPartiallyOverlaps (interval1, interval2) {
  const start2 = interval2 && convertGpsTime(interval2.beginning)
  const end2 = interval2 && convertGpsTime(interval2.end)
  const start1 = interval1 && convertGpsTime(interval1.beginning)
  const end1 = interval1 && convertGpsTime(interval1.end)
  if (start2 && start1 && end2 && end1) {
    if (start2 > end1 || end1 < start2) {
      return false
    }
    return true
  }
  return true // ??
}

function mergeIntervals (intervals) {
  let beginningInterval = intervals[0]
  let endInterval = intervals[0]
  const mergedIntervals = []
  const addToIntervals = () => mergedIntervals.push({
    beginning: {
      tow: beginningInterval.beginning.tow,
      week: beginningInterval.beginning.week,
    },
    end: {
      tow: endInterval.end.tow,
      week: endInterval.end.week,
    },
  })
  for (let i = 1; i <= intervals.length - 1; i++) {
    const interval = intervals[i]
    if ((
      gpsIntervalsEquals(interval, beginningInterval) || gpsIntervalsEquals(interval, endInterval) || (
        (interval.end.week === beginningInterval.beginning.week &&
          beginningInterval.beginning.tow - interval.end.tow === 1
        ) ||
        (interval.end.week === endInterval.beginning.week &&
          endInterval.beginning.tow - interval.end.tow === 1
        )
      )
    ) && i !== intervals.length - 1) {
      continue
    }
    // Change the endInterval reference to current interval if:
    // 1) If the difference between endInterval's tow and current interval is 1 (to merge intervals like: end ...399 and beginning 400)
    // 2)
    if (
      (interval.beginning.week === endInterval.end.week && interval.beginning.tow - endInterval.end.tow === 1) ||
      (interval.beginning.tow >= beginningInterval.beginning.tow && interval.beginning.tow <= endInterval.end.tow &&
        (interval.end.tow >= endInterval.end.tow || interval.end.week > endInterval.end.week)
      )
    ) {
      endInterval = interval
      if (i !== intervals.length - 1) continue
    }
    addToIntervals()
    beginningInterval = interval
    endInterval = interval
  }
  if (mergedIntervals.length === 0) addToIntervals()
  return mergedIntervals
}

const OverlapStrategy = {
  FULL: 'full',
  PARTIAL: 'partial',
}

function checkOverlapArtifacts (artifact1, artifact2, overlapOption) {
  const checkOverlap = overlapOption === OverlapStrategy.FULL
    ? isIntervalsFullyOverlaps
    : overlapOption === OverlapStrategy.PARTIAL
      ? isIntervalsPartiallyOverlaps
      : () => { return false }
  const artifact1Properties = (artifact1 && artifact1.properties) || {}
  const artifact2Properties = (artifact2 && artifact2.properties) || {}
  const artifact1Intervals = artifact1 ? getArtifactGpsIntervals(artifact1) : []
  const artifact2Intervals = artifact2 ? getArtifactGpsIntervals(artifact2) : []
  if (
    typeof artifact1Properties !== 'undefined' && typeof artifact2Properties !== 'undefined' &&
    (typeof artifact1Properties.interval !== 'undefined' || artifact1Intervals.length > 0) &&
    (typeof artifact2Properties.interval !== 'undefined' || artifact2Intervals.length > 0)
  ) {
    const artifact2Interval = [artifact2Properties.interval, artifact2Intervals[0]].filter(Boolean).map(transformLegacyGpsTimeInterval)[0]
    const artifact1Interval = [artifact1Properties.interval, artifact1Intervals[0]].filter(Boolean).map(transformLegacyGpsTimeInterval)[0]
    if (!checkOverlap(artifact1Interval, artifact2Interval)) {
      return false
    }
    artifact2Intervals.sort((a, b) => convertGpsTime(a.beginning) - convertGpsTime(b.beginning))
    if (artifact2Intervals.length > 0) {
      const intervals = mergeIntervals(artifact2Intervals)
      return intervals.some(interval => checkOverlap(
        overlapOption === OverlapStrategy.PARTIAL ? interval : artifact1Interval,
        overlapOption === OverlapStrategy.PARTIAL ? artifact1Interval : interval,
      ))
    }
  }
  return false
}

export function isArtifactIntervalsOverlaps (artifact1, artifact2) {
  return checkOverlapArtifacts(artifact1, artifact2, OverlapStrategy.FULL)
}

export function isArtifactIntervalsPartiallyOverlaps (artifact1, artifact2) {
  return checkOverlapArtifacts(artifact1, artifact2, OverlapStrategy.PARTIAL)
}

export const getArtifactIdsForPipelineWizard = state => {
  const pipelineTemplate = getPipelineTemplate(state)
  const selectedTemplateArtifacts = getSelectedTemplateArtifacts(state)

  const templateArtifactsWithOptions = getUserInputArtifacts(pipelineTemplate)
    .filter(artifact => typeof getJobIoOptionsForArtifactTemplate(pipelineTemplate.type, artifact.artifactType) !== 'undefined')
  const artifactIds = concatMap(
    templateArtifact => selectedTemplateArtifacts[templateArtifact.templateId] || [],
    templateArtifactsWithOptions
  )
  return artifactIds
}

export const getRefStationDatumsMap = (artifacts, artifactOptions, state) => {
  const datums = getDatums(state)

  return artifacts.reduce((allDatums, artifact) => {
    const options = artifactOptions[artifact.id] || {}
    const values = path(['values'], options)
    const selectedAnalyze = values && values.analyze
    if (values && isCustomAnalyzeSelected(values)) {
      // New CRS structure
      if (REF_STATION_BACKEND_NAME in values) {
        if (!values[REF_STATION_BACKEND_NAME]) return allDatums
        const crsDatum = values[REF_STATION_BACKEND_NAME].datum
        if (crsDatum) {
          const foundDatum = datums.find(datum => datum.name.includes(crsDatum.name))
          if (foundDatum) {
            return {
              ...allDatums,
              [foundDatum.navlab_datum]: true,
            }
          }
        }
      } else {
        const tab = values[CRSFields.TAB]
        const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
        // Old CRS structure
        if (tabTemplate) {
          const { fields } = tabTemplate
          let crsDatums = []
          if (fields[CRSFields.C_CRS]) {
            crsDatums = [
              ...((values[CRSFields.C_CRS] || {}).datums || []).slice(0, 1),
            ]
          } else if (fields[CRSFields.H_CRS] && fields[CRSFields.V_CRS]) {
            crsDatums = [
              ...((values[CRSFields.H_CRS] || {}).datums || []).slice(0, 1),
            ]
          } else {

          }
          const foundDatums = crsDatums
            .map(crsDatum => datums.find(datum => datum.name.includes(crsDatum)) || null)
            .filter(datum => datum)
            .map(datum => datum.navlab_datum)

          return foundDatums.length > 0
            ? {
              ...allDatums,
              ...foundDatums.reduce((aDatums, datum) => ({ ...aDatums, [datum]: true }), {}),
            }
            : allDatums
        }
      }
      return allDatums
    }
    return (selectedAnalyze && selectedAnalyze.datum)
      ? { ...allDatums, [selectedAnalyze.datum]: true }
      : allDatums
  }, {})
}

export function isMissionCanBePreselected (missionId, props) {
  const { pipelineTemplate, artifacts, isUserAdmin } = props
  const missionArtifacts = artifacts.filter(art => art.missionId === missionId)
  const { artifacts: templateArtifacts, jobs: templateJobs } = pipelineTemplate
  const [firstJob] = templateJobs
  const { inputs } = firstJob
  const allArtifactsByTemplateId = templateArtifacts.reduce((all, template) => ({
    ...all,
    [template.templateId]: missionArtifacts.filter(art => art.artifactType === template.artifactType),
  }), [])
  let nonDisabledMissionArtifacts = []
  const preselectableArtifactsByTemplateId = templateArtifacts.reduce((all, template) => {
    const { useMissionLimits, templateId } = template
    const artifactsForTemplate = allArtifactsByTemplateId[templateId] || []
    const range = getArtifactRange(templateId, pipelineTemplate, missionArtifacts, isUserAdmin)
    const preselectArtifacts = useMissionLimits ? range.missionMax : range.max
    const nonDisabledArtifactsForMission = artifactsForTemplate.filter(art => !isDisabledArtifact(art, props))
    nonDisabledMissionArtifacts = [
      ...nonDisabledMissionArtifacts,
      ...nonDisabledArtifactsForMission,
    ]
    return {
      ...all,
      [templateId]: artifactsForTemplate.filter(art => !isDisabledArtifact(art, props)).slice(0, preselectArtifacts),
    }
  }, {})
  let metMinConditions = true
  let canPreselect = true
  const errors = []
  const warnings = []
  inputs.forEach(input => {
    const { templateId, useMissionLimits } = input
    const template = templateArtifacts.find(template => template.templateId === templateId)
    const { artifactType } = template
    const allArtifactsForTemplate = allArtifactsByTemplateId[templateId]
    const completedArtifactsForTemplate = allArtifactsForTemplate.filter(art => isArtifactCanBeSelectedForProcessing(art))
    const uncompletedArtifactsForTemplate = allArtifactsForTemplate.filter(art => !isArtifactCanBeSelectedForProcessing(art))
    const range = getArtifactRange(templateId, pipelineTemplate, missionArtifacts, isUserAdmin)
    const minValue = useMissionLimits ? range.missionMin : range.min
    // const maxValue = useMissionLimits ? range.missionMax : range.max
    const isValid = completedArtifactsForTemplate.length >= minValue
    const prettyArtifactStatus = prettifyArtifactType(artifactType)
    if (minValue === 0) {
      // If min required amount of artifact for this template is 0 and we have no suitable artifacts
      // we can select this mission, but we don't need to PRESELECT the mission at the first place
      if (completedArtifactsForTemplate.length <= 0 && uncompletedArtifactsForTemplate.length > 0) {
        canPreselect = false
        warnings.push(`Uncompleted ${prettyArtifactStatus} ${uncompletedArtifactsForTemplate.map(art => art.name).join(', ')} artifacts from this mission can't be used during processing`)
      }
    } else if (!isValid) {
      metMinConditions = false
      if (allArtifactsForTemplate.length > minValue) {
        errors.push(
          <React.Fragment>
            <span>Mission does not have enough completed {prettyArtifactStatus} artifacts.</span>
            <div><b>Uncompleted {prettyArtifactStatus} artifacts</b>: {uncompletedArtifactsForTemplate.map(art => art.name).join(', ')}</div>
          </React.Fragment>
        )
      } else {
        errors.push(`Mission does not have enough completed ${prettyArtifactStatus} artifacts. `)
      }
    }
  })
  return {
    valid: metMinConditions,
    canPreselect: metMinConditions ? canPreselect : false,
    preselectableArtifacts: preselectableArtifactsByTemplateId,
    allArtifactsByTemplateId,
    missionArtifacts,
    nonDisabledMissionArtifacts,
    errors,
    warnings,
    id: missionId,
  }
}

const isArtifactIntervalsIntersects = (art1, art2) => {
  const art1Intervals = getArtifactGpsIntervals(art1)
  const art2Intervals = getArtifactGpsIntervals(art2)
  return art1Intervals.some(art1Interval => {
    return art2Intervals.some(art2Interval => {
      if (
        art2Interval &&
        art1Interval
      ) {
        const art1Start = convertGpsTime(art1Interval.beginning)
        const art1End = convertGpsTime(art1Interval.end)
        const art2Start = convertGpsTime(art2Interval.beginning)
        const art2End = convertGpsTime(art2Interval.end)
        return !(art1End < art2Start || art1Start > art2End)
      }
    })
  })
}

export function isDisabledArtifact (artifact, props, disabledArtifacts) {
  const {
    navRoverGrants,
    lidarProcessingGrants,
    mission,
    missions,
    pipelines,
    pipelineWizardType,
    artifactsIntersection,
  } = props
  const { artifactType, properties, artifactStatus, id: artifactId } = artifact
  if (artifactStatus === ArtifactStatuses.UNCOMPLETED) {
    return true
  }
  if (pipelineWizardType === PipelineWizardType.ORTHOIMAGE_GENERATION) {
    if (isPointcloudArtifact(artifactType)) {
      // Disable Pointcloud artifact which are not overlapped with one of the camera artifacts
      const { selectedCameraArtifacts = [], selectedPointcloudArtifacts = [] } = props
      let isDisabled = !selectedCameraArtifacts.some(selectedCameraArtifact => isArtifactIntervalsPartiallyOverlaps(artifact, selectedCameraArtifact))
      if (selectedPointcloudArtifacts.length > 0 && !isDisabled) {
        // Disable pointcloud artifact if it's overlapping with the other pointcloud artifact by time
        isDisabled = selectedPointcloudArtifacts
          .filter(selectedPointCloudArtifact => selectedPointCloudArtifact.id !== artifactId)
          .some(selectedPointCloudArtifact => isArtifactIntervalsPartiallyOverlaps(artifact, selectedPointCloudArtifact))
      }
      return isDisabled
    }
    if (isCameraArtifact(artifactType)) {
      const { selectedCameraArtifacts = [] } = props
      if (selectedCameraArtifacts.length <= 0) {
        return false
      }
      const sensorIndices = selectedCameraArtifacts.map(art => getArtifactSensorIndex(art))
      sensorIndices.sort()
      // Get the lowest sensor index
      const [lowestSensorIndex] = sensorIndices
      // Disable artifact which has different sensor index
      return getArtifactSensorIndex(artifact) !== lowestSensorIndex
    }
  }
  if (pipelineWizardType === PipelineWizardType.NAVLAB) {
    // Disable RefStation `Artifact`s which do not intersect with any selected NavRover.
    if (isRefStationArtifact(artifactType)) {
      const { selectedNavigationRoverArtifacts = [] } = props
      return !selectedNavigationRoverArtifacts.some(selectedNavigationRoverArtifact => isArtifactIntervalsIntersects(artifact, selectedNavigationRoverArtifact))
      /*
      return !selectedNavigationRoverArtifacts.some(selectedNavigationRoverArtifact => {
        const navRoverProperties = (selectedNavigationRoverArtifact && selectedNavigationRoverArtifact.properties) || {}
        if (
          typeof navRoverProperties !== 'undefined' &&
          typeof navRoverProperties.interval !== 'undefined' &&
          typeof properties !== 'undefined' &&
          typeof properties.interval !== 'undefined'
        ) {
          const refStationInterval = properties.interval
          const navRoverInterval = navRoverProperties.interval
          const refStationStart = convertGpsTime(refStationInterval.beginning)
          const refStationEnd = convertGpsTime(refStationInterval.end)
          const navRoverStart = convertGpsTime(navRoverInterval.beginning)
          const navRoverEnd = convertGpsTime(navRoverInterval.end)
          return !(refStationEnd < navRoverStart || refStationStart > navRoverEnd)
        }
      })
      */
    }
    if (isNavRoverArtifact(artifactType)) {
      // Disable artifacts for which we do not have a valid grant.
      return navRoverGrants.length <= 0
        ? true
        : navRoverGrants.some(grant =>
          !isNil(grant.grant.context) &&
            grant.grant.context.artifact_id === artifact.id &&
            !grant.allowed
        )
    }
  }
  if (isTrajectoryArtifact(artifactType) &&
    pipelineWizardType === PipelineWizardType.POINTCLOUD_OPTIMIZATION &&
    artifact.mission
  ) {
    const { selectedPointcloudArtifacts = [] } = props
    return !isArtifactCanBeSelectedForProcessing(artifact) ||
      !selectedPointcloudArtifacts.some(selectedPointcloudArtifact => isArtifactIntervalsIntersects(artifact, selectedPointcloudArtifact))
    /*
    const [trajectoryInterval] = getArtifactGpsIntervals(artifact)
    return !selectedPointcloudArtifacts.some(selectedPointcloudArtifact => {
      const [pointcloudInterval] = getArtifactGpsIntervals(selectedPointcloudArtifact)
      if (
        pointcloudInterval &&
        trajectoryInterval
      ) {
        const trajectoryStart = convertGpsTime(trajectoryInterval.beginning)
        const trajectoryEnd = convertGpsTime(trajectoryInterval.end)
        const pointcloudStart = convertGpsTime(pointcloudInterval.beginning)
        const pointcloudEnd = convertGpsTime(pointcloudInterval.end)
        return !(trajectoryEnd < pointcloudStart || trajectoryStart > pointcloudEnd)
      }
    })
    */
  }
  if (pipelineWizardType === PipelineWizardType.SPATIAL_FUSER) {
    const { selectedGeotiffArtifacts = [] } = props
    if (isCameraArtifact(artifactType) && selectedGeotiffArtifacts.length > 0) {
      return true
    }
    if (isTrajectoryArtifact(artifactType) && mission) {
      const { selectedTrajectoryArtifact } = props
      if (selectedTrajectoryArtifact) {
        return !isArtifactCanBeSelectedForProcessing(artifact) || artifact.id !== selectedTrajectoryArtifact.id
      }
    }
    // Disable Recon artifacts without properties (it means that artifact is still in processing or failed)
    if (isReconDataArtifact(artifactType)) {
      const mission = missions.find(m => m.id === artifact.mission.id)
      if (!mission || (mission && !mission.plp)) {
        return true
      }
      const [firstAnalyzingPipeline] = artifact.analyzingPipelines
      if (!firstAnalyzingPipeline) {
        return true
      }
      const pipeline = pipelines.find(pipe => pipe.id === firstAnalyzingPipeline.id)
      if (!pipeline || (pipeline && isSomePipelineProcessing([pipeline]))) {
        return true
      }
    }
    if (isPolygonArtifact(artifactType)) {
      const intersections_ = artifactsIntersection[artifactId] || {}
      const intersectionKeys = Object.keys(intersections_)
      const intersections = intersectionKeys.map(artifactId => ({ id: artifactId, ...intersections_[artifactId] }))
      const selectedTrajectoriesIntersections = intersections
        .filter(intersection => intersection.artifactType === ArtifactTypes.TRAJECTORY && intersection.selected)
      return !selectedTrajectoriesIntersections.every(intersection => intersection.intersects)
    }
    const { missionId } = artifact
    // Disable artifacts for which we do not have a valid grant.
    if (missionId) {
      return lidarProcessingGrants.length <= 0
        ? false
        : lidarProcessingGrants.some(grant =>
          !isNil(grant.grant.context) &&
        grant.grant.context.mission_id === missionId &&
        !grant.allowed
        )
    }
  }
  // Disable pointcloud artifact if it is already classified
  if (
    isPointcloudArtifact(artifactType) &&
    pipelineWizardType === PipelineWizardType.CLASSIFICATION
  ) {
    return Boolean(properties.classified)
  }
  return false
}

// Get the jobs for which this artifact is used as input.
export function getJobsForArtifactTemplate (artifactTemplateId, templateJobs) {
  return templateJobs
    .filter(job => (job.inputs || []).some(input => `${input.templateId}` === `${artifactTemplateId}`))
    .map(job => job.jobType)
}

export function getArtifactsWithIntervals (state, artifactType) {
  const pipelineTemplate = getPipelineTemplate(state)
  const artifacts = getArtifacts(state)
  const selectedTemplateArtifacts = getSelectedTemplateArtifacts(state)
  const { artifacts: templateArtifacts } = pipelineTemplate

  // Find a navrover template artifact.
  const navRoverTemplateArtifact = templateArtifacts.find(artifact => artifact.artifactType === artifactType)
  // Find the actual artifacts selected for this template artifact.
  const selectedNavigationRoverArtifactIds = navRoverTemplateArtifact
    ? selectedTemplateArtifacts[navRoverTemplateArtifact.templateId] || []
    : []
  const selectedNavigationRoverArtifacts = artifacts
    .filter(art => selectedNavigationRoverArtifactIds.includes(art.id))
    .filter(({ properties = {} }) => properties.imuGaps && properties.poses && properties.trackingStats)
    .filter(({ properties = {} }) => properties.poses.length > 0 || properties.trackingStats.length > 0)
  return selectedNavigationRoverArtifacts
}

export const transformTimeToName = (time, int = true) => {
  return time.week + '/' + (int ? parseInt(time.tow) : time.tow)
}

export const isPropertiesMissing = artifact => {
  const { artifactType, properties } = artifact
  if (isNavRoverArtifact(artifactType)) {
    return typeof properties === 'undefined' || typeof properties.inertial === 'undefined'
  }
  if (isRefStationArtifact(artifactType)) {
    return typeof properties === 'undefined' || typeof properties.position === 'undefined'
  }
  if (isTrajectoryArtifact(artifactType)) {
    return typeof properties === 'undefined'
  }
  return false
}

export const getOptionalErrorString = artifact => {
  if (isPropertiesMissing(artifact)) {
    return ''
  }
  const { artifactType, properties } = artifact
  if (isRefStationArtifact(artifactType)) {
    const position = properties.position
    const keys = Object.keys(position)
    const positionEntries = keys.map(key => ({
      ...position[key],
      priority: analyzePriority[key] || Infinity,
    }))
      .filter(positionEntry => {
        const { failed, estimatedErrorRms } = positionEntry
        // Remove all failed and empty entries
        return !((typeof failed !== 'undefined' && Boolean(failed)) || typeof estimatedErrorRms === 'undefined')
      })
      .sort((a, b) => a.priority - b.priority)

    const mostPrecise = positionEntries.reduce((b, a) => (
      a.estimatedErrorRms < b.estimatedErrorRms &&
        a.priority <= b.priority &&
        a.estimatedErrorRms
    ) ? a : b, { estimatedErrorRms: Infinity, priority: Infinity }
    )
    const bestError = mostPrecise && mostPrecise.estimatedErrorRms
    const roundedCm = Math.round(bestError * 10000) / 100
    return ` | Error: ${roundedCm}cm`
  }
  if (isTrajectoryArtifact(artifactType) && properties.errorRms) {
    return ` | Error: ${withMaxPrecision(properties.errorRms.toString(), 3)}°`
  }
  return ''
}

export const getOptionalOtherPropsString = artifact => {
  if (isPropertiesMissing(artifact)) {
    return ''
  }
  const { artifactType, properties } = artifact
  const result = []
  if (isRefStationArtifact(artifactType) && typeof properties.interval !== 'undefined') {
    const { beginning, end } = transformLegacyGpsTimeInterval(properties.interval)
    const duration = convertGpsTime(end) - convertGpsTime(beginning)
    const totalMinutes = Math.floor(duration / 60)
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes - (hours * 60)
    if (hours > 0) {
      result.push(` | Duration: ${hours}h ${minutes}m`)
    } else {
      result.push(` | Duration: ${minutes}m`)
    }
  }
  return result
}

export const getArtifactScheduledValue = artifact => {
  let value = ''
  if (artifact.artifactStatus === ArtifactStatuses.SCHEDULED) {
    value = moment(artifact.scheduled * 1000)
      // .zone('+00:00')
      .format('DD MMM HH:mm:ss')
  }
  return value
}
