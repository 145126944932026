/**
* Creates a Url from Url fragments. e.g. from
* ```
* {
*     pathname: "/lol",
*     search: "?a=b",
*     heash: "#rofl"
* }
* ```
* it will create `/lol?a=b#rofl`. Basically it just concats the fragments in the correct order.
* @param fragments The url fragments to create the url from.
*/
export function createUrl (fragments) {
  if (!fragments) {
    return undefined
  }
  const { pathname, search, hash } = fragments
  // Url without a path is not considered a valid url.
  if (typeof pathname !== 'string') {
    return undefined
  }
  const guardedSearch = typeof search === 'string' ? search : ''
  const guardedHash = typeof hash === 'string' ? hash : ''
  return `${pathname}${guardedSearch}${guardedHash}`
}
