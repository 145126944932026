import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// Material UI dependencies.
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
// Project internal dependencies.

const Breadcrumb = props => {
  const { breadcrumb, options } = props
  const { last, tabsValue, onClick, classes } = options
  const { label = '', path: url, component } = breadcrumb
  const RenderComponent = component || Link
  // Maximum breadcrumb text length is 120
  const maximumLabelLength = 120
  const isComponentTab = typeof label === 'function'
  const Component = isComponentTab && label
  const longText = label.length >= maximumLabelLength
  const text = label === ''
    ? <Trans i18nKey='breadcrumbs.noLabel'/>
    : isComponentTab
      ? <Component />
      : longText
        ? label.substring(0, maximumLabelLength) + '...'
        : label
  const content = (
    last
      ? <Typography style={{ position: 'relative' }} component={Link} key={url} className={classNames(classes.breadcrumbActive, classes.breadcrumbLink)} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
        {text}
      </Typography>
      : <RenderComponent
        {...(component === 'a' && { href: url })}
        key={url}
        to={url}
        id={url}
        className={classes.breadcrumbLink}
        onClick={component === 'a' ? () => {} : onClick(tabsValue)}
      >
        {text}
      </RenderComponent>
  )
  return (
    longText
      ? <Tooltip key={text} title={label}>
        {content}
      </Tooltip>
      : content
  )
}

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.object,
  options: PropTypes.object,
}

export default Breadcrumb
