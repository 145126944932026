import { degreesToRadians, radiansToDegrees } from '@turf/turf'
import { Matrix3, Matrix4, Vector3, Euler } from 'math-ds'
import { makeUnique } from 'utils/list'

/**
 * Returns rotation tuple
 * @param  {Object} transform
 * @return {Array}
 */
export function getRotation (transform) {
  // If transform is not provided return default setup
  if (!transform) return [0, 0, 0]
  return [
    'rx' in transform ? transform.rx : transform.pitch,
    'ry' in transform ? transform.ry : transform.yaw,
    'rz' in transform ? transform.rz : transform.roll,
  ]
}

/**
 * Returns translation tuple
 * @param  {Object} transform
 * @return {Array}
 */
export function getTranslation (transform) {
  // If transform is not provided return default setup
  if (!transform) return [0, 0, 0]
  return [
    'tx' in transform ? transform.tx : transform.x,
    'ty' in transform ? transform.ty : transform.y,
    'tz' in transform ? transform.tz : transform.z,
  ]
}

/**
 * Returns the Matrix3 object with initial values set to 'values' array (make matrix from an array of values)
 * @param  {Matrix4} matrix
 * @return {Matrix3}
 */
export const getMatrix3FromArray = values => {
  return new Matrix3().set(
    values[0], values[1], values[2],
    values[3], values[4], values[5],
    values[6], values[7], values[8],
  )
}

/**
 * Returns the Matrix3 object with initial values set to uppper 3x3 matrix of rotation matrix
 * @param  {Matrix4} matrix
 * @return {Matrix3}
 */
function getMatrix3FromRotationMatrix (rotationMatrix) {
  const me = rotationMatrix.elements
  const m3 = new Matrix3().set(
    me[0], me[4], me[8],
    me[1], me[5], me[9],
    me[2], me[6], me[10],
  )
  return m3// new Matrix3().setFromMatrix4(rotationMatrix)
}

/**
 * Returns Matrix4 object with basis set to values from Matrix3
 * @param  {Matrix3} matrix
 * @return {Matrix4} Rotation matrix
 */
export const getRotationMatrixFromMatrix3 = matrix => {
  const values = matrix.toArray()
  return new Matrix4().makeBasis(
    new Vector3(values[0], values[1], values[2]),
    new Vector3(values[3], values[4], values[5]),
    new Vector3(values[6], values[7], values[8]),
  )
}

const SensorModelLidarModel = {
  'ldr-hdl32': 'Velodyne HDL 32E',
  'ldr-vlp32': 'Velodyne VLP 32C',
  'ldr-vlp16': 'Velodyne VLP 16',

  'ldr-v1uv': 'Riegl VUX-1 UAV',
  'ldr-v1lr': 'Riegl VUX-1 LR',
  'ldr-v1ha': 'Riegl VUX-1 HA',
  'ldr-mv1uv': 'Riegl miniVUX-1 UAV',
  'ldr-mv2uv': 'Riegl miniVUX-2 UAV',
  'ldr-v240': 'Riegl VUX-240',

  'ldr-qum8': 'Quanergy M8',
  'ldr-p10': 'Pioneer P10',
  'ldr-p360': 'Pioneer P360',
}

export const findSensorModel = (mission, artifact) => {
  let sensorModel = ''
  if (artifact) {
    const { properties } = artifact
    const { fileProperties = {} } = properties
    // Try to find sensorModel in artifact.fileProperties
    const sensorModels = Object.values(fileProperties).map(fileProperties => {
      const { settingsAcquisition } = fileProperties
      return settingsAcquisition && settingsAcquisition.sensorModel
    })
    // Assume we have the same lidar model for all files in artifact
    sensorModel = makeUnique(sensorModels)[0]
  }
  // If no sensorModel found try to find it in mission PLP
  if (!sensorModel && mission && mission.plp) {
    const sessionsLidars = mission.plp.sessionsLidars
    const sessionsLidarsKeys = Object.keys(sessionsLidars || {}) || []
    const sessions = sessionsLidarsKeys.reduce((allSessions, key) => [...allSessions, ...sessionsLidars[key].sessions], [])
    sensorModel = makeUnique(sessions.map(properties => {
      const { settingsAcquisition } = properties
      return settingsAcquisition.sensorModel
    }))[0]
  }
  return sensorModel
}

/**
 * Returns Transform with rotation set to YXZ rotation matrix and translation set to traslationIn Vector3
 * @param  {Vector3} translationIn
 * @param  {Number} yaw Rotation in degrees
 * @param  {Number} pitch Rotation in degrees
 * @param  {Number} roll Rotation in degrees
 * @return {Transform}
 */
export function fromYPR (translationIn, yaw, pitch, roll) {
  return new Transform(
    makeYXZRotationMatrix({ x: pitch, y: yaw, z: roll }),
    translationIn,
  )
}

function loadFrom (o) {
  return fromYPR(
    new Vector3(o['x'], o['y'], o['z']),
    o['yaw'],
    o['pitch'],
    o['roll'],
  )
}

/**
 * Because we now do calibration in IMU frame, we multiply mounting with imuOrientation in computeWorldPosition,
 * thus we need to multiply it with its inverse here, when loading old projects, to get the same orientation.
 * @param  {Matrix3} mRotationPlsToImu Rotation matrix PLS to IMU
 * @param  {Transform} transform
 * @return {Transform}
 */
const applyInversedRotationMatrixPlsToImu = (mRotationPlsToImu, transform) => {
  const mRotationPlsToIMUInversed = new Matrix3().getInverse(mRotationPlsToImu)
  const newTransform = new Transform()
  newTransform.rotation = mRotationPlsToIMUInversed.clone().multiply(transform.rotation).clone()
  newTransform.translation = transform.translation.clone().applyMatrix3(mRotationPlsToIMUInversed).clone()
  return newTransform
}

/**
 * If PLP has writeEvents field in it - SE6 and higher, other SE5 and lower
 * @param  {Object} plp
 * @return {Boolean}
 */
export const isSE5AndLowerPLP = plp => {
  return !('writeEvents' in plp)
}

export const transformLidarCalibration = (sensorModel, transform, orientation) => {
  const { offset, rotation } = transform
  const imuOrientation = orientation || [0, 0, 0]
  // Making Transform object from rotation and offset
  let mounting = loadFrom({
    'pitch': rotation[0],
    'yaw': rotation[1],
    'roll': rotation[2],
    'x': offset[0],
    'y': offset[1],
    'z': offset[2],
  })
  const mRotationPlsToImu = getRotationPlsToImu({ x: imuOrientation[0], y: imuOrientation[1], z: imuOrientation[2] })
  if (sensorModel) {
    // Apply rotation to fix the incorrect orientation due to convention change (PLS to Manufacturer)
    if (sensorModel.includes('Velodyne')) {
      mounting = rotateMountingVelodyne(mounting)
    } else if (sensorModel === SensorModelLidarModel['ldr-qum8']) {
      mounting = rotateMountingQuanergy(mounting)
    } else if (sensorModel.includes('Riegl') && sensorModel !== SensorModelLidarModel['ldr-v240']) {
      mounting = rotateMountingVuxLr(mounting)
    } else if (sensorModel === SensorModelLidarModel['ldr-v240']) {
      mounting = rotateMountingVux240(mounting)
    }
  }
  mounting = applyInversedRotationMatrixPlsToImu(mRotationPlsToImu, mounting)
  return mounting.toOffsetAndRotation()
}

export const transformCameraCalibration = (transform, orientation) => {
  const { offset, rotation } = transform
  const imuOrientation = orientation || [0, 0, 0]
  // Making Transform object from rotation and offset
  const mounting = loadFrom({
    'pitch': rotation[0],
    'yaw': rotation[1],
    'roll': rotation[2],
    'x': offset[0],
    'y': offset[1],
    'z': offset[2],
  })
  const mRotationPlsToImu = getRotationPlsToImu({ x: imuOrientation[0], y: imuOrientation[1], z: imuOrientation[2] })
  return applyInversedRotationMatrixPlsToImu(mRotationPlsToImu, rotateCamerasPlsToOpenCv(mounting)).toOffsetAndRotation()
}

/**
 * Return the container for sensor/camera transform with rotation set to Matrix3 and translation set Vector3
 * @param  {Matrix3} rotation if not set = identity Matrix3
 * @param  {Vector3} translation if not set = zero Vector3
 * @return {Transform}
 */
function Transform (rotation, translation) {
  this.translation = new Vector3()
  this.rotation = new Matrix3().identity()
  if (rotation) {
    this.rotation = rotation.clone()
  }
  if (translation) {
    this.translation = translation.clone()
  }
  /**
   * Multiplying two Transforms will return new Transform with:
   * rotation set to: this.rotation * other.rotation
   * translation set to: other.translation * multiply this.rotation + this.translation
   * @param  {Transform} otherTransform
   * @return {Transform} new transform
  */
  this.multiply = otherTransform => {
    return new Transform(
      (this.rotation.clone().multiply(otherTransform.rotation)),
      otherTransform.translation.clone().applyMatrix3(this.rotation).add(this.translation),
    )
  }
  /**
   * Return the array of angles describing XYZ rotation
   * @return {Object}
  */
  this.getEulerAnglesXYZ = () => {
    // Make Matrix4 from Matrix3 (because Euler on line 248 accepts only Matrix4)
    const matrix4 = getRotationMatrixFromMatrix3(this.rotation)
    // ZYX (210) euler angle is equivalent to XYZ (012) fixed axis rotation
    const euler = new Euler().setFromRotationMatrix(matrix4, 'ZYX')
    // There is no need to set them as [euler.z, euler.y, euler.x] math-ds do that internally
    return [euler.x, euler.y, euler.z].map(radiansToDegrees)
  }
  /**
   * Return the object with offset and rotation arrays (because we use an arrays in UI)
   * @return {Object}
  */
  this.toOffsetAndRotation = () => {
    return {
      offset: [this.translation.x, this.translation.y, this.translation.z],
      rotation: this.getEulerAnglesXYZ(),
    }
  }
  return this
}

/**
 * All rotateMounting* functions use the same algorithm for rotation = transform * Transform(Matrix3f(m.transpose()))
 * So I used this function to make code more cleaner.
 * Returns the result of multiplying sensor transform by manufacturer rotation matrix
 * @param  {Array}     matrixArray Input array of values that corresponds to manufacturer rotation matrix
 * @param  {Transform} transform Input sensor transform
 * @return {Transform}
 */
const rotateMounting = (matrixArray, transform) => {
  // Make Matrix3 from an array
  const m = getMatrix3FromArray(matrixArray)
  // From phusion -> common -> convertersettings.cpp lines 17, 41, 56, 79
  // transform * Transform(Matrix3f(m.transpose()))
  return transform.multiply(new Transform(m.transpose()))
}

/**
 * Here I just returns the result of setNovAtelInertialExplorer
 * because spatial fuser pipelines run with the '--setimuorientation" "inertialexplorer:x,y,z" command
 * So the orientation argument will not be from PLP file, but from trajectory (or entered by user if not exist)
 * and will have vehicleToImu orientation
 * @param  {Object} orientation Object with x, y, z fields describing the orientation
 * @return {Matrix3} Rotation matrix PLS to IMU
 */
export function getRotationPlsToImu (orientation) {
  return setNovAtelInertialExplorer(orientation)
}

/**
 * Returns rotation matrix with ZXY order
 * @param  {Object} orientation Object with x, y, z fields describing the orientation
 * @return {Matrix3} Rotation matrix with ZXY order
 */
export function makeZXYRotationMatrix (orientation) {
  // Create rotation matrix around x axis
  const rotationX = new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), degreesToRadians(orientation.x))
  // Create rotation matrix around y axis
  const rotationY = new Matrix4().makeRotationAxis(new Vector3(0, 1, 0), degreesToRadians(orientation.y))
  // Create rotation matrix around z axis
  const rotationZ = new Matrix4().makeRotationAxis(new Vector3(0, 0, 1), degreesToRadians(orientation.z))
  // Multiply in ZXY order
  // Result of multiplying will be stored in rotationZ matrix
  rotationZ.multiply(rotationX).multiply(rotationY)
  return getMatrix3FromRotationMatrix(rotationZ)
}

/**
 * Returns rotation matrix with ZYX order
 * @param  {Object} orientation Object with x, y, z fields describing the orientation
 * @return {Matrix3} Rotation matrix with ZYX order
 */
export function makeZYXRotationMatrix (orientation) {
  // Create rotation matrix around x axis
  const rotationX = new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), degreesToRadians(orientation.x))
  // Create rotation matrix around y axis
  const rotationY = new Matrix4().makeRotationAxis(new Vector3(0, 1, 0), degreesToRadians(orientation.y))
  // Create rotation matrix around z axis
  const rotationZ = new Matrix4().makeRotationAxis(new Vector3(0, 0, 1), degreesToRadians(orientation.z))
  // Multiply in ZYX order
  // Result of multiplying will be stored in rotationZ matrix
  rotationZ.multiply(rotationY).multiply(rotationX)
  return getMatrix3FromRotationMatrix(rotationZ)
}

/**
 * Returns rotation matrix with YXZ order
 * @param  {Object} orientation Object with x, y, z fields describing the orientation
 * @return {Matrix3} Rotation matrix with YXZ order
 */
export function makeYXZRotationMatrix (orientation) {
  // Create rotation matrix around x axis
  const rotationX = new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), degreesToRadians(orientation.x))
  // Create rotation matrix around y axis
  const rotationY = new Matrix4().makeRotationAxis(new Vector3(0, 1, 0), degreesToRadians(orientation.y))
  // Create rotation matrix around z axis
  const rotationZ = new Matrix4().makeRotationAxis(new Vector3(0, 0, 1), degreesToRadians(orientation.z))
  // YXZ (102) euler angle rotation is equivalent to ZXY (201) fixed axis rotation
  // Result of multiplying will be stored in rotationY matrix
  rotationY.multiply(rotationX).multiply(rotationZ)
  return getMatrix3FromRotationMatrix(rotationY)
}

export function getPlsToNovAtelVehicle () {
  // plsToNovatelVehicle - a fixed rotation between the PLS frame and Novatel vehicle frame
  const plsToNovatelVehicle = [
    1, 0, 0,
    0, 0, 1,
    0, -1, 0,
  ]
  return getMatrix3FromArray(plsToNovatelVehicle)
}

function setNovAtelInertialExplorer (vehicleToImu) {
  // rotationPlsToImu * imuToAxesSwappedImu = plsToNovatelVehicle * novatelVehicleToAxesSwappedImu
  // therefore
  // rotationPlsToImu = plsToNovatelVehicle * novatelVehicleToAxesSwappedImu * imuToAxesSwappedImu^-1
  // This is the input ZXY rotation, as described in APN 065, page 6
  const novatelImuToVehicle = makeZXYRotationMatrix(vehicleToImu)
  return getPlsToNovAtelVehicle().multiply(novatelImuToVehicle)
}

/**
 * Rotate sensor due to Velodyne model
 * Returns rotated transform.rotation due to Velodyne model
 * @param  {Transform} transform Input sensor transform
 * @return {Transform} Rotated transform
 */
export function rotateMountingVelodyne (transform) {
  // In SE6, we don't use socs anymore, but rather polar coordinates. Because of that, we opted to go for
  // manufacturer-defined socs frame when converting to cartesian, thus using different coordinate system
  // than PLS. This means we now have to rotate our orientations when loading SE5 projects. This is a
  // simple rotation matrix derived from PLS and manufacturer sensor systems.
  // PLS old : x right, y up, z back
  // Velodyne: x right, y forward, z up
  // --------------
  // x   y   z manufacturer
  // x  -z  y manufacturer from PLS old
  const m = [
    1, 0, 0,
    0, 0, -1,
    0, 1, 0,
  ]
  // Line 225 for more documentation
  return rotateMounting(m, transform)
}

/**
 * Rotate sensor due to Quanergy model
 * Returns rotated transform.rotation due to Quanergy model
 * @param  {Transform} transform Input sensor transform
 * @return {Transform} Rotated transform
 */
export function rotateMountingQuanergy (transform) {
  // PLS old : x right, y up, z back
  // Quanergy: x forward, y left, z up
  const m = [
    0, 0, -1,
    -1, 0, 0,
    0, 1, 0,
  ]
  // Line 225 for more documentation
  return rotateMounting(m, transform)
}

/**
 * Rotate sensor due to Riegl model
 * Returns rotated transform.rotation due to Riegl model
 * @param {Transform} transform Input sensor transform
 * @return {Transform} Rotated transform
 */
export function rotateMountingVuxLr (transform) {
  // PLS:   X right, Y up, Z back
  // Riegl: X up, Y right, Z forward
  const m = [
    0, 1, 0,
    1, 0, 0,
    0, 0, -1,
  ]
  // Line 225 for more documentation
  return rotateMounting(m, transform)
}

/**
 * Rotate sensor due to RieglVux240 model
 * Returns rotated transform.rotation due to RieglVux240 model
 * @param {Transform} transform Input sensor transform
 * @return {Transform} Rotated transform
 */
export function rotateMountingVux240 (transform) {
  // PLS:   X right, Y up, Z back
  // Riegl: X down, Y forward, Z right
  const m = [
    0, -1, 0,
    0, 0, -1,
    1, 0, 0,
  ]
  // Line 225 for more documentation
  return rotateMounting(m, transform)
}

export function plsToOpenCv () {
  // PLS: X right, Y up, Z back
  // OpenCV: X right, Y down, Z forward
  const m = [
    1, 0, 0,
    0, -1, 0,
    0, 0, -1,
  ]
  return getMatrix3FromArray(m)
}

export function rotateCamerasPlsToOpenCv (transform) {
  return transform.multiply(new Transform(plsToOpenCv().transpose()))
}
