import { createReducer } from 'reduxsauce'

import { INITIAL_STATE } from './initialState'
import { SupportTypes } from './actions'

export const createTicketLoading = state =>
  state.merge({
    createTicketIsLoading: true,
    createTicketErrorMessage: null,
  })

export const createTicketSuccess = state =>
  state.merge({
    createTicketIsLoading: false,
  })

export const createTicketFailure = (state, { errorMessage }) =>
  state.merge({
    createTicketIsLoading: false,
    createTicketErrorMessage: errorMessage,
  })

// Open Support form dialog window
export const openSupportFormDialog = state =>
  state.merge({
    isSupportFormDialogOpened: true,
  })

// Close Support form dialog window
export const closeSupportFormDialog = state =>
  state.merge({
    isSupportFormDialogOpened: false,
  })

export const reducer = createReducer(INITIAL_STATE, {
  [SupportTypes.CREATE_TICKET_LOADING]: createTicketLoading,
  [SupportTypes.CREATE_TICKET_SUCCESS]: createTicketSuccess,
  [SupportTypes.CREATE_TICKET_FAILURE]: createTicketFailure,
  [SupportTypes.OPEN_SUPPORT_FORM_DIALOG]: openSupportFormDialog,
  [SupportTypes.CLOSE_SUPPORT_FORM_DIALOG]: closeSupportFormDialog,
})
