import { v4 } from 'uuid'
import { omit } from 'ramda'
import { getInitialStateValues } from 'components/TemplatedForm/utils'
import { ProjectCRSTemplateInfo } from './ProjectCRSTemplateInfo'

export const getProjectCRSInitialValues = (state, crs) => {
  const initialCRSState = getInitialStateValues({
    state,
    extra: { initialValues: crs },
    formTemplate: ProjectCRSTemplateInfo,
  })
  return addUniqueKeyToCRS(omit(['name', 'id'], initialCRSState))
}

export const addUniqueKeyToCRS = crs => {
  return {
    ...crs,
    uniqueKey: v4(),
  }
}
