import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18n from 'i18n'
import { convertRawWorker, convertRawWorkerRule } from 'types/workers'
import axios from 'utils/axios'
import { getErrorMessage } from 'utils/api'
// Local deps
import WorkersActions, { WorkersTypes } from './actions'

// Sagas
function * getWorkers ({ withLoading = true }) {
  if (withLoading) yield put(WorkersActions.getWorkersLoading())
  try {
    const url = '/workers'
    const { data: { data: workers } } = yield call(axios.get, url)
    yield put(WorkersActions.getWorkersSuccess(workers.map(convertRawWorker)))
  } catch (e) {
    yield put(WorkersActions.getWorkersFailure(getErrorMessage(e)))
  }
}

function * getWorkerRules ({ withLoading = true }) {
  if (withLoading) yield put(WorkersActions.getWorkerRulesLoading())
  try {
    const url = '/worker_rules'
    const { data: { data: rules } } = yield call(axios.get, url)
    yield put(WorkersActions.getWorkerRulesSuccess(rules.map(convertRawWorkerRule)))
  } catch (e) {
    yield put(WorkersActions.getWorkerRulesFailure(getErrorMessage(e)))
  }
}

function * addWorkerRule ({ data }) {
  yield put(WorkersActions.addWorkerRuleLoading())
  try {
    const url = '/worker_rules'
    yield call(axios.post, url, data)
    yield put(WorkersActions.getWorkerRules())
    toast.success(i18n.t('toast.workers.addWorkerRuleSuccess'))
    // yield put(WorkersActions.addWorkerRuleSuccess(rules.map(convertRawWorkerRule)))
  } catch (e) {
    yield put(WorkersActions.addWorkerRuleFailure(getErrorMessage(e)))
  }
}

function * deleteWorkerRule ({ id }) {
  yield put(WorkersActions.deleteWorkerRuleLoading())
  try {
    const url = `/worker_rules/${id}`
    yield call(axios.delete, url)
    yield put(WorkersActions.deleteWorkerRuleSuccess(id))
    toast.success(i18n.t('toast.workers.deleteWorkerRuleSuccess'))
  } catch (e) {
    yield put(WorkersActions.deleteWorkerRuleFailure(getErrorMessage(e)))
  }
}

// Watchers
function * getWorkersWatcher () {
  yield takeLatest(WorkersTypes.GET_WORKERS, getWorkers)
}

function * getWorkerRulesWatcher () {
  yield takeLatest(WorkersTypes.GET_WORKER_RULES, getWorkerRules)
}

function * addWorkerRuleWatcher () {
  yield takeLatest(WorkersTypes.ADD_WORKER_RULE, addWorkerRule)
}

function * deleteWorkerRuleWatcher () {
  yield takeLatest(WorkersTypes.DELETE_WORKER_RULE, deleteWorkerRule)
}

export default function * root () {
  yield fork(getWorkersWatcher)
  yield fork(getWorkerRulesWatcher)
  yield fork(addWorkerRuleWatcher)
  yield fork(deleteWorkerRuleWatcher)
}
