import { isUserCompanyCustomer, getCompanyId, isUserAdmin } from 'utils/company'

export const convertRawUser = raw => {
  const isCustomerUser = isUserCompanyCustomer(raw)
  const isAdmin = isUserAdmin(raw)
  return {
    ...raw,
    companyId: getCompanyId(raw),
    artifactPermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    projectPermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    pipelinePermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    missionPermissions: {
      delete: !isCustomerUser,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
    projectCRSPermissions: {
      delete: isAdmin,
      update: !isCustomerUser,
      add: !isCustomerUser,
      read: true,
    },
  }
}
