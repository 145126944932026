import React from 'react'
import { path, omit } from 'ramda'
import { Trans } from 'react-i18next'
// Project deps
import { CRSFields } from 'templates/CRS/constants'
import { CRSAuthNames } from 'types/coordinateReferenceSystem'
import Warning from 'components/Warning'

export const getCRSToBackend = crs => {
  return crs ? omit(['datum', CRSFields.IS_ELLIPSOIDAL, 'uniqueKey', 'lat', 'lng', 'id', 'height_type'], crs) : null
}

// Check if the `crs` field is likely the string that corresponds to compound crs
export const isCRSFieldCompound = value => {
  return value ? typeof value === 'string' ? CRSAuthNames.some(authName => value.startsWith(authName)) : true : false
}

/**
 * Returns a string representation of coordinate reference system, units and others.
 * Will be send to the backend and also used on frontend
 * @param {Object} data
 */
export const getBackendString = data => {
  return typeof data === 'string' ? data : data && data.auth_name && data.code ? `${data.auth_name}:${data.code}` : null
}

/**
 * Check if CRS is deprecated or not
 * @param {Object} crs
 */
export const isCRSDeprecated = crs => path(['deprecated'], crs) === 1

/**
 * Returns warning message if CRS is deprecated
 * @param {Object} state
 * @param {Object} crs
 */
export const getCRSDeprecatedWarning = (state, crs) => isCRSDeprecated(crs)
  ? <Warning variant='warning'><Trans i18nKey='CRS.warnings.deprecatedCrs'/></Warning>
  : ''

/**
 * Returns label that will be displayed in combobox to differentiate between various items
 * @param {Object} coordinateSystem
 */
export function getCoordinateSystemLabel (coordinateSystem) {
  return !coordinateSystem ? null : `${coordinateSystem.auth_name} ${coordinateSystem.code}: ${coordinateSystem.name}`
}
/**
 * The same as above but also 'type' field added
 * @param {Object} coordinateSystem
 */
export function getCoordinateSystemLabelWithType (coordinateSystem) {
  return !coordinateSystem ? null : `${coordinateSystem.auth_name} ${coordinateSystem.code}: ${coordinateSystem.name}${coordinateSystem.type ? `(${coordinateSystem.type})` : ''}`
}

/**
 * Checks if compound crs is geocentric or not
 * @param {AppState} state
 * @param {Object} values values for the form with crs fields (compound crs, vertical crs, ...etc)
 */
export const isCompoundGeocentricCRS = (state, values) => {
  const crsValue = values[CRSFields.C_CRS]
  return crsValue ? isGeocentricCRS(crsValue) : false
}

/**
 * Checks if crs is horizontal crs (can be projected or geographic 2d)
 * @param {Object} crs
 */
export function isHorizontalCoordinateSystem (crs) {
  // return !isVerticalCoordinateSystem(crs) && !isCompoundCoordinateSystem(crs)
  return isProjectedCRS(crs) || isGeographic2DCRS(crs)
}
/**
 * Checks if crs is vertical crs (can be vertical or geographic 3d)
 * @param {Object} crs
 */
export function isVerticalCoordinateSystem (crs) {
  return isVerticalCRS(crs) || isGeographic3DCRS(crs)
}
/**
 * Checks if crs is compound crs (can be compound or geographic 3d or geocentric)
 * @param {Object} crs
 */
export function isCompoundCoordinateSystem (crs) {
  return isCompoundCRS(crs) || isGeographic3DCRS(crs) || isGeocentricCRS(crs)
}
/**
 * Checks if crs is geographic 2d or not
 * @param {Object} crs
 */
export const isGeographic2DCRS = crs => {
  return crs ? crs.type === 'geographic 2d' : false
}
/**
 * Checks if crs is projected or not
 * @param {Object} crs
 */
export const isProjectedCRS = crs => {
  return crs ? crs.type === 'projected' : false
}
/**
 * Checks if crs is geographic 3d or not
 * @param {Object} crs
 */
export const isGeographic3DCRS = crs => {
  return crs ? crs.type === 'geographic 3d' : false
}
/**
 * Checks if crs is compound or not
 * @param {Object} crs
 */
export const isCompoundCRS = crs => {
  return crs ? crs.type === 'compound' : false
}
/**
 * Checks if crs is vertical or not
 * @param {Object} crs
 */
export const isVerticalCRS = crs => {
  return crs ? crs.type === 'vertical' : false
}
/**
 * Checks if crs is geocentric or not
 * @param {Object} crs
 */
export const isGeocentricCRS = crs => {
  return crs ? crs.type === 'geocentric' : false
}
