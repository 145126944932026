import React from 'react'
import { Trans } from 'react-i18next'
import { path, omit } from 'ramda'
// Project deps
import { getEPSGDatums } from 'modules/app/selectors'
import {
  // getSelectedTemplateArtifacts,
  // getPipelineTemplate,
  getSelectedTrajectoryArtifacts,
  getSelectedTrajectoryDatums,
  getCloneJobOptions,
} from 'modules/pipelineWizard/selectors'
// import { ArtifactTypes } from '../../types/artifacts'
import Warning from 'components/Warning'
import { getCloneJobOptionValue } from '../utils'
import { getEPSGDatumLabel, getDatumWithoutAreaBounds } from 'utils/datums'
import { DataType } from 'types/form'
// Local deps
import { getDatumInitialValue } from './utils'
import { MapBackendNameToFrontend, SPATIAL_FUSER_BACKEND_NAME } from 'templates/constants'
import { ProjectCRSTemplateInfo } from './ProjectCRS/ProjectCRSTemplateInfo'
import { getBackendTransformedValues } from 'utils/templates'
import { getProjectCRSInitialValues } from './ProjectCRS/utils'

export default {
  processing_datum: {
    name: MapBackendNameToFrontend.processing_datum,
    dataType: DataType.AUTOCOMPLETE,
    disabled: false,
    initialValue: (state, extra, options) => {
      const { actions = {}, extraProps = {} } = options
      const { projectCRS, clone } = extraProps
      const { getRecommendedCRS } = actions
      if (projectCRS && !clone) {
        const projectCRSDatum = getDatumInitialValue(state, projectCRS)
        if (projectCRSDatum) {
          return projectCRSDatum
        }
      }
      const value = getCloneJobOptionValue(state, extra, options, 'processing_datum')
      const epsgDatums = getEPSGDatums(state)
      const selectedTrajectoryDatums = getSelectedTrajectoryDatums(state)
      const [firstTrajectoryDatumKey] = Object.keys(selectedTrajectoryDatums)
      const firstTrajectoryDatumValue = value || selectedTrajectoryDatums[firstTrajectoryDatumKey]
      const datumCode = firstTrajectoryDatumValue && `${firstTrajectoryDatumValue.code}`
      const finalInitialValue = firstTrajectoryDatumValue
        ? epsgDatums.find(datum => datum.code === datumCode)
        : epsgDatums.find(datum => datum.code === '1156')
      if (finalInitialValue && !projectCRS) getRecommendedCRS(getDatumWithoutAreaBounds(finalInitialValue), false)
      return finalInitialValue
    },
    extraRender: (state, values = {}, extra, propOptions = {}) => {
      const selectedTrajectoryArtifacts = getSelectedTrajectoryArtifacts(state)
      const processingDatum = values.processing_datum
      const processingDatumCode = processingDatum && path(['name'], processingDatum)
      const selectedTrajectoryArtifactsWithDatum = selectedTrajectoryArtifacts
        .filter(trajectoryArtifact => path(['properties', 'trajectory_datum', 'name'], trajectoryArtifact))
      const someDatum = (processingDatumCode && selectedTrajectoryArtifactsWithDatum.length > 0)
        ? selectedTrajectoryArtifactsWithDatum.some(trajectoryArtifact => {
          const trajectoryDatumCode = path(['properties', 'trajectory_datum', 'name'], trajectoryArtifact)
          return `${trajectoryDatumCode}` === `${processingDatumCode}`
        }) : true

      return someDatum
        ? null
        : <Warning variant='warning'><Trans i18nKey='pipelineWizard.configureStep.differentProcessingDatum'/></Warning>
    },
    onChange: (name, value, options) => {
      const { actions = {} } = options
      const { getRecommendedCRS } = actions
      // Whenever user selects new datum we should get new recommended crs
      if (typeof getRecommendedCRS === 'function' && value) {
        getRecommendedCRS(getDatumWithoutAreaBounds(value))
      }
    },
    options: state => getEPSGDatums(state),
    displayTemplate: datum => getEPSGDatumLabel(datum),
    searchTemplate: datum => getEPSGDatumLabel(datum),
    getValue: (state, value, values, extra) => value ? getEPSGDatumLabel(value) : '',
    // backendTransform: getBackendString,
  },
  [SPATIAL_FUSER_BACKEND_NAME]: {
    name: 'CRS',
    dataType: DataType.CRS,
    initialValue: (state, extra, { extraProps }) => {
      const { projectCRS, clone } = extraProps
      if (clone) {
        const jobOptions = getCloneJobOptions(state, extra.templateJobId)
        if (jobOptions[SPATIAL_FUSER_BACKEND_NAME]) {
          const outputCRS = jobOptions[SPATIAL_FUSER_BACKEND_NAME]
          // We always add uniqueKey field for crs, so it should be more than one field
          if (Object.keys(outputCRS).length > 1) {
            const crs = getProjectCRSInitialValues(state, outputCRS)
            return crs
          }
        }
        return null
      }
      if (projectCRS) {
        const crs = getProjectCRSInitialValues(state, projectCRS)
        return crs
      }
      return null
    },
    backendTransform: (original, state, values) => {
      return omit(['name'], getBackendTransformedValues(state, original, original, ProjectCRSTemplateInfo))
    },
  },
}

/*

/*
  This template is used in output_crs Job
*/
/*
const getNavTrajectoryArtifacts = state => {
  const selectedArtifacts = getSelectedTemplateArtifacts(state)
  const template = getPipelineTemplate(state)
  const trajectoryTemplate = template.artifacts.find(art => art.artifactType === ArtifactTypes.TRAJECTORY)
  const templateId = trajectoryTemplate && trajectoryTemplate.templateId
  const trajectoryArtifactsIds = typeof templateId === 'number' ? selectedArtifacts[templateId] : []
  const trajectoryArtifacts = getArtifacts(state).filter(art => trajectoryArtifactsIds.indexOf(art.id) >= 0)
  const navArtifacts = trajectoryArtifacts.map((art, index) => {
    const navArtifact = getArtifacts(state).find(_art =>
      path(['mission', 'id'], _art) === path(['mission', 'id'], art) &&
      art.mission &&
      _art.artifactType === ArtifactTypes.NAVIGATION_ROVER
    )
    return navArtifact
  })
  return {
    navArtifacts,
    trajectoryArtifacts,
  }
}
*/

/*
const getCrsValue = (state, value, crss) => {
  if (!value.name || !('standard_axis_order' in value)) {
    return getCRSValue(state, value, crss)
  }
  return value
}

const getProcessingDatum = (state, extra, values) => {
  return values.processing_datum
}

const getVerticalCRSOptions = (state, extra, values) => {
  const currentProject = getCurrentProject(state)
  const recommendedCRS = getRecommendedCRS(state, currentProject.projectId) || {}
  const recommendedVerticalCRS = recommendedCRS.verticalCRS || []
  const coordinateSystems = getVerticalCoordinateSystems(state)
  const processingDatum = getProcessingDatum(state, extra, values)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (processingDatum && filterMode) {
    if (recommendedVerticalCRS.length > 0) return recommendedVerticalCRS
  }
  return coordinateSystems
}

const getHorizontalCRSOptions = (state, extra, values) => {
  const currentProject = getCurrentProject(state)
  const recommendedCRS = getRecommendedCRS(state, currentProject.projectId) || {}
  const recommendedHorizontalCRS = recommendedCRS.horizontalCRS || []
  const coordinateSystems = getHorizontalCoordinateSystems(state)
  const processingDatum = getProcessingDatum(state, extra, values)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (processingDatum && filterMode) {
    if (recommendedHorizontalCRS.length > 0) return recommendedHorizontalCRS
  }
  return coordinateSystems
}

const getGeoidOptions = (state, extra, values) => {
  const currentProject = getCurrentProject(state)
  const recommendedCRS = getRecommendedCRS(state, currentProject.projectId) || {}
  const recommendedGeoids = recommendedCRS.verticalGeoids || []
  const geoids = getGeoids(state)
  const processingDatum = getProcessingDatum(state, extra, values)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (processingDatum && filterMode) {
    if (recommendedGeoids.length > 0) return recommendedGeoids
  }
  return geoids
}

const getCompoundCRSOptions = (state, extra, values) => {
  const currentProject = getCurrentProject(state)
  const recommendedCRS = getRecommendedCRS(state, currentProject.projectId) || {}
  const recommendedCompoundCRS = recommendedCRS.compoundCRS || []
  const coordinateSystems = getCompoundCoordinateSystems(state)
  const processingDatum = getProcessingDatum(state, extra, values)
  const filterMode = values[CRSFields.FILTER_MODE]
  if (processingDatum && filterMode) {
    if (recommendedCompoundCRS.length > 0) return recommendedCompoundCRS
  }
  return coordinateSystems
}

const getCRSWarning = (crsField, state, values, extra, propOptions) => {
  const { name } = propOptions
  const processingDatum = getProcessingDatum(state, extra, values)
  const prevExtraRender = FieldTemplates[crsField].extraRender

  const crsValue = values[name]
  const crsDatum = (crsValue ? (crsValue.datums || []) : [])[0]

  return (crsDatum && processingDatum && crsDatum !== processingDatum.name)
    ? <Warning variant='warning'><Trans i18nKey='pipelineWizard.configureStep.differentCRSDatum'/></Warning>
    : prevExtraRender(state, values, extra, propOptions)
}

export const getHorizontalCRS = (state, extra, values) => {
  const coordinateSystems = getCoordinateSystems(state)
  const currentProject = getCurrentProject(state)
  const recommendedCRS = getRecommendedCRS(state, currentProject.projectId) || {}
  const initialHorizontalCRS = recommendedCRS.initialHorizontalCRS

  const horizontalCoordinateSystems = getHorizontalCRSOptions(state, extra, values)
  const processingDatum = getProcessingDatum(state, extra, values)
  return processingDatum
    ? initialHorizontalCRS
      ? coordinateSystems.find(crs => `${crs.code}` === `${initialHorizontalCRS.code}`)
      : horizontalCoordinateSystems[0] || null
    : null
}

export const getVerticalCRS = (state, extra, values) => {
  const coordinateSystems = getCoordinateSystems(state)
  const currentProject = getCurrentProject(state)
  const recommendedCRS = getRecommendedCRS(state, currentProject.projectId) || {}
  const initialVerticalCRS = recommendedCRS.initialVerticalCRS

  const verticalCoordinateSystems = getVerticalCRSOptions(state, extra, values)
  const processingDatum = getProcessingDatum(state, extra, values)
  return processingDatum
    ? initialVerticalCRS
      ? coordinateSystems.find(crs => `${crs.code}` === `${initialVerticalCRS.code}`)
      : verticalCoordinateSystems[0] || null
    : null
}

const SpatialFuserFieldsTemplatesBasic = Object.keys(FieldTemplates).reduce((allFields, key) => {
  return {
    ...allFields,
    [key]: {
      ...FieldTemplates[key],
      backendName: SPATIAL_FUSER_BACKEND_NAME,
    },
  }
}, {})

const SpatialFuserFieldsTemplates = {
  ...SpatialFuserFieldsTemplatesBasic,
  [CRSFields.NORMALIZE_AXIS_ORDER]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.NORMALIZE_AXIS_ORDER],
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, CRSFields.NORMALIZE_AXIS_ORDER, false),
  },
  /*
  [CRSFields.IS_ELLIPSOIDAL]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.IS_ELLIPSOIDAL],
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, CRSFields.IS_ELLIPSOIDAL, false),
  },
  [CRSFields.GEOID_CRS]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.GEOID_CRS],
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, CRSFields.GEOID_CRS, false),
  },
  [CRSFields.GRID]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.GRID],
    initialValue: (state, extra, options) => {
      const value = getCloneJobOptionValue(state, extra, options, CRSFields.GRID, null)
      return value
    },
    options: (state, values, extra) => getGeoidOptions(state, extra, values),
    extraRender: (state, values, extra, propOptions) => {
      const { name } = propOptions
      const geoid = values[name]
      const currentProject = getCurrentProject(state)
      const position = path(['project', 'gpsPosition'], currentProject)
      if (position && geoid) {
        const isProjectPositionValid = isCoordinateInBounds(position, (
          geoid.boundary
            ? {
              w_boundary: geoid.boundary.west,
              e_boundary: geoid.boundary.east,
              s_boundary: geoid.boundary.south,
              n_boundary: geoid.boundary.north,
            }
            : geoid.bound
        ))
        if (!isProjectPositionValid) {
          return <Warning variant='warning'>
            The position of the project is not within the selected geoid
          </Warning>
        }
      }
      return null
    },
  },
  [CRSFields.EPOCH]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.EPOCH],
    disabled: (state, values, extra, formTemplate, options) => {
      return Boolean(formTemplate[CRSFields.EPOCH].initialValue(state, extra, options))
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, CRSFields.EPOCH),
  },
  [CRSFields.C_CRS]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.C_CRS],
    options: (state, values, extra) => getCompoundCRSOptions(state, extra, values),
    initialValue: (state, extra, options) => {
      const value = getCloneJobOptionValue(state, extra, options, CRSFields.C_CRS, null)
      if (value) return getCrsValue(state, value, getCompoundCoordinateSystems(state))
      return null
    },
    extraRender: (state, values, extra, propOptions) => getCRSWarning(CRSFields.C_CRS, state, values, extra, propOptions),
  },
  [CRSFields.H_CRS]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.H_CRS],
    initialValue: (state, extra, options) => {
      const value = getCloneJobOptionValue(state, extra, options, CRSFields.H_CRS, null)
      if (value) return getCrsValue(state, value, getHorizontalCoordinateSystems(state))
      return null
    },
    options: (state, values, extra) => getHorizontalCRSOptions(state, extra, values),
    extraRender: (state, values, extra, propOptions) => {
      const currentProject = getCurrentProject(state)
      const recommendedCRS = getRecommendedCRS(state, currentProject.projectId) || {}
      const hCrsValue = values[CRSFields.H_CRS]
      const initialHCRSValue = recommendedCRS.initialHorizontalCRS
      return initialHCRSValue
        ? hCrsValue && (`${hCrsValue.code}` !== `${initialHCRSValue.code}`)
          ? getCRSWarning(CRSFields.H_CRS, state, values, extra, propOptions)
          : null
        : getCRSWarning(CRSFields.H_CRS, state, values, extra, propOptions)
    },
  },
  [CRSFields.V_CRS]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.V_CRS],
    options: (state, values, extra) => getVerticalCRSOptions(state, extra, values),
    initialValue: (state, extra, options) => {
      const value = getCloneJobOptionValue(state, extra, options, CRSFields.V_CRS, null)
      if (value) return getCrsValue(state, value, getVerticalCoordinateSystems(state))
      return null
    },
  },
  [CRSFields.H_UNITS]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.H_UNITS],
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, CRSFields.H_UNITS, null),
  },
  [CRSFields.V_UNITS]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.V_UNITS],
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, CRSFields.V_UNITS, null),
  },
  [CRSFields.CUSTOM_CRS]: {
    ...SpatialFuserFieldsTemplatesBasic[CRSFields.CUSTOM_CRS],
    disableGetPositionButton: true,
    initialValue: (state, extra, options) => {
      const crsStringOld = getCloneJobOptionValue(state, extra, options, CRSFields.CUSTOM_CRS)
      return crsStringOld || ''
    },
  },
}

// Template for compound and geographic 3D tab
export const compoundTemplate = {
  ...getCompoundTabTemplateFields(SpatialFuserFieldsTemplates),
  [CRSFields.GRID]: {
    ...SpatialFuserFieldsTemplates[CRSFields.GRID],
    // disabled: disableCompoundCRSGrid,
    backendTransform: getCompoundCRSGrid,
  },
  [CRSFields.C_CRS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.C_CRS],
  },
  [CRSFields.H_UNITS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.H_UNITS],
    disabled: (state, values, extra, formTemplate) => coordinateSystemFieldDisabled(state, values) ||
      disableHUnits(CRSFields.C_CRS, state, values, formTemplate),
    altOption: (state, values, formTemplate, option) => {
      const alternativeOption = {
        ...SpatialFuserFieldsTemplates[CRSFields.H_UNITS],
        name: 'Unit',
        gridProps: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 6,
        },
      }
      return isCompoundGeocentricCRS(state, values) ? alternativeOption : option
    },
  },
  [CRSFields.V_UNITS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.V_UNITS],
    invisibleForTab: (state, values) => isCompoundGeocentricCRS(state, values),
    backendTransform: (original, state, values) => unitBackendTransform(getCompoundCRSVUnit(state, values)),
  },
  /*
  [CRSFields.NORMALIZE_AXIS_ORDER]: {
    ...SpatialFuserFieldsTemplates[CRSFields.NORMALIZE_AXIS_ORDER],
    invisibleForTab: (state, values) => {
      const compoundCrs = values[CRSFields.C_CRS]
      return compoundCrs ? compoundCrs.standard_axis_order : true
    },
  },
  */
 
/*
// Template for user-defined compound CRS tab
export const crsTemplate = {
  ...getCRSTabTemplateFields(SpatialFuserFieldsTemplates),
  [CRSFields.GRID]: {
    ...SpatialFuserFieldsTemplates[CRSFields.GRID],
  },
  [CRSFields.H_CRS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.H_CRS],
  },
  [CRSFields.H_UNITS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.H_UNITS],
    disabled: (state, values, extra, formTemplate) => coordinateSystemFieldDisabled(state, values) ||
      disableHUnits(CRSFields.H_CRS, state, values, formTemplate),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  [CRSFields.V_CRS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.V_CRS],
  },
  [CRSFields.V_UNITS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.V_UNITS],
    gridProps: {
      xs: 12,
      sm: 12,
      md: 3,
      lg: 3,
    },
  },
  /*
  [CRSFields.NORMALIZE_AXIS_ORDER]: {
    ...SpatialFuserFieldsTemplates[CRSFields.NORMALIZE_AXIS_ORDER],
    invisibleForTab: (state, values) => {
      const horizontalCrs = values[CRSFields.H_CRS]
      return horizontalCrs ? horizontalCrs.standard_axis_order : true
    },
  },
  */
 /*
// Template for proj/wkt/json string tab
export const customCRSTemplate = {
  ...getCustomTabTemplateFields(SpatialFuserFieldsTemplates),
  [CRSFields.CUSTOM_CRS]: {
    ...SpatialFuserFieldsTemplates[CRSFields.CUSTOM_CRS],
  },
}

const tabsTemplate = getTabsTemplate({
  compoundTemplate,
  crsTemplate,
  customCRSTemplate,
})

const Template = getCRSTemplate(tabsTemplate, {
  ...compoundTemplate,
  ...crsTemplate,
  ...customCRSTemplate,
})

export default {
  processing_datum: {
    name: 'Processing datum',
    dataType: DataType.AUTOCOMPLETE,
    disabled: false,
    initialValue: (state, extra, options) => {
      const { actions = {} } = options
      const value = getCloneJobOptionValue(state, extra, options, 'processing_datum')
      const epsgDatums = getEPSGDatums(state)
      const { getRecommendedCRS } = actions
      const selectedTrajectoryDatums = getSelectedTrajectoryDatums(state)
      const [firstTrajectoryDatumKey] = Object.keys(selectedTrajectoryDatums)
      const firstTrajectoryDatumValue = value || selectedTrajectoryDatums[firstTrajectoryDatumKey]
      const datumCode = firstTrajectoryDatumValue && `${firstTrajectoryDatumValue.code}`
      const finalInitialValue = firstTrajectoryDatumValue
        ? epsgDatums.find(datum => datum.code === datumCode)
        : epsgDatums.find(datum => datum.code === '1156')
      if (finalInitialValue) getRecommendedCRS(finalInitialValue, false)
      return finalInitialValue
    },
    extraRender: (state, values = {}, extra, propOptions = {}) => {
      const selectedTrajectoryArtifacts = getSelectedTrajectoryArtifacts(state)
      const processingDatum = values.processing_datum
      const processingDatumCode = processingDatum && path(['name'], processingDatum)
      const selectedTrajectoryArtifactsWithDatum = selectedTrajectoryArtifacts
        .filter(trajectoryArtifact => path(['properties', 'trajectory_datum', 'name'], trajectoryArtifact))
      const someDatum = (processingDatumCode && selectedTrajectoryArtifactsWithDatum.length > 0)
        ? selectedTrajectoryArtifactsWithDatum.some(trajectoryArtifact => {
          const trajectoryDatumCode = path(['properties', 'trajectory_datum', 'name'], trajectoryArtifact)
          return `${trajectoryDatumCode}` === `${processingDatumCode}`
        }) : true

      return someDatum
        ? null
        : <Warning variant='warning'><Trans i18nKey='pipelineWizard.configureStep.differentProcessingDatum'/></Warning>
    },
    onChange: (name, value, options) => {
      const { actions = {} } = options
      const { getRecommendedCRS } = actions
      // Whenever user selects new datum we should get new recommended crs
      if (typeof getRecommendedCRS === 'function' && value) {
        getRecommendedCRS(value)
      }
    },
    options: state => getEPSGDatums(state),
    displayTemplate: datum => getEPSGDatumLabel(datum),
    searchTemplate: datum => getEPSGDatumLabel(datum),
    getValue: (state, value, values, extra) => value ? getEPSGDatumLabel(value) : '',
    // backendTransform: getBackendString,
  },
  [SPATIAL_FUSER_BACKEND_NAME]: {
    name: 'CRS',
    dataType: DataType.CRS,
    initialValue: (state, extra, options) => {
      const jobOptions = getCloneJobOptions(state, extra.templateJobId)
      if (jobOptions[SPATIAL_FUSER_BACKEND_NAME]) {
        const crs = getProjectCRSInitialValues(state, jobOptions[SPATIAL_FUSER_BACKEND_NAME])
        return crs
      }
      return null
    },
    backendTransform: (original, state, values) => {
      return omit(['name'], getBackendTransformedValues(state, original, original, ProjectCRSTemplateInfo))
    },
  },

  [CRSFields.FILTER_MODE]: {
    name: 'Show only recommended coordinate reference systems',
    dataType: DataType.BOOLEAN,
    initialValue: true,
    sendToBackend: false,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  ...Template,
  [CRSFields.TAB]: {
    ...Template[CRSFields.TAB],
    initialValue: (state, extra, options) => {
      const crsStringOld = getCloneJobOptionValue(state, extra, options, CRSFieldsOld.CUSTOM_CRS)
      if (typeof crsStringOld !== 'undefined') return 2
      return getInitialTab(state, extra, options, true)
    },
  },
  [CRSFields.LAST_CHANGED_TAB]: {
    ...Template[CRSFields.LAST_CHANGED_TAB],
    initialValue: (state, extra, options) => {
      const crsStringOld = getCloneJobOptionValue(state, extra, options, CRSFieldsOld.CUSTOM_CRS)
      if (typeof crsStringOld !== 'undefined') return 2
      return getInitialLastChangedTab(state, extra, options, true)
    },
  },
}
*/
