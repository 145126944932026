import { convertRawJobIO } from 'types/jobIos'
import axios from 'utils/axios'
export async function createJobIO (headers, jobId, artifactId, ioType, options) {
  try {
    const url = `/jobs/${jobId}/job_ios`
    const body = {
      job_io_type: ioType,
      artifact: {
        id: artifactId,
      },
      options,
    }
    const result = await axios.post(url, body, { headers })
    const jobIO = convertRawJobIO(result.data.data)
    return jobIO
  } catch (e) {
    throw e
  }
}
