import { isProductionEnvironment, isBayerEnvironment } from 'config'
import i18n from 'i18n'
import { PermissionType } from 'types/permissions'

export const prettifyPermissionType = type => {
  switch (type) {
    case PermissionType.READ:
      return i18n.t('permissions.read')
    default:
      return i18n.t('permissions.unknown')
  }
}

export const isStorageCapicityExceeded = (companyDataUsage, files) => {
  /*
  if (isProductionEnvironment()) {
    return false
  }
  */
  if (!companyDataUsage) {
    return true
  }
  const { max_data_size, stored_data_size } = companyDataUsage
  if (max_data_size === -1) {
    return false
  }
  if (files && files.length > 0) {
    const overallFilesSize = files.reduce((sum, file) => sum + file.fileSize, 0)
    return stored_data_size + overallFilesSize > max_data_size
  }
  return stored_data_size > max_data_size || max_data_size - stored_data_size < 1024 * 1024
}

export const getStorageExceededSize = (companyDataUsage, files) => {
  if (!companyDataUsage) {
    return undefined
  }
  const { max_data_size, stored_data_size } = companyDataUsage
  if (max_data_size === -1) {
    return undefined
  }
  if (files && files.length > 0) {
    const overallFilesSize = files.reduce((sum, file) => sum + file.fileSize, 0)
    return (stored_data_size + overallFilesSize) - max_data_size
  }
  return stored_data_size - max_data_size
}

/**
 * Determines the base environment based on the current environment settings.
 *
 * @constant {string}
 */
export const baseEnvironment = isProductionEnvironment()
  ? 'lm_production'
  : isBayerEnvironment()
    ? 'lm_bayer'
    : 'lm_develop'