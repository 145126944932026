import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  jobs: [],
  pagination: {
    has_next: false,
    has_prev: false,
    page: 1,
    page_size: 50,
    total: 0,
    total_pages: 0,
  },
  projects: [],
  dataFileUploadLogs: [],
  jobRunLogs: [],

  getRefundsIsLoading: false,
  refunds: [],

  allCustomers: [],
  activeCustomers: [],
  canceledCustomers: [],
  customersStatisticIsLoading: false,
  subscriptionsStatisticIsLoading: false,

  getProjectsIsLoading: false,
  getProjectsErrorMessage: null,
  getJobsIsLoading: false,
  getJobsErrorMessage: null,
  getJobsQueriedIsLoading: false,
  getJobsQueriedErrorMessage: null,
  getJobRunLogsIsLoading: false,
  getJobRunLogsErrorMessage: null,
  getDataFileUploadLogsIsLoading: false,
  getDataFileUploadLogsErrorMessage: null,
  getLogsIsLoading: false,
  getLogsErrorMessage: null,
  workerUsage: [],
  getWorkerUsageIsLoading: false,

  downloadCSVSubscriptionsIsLoading: false,
})
