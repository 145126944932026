import { getDateWithTimezone } from 'utils/dateTime'
import { isCancelledSubscription, isStripeSubscription } from 'utils/subscription'

export const convertRawSubscription = raw => {
  const {
    next_payment_date,
    end_date,
  } = raw
  const isCancelled = isCancelledSubscription(raw)
  const isStripe = isStripeSubscription(raw)
  return {
    ...raw,
    // set end_date for cancelled stripe subcsription to the next payment date to simplify things
    // https://gitlab.com/phoenixlidar/lidarmill-frontend/-/issues/1121
    end_date: isStripe && isCancelled
      ? getDateWithTimezone(next_payment_date)
      : end_date
        ? getDateWithTimezone(end_date)
        // in this case our subscription never expires (non-recurring subscription without end date or stripe subscription)
        // set it to the date in future to simpify things
        : getDateWithTimezone().add(100, 'years'),
  }
}
