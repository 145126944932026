import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getCompanies: null,
  getCompaniesLoading: null,
  getCompaniesSuccess: ['companies'],
  getCompaniesFailure: ['errorMessage'],

  getPositions: ['companyId'],
  getPositionsLoading: ['companyId'],
  getPositionsSuccess: ['companyId', 'positions'],
  getPositionsFailure: ['companyId', 'errorMessage'],

  addReferenceStationPosition: ['companyId', 'name', 'data'],
  addReferenceStationPositionLoading: null,
  addReferenceStationPositionSuccess: ['companyId', 'position'],
  addReferenceStationPositionFailure: ['errorMessage'],

  getCompanyUsers: ['companyId'],
  getCompanyUsersLoading: null,
  getCompanyUsersSuccess: ['users'],
  getCompanyUsersFailure: ['errorMessage'],

  getCompany: ['companyId'],
  getCompanyLoading: null,
  getCompanySuccess: ['company'],
  getCompanyFailure: ['errorMessage'],

  generateInviteUrl: ['companyId', 'email', 'companyRole'],
  generateInviteUrlLoading: ['companyId'],
  generateInviteUrlSuccess: ['companyId'],
  generateInviteUrlFailure: ['companyId', 'errorMessage'],

  checkInviteToken: ['token'],
  checkInviteTokenLoading: ['token'],
  checkInviteTokenSuccess: ['token'],
  checkInviteTokenFailure: ['token', 'errorMessage'],

  registerEmployee: ['companyId', 'token', 'data'],
  registerEmployeeLoading: null,
  registerEmployeeSuccess: null,
  registerEmployeeFailure: ['errorMessage'],

  getCompanyProjects: ['companyId', 'setAsCurrent'],
  getCompanyProjectsLoading: ['companyId'],
  getCompanyProjectsSuccess: ['companyId', 'projects', 'setAsCurrent'],
  getCompanyProjectsFailure: ['companyId', 'errorMessage'],

  getCompanySystemTypes: ['companyId'],
  getCompanySystemTypesLoading: ['companyId'],
  getCompanySystemTypesSuccess: ['companyId', 'systemTypes'],
  getCompanySystemTypesFailure: ['companyId'],

  getCompanySubscriptions: ['companyId'],
  getCompanySubscriptionsLoading: ['companyId'],
  getCompanySubscriptionsSuccess: ['companyId', 'subscriptions'],
  getCompanySubscriptionsFailure: ['companyId'],

  getCompanyPermissions: ['companyId'],
  getCompanyPermissionsLoading: ['companyId'],
  getCompanyPermissionsSuccess: ['companyId', 'permissions'],
  getCompanyPermissionsFailure: ['companyId'],

  addProjectsPermission: ['userId', 'projectIds'],
  addProjectsPermissionLoading: null,
  addProjectsPermissionSuccess: ['userId', 'permissions'],
  addProjectsPermissionFailure: null,

  addCompanyPermission: ['userId', 'companyId'],
  addCompanyPermissionLoading: ['userId', 'companyId'],
  addCompanyPermissionSuccess: ['userId', 'permission'],
  addCompanyPermissionFailure: ['userId', 'companyId'],

  deleteCompanyPermission: ['userId', 'permissionId'],
  deleteProjectPermission: ['userId', 'permissionId'],
  deletePermissionLoading: ['userId', 'permissionId'],
  deletePermissionSuccess: ['userId', 'permissionId'],
  deletePermissionFailure: ['userId', 'permissionId'],

  getCompanyDataUsage: ['companyId'],
  getCompanyDataUsageLoading: ['companyId'],
  getCompanyDataUsageSuccess: ['companyId', 'dataUsage'],
  getCompanyDataUsageFailure: ['companyId'],
})

export const CompaniesTypes = Types
export default Creators
