import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getJobsForUser: ['userId', 'filter'],
  getJobsForUserLoading: null,
  getJobsForUserSuccess: ['jobs'],
  getJobsForUserFailure: ['errorMessage'],
})

export const JobsTypes = Types
export default Creators
