import { toast } from 'react-toastify'
import { getAuthentificationHeader } from 'utils/api'
import axios from 'utils/axios'

export const apiSearch = async (search, userId, token, page, count) => {
  const url = `/search/autocomplete?keyword=${search}&user_id=${userId}&per_page=${count}&page=${page}`
  try {
    const response = await axios.get(
      url,
      {
        headers: getAuthentificationHeader(token),
      },
    )
    return response.data.data
  } catch (e) {
    if (e.message === 'Network Error') {
      toast.error('Network error!')
    } else {
      const status = e.response.status
      if (status === 401) {
        toast.error('You\'re not authorized')
      }
    }
    return { results: [], total: 0 }
  }
}
