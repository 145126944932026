import { convertRawJobRun } from 'types/jobRuns'
import axios from 'utils/axios'

export async function createJobRun (headers, jobId, options) {
  try {
    const url = `/jobs/${jobId}/job_runs`
    const body = { options }
    const result = await axios.post(url, body, { headers })
    const jobRun = convertRawJobRun(result.data.data)
    return jobRun
  } catch (e) {
    throw e
  }
}
