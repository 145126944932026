import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
// Project deps
import VirtualizedList from 'components/VirtualizedList'

function createMarkup (snippet) {
  return { __html: snippet }
}

const styles = theme => ({
  listItem: {
    height: 'auto',
    alignItems: 'flex-start',
    padding: theme.spacing(0.5, 1),
  },
})

const SearchBlock = ({ data, type, title, getUrl, classes, location, history, onClose }) => {
  // Handlers
  const onItemClick = url => e => {
    e.stopPropagation()
    e.preventDefault()
    if (location.pathname === url) {
      document.location.reload()
    } else {
      history.push(url)
      onClose(e)
    }
  }
  return (
    data.length > 0
      ? <VirtualizedList
        list={data}
        renderItem={({ description = {}, name = {}, project_id, id, component_info = {} }, { style, index, key }) => {
          const url = getUrl(project_id, id)
          return (
            <MenuItem
              classes={{ root: classes.listItem }}
              key={key}
              style={style}
              onClick={onItemClick(url)}
              component='a'
              href={url}
            >
              <ListItemText
                primary={
                  <Typography
                    noWrap
                    variant='body1'
                    dangerouslySetInnerHTML={createMarkup(name.snippet || '')}
                  />
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      noWrap
                      variant='body2'
                      dangerouslySetInnerHTML={createMarkup(description.snippet || '')}
                    />
                    <Typography
                      noWrap
                      variant='body2'
                      dangerouslySetInnerHTML={createMarkup(component_info.snippet || '')}
                    />
                  </React.Fragment>
                }
              />
            </MenuItem>
          )
        }}
      />
      : <Typography style={{ padding: 8 }}>No data</Typography>
  )
}

SearchBlock.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  getUrl: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default withStyles(styles)(SearchBlock)
