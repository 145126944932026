import React from 'react'
import { Trans } from 'react-i18next'
// Local deps
import {
  getCloneArtifactOptionValue,
  safeToString,
  getImuOrientation,
} from '../utils'
import { DataType } from 'types/form'
import {
  getLidarTransform,
  getSensorIndex,
  clampBetween360Degrees,
} from '../jobIoOptionsUtils'
import { MapBackendNameToFrontend } from 'templates/constants'
import Tooltip from 'components/StyledTooltip'
import { findSensorModel, isSE5AndLowerPLP, transformLidarCalibration } from 'utils/calibrationconverter'
import { getAllArtifacts, getMissions } from 'modules/projects/selectors'
import { findById } from 'utils/list'
import { Wiki } from 'wiki'

export default {
  overrideTransform: {
    name: MapBackendNameToFrontend.overrideTransform,
    dataType: DataType.BOOLEAN,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(state, artifactId, options, 'overrideTransform', false),
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  index: {
    name: MapBackendNameToFrontend.index,
    dataType: DataType.INTEGER,
    invisible: true,
    disabled: (_state, values) => !values.overrideTransform,
    optional: (_state, values) => !values.overrideTransform,
    initialValue: (state, artifactId, options) =>
      getCloneArtifactOptionValue(state, artifactId, options, 'index', () => safeToString(getSensorIndex(state, artifactId, options))),
  },
  transform: {
    dataType: DataType.AUTOCOMPLETE,
    invisible: true,
    optional: true,
    sendToBackend: false,
    initialValue: (state, artifactId, options) => {
      const { values } = options
      const orientation = getImuOrientation(state, artifactId, options)
      const lidarTransform = getLidarTransform(state, artifactId, values.index)
      const lidarArtifact = findById(artifactId, getAllArtifacts(state))
      const mission = findById(lidarArtifact.missionId, getMissions(state))
      const shouldPerformTransformation = mission && mission.plp && isSE5AndLowerPLP(mission.plp)
      const sensorModel = findSensorModel(mission, lidarArtifact)
      // This happens when we update IMU orientation and if we have se6 plp
      if (!shouldPerformTransformation) {
        if (values.transform) {
          return {
            offset: values.offset,
            rotation: values.rotation,
          }
        }
        return lidarTransform
      } else {
        if (values.transform && values.overrideTransform) {
          return {
            offset: values.offset,
            rotation: values.rotation,
          }
        }
        return transformLidarCalibration(sensorModel, lidarTransform, orientation)
      }
    },
  },
  offset: {
    name: <Tooltip placement='top-start' title={<Trans i18nKey='templates.artifactOptions.generic.tooltip.offset'/>}>
      <div>
        <Trans i18nKey='templates.artifactOptions.generic.offsetLink' components={[
          <a key='link' target='_blank' rel='noopener noreferrer' href={Wiki.rotationOffsets}>link</a>,
        ]}/>
      </div>
    </Tooltip>,
    dataType: DataType.VECTOR3,
    componentNames: ['X', 'Y', 'Z'],
    precision: [3, 3, 3],
    disabled: (_state, values) => !values.overrideTransform,
    optional: (_state, values) => !values.overrideTransform,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'offset',
      () => {
        const { values } = options
        return values.transform.offset.map(safeToString)
      }
    ),
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
  rotation: {
    name: <Tooltip placement='top-start' title={<Trans i18nKey='templates.artifactOptions.generic.tooltip.rotation'/>}>
      <div>
        <Trans i18nKey='templates.artifactOptions.generic.rotationLink' components={[
          <a key='link' target='_blank' rel='noopener noreferrer' href={Wiki.rotationOffsets}>link</a>,
        ]}/>
      </div>
    </Tooltip>,
    dataType: DataType.VECTOR3,
    componentNames: ['X', 'Y', 'Z'],
    rounding: true,
    precision: [3, 3, 3],
    format: value => safeToString(clampBetween360Degrees(value)),
    disabled: (_state, values) => !values.overrideTransform,
    optional: (_state, values) => !values.overrideTransform,
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(
      state,
      artifactId,
      options,
      'rotation',
      () => {
        const { values } = options
        return values.transform.rotation.map(safeToString)
      }
    ),
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  },
}
