import i18n from 'i18n'
import { baseEnvironment } from './permissions'

export const getProjectName = name => {
  return name || i18n.t('pages.project.noName')
}

export const getProjectDescription = description => {
  return description || i18n.t('pages.project.noDescription')
}

export const getProjectDownloadLink = downloadRequestId => {
  return `https://download.phoenixlidar.com/downloading_requests/${downloadRequestId}?compress_level=0&base=${baseEnvironment}`
}
