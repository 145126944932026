import React from 'react'
import { useSelector } from 'react-redux'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogContent, LinearProgress, Typography } from '@material-ui/core'
// Project deps
import { getUpdateCRSDialogOpen, getUpdateCRSStatus } from 'modules/users/selectors'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: theme.breakpoints.values.md,
    width: '100%',
  },
  status: {
    width: '100%',
  },
  statusTitle: {
    fontSize: 20,
  },
}))

const UpdateCRSDialog = props => {
  const isUpdateCRSDialogOpen = useSelector(state => getUpdateCRSDialogOpen(state))
  const updateCRSStatus = useSelector(state => getUpdateCRSStatus(state))
  const classes = useStyles()
  return (
    <Dialog open={isUpdateCRSDialogOpen} fullScreen>
      <DialogContent className={classes.dialogContent}>
        {updateCRSStatus && (
          <div className={classes.status}>
            <Typography align='center' className={classes.statusTitle}>{updateCRSStatus.title}</Typography>
            <LinearProgress variant='determinate' value={updateCRSStatus.current * 100 / updateCRSStatus.total}/>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default UpdateCRSDialog
