import moment from 'moment'
// Project deps
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import { getCurrentProject } from 'modules/projects/selectors'
import { DataSetTypeMapping, DataSetTypeOptions, DataSetTypeInitialValue } from './utils'
import { MapBackendNameToFrontend } from 'templates/constants'

export default {
  pipelineName: {
    name: 'Pipeline name',
    dataType: DataType.STRING,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'pipelineName',
      () => {
        const currentProject = getCurrentProject(state)
        return `${moment.utc().format('DD/MM/YYYY')} ${currentProject.project.name}`
      },
    ),
    sendToBackend: false,
  },
  dataset_type: {
    name: MapBackendNameToFrontend.dataset_type,
    dataType: DataType.SELECTION,
    options: DataSetTypeOptions,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'dataset_type',
      DataSetTypeInitialValue,
    ),
    mapping: DataSetTypeMapping,
  },
}
