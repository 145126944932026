// Project deps
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
// import { findById } from '../utils/list'
import { MapBackendNameToFrontend } from '../constants'

export default {
  use_las: {
    name: MapBackendNameToFrontend.use_las,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'use_las', true),
  },
  use_reference_file: {
    name: MapBackendNameToFrontend.use_reference_file,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'use_reference_file', true),
  },
}
