import { useEffect, useRef } from 'react'

export function useTimer (callback, delay = 2000, callOnInitiate = true) {
  const savedCallback = useRef()
  const iteration = useRef(0)
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    if (iteration.current < 1 && callOnInitiate) {
      savedCallback.current()
      iteration.current += 1
    }
    function tick () {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
/*
export function useTimer (callback, delay = 2000, callOnInitiate = true) {
  useEffect(() => {
    const timer = getTimer(callback, delay)
    if (callOnInitiate) callback()
    timer.startTimeout()
    return timer.clearTimeout
  }, [])
}
*/
