import i18n from 'i18n'
import { path } from 'ramda'
import { CompanyRole } from 'types/company'

export const getCompanyId = user => path(['company', 'id'], user)
export const isUserActive = user => Boolean(path(['is_active'], user))
export const isUserPLS = user => isUserActive(user) && Boolean(path(['is_pls'], user))
export const isUserAdmin = user => isUserActive(user) && Boolean(path(['is_superuser'], user))
export const isUserAdminOrPLSUser = user => isUserAdmin(user) || isUserPLS(user)
export const isUserCompanyAdmin = user => Boolean(path(['is_company_admin'], user))
export const isUserCompanyCustomer = user => path(['company_role'], user) === CompanyRole.CUSTOMER
export const isUserCompanyEmployee = user => path(['company_role'], user) === CompanyRole.EMPLOYEE

export const getUserPermissions = (loggedUser = {}, activeUser = {}) => {
  const sameCompany = getCompanyId(loggedUser) === getCompanyId(activeUser)
  const userOwnProfile = loggedUser && activeUser ? loggedUser.id === activeUser.id : false
  const isAdmin = isUserAdmin(loggedUser)
  const isCompanyAdmin = isUserCompanyAdmin(loggedUser) && sameCompany
  const isCompanyEmployee = isUserCompanyEmployee(loggedUser) && sameCompany
  const isAdminOrEmployee = isAdmin || isCompanyAdmin || (isCompanyEmployee && isUserCompanyCustomer(activeUser))
  return {
    // read: isAdmin || isUserCompanyAdmin || isUserCompanyEmployee,
    update: userOwnProfile || isAdminOrEmployee,
    delete: userOwnProfile || isAdminOrEmployee,
    password: userOwnProfile || isAdminOrEmployee,
    activate: isAdmin ||
      ((isCompanyAdmin || (isCompanyEmployee && isUserCompanyCustomer(activeUser))) && !userOwnProfile),
  }
}

export const getCompanyPermissions = (loggedUser = {}, companyId) => {
  const sameCompany = getCompanyId(loggedUser) === companyId
  const isAdmin = isUserAdmin(loggedUser)
  const isCompanyAdmin = isUserCompanyAdmin(loggedUser) && sameCompany
  const isCompanyEmployee = isUserCompanyEmployee(loggedUser) && sameCompany
  const isCompanyCustomer = isUserCompanyCustomer(loggedUser) && sameCompany
  const all = isAdmin || isCompanyAdmin || isUserCompanyEmployee || isUserCompanyCustomer
  const isAdminOrCompanyAdmin = isAdmin || isCompanyAdmin
  return {
    // read: all,
    update: isAdminOrCompanyAdmin,
    delete: isAdmin,
    buySubscription: isAdminOrCompanyAdmin,
    useSubscription: all,
    generateInvite: !isCompanyCustomer,
    inviteEmployee: isAdminOrCompanyAdmin,
    inviteCustomer: isAdminOrCompanyAdmin || isCompanyEmployee,
  }
}

export const getActiveUserProjects = (loggedUser, activeUser, projects, permissions, showAllProjects) => {
  if (!loggedUser && !activeUser) return []
  if (!loggedUser && activeUser) return []
  if (loggedUser && !activeUser) {
    return isUserCompanyCustomer(loggedUser)
      ? projects
      : showAllProjects ? projects : projects.filter(project => project.userId === loggedUser.id)
  }
  if (loggedUser.id === activeUser.id) {
    return isUserCompanyCustomer(loggedUser)
      ? projects
      : showAllProjects ? projects : projects.filter(project => project.userId === loggedUser.id)
  }
  return activeUser
    ? isUserCompanyCustomer(activeUser)
      ? permissions.find(permission => permission.type === 'company')
        ? projects
        : projects.filter(
          project => permissions.find(permission => permission.type === 'project' && permission.projectId === project.id))
      : showAllProjects ? projects : projects.filter(project => project.userId === activeUser.id)
    : []
}

export const prettifyCompanyRole = role => {
  switch (role) {
    case CompanyRole.CUSTOMER:
      return i18n.t('company.role.customer')
    case CompanyRole.EMPLOYEE:
      return i18n.t('company.role.employee')
    default:
      return i18n.t('company.role.unknown')
  }
}
