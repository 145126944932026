import zlib from 'zlib'
import { path } from 'ramda'
import { Buffer } from 'buffer'
// Project deps
// import { GenericParserInfo, LogMessage } from './utils'
// import { ProtoArtifact } from '../../types/import-wizard'
import { createProtoArtifact } from 'utils/protoArtifacts'
import { ArtifactTypes } from 'types/artifacts'
import { getIntervalFromSessionCamera, getNumberOfImagesForSessionCamera, getCamArtifactProperties } from 'utils/artifacts'
import { concatMap } from 'utils/list'
// Local deps
import { isNavApplanixFileName } from './nav-applanix'
import { isBaslerOrPylonCamera, getFileFromDropbox } from './utils'
import { isTrjFileName } from './trj'
import { getRotation, getTranslation } from 'utils/calibrationconverter'
import { isFileNameEndsWith } from 'utils/baseName'

// The key used in SpatialExplorer. See `phusion/common/project.cpp` for reference.
const xorKey = 'f2Fx+9id1_D'

// function xorCypher(input: Uint8Array, key: string): Uint8Array
export function xorCypher (input, key) {
  return input.map((value, index) => value ^ key.charCodeAt(index % key.length))
}

/**
 * Plp files use a simple encryption algorithm to prevent users from viewing SE internals.
 * The encryption is described as follows:
 * ```
 * encrypt = plaintext => xorCypher(QT5::qCompress(plaintext), xorKey)
 * decrypt = cyphertext => QT5::qUncompress(xorCypher(cyphertext, xorKey))
 * ```
 * The `qCompress` and `qUncompress` utilities from the C++ Qt5 library use a modified
 * `zlib` compression algorithm which puts the size of the compressed data into the first 4 bytes.
 * Removing those will yield a `zlib`-compatible bytearray,
 */
// function decompressPlp(input: ArrayBuffer, onErr: (err: Error) => void, onSuccess: (result: string) => void)
export function decompressPlp (input, onErr, onSuccess) {
  try {
    const decyphered = xorCypher(new Uint8Array(input), xorKey)
    const asBuffer = Buffer.from(decyphered.buffer, 4)
    zlib.inflate(asBuffer, (err, inflatedBuffer) => {
      if (err) {
        onErr(err)
      } else {
        onSuccess(inflatedBuffer.toString())
      }
    })
  } catch (e) {
    onErr(e)
  }
}

/**
 * Checks whether the file name of a file looks like a plp file name.
 * This is done by looking at the extension and checking whether it is '.plp'.
 */
// function isPlpFileName(fileName: string): boolean
export function isPlpFileName (fileName) {
  const plpExtensions = ['.plp']
  return plpExtensions.some(extension => isFileNameEndsWith(fileName, extension))
}

export function isPlpFileType (fileType) {
  return fileType === 'plp'
}

export function shouldPlpFileBeParsed (fileName) {
  const parseableExtensions = ['.plp']
  return parseableExtensions.some(extension => isFileNameEndsWith(fileName, extension))
}

export function isCreatedFromPlp (createdFrom = '') {
  return isFileNameEndsWith(createdFrom, '.plp')
}

/*
  interface Plp {
  readonly groundControl: {
    readonly [fileName: string]: {}
  }
  readonly sessionsCameras: {
    readonly [cameraId: number]: {
      readonly sessions: {
        readonly exposures: {
          readonly [index: string]: {
            readonly fileInfo: string
          }[]
        }
      }[]
    }
  }
  readonly sessionsLidars: {
    readonly [lidarId: number]: {
      readonly sessions: {
        readonly enabled: boolean
        readonly fileInfo: string
        readonly interval: {
          readonly beginning: { readonly tow: number readonly week: number }
          readonly end: { readonly tow: number readonly week: number }
        }
        readonly sensorModel: string
      }[]
      readonly settingsProcessing: {
        readonly transform: {
          readonly x: number
          readonly y: number
          readonly z: number
          readonly pitch: number
          readonly yaw: number
          readonly roll: number
        }
      }
    }
  }
  readonly trajectories: {
    readonly list: {
      readonly fileName: string
    }[]
  }
}
*/

/*
export interface PlpFileParserInfo extends GenericParserInfo {
  readonly fileType: 'plp'
  readonly result?: {
    readonly data: string
    readonly protoArtifacts: ProtoArtifact[]
  }
}
*/

// function generateProtoArtifacts(plpFileName: string, data: string): ProtoArtifact[]
export function generateProtoArtifacts (plpFileName, plp) {
  const { groundControl = {}, sessionsCameras = {}, sessionsLidars = {}, trajectories = {} } = plp
  const createEmptyProtoArtifactWithDefaultProperties = (artifactType, properties) => {
    return createProtoArtifact(
      artifactType,
      [],
      {
        createdFrom: plpFileName,
        ...(properties || {}),
        properties: {
          ...(properties.properties || {}),
        },
      },
    )
  }
  const groundControlArtifacts = Object.keys(groundControl).map(fileName =>
    createEmptyProtoArtifactWithDefaultProperties(ArtifactTypes.GROUND_CONTROL_POINTS, { fileSuggestions: [fileName] }),
  )
  const cameraSensorIndices = Object.keys(sessionsCameras)
  const cameraArtifacts = cameraSensorIndices.map(sensorIndex => {
    const sessionCamera = sessionsCameras[parseInt(sensorIndex)]
    const { sessions, settingsProcessing } = sessionCamera
    const subFolder = `cam${sensorIndex}`
    const sensorModels = []
    const pixelCounts = []
    const fileNames = Object.keys(sessions).reduce((allFileNames, sessionKey) => {
      const session = sessions[sessionKey]
      const settings = session.settingsAcquisition
      const pixelCount = path(['receptors', +sessionKey, 'pixelCount'], settingsProcessing)
      sensorModels.push(settings.sensorModel)
      pixelCounts.push(pixelCount)
      return [
        ...allFileNames,
        ...concatMap(
          exposureKey => session.exposures[exposureKey].map(exposure => exposure.fileInfo || ''),
          Object.keys(session.exposures),
        ),
      ]
    }, [])
    // For Basler and Pylon cameras we need to add one more file - PFS
    // And it must be only one of that kind per camera
    if (sensorModels.some(sensorModel => isBaslerOrPylonCamera(sensorModel)) && fileNames.length > 0) {
      fileNames.unshift(`${subFolder}.pfs`)
    }
    const transform = 'mounting' in settingsProcessing ? settingsProcessing.mounting : settingsProcessing.transform
    return createEmptyProtoArtifactWithDefaultProperties(ArtifactTypes.CAMERA_DATA, {
      fileSuggestions: fileNames,
      properties: {
        index: sensorIndex,
        offset: getTranslation(transform),
        rotation: getRotation(transform),
        ...getCamArtifactProperties(
          subFolder,
          getNumberOfImagesForSessionCamera(sessionCamera, sensorModels),
          'plp',
        ),
        interval: getIntervalFromSessionCamera(sessionCamera),
        sensorModels: sensorModels,
        pixelCounts: pixelCounts,
      },
    })
  })

  const lidarSensorIndices = Object.keys(sessionsLidars)
  const lidarArtifacts = lidarSensorIndices.map(sensorIndex => {
    const { sessions, settingsProcessing } = sessionsLidars[parseInt(sensorIndex)]
    const transform = 'mounting' in settingsProcessing ? settingsProcessing.mounting : settingsProcessing.transform
    const fileSuggestions = sessions.map(session => session.fileInfo || '')
    /*
    const fileProperties = sessions.reduce(
      (accum, session) => ({
        ...accum,
        [session.fileInfo]: { interval: session.interval },
      }),
      {}
    )
    */
    return createEmptyProtoArtifactWithDefaultProperties(
      ArtifactTypes.LIDAR_DATA,
      {
        fileSuggestions: fileSuggestions,
        properties: {
          sensorModel: '',
          index: sensorIndex,
          offset: getTranslation(transform),
          rotation: getRotation(transform),
          fileProperties: {},
        },
      },
    )
  })

  const navroverArtifacts = (trajectories.list || []).map(trajectory => {
    const { fileName } = trajectory
    const isApplanix = isNavApplanixFileName(fileName)
    const artifactType = isTrjFileName(fileName)
      ? ArtifactTypes.TRAJECTORY
      : isApplanix ? ArtifactTypes.NAVIGATION_ROVER_APPLANIX : ArtifactTypes.NAVIGATION_ROVER
    return createEmptyProtoArtifactWithDefaultProperties(artifactType, { fileSuggestions: [fileName] })
  })

  return [
    ...navroverArtifacts,
    ...groundControlArtifacts,
    ...lidarArtifacts,
    ...cameraArtifacts,
  ]
}

// function parsePlaintextPlp(log: LogMessage[], fileName: string, data: string): PlpFileParserInfo
function parsePlaintextPlp (log, fileName, data) {
  try {
    const plp = JSON.parse(data)
    const protoArtifacts = generateProtoArtifacts(fileName, plp)
    if (typeof protoArtifacts === 'undefined') {
      log.push({ level: 'error', message: `Error parsing the file '${fileName}'.` })
      return { okay: false, log, fileType: 'plp' }
    }
    if ('writeEvents' in plp && plp.writeEvents.length > 0) {
      const writeEvents = plp.writeEvents
      const lastWriteEvent = writeEvents[writeEvents.length - 1]
      if (
        lastWriteEvent &&
        'applicationName' in lastWriteEvent &&
        lastWriteEvent.applicationName !== 'rover'
      ) {
        log.push({
          level: 'info',
          message: `You're trying to upload the project file that was modified. To avoid the project being misconfigured we recommend uploading the original project file from the rover.`,
        })
      }
    }
    return { result: { data, protoArtifacts }, okay: true, log, fileType: 'plp' }
  } catch (e) {
    log.push({ level: 'error', message: `Error parsing the file '${fileName}'.` })
    return { okay: false, log, fileType: 'plp' }
  }
}

// function readPlaintextPlp(log: LogMessage[], file: File): Promise<PlpFileParserInfo>
async function readPlaintextPlp (log, file, fileName) {
  const fileReader = new FileReader()
  // Handler for successful reading.
  return new Promise(resolve => {
    fileReader.onload = () => resolve(parsePlaintextPlp(log, fileName, fileReader.result))
    // Handler for erroneous reading.
    fileReader.onerror = () => {
      log.push({ level: 'error', message: `Error reading file '${fileName}'.` })
      resolve({ okay: false, log, fileType: 'plp' })
    }
    fileReader.readAsText(file)
  })
}

// function parsePlpFile(file: File): Promise<PlpFileParserInfo>
export async function parsePlpFile (file, blob) {
  const log = []
  const fileName = file ? file.name : 'temp.plp'
  if (!isPlpFileName(fileName)) {
    log.push({
      level: 'warning',
      message: `Unsupported file extension in filename '${fileName}'`,
    })
  }
  const fileToUse = blob || file
  return new Promise(resolve => {
    const fileReader = new FileReader()

    // Handler for successful reading.
    fileReader.onload = async () => {
      const onDecryptionError = async err => {
        log.push({
          level: 'error',
          message: `Error decrypting the file '${fileName}': ${err}.`,
        })
        resolve(await readPlaintextPlp(log, fileToUse, fileName))
      }
      const onDecryptionSuccess = result => resolve(parsePlaintextPlp(log, fileName, result))
      decompressPlp(fileReader.result, onDecryptionError, onDecryptionSuccess)
    }
    // Handler for erroneous reading.
    fileReader.onerror = () => {
      log.push({ level: 'error', message: `Error reading file '${fileName}'.` })
      resolve({ okay: false, log, fileType: 'plp' })
    }
    fileReader.readAsArrayBuffer(fileToUse)
  })
}

/*
export async function parseDropboxPlpFile (file) {
  const response = await axios.get(file.link, {
    responseType: 'arraybuffer',
  })
  const blob = new Blob([response.data], {type : 'application/json'})
  return new parsePlpFile(file, blob)
}
*/

export async function parseDropboxPlpFile (file) {
  return getFileFromDropbox(file, { responseType: 'arraybuffer' }, { type: 'application/json' }, parsePlpFile)
}
