import { omit } from 'ramda'

export function getIEDatumLabel (datum) {
  return !datum
    ? null
    : `${datum.auth_name} ${datum.code}: ${datum.name} (${datum.navlab_datum})`
}

export function getEPSGDatumLabel (datum) {
  const data = datum.epsg_datum || datum
  return !data
    ? null
    : `${data.auth_name} ${data.code}: (${data.name})`
}

export function getDatumWithoutAreaBounds (datum) {
  return datum ? omit(['area_bounds'], datum) : null
}
