import { path } from 'ramda'
import { getGeoids } from 'modules/app/selectors'

/**
 * Returns label that will be displayed in UI
 * @param {*} geoid
 */
export function getGeoidLabel (geoid) {
  return !geoid ? null : `${geoid.name}${geoid.area_of_use ? ` (${geoid.area_of_use})` : ''}`
}

/**
 * Returns geoid file name
 * @param {*} geoid
 */
export const getGeoidFileName = geoid => {
  if (geoid) {
    // If type of the geoid is 'string', so it's probably already a name
    if (typeof geoid === 'string') {
      return geoid
    }
    // If type of geoid is 'object', so we can get it from the last part of the file field
    if (typeof geoid === 'object' && 'file' in geoid) {
      const splittedFileName = geoid.file.split('/')
      return splittedFileName[splittedFileName.length - 1]
    }
  }
  return null
}

export const getGeoid = (state, geoidFromProps) => {
  let geoid = typeof geoidFromProps === 'string'
    ? geoidFromProps
    : typeof geoidFromProps === 'object' && geoidFromProps
      ? { ...geoidFromProps }
      : geoidFromProps
  if (typeof geoid !== 'string' && geoid) {
    geoid = getGeoidFileName(geoid)
  }
  if (geoid) {
    return getGeoids(state).find(g => {
      return getGeoidFileName(g) === geoid
    }) || null
  }
  return null
}
/**
 * Returns source crs from geoid
 * @param {*} geoid
 */
export const getGeoidCRSSource = geoid => {
  if (!geoid) return null
  return path(['crs', 'source'], geoid) || null
}
/**
 * Returns target crs from geoid
 * @param {*} geoid
 */
export const getGeoidCRSTarget = geoid => {
  if (!geoid) return null
  return path(['crs', 'target'], geoid) || null
}
