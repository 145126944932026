import { isFileNameEndsWith } from 'utils/baseName'

export const OBJExtensions = [
  '.obj',
]
/**
 * Checks whether the file name of a file looks like a obj file name.
 */
export function isOBJFileName (fileName) {
  return OBJExtensions.some(objExtension => isFileNameEndsWith(fileName, objExtension))
}

export function isOBJFileType (fileType) {
  return fileType === 'obj'
}
