import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  workers: [],
  getWorkersIsLoading: false,
  getWorkersErrorMessage: null,

  rules: [],
  getWorkerRulesIsLoading: false,
  getWorkerRulesErrorMessage: null,
})
