import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  checkGrant: ['grant', 'key', 'field', 'resultField'],
  checkGrantLoading: ['grant', 'key'],
  checkGrantSuccess: ['grant', 'allowed', 'key'],
  checkGrantFailure: ['errorMessage', 'key'],

  checkGrants: ['grants', 'key'],
  checkGrantsLoading: ['key'],
  checkGrantsSuccess: ['grants', 'key'],
  checkGrantsFailure: ['errorMessage'],

  getGrants: ['userId'],
  getGrantsLoading: null,
  getGrantsSuccess: ['grants', 'navlabUsed', 'navlabMax', 'userId'],
  getGrantsFailure: ['errorMessage'],
})

export const GrantsTypes = Types
export default Creators
