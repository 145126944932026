import { Dropbox } from 'dropbox'
import fetch from 'isomorphic-fetch'
import { toast } from 'react-toastify'
// Project deps
import config from 'config'
import axios, { removeTokenOnceOptions } from 'utils/axios'

/**
 * Function creates temporary link to the dropbox file and download it
 * and return the blob with downloaded data
 * @param {Object} file Dropbox-like file with token and path specified
 * @param {Object} options Will be passed to axios
 * @param {Object} blobOptions With this options blob will be created
 */
export const getDropboxFileData = async (file, options = {}, blobOptions = { type: 'text/plain' }) => {
  const { token, path } = file
  const dbx = new Dropbox({ clientId: config.DROPBOX_API_KEY, accessToken: token, fetch })
  const result = await dbx.filesGetTemporaryLink({ path })
  const response = await axios.get(result.result.link, { ...options, ...removeTokenOnceOptions() })
  return new Blob([response.data], blobOptions)
}

/**
 * Function creates temporary link to the file and download it
 * and return the blob with downloaded data
 * @param {Object} file Dropbox-like file with token and path specified
 * @param {Object} options Will be passed to axios
 * @param {Object} blobOptions With this options blob will be created
 */
export const getFileData = async (link, options = {}, blobOptions = { type: 'text/plain' }) => {
  const response = await axios.get(link, { ...options, ...removeTokenOnceOptions() })
  return new Blob([response.data], blobOptions)
}
export const getFile = async (fileLink, options, blobOptions, callback) => {
  let blob = null
  try {
    blob = await getFileData(fileLink, options, blobOptions)
    return callback(null, blob)
  } catch (e) {
    toast.error('Something went wrong while downloading file')
  }
}

export const isBaslerOrPylonCamera = cameraModel => {
  return (/basler/ig).test(cameraModel) || (/pylon/ig).test(cameraModel)
}

export const isLadybugCamera = cameraModel => {
  return (/ladybug/ig).test(cameraModel)
}

export const getFileFromDropbox = async (file, options, blobOptions, callback) => {
  let blob = null
  try {
    blob = await getDropboxFileData(file, options, blobOptions)
    if (typeof callback === 'function') {
      return callback(file, blob, true)
    }
    return blob
  } catch (e) {
    toast.error('Something went wrong while receiving file from dropbox')
  }
}
