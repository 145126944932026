const defaultPagination = {
  has_next: false,
  has_prev: false,
  page: 1,
  page_size: 50,
  total: 0,
  total_pages: 0,
}

export const pipelineState = {
  pipelines: [],
  pipelinesPagination: defaultPagination,
  updatePipelineStatus: {},
  deletePipelineStatus: {},
  retryJobStatus: {},
  getJobRunStatus: {},

  getPipelinesIsLoading: false,
  getPipelinesErrorMessage: null,

  getPipelineIsLoading: false,
  getPipelineErrorMessage: null,

  getPipelinesQueriedIsLoading: false,
  getPipelinesQueriedErrorMessage: null,

  getJobRunIsLoading: false,
  getJobRunErrorMessage: null,

  // Needed to make a redirect from job run page
  getPipelineIdByJobRunIsLoading: false,
  pipelineFromJobRun: null,
  getPipelineIdByJobRunErrorMessage: null,

}
