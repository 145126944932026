
export function getSuccessStatus (state, id) {
  return {
    ...state,
    [id]: {
      error: null,
      loading: false,
    },
  }
}

export function getFailureStatus (state, id, error) {
  return {
    ...state,
    [id]: {
      error,
      loading: false,
    },
  }
}

export function getLoadingStatus (state, id) {
  return {
    ...state,
    [id]: {
      error: null,
      loading: true,
    },
  }
}
