import { call, put, takeLatest, takeEvery, fork, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import axios, { axiosAccounts } from 'utils/axios'
import { routeCompanyUserRegisterSuccess } from 'utils/routing'
import { getErrorMessage, resolveTasks, showErrorMessage } from 'utils/api'
import { PermissionType } from 'types/permissions'
import { register } from '../utils'
// Local deps
import CompaniesActions, { CompaniesTypes } from './actions'
import { convertRawCompanyPosition } from 'types/companyPositions'
import { getAddress, getSystemSerialNumber } from 'utils/users'

// Sagas
// Retrieve a list of companies (used by admins)
function * getCompanies () {
  yield put(CompaniesActions.getCompaniesLoading())
  try {
    const { data: { data: companies } } = yield call(axios.get, `/companies`)
    yield put(CompaniesActions.getCompaniesSuccess(companies))
  } catch (e) {
    yield put(CompaniesActions.getCompaniesFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of company users
function * getCompanyUsers ({ companyId }) {
  yield put(CompaniesActions.getCompanyUsersLoading())
  try {
    const { data: { data: users } } = yield call(axios.get, `/companies/${companyId}/users?exclude=last_activity`)
    yield put(CompaniesActions.getCompanyUsersSuccess(users))
  } catch (e) {
    yield put(CompaniesActions.getCompanyUsersFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of company projects
function * getCompanyProjects ({ companyId, setAsCurrent = true }) {
  yield put(CompaniesActions.getCompanyProjectsLoading(companyId))
  try {
    const { data: { data: projects } } = yield call(axios.get, `/companies/${companyId}/projects`)
    yield put(CompaniesActions.getCompanyProjectsSuccess(companyId, projects, setAsCurrent))
  } catch (e) {
    yield put(CompaniesActions.getCompanyProjectsFailure(companyId, getErrorMessage(e)))
  }
}
// Retrieve individual company
function * getCompany ({ companyId }) {
  yield put(CompaniesActions.getCompanyLoading())
  try {
    const { data: { data: company } } = yield call(axios.get, `/companies/${companyId}`)
    yield put(CompaniesActions.getCompanySuccess(company))
  } catch (e) {
    yield put(CompaniesActions.getCompanyFailure(getErrorMessage(e)))
  }
}
// Generate invite url for a company with provided email and company role (customer or employee)
function * generateInviteUrl ({ companyId, email, companyRole }) {
  yield put(CompaniesActions.generateInviteUrlLoading(companyId))
  try {
    const { data: { message } } = yield call(axios.post, `/companies/${companyId}/invite_user`, {
      company_role: companyRole,
      email,
    })
    toast.success(message)
    yield put(CompaniesActions.generateInviteUrlSuccess(companyId))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.generateInviteUrlFailure(companyId, getErrorMessage(e)))
  }
}
// Register company employee
function * registerEmployee ({ companyId, token, data }) {
  yield put(CompaniesActions.registerEmployeeLoading())
  try {
    const body = {
      email: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      country: data.country,
      address: getAddress(data),
      // phone: data.phone,
      // mapping_system_product: data.mappingSystemProduct,
      // navigation_system_vendor: data.navigationSystemVendor,
      // time_zone: data.timeZone,
      terms_accepted: data.termsAccepted,
      system_serial_numbers: getSystemSerialNumber(data),
    }
    yield call(register, `/companies/${companyId}/register/${token}?lat=${data.lat}&lon=${data.lng}`, body, true, routeCompanyUserRegisterSuccess())
    yield put(CompaniesActions.registerEmployeeSuccess())
  } catch (e) {
    yield put(CompaniesActions.registerEmployeeFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of company system types
function * getCompanySystemTypes ({ companyId }) {
  yield put(CompaniesActions.getCompanySystemTypesLoading(companyId))
  try {
    const { data: { data: systemTypes } } = yield call(axiosAccounts.get, `/companies/${companyId}/system_types`)
    yield put(CompaniesActions.getCompanySystemTypesSuccess(companyId, systemTypes))
  } catch (e) {
    yield put(CompaniesActions.getCompanySystemTypesFailure(companyId, getErrorMessage(e)))
  }
}

// Retrieve a list of company subscriptions
function * getCompanySubscriptions ({ companyId }) {
  yield put(CompaniesActions.getCompanySubscriptionsLoading(companyId))
  try {
    const { data: { data: subscriptions } } = yield call(axiosAccounts.get, `/companies/${companyId}/subscriptions`)
    yield put(CompaniesActions.getCompanySubscriptionsSuccess(companyId, subscriptions))
  } catch (e) {
    yield put(CompaniesActions.getCompanySubscriptionsFailure(companyId))
  }
}
// Retrieve a list of company permissions (permissions for a customer users)
function * getCompanyPermissions ({ companyId }) {
  yield put(CompaniesActions.getCompanyPermissionsLoading(companyId))
  try {
    const { data: { data: permissions } } = yield call(axios.get, `/companies/${companyId}/permissions`)
    yield put(CompaniesActions.getCompanyPermissionsSuccess(companyId, permissions))
  } catch (e) {
    yield put(CompaniesActions.getCompanyPermissionsFailure(companyId))
  }
}
// Add permissions for a customer user to read provided projects
function * addProjectsPermission ({ userId, projectIds }) {
  yield put(CompaniesActions.addProjectsPermissionLoading(userId))
  try {
    const tasks = []
    for (const projectId of projectIds) {
      tasks.push(yield fork(axios.post, `/projects/${projectId}/permissions`, {
        user: { id: userId },
        permission_type: PermissionType.READ,
      }))
    }
    const allPermissions = yield resolveTasks(tasks)
    toast.success(i18next.t('toast.company.addProjectsPermissionSuccess'))
    yield put(CompaniesActions.addProjectsPermissionSuccess(userId, allPermissions))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.addProjectsPermissionFailure(userId))
  }
}
// Add permissions for a customer user to ALL projects of company
function * addCompanyPermission ({ userId, companyId }) {
  yield put(CompaniesActions.addCompanyPermissionLoading(userId, companyId))
  try {
    const { data: { data: permission } } = yield call(axios.post, `/companies/${companyId}/permissions`, {
      user: { id: userId },
      permission_type: PermissionType.READ,
    })
    toast.success(i18next.t('toast.company.addCompanyPermissionSuccess'))
    yield put(CompaniesActions.addCompanyPermissionSuccess(userId, permission))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.addCompanyPermissionFailure(userId, companyId))
  }
}
// Delete permission for a customer user to read ALL company projects
function * deleteCompanyPermission ({ userId, permissionId }) {
  yield put(CompaniesActions.deletePermissionLoading(userId, permissionId))
  try {
    yield call(axios.delete, `/companies/permissions/${permissionId}`)
    toast.success(i18next.t('toast.company.deleteCompanyPermissionSuccess'))
    yield put(CompaniesActions.deletePermissionSuccess(userId, permissionId))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.deletePermissionFailure(userId, permissionId))
  }
}
// Delete permission for a customer user to read individual project
function * deleteProjectPermission ({ userId, permissionId }) {
  yield put(CompaniesActions.deletePermissionLoading(userId, permissionId))
  try {
    yield call(axios.delete, `/projects/permissions/${permissionId}`)
    toast.success(i18next.t('toast.company.deleteProjectPermissionSuccess'))
    yield put(CompaniesActions.deletePermissionSuccess(userId, permissionId))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.deletePermissionFailure(userId, permissionId))
  }
}
// Retrieve a list of company positions (reference station positions)
function * getPositions ({ companyId }) {
  yield put(CompaniesActions.getPositionsLoading(companyId))
  try {
    const state = yield select(state => state)
    const { data: { data: positions } } = yield call(axios.get, `/companies/${companyId}/positions`)
    yield put(CompaniesActions.getPositionsSuccess(
      companyId,
      positions.map(position => convertRawCompanyPosition(position, state)),
    ))
  } catch (e) {
    yield put(CompaniesActions.getPositionsFailure(companyId, getErrorMessage(e)))
  }
}
// Add custom reference station position for a company
function * addReferenceStationPosition ({ companyId, name, data }) {
  yield put(CompaniesActions.addReferenceStationPositionLoading())
  try {
    const state = yield select(state => state)
    const { data: { data: position } } = yield call(axios.post,
      `/companies/${companyId}/positions`,
      {
        name,
        ...data,
      },
    )
    toast.success(i18next.t('toast.company.addReferenceStationPositionSuccess'))
    yield put(CompaniesActions.addReferenceStationPositionSuccess(companyId, convertRawCompanyPosition(position, state)))
  } catch (e) {
    yield put(CompaniesActions.addReferenceStationPositionFailure())
  }
}
// Check invite token for exparation
function * checkInviteToken ({ token }) {
  yield put(CompaniesActions.checkInviteTokenLoading(token))
  try {
    yield call(axios.get, `/companies/check_invite/${token}`)
    yield put(CompaniesActions.checkInviteTokenSuccess(token))
  } catch (e) {
    showErrorMessage(e)
    yield put(CompaniesActions.checkInviteTokenFailure(token, getErrorMessage(e)))
  }
}

// Retrieve a data usage by company
function * getCompanyDataUsage ({ companyId }) {
  yield put(CompaniesActions.getCompanyDataUsageLoading(companyId))
  try {
    const { data: { data: dataUsage } } = yield call(axios.get, `/companies/${companyId}/data_usage`)
    yield put(CompaniesActions.getCompanyDataUsageSuccess(companyId, dataUsage))
  } catch (e) {
    yield put(CompaniesActions.getCompanyDataUsageFailure(companyId, getErrorMessage(e)))
  }
}

// Watchers
function * getCompanyPermissionsWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANY_PERMISSIONS, getCompanyPermissions)
}
function * getCompaniesWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANIES, getCompanies)
}
function * getCompanyUsersWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANY_USERS, getCompanyUsers)
}
function * getCompanyProjectsWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_PROJECTS, getCompanyProjects)
}
function * getCompanyWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANY, getCompany)
}
function * generateInviteUrlWatcher () {
  yield takeLatest(CompaniesTypes.GENERATE_INVITE_URL, generateInviteUrl)
}
function * registerEmployeeWatcher () {
  yield takeLatest(CompaniesTypes.REGISTER_EMPLOYEE, registerEmployee)
}
function * getCompanySystemTypesWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANY_SYSTEM_TYPES, getCompanySystemTypes)
}
function * getCompanySubscriptionsWatcher () {
  yield takeEvery(CompaniesTypes.GET_COMPANY_SUBSCRIPTIONS, getCompanySubscriptions)
}
function * addProjectsPermissionWatcher () {
  yield takeLatest(CompaniesTypes.ADD_PROJECTS_PERMISSION, addProjectsPermission)
}
function * addCompanyPermissionWatcher () {
  yield takeLatest(CompaniesTypes.ADD_COMPANY_PERMISSION, addCompanyPermission)
}
function * deleteCompanyPermissionWatcher () {
  yield takeLatest(CompaniesTypes.DELETE_COMPANY_PERMISSION, deleteCompanyPermission)
}
function * deleteProjectPermissionWatcher () {
  yield takeLatest(CompaniesTypes.DELETE_PROJECT_PERMISSION, deleteProjectPermission)
}
function * getPositionsWatcher () {
  yield takeEvery(CompaniesTypes.GET_POSITIONS, getPositions)
}
function * addReferenceStationPositionWatcher () {
  yield takeLatest(CompaniesTypes.ADD_REFERENCE_STATION_POSITION, addReferenceStationPosition)
}
function * checkInviteTokenWatcher () {
  yield takeLatest(CompaniesTypes.CHECK_INVITE_TOKEN, checkInviteToken)
}
function * getCompanyDataUsageWatcher () {
  yield takeLatest(CompaniesTypes.GET_COMPANY_DATA_USAGE, getCompanyDataUsage)
}

export default function * root () {
  yield fork(getCompanySystemTypesWatcher)
  yield fork(getCompanySubscriptionsWatcher)
  yield fork(getCompanyPermissionsWatcher)
  yield fork(getCompaniesWatcher)
  yield fork(getCompanyUsersWatcher)
  yield fork(getCompanyProjectsWatcher)
  yield fork(getCompanyWatcher)
  yield fork(generateInviteUrlWatcher)
  yield fork(registerEmployeeWatcher)
  yield fork(addProjectsPermissionWatcher)
  yield fork(addCompanyPermissionWatcher)
  yield fork(deleteCompanyPermissionWatcher)
  yield fork(deleteProjectPermissionWatcher)
  yield fork(getPositionsWatcher)
  yield fork(addReferenceStationPositionWatcher)
  yield fork(checkInviteTokenWatcher)
  yield fork(getCompanyDataUsageWatcher)
}
