import { MapBackendNameToFrontend } from 'templates/constants'

export const CRSTag = {
  UNIT: 'unit',
  CRS: 'crs',
  CUSTOM_CRS: 'custom_crs',
  COORDINATE: 'coordinate',
  EPOCH: 'epoch',
  TAB: 'tab',
  GEOID: 'geoid',
  // ENABLE_V_CRS: 'enable_v_crs',
}

export const UnitName = {
  H_UNITS: MapBackendNameToFrontend.h_units,
  V_UNITS: MapBackendNameToFrontend.v_units,
}

/**
 * Available CRS fields
 */

export const CRSFieldsOld = {
  C_CRS: 'compound_crs',
  V_CRS: 'vertical_crs',
  H_CRS: 'horizontal_crs',
  V_UNITS: 'v_units',
  H_UNITS: 'h_units',
  GRID: 'grid',
  CUSTOM_CRS: 'custom_str',
}
export const CRSFields = {
  C_CRS: 'crs',
  V_CRS: 'crs_v',
  H_CRS: 'crs_h',
  V_UNITS: 'unit_v',
  H_UNITS: 'unit_h',
  GRID: 'geoid',
  GEOID_CRS: 'geoid_crs',
  IS_ELLIPSOIDAL: 'is_ellipsoidal',

  EPOCH: 'epoch',
  TAB: 'tab',
  TABS_TEMPLATE: 'tabsTemplate',
  LAST_CHANGED_TAB: 'lastChangedTab',
  LONGITUDE_TRANSFORMED: 'longitudeTransformed',
  LATITUDE_TRANSFORMED: 'latitudeTransformed',
  ALTITUDE_TRANSFORMED: 'altitudeTransformed',

  FILTER_MODE: 'filter_mode',
  COORDINATES_CHOOSER: 'coordinates_chooser',

  CUSTOM_CRS: 'custom_crs',
  // ENABLE_V_CRS: 'enable_v_crs',

  NORMALIZE_AXIS_ORDER: 'normalize_axis_order',
}

export const OldCRSFieldsToNewMapping = {
  [CRSFieldsOld.C_CRS]: CRSFields.C_CRS,
  [CRSFieldsOld.V_CRS]: CRSFields.V_CRS,
  [CRSFieldsOld.H_CRS]: CRSFields.H_CRS,
  [CRSFieldsOld.V_UNITS]: CRSFields.V_UNITS,
  [CRSFieldsOld.H_UNITS]: CRSFields.H_UNITS,
  [CRSFieldsOld.GRID]: CRSFields.GRID,
  [CRSFieldsOld.CUSTOM_CRS]: CRSFields.C_CRS,
}

const omitFields = [CRSFields.EPOCH]

export const CRSUserDisplayFields = {
  [CRSFieldsOld.V_UNITS]: ['name'],
  [CRSFieldsOld.H_UNITS]: ['name'],
  [CRSFieldsOld.GRID]: ['name'],
  // datum: ['name']
}

export const NavlabOptionsDisplayFields = {
  output_datum: ['name'],
}

export const SpatialFuserOptionsDisplayFields = {
  output_crs: {
    [CRSFieldsOld.V_UNITS]: ['name'],
    [CRSFieldsOld.H_UNITS]: ['name'],
    [CRSFieldsOld.GRID]: ['name'],
    [CRSFieldsOld.V_CRS]: ['code', 'auth_name', 'proj_string'],
    [CRSFieldsOld.H_CRS]: ['code', 'auth_name', 'proj_string'],
    [CRSFieldsOld.C_CRS]: ['code', 'auth_name', 'proj_string'],
    // processing_datum: ['name']
  },
}

export const ReferenceStationOptionsDisplayFields = {
  [CRSFieldsOld.V_CRS]: ['code', 'auth_name', 'proj_string'],
  [CRSFieldsOld.H_CRS]: ['code', 'auth_name', 'proj_string'],
  [CRSFieldsOld.C_CRS]: ['code', 'auth_name', 'proj_string'],
}

export const CRSFieldsList = [
  ...Object.keys(CRSFields).map(key => CRSFields[key]),
  'cesiumMap',
  'custom_str_type',
]

export const CRSFieldsListNew = [
  CRSFields.C_CRS,
  CRSFields.V_CRS,
  CRSFields.H_CRS,
  CRSFields.V_UNITS,
  CRSFields.H_UNITS,
  CRSFields.GRID,
  CRSFields.GEOID_CRS,
  CRSFields.IS_ELLIPSOIDAL,
  CRSFields.NORMALIZE_AXIS_ORDER,
  'datum',
  'height_type',
]

export const RefStationCRSFieldsOmitList = [
  ...Object.keys(CRSFields).reduce((allFields, key) => omitFields.indexOf(CRSFields[key]) >= 0
    ? allFields
    : [...allFields, CRSFields[key]]
  , []),
  'cesiumMap',
]

export const crsTabNames = {
  COMPOUND: 'compound',
  CRS: 'crs',
  CUSTOM_CRS: 'custom',
}

export const tabsMap = {
  0: crsTabNames.COMPOUND,
  1: crsTabNames.CRS,
  2: crsTabNames.CUSTOM_CRS,
}

export const lasFormats = {
  export_las_1_4: MapBackendNameToFrontend.export_las_1_4,
  export_las_1_2: MapBackendNameToFrontend.export_las_1_2
}
