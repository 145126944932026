import i18n from 'i18n'
import { getUTCDate } from 'utils/dateTime'

export const ArtifactTypes = {
  NAVIGATION_ROVER: 'navigation_rover',
  NAVIGATION_ROVER_APPLANIX: 'navigation_rover_applanix',
  REFERENCE_STATION: 'reference_station',
  GROUND_CONTROL_POINTS: 'ground_control_points',
  TRAJECTORY: 'trajectory',
  CAMERA_DATA: 'camera_data',
  LIDAR_DATA: 'lidar_data',
  POINTCLOUD: 'pointcloud',
  POTREE: 'potree',
  RECON_DATA: 'recon_data',
  TEST_ARTIFACT_INPUT: 'test_artifact_input',
  TEST_ARTIFACT_OUTPUT: 'test_artifact_output',
  LOG: 'log',
  TILES: 'tiles',
  DOCUMENTATION: 'doc',
  GEOTIFF: 'geo_tiff',
  POLYGON: 'polygon',
}

export const ArtifactMissionType = {
  MISSION: 'Mission artifact',
  NON_MISSION: 'Non mission artifact',
}

export const ArtifactTypesArray = [
  ArtifactTypes.NAVIGATION_ROVER,
  ArtifactTypes.NAVIGATION_ROVER_APPLANIX,
  ArtifactTypes.REFERENCE_STATION,
  ArtifactTypes.GROUND_CONTROL_POINTS,
  ArtifactTypes.TRAJECTORY,
  ArtifactTypes.CAMERA_DATA,
  ArtifactTypes.LIDAR_DATA,
  ArtifactTypes.POINTCLOUD,
  ArtifactTypes.POTREE,
  ArtifactTypes.TEST_ARTIFACT_INPUT,
  ArtifactTypes.TEST_ARTIFACT_OUTPUT,
  ArtifactTypes.LOG,
  ArtifactTypes.TILES,
  ArtifactTypes.RECON_DATA,
  ArtifactTypes.DOCUMENTATION,
  ArtifactTypes.GEOTIFF,
  ArtifactTypes.POLYGON,
]

export const ArtifactStatuses = {
  UPLOADING: 'uploading',
  PROCESSING: 'processing',
  OUTPUT_OF_FAILED_PIPELINE: 'output_of_failed_pipeline',
  READY: 'ready',
  UNCOMPLETED: 'uncompleted',
  SCHEDULED: 'scheduled',
  WAITING: 'waiting',
  CONTACT_SUPPORT: 'contact_support',
}

export const PrettyArtifactStatuses = Object.values(ArtifactStatuses).reduce((allTranslations, value) => {
  return {
    ...allTranslations,
    [value]: i18n.t(`artifact.status.${value}`),
  }
}, {})

// Check whether the object stored in the `fileProperties` mapping comes from
// the analysis pipeline of the reference station.

// function isRefstationAnalyzeResult(props: FileProperties): props is RefstationAnalyzeResult
export function isRefstationAnalyzeResult (fileProps) {
  return fileProps && 'interval' in fileProps
}

// Check whether the object stored in the `fileProperties` mapping comes from
// the binary .ldr file analysis.

// function isLdrFileParserInfo(props: FileProperties): props is LdrFileParserInfo
export function isLdrFileParserInfo (fileProps) {
  return !!(fileProps && (fileProps.fileType && fileProps.result))
}

// Check whether the object stored in the `fileProperties` mapping comes from
// the .plp file analysis part of the .ldr files.
// function isPlpLdrInfo(props: FileProperties): props is PlpLdrInfo
export function isPlpLdrInfo (fileProps) {
  return !!(fileProps && fileProps.interval)
}

export function isCamFileParserInfo (fileProps) {
  return !!(fileProps && (fileProps.fileType && fileProps.result))
}

export function getArtifactWarnings (artifact) {
  const { properties = {} } = artifact
  if (properties) {
    const { warnings = [] } = properties
    const fileProperties = properties ? (properties.fileProperties || {}) : {}
    const newWarnings = [...warnings]
    const badObservationFrequencies = Object.keys(fileProperties).reduce((allFrequencies, fileName) => {
      const fileProperty = fileProperties[fileName]
      const { observationFrequency } = fileProperty
      const badFrequency = observationFrequency && observationFrequency > 0 && observationFrequency < 1
      return !badFrequency ? allFrequencies : {
        ...allFrequencies,
        [fileName]: observationFrequency,
      }
    }, {})
    if (Object.keys(badObservationFrequencies).length > 0) {
      newWarnings.push({
        key: 'pipelineWizard.artifactStep.warnings.badRefStationObservationFrequency',
        values: {
          fileNames: Object.keys(badObservationFrequencies).join(', '),
          frequencyValues: Object.values(badObservationFrequencies).join(', '),
        },
      })
    }
    return newWarnings
  }
}

/**
 * Retrieve the number of images from cam file
 * @param {Object} camFile
 */
export const getCamFilePhotosNumber = camFile => {
  return camFile ? Math.ceil((camFile.file.size - 2048) / 312) : 0
}

export function convertRawArtifact (raw) {
  const {
    id,
    name,
    artifact_type: artifactType,
    artifact_status: artifactStatus,
    is_output_artifact: isOutputArtifact,
    project,
    completed,
    hidden,
    created,
    updated,
    properties,
    size,
    scheduled,
    analyzing_pipeline,
    input_pipelines,
    output_pipelines,
    mission,
  } = raw
  return {
    id,
    name,
    size,
    artifactType,
    artifactStatus,
    projectId: project.id,
    // Cast stuff like `undefined`, `null` or not defined to boolean.
    completed: Boolean(completed),
    isOutputArtifact: Boolean(isOutputArtifact),
    hidden: Boolean(hidden),
    created: typeof created === 'string' ? getUTCDate(created) : undefined,
    updated: typeof updated === 'string' ? getUTCDate(updated) : undefined,
    properties: typeof properties === 'object' && properties !== null ? properties : undefined,
    scheduled,
    analyzingPipelines: analyzing_pipeline === null || typeof analyzing_pipeline === 'undefined' ? [] : analyzing_pipeline,
    input_pipelines: input_pipelines || [],
    output_pipelines: output_pipelines || [],
    mission: mission || undefined,
    missionId: mission && mission.id,
    warnings: getArtifactWarnings(raw),
  }
}

export function convertRawDataDirectory (raw) {
  const { id, s3_key, s3_link, description, completed, created, updated, file_index, artifact } = raw
  return {
    id,
    s3Key: s3_key,
    s3Link: s3_link,
    description,
    completed: Boolean(completed),
    created: new Date(created),
    updated: new Date(updated),
    fileIndex: file_index,
    artifactId: artifact.id,
  }
}

export function convertRawDataFile (raw) {
  const { id, s3_key, s3_link, file_size, file_name, completed, created, updated, chunks, artifact } = raw
  const dataFile = {
    id,
    s3Key: s3_key,
    s3Link: s3_link,
    fileSize: file_size,
    fileName: file_name,
    completed: Boolean(completed),
    created: new Date(created),
    updated: new Date(updated),
    artifactId: artifact.id,
  }
  if (typeof chunks !== 'undefined') {
    return {
      ...dataFile,
      chunks: chunks.map(chunk => ({
        id: chunk.chunk_id,
        size: chunk.chunk_size,
        completed: chunk.completed,
        checksum: chunk.checksum,
      })),
    }
  }
  return dataFile
}

/**
 * All the next function are used to determine the type of the artifact
 */
export function isTrajectoryArtifact (artifactType) {
  return artifactType === ArtifactTypes.TRAJECTORY
}
export function isTilesArtifact (artifactType) {
  return artifactType === ArtifactTypes.TILES
}
export function isCameraArtifact (artifactType) {
  return artifactType === ArtifactTypes.CAMERA_DATA
}
export function isLidarArtifact (artifactType) {
  return artifactType === ArtifactTypes.LIDAR_DATA
}
export function isNavRoverArtifact (artifactType) {
  return artifactType === ArtifactTypes.NAVIGATION_ROVER
}
export function isNavRoverApplanixArtifact (artifactType) {
  return artifactType === ArtifactTypes.NAVIGATION_ROVER_APPLANIX
}
export function isRefStationArtifact (artifactType) {
  return artifactType === ArtifactTypes.REFERENCE_STATION
}
export function isGCPArtifact (artifactType) {
  return artifactType === ArtifactTypes.GROUND_CONTROL_POINTS
}
export function isPointcloudArtifact (artifactType) {
  return artifactType === ArtifactTypes.POINTCLOUD
}
export function isLogArtifact (artifactType) {
  return artifactType === ArtifactTypes.LOG
}
export function isPotreeArtifact (artifactType) {
  return artifactType === ArtifactTypes.POTREE
}
export function isReconDataArtifact (artifactType) {
  return artifactType === ArtifactTypes.RECON_DATA
}
export function isDocumentationArtifact (artifactType) {
  return artifactType === ArtifactTypes.DOCUMENTATION
}
export function isGeotiffArtifact (artifactType) {
  return artifactType === ArtifactTypes.GEOTIFF
}
export function isPolygonArtifact (artifactType) {
  return artifactType === ArtifactTypes.POLYGON
}
