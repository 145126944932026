import { radiansToDegrees } from '@turf/turf'
import L from 'leaflet'
import { Euler, RotationOrder, Matrix4 } from 'math-ds'

export const mapDegrees = (max, min, value) => {
  return value > max ? value - max : value < min ? value + (-min) : value
}

/**
 * @param {number} x - x coordinate of the point
 * @param {number} y - y coordinate of the point
 * @param {number} x1 - left bottom x coordinate
 * @param {number} y1 - left bottom y coordinate
 * @param {number} x2 - right top x coordinate
 * @param {number} y2 - right top y coordinate
 */
export const isCoordinateLieInRectangle = (x, y, x1, y1, x2, y2) => {
  return x >= x1 && x <= x2 && y >= y1 && y <= y2
}

export const clampDegrees = deg => {
  return deg > 180 ? deg - 360 : deg
}

export const isCoordinateInBounds = (position = {
  lng: 0,
  lat: 0,
}, bounds = {
  w_boundary: 0,
  e_boundary: 0,
  s_boundary: 0,
  n_boundary: 0,
}) => {
  const eb = clampDegrees(bounds.e_boundary)
  const wb = clampDegrees(bounds.w_boundary)
  const nb = clampDegrees(bounds.n_boundary)
  const sb = clampDegrees(bounds.s_boundary)

  /*
  const pt = turf.point([position.lng, position.lat])
  const poly = turf.polygon([[

    [wb, nb],
    [eb, nb],
    [eb, sb],
    [wb, sb],
    [wb, nb],
  ]])
  turf.booleanPointInPolygon(pt, poly)
  */
  if (
    typeof bounds.e_boundary === 'number' &&
    typeof bounds.w_boundary === 'number' &&
    typeof bounds.n_boundary === 'number' &&
    typeof bounds.s_boundary === 'number'
  ) {
    return isPointInsidePolygon([position.lat, position.lng], L.polygon(
      [
        [nb, wb],
        [nb, eb],
        [sb, eb],
        [sb, wb],
      ],
    ))
  }
  return false
}

export function isPointInsidePolygon (pointCoordinates, poly) {
  let inside = false
  const x = pointCoordinates[0]
  const y = pointCoordinates[1]
  for (let ii = 0; ii < poly.getLatLngs().length; ii++) {
    const polyPoints = poly.getLatLngs()[ii]
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      const xi = polyPoints[i].lat || polyPoints[i][0]
      const yi = polyPoints[i].lng || polyPoints[i][1]
      const xj = polyPoints[j].lat || polyPoints[j][0]
      const yj = polyPoints[j].lng || polyPoints[j][1]

      const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
      if (intersect) inside = !inside
    }
  }
  return inside
}

export const transformToEuler = transform => {
  const matrix = new Matrix4()
  matrix.fromArray(transform)
  return getEulerTransform(matrix)
}

export const getEulerTransform = matrix => {
  const euler = new Euler()
  euler.setFromRotationMatrix(matrix, RotationOrder.YXZ)
  return [euler.x, euler.y, euler.z].map(radiansToDegrees)
}
