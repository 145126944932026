import { call, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { omit } from 'ramda'
import cbor from 'cbor-js'
// Project deps
import i18next from 'i18n'
import history from 'browserHistory'
import { token as getToken } from 'modules/users/selectors'
import axios, { axiosCbor } from 'utils/axios'
import { getAuthentificationHeader, showErrorMessage } from 'utils/api'
import { routeRegisterSuccess } from 'utils/routing'
import { keepProps } from 'utils/dict'

export function * getGCP (options, displayErrors = true) {
  const token = yield select(state => getToken(state))
  try {
    const coordinate_reference_system = omit(['points', 'pipeline_string', 'epoch'], options)
    const otherFields = keepProps(['points', 'pipeline_string', 'epoch'], options)
    const { data: { data: gcps } } = yield call(axios.post,
      `/transformation/crs_to_wgs84`, {
        ...otherFields,
        coordinate_reference_system,
      }, {
        headers: getAuthentificationHeader(token),
      },
    )
    return gcps
  } catch (e) {
    if (displayErrors) showErrorMessage(e, i18next.t('toast.importWizard.transformFromCRSToCRSError'))
    throw new Error(e)
  }
}

export function * transformFromCRSToCRS (options) {
  const token = yield select(state => getToken(state))
  try {
    const { data: { data: response } } = yield call(axios.post,
      `/transformation/crs_to_crs`, options, {
        headers: getAuthentificationHeader(token),
      },
    )
    return response
  } catch (e) {
    showErrorMessage(e, i18next.t('toast.importWizard.transformFromCRSToCRSError'))
    throw new Error(e)
  }
}

export function * register (url, body, redirect = true, route) {
  try {
    const { data } = yield call(axios.post, url, body)
    if (redirect) history.push(route || routeRegisterSuccess())
    toast.success(i18next.t('toast.user.registerSuccess'))
    return data
  } catch (e) {
    showErrorMessage(e)
    return e
  }
}

export async function getGCPPromise (options) {
  try {
    const coordinate_reference_system = omit(['points', 'pipeline_string', 'epoch'], options)
    const otherFields = keepProps(['points', 'pipeline_string', 'epoch'], options)
    const { data: { data: gcps } } = await axios.post(
      `/transformation/crs_to_wgs84`, {
        ...otherFields,
        coordinate_reference_system,
      },
    )
    return gcps
  } catch (e) {
    // if (displayErrors) showErrorMessage(e, 'Can\'t transform coordinates')
  }
}

export function * getCbor (url) {
  try {
    const { data: cborData } = yield call(axiosCbor.get, url, { data: {}, responseType: 'arraybuffer' })
    const parsedCborData = cbor.decode(cborData)
    return parsedCborData.data
  } catch (e) {
    return []
  }
}
