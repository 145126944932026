import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // getProjects
  getAllProjects: null,
  getAllProjectsLoading: null,
  getAllProjectsSuccess: ['projects'],
  getAllProjectsFailure: ['errorMessage'],
  // getJobs
  getAllJobs: null,
  getAllJobsLoading: null,
  getAllJobsUpdate: ['jobs'],
  getAllJobsSuccess: null,
  getAllJobsFailure: ['errorMessage'],
  // getJobs
  getAllJobsQueried: ['per_page', 'page', 'query'],
  getAllJobsQueriedLoading: null,
  getAllJobsQueriedSuccess: ['jobs', 'pagination'],
  getAllJobsQueriedFailure: ['errorMessage'],
  // getDataFileUploadLogs
  getDataFileUploadLogs: ['startDate', 'endDate'],
  getDataFileUploadLogsLoading: null,
  getDataFileUploadLogsSuccess: ['dataFileUploadLogs'],
  getDataFileUploadLogsFailure: ['errorMessage'],
  // getJobRunLogs
  getJobRunLogs: ['startDate', 'endDate'],
  getJobRunLogsLoading: null,
  getJobRunLogsSuccess: ['jobRunLogs'],
  getJobRunLogsFailure: ['errorMessage'],

  getLogs: ['startDate', 'endDate'],
  getLogsLoading: null,
  getLogsSuccess: ['jobRunLogs', 'dataFileUploadLogs'],
  getLogsFailure: ['errorMessage'],

  getWorkerUsage: ['startDate', 'endDate'],
  getWorkerUsageLoading: null,
  getWorkerUsageSuccess: ['workerUsage'],
  getWorkerUsageFailure: null,
})

export const StatisticsTypes = Types

export default Creators
