// import * as parse from 'csv-parse/lib'
import i18n from 'i18n'
import { isFileNameEndsWith } from 'utils/baseName'
import { getFileFromDropbox, getFile } from './utils'
// import { GenericParserInfo, LogMessage } from "./utils";

/**
 * Checks whether the file name of a file looks like a GCP file name.
 * This is done by looking at the extension and checking whether it is ".gcp".
 */
// function isGcpFileName(fileName: string): boolean {
export function isGcpFileName (fileName) {
  const gcpExtensions = ['.gcp', '.csv', '.txt']
  return gcpExtensions.some(gcpExtension => isFileNameEndsWith(fileName, gcpExtension))
}

export function isGcpFileType (fileType) {
  return fileType === 'gcp'
}

export function isGcpRowParsed (row) {
  return row && typeof row.okay !== 'undefined' ? row.okay : true && (typeof row.errorType === 'undefined' || row.errorType === 'warning')
}

// function shouldGcpFileBeParsed (fileName: string): boolean
export function shouldGcpFileBeParsed (fileName) {
  const parsableExtensions = ['.gcp', '.csv']
  return parsableExtensions.some(extension => isFileNameEndsWith(fileName, extension))
}

// It's just a hacky way to do this, need to make it more clever
const transform = string => string
  // Replace all commas in the quoted strings with `c0mma`
  .replace(/"[^"]+"/g, v => v.replace(/,/g, 'c0mma'))
  .replace(/'[^']+'/g, v => v.replace(/,/g, 'c0mma'))
  // Replace all semicolons in the quoted string with `semic0l0n'
  .replace(/"[^"]+"/g, v => v.replace(/;/g, 'semic0l0n'))
  .replace(/'[^']+'/g, v => v.replace(/;/g, 'semic0l0n'))
const reverseTransform = string => string
  .replace(/c0mma/ig, ',')
  .replace(/semic0l0n/ig, ';')

// function parseGcpFile(file: File): Promise<GcpFileParserInfo>
export async function parseGcpFile (file, blob) {
  return new Promise(resolve => {
    const log = []
    if (file && !isGcpFileName(file.name)) {
      log.push({
        level: 'warning',
        message: `Unsupported file extension in filename "${file.name}"`,
      })
    }
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const text = fileReader.result
      const rows = text
        .split('\n')
        .map(string => {
          // Remove # in text string
          const stringWithoutHash = string.substr(0, 1) + string.substr(1, string.length - 2).replace(/#/g, '') + string.substr(string.length - 1, 1)
          // Replace all commas and semicolons inside quoted strings
          const queue = []
          let tempString = stringWithoutHash
          for (let i = 0; i < tempString.length; i++) {
            const char = tempString[i]
            if (char === '"' || char === '\'') {
              if (queue.length === 0) {
                queue.push({ char, i })
              } else {
                const prevChar = queue[queue.length - 1]
                if (prevChar.char === char) {
                  const stringBetweenQuotes = transform(tempString.slice(prevChar.i, i + 1))
                  queue.pop()
                  tempString = tempString.slice(0, prevChar.i) + stringBetweenQuotes + tempString.slice(i + 1, tempString.length + 1)
                  i = prevChar.i + stringBetweenQuotes.length - 1
                } else {
                  queue.push({ char, i })
                }
              }
            }
          }
          return tempString
        })
      const separatorsByRow = []
      // Calculating the separators for every row
      for (let i = 0; i < rows.length; i++) {
        const rowString = rows[i]
        // Calculating the number of separators in the string (; and ,)
        const numberOfSeparators = (rowString.match(/,/g) || []).length + (rowString.match(/;/g) || []).length
        separatorsByRow.push(numberOfSeparators)
      }
      const filteredSeparatorsNumbers = separatorsByRow.filter(sep => sep > 0).sort((a, b) => a - b)
      const median = filteredSeparatorsNumbers[Math.floor(filteredSeparatorsNumbers.length / 2)]
      const result = []
      /*
      const isEveryRowHasEmptyLastElement = rows.every(row => {
        const rowString = row.trim().replace(/\r\n|\n|\r/gm, '')
        const splittedBySeparators = rowString.split(/,|;/)
        return !splittedBySeparators[splittedBySeparators.length - 1]
      })
      */
      // Actual CSV parsing logic
      const data = []
      let emptyColumns = {}
      for (let i = 0; i < rows.length; i++) {
        let rowString = rows[i]
        rowString = rowString.trim().replace(/\r\n|\n|\r/gm, '')
        const numberOfSeparators = separatorsByRow[i]
        const splittedBySeparators = rowString.split(/,|;/) // (/,| |;/)
        rowString = reverseTransform(rowString)
        data.push({ string: rowString, data: splittedBySeparators, numberOfSeparators })
        // Find columns for which each row has empty value in it
        emptyColumns = splittedBySeparators.reduce((all, column, index) => {
          const isEmptyColumn = typeof all[index] === 'undefined' ? true : all[index]
          return { ...all, [index]: isEmptyColumn ? !column : false }
        }, emptyColumns)
      }
      for (let i = 0; i < data.length; i++) {
        const { string: rowString, data: rowData, numberOfSeparators } = data[i]
        if (!rowString || rowData.every(separatedString => !separatedString)) {
          result.push({
            okay: false,
            type: 'empty',
            data: ' ',
            message: i18n.t('importWizard.groundControlPoints.errors.emptyRow', { line: i }),
          })
        } else if (rowString.startsWith('#')) {
          result.push({
            okay: false,
            type: 'comment',
            data: rowString.slice(1, rowString.length),
            message: i18n.t('importWizard.groundControlPoints.errors.comment', { line: i }),
          })
        } else if (numberOfSeparators === 0) {
          result.push({
            okay: false,
            type: 'null-separators',
            data: rowString,
            message: i18n.t('importWizard.groundControlPoints.errors.separators', { line: i, median, number: numberOfSeparators }),
          })
        } else if (numberOfSeparators !== median) {
          result.push({
            okay: false,
            type: 'not-matched-separators',
            data: rowString,
            message: i18n.t('importWizard.groundControlPoints.errors.separators', { line: i, median, number: numberOfSeparators }),
          })
        } else {
          // Remove empty columns from final data
          // const splittedBySeparators = rowData.reduce((all, column, index) => {
          //   const isEmptyColumn = typeof emptyColumns[index] === 'undefined' ? true : emptyColumns[index]
          //   return isEmptyColumn ? all : [...all, column]
          // }, [])
          /**
           * For now we don't skip columns with empty values because SECore uses original gcp file
           * and column index can be messed up because of this
           */
          const splittedBySeparators = rowData
          // Find all columns with empty values
          const indexes = splittedBySeparators.reduce((allIndexes, str, index) => !str
            ? [...allIndexes, index]
            : allIndexes
          , [])
          // indexes = indexes.filter(index => !(index === splittedBySeparators.length - 1 && isEveryRowHasEmptyLastElement))
          if (indexes.length > 0) {
            result.push({
              okay: true,
              type: 'empty',
              errorType: 'warning',
              data: splittedBySeparators,
              message: i18n.t('importWizard.groundControlPoints.errors.emptyFields', { line: i, indeces: indexes.map(index => index + 1).join(', ') }),
            })
          } else {
            result.push({ okay: true, data: splittedBySeparators.map(string => reverseTransform(string)) })
          }
        }
      }
      resolve({
        result,
        okay: true,
        log,
        fileType: 'gcp',
      })
      // DO NOT DELETE
      /*
      const parsed = Papa.parse(fileReader.result, {
        skipEmptyLines: 'greedy',
        // worker: true,
      })
      console.log(parsed)
      resolve({
        result: parsed.data,
        okay: true,
        log,
        fileType: 'gcp',
        comments: '#',
      })
      const parser = parse({
        comment: '#',
        skip_empty_lines: true,
        skip_lines_with_error: true,
        delimiter: ','
      })
      // Catch any error
      parser.on('error', function(err){
        console.error(err)
      })
      parser.on('skip', function (err) {
        console.log(index)
        console.log(rows[index])
        console.log(err)
        index++
        log.push({
          level: 'error',
          message: err.message,
        })
      })
      // Use the readable stream api
      parser.on('readable', function(){
        let record
        while (record = this.read()) {
          console.log(record)
          index++
          output.push(record)
        }
      })
      parser.on('end', function (err) {
        if (err) {
          log.push({
            level: 'error',
            message: `Error parsing the file "${file.name}": ${err}.`,
          })
          resolve({ okay: false, log, fileType: 'gcp' })
        }
        resolve({
          result: output,
          okay: true,
          log,
          fileType: 'gcp',
        })
      })
      parser.write(text)
      parser.end()
      */
      /*
      const parser = parse({ comment: '#', skip_lines_with_error: true }, (err, output) => {
        if (err) {
          log.push({
            level: 'error',
            message: `Error parsing the file "${file.name}": ${err}.`,
          })
          resolve({ okay: false, log, fileType: 'gcp' })
        }

      })
      */
      // parser.write(text)
      // parser.end()
    }
    fileReader.onerror = () => {
      log.push({
        level: 'error',
        message: `Error reading file "${file.name}".`,
      })
      resolve({ okay: false, log, fileType: 'gcp' })
    }
    fileReader.readAsText(blob || file)
  })
}

/*
export async function parseDropboxGcpFile (file) {
  const response = await axios.get(file.link)
  const blob = new Blob([response.data], {type : 'text/plain'})
  return new Promise(resolve => parseGcpFile(file, blob))
}
*/

export async function parseDropboxGcpFile (file) {
  return getFileFromDropbox(file, undefined, undefined, parseGcpFile)
}

export async function reparseGCPFile (fileLink) {
  return getFile(fileLink, undefined, undefined, parseGcpFile)
}
