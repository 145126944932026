import { createReducer } from 'reduxsauce'
// Project deps
// Local deps
import { ProjectCRSWizardTypes } from './actions'
import { INITIAL_STATE } from './initialState'

export const getRecommendedWizardCRSLoading = (state, { id }) => state.merge({})
export const getRecommendedWizardCRSSuccess = (state, { id, crs }) => state.merge({
  recommendedCRS: {
    ...state.get('recommendedCRS'),
    [id]: {
      vertical: crs.vertical,
      horizontal: crs.horizontal,
    },
  },
})
export const getRecommendedWizardCRSFailure = (state, { id }) => state.merge({})

// Recommended geoids
export const getRecommendedWizardGeoidsLoading = (state, { id }) => state.merge({})
export const getRecommendedWizardGeoidsSuccess = (state, { id, lat, lng, datum, geoids }) => state.merge({
  recommendedGeoids: {
    ...state.get('recommendedGeoids'),
    [id]: geoids,
  },
})
export const getRecommendedWizardGeoidsFailure = (state, { id }) => state.merge({})

export const reducer = createReducer(INITIAL_STATE, {
  [ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_CRS_LOADING]: getRecommendedWizardCRSLoading,
  [ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_CRS_SUCCESS]: getRecommendedWizardCRSSuccess,
  [ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_CRS_FAILURE]: getRecommendedWizardCRSFailure,

  [ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_GEOIDS_LOADING]: getRecommendedWizardGeoidsLoading,
  [ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_GEOIDS_SUCCESS]: getRecommendedWizardGeoidsSuccess,
  [ProjectCRSWizardTypes.GET_RECOMMENDED_WIZARD_GEOIDS_FAILURE]: getRecommendedWizardGeoidsFailure,
})
