import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
// Project deps
import formatSize from 'utils/formatSize'
import { getCompanyDataUsage } from 'modules/companies/selectors'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative',
  },
  progressBar: {
    width: '100%',
    height: 20,
    position: 'relative',
    background: '#eee',
  },
  done: {
    background: props => props.done <= 50
      ? '#4caf50' : props.done <= 75
        ? '#ff9800' : props.done > 75
          ? '#f44336'
          : '#f44336',
    minWidth: ({ isStorageExceeded }) => isStorageExceeded ? '5%' : 0,
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    width: props => `${props.doneWidth}%`,
    maxWidth: '100%',
    color: '#fff',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  doneLabel: {
    position: 'relative',
    zIndex: 3,
  },
  free: {
    position: 'absolute',
    width: ({ doneWidth }) => `${100 - doneWidth}%`,
    height: '100%',
    left: ({ doneWidth }) => `${doneWidth}%`,
  },
  exceeded: {
    height: '100%',
    position: 'absolute',
    left: ({ exceeded }) => `${exceeded}%`,
    top: 0,
    width: ({ exceededWidth }) => `${exceededWidth}%`,
    borderLeft: '1px dashed #d2d0d0',
    background: ({ isStorageExceeded }) => isStorageExceeded ? '#c33c32' : 'transparent',
    zIndex: 2,
    color: '#fff',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxSizeLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 20,
  },
  allSizeLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    bottom: 20,
  },
  minSizeLabel: {
    position: 'absolute',
    left: 0,
    bottom: 20,
  },
  noLabels: {
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    color: ({ isStorageExceeded, doneWidth }) => isStorageExceeded ? '#fff' : doneWidth > 52 ? '#fff' : '#000',
    left: '42%',
    height: 20,
    fontSize: 14,
  },
}))

const StorageProgressBar = props => {
  const { companyId, labels } = props
  const dataUsage = useSelector(state => getCompanyDataUsage(state, companyId))
  const maxFromProps = dataUsage.max_data_size
  const used = dataUsage.stored_data_size

  const max = maxFromProps === -1 ? used + 1024 * 1024 : maxFromProps || 1

  const fullnessPercentage = (used / max) * 100

  const isStorageExceeded = fullnessPercentage > 100

  const difference = used - max
  const d = (difference / used) * 100
  const exceededBarWidth = isStorageExceeded ? d > 95 ? 95 : d : 0
  const fullnessBarWidth = isStorageExceeded ? 100 - exceededBarWidth : fullnessPercentage

  const exceededSize = formatSize(difference)
  const usedSize = formatSize(used)
  const freeSize = formatSize(max - used)
  const maxSize = formatSize(max)

  const classes = useStyles({
    done: fullnessPercentage,
    doneWidth: fullnessBarWidth,
    exceeded: isStorageExceeded ? fullnessBarWidth : 100,
    exceededWidth: exceededBarWidth,
    isStorageExceeded,
  })

  return (
    <div className={classes.container }>
      <div className={classes.progressBar}>
        <Tooltip title={isStorageExceeded ? `${maxSize} max space` : `${usedSize} used space`} placement='top' style={{ zIndex: 1 }}>
          <div className={classes.done}>
            {labels && <div className={classes.doneLabel}>
              {isStorageExceeded ? maxSize : fullnessBarWidth > 4 ? usedSize : ''}
            </div>
            }
          </div>
        </Tooltip>
        {!isStorageExceeded && <Tooltip placement='top' title={`${freeSize} free space`} style={{ zIndex: 1 }}>
          <div className={classes.free}/>
        </Tooltip>
        }
        {difference > 0 && <Tooltip placement='top' title={`${exceededSize} exceeded space`} style={{ zIndex: 1 }}>
          <div className={classes.exceeded}>
            {labels ? fullnessPercentage > 120 ? exceededSize : '' : null}
          </div>
        </Tooltip>
        }
        {labels && <div className={classes.minSizeLabel}>0</div> }
        {labels && <div className={classes.maxSizeLabel} style={{
          ...isStorageExceeded
            ? { left: `${fullnessBarWidth}%` }
            : { right: 0 },
        }}>
          {maxSize}
        </div>
        }
        {labels && isStorageExceeded && <div className={classes.allSizeLabel}>
          {usedSize}
        </div>
        }
        {!labels && !isNaN(fullnessBarWidth) && <div className={classes.noLabels}>
          {fullnessPercentage.toFixed(2)}%
        </div>
        }
      </div>
    </div>
  )
}

StorageProgressBar.propTypes = {
  labels: PropTypes.bool,
  companyId: PropTypes.string,
}

export default StorageProgressBar
