// External dependencies.
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
// Material UI dependencies.
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
// Project internal dependencies.
import AppActions from 'modules/app/actions'
// Local deps
import Navigator from './Navigator'
import Header from './Header'
import { getHeaderHeight } from 'modules/app/selectors'

const useStyles = makeStyles(theme => createStyles({
  ...theme.global,
  root: {
    display: 'flex',
    minHeight: '100vh',
    background: theme.palette.background.main,
  },
  appContent: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: ({ sidebarWidth }) => `calc(100% - ${sidebarWidth}px)`,
    },
  },
  mainContent: {
    height: 'auto',
    flex: 1,
    background: theme.palette.background.main,
  },
}))

const Layout = props => {
  const {
    breadcrumbs,
    tab,
    title,
    children,
    style = {},
    activeUser,
    activeUserId,
    activeCompanyId,
    showAllProjects = false,
    projects,
    projectPage = false,
  } = props
  const dispatch = useDispatch()
  const headerHeight = useSelector(getHeaderHeight)
  const [sidebarWidth, setSidebarWidth] = useState(256)
  // const [headerHeight, setHeaderHeight] = useState(150)
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const classes = useStyles({ sidebarWidth })
  const getConstants = useCallback(() => dispatch(AppActions.getConstants()), [dispatch])
  const changeHeaderHeight = height => dispatch(AppActions.changeHeaderHeight(height))
  useEffect(() => {
    getConstants()
  }, [getConstants])

  // Handlers
  const onSideMenuChange = () => {
    setOpenSideMenu(prevState => !prevState)
  }
  const onChangeHeaderHeight = (width, height) => {
    changeHeaderHeight(height)
  }
  const onChangeSidebarWidth = width => {
    setSidebarWidth(width)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigator
        openSideMenu={openSideMenu}
        onSideMenuChange={onSideMenuChange}
        PaperProps={{ style: { width: sidebarWidth } }}
        headerHeight={headerHeight}
        activeUser={activeUser}
        activeUserId={activeUserId}
        activeCompanyId={activeCompanyId}
        showAllProjects={showAllProjects}
        projects={projects}
        onChangeSidebarWidth={onChangeSidebarWidth}
        drawerWidth={sidebarWidth}
      />
      <div className={classes.appContent}>
        <Header
          onSideMenuChange={onSideMenuChange}
          onChangeHeaderHeight={onChangeHeaderHeight}
          breadcrumbs={breadcrumbs}
          tab={tab}
          title={title}
          activeUserId={activeUserId}
          activeCompanyId={activeCompanyId}
          projectPage={projectPage}
        />
        <main className={classes.mainContent} style={style}>
          {children}
        </main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  projectPage: PropTypes.bool,
  showAllProjects: PropTypes.bool,
  projects: PropTypes.array,
  tab: PropTypes.string,
  activeUserId: PropTypes.string,
  activeCompanyId: PropTypes.string,
  getConstants: PropTypes.func,
  onChangeHeaderHeight: PropTypes.func,
  style: PropTypes.object,
  activeUser: PropTypes.object,
  breadcrumbs: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
}

export default Layout
