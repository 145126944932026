import React from 'react'
import { Trans } from 'react-i18next'
// Material UI
import { Typography } from '@material-ui/core'

const LidarmillUpdateForm = props => {
  return (
    <article style={{ width: '100%' }}>
      <Typography paragraph variant='h6' color='primary' align='center'>
        <Trans i18nKey='lidarmillUpdate.title' />
      </Typography>
      <Typography paragraph>
        <Trans i18nKey='lidarmillUpdate.primaryMessage' components={[
          <b key='important'>Important</b>,
          <a key='link' rel='noopener noreferrer' href='https://us02web.zoom.us/webinar/register/WN_ml9AqRbOSUG0mXp1SxKIfA' target='_blank'>
            <b>link</b>
          </a>,
        ]}/>
      </Typography>
      <Typography paragraph>
        <Trans i18nKey='lidarmillUpdate.secondaryMessage' />
      </Typography>
    </article>
  )
}

export default LidarmillUpdateForm
