import { path } from 'ramda'
import { MissionTemplate } from 'templates/missions'
import { FILES_FIELD, PROTO_ARTIFACT_FIELD } from 'types/importWizard'
import { isCameraArtifact } from 'types/artifacts'
import { filterByMissionId } from 'utils/list'
// Local deps
import ImportWizardActions, { ImportWizardTypes } from './actions'
import { isDropboxFile } from './utils'
/**
 * This middleware responsible for swapping between mission types and keep the same artifacts if they are in
 */
const changeMissionType = async (action, next, store) => {
  const { id, missionType } = action
  const state = store.getState()
  const files = state.importWizard.get(FILES_FIELD.MISSION)
  const protoArtifacts = state.importWizard.get(PROTO_ARTIFACT_FIELD.MISSION)
  const missionFiles = filterByMissionId(id, files)
  const dropboxFiles = missionFiles.filter(file => isDropboxFile(file))
  const otherFiles = missionFiles.filter(file => !isDropboxFile(file))
  let images = []
  // If there is some 'cam' template in new mission-template we keep the uploaded images
  if (path(['cam'], MissionTemplate[missionType])) {
    images = protoArtifacts.reduce((allImages, protoArtifact) =>
      isCameraArtifact(protoArtifact.artifactType)
        ? [...allImages, ...protoArtifact.files]
        : allImages
    , [])
  }
  next(action)
  next(ImportWizardActions.missionFilesAnalyzed([
    ...otherFiles,
    ...dropboxFiles,
    ...images,
  ]))
}

const actions = {
  [ImportWizardTypes.CHANGE_MISSION_TYPE]: changeMissionType,
}

function runAction (action, next, store) {
  const doAction = actions[action.type]
  return (
    doAction
      ? doAction(action, next, store)
      : next(action)
  )
}

export const importWizardMiddleware = store => next => action => {
  return runAction(action, next, store)
}
