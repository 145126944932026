import { CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
export const Loader = () => {
  const styles = useStyles()
  return (
    <div className={styles.root}>
      <CircularProgress size={80} />
    </div>
  )
}
