import uuid from 'uuid'
import { mergeDeepRight } from 'utils/dict'
import { extractFileProperties } from 'utils/fileProperties'
// export interface ProtoArtifactReducerState {
//   files: AnalyzedFile[]
//   protoArtifacts: ProtoArtifact[]
// }

// type ProtoArtifactReducer = (state: ProtoArtifactReducerState) => ProtoArtifactReducerState
// export function createProtoArtifact(
//   artifactType: ArtifactType,
//   files?: AnalyzedFile[],
//   createdFrom?: string,
//   fileSuggestions?: string[],
//   properties?: Object
// )
export function createProtoArtifact (
  artifactType,
  files,
  options = {},
) {
  const { createdFrom, fileSuggestions, properties, missionId } = options
  const fileSuggestionsNonNull = fileSuggestions || []
  const fileSuggestionsWithoutPaths = fileSuggestionsNonNull.map(
    filename => {
      const splittedPath = filename.split('/')
      return splittedPath[splittedPath.length - 1]
    },
  )
  return {
    id: uuid.v4(),
    name: '',
    artifactType,
    files: files || [],
    createdFrom,
    fileSuggestions: fileSuggestionsWithoutPaths,
    missionId,
    properties: mergeDeepRight(
      typeof properties !== 'undefined' ? properties : {},
      { fileProperties: extractFileProperties(files || []) },
    ),
  }
}
