import { Map } from 'immutable'
import { FILES_FIELD, PROTO_ARTIFACT_FIELD } from '../../types/importWizard'

export const INITIAL_STATE = Map({
  demoCrsTransform: {},
  recommendedCRS: {},
  gcps: {},
  pipelineStrings: {},
  gcpsLoading: {},
  checkUnits: {},
  crsInfo: {},

  analyzing: false,
  projectId: null,
  step: 0,
  missions: [],
  currentMissionId: null,
  plp: {},
  errors: {},

  confirmIsLoading: {},
  // files and protos used when we edit the mission
  editMissionId: null,
  editMission: {},
  editDialogOpen: false,
  editMissionArtifacts: [],
  editMissionProtoFiles: [],
  editMissionProtoArtifacts: [],
  editMissionFiles: [],

  // missions
  [FILES_FIELD.MISSION]: [],
  [PROTO_ARTIFACT_FIELD.MISSION]: [],
  // gcps
  [FILES_FIELD.GCP]: [],
  [PROTO_ARTIFACT_FIELD.GCP]: [],
  [FILES_FIELD.EDIT_GCP]: [],
  [PROTO_ARTIFACT_FIELD.EDIT_GCP]: [],
  // ref stations
  [FILES_FIELD.REF]: [],
  [PROTO_ARTIFACT_FIELD.REF]: [],
  // non missions
  [FILES_FIELD.NON_MISSION]: [],
  [PROTO_ARTIFACT_FIELD.NON_MISSION]: [],
  // non missions
  [FILES_FIELD.DOCUMENTATION]: [],
  [PROTO_ARTIFACT_FIELD.DOCUMENTATION]: [],
  // polygons
  [FILES_FIELD.POLYGON]: [],
  [PROTO_ARTIFACT_FIELD.POLYGON]: [],

  // pipeline settings files
  settingsFiles: [],
  settingsFilesProcessingType: 'sessions',

  lastDropboxPath: '',

  showCameraArtifactsMapping: false,
  dropboxFolders: [],
  cameraFolders: [],
  keptImages: {},
  keptPfsFiles: {},
  systemPfsFiles: {},
  systemKeptImages: {},
  systemFolders: [],
  predefineSystemFolder: {},
  predefineDropboxFolder: {},
})
