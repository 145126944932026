import React from 'react'
import { Trans } from 'react-i18next'
import { CircularProgress, Chip } from '@material-ui/core'
/*
import SuccessIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Close'
import WaitingIcon from '@material-ui/icons/AccessTime'
*/
import { JobRunState, PrettyJobRunState } from 'types/jobRuns'
import { theme } from 'HOC/withRoot'

export const getJobRunStateColor = status => {
  return theme.palette.jobRunStateColor[status] || 'inherit'
}

/**
 * Returns the icon based on the job-run state
 * @param {*} status
 * @param {*} color - custom color
 */
export function getJobRunStateIcon (status, value) {
  const style = {
    backgroundColor: getJobRunStateColor(status),
    color: '#fff',
    height: 20,
    borderRadius: 6,
    marginRight: 4,
  }
  const isPipelineInAction =
    status === JobRunState.RUNNING ||
    status === JobRunState.CANCELING
  const isJobRunScheduled =
    status === JobRunState.SCHEDULED
  return <Chip
    style={style}
    label={
      <React.Fragment>
        {isPipelineInAction && <CircularProgress color='inherit' size={12} style={{ marginRight: 2, verticalAlign: 'middle' }}/>}
        {isJobRunScheduled
          ? value
            ? <Trans
              i18nKey={`pipelineWizard.artifactStatus.${status}`}
              values={{ time: value }}
            />
            : prettifyJobRunState(status)
          : prettifyJobRunState(status)
        }
      </React.Fragment>
    }
    size='small'
  />
  /*
  if (status === JobRunState.WAITING) {
    return <WaitingIcon style={{ color: color || 'grey' }} />
  }
  if (status === JobRunState.RUNNING) {
    return <CircularProgress size={18} style={{ color: color }} />
  }
  if (status === JobRunState.SUCCESS) {
    return <SuccessIcon style={{ color: color || 'green' }} />
  }
  if (status === JobRunState.FAILURE) {
    return <CancelIcon style={{ color: color || 'red' }} />
  }
  if (status === JobRunState.CANCELING) {
    return <CancelIcon style={{ color: color || 'grey' }} />
  }
  if (status === JobRunState.CANCELED) {
    return <CancelIcon style={{ color: color || 'orange' }} />
  }
  if (status === JobRunState.WAITING_FOR_ARTIFACT) {
    return <WaitingIcon style={{ color: color || 'orange' }} />
  }
  if (status === JobRunState.SCHEDULED) {
    return <WaitingIcon style={{ color: color || 'orange' }} />
  }
  */
}

export const isJobRunSuccess = jobRunState => {
  return jobRunState === JobRunState.SUCCESS
}
export const isJobRunFailed = jobRunState => {
  return jobRunState === JobRunState.FAILURE
}
export const isJobRunCanceled = jobRunState => {
  return jobRunState === JobRunState.CANCELED
}

export function prettifyJobRunState (state) {
  return PrettyJobRunState[state] || state
}
