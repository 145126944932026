import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
// Material UI
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
// Project deps
import TermsAndConditions from 'components/TermsAndConditions'

const AcceptTermsDialog = props => {
  const { open = false, onClose, onSubmit } = props
  return (
    <Dialog
      maxWidth='lg'
      open={open}
    >
      <DialogContent style={{ flexDirection: 'column' }}>
        <Trans i18nKey='acceptTermsAndConditionsDialog.message'/>
        <TermsAndConditions/>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans i18nKey='acceptTermsAndConditionsDialog.cancel'/>
        </Button>
        <Button onClick={onSubmit} color='secondary'>
          <Trans i18nKey='acceptTermsAndConditionsDialog.confirm'/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AcceptTermsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default AcceptTermsDialog
