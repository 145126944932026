export const artifactState = {
  dataFiles: [],
  dataDirectories: [],
  deleteArtifactStatus: {},
  artifactLoading: {},
  completeDataDirectoryLoading: {},
  deleteDataDirectoryFileLoading: {},
  exportCalibrationLoading: {},
  exportCalibrationErrors: {},
  exportCalibrationWarnings: {},
  addCalibrationLoading: false,
  setPointcloudSettingsLoading: false,
  fixingAntennaMismatchLoading: false,
  updateArtifactPosesStatus: {},
}
