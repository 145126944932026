
export function convertRawDataFileUploadLog (raw) {
  const {
    id,
    user_id,
    upload_size,
    upload_duration,
    started_at,
    finished_at,
    created,
    updated,
  } = raw
  return {
    id,
    userId: user_id,
    uploadSize: upload_size,
    uploadDuration: upload_duration,
    startedAt: new Date(started_at),
    finishedAt: typeof finished_at === 'string' ? new Date(finished_at) : undefined,
    created: new Date(created),
    updated: new Date(updated),
  }
}
