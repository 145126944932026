import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  putGetArtifact: ['artifact'],
  putGetArtifacts: ['artifacts'],
  putGetArtifactLoading: ['artifactId'],
  putGetArtifactError: ['artifactId'],

  putCreateArtifact: ['artifact'],
  putCreateArtifactError: ['artifact'],
  putUpdateArtifact: ['artifact'],

  // Data Directories
  putGetDataDirectory: ['dataDirectory'],
  putGetDataDirectoryLoading: ['dataDirectoryId'],
  putGetDataDirectoryError: ['dataDirectoryId'],

  putListDataFiles: ['dataFiles', 'artifactId'],
  putListDataFilesLoading: ['artifactId'],
  putListDataFilesError: ['artifactId', 'error'],

  putCompleteArtifact: ['artifactId'],
  putCompleteArtifactLoading: ['artifactId'],
  putCompleteArtifactError: ['artifactId', 'error'],

  putCreateDataDirectoryLoading: ['artifactId'],
  putCreateDataDirectory: ['dataDirectory'],
  putCreateDataDirectoryError: ['artifactId', 'error'],

  getDataFiles: ['dataFiles', 'artifactId'],
  getDataFilesLoading: ['artifactId'],
  getDataFilesError: ['artifactId', 'error'],

  // create artifact for a project
  createArtifact: ['projectId', 'name', 'artifactType', 'properties', 'missionId'],
  createArtifactLoading: null,
  createArtifactSuccess: ['artifact'],
  createArtifactFailure: ['errorMessage'],

  // deleteArtifact
  deleteArtifact: ['artifactId', 'force'],
  deleteArtifactLoading: ['artifactId'],
  deleteArtifactSuccess: ['artifactId'],
  deleteArtifactFailure: ['artifactId', 'errorMessage'],

  // delete artifacts
  deleteArtifacts: ['artifactIds'],

  // updateArtifact
  updateArtifact: ['artifactId', 'name', 'properties', 'withSuccessMessage'],
  updateArtifactLoading: ['artifactId'],
  updateArtifactSuccess: ['artifactId', 'artifact'],
  updateArtifactFailure: ['errorMessage'],

  // deleteDataFile
  deleteDataFile: ['dataFileId', 'dataFile', 'artifact'],
  deleteDataFileLoading: null,
  deleteDataFileSuccess: ['dataFileId'],
  deleteDataFileFailure: ['errorMessage'],

  // deleteDataDirectoryFile
  deleteDataDirectoryFile: ['artifactId', 'dataDirectoryId', 'link'],
  deleteDataDirectoryFileLoading: ['link'],
  deleteDataDirectoryFileSuccess: ['artifactId', 'dataDirectoryId', 'link'],
  deleteDataDirectoryFileFailure: ['link', 'errorMessage'],

  // uncompleteDataDirectory
  setCompleteDataDirectory: ['dataDirectoryId', 'completed'],
  setCompleteDataDirectoryLoading: ['dataDirectoryId'],
  setCompleteDataDirectorySuccess: ['dataDirectoryId', 'dataDirectory'],
  setCompleteDataDirectoryFailure: ['dataDirectoryId', 'errorMessage'],

  // getArtifactData
  getCurrentArtifact: ['artifactId'],
  getCurrentArtifactData: ['artifactId', 'withoutLoading'],
  getCurrentArtifactDataLoading: null,
  getCurrentArtifactDataSuccess: [
    'artifactId',
    'artifact',
    'input_pipelines',
    'output_pipelines',
    'dataDirectories',
    'dataFiles',
  ],
  getCurrentArtifactDataFailure: ['errorMessage'],

  updateProjectArtifactsState: ['projectId', 'fullReplace'],
  updateProjectArtifactsStateSuccess: ['artifacts', 'fullReplace', 'projectId'],
  updateProjectArtifactsStateFailure: ['projectId'],

  copyArtifact: ['projectId', 'oldProjectId', 'artifactId', 'artifact', 'onSuccess'],
  copyArtifactLoading: null,
  copyArtifactSuccess: ['artifact'],
  copyArtifactFailure: null,

  getArtifact: ['artifactId', 'onSuccess', 'onError'],
  getArtifactLoading: ['artifactId'],
  getArtifactFailure: ['artifactId', 'errorMessage'],

  getArtifacts: ['artifactIds'],
  getArtifactsLoading: ['artifactIds'],
  getArtifactsFailure: ['artifactIds', 'errorMessage'],
  getArtifactsSuccess: ['artifacts'],

  exportCalibration: ['artifactId', 'serial', 'data', 'successCallback'],
  exportCalibrationLoading: ['artifactId'],
  exportCalibrationFailure: ['artifactId', 'errors'],
  exportCalibrationSuccess: ['artifactId'],

  validateCalibration: ['artifactId', 'serial', 'data', 'successCallback'],
  validateCalibrationLoading: ['artifactId'],
  validateCalibrationFailure: ['artifactId', 'errors', 'warnings'],
  validateCalibrationSuccess: ['artifactId', 'warnings'],

  setPointcloudSettings: ['artifactId', 'fieldsMapping', 'crsValues', 'lasSettings', 'shouldRestart', 'onSuccess'],
  setPointcloudSettingsLoading: null,
  setPointcloudSettingsFailure: null,
  setPointcloudSettingsSuccess: ['artifact'],

  downloadArtifact: ['artifactId', 'dataDirectoryId'],
  downloadDataDirectoryFiles: ['dataDirectoryId', 'urls'],

  fixAntennaMismatch: ['artifactId', 'data', 'onSuccess'],
  fixAntennaMismatchLoading: ['artifactId'],
  fixAntennaMismatchFailure: ['artifactId'],
  fixAntennaMismatchSuccess: ['artifactId', 'artifact'],

  updateArtifactPoses: ['artifactId'],
  updateArtifactPosesLoading: ['artifactId'],
  updateArtifactPosesFailure: ['artifactId'],
  updateArtifactPosesSuccess: ['artifactId', 'error'],
})

export const ArtifactsTypes = Types
export default Creators
