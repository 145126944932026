import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  selectedCRS: null,
  genericCRS: null,
  isPredefined: false,
  useDefaultCRS: false,
  useProjString: false,
  isCRSLoading: false,
})
