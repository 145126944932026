import { all, call, put, takeLatest, fork, select } from 'redux-saga/effects'
import AppActions, { AppTypes } from './actions'
import { isConstantsLoaded } from './selectors'
import axios, { axiosResources, axiosAccountsClient, axiosAccounts } from 'utils/axios'
import { getErrorMessage } from 'utils/api'
import { getCbor } from 'modules/utils'

// constants
function * getConstants () {
  const constantsLoaded = yield select(state => isConstantsLoaded(state))
  if (!constantsLoaded) {
    yield put(AppActions.getConstantsLoading())
    try {
      const [
        { data: { data: constants } },
        { data: { data: geoids } },
        coordinateSystems,
        { data: { data: units } },
        { data: { data: salesProducts } },
      ] = yield all([
        call(axios.get, '/constants/all'),
        call(axios.get, '/constants/vertical_geoids'),
        call(getCbor, '/constants/crs'),
        call(axios.get, '/constants/crs_units'),
        call(axiosAccounts, '/sales_products'),
      ])
      yield put(AppActions.getConstantsSuccess(
        constants.countries,
        constants.industries,
        constants.time_zones,
        constants.artifacts_filetypes,
        constants.epsg_datums,
        constants.ie_datums,
        coordinateSystems,
        units,
        geoids,
        constants.datum_epochs,
        constants.classifications,
        salesProducts
      ))
      yield put(AppActions.getAntennas())
    } catch (e) {
      yield put(AppActions.getConstantsFailure(getErrorMessage(e)))
    }
  }
}

function * getProducts () {
  yield put(AppActions.getProductsLoading())
  try {
    const { data: products } = yield call(axiosAccountsClient.get, `/products`)
    console.log(products)
    yield put(AppActions.getProductsSuccess(products))
  } catch (e) {
    yield put(AppActions.getProductsFailure(getErrorMessage(e)))
  }
}

export function * getAntennas () {
  yield put(AppActions.getAntennasLoading())
  try {
    const { data: antennas } = yield call(axiosResources.get, '/antennas/?exclude=calibrations_antex,calibrations_antinfo,updated,created')
    yield put(AppActions.getAntennasSuccess(antennas))
  } catch (e) {
    yield put(AppActions.getAntennasFailure())
  }
}

export function * getAllSystemTypes () {
  yield put(AppActions.getAllSystemTypesLoading())
  try {
    const { data: { data: systemTypes } } = yield call(axiosAccounts.get, '/system_types')
    yield put(AppActions.getAllSystemTypesSuccess(systemTypes))
  } catch (e) {
    yield put(AppActions.getAllSystemTypesFailure())
  }
}

export function * getRecommendedDatums ({ lat, lng }) {
  yield put(AppActions.getRecommendedDatumsLoading())
  try {
    const { data: { data: datums } } = yield call(axios.get, `/constants/epsg_datums?lat=${lat}&lon=${lng}&local=true&auth_name=epsg`)
    yield put(AppActions.getRecommendedDatumsSuccess(lat, lng, datums))
  } catch (e) {
    yield put(AppActions.getRecommendedDatumsFailure())
  }
}

export function * getGCPTemplate () {
  yield put(AppActions.getGCPTemplateLoading())
  try {
    const { data: template } = yield call(axios.get, `/gcp_template/`)
    yield put(AppActions.getGCPTemplateSuccess(template))
  } catch (e) {
    yield put(AppActions.getGCPTemplateFailure())
  }
}

function * getConstantsWatcher () {
  yield takeLatest(AppTypes.GET_CONSTANTS, getConstants)
}

function * getAntennasWatcher () {
  yield takeLatest(AppTypes.GET_ANTENNAS, getAntennas)
}

function * getAllSystemTypesWatcher () {
  yield takeLatest(AppTypes.GET_ALL_SYSTEM_TYPES, getAllSystemTypes)
}

function * getRecommendedDatumsWatcher () {
  yield takeLatest(AppTypes.GET_RECOMMENDED_DATUMS, getRecommendedDatums)
}

function * getGCPTemplateWatcher () {
  yield takeLatest(AppTypes.GET_GCP_TEMPLATE, getGCPTemplate)
}

function * getProductsWatcher () {
  yield takeLatest(AppTypes.GET_PRODUCTS, getProducts)
}

export default function * root () {
  yield fork(getConstantsWatcher)
  yield fork(getAntennasWatcher)
  yield fork(getAllSystemTypesWatcher)
  yield fork(getRecommendedDatumsWatcher)
  yield fork(getGCPTemplateWatcher)
  yield fork(getProductsWatcher)
}
