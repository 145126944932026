import { isLocalEnvironment } from 'config'
import { getStorageItem } from './localStorage'
import Cookies from 'js-cookie'
import { cookieName } from './cookies'

const DEFAULT_PERMISSIONS = {
  delete: false,
  update: false,
  add: false,
  read: false,
}
export const getUserArtifactPermissions = user => {
  return user ? user.artifactPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserProjectPermissions = user => {
  return user ? user.projectPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserPipelinePermissions = user => {
  return user ? user.pipelinePermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserMissionPermissions = user => {
  return user ? user.missionPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserProjectCRSPermissions = user => {
  return user ? user.projectCRSPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getFullUserName = (user, withEmail = false) => {
  return user.first_name + ' ' + user.last_name + (withEmail ? ` (${user.email})` : '')
}

export const getAddress = data => {
  return [
    data.city.replace(/,/, '').trim(),
    data.street.replace(/,/, '').trim(),
    data.state.replace(/,/, '').trim(),
    data.zip.replace(/,/, '').trim(),
  ].join(', ')
}

export const getSystemSerialNumber = data => {
  return data.systemSerial.map(serialNumber => serialNumber.replace(/,/, '').trim()).join(', ')
}

export const getUserAuthToken = () => {
  let token
  if (isLocalEnvironment()) {
    token = getStorageItem('userToken')
  } else {
    const usersData = JSON.parse(localStorage.getItem('persist:root'))
    if (usersData) {
      const userData = JSON.parse(usersData.users);
      token = JSON.parse(userData).data.token
    } else {
      token = Cookies.get(cookieName)
    }
  }
  return token
}