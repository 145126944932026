import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { UrlTypes } from './actions'
import { sendGA } from 'utils/ga'

export const urlChange = (state, { url }) => {
  sendGA(url)
  return state.merge({
    url,
    prevUrl: !state.get('url') ? state.get('prevUrl') : state.get('url'),
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [UrlTypes.CHANGE_URL]: urlChange,
})
