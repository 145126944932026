// Project deps
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import { DataSetTypeMapping, DataSetTypeOptions, DataSetTypeInitialValue } from './utils'
import { MapBackendNameToFrontend } from 'templates/constants'

// lidar sensor models
const sensorModels = [
  'Velodyne HDL 32E',
  'Velodyne VLP 32C',
  'Velodyne VLP 16',
  'Riegl VUX-1UAV',
  'Riegl VUX-1UAV22',
  'Riegl VUX-1LR',
  'Riegl VUX-1LR22',
  'Riegl VUX-1HA',
  'Riegl VUX-1HA22',
  'Riegl VUX-120',
  'Riegl VUX-120-23',
  'Riegl VUX-160-23',
  'GeoLas ELMAP15',
  'GeoLas ELMAP30',
  'GeoLas ELMAP15-V',
  'GeoLas ELMAP30-V',
  'Riegl VQ-840-G',
  'Riegl VUX-240',
  'Quanergy M1',
  'Generic LAS',
  'Quanergy M8',
  'Riegl miniVUX-1UAV',
  'Riegl miniVUX-2UAV',
  'Riegl miniVUX-3UAV',
  'Pioneer P10',
  'Ouster OS1 64',
  'Ouster OS2 64',
  'Pioneer P90',
  'Hesai Pandar64',
  'Velodyne VLP16 Hi-Res',
  'Livox Horizon',
  'Livox Avia',
  'Livox Mid40',
  'Optech CL-360HD',
  'Optech CL-360XR',
  'Hesai XT32',
  'Hesai XT32M2X',
  'Generic PCAP',
  'Wingtra LiDAR',
]

/**
 * Used to map selected sensor model from {@link sensorModels} to some arbitrary value (which will be send to the backend).
 * In case the mapping doesn't exist the original value will be send to the backend.
 */
const mapSensorModelToBackendName = {
  'Wingtra LiDAR': 'Hesai XT32M2X',
}

export default {
  // Sensor model is a string.
  // And some sensor models could be mapped to another string e.g.: "Wingtra LiDAR" should be "Hesai XT32M2X" since that is what the "Wingtra LiDAR" technically is
  // This variable will store the original value of the selected sensor to reproduce the original "Wingtra LiDAR" selected sensor while cloning the pipeline.
  sensor_model_original: {
    name: '',
    dataType: DataType.STRING,
    initialValue: '',
    optional: true,
    backendTransform: (original, state, formValues) => formValues.sensor_model,
    sendToBackend: true,
    invisible: true,
  },
  sensor_model: {
    name: 'Sensor model',
    dataType: DataType.AUTOCOMPLETE,
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        console.log(getCloneJobOptionValue(state, artifactId, options, 'sensor_model_original'))
        const sensor_model = getCloneJobOptionValue(state, artifactId, options, 'sensor_model_original') || getCloneJobOptionValue(state, artifactId, options, 'sensor_model')
        if (sensor_model) return sensor_model
      }
      return null
    },
    options: sensorModels,
    backendTransform: (original, state, formValues) => mapSensorModelToBackendName[original] || original,
    sendToBackend: true,
    gridProps: { xs: 12, sm: 12, md: 12, lg: 12 },
  },
  dataset_type: {
    name: MapBackendNameToFrontend.dataset_type,
    dataType: DataType.SELECTION,
    options: DataSetTypeOptions,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'dataset_type',
      DataSetTypeInitialValue,
    ),
    mapping: DataSetTypeMapping,
  },
}
