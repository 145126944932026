import i18n from 'i18n'
import { getUTCDate } from 'utils/dateTime'

export const PipelineWizardType = {
  NAVLAB: 'navlab',
  NAVLAB_APPLANIX: 'navlab_applanix',
  SPATIAL_FUSER: 'sf',
  CLASSIFICATION: 'classification',
  POINTCLOUD_OPTIMIZATION: 'pointcloud_optimization',
  ORTHOIMAGE_GENERATION: 'orthoimage_generation',
}
/**
 * The stage of the creation of a `Pipeline`.
 */
export const PipelineCreationStage = {
  /**
   * The `Pipeline` object is being created.
   */
  CREATE_PIPELINE: 'create_pipeline',
  /**
   * The jobs are being created.
   */
  CREATE_JOBS: 'create_jobs',
  /**
   * The artifacts are being created.
   */
  CREATE_ARTIFACTS: 'create_artifacts',
  /**
   * The job ios are being created.
   */
  CREATE_JOB_IOS: 'create_job_ios',
  /**
   * The job runs are being created.
   */
  CREATE_JOB_RUNS: 'create_runs',
  /**
   * All the objects in the `Pipeline` have been created.
   * It can now be finalized.
   */
  FINALIZE_PIPELINE: 'finalize_pipeline',
  /**
   * The procedure has finished.
   */
  DONE: 'done',
}
const PipelineCreationStageKeys = Object.keys(PipelineCreationStage)

export const CreationStageValues = PipelineCreationStageKeys.reduce((res, key, index) => {
  return {
    ...res,
    [PipelineCreationStage[key]]: (100 / (PipelineCreationStageKeys.length - 1)) * (index + 1),
  }
}, {})

export const errorsMap = PipelineCreationStageKeys.reduce((allErrors, key) => {
  const value = PipelineCreationStage[key]
  return {
    ...allErrors,
    [value]: i18n.t(`pipelineWizard.errors.${value}`),
  }
}, {})
export const stageText = PipelineCreationStageKeys.reduce((allErrors, key) => {
  const value = PipelineCreationStage[key]
  return {
    ...allErrors,
    [value]: i18n.t(`pipelineWizard.success.${value}`),
  }
}, {})

export function convertRawPipeline (pipeline) {
  const {
    created,
    updated,
  } = pipeline
  return {
    ...pipeline,
    created: typeof created === 'string' ? getUTCDate(created) : undefined,
    updated: typeof updated === 'string' ? getUTCDate(updated) : undefined,
  }
}

/**
 * Converts raw recursive pipeline by adding job, pipeline and project ids to the job runs, job ios and job itself
 * @note Nazar removed job: { id } from the job runs and job ios so this should be removed completely by getting job id from props or state, but not from job run or job io
 *       Requires some work to do. So by now it's okay.
 * @param {RecursivePipeline} pipeline
 * @returns {ConvertedRecursivePipeline}
 */
export function convertRawRecursivePipeline (pipeline) {
  return {
    ...pipeline,
    jobs: (pipeline.jobs || []).map(job => ({
      ...job,
      pipeline: { id: pipeline.id, project: { id: pipeline.project.id } },
      job_runs: (job.job_runs || []).map(job_run => ({ ...job_run, job: { id: job.id, job_type: job.job_type } })),
      job_ios: (job.job_ios || []).map(job_io => ({ ...job_io, job: { id: job.id } })),
    })),
  }
}
