import { omit } from 'ramda'
import moment from 'utils/moment'
// Project deps
// Local deps
import { DataType } from 'types/form'
import { getProjectCRSInitialValues } from './ProjectCRS/utils'
import { getBackendTransformedValues } from 'utils/templates'
import { ProjectCRSTemplateInfo } from './ProjectCRS/ProjectCRSTemplateInfo'
import { getClassificationsValues, getLasTemplateFieldInitialValue } from 'templates/utils'
import { LAS_BACKEND_NAME, MapBackendNameToFrontend } from 'templates/constants'
import { LasTimeFormat, MapPrettyLasTimeFormatToTimeFormat, PrettyLasTimeFormat } from 'modules/upload/file-types'
import { LasSettingsTemplate } from './LasSettingsTemplate'
import { getClassifications } from 'modules/app/selectors'

export const LasCRSTemplate = {
  [LAS_BACKEND_NAME]: {
    name: MapBackendNameToFrontend.coordinate_reference_system,
    dataType: DataType.CRS,
    optional: false,
    title: false,
    initialValue: (state, extra, { extraProps }) => {
      const { projectCRS } = extraProps
      if (projectCRS) {
        const crs = getProjectCRSInitialValues(state, projectCRS)
        return crs
      }
      return null
    },
    backendTransform: (original, state, values) => {
      return omit(['name'], getBackendTransformedValues(state, original, original, ProjectCRSTemplateInfo))
    },
  },
}

export const LasVisualizationSettings = {
  default_material: {
    name: 'Default material',
    variant: 'outlined',
    dataType: DataType.SELECTION,
    optional: true,
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('default_material', extraProps),
    options: [
      'None',
      'RGB',
      'Elevation',
      'Classification',
      'Point Source ID',
      'GPSTime',
      'Scan Angle Rank',
      'Intensity',
      'Reflectance',
    ],
  },
  point_size: {
    name: 'Point size',
    dataType: DataType.SLIDER,
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('point_size', extraProps),
    min: 0.00,
    max: 5.00,
    step: 0.01,
    marks: [
      {
        value: 0,
        label: '0',
      },
      {
        value: 2.5,
        label: '2.5',
      },
      {
        value: 5,
        label: '5',
      },
    ],
  },
  point_budget: {
    name: 'Point budget',
    dataType: DataType.SLIDER,
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('point_budget', extraProps),
    min: 0,
    max: 10,
    step: 1,
    marks: [
      {
        value: 0,
        label: '0',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 10,
        label: '10',
      },
    ],
  },
  enable_edl: {
    name: 'Enable EDL',
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('enable_edl', extraProps),
  },
  enable_curves: {
    name: 'Enable curves display',
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('enable_curves', extraProps),
  },
  enabled_classes: {
    name: 'Enabled classes',
    variant: 'outlined',
    dataType: DataType.MULTI_SELECTION,
    renderValue: (state, selected) => {
      const classifications = getClassifications(state)
      return selected.map(id => `${classifications[id]}(${id})`).join(' / ')
    },
    options: (state, formValues, extra) => {
      const { classifications } = getClassificationsValues(state, extra)
      return Object.keys(classifications).map(key => ({ value: key, label: classifications[key] }))
    },
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('enabled_classes', extraProps, state, extra),
  },
}
export const LasSettingsFieldNames = ['time_format', 'acquisition_date', 'merge_files']
export const LasVisualizationSettingsFieldsNames = ['enabled_classes', 'enable_curves', 'enable_edl', 'default_material', 'point_size', 'point_budget']
export const LasFieldsMappingFieldsNames = ['psid', 'user_data']
export const LasVisualizationSettingsFields = Object.keys(LasVisualizationSettings)

export const LasPointcloudSettingsWithout = {
  time_format: {
    name: 'Time format',
    dataType: DataType.SELECTION,
    optional: true,
    options: [
      PrettyLasTimeFormat.none,
      PrettyLasTimeFormat.standard,
      PrettyLasTimeFormat.adjusted,
      PrettyLasTimeFormat.day,
      PrettyLasTimeFormat.week,
      PrettyLasTimeFormat.microseconds,
    ],
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('time_format', extraProps),
    variant: 'outlined',
    mapping: MapPrettyLasTimeFormatToTimeFormat,
    getValue: (state, formValue, values, extra, extraProps) => {
      return PrettyLasTimeFormat[formValue] || ''
    },
  },
  acquisition_date: {
    name: 'Please select the UTC date at which the acquisition of this data started.',
    placeholder: 'Acquisition date',
    dataType: DataType.TIME_OF_WEEK,
    variant: 'outlined',
    optional: (state, values) => {
      return !(values.time_format === LasTimeFormat.TimeOfWeek || values.time_format === LasTimeFormat.SecondsIntoUtcDay)
    },
    invisible: (state, values) => {
      return !(values.time_format === LasTimeFormat.TimeOfWeek || values.time_format === LasTimeFormat.SecondsIntoUtcDay)
    },
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('acquisition_date', extraProps),
    backendTransform: (original, state, values) => {
      if (values.time_format === LasTimeFormat.TimeOfWeek || values.time_format === LasTimeFormat.SecondsIntoUtcDay) {
        return moment.utc(original).format()
      }
      return null
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  ...LasSettingsTemplate,
}

export const LasPointcloudSettings = {
  number_of_files: {
    dataType: DataType.INTEGER,
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('number_of_files', extraProps),
    invisible: true,
    sendToBackend: false,
    optional: true,
  },
  merge_files: {
    name: 'Merge files',
    dataType: DataType.BOOLEAN,
    // initialValue: false,
    tooltip: 'If enabled, all LAS/LAZ files will be merged into a single pointcloud. This can be useful when you are uploading a pointcloud separated into tiles.',
    position: 'flex-end',
    optional: true,
    initialValue: (state, extra, { extraProps }) => getLasTemplateFieldInitialValue('merge_files', extraProps),
    invisible: (state, values, extra, formTemplate, option, options) => {
      return values.number_of_files <= 1
    },
    backendTransform: (original, state, values) => {
      if (values.number_of_files > 1) {
        return original
      }
      return null
    },
  },
  text: {
    name: 'warning',
    variant: 'text',
    dataType: DataType.TEXT,
    initialValue: getLasTemplateFieldInitialValue('text'),
    invisible: false,
    sendToBackend: false,
  },
  ...LasPointcloudSettingsWithout,
}

export const LasPointcloudSettingsFields = Object.keys(LasPointcloudSettings)

export const LasAllSettingsTemplate = {
  ...LasPointcloudSettings,
  ...LasSettingsTemplate,
  ...LasCRSTemplate,
  ...LasVisualizationSettings,
}
