import { createReducer } from 'reduxsauce'
import { omit } from 'ramda'
import { merge } from 'lodash'
// Project deps
// Local deps
import { ProjectWizardTypes } from './actions'
import { INITIAL_STATE } from './initialState'
import { addUniqueKeyToCRS } from 'templates/CRS/ProjectCRS/utils'

// Get generic CRS
export const getGenericCRSLoading = state => state.merge({
  isCRSLoading: true,
})
export const getGenericCRSSuccess = (state, { crs }) => {
  return state.merge({
    genericCRS: addUniqueKeyToCRS(omit(['name'], crs)),
    isCRSLoading: false,
  })
}
export const getGenericCRSFailure = state => state.merge({
  isCRSLoading: false,
})

// Get predefined CRS
export const getPredefinedCRSLoading = (state, { crsId }) => state.merge({
  isCRSLoading: true,
})
export const getPredefinedCRSSuccess = (state, { crsId, crs }) => {
  return state.merge({
    selectedCRS: addUniqueKeyToCRS(merge(state.get('selectedCRS'), crs)),
    isCRSLoading: false,
  })
}
export const getPredefinedCRSFailure = (state, { crsId }) => state.merge({
  isCRSLoading: false,
})

export const setCRS = (state, { crs, isPredefined, useDefaultCRS, useProjString }) => state.merge({
  selectedCRS: addUniqueKeyToCRS(crs),
  isPredefined,
  useDefaultCRS,
  useProjString,
})

export const resetProjectWizardState = state => state.merge({
  ...INITIAL_STATE.toObject(),
})

export const reducer = createReducer(INITIAL_STATE, {
  [ProjectWizardTypes.SET_CRS]: setCRS,
  [ProjectWizardTypes.GET_GENERIC_CRS_LOADING]: getGenericCRSLoading,
  [ProjectWizardTypes.GET_GENERIC_CRS_SUCCESS]: getGenericCRSSuccess,
  [ProjectWizardTypes.GET_GENERIC_CRS_FAILURE]: getGenericCRSFailure,
  [ProjectWizardTypes.GET_PREDEFINED_CRS_LOADING]: getPredefinedCRSLoading,
  [ProjectWizardTypes.GET_PREDEFINED_CRS_SUCCESS]: getPredefinedCRSSuccess,
  [ProjectWizardTypes.GET_PREDEFINED_CRS_FAILURE]: getPredefinedCRSFailure,
  [ProjectWizardTypes.RESET_PROJECT_WIZARD_STATE]: resetProjectWizardState,
})
