import { toast } from 'react-toastify'
import { path } from 'ramda'
import i18n from 'i18n'
import { all, join } from 'redux-saga/effects'
import axios from 'utils/axios'

/**
 * Returns authentification header
 * @param {String} authToken
 */
export const getAuthentificationHeader = authToken => {
  return { Authorization: `Bearer ${authToken}` }
}

export function isResultSuccessful (result) {
  const { okay, status: statusCode, statusText } = result
  return ((okay || statusText === 'OK') && statusCode >= 200 && statusCode <= 299)
}

const getRecursiveMessage = messages => {
  if (typeof messages === 'object') {
    return Object.keys(messages).reduce((message, key) => {
      const keyedMessage = messages[key]
      return `${message}\n${key}${Array.isArray(keyedMessage) ? `: ${keyedMessage.join(', ')}` : ` -> ${getRecursiveMessage(keyedMessage)}`}`
    }
    , '')
  }
  return messages || ''
}

export function getErrorMessage (e, networkError) {
  const errorMessage = e && e.message
  if (errorMessage === 'Network Error') return networkError
  const backendMessage = path(['response', 'data', 'message'], e)
  if (typeof backendMessage === 'object') {
    return getRecursiveMessage(backendMessage)
  }
  if (typeof backendMessage === 'string') {
    return backendMessage
  }
  return (e.message || e).toString()
}

export function showErrorMessage (e, networkError) {
  const errorMessage = getErrorMessage(e, networkError)
  toast.error(errorMessage || '')
}

export function getMergedData (results) {
  return results.reduce((accum, result) => [...accum, result.data.data], [])
}

export function * joinAll (tasks) {
  try {
    const joinedResults = yield all(tasks.map(t => join(t)))
    return joinedResults
  } catch (e) {
    throw new Error(e)
  }
}

export function * resolveTasks (tasks, transformFn) {
  try {
    const joinedResults = yield joinAll(tasks)
    const mergedData = getMergedData(joinedResults)
    return typeof transformFn === 'function' ? mergedData.map(transformFn) : mergedData
  } catch (e) {
    throw new Error(e)
  }
}

/**
 * Check if email can be used for registration (not used by any other user)
 * @param {String} email
 */
export const apiCheckEmail = async email => {
  const url = '/check_email'
  try {
    await axios.post(url, { email })
    return { okay: true }
  } catch (e) {
    // showErrorMessage(e)
    const status = e.response.status
    if (status === 401) {
      toast.error(i18n.t('toast.register.checkEmailError'))
    }
    return { okay: false, status }
  }
}
