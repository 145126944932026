// import { FileType, GenericParserInfo } from './utils'
import { isNavFileName, parseNavFile, isNavFileType } from './nav'
import { isNavApplanixFileName } from './nav-applanix'
import { isCamFileName, parseCamFile, parseDropboxCamFile, isCamFileType, parseImageFile } from './cam'
import { isLdrFileName, parseLdrFile, parseDropboxLdrFile, shouldLdrFileBeParsed, isLdrFileType } from './ldr'
import { isLogFileName, parseLogFile, parseDropboxLogFile, shouldLogFileBeParsed, isLogFileType } from './log'
import { isGcpFileName, parseGcpFile, parseDropboxGcpFile, isGcpFileType } from './gcp'
import { isPlpFileName, parsePlpFile, parseDropboxPlpFile, isPlpFileType } from './plp'
import { isLasFileName, parseLasFile, parseDropboxLasFile, isLasFileType } from './las' // parseLasFile, isLasFileType
import { isKMLFileName } from './kml'
import { isXMLFileName } from './xml'
import { isOBJFileName } from './obj'
import { isDXFFileName } from './dxf'
import { isShapeFileName } from './shape'
import { isTrjFileName, parseTrjFile, isTrjFileType } from './trj'
import { isGeotiffFileName, parseGeotiffFile, isGeotiffFileType } from './geotiff'
import { isDataFileName } from './data'
import { isRefStationFileName, parseRefStationFile, parseDropboxRefStationFile, isRefStationFileType, ReferenceStationFileType, shouldRefStationFileBeParsed } from './refStation'
import { isPolygonFileType, parseDropboxPolygonFile, parsePolygonFile } from './polygon'

export * from './cam'
export * from './nav'
export * from './nav-applanix'
export * from './ldr'
export * from './gcp'
export * from './plp'
export * from './trj'
export * from './las'
export * from './log'
export * from './refStation'
export * from './data'
export * from './geotiff'
export * from './kml'
export * from './xml'
export * from './obj'
export * from './dxf'
export * from './shape'

// export { FileType } from './utils'

/**
 * Returns the correct file type by just looking at the name of the file. This function is not asynchroneous and
 * pretty fast, but might not always work if the name of a file (specifically, the file extension) is wrong.
 * @param file The file to detect the file type of.
 * @return The `FileType` of the file.
 */
export function getFileTypeByFileExtension (file) {
  if (isLdrFileName(file.name)) {
    return 'ldr'
  }
  if (isCamFileName(file.name)) {
    return 'cam'
  }
  if (isNavFileName(file.name)) {
    return 'nav'
  }
  if (isNavApplanixFileName(file.name)) {
    return 'nav_applanix'
  }
  if (isGcpFileName(file.name)) {
    return 'gcp'
  }
  if (isPlpFileName(file.name)) {
    return 'plp'
  }
  if (isLogFileName(file.name)) {
    return 'log'
  }
  if (isLasFileName(file.name)) {
    return 'las'
  }
  if (isKMLFileName(file.name)) {
    return 'kml'
  }
  if (isXMLFileName(file.name)) {
    return 'xml'
  }
  if (isOBJFileName(file.name)) {
    return 'obj'
  }
  if (isDXFFileName(file.name)) {
    return 'dxf'
  }
  if (isShapeFileName(file.name)) {
    return 'shape'
  }
  if (isTrjFileName(file.name)) {
    return 'trj'
  }
  if (isGeotiffFileName(file.name)) {
    return 'tiff'
  }
  if (isRefStationFileName(file.name)) {
    return ReferenceStationFileType
  }
  if (isDataFileName(file.name)) {
    return 'data'
  }
  return 'unknown'
}

export function shouldFileBeParsed (file) {
  if (isLdrFileName(file.name)) {
    return shouldLdrFileBeParsed(file.name)
  }
  if (isLogFileName(file.name)) {
    return shouldLogFileBeParsed(file.name)
  }
  if (isRefStationFileName(file.name)) {
    return shouldRefStationFileBeParsed(file.name)
  }
  if (
    isNavApplanixFileName(file.name) ||
    isDataFileName(file.name)
  ) {
    return false
  }
  return true
}

/**
 * Parses the file with the correct parser, chosen based on the file's extension. Returns either the `ParserInfo`
 * of the correct parser or `undefined` if no matching parser could be found for this file extension.
 * @param file The file to parse.
 * @return The `ParserInfo` result of the parser or `undefined` if no parser could be found.
 */
export async function parseFileByFileExtension (file, fileType) {
  if (isCamFileType(fileType)) {
    return parseCamFile(file)
  }
  if (isLasFileType(fileType)) {
    return parseLasFile(file)
  }
  if (isNavFileType(fileType)) {
    return parseNavFile(file)
  }
  if (isTrjFileType(fileType)) {
    return parseTrjFile(file)
  }
  if (isGeotiffFileType(fileType)) {
    return parseGeotiffFile(file)
  }
  if (isLdrFileType(fileType)) {
    return parseLdrFile(file)
  }
  if (isGcpFileType(fileType)) {
    return parseGcpFile(file)
  }
  if (isPlpFileType(fileType)) {
    return parsePlpFile(file)
  }
  if (isLogFileType(fileType)) {
    return parseLogFile(file)
  }
  if (isRefStationFileType(fileType)) {
    return parseRefStationFile(file)
  }
  if (isPolygonFileType(fileType)) {
    return parsePolygonFile(file)
  }
  if (isParsableImageFile(file)) {
    return parseImageFile(file)
  }
}

export const isParsableImageFile = file => {
  return file.type === 'image/png' ||
    file.type === 'image/jpeg' ||
    file.type === 'image/jpg'
}

/**
 * Parses the file with the correct parser, chosen based on the file's extension. Returns either the `ParserInfo`
 * of the correct parser or `undefined` if no matching parser could be found for this file extension.
 * @param file The file to parse.
 * @return The `ParserInfo` result of the parser or `undefined` if no parser could be found.
 */
export async function parseDropboxFileByFileExtension (file, fileType) {
  if (isCamFileType(fileType)) {
    return parseDropboxCamFile(file)
  }
  if (isGcpFileType(fileType)) {
    return parseDropboxGcpFile(file)
  }
  if (isLdrFileType(fileType)) {
    return parseDropboxLdrFile(file)
  }
  if (isPlpFileType(fileType)) {
    return parseDropboxPlpFile(file)
  }
  if (isLogFileType(fileType)) {
    return parseDropboxLogFile(file)
  }
  // Nav shouldn't be downloaded and parsed so we can just use simple file object
  if (isNavFileType(fileType)) {
    return parseNavFile(file)
  }
  // Trajectory shouldn't be downloaded and parsed so we can just use simple file object
  if (isTrjFileType(fileType)) {
    return parseTrjFile(file)
  }
  // Geotiff shouldn't be downloaded and parsed so we can just use simple file object
  if (isGeotiffFileType(fileType)) {
    return parseGeotiffFile(file)
  }
  if (isLasFileType(fileType)) {
    return parseDropboxLasFile(file)
  }
  if (isRefStationFileType(fileType)) {
    return parseDropboxRefStationFile(file)
  }
  if (isPolygonFileType(fileType)) {
    return parseDropboxPolygonFile(file)
  }
  /*
  if (isLasFileType(fileType)) {
    return parseLasFile(file)
  }
  */
}
