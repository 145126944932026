export const createTicketError = state => {
  return state.support.get('createTicketErrorMessage')
}

export const isCreateTicketLoading = state => {
  return state.support.get('createTicketIsLoading')
}

export const isSupportFormDialogOpened = state => {
  return state.support.get('isSupportFormDialogOpened')
}
