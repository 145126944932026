/* I18N */
import i18next from 'i18next'
import commonDe from './de/common.json'
import commonEn from './en/common.json'
import ICU from 'i18next-icu'
import en from 'i18next-icu/locale-data/en'
import de from 'i18next-icu/locale-data/de'

i18next
  .use(new ICU({ localeData: [en, de] }))
  .init({
    initImmediate: true,
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    lng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    resources: {
      en: { common: commonEn },
      de: { common: commonDe },
    },
  })

export default i18next
