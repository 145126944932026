import { CRSFields } from 'templates/CRS/constants'

export const CRSAuthNames = [
  'EPSG',
  'ESRI',
  'IGNF',
  'OGC',
]

export const SECrsFieldNamesMap = {
  'crs': CRSFields.C_CRS,
  'crsV': CRSFields.V_CRS,
  'crsH': CRSFields.H_CRS,
  'geoid': CRSFields.GRID,
  'geoidCrs': CRSFields.GEOID_CRS,
  'normalizeAxisOrder': CRSFields.NORMALIZE_AXIS_ORDER,
  'unitH': CRSFields.H_UNITS,
  'unitV': CRSFields.V_UNITS,
}
