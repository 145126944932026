// External dependencies.
import React from 'react'
import i18n from 'i18n'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'
// Material UI dependencies.
import Typography from '@material-ui/core/Typography'
// Project internal dependencies.
import styles from './style.css'
import { Divider } from '@material-ui/core'
/**
 * Component that can display a large list of items without loosing the performance
 */
const VirtualizedList = props => {
  const {
    useDynamicRowHeight = false,
    selectedData,
    list,
    hasFavorites,
    favoriteItemsAmount,
    autoSizeHeight = false,
    // listWidth = 300,
    listHeight = 300,
    rowHeight = 50,
    overscanRowCount = 1,
    showScrollingPlaceholder = false,
    scrollingRender,
    noDataLabel = i18n.t('customTable.default.empty'),
  } = props
  // const listWidth = props.listWidth || 300

  const getDatum = index => {
    if (index > favoriteItemsAmount && hasFavorites) index -= 1;
    return list[index % list.length];
  }
  const noRowsRenderer = () => <Typography className={styles.noRows} style={{ padding: 8 }}>{noDataLabel}</Typography>

  const rowRenderer = ({ index, isScrolling, key, style }) => {
    const item = getDatum(index)
    const options = { key, style, isScrolling, index }
    if (index === favoriteItemsAmount && hasFavorites) {
      return <Divider key={key} style={{
        ...style, 
        height: '4px',
        backgroundColor: 'red',
        width: '100%',
        }} />;
    }
    if (showScrollingPlaceholder && isScrolling) {
      if (typeof scrollingRender === 'function') return scrollingRender(item, options)
      return (
        <div
          className={clsx(styles.row, styles.isScrollingPlaceholder)}
          key={key}
          style={style}>
          Scrolling...
        </div>
      )
    }
    return props.renderItem(item, options)
  }

  const getRowHeight = ({ index }) => {
    const item = getDatum(index)
    return item.rowHeight || rowHeight
  }

  const style = autoSizeHeight ? { flex: '1 1 auto', height: '100%', width: '100%', overflow: 'hidden' } : { width: '100%', height: '100%' }
  const actualHeight = list.length * rowHeight > listHeight ? listHeight : list.length * rowHeight
  return (
    <div style={style}>
      <AutoSizer disableHeight={!autoSizeHeight}>
        {({ width, height }) => (
          <List
            // ref={'List'}
            className={styles.List}
            style={{ outline: 'none' }}
            height={autoSizeHeight ? height : actualHeight}
            overscanRowCount={overscanRowCount}
            noRowsRenderer={noRowsRenderer}
            rowCount={list.length}
            rowHeight={useDynamicRowHeight ? getRowHeight : rowHeight}
            rowRenderer={rowRenderer}
            width={width}
            selectedData={selectedData}
          />
        )}
      </AutoSizer>
    </div>
  )
}

VirtualizedList.propTypes = {
  useDynamicRowHeight: PropTypes.bool,
  noDataLabel: PropTypes.node,
  listWidth: PropTypes.number,
  rowHeight: PropTypes.number,
  listHeight: PropTypes.number,
  overscanRowCount: PropTypes.number,
  list: PropTypes.array,
  selectedData: PropTypes.array,
  autoSizeHeight: PropTypes.bool,
  showScrollingPlaceholder: PropTypes.bool,
  hasFavorites: PropTypes.bool,
  favoriteItemsAmount: PropTypes.number,
  renderItem: PropTypes.func,
  scrollingRender: PropTypes.func,
}

export default VirtualizedList
