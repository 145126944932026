import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: 12,
  },
})(Tooltip)

export default StyledTooltip
