import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
// Project deps
import { abbreviateArtifactType } from 'utils/artifacts'
import { theme } from 'HOC/withRoot'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarBig: {
    width: 45,
    height: 45,
    fontSize: 16, // 40 / 2.5,
    [theme.breakpoints.down('md')]: {
      width: 32,
      height: 32,
      fontSize: 12.8, // 32 / 2.5,
    },
  },
  avatarMini: {
    width: 32,
    height: 32,
    fontSize: 12.8, // 32 / 2.5,
    [theme.breakpoints.down('md')]: {
      width: 26,
      height: 26,
      fontSize: 10.4, // 26 / 2.5,
    },
  },
})

const ArtifactAvatar = ({ artifactType, mini, classes, style = {}, subText }) => {
  const avatarClass = mini ? classes.avatarMini : classes.avatarBig
  return (
    <Avatar
      style={{
        ...style,
        backgroundColor: theme.palette.artifactColor[artifactType],
        color: theme.palette.artifactTextColor[artifactType],
      }}
      className={avatarClass}
    >
      <div>
        {abbreviateArtifactType(artifactType)}
        {typeof subText !== 'undefined' && <sub>{subText}</sub>}
      </div>
    </Avatar>
  )
}

ArtifactAvatar.propTypes = {
  style: PropTypes.object,
  classes: PropTypes.object,
  subText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  artifactType: PropTypes.string,
  mini: PropTypes.bool,
}

export default withStyles(styles)(React.memo(ArtifactAvatar))
