
export const getBackendTransformedValues = (
  state,
  values,
  rawValues,
  fieldsTemplates,
  loopThroughValues = false,
) => {
  return Object.keys(loopThroughValues ? values : fieldsTemplates).reduce((allValues, key) => {
    const value = values[key]
    const option = fieldsTemplates[key] || {}
    const { backendTransform, sendToBackend, backendTransformName, backendName } = option
    const shouldSendToBackend = typeof sendToBackend === 'function'
      ? sendToBackend(state, values)
      : typeof sendToBackend === 'boolean'
        ? sendToBackend
        : true
    if (!shouldSendToBackend) return allValues
    const typeOfBackendTransformName = typeof backendTransformName
    const fieldName = typeOfBackendTransformName !== 'undefined'
      ? typeOfBackendTransformName === 'function'
        ? backendTransformName(state, values)
        : backendTransformName
      : key
    const backendFinalName = typeof backendName === 'function'
      ? backendName(value, state, rawValues)
      : backendName
    const fields = allValues[backendFinalName] || {}
    const transformedValue = typeof backendTransform === 'function'
      ? backendTransform(value, state, rawValues)
      : 'backendTransform' in option
        ? backendTransform
        : value
    const field = fieldName ? {
      [fieldName]: transformedValue,
    } : transformedValue
    return {
      ...allValues,
      ...(backendFinalName ? {
        [backendFinalName]: {
          ...fields,
          ...field,
        },
      } : field
      ),
    }
  }, {})
}
