import { UploadStatus, isCurrentStageRunning } from './utils'

export const getFilesLoaded = state => {
  return state.upload.get('files')
}

export const getFailedFiles = state => {
  return state.upload.get('failedFiles')
}

export const getDataFiles = state => {
  return state.upload.get('dataFiles')
}

const fileCanBeUploaded = (currentArtifact, transfer, file) => {
  if (file.status !== UploadStatus.PREPARED) {
    return false
  }
  const currentArtifactState = currentArtifact[file.artifactId]
  const stage = currentArtifactState && currentArtifactState.stage
  const fileId = file.id
  return isCurrentStageRunning(stage) && !transfer.some(transferState => transferState.id === fileId)
}

const fileCanBePrepared = (currentArtifact, prepare, file) => {
  if (file.status !== UploadStatus.PENDING) {
    return false
  }
  const currentArtifactState = currentArtifact[file.artifactId]
  const stage = currentArtifactState && currentArtifactState.stage
  const fileId = file.id
  return isCurrentStageRunning(stage) && !prepare.some(prepareState => prepareState.id === fileId)
}

/**
 * It is neccessary to find a file which can be uploaded as the files in the list of prepared
 * files might not all be uploadable, depending on whether the user already initiated the upload
 * on this artifact or not or cancelled it.
 * @param state The upload state.
 * @return The next file that can be prepared (if such).
 */
export function getNextUploadableFile ({ upload: state }, fileId, artifactId) {
  const transfer = state.get('transfer')
  const files = state.get('files')
  const currentArtifact = state.get('currentArtifact')
  if (artifactId || fileId) {
    if (artifactId && fileId) {
      const fileFromProps = files.find(file =>
        file.id === fileId &&
        file.artifactId === artifactId &&
        fileCanBeUploaded(currentArtifact, transfer, file),
      )
      if (fileFromProps) {
        return fileFromProps
      }
    }
    if (fileId) {
      const fileFromProps = files.find(file =>
        file.id === fileId &&
        fileCanBeUploaded(currentArtifact, transfer, file),
      )
      if (fileFromProps) {
        return fileFromProps
      }
    }
    if (artifactId) {
      const fileFromProps = files.find(file =>
        file.artifactId === artifactId &&
        fileCanBeUploaded(currentArtifact, transfer, file),
      )
      if (fileFromProps) {
        return fileFromProps
      }
    }
  } else {
    const nextUploadableFile = files.find(file => fileCanBeUploaded(currentArtifact, transfer, file))
    return nextUploadableFile
  }
}

/**
 * It is necessary to find a file which can be prepared as the files in the list of pending
 * files might not all be preparable, depending on whether the user already initiated the upload
 * on this artifact or not.
 * @param state The store state.
 * @return The next file that can be prepared (if such).
 */
export function getNextPreparableFile ({ upload: state }, artifactId) {
  const prepare = state.get('prepare')
  const files = state.get('files')
  const currentArtifact = state.get('currentArtifact')
  if (artifactId) {
    const fileFound = files.find(file => file.artifactId === artifactId && fileCanBePrepared(currentArtifact, prepare, file))
    if (fileFound) {
      return fileFound
    }
  } else {
    const nextPreparableFile = files.find(file => fileCanBePrepared(currentArtifact, prepare, file))
    return nextPreparableFile
  }
}

export function isSomeFileReadyToBeTransfered ({ upload: state }) {
  const transfer = state.get('transfer')
  const currentArtifact = state.get('currentArtifact')
  const fileToTransfer = state.get('files').some(file => fileCanBeUploaded(currentArtifact, transfer, file))
  return fileToTransfer
}

export function isSomeFileReadyToBePrepared ({ upload: state }) {
  const prepare = state.get('prepare')
  const currentArtifact = state.get('currentArtifact')
  const filesToPrepare = state.get('files').some(file => fileCanBePrepared(currentArtifact, prepare, file))
  return filesToPrepare
}

export function getPreparedFilesForArtifact ({ upload: state }, artifactId) {
  const transfer = state.get('transfer')
  const currentArtifact = state.get('currentArtifact')
  const filesToUpload = state.get('files').filter(
    file => file.artifactId === artifactId &&
    fileCanBeUploaded(currentArtifact, transfer, file),
  )
  return filesToUpload
}

export const getFailedFileForFile = ({ upload: state }) => file => {
  const failedFiles = state.get('failedFiles')
  return failedFiles.find(failed =>
    failed.fileName === file.file.name &&
    failed.fileSize === file.file.size &&
    failed.artifactId === file.artifactId,
  )
}

export const getCurrentArtifact = ({ upload: state }) => artifactId => {
  const currentArtifact = state.get('currentArtifact')
  if (artifactId) {
    return currentArtifact[artifactId]
  }
  return currentArtifact
}

export const getUploadTransferTries = ({ upload: state }, id) => {
  const file = state.get('transfer').find(transferFile => transferFile.id === id)
  return file ? file.tries : 0
}

export const getIsBusy = state => {
  return state.upload.get('busy')
}

export const getArtifactUploadState = state => {
  return state.upload.get('uploadArtifactState')
}

export const getDataDirectoryUploadedFiles = ({ upload: state }) => dataDirectoryId =>
  state.get('dataDirectoryUploadedFiles')[dataDirectoryId] || 0

export const getDataDirectoryUploadedFilesSize = ({ upload: state }) => dataDirectoryId =>
  state.get('dataDirectoryUploadedFilesSize')[dataDirectoryId] || 0

export const getDataDirectoryUpdateSizeTime = ({ upload: state }) => dataDirectoryId =>
  state.get('dataDirectoryUpdateSizeTime')[dataDirectoryId] || new Date()