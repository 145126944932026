import i18n from 'i18n'
import { getUTCDate } from 'utils/dateTime'
/**
 * The state of a job run.
 */
export const JobRunState = {
  /**
   * Waiting (TODO: and ready?) to be executed.
   */
  WAITING: 'waiting',
  /**
   * Currently being executed.
   */
  RUNNING: 'running',
  /**
   * Successfully executed.
   */
  SUCCESS: 'success',
  /**
   * Something failed.
   */
  FAILURE: 'failure',
  /**
   * The user has asked to cancel the job and it is
   * waiting for the worker to acknowledge.
   */
  CANCELING: 'canceling',
  /**
   * The worker has canceled the job.
   */
  CANCELED: 'canceled',
  /**
   * The worker has canceled the job.
   */
  WAITING_FOR_ARTIFACT: 'waiting_for_artifact',
  /**
   * This job run is scheduled
   */
  SCHEDULED: 'scheduled',
  /**
   * Unlicensed job run
   */
  UNLICENSED: 'unlicensed',
  /**
   * This job run state indicate that pipeline created with errors (eg. no job runs, no job, no job ios)
   */
  CONTACT_SUPPORT: 'contact_support',
}

export const JobRunStates = [
  JobRunState.WAITING,
  JobRunState.WAITING_FOR_ARTIFACT,
  JobRunState.SUCCESS,
  JobRunState.SCHEDULED,
  JobRunState.CANCELED,
  JobRunState.CANCELING,
  JobRunState.FAILURE,
  JobRunState.RUNNING,
  JobRunState.UNLICENSED,
  JobRunState.CONTACT_SUPPORT,
]

export const PrettyJobRunState = JobRunStates.reduce((allStates, state) => ({
  ...allStates,
  [state]: i18n.t(`jobRun.status.${state}`),
}), {})

/**
 * Converts a `RawJobRun` into a regular `JobRun`.
 * @param raw The raw job run.
 * @return The converted job run.
 */
export function convertRawJobRun (raw) {
  const {
    id,
    job_run_state,
    failure_reason,
    started_at,
    finished_at,
    options,
    results,
    job: { id: jobId },
    progress,
    created,
    updated,
    stages,
  } = raw
  const isStagesOkay = typeof stages === 'object' && stages !== null ? stages : undefined // tslint:disable-line
  return {
    id,
    jobRunState: job_run_state,
    startedAt: typeof started_at === 'string' ? getUTCDate(started_at) : undefined,
    finishedAt: typeof finished_at === 'string' ? getUTCDate(finished_at) : undefined,
    failureReason: typeof failure_reason === 'string' ? failure_reason : undefined,
    options: typeof options === 'object' && options !== null ? options : undefined, // tslint:disable-line
    results: typeof results === 'object' && results !== null ? results : undefined, // tslint:disable-line
    jobId,
    progress: typeof progress === 'number' ? progress : undefined,
    created: getUTCDate(created),
    updated: getUTCDate(updated),
    stages: isStagesOkay && {
      commands: stages.commands,
      currentStage: stages.current_stage,
    },
  }
}
