import { toast } from 'react-toastify'
import { call, put, takeLatest, fork, select, takeEvery } from 'redux-saga/effects'
import axios from 'utils/axios'
import { convertRawMission } from 'types/missions'
import { getErrorMessage, resolveTasks, showErrorMessage } from 'utils/api'
import i18next from 'i18n'
// Local deps
import MissionsActions, { MissionsTypes } from './actions'
import { getCbor } from 'modules/utils'
import { getCurrentProject } from 'modules/projects/selectors'
import { findById } from 'utils/list'

// Delete individual mission (artifacts that belongs to the mission will be deleted as well)
export function * deleteMission ({ missionId }) {
  yield put(MissionsActions.deleteMissionLoading(missionId))
  try {
    const url = `/missions/${missionId}`
    yield call(axios.delete, url)
    yield put(MissionsActions.deleteMissionSuccess(missionId))
    toast.success(i18next.t('toast.mission.deleteSuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(MissionsActions.deleteMissionFailure(getErrorMessage(e), missionId))
  }
}

// Update individual mission name/plp
export function * updateMission ({ missionId, data }) {
  yield put(MissionsActions.updateMissionLoading())
  try {
    const { name, plp } = data
    let plpJson
    try {
      plpJson = JSON.parse(plp)
    } catch (_) {
      plpJson = undefined
    }
    const url = `/missions/${missionId}`
    const { data: { data: mission } } = yield call(axios.put, url, {
      ...(typeof name === 'string' ? { name } : {}),
      ...(typeof plpJson !== 'undefined' ? { plp: plpJson } : {}),
    })
    yield put(MissionsActions.updateMissionSuccess(missionId, convertRawMission(mission)))
  } catch (e) {
    yield put(MissionsActions.updateMissionFailure(getErrorMessage(e)))
  }
}
// Retrieves single mission by Id
export function * getMission ({ projectId, missionId }) {
  yield put(MissionsActions.getMissionLoading())
  try {
    const { data: { data: mission } } = yield call(axios.get, `/missions/${missionId}`)
    yield put(MissionsActions.getMissionSuccess(projectId, mission))
  } catch (e) {
    yield put(MissionsActions.getMissionFailure(getErrorMessage(e)))
  }
}
// Retrieve a list of project missions
export function * getMissions ({ projectId, exclude = false }) {
  yield put(MissionsActions.getMissionsLoading())
  try {
    const currentProject = yield select(state => getCurrentProject(state))
    const currentProjectMissions = currentProject.missions || []
    const missions = yield call(getCbor, `/projects/${projectId}/missions${exclude ? `?exclude=plp` : ''}`)
    const oldMissions = []
    const tasks = []
    for (let i = 0; i < missions.length; i++) {
      const mission = missions[i]
      const missionId = mission.id
      const missionFound = findById(missionId, currentProjectMissions)
      if (missionFound) {
        oldMissions.push({ ...mission, plp: missionFound.plp || mission.plp })
      } else {
        tasks.push(yield fork(axios.get, `/missions/${missionId}`))
      }
    }
    const newMissions = yield resolveTasks(tasks)
    yield put(MissionsActions.getMissionsSuccess(projectId, [...oldMissions, ...newMissions]))
  } catch (e) {
    yield put(MissionsActions.getMissionsFailure(getErrorMessage(e)))
  }
}

function * deleteMissionWatcher () {
  yield takeLatest(MissionsTypes.DELETE_MISSION, deleteMission)
}

function * updateMissionWatcher () {
  yield takeLatest(MissionsTypes.UPDATE_MISSION, updateMission)
}

function * getMissionWatcher () {
  yield takeEvery(MissionsTypes.GET_MISSION, getMission)
}

function * getMissionsWatcher () {
  yield takeLatest(MissionsTypes.GET_MISSIONS, getMissions)
}

export default function * root () {
  yield fork(deleteMissionWatcher)
  yield fork(updateMissionWatcher)
  yield fork(getMissionWatcher)
  yield fork(getMissionsWatcher)
}
