
import { path } from 'ramda'
import i18n from 'i18n'
// Project deps
import { getEPSGDatums, getIEDatums } from 'modules/app/selectors'
import { getFileNameForFilePropertiesWithDefinedCRS, isOutFileName } from 'modules/upload/file-types'
import { getNumberOfWeeks, fromDecimalYear } from 'utils/dateTime'
import { getEPSGDatumLabel } from 'utils/datums'
import { CRSFields } from './constants'
import { DataType } from 'types/form'
import { getDatumInitialValue } from './utils'
import { isPointcloudArtifact } from 'types/artifacts'
import { makeUnique } from 'utils/list'

const findProjectCRSIeDatum = (state, projectCRSDatum) => {
  const espgDatums = getEPSGDatums(state)
  return espgDatums.find(datum => datum.name.includes(projectCRSDatum.name)) || null
}

export const PofTemplate = {
  trajectory_datum: {
    name: i18n.t('templates.artifactOptions.trajectory.trajectory_datum'),
    dataType: DataType.AUTOCOMPLETE,
    optionProps: { variant: 'outlined' },
    options: state => getEPSGDatums(state),
    displayTemplate: datum => getEPSGDatumLabel(datum),
    searchTemplate: datum => getEPSGDatumLabel(datum),
    getValue: (state, value, values, extra) => value ? getEPSGDatumLabel(value) : '',
    optional: false,
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { projectCRS, protoArtifact } = extraProps
      if (protoArtifact && isPointcloudArtifact(protoArtifact.artifactType)) {
        const { properties } = protoArtifact
        const firstFileWithCRSInFileProperties = getFileNameForFilePropertiesWithDefinedCRS(Object.keys(properties.fileProperties), properties)
        if (firstFileWithCRSInFileProperties) {
          const fileProps = properties.fileProperties[firstFileWithCRSInFileProperties].crs
          if (fileProps.datum) {
            return findProjectCRSIeDatum(state, fileProps.datum)
          }
        }
      }
      if (projectCRS) {
        const projectCRSDatum = getDatumInitialValue(state, projectCRS)
        if (projectCRSDatum) {
          return findProjectCRSIeDatum(state, projectCRSDatum)
        }
      }
      return null
    },
    viewLimit: 4,
    rowHeight: 35,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
}

const getValueFromLogFile = (fieldName, transformFn) => (state, extra, { extraProps }) => {
  const { sbetLogFile } = extraProps
  if (sbetLogFile) {
    const result = path(['parseResult', 'result'], sbetLogFile)
    const value = result[fieldName]
    if (value) {
      if (typeof transformFn === 'function') return transformFn(value)
      return value
    }
  }
  return null
}

export const getSbetGPSWeekInitialValue = (state, extra, options) =>
  getValueFromLogFile('missionDate', value => {
    const gpsWeek = getNumberOfWeeks(new Date(value))
    return gpsWeek.toString()
  })(state, extra, options)

export const getSbetEpochInitialValue = (state, extra, options) =>
  getValueFromLogFile(CRSFields.EPOCH, fromDecimalYear)(state, extra, options)

export const getSbetDatumInitialValue = (state, extra, options) =>
  getValueFromLogFile('datum', value => {
    const epsgDatums = getIEDatums(state)
    return epsgDatums.find(datum => datum.navlab_datum.includes(value))
  })(state, extra, options)

export const SbetTemplate = {
  gps_week: {
    name: i18n.t('templates.artifactOptions.trajectory.gps_week'),
    dataType: DataType.GPS_WEEK,
    initialValue: (state, extra, options) => getSbetGPSWeekInitialValue(state, extra, options),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  timesystem: {
    name: i18n.t('templates.artifactOptions.trajectory.timesystem'),
    variant: 'outlined',
    dataType: DataType.SELECTION,
    options: ['GPS', 'UTC'],
    initialValue: 'GPS',
  },
  trajectory_datum: {
    name: i18n.t('templates.artifactOptions.trajectory.trajectory_datum'),
    optionProps: { variant: 'outlined' },
    dataType: DataType.AUTOCOMPLETE,
    options: state => getEPSGDatums(state),
    displayTemplate: datum => getEPSGDatumLabel(datum),
    searchTemplate: datum => getEPSGDatumLabel(datum),
    getValue: (state, value, values, extra) => value ? getEPSGDatumLabel(value) : '',
    optional: false,
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { projectCRS, protoArtifact } = extraProps
      if (protoArtifact && isPointcloudArtifact(protoArtifact.artifactType)) {
        const { properties } = protoArtifact
        const firstFileWithCRSInFileProperties = getFileNameForFilePropertiesWithDefinedCRS(Object.keys(properties.fileProperties), properties)
        if (firstFileWithCRSInFileProperties) {
          const fileProps = properties.fileProperties[firstFileWithCRSInFileProperties].crs
          if (fileProps.datum) {
            return findProjectCRSIeDatum(state, fileProps.datum)
          }
        }
      }
      if (projectCRS) {
        const projectCRSDatum = getDatumInitialValue(state, projectCRS)
        if (projectCRSDatum) {
          return findProjectCRSIeDatum(state, projectCRSDatum)
        }
      }
      return getSbetDatumInitialValue(state, extra, options)
    },
    viewLimit: 4,
    rowHeight: 35,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
}

const SbetTemplateFieldNames = Object.keys(SbetTemplate)
const PofTemplateFieldNames = Object.keys(PofTemplate)
export const TrajectoryTemplateFieldNames = makeUnique([...SbetTemplateFieldNames, ...PofTemplateFieldNames, 'valid'])

export const getTrajectoryCRSTemplateFieldNames = fileName => {
  if (isOutFileName(fileName)) {
    return SbetTemplateFieldNames
  }
  return PofTemplateFieldNames
}

export const getTrajectoryCRSTemplate = files => {
  const sbetFilesUploaded = files.find(({ file: { name } }) => isOutFileName(name))
  return sbetFilesUploaded ? SbetTemplate : PofTemplate
}
