import React from 'react'
import { Trans } from 'react-i18next'
// turf
// Project deps
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import { path } from 'ramda'
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import {
  getJobOptions,
  getPipelineTemplate,
  // getSelectedTemplateArtifacts,
} from 'modules/pipelineWizard/selectors'
import { isPipelineSettingsJob } from 'utils/jobs'
// import { findById } from '../utils/list'
import { isEnablePhotosEnabled } from '../jobOptionsUtils'
import { MapBackendNameToFrontend } from '../constants'
import { isAdmin } from 'modules/users/selectors'
import WikiButton from 'components/WikiButton'
import Warning from 'components/Warning'

export default {
  trj_optimization: {
    name: (state, values, extra) => {
      return <span>{MapBackendNameToFrontend.trj_optimization} <WikiButton title={<Trans i18nKey='templates.jobOptions.optimization.tooltip.trj_optimization'/>}/></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'trj_optimization', true),
    /*
    initialValue: (state, values) => {
      return getSelectedTrajectoryArtifacts(state).length <= 1
      // return getMissionIds(state, values).length <= 1
    },
    getValue: (state, value, values, extra) => {
      return getSelectedTrajectoryArtifacts(state).length > 1 ? false : value
      // getMissionIds(state, values).length > 1 ? false : value
    },
    backendTransform: (original, state, values) => {
      return getSelectedTrajectoryArtifacts(state).length > 1 ? false : original
      // getMissionIds(state, values).length > 1 ? false : original
    },
    disabled: (state, values) => {
      return getSelectedTrajectoryArtifacts(state).length > 1
      // getMissionIds(state, values).length > 1
    },
    */
  },
  trj_optimization_use_gcp: {
    adminOnly: true,
    name: (state, values, extra) => {
      return <span>{MapBackendNameToFrontend.trj_optimization_use_gcp} <WikiButton title={<Trans i18nKey='templates.jobOptions.optimization.tooltip.trj_optimization_use_gcp'/>}/></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'trj_optimization_use_gcp',
      () => {
        const selectedTemplate = getPipelineTemplate(state)
        const jobId = (selectedTemplate.jobs.find(templateJob => isPipelineSettingsJob(templateJob.jobType)) || {}).templateId
        const jobValues = getJobOptions(state)[jobId]
        if (jobValues && path(['rawValues', 'dataset_type'], jobValues) === 'Mobile') {
          return true
        }
        return false
      }
    ),
    sendToBackend: (state, values) => isAdmin(state),
  },
  gcp_align_to_ground_only: {
    name: MapBackendNameToFrontend.gcp_align_to_ground_only,
    dataType: DataType.BOOLEAN,
    disabled: (state, values) => values.trj_optimization_use_gcp,
    invisible: (state, values, extra, formTemplate, option, options) => {
      const selectedTemplate = getPipelineTemplate(state)
      const jobId = (selectedTemplate.jobs.find(templateJob => isPipelineSettingsJob(templateJob.jobType)) || {}).templateId
      const jobValues = getJobOptions(state)[jobId]
      if (jobValues && path(['rawValues', 'dataset_type'], jobValues) === 'Mobile') {
        return true
      }
      return false
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'gcp_align_to_ground_only',
      false,
    ),
    additionalContent: (state, values) => {
      if (values.trj_optimization_use_gcp) {
        return <Warning containerStyle={{ paddingLeft: 16 }} variant='warning'>Can&apos;t be used with &apos;{MapBackendNameToFrontend.trj_optimization_use_gcp}&apos; option enabled</Warning>
      }
      return null
    },
    transformOnChangeOf: ['trj_optimization_use_gcp'],
    transform: (original, state, formValues, artifactId) => {
      if (formValues.trj_optimization_use_gcp) {
        return false
      }
      return original
    },
  },
  sensor_calibration: {
    name: (state, values, extra) => {
      return <span>{MapBackendNameToFrontend.sensor_calibration} <WikiButton title={<Trans i18nKey='templates.jobOptions.optimization.tooltip.sensor_calibration'/>}/></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'sensor_calibration', false),
  },
  use_photos_in_turns: {
    name: MapBackendNameToFrontend.use_photos_in_turns,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'use_photos_in_turns', false),
    disabled: state => !isEnablePhotosEnabled(state),
    getValue: (state, value) => isEnablePhotosEnabled(state) ? value : false,
    sendToBackend: (state, values) => isEnablePhotosEnabled(state),
    backendTransform: (value, state) => isEnablePhotosEnabled(state) ? value : false,
  },
  /*
  list_selector: {
    name: 'Sensor calibration',
    dataType: DataType.FILELIST_SELECTOR,
    disabled: (_state, values) => !values.trj_optimization,
    invisible: (state, _values, extra) => getSelectedGCPArtifacts(state).length <= 0,
    initialValue: (state, extra, options) => {
      const trj_optimization = getCloneJobOptionValue(state, extra, options, 'trj_optimization', true)
      const cloneGcpsLists = Object.values(GCPListType).map(key => ({
        id: key,
        list: getCloneJobOptionValue(state, extra, options, key, []),
      }))
      return getSelectedGCPArtifacts(state).reduce((allFiles, artifact) => {
        const { properties } = artifact
        const { fileNames } = properties
        return [
          ...allFiles,
          ...fileNames.map(fileName => {
            const predefinedGcps = cloneGcpsLists.find(gcpsList => gcpsList.list.includes(fileName))
            const predefinedValue = predefinedGcps
              ? predefinedGcps.id
              : GCPListType.ALIGN
            return {
              id: fileName,
              list: trj_optimization ? predefinedValue : GCPListType.IGNORE,
            }
          }),
        ]
      }, [])
    },
    options: (state, formValues, extra) => {
      const gcpArtifacts = getSelectedGCPArtifacts(state)
      const gcpArtifactNumber = gcpArtifacts.length
      return gcpArtifacts.reduce((allFiles, artifact) => {
        const { properties, name } = artifact
        const { fileNames = [] } = properties
        return [
          ...allFiles,
          ...fileNames.map(fileName => ({ name: gcpArtifactNumber > 1 ? `${fileName}(${name})` : fileName, id: fileName })),
        ]
      }, [])
    },
    selectorOptions: [
      {
        id: GCPListType.ALIGN,
        label: 'Georeferencing',
      },
      {
        id: GCPListType.CHECKSHOTS,
        label: 'Georeference validation',
      },
      {
        id: GCPListType.IGNORE,
        label: 'Visualization only',
      },
    ],
    transformOnChangeOf: ['trj_optimization'],
    transform: (original, state, formValues, artifactId) => {
      if (!formValues.trj_optimization) {
        return original.map(value => ({ ...value, list: GCPListType.IGNORE }))
      }
      return original
    },
    backendTransformName: '',
    backendTransform: (value, state) => value.reduce((allValues, v) => {
      const { id, list } = v
      if (!list) return allValues
      return {
        ...allValues,
        [list]: [
          ...(allValues[list]),
          id,
        ],
      }
    }, {
      [GCPListType.ALIGN]: [],
      [GCPListType.CHECKSHOTS]: [],
      [GCPListType.IGNORE]: [],
    }),
  },
  */
  /*
  laser_orientation: {
    name: 'Laser orientation',
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra) => getCloneJobOptionValue(state, extra, 'laser_orientation', false),
    tooltip: 'This boresight optimization tool applies an angular correction per laser (pitch,yaw,roll correction)',
  },
  alternative_calibration_tools: {
    name: 'Alternative calibration tools',
    dataType: DataType.DROPDOWN_PANEL,
    initialValue: false,
    options: ['laser_ranging', 'laser_intrinsicts'],
  },
  laser_ranging: {
    name: 'Laser ranging',
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra) => getCloneJobOptionValue(state, extra, 'laser_ranging', false),
    tooltip: 'This calibration option applies a ranging scale correction per laser',
    invisible: true,
  },
  laser_intrinsicts: {
    name: 'Laser intrinsics',
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra) => getCloneJobOptionValue(state, extra, 'laser_intrinsicts', false),
    tooltip: 'This calibration option applies both a scan angle and tilt angle scale and offset per laser',
    invisible: true,
  },
  aligntogcp: {
    name: 'Align to GCP',
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra) => getCloneJobOptionValue(state, extra, 'aligntogcp', () => isAlignToGcpEnabled(state)),
    disabled: state => !isAlignToGcpEnabled(state),
    getValue: (state, value, values, extra) => isAlignToGcpEnabled(state) ? value : false,
    backendTransform: (value, state, values) => isAlignToGcpEnabled(state) ? value : false,
    sendToBackend: (state, values) => isAlignToGcpEnabled(state),
    tooltip: 'Determines vertical offsets between input GCP elevations and the ground classified points within your pointcloud, computes an average deviation magnitude, and vertically transforms the point cloud by the average magnitude to best align the point cloud to the input GCPs',
  },
  gcp_threshold: {
    name: 'Ignore GCPs outside of X standard deviations',
    dataType: DataType.INTEGER,
    initialValue: (state, extra) => getCloneJobOptionValue(state, extra, 'gcp_threshold', '2'),
    disabled: (state, values) => !isGCPThresholdEnabled(state),
    getValue: (state, value) => !isGCPThresholdEnabled(state) ? '2' : value,
    sendToBackend: (state, values) => isGCPThresholdEnabled(state),
  },
  */
}
