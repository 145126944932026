import { omit } from 'ramda'
import { DataType } from 'types/form'
import {
  omitFields,
  mapNameToTransformName,
  mapNameToValue,
} from 'types/templatedForm'

/**
 * Checks if a value is not empty.
 * @param value The value for the job option.
 * @return Whether the value is empty or not.
 */
export function isValueSet (value, dataType) {
  if (typeof value === 'string') {
    return value !== ''
  }
  if (typeof value === 'boolean') {
    return true
  }
  if (typeof value === 'number') {
    return true
  }
  if (Array.isArray(value) && typeof value[0] === 'string') {
    return value.every(elem => elem !== '')
  }
  if (Array.isArray(value) && typeof value[0] === 'number') {
    return true
  }
  if ((dataType === DataType.AUTOCOMPLETE || dataType === DataType.GEOID_AUTOCOMPLETE || dataType === DataType.ANTENNA_AUTOCOMPLETE) &&
    typeof value === 'object' && value !== null
  ) {
    return true
  }
  if ((dataType === DataType.DATETIME || dataType === DataType.TIME_OF_WEEK) && typeof value === 'object' && value !== null) {
    return true
  }
  if (dataType === DataType.CRS && typeof value === 'object' && Boolean(value)) {
    if (Object.values(omit(['uniqueKey', 'name', 'id', 'project'], value)).some(Boolean)) {
      return true
    }
  }
  return false
}

/**
 * Returns the actual value that will be used in the rendered option
 * Here we can write specific handlers for each DataType
 * @param {*} props
 */
export const getValue = props => {
  const { values, name, option, state, value, extra, extraProps = {} } = props
  const { getValue, dataType } = option
  let formValue = value || values[name] || ''
  switch (dataType) {
    case DataType.BOOLEAN:
      formValue = Boolean(formValue)
      break
    case DataType.GPS_WEEK:
      formValue = formValue.toString()
      break
    default:
      break
  }
  return typeof getValue === 'function'
    ? getValue(state, formValue, values, extra, extraProps)
    : typeof getValue !== 'undefined'
      ? getValue
      : formValue
}

export const isDisabled = ({ option, state, values, extra, formTemplate, name }) => {
  let disabled
  // If `option.disabled` is a function it needs to be evaluated...
  if (typeof option.disabled === 'function') {
    disabled = option.disabled(state, values, extra, formTemplate, { option, state, values, extra, formTemplate, name })
  } else {
    // ...otherwise the raw value can be used.
    disabled = option.disabled
  }
  return disabled
}

/**
 * Retrieves a warning message from an option object. The message can be either a static
 * string or a dynamically generated string based on a function evaluation.
 * @returns {string} - The warning message for the field, either directly from the option object or computed dynamically.
 */
export const getWarningMessage = ({ option, state, values, extra, formTemplate, name }) => {
  let message
  // If `option.warningMessage` is a function it needs to be evaluated...
  if (typeof option.warningMessage === 'function') {
    message = option.warningMessage(state, values, extra, formTemplate, { option, state, values, extra, formTemplate, name })
  } else {
    // ...otherwise the raw value can be used.
    message = option.warningMessage
  }
  return message
}

export const getPositionTransformedValues = (state, values, formTemplate) => {
  const valuesToSave = omit(omitFields, values)
  const transformedValues = Object.keys(valuesToSave).reduce((values, field) => {
    const option = formTemplate[field] || {}
    const { backendName, backendTransform } = option
    const originalValue = valuesToSave[field]
    const transformValueFunction = mapNameToValue[field]
    const value = typeof transformValueFunction === 'function'
      ? transformValueFunction(state, values, originalValue, formTemplate[field])
      : originalValue
    const backendFinalName = typeof backendName === 'function'
      ? backendName(value, state, values)
      : backendName
    const fieldValues = values[backendFinalName] || {}
    const transformedValue = typeof backendTransform === 'function'
      ? backendTransform(value, state, values)
      : 'backendTransform' in option
        ? backendTransform
        : value
    const valueField = { [mapNameToTransformName[field] || field]: transformedValue }
    return {
      ...values,
      ...(backendFinalName ? {
        [backendFinalName]: {
          ...fieldValues,
          ...valueField,
        },
      } : valueField
      ),
    }
  }, {})
  return transformedValues
}
