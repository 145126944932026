import { getUTCDate } from 'utils/dateTime'

/*
  This jobs is used only on frontend
  Their values will be written in some root job (like spatial_fuser)
  Basically used is only for displaying them as steps in pipeline wizard
*/
export const DummyJob = {
  OUTPUT_CRS: 'output_crs',
  LIDAR_SETTINGS: 'lidar_settings',
  FLIGHTLINES: 'flightlines',
  OPTIMIZATION: 'optimization',
  OPTIMIZATION_POINTCLOUD: 'optimization_pointcloud',
  GROUND_CLASSIFICATION: 'ground_classification',
  PRODUCTS: 'products',
  IMU: 'imu',
  CALIBRATION: 'calibration',
  LOCAL_CRS: 'local_crs',
  PIPELINE_SETTINGS: 'pipeline_settings',
}

export const DummyJobs = Object.keys(DummyJob).map(key => DummyJob[key])

/**
 * An object containing the names of all job types known to the backend.
 */
export const JobType = {
  NL_ANALYZE_NAVIGATION_ROVER: 'nl_analyze_navigation_rover',
  NL_ANALYZE_REFERENCE_STATION: 'nl_analyze_reference_station',
  NL_ANALYZE_TRAJECTORY: 'nl_analyze_trajectory',
  NL_ANALYZE_LIDAR_DATA: 'nl_analyze_lidar_data',
  NL_ANALYZE_RECON_DATA: 'nl_analyze_recon',
  ANALYZE_CAMERA_DATA: 'analyze_camera_data',
  NAVLAB: 'navlab',
  NAVLAB_APPLANIX: 'navlab_applanix',
  SPATIAL_FUSER: 'spatial_fuser',
  POTREE_CONVERTER: 'potree_converter',
  TEST_JOB: 'test_job_please_ignore',
  TRANSFER: 'transfer_project',
  UPLOAD_FILES: 'upload_files',
  CLASSIFICATION: 'classification',
  CONVERTER: 'converter',
  POINTCLOUD_OPTIMIZATION: 'pointcloud_optimization',
  GEOTIFF_OPTIMIZATION: 'geo_tiff_optimization',
  ORTHOIMAGE_GENERATION: 'orthoimage_generation',
  ...DummyJob,
}

export const JobTypes = [
  JobType.NL_ANALYZE_NAVIGATION_ROVER,
  JobType.NL_ANALYZE_REFERENCE_STATION,
  JobType.ANALYZE_CAMERA_DATA,
  JobType.NL_ANALYZE_TRAJECTORY,
  JobType.NL_ANALYZE_LIDAR_DATA,
  JobType.NL_ANALYZE_RECON_DATA,
  JobType.NAVLAB,
  JobType.NAVLAB_APPLANIX,
  JobType.SPATIAL_FUSER,
  JobType.POTREE_CONVERTER,
  JobType.TEST_JOB,
  JobType.TRANSFER,
  JobType.UPLOAD_FILES,
  JobType.CLASSIFICATION,
  JobType.POINTCLOUD_OPTIMIZATION,
  JobType.ORTHOIMAGE_GENERATION,
]

export function convertRawJob (raw) {
  const { id, job_type, pipeline, created, updated, last_job_run } = raw
  const lastJobRun = last_job_run || {}
  return {
    id,
    jobType: job_type,
    pipelineId: pipeline && pipeline.id,
    projectId: pipeline && pipeline.project.id,
    created: new Date(created),
    updated: new Date(updated),
    lastJobRunId: typeof lastJobRun.id === 'string' ? lastJobRun.id : undefined,
    lastJobRunPriority: typeof lastJobRun.priority === 'number' ? lastJobRun.priority : 5,
    lastJobRunState: typeof lastJobRun.job_run_state === 'string' ? lastJobRun.job_run_state : undefined,
    lastJobRunCreated: typeof lastJobRun.created === 'string' ? getUTCDate(lastJobRun.created) : undefined,
    lastJobRunFinishedAt: typeof lastJobRun.finished_at === 'string' ? getUTCDate(lastJobRun.finished_at) : undefined,
    lastJobRunStartedAt: typeof lastJobRun.started_at === 'string' ? getUTCDate(lastJobRun.started_at) : undefined,
    progress: typeof lastJobRun.progress === 'number' ? lastJobRun.progress : 0,
  }
}
