// Bytes to KB, MB, or GB
const sizeFormatter = (bufSize, decimal = true) => {
  if (!bufSize) {
    return { KB: 0, MB: 0, GB: 0, TB: 0 }
  }

  const size = Number(bufSize)
  if (decimal) {
    return {
      KB: size / 1e3,
      MB: size / 1e6,
      GB: size / 1e9,
      TB: size / 1e12,
    }
  }
  return {
    KB: size / 1024,
    MB: size / (1024 * 1024),
    GB: size / (1024 * 1024 * 1024),
    TB: size / (1024 * 1024 * 1024 * 1024),
  }
}

/**
 * Prettify file/project/directory size to be like: 1024 -> 1KB, ..etc
 * @param {Object} size
 * @returns prettified string
 */
export default function formatSize (size, decimal = false) {
  const { KB, MB, GB, TB } = sizeFormatter(size, decimal)
  if (TB >= 1) { return `${TB.toFixed(2)} TB` }
  if (GB >= 1) { return `${GB.toFixed(2)} GB` }
  if (MB >= 1) { return `${MB.toFixed(2)} MB` }
  return `${KB.toFixed(2)} KB`
}
