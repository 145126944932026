import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { AppTypes } from './actions'
import { storageId } from 'config'
import { isVerticalCoordinateSystem, isCompoundCoordinateSystem, isHorizontalCoordinateSystem, isGeographic3DCRS } from 'utils/coordinateSystem'
import { setStorageItem } from 'utils/localStorage'

export const getConstantsSuccess = (state, {
  countries,
  industries,
  timeZones,
  filetypes,
  epsg_datums,
  ie_datums,
  coordinateSystems,
  units,
  geoids,
  datum_epochs,
  classifications,
  salesProducts,
}) => {
  const horizontalCoordinateSystems = coordinateSystems.filter(isHorizontalCoordinateSystem)
  const geographic3DCoordinateSystems = coordinateSystems.filter(isGeographic3DCRS)
  return state.merge({
    constants: {
      ...state.get('constants'),
      countries,
      timeZones,
      industries,
      filetypes,
      epochs: datum_epochs,
      epsg_datums: epsg_datums.map(datum => ({ ...datum, code: datum.code.toString() })) || [],
      ie_datums: ie_datums.map(datum => ({ ...datum, code: datum.code.toString() })) || [],
      verticalCoordinateSystems: coordinateSystems.filter(isVerticalCoordinateSystem),
      horizontalCoordinateSystems,
      horizontalGeographic3DCoordinateSystems: [...geographic3DCoordinateSystems, ...horizontalCoordinateSystems],
      compoundCoordinateSystems: coordinateSystems.filter(isCompoundCoordinateSystem),
      classifications,
      coordinateSystems,
      units,
      geoids,
      salesProducts,
    },
    constantsLoaded: true,
    getConstantsIsLoading: false,
  })
}

export const getConstantsLoading = state => {
  return state.merge({
    getConstantsIsLoading: true,
  })
}

export const getConstantsFailure = state => {
  return state.merge({
    constantsLoaded: false,
    getConstantsIsLoading: false,
  })
}

export const getAntennasSuccess = (state, { antennas }) => {
  return state.merge({
    getAntennasIsLoading: false,
    constants: {
      ...state.get('constants'),
      antennas: [
        { name: 'Generic', radome: 'NONE' },
        ...antennas.map(antenna => ({ ...antenna, name: antenna.antenna_type })),
      ],
    },
  })
}

export const getAntennasLoading = state => {
  return state.merge({ getAntennasIsLoading: true })
}

export const getAntennasFailure = state => {
  return state.merge({ getAntennasIsLoading: false })
}

export const getAllSystemTypesLoading = state => {
  return state.merge({
    getAllSystemTypesIsLoading: true,
  })
}

export const getAllSystemTypesSuccess = (state, { systemTypes }) => {
  return state.merge({
    systemTypes,
    getAllSystemTypesIsLoading: false,
  })
}

export const getAllSystemTypesFailure = state => {
  return state.merge({
    getAllSystemTypesIsLoading: false,
  })
}

export const changeUserSettingsProjects = (state, { settings }) => {
  const userSettings = state.get('userSettings')
  const newUserSettings = {
    ...userSettings,
    projects: {
      ...userSettings.projects,
      ...settings,
    },
  }
  setStorageItem(storageId.settings, JSON.stringify(newUserSettings))
  return state.merge({
    userSettings: newUserSettings,
  })
}

export const changeHeaderHeight = (state, { height }) => {
  return state.merge({
    headerHeight: height,
  })
}

export const getRecommendedDatumsLoading = state => state.merge({})
export const getRecommendedDatumsSuccess = (state, { lat, lng, datums }) => state.merge({
  recommendedDatums: {
    ...state.get('recommendedDatums'),
    [`${lat},${lng}`]: datums,
  },
})
export const getRecommendedDatumsFailure = state => state.merge({})

export const getGCPTemplateLoading = state => state.merge({})
export const getGCPTemplateSuccess = (state, { template }) => state.merge({
  gcpTemplate: template,
})
export const getGCPTemplateFailure = state => state.merge({})

export const openCheckTokenDialog = (state, { callback }) => state.merge({
  checkTokenDialogOpen: true,
  checkTokenCallback: callback,
})
export const closeCheckTokenDialog = (state, { callback }) => state.merge({
  checkTokenDialogOpen: false,
  checkTokenCallback: null,
})

export const getProductsLoading = state => state.merge({ products: [] })
export const getProductsSuccess = (state, { products }) => state.merge({ products })
export const getProductsFailure = state => state.merge({ products: [] })

export const reducer = createReducer(INITIAL_STATE, {
  [AppTypes.GET_CONSTANTS_LOADING]: getConstantsLoading,
  [AppTypes.GET_CONSTANTS_SUCCESS]: getConstantsSuccess,
  [AppTypes.GET_CONSTANTS_FAILURE]: getConstantsFailure,
  [AppTypes.GET_ANTENNAS_LOADING]: getAntennasLoading,
  [AppTypes.GET_ANTENNAS_SUCCESS]: getAntennasSuccess,
  [AppTypes.GET_ANTENNAS_FAILURE]: getAntennasFailure,
  [AppTypes.GET_ALL_SYSTEM_TYPES_LOADING]: getAllSystemTypesLoading,
  [AppTypes.GET_ALL_SYSTEM_TYPES_SUCCESS]: getAllSystemTypesSuccess,
  [AppTypes.GET_ALL_SYSTEM_TYPES_FAILURE]: getAllSystemTypesFailure,
  [AppTypes.GET_RECOMMENDED_DATUMS_LOADING]: getRecommendedDatumsLoading,
  [AppTypes.GET_RECOMMENDED_DATUMS_SUCCESS]: getRecommendedDatumsSuccess,
  [AppTypes.GET_RECOMMENDED_DATUMS_FAILURE]: getRecommendedDatumsFailure,
  [AppTypes.GET_GCP_TEMPLATE_LOADING]: getGCPTemplateLoading,
  [AppTypes.GET_GCP_TEMPLATE_SUCCESS]: getGCPTemplateSuccess,
  [AppTypes.GET_GCP_TEMPLATE_FAILURE]: getGCPTemplateFailure,

  [AppTypes.GET_PRODUCTS_LOADING]: getProductsLoading,
  [AppTypes.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [AppTypes.GET_PRODUCTS_FAILURE]: getProductsFailure,

  [AppTypes.CHANGE_USER_SETTINGS_PROJECTS]: changeUserSettingsProjects,
  [AppTypes.CHANGE_HEADER_HEIGHT]: changeHeaderHeight,
  [AppTypes.OPEN_CHECK_TOKEN_DIALOG]: openCheckTokenDialog,
  [AppTypes.CLOSE_CHECK_TOKEN_DIALOG]: closeCheckTokenDialog,
})
